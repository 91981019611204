import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BLACK } from 'config/appColors';
import { Message } from 'types';

import { AdminAudit, SupportedMessageEnum } from '../../../types';

const StyledDateWarning = styled('div')`
  margin-bottom: 12px;
  line-height: 2rem;
  letter-spacing: 0.017rem;
  color: ${BLACK};

  p {
    margin: 0;
    padding: 0;
  }
`;

const StyledDateWarningTitle = styled('p')`
  font-weight: 700;
`;

const StyledDateWarningDetailsTitle = styled('span')`
  font-weight: 300;
  font-style: italic;
`;

interface DateWarningTooltipContentProps {
  message: Message;
  auditProps: AdminAudit;
}

interface MessageConfig {
  id: SupportedMessageEnum;
  reason?: keyof AdminAudit;
  description?: keyof AdminAudit;
}

const MESSAGES_CONFIG: MessageConfig[] = [
  {
    id: SupportedMessageEnum.TOO_LATE_UPLOAD,
  },
  {
    id: SupportedMessageEnum.AUDIT_TIME_EXCEEDED,
    reason: 'exceededReason',
    description: 'exceededReasonDescription',
  },
  {
    id: SupportedMessageEnum.AUDIT_TIME_NOT_REACHED,
    reason: 'reductionReason',
    description: 'reductionReasonDescription',
  },
];

export const DateWarningTooltipContent: FC<DateWarningTooltipContentProps> = (
  props
) => {
  const [t] = useTranslation('adminReports');
  const { auditProps, message } = props;
  const { id, text } = message;

  const getDataToDisplay = (msgId: string, key: 'reason' | 'description') => {
    const messageFields = MESSAGES_CONFIG.find((msg) => msg.id === msgId);
    const fieldName = messageFields?.[key];
    const data = fieldName && auditProps[fieldName];

    return typeof data === 'string' ? data : '';
  };

  const reasonToDisplay = getDataToDisplay(id, 'reason');
  const descriptionToDisplay = getDataToDisplay(id, 'description');

  return (
    <StyledDateWarning>
      <StyledDateWarningTitle>{text}</StyledDateWarningTitle>
      {reasonToDisplay && (
        <p>
          <StyledDateWarningDetailsTitle>
            {t('Reason')}:{' '}
          </StyledDateWarningDetailsTitle>
          {reasonToDisplay}
        </p>
      )}
      {descriptionToDisplay && (
        <p>
          <StyledDateWarningDetailsTitle>
            {t('Description')}:{' '}
          </StyledDateWarningDetailsTitle>
          {descriptionToDisplay}
        </p>
      )}
    </StyledDateWarning>
  );
};
