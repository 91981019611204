import React, { ChangeEvent, FC, useEffect, useRef } from 'react';

import { useMemoizedDebounce } from 'hooks/useMemoizedDebounce';
import { Nullable } from 'types';

import { FILTER_DEFAULT_MIN_LENGTH } from '../../../config/constants';
import { TextFieldFilter, UseFiltersAndSorting } from '../../../types';
import { StyledTextField } from '../../atoms/StyledTextField';

type TextFieldFilterCellProps = TextFieldFilter & UseFiltersAndSorting;

export const TextFieldFilterCell: FC<TextFieldFilterCellProps> = ({
  filters,
  filterKey,
  minLength = FILTER_DEFAULT_MIN_LENGTH,
  setFilterValue,
}) => {
  const ref = useRef<Nullable<HTMLInputElement>>(null);
  const value = filters[filterKey];

  const handleSearchPhraseChange = ({
    target,
  }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchPhrase = target.value.trim();
    if (searchPhrase.length === 0 || searchPhrase.length >= minLength) {
      setFilterValue({ filterKey, value: target.value });
    }
  };

  const debouncedHandleSearchPhraseChange = useMemoizedDebounce(
    handleSearchPhraseChange,
    500,
    [filters]
  );

  useEffect(() => {
    if (ref.current?.value && value === '') {
      ref.current.value = '';
    } else if (ref.current?.value === '' && value) {
      ref.current.value = value as string;
    }
  }, [value, ref]);

  return (
    <StyledTextField
      inputRef={ref}
      defaultValue={value}
      onChange={debouncedHandleSearchPhraseChange}
    />
  );
};
