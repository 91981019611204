import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { UNEXPECTED_ERROR, UNEXPECTED_ERROR_EXTENDED } from 'config/constants';
import { forceMockHeader } from 'mock/constants';
import type { RootState } from 'store';
import { ExtendedError, LoaderStatusEnum, Nullable } from 'types';
import apiClient from 'utils/apiClient';

import { SupplierAddressesState } from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';
import { Address, SupplierDetailsParams } from '../../types';

const initialState: SupplierAddressesState = {
  list: [],
  loading: LoaderStatusEnum.IDLE,
  error: null,
};

const reducerName = `${parentReducerName}/detailsAddresses`;

export const getSupplierAddresses = createAsyncThunk(
  `${reducerName}/list`,
  async ({ supplierId }: SupplierDetailsParams, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        endpoints.SUPPLIERS.DETAILS_ADDRESSES(supplierId),
        {
          headers: forceMockHeader,
        }
      );

      if (!Array.isArray(response.data)) {
        response.data = [];
      }

      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errCode = err?.response?.status;
        const errText = err?.response?.statusText;

        return rejectWithValue({
          message: UNEXPECTED_ERROR_EXTENDED,
          details: `${errCode} - ${errText}`,
        });
      }
      return rejectWithValue({
        message: UNEXPECTED_ERROR,
      });
    }
  }
);

// slice
export const supplierAddressesSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetSupplierAddressesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSupplierAddresses.fulfilled, (state, action) => {
      state.list = action.payload as Address[];
      state.loading = LoaderStatusEnum.SUCCESS;
      state.error = null;
    });
    builder.addCase(getSupplierAddresses.pending, (state) => {
      state.loading = LoaderStatusEnum.LOADING;
    });
    builder.addCase(getSupplierAddresses.rejected, (state, action) => {
      state.error = action.payload as ExtendedError;
      state.loading = LoaderStatusEnum.ERROR;
    });
  },
});

// actions
export const { resetSupplierAddressesState } = supplierAddressesSlice.actions;

// selectors
export const selectSupplierAddresses = (state: RootState): Address[] =>
  state[parentReducerName].detailsAddresses.list || [];

export const selectSupplierAddressesLoading = (
  state: RootState
): LoaderStatusEnum => state[parentReducerName].detailsAddresses.loading;

export const selectSupplierAddressesError = (
  state: RootState
): Nullable<ExtendedError> => state[parentReducerName].detailsAddresses.error;

// reducer
export default supplierAddressesSlice.reducer;
