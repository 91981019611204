import i18next from 'i18n';

import de from './locales/de.json';
import en from './locales/en.json';

const ns = 'user';

i18next.addResources('en', ns, en);
i18next.addResources('de', ns, de);

export { default } from './components/pages/PageLogin';
export * from './components/pages/PageLogin';
