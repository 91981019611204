import { styled } from '@mui/material';
import React, { FC } from 'react';

import { DatePicker } from 'components/atoms/DatePicker';
import { TEXT, WHITE } from 'config/appColors';
import { DateFilter, UseFiltersAndSorting } from 'domains/adminReports/types';
import { formatDate } from 'utils/dates';

import { commonInputStyles } from '../../atoms/StyledTextField';

const StyledDatePicker = styled(DatePicker)`
  ${commonInputStyles};

  input {
    text-align: center;
    font-size: 1.6rem;
    padding-right: 0;
  }

  .MuiInputBase-root {
    background: ${WHITE};
    padding-right: 10px;
  }

  .MuiInputAdornment-root {
    background: ${WHITE};
  }

  svg {
    font-size: 2rem;
    color: ${TEXT.SECONDARY_LIGHT};
  }
`;

type DateFilterCellProps = DateFilter & UseFiltersAndSorting;

export const DateFilterCell: FC<DateFilterCellProps> = ({
  filterKey,
  filters,
  setFilterValue,
}) => {
  const rawValue = filters[filterKey];
  const value = typeof rawValue === 'string' ? rawValue : '';

  const handleDateChange = (newDate: Date) => {
    setFilterValue({ filterKey, value: formatDate(newDate, 'yyyy-MM-dd') });
  };

  return (
    <StyledDatePicker
      inputPlaceholder=" "
      inputWidth="auto"
      value={value}
      onChange={handleDateChange}
    />
  );
};
