import { styled } from '@mui/material';
import React from 'react';

import { StartEndDates } from 'types';
import { renderDateRange } from 'utils/dates';

import { AdminTableCell } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

const StyledDateRange = styled(StyledPlainText)`
  padding: 0 0 3px 8px;
`;

const StyledDateRangeTitle = styled('span')`
  margin-left: -8px;
  display: block;
  font-weight: 600;
`;

export const CertificateRecertificationCell: AdminTableCell = (props) => {
  const { certificate, t } = props;

  if (!certificate) {
    return null;
  }

  const {
    announcedRecertificationTimeframe,
    unannouncedRecertificationTimeframe,
  } = certificate;

  const renderCertificateDateRange = (title: string, dates?: StartEndDates) =>
    dates && (
      <StyledDateRange>
        <StyledDateRangeTitle>{title}:</StyledDateRangeTitle>
        {renderDateRange(dates.start, dates.end)}
      </StyledDateRange>
    );

  return (
    <>
      {renderCertificateDateRange(
        t('Announced'),
        announcedRecertificationTimeframe
      )}
      {renderCertificateDateRange(
        t('Unannounced'),
        unannouncedRecertificationTimeframe
      )}
    </>
  );
};
