import React, { FC } from 'react';

import { DottedButton } from 'components/atoms/DottedButton';
import DropdownMenu from 'components/molecules/DropdownMenu';

import {
  LockHistoryActionsDropdownProps,
  useLockHistoryActionsDropdown,
} from '../../../hooks/useLockHistoryActionsDropdown';

export const LockHistoryActionsDropdown: FC<LockHistoryActionsDropdownProps> = (
  props
) => {
  const dropdownOptions = useLockHistoryActionsDropdown(props);

  return (
    <DropdownMenu
      options={dropdownOptions}
      renderOpenButton={({ onClick }) => <DottedButton onClick={onClick} />}
    />
  );
};
