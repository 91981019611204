import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sub } from 'date-fns';

import { SortStateType } from 'components/organisms/Table/types';
import { RootState } from 'store';
import { Nullable } from 'types';
import { formatDate } from 'utils/dates';

import {
  CollectionName,
  CommonFilterableAuditsCertificatesFilters,
  FilterableListsState,
  FilterErrors,
  FilterStringValuePayload,
  SortStatePayload,
} from './types';
import { parentReducerName } from '../../config/constants';

export const MIN_SEARCH_CHARS = 3;

const initialFilterState: CommonFilterableAuditsCertificatesFilters = {
  startDate: formatDate(sub(new Date(), { days: 365 }), 'yyyy-MM-dd'),
  endDate: formatDate(new Date(), 'yyyy-MM-dd'),
  moduleId: '',
  supplierNameCoidSearchString: '',
};

const commonState = {
  sort: null,
  filter: initialFilterState,
  limit: 50,
  offset: 0,
};

const initialState: FilterableListsState = {
  audits: commonState,
  certificates: commonState,
};

const reducerName = `${parentReducerName}/filterableLists`;

export const filterableListsSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setFilterState: (
      state,
      { payload }: PayloadAction<FilterStringValuePayload>
    ) => {
      state[payload.collection].filter[payload.key] = payload.value;
    },
    setSortState: (state, { payload }: PayloadAction<SortStatePayload>) => {
      state[payload.collection].sort = payload.sortState;
    },
    resetFilterAndSortState: (
      state,
      { payload }: PayloadAction<CollectionName>
    ) => {
      state[payload].sort = null;
      state[payload].filter = initialFilterState;
    },
  },
});

// actions
export const { setSortState, setFilterState, resetFilterAndSortState } =
  filterableListsSlice.actions;

// selectors
export const selectFilterableListsFilter =
  (collection: CollectionName) =>
  (state: RootState): CommonFilterableAuditsCertificatesFilters =>
    state[parentReducerName].filterableLists[collection].filter;

export const selectFilterableListsSortState =
  (collection: CollectionName) =>
  (state: RootState): Nullable<SortStateType> =>
    state[parentReducerName].filterableLists[collection].sort;

export const selectSearchMinCharError =
  (collection: CollectionName) =>
  (state: RootState): boolean => {
    const searchPhraseLength =
      state[parentReducerName].filterableLists[collection].filter
        .supplierNameCoidSearchString.length;

    return searchPhraseLength > 0 && searchPhraseLength < MIN_SEARCH_CHARS;
  };

export const selectFilterErrors =
  (collection: CollectionName) =>
  (state: RootState): FilterErrors => {
    const searchError = selectSearchMinCharError(collection)(state);

    return { searchError };
  };

export default filterableListsSlice.reducer;
