import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AttributeRowBase } from 'components/atoms/AttributeRow/AttributeRowBase';
import { SectionTitle } from 'components/atoms/SectionTitle';
import { StatusIndicator } from 'components/atoms/StatusIndicator';
import { BACKGROUND_COLORS as PROCESS_STATUS_BG_COLORS } from 'components/molecules/ProcessStatusIndicator';
import { ERROR } from 'config/appColors';
import { AuditAppointment } from 'domains/supplier/types';
import { ProcessStatus, WithUndefined } from 'types';
import { renderDateRange } from 'utils/dates';

import { selectAuditAppointmentDetails } from '../../../state/details/slice';

const StyledHeaderGrid = styled(Grid)`
  margin-bottom: 2rem;
  padding-right: 1rem;
  justify-content: space-between;
  align-items: center;
`;

const StyledAttributeRowsContainer = styled('div')`
  margin-bottom: 25px;
  margin-left: 10px;
`;

const StyledCommentAttributeRow = styled(AttributeRowBase)`
  flex-direction: column;
`;

export const dropdownItemStyles = {
  dropdownDeleteItemStyle: {
    color: `${ERROR.LIGHT}`,
  },
};

export const DetailsTabGeneralSection: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const auditAppointmentDetails = useSelector(selectAuditAppointmentDetails);

  const getStatusTableItem = (
    status: WithUndefined<AuditAppointment['status']>
  ) => {
    if (!status) {
      return [];
    }

    return [
      {
        label: t('Status'),
        value: (
          <StatusIndicator
            label={status.name}
            bgColor={PROCESS_STATUS_BG_COLORS[status.id || ProcessStatus.OPEN]}
          />
        ),
      },
    ];
  };

  const attributeRowItems = [
    ...getStatusTableItem(auditAppointmentDetails?.status),
    {
      label: t('Supplier'),
      value: `${auditAppointmentDetails?.supplier.name} (COID: ${auditAppointmentDetails?.supplier.coid})`,
    },
    {
      label: t('Certification Body'),
      value: auditAppointmentDetails?.certificationBody.name,
    },
    {
      label: t('Standard/program', { ns: 'components' }),
      value: auditAppointmentDetails?.module?.name,
    },
    {
      label: t('Type'),
      value: auditAppointmentDetails?.type,
    },
    {
      label: t('Kind'),
      value: auditAppointmentDetails?.kind,
    },
    {
      label: t('Unannounced Period'),
      value: renderDateRange(
        auditAppointmentDetails?.unannouncedPeriod?.start,
        auditAppointmentDetails?.unannouncedPeriod?.end
      ),
    },
    {
      label: t('Execution Mode'),
      value: auditAppointmentDetails?.executionMode,
    },
    {
      label: t('Due Date'),
      value: renderDateRange(
        auditAppointmentDetails?.dueDate?.start,
        auditAppointmentDetails?.dueDate?.end
      ),
    },
  ];

  return (
    <>
      <StyledHeaderGrid container>
        <SectionTitle>{t('General')}</SectionTitle>
      </StyledHeaderGrid>
      <StyledAttributeRowsContainer>
        {attributeRowItems.map(({ label, value }) => (
          <AttributeRowBase key={label} label={label} value={value} />
        ))}
        <StyledCommentAttributeRow
          label={t('Comment')}
          value={auditAppointmentDetails?.description}
        />
      </StyledAttributeRowsContainer>
    </>
  );
};
