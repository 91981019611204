import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { LoaderStatusEnum, Nullable, WithUndefined } from 'types';
import { formatDate, getDateFromString, isDateInRange } from 'utils/dates';

import { TimeState } from '../state/formOptions/types';
import { TimeRange, TimeSelectPlaceholderEnum } from '../types';

interface DatePickerPropsUtils {
  getValue: (
    loading: LoaderStatusEnum,
    value?: Nullable<string>
  ) => Nullable<string>;
  getPlaceholder: (
    type: TimeSelectPlaceholderEnum,
    value?: Nullable<string>
  ) => string;
  getDateForRange: (
    timeState: TimeState,
    type: keyof Omit<TimeRange, 'hints'>
  ) => WithUndefined<Date>;
  getDefaultCalendarMonth: (timeState: TimeState) => WithUndefined<Date>;
}

export const useDatePickerPropsUtils = (): DatePickerPropsUtils => {
  const [t] = useTranslation('auditAppointment');
  const isAdmin = useSelector(selectUserIsAdmin);

  const placeholders = {
    [TimeSelectPlaceholderEnum.SELECT]: () =>
      t('select date', { ns: 'components' }),
    [TimeSelectPlaceholderEnum.CALCULATING]: () => t('calculating ...'),
    [TimeSelectPlaceholderEnum.CHECKING]: (value: string) =>
      t('{{date}} - checking ...', { date: formatDate(value) }),
  };

  const getValue = (loading: LoaderStatusEnum, value?: Nullable<string>) =>
    loading === LoaderStatusEnum.LOADING ? null : value || null;

  const getPlaceholder = (
    type: TimeSelectPlaceholderEnum,
    value?: Nullable<string>
  ) => placeholders[type](value || '');

  const getDateForRange = (
    timeState: TimeState,
    type: keyof Omit<TimeRange, 'hints'>
  ) => getDateFromString(timeState.range?.[type]);

  const getDefaultCalendarMonth = (time: TimeState) => {
    const earliest = getDateForRange(time, 'earliest');
    const latest = getDateForRange(time, 'latest');

    if (
      !earliest ||
      !latest ||
      !isAdmin ||
      !isDateInRange(new Date(), earliest, latest)
    )
      return earliest;

    return new Date();
  };

  return { getValue, getPlaceholder, getDateForRange, getDefaultCalendarMonth };
};
