import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { TEXT } from 'config/appColors';
import { TranslatedText } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

const StyledRow = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

const StyledLabel = styled('span')`
  width: 160px;
  padding-right: 10px;
  overflow-wrap: break-word;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.015rem;
  font-weight: 500;
  flex-shrink: 0;
  color: ${TEXT.PRIMARY};
`;

const StyledValue = styled('div')`
  overflow-wrap: break-word;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.015rem;
  font-weight: 400;
  color: ${TEXT.PRIMARY};
`;

interface AttributeRowBaseProps {
  label: string | TranslatedText;
  value: ReactNode;
  className?: string;
}

export const AttributeRowBase: FC<AttributeRowBaseProps> = (props) => {
  const { label, value, className } = props;

  const finalLabel =
    typeof label === 'string' ? label : getTranslatedValue(label);

  const labelIsValid = !!finalLabel;
  const valueIsValidString =
    typeof value === 'string' && value.trim().length > 0;
  const valueIsValidArray = Array.isArray(value) && value.length > 0;
  const valueIsJSX = typeof value !== 'string' && !Array.isArray(value);

  /**
   * If value is more complex (e.g. JSX) validate
   * the value inside particular attribute row component (e.g. AttributeRowScope)
   */
  const showAttributeRow =
    labelIsValid && (valueIsValidString || valueIsValidArray || valueIsJSX);

  if (!showAttributeRow) {
    return null;
  }

  return (
    <StyledRow key={finalLabel} className={className}>
      <StyledLabel className="AttributeRowBase-label">
        {finalLabel}:
      </StyledLabel>
      <StyledValue className="AttributeRowBase-value">{value}</StyledValue>
    </StyledRow>
  );
};
