import { styled, SxProps } from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import BaseModal from 'components/atoms/BaseModal';
import { Select } from 'components/atoms/Select';
import { TextField } from 'components/atoms/TextField';
import { AlertList } from 'components/molecules/AlertList';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { ARGENT } from 'config/appColors';
import { DocumentDefinition } from 'types';
import { getLanguagesForDocDefinition } from 'utils/getLanguagesForDocDefinition';
import { removeSpacesFromString } from 'utils/removeSpacesFromString';

interface SelectFileDetailsProps {
  docDefinition: DocumentDefinition;
  language: string;
  displayName: string;
  handleLanguageChange: (language: string) => void;
  handleDisplayNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleClose: () => void;
  openFileBrowser: () => void;
  errors: string[];
  isLoading?: boolean;
}

const StyledBaseModal = styled(BaseModal)`
  .MuiDialog-paper {
    width: 450px;
  }

  .MuiDialogContent-root {
    min-height: 180px;
    padding: 0;
    border-bottom: none;
  }

  .MuiDialogActions-root {
    border-top: 1px ${ARGENT} solid;
    padding-top: 16px;
  }
`;
const StyledContainer = styled('div')`
  width: 100%;
  margin: 0 50px;
  padding: 10px 0 30px;
  font-weight: 400;
  letter-spacing: 0.015rem;
`;
const StyledInputDescription = styled('p')`
  margin-bottom: 5px;
`;
const StyledSelect = styled(Select)`
  height: 40px;
`;

const menuSx: SxProps = {
  sx: { maxHeight: '320px' },
};

export const SelectFileDetailsModal: FC<SelectFileDetailsProps> = (props) => {
  const {
    docDefinition,
    language,
    displayName,
    handleLanguageChange,
    handleDisplayNameChange,
    handleClose,
    openFileBrowser,
    errors,
    isLoading = false,
  } = props;
  const [t] = useTranslation('auditDraft');
  const MIN_NAME_CHARS = 5;
  const MAX_NAME_CHARS = 50;
  const {
    type: { name: fileType },
    isNameRequired,
    languageSensitive,
  } = docDefinition;
  const options = getLanguagesForDocDefinition(docDefinition);
  const getTitle = () =>
    t('Upload') +
    (fileType ? `: ${fileType}` : ` ${t('file', { ns: 'components' })}`);
  const isLanguageValid = languageSensitive ? !!language : true;
  const isDisplayNameValid = isNameRequired
    ? removeSpacesFromString(displayName).length >= MIN_NAME_CHARS &&
      displayName.length <= MAX_NAME_CHARS
    : true;

  const disableNext = !isLanguageValid || !isDisplayNameValid;

  return (
    <StyledBaseModal open>
      <TemplateAuditModal
        title={getTitle()}
        contentComponent={
          <StyledContainer>
            {errors.length > 0 && (
              <AlertList alerts={errors} noAlertsTranslation />
            )}
            {isNameRequired && (
              <>
                <StyledInputDescription>
                  {t('Please add the name of the file.')}
                </StyledInputDescription>
                <TextField
                  fullWidth
                  helperText={t('min. {{min}} and max. {{max}} characters', {
                    min: MIN_NAME_CHARS,
                    max: MAX_NAME_CHARS,
                  })}
                  value={displayName}
                  onChange={handleDisplayNameChange}
                  inputProps={{ maxLength: MAX_NAME_CHARS }}
                />
              </>
            )}
            {languageSensitive && (
              <>
                <StyledInputDescription>
                  {t('Please select language of the file.')}
                </StyledInputDescription>
                <StyledSelect
                  fullWidth
                  placeholder={t('Please select a language.')}
                  MenuProps={menuSx}
                  options={options}
                  value={language}
                  onChange={(ev) =>
                    handleLanguageChange(ev.target.value as string)
                  }
                />
              </>
            )}
          </StyledContainer>
        }
        closeAction={handleClose}
        nextAction={openFileBrowser}
        disableNext={disableNext}
        noPrevArrow
        noNextArrow
        nextColor="info"
        nextLabel={t('Continue', { ns: 'components' })}
        nextButtonLoading={isLoading}
      />
    </StyledBaseModal>
  );
};
