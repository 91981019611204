import routes from 'config/routes';

import { UUID_V4 } from '../regex/uuid';

interface GetAuditFileUploadPathnameParams {
  supplierId: string;
  auditId?: string;
}

export const getAuditFileUploadPathname = ({
  supplierId,
  auditId,
}: GetAuditFileUploadPathnameParams): string => {
  const isAmendment = !!auditId;
  let pathname = isAmendment
    ? routes.AUDIT_FILE_UPLOAD_AMENDMENT_UPLOAD
    : routes.AUDIT_FILE_UPLOAD_REGULAR_UPLOAD;
  pathname = pathname.replace(`:supplierId(${UUID_V4})`, supplierId);

  if (isAmendment) {
    pathname = pathname.replace(`:auditId(${UUID_V4})`, auditId);
  }

  return pathname;
};
