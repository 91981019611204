import { FC } from 'react';

interface LoggerProps {
  message: string;
}

export const Logger: FC<LoggerProps> = ({ message }) => {
  console.warn(message);
  return null;
};
