import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import { AlertVariantEnum } from 'types';
import { renderDateRange } from 'utils/dates';

import { useRemoveAuditMutation } from '../../../state/additionalAuditManage/api';
import { selectCertificateDetailsAuditToRemove } from '../../../state/modal/slice';
import { CertificateModalCommonProps } from '../../../types';
import {
  CertificateDetailsModalBase,
  NextAction,
} from '../../templates/CertificateDetailsModalBase';

type RemoveAuditModalProps = CertificateModalCommonProps;

export const RemoveAuditModal: FC<RemoveAuditModalProps> = ({
  certificateUuid,
}) => {
  const [t] = useTranslation('certificateDetails');

  const { removeAudit, isLoading, error } = useRemoveAuditMutation();
  const auditToRemove = useSelector(selectCertificateDetailsAuditToRemove);

  const handleRemoveClick: NextAction = (openSnackbar, closeModal) => {
    if (auditToRemove) {
      removeAudit({
        certificateUuid,
        auditUuid: auditToRemove.uuid,
      }).then(() => {
        openSnackbar(t('Audit/Assessment is removed.'), 'success');
        closeModal();
      });
    }
  };
  const { auditTimeframe, auid = '', module } = auditToRemove || {};

  const formattedTimeframe = auditTimeframe
    ? ` (${renderDateRange(auditTimeframe.startDate, auditTimeframe.endDate)})`
    : '';
  const description = `${auid} ${
    module?.name ?? ''
  }${formattedTimeframe}`.trim();

  return (
    <CertificateDetailsModalBase
      title={t('Remove Audit/Assessment')}
      nextColor="error"
      prevLabel={t('Cancel', { ns: 'components' })}
      nextLabel={t('Remove')}
      nextAction={handleRemoveClick}
      isLoading={isLoading}
    >
      <StyledBaseModalContent
        alertsState={{ alerts: error || [], variant: AlertVariantEnum.ERROR }}
      >
        <p>
          {t(
            'Do you want to remove the following Audit/Assessment from the group certificate?'
          )}
        </p>
        {description && <p>{description}</p>}
      </StyledBaseModalContent>
    </CertificateDetailsModalBase>
  );
};
