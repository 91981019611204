import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { AuditAppointmentOptionsResponse } from './types';
import { endpoints } from '../../config/endpoints';
import { AuditDraftCommonRequestParams } from '../../types';

const auditAppointmentOptionsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditDraftAuditAppointmentOptions: build.query<
      AuditAppointmentOptionsResponse,
      string
    >({
      query: (auditDraftId) => ({
        url: endpoints.APPOINTMENTS(auditDraftId),
      }),
      providesTags: ['AUDIT_DRAFT_AUDIT_APPOINTMENT_OPTIONS'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const useGetAuditAppointmentOptionsQuery = (
  params: AuditDraftCommonRequestParams
) => {
  const query =
    auditAppointmentOptionsApi.useGetAuditDraftAuditAppointmentOptionsQuery(
      params.auditDraftId
    );
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  return {
    ...query,
    isMandatory: query.data?.isMandatory || false,
    appointments: query.data?.possibleAppointments || [],
    error,
    status,
  };
};
