import { styled } from '@mui/material';
import React, { FC } from 'react';

import { ImageLoader } from 'components/atoms/ImageLoader';
import { Logger } from 'components/atoms/Logger';
import { AttributeTable } from 'components/molecules/AttributeTable';
import { InfoBox, InfoBoxTypeEnum, TemplateCmpTypeEnum } from 'types';
import { checkIfEnumValueExists } from 'utils/checkIfEnumValueExists';
import { generateId } from 'utils/generateId';
import { getModuleLogoSrc } from 'utils/getModuleLogoSrc';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useGetCertificateDetailsQuery } from '../../../state/certificateDetails/api';
import { useGetCertificateDetailsDocumentsQuery } from '../../../state/certificateDocuments/api';
import { CertificateDetailsTab } from '../../../types';
import { StyledHeading } from '../../atoms/StyledHeading';
import { CertificateDetailsBanner } from '../../molecules/CertificateDetailsBanner';
import { FileDetailsTable } from '../FileDetailsTable';

const StyledContent = styled('div')`
  position: relative;
`;

const StyledImageWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledCertificateDocumentsWrapper = styled('div')`
  margin-top: 20px;
`;

type CertificateTabProps = CertificateDetailsTab['props'] & {
  certificateUuid: string;
  children: CertificateDetailsTab['children'];
};

export const CertificateTab: FC<CertificateTabProps> = (props) => {
  const {
    certificateUuid,
    headerTextDetails,
    headerTextDocuments,
    children,
    logoResolver,
  } = props;
  const { certificateDetails } = useGetCertificateDetailsQuery({
    certificateUuid,
    // certificateVersion: 2 // TODO: it's going to be handled in the future
  });

  const {
    certificateDocuments,
    error,
    status: certificateDocumentsStatus,
  } = useGetCertificateDetailsDocumentsQuery({ certificateUuid });

  const attributeTableData = { certificate: certificateDetails };

  return (
    <>
      {children.map((childConfig, index) => {
        if (checkIfEnumValueExists(InfoBoxTypeEnum, childConfig.type)) {
          return (
            <CertificateDetailsBanner
              key={childConfig.type}
              certificateUuid={certificateUuid}
              config={childConfig as InfoBox}
            />
          );
        }

        if (childConfig.type === TemplateCmpTypeEnum.ATTRIBUTE_TABLE) {
          return (
            <StyledContent key={childConfig.type}>
              <StyledHeading variant="h3">
                {getTranslatedValue(headerTextDetails)}
              </StyledHeading>
              <StyledImageWrapper>
                {certificateDetails && (
                  <ImageLoader
                    src={getModuleLogoSrc(certificateDetails, logoResolver)}
                    width="150px"
                    height="100px"
                  />
                )}
              </StyledImageWrapper>
              <AttributeTable
                data={attributeTableData}
                rows={childConfig.children}
              />
            </StyledContent>
          );
        }
        /**
         * we don't expect other types of children here;
         * however once we have additional types we need to handle them here
         */
        return (
          <Logger
            key={generateId(childConfig.type, index)}
            message={`Unsupported child type - ${childConfig.type}.`}
          />
        );
      })}
      {certificateDetails && (
        <StyledCertificateDocumentsWrapper>
          <StyledHeading variant="h3">
            {getTranslatedValue(headerTextDocuments)}
          </StyledHeading>
          <FileDetailsTable
            certificateUuid={certificateDetails.uuid}
            documents={certificateDocuments}
            loadingStatus={certificateDocumentsStatus}
            error={error}
          />
        </StyledCertificateDocumentsWrapper>
      )}
    </>
  );
};
