import { styled } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import React, { FC, ReactElement } from 'react';

const StyledHeading = styled(Typography)`
  &.MuiTypography-h1 {
    font-size: 2.6rem;
  }

  &.MuiTypography-h2 {
    font-size: 2.1rem;
  }

  &.MuiTypography-h3 {
    font-size: 1.8rem;
  }

  &.MuiTypography-h4 {
    font-size: 1.6rem;
  }

  &.MuiTypography-h5 {
    font-size: 1.5rem;
  }

  &.MuiTypography-h6 {
    font-size: 1.4rem;
  }
`;

export const Heading: FC<TypographyProps> = (props): ReactElement => (
  <StyledHeading {...props} />
);

export default Heading;
