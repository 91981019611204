/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import endpoints from 'domains/user/config/endpoints';
import { MOCK_API_PREFIX, forceMockHeader as mockHeader } from 'mock/constants';
import { AppDispatch, RootState } from 'store';
import isIframe from 'utils/isIframe';

import { refreshTokenInstance } from './axiosInstance';
import { nonAuthEndpoints } from '../../config/nonAuthEndpoints';
import {
  loginWithAccessToken,
  selectAccessToken,
} from '../../domains/user/state/auth/slice';
import { WidgetErrorTypeEnum } from '../../domains/widgets/components/organisms/WidgetErrorScreen/types';
import { AccessToken } from '../accessToken';
import { trigger } from '../customEvents';
import {
  LOGOUT_EVENT,
  REDIRECT_TO_IFRAME_ERROR_EVENT,
} from '../customEvents/eventNames';
import { isAnyMock, isFullMock } from '../environments';
import { injectBaseMockUrl } from '../injectMockUrl';
import { LangHandler } from '../langHandler';

export const requestInterceptor = (store: ToolkitStore<RootState>) => ({
  success: async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const state = store.getState();
    let token = selectAccessToken(state);
    token = AccessToken.isAlive(token) ? token : '';
    const { url = '', headers = {}, baseURL } = request;
    const endpointRequiresToken = !nonAuthEndpoints.includes(url);
    const forceMockHeader = Object.keys(mockHeader)[0];
    const hasForceMockHeader = !!headers[forceMockHeader];
    const forceMock = isFullMock || (isAnyMock && hasForceMockHeader);
    const { getTextWithLang } = LangHandler;
    let refreshTokenEndpoint = getTextWithLang(endpoints.AUTH.REFRESH_TOKEN);

    if (forceMock) {
      request.baseURL = baseURL?.replace('api', MOCK_API_PREFIX);
    } else if (hasForceMockHeader) {
      // remove mock header for production
      delete headers[forceMockHeader];
    }

    if (isFullMock) {
      // mocked refresh token only for full mock env
      refreshTokenEndpoint = injectBaseMockUrl(refreshTokenEndpoint);
    }

    if (endpointRequiresToken) {
      if (!token) {
        try {
          const response = await refreshTokenInstance.post(
            refreshTokenEndpoint
          );
          token = response?.data?.jwtToken;
          if (token) {
            const dispatch = store.dispatch as AppDispatch;
            dispatch(loginWithAccessToken(token));
          }
        } catch (error) {
          trigger(isIframe ? REDIRECT_TO_IFRAME_ERROR_EVENT : LOGOUT_EVENT, {
            ...(isIframe && { type: WidgetErrorTypeEnum.SESSION_EXPIRED }),
          });
          return Promise.reject(new Error());
        }
      }
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    request.url = getTextWithLang(url);

    return request;
  },
  reject: (err: AxiosError): Promise<AxiosError> => Promise.reject(err),
});

export const responseInterceptor = {
  success: (success: AxiosResponse): AxiosResponse => success,
  reject: (error: AxiosError): Promise<AxiosError> => {
    const errorStatus = error?.response?.status;
    const responseURL = error?.response?.request?.responseURL;

    if (errorStatus === 401 && !responseURL.includes(endpoints.AUTH.LOGIN)) {
      trigger(isIframe ? REDIRECT_TO_IFRAME_ERROR_EVENT : LOGOUT_EVENT);
    }

    return Promise.reject(error);
  },
};

export const yiiRequestInterceptor = (store: ToolkitStore<RootState>) => ({
  success: async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const state = store.getState();
    let token = selectAccessToken(state);
    token = AccessToken.isAlive(token) ? token : '';
    const { headers = {}, baseURL } = request;
    const { getTextWithLang } = LangHandler;
    let refreshTokenEndpoint = getTextWithLang(endpoints.AUTH.REFRESH_TOKEN);

    if (isFullMock) {
      // mocked refresh token only for full mock env
      refreshTokenEndpoint = injectBaseMockUrl(refreshTokenEndpoint);
    }

    const forceMockHeader = Object.keys(mockHeader)[0];
    const hasForceMockHeader = !!headers[forceMockHeader];
    const forceMock = isFullMock || (isAnyMock && hasForceMockHeader);

    if (forceMock) {
      request.baseURL = `${baseURL}/${MOCK_API_PREFIX}`;
    } else if (hasForceMockHeader) {
      // remove mock header for production
      delete headers[forceMockHeader];
    }

    if (!token) {
      try {
        const response = await refreshTokenInstance.post(refreshTokenEndpoint);
        token = response?.data?.jwtToken;
        if (token) {
          const dispatch = store.dispatch as AppDispatch;
          dispatch(loginWithAccessToken(token));
        }
      } catch (error) {
        trigger(isIframe ? REDIRECT_TO_IFRAME_ERROR_EVENT : LOGOUT_EVENT, {
          ...(isIframe && { type: WidgetErrorTypeEnum.SESSION_EXPIRED }),
        });
        return Promise.reject(new Error());
      }
    }
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token}`,
    };

    return request;
  },
  reject: (err: AxiosError): Promise<AxiosError> => Promise.reject(err),
});
