import { MouseEvent as ReactMouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import platformRoutes from 'config/routes';
import { DiaryAuditAppointment } from 'domains/auditAppointment/types';
import iframeRoutes from 'domains/widgets/config/routes';
import isIframe from 'utils/isIframe';
import { UUID_V4 } from 'utils/regex/uuid';

type UseAuditAppointmentDetailsLinkType = (
  event: ReactMouseEvent<HTMLTableRowElement, MouseEvent>,
  row: DiaryAuditAppointment
) => void;

export const useAuditAppointmentDetailsLink =
  (): UseAuditAppointmentDetailsLinkType => {
    const { push } = useHistory();
    const routes = isIframe ? iframeRoutes : platformRoutes;

    return (
      event: ReactMouseEvent<HTMLTableRowElement, MouseEvent>,
      row: DiaryAuditAppointment
    ) => {
      const pathname = routes.AUDIT_APPOINTMENT_DETAILS.replace(
        `:supplierId(${UUID_V4})`,
        row.supplier.uuid
      ).replace(`:auditAppointmentId`, row.uuid);
      push({ pathname, state: { showBackButton: true } });
    };
  };
