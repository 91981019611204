import { Nullable } from 'types';

import { Release } from '../../types';

export enum AuditDetailsModalEnum {
  CREATE_RELEASE = 'create-release',
  DELETE_AUDIT = 'delete-audit',
  DELETE_RELEASE = 'delete-release',
  ISSUE_GROUP_CERTIFICATE = 'issue-group-certificate',
  UPDATE_RELEASE = 'update-release',
  SWITCH_CB_MODAL = 'switch-cb-modal',
}

export interface AuditDetailsModalState {
  openModalName: Nullable<AuditDetailsModalEnum>;
  selectedRelease: Nullable<Release>;
}

export interface OpenAuditModalPayload {
  name: AuditDetailsModalState['openModalName'];
}

export interface AuditModalCommonProps {
  auditUuid: string;
  supplierId: string;
}
