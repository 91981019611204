import { omit } from 'lodash';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { forceMockHeader } from 'mock/constants';
import { apiSlice } from 'store/apiSlice';

import {
  AdditionalAuditListParams,
  AdditionalAuditListResponse,
  AdditionalSupplierListParams,
  AdditionalSupplierListResponse,
} from './types';
import endpoints from '../../config/endpoints';

const certificateDetailsGroupCertificateTabApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateAdditionalSupplierList: build.query<
      AdditionalSupplierListResponse,
      AdditionalSupplierListParams
    >({
      query: (params) => ({
        url: endpoints.GROUP_CERTIFICATE.ADDITIONAL_SUPPLIER.LIST(
          params.certificateUuid
        ),
        headers: forceMockHeader,
        params: omit(params, ['certificateUuid']),
      }),
      providesTags: ['CERTIFICATE_DETAILS_ADDITIONAL_SUPPLIER_LIST'],
      keepUnusedDataFor: 10,
    }),
    getCertificateAdditionalAuditList: build.query<
      AdditionalAuditListResponse,
      AdditionalAuditListParams
    >({
      query: (params) => ({
        url: endpoints.GROUP_CERTIFICATE.ADDITIONAL_AUDIT.LIST(
          params.certificateUuid
        ),
        headers: forceMockHeader,
        params: omit(params, ['certificateUuid']),
      }),
      providesTags: ['CERTIFICATE_DETAILS_ADDITIONAL_AUDIT_LIST'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetCertificateAdditionalSupplierListQuery = (
  params: AdditionalSupplierListParams
) => {
  const query =
    certificateDetailsGroupCertificateTabApi.useGetCertificateAdditionalSupplierListQuery(
      params
    );
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    suppliers: query.data?.suppliers || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};

export const useGetCertificateAdditionalAuditListQuery = (
  params: AdditionalAuditListParams
) => {
  const query =
    certificateDetailsGroupCertificateTabApi.useGetCertificateAdditionalAuditListQuery(
      params
    );
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    audits: query.data?.audits || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};
