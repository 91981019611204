import { useHistory } from 'react-router-dom';

import { CERTIFICATES_CONFIRMATIONS_TAB_SLUG } from 'config/constants';
import { getSupplierPathname as YII_getSupplierPathname } from 'domains/widgets/utils/getSupplierPathname';
import { useAbortModal } from 'hooks/useAbortModal';
import { getSupplierPathname } from 'utils/getSupplierPathname';
import isIframe from 'utils/isIframe';

interface UseCertificateDraftAbortModal {
  isAbortModalOpen: boolean;
  openAbortModal: () => void;
  closeAbortModal: () => void;
  handleAbortAction: () => void;
}

export const useCertificateDraftAbortModal = (
  supplierUuid = ''
): UseCertificateDraftAbortModal => {
  const { push, location } = useHistory();

  const handleAbortAction = () => {
    // TODO: once we get rid of iframe things ternary&YII_getSupplierPathname should be removed
    const pathname = isIframe
      ? YII_getSupplierPathname(supplierUuid, 'CERTIFICATES')
      : getSupplierPathname(supplierUuid, CERTIFICATES_CONFIRMATIONS_TAB_SLUG);

    push(pathname, location.state);
  };

  return {
    handleAbortAction,
    ...useAbortModal(),
  };
};
