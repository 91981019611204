import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { DocumentDefinitionsResponse } from 'types';

import { DocumentDefinitionsParams } from './types';
import { endpoints } from '../../config/endpoints';

const documentDefinitionsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditDraftDocumentDefinitions: build.query<
      DocumentDefinitionsResponse,
      DocumentDefinitionsParams
    >({
      query: ({ auditDraftId }) => ({
        url: endpoints.DOCUMENT_DEFINITIONS(auditDraftId),
      }),
      providesTags: ['AUDIT_DRAFT_DOCUMENT_DEFINITIONS'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const useGetAuditDraftDocumentDefinitionsQuery = (
  params: DocumentDefinitionsParams
) => {
  const query =
    documentDefinitionsApi.useGetAuditDraftDocumentDefinitionsQuery(params);
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query, 'formatToAlerts');

  return {
    ...query,
    allRequiredDocumentsFulfilled:
      query.data?.allRequiredDocumentsFulfilled || false,
    documentDefinitions: query.data?.documentDefinitions || [],
    error,
    status,
  };
};
