import { Divider, SelectChangeEvent, styled } from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';

import { AlertList } from 'components/molecules/AlertList';
import { MODAL_HEADER_HEIGHT } from 'components/molecules/ModalHeader';
import { ActionInformationSection } from 'components/organisms/ActionInformationSection';
import { HEADER_HEIGHT } from 'components/templates/TemplatePageDefault';
import { AlertsState, GroupedOption, Nullable, SelectOption } from 'types';
import { isActionCommentFieldValid } from 'utils/validation/isActionCommentFieldValid';

interface BaseModalContentProps {
  adminReasonOptions?: (SelectOption | GroupedOption)[];
  setIsAdminInfoValid?: Dispatch<SetStateAction<boolean>>;
  alertsState: Nullable<AlertsState>;
  children: ReactNode;
  adminReason?: string;
  adminComment?: string;
  adminReasonChangeHandler?: (event: SelectChangeEvent<unknown>) => void;
  adminCommentChangeHandler?: (comment: string) => void;
  adminSendEmailValue?: boolean;
  adminSendEmailChangeHandler?: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  isAdmin?: boolean;
  adminCommentHeading?: string;
  adminCommentRows?: string | number;
  className?: string;
}

const CONTAINER_HORIZONTAL_PADDING = 48;

const StyledContentContainer = styled('div')`
  width: 100%;
  padding: 30px ${CONTAINER_HORIZONTAL_PADDING}px;
  max-height: calc(
    100vh - ${HEADER_HEIGHT}px - ${MODAL_HEADER_HEIGHT}px - 68px - 64px
  ); // 68px - action buttons, 64px - margins between modal
  overflow-x: hidden;
`;

const StyledDivider = styled(Divider)`
  width: calc(100% + ${CONTAINER_HORIZONTAL_PADDING * 2}px);
  margin-left: -${CONTAINER_HORIZONTAL_PADDING}px;
  margin-top: 10px;
`;

export const BaseModalContent: FC<BaseModalContentProps> = (props) => {
  const {
    adminReasonOptions,
    setIsAdminInfoValid,
    alertsState,
    children,
    adminReason = '',
    adminComment = '',
    adminCommentChangeHandler,
    adminReasonChangeHandler,
    adminSendEmailValue,
    adminSendEmailChangeHandler,
    isAdmin = false,
    adminCommentHeading,
    adminCommentRows,
    className,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const showAlerts = alertsState && alertsState.alerts.length > 0;

  useEffect(() => {
    if (setIsAdminInfoValid) {
      setIsAdminInfoValid(
        isActionCommentFieldValid(adminReason, adminComment || null)
      );
    }
  }, [adminReason, adminComment, setIsAdminInfoValid]);

  useEffect(() => {
    const alertsAreNotEmpty =
      alertsState?.alerts && alertsState.alerts.length > 0;

    if (alertsAreNotEmpty && containerRef.current)
      containerRef.current.scrollTop = 0;
  }, [alertsState]);

  return (
    <StyledContentContainer className={className} ref={containerRef}>
      <div>
        {showAlerts && (
          <AlertList
            variant={alertsState?.variant}
            alerts={alertsState.alerts}
          />
        )}
        {children}
      </div>
      {isAdmin &&
        adminCommentChangeHandler &&
        adminReasonChangeHandler &&
        adminReasonOptions && (
          <>
            <StyledDivider />
            <ActionInformationSection
              heading={adminCommentHeading}
              adminReason={adminReason}
              adminComment={adminComment}
              handleAdminReasonChange={adminReasonChangeHandler}
              handleAdminCommentChange={adminCommentChangeHandler}
              handleSendMailChange={adminSendEmailChangeHandler}
              adminReasonOptions={adminReasonOptions}
              sendMail={adminSendEmailValue}
              textareaRows={adminCommentRows}
            />
          </>
        )}
    </StyledContentContainer>
  );
};
