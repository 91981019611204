import { TranslatedText } from '../../types';
import { getTranslatedValue } from '../getTranslatedValue';

type Template = {
  props: {
    pageHeader: TranslatedText;
  };
};

export const getTemplatePageHeader = (template?: Template): string =>
  getTranslatedValue(template?.props?.pageHeader);
