import { StepperStepType } from 'components/atoms/Step';
import { ButtonColorType } from 'types';

export interface ButtonsConfig {
  prevLabel: string;
  nextLabel: string;
  disableNext: boolean;
  disablePrev: boolean;
  prevColor: ButtonColorType;
  nextColor: ButtonColorType;
}

export interface AuditPlanStep {
  id: AuditAppointmentStepEnum;
  label: string;
  value?: string;
}

export interface AuditPlanStepperFormHook {
  showStepper: boolean;
  currentStep: AuditAppointmentStepEnum;
  auditPlanSteps: AuditPlanStep[];
  buttonsConfig: ButtonsConfig;
  isAbortModalOpen: boolean;
  openAbortModal: () => void;
  closeAbortModal: () => void;
  isModalOpen: boolean;
  nextAction: () => void;
  prevAction: () => void;
  handleStepClick: (step: StepperStepType) => void;
}

export enum TimeSelectPlaceholderEnum {
  SELECT,
  CHECKING,
  CALCULATING,
}

export enum AuditAppointmentStepEnum {
  MODULE = 'module',
  TYPE = 'type',
  KIND = 'kind',
  TIME = 'time',
  EXECUTION_MODE = 'executionMode',
  DESCRIPTION = 'description',
  SUMMARY = 'summary',
}

export interface DiaryExportParams {
  onExportErrorCallback: () => void;
}
