import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Column,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { TranslatedText } from 'types';
import { renderDateRange } from 'utils/dates';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { AdditionalAudit } from '../../../state/additionalAuditManage/types';
import { useGetCertificateAdditionalAuditListQuery } from '../../../state/certificateGroupCertificateTab/api';
import { StyledTable } from '../../atoms/StyledAdditionalDataTable';
import { StyledHeading } from '../../atoms/StyledHeading';
import { AdditionalAuditActionsDropdown } from '../../molecules/AdditionalAuditActionsDropdown';

interface AdditionalAuditsTableProps {
  certificateUuid: string;
  headerText: TranslatedText;
}

export const AdditionalAuditsTable: FC<AdditionalAuditsTableProps> = (
  props
) => {
  const { certificateUuid, headerText } = props;
  const [t] = useTranslation('certificateDetails');

  const { pagination, limit, offset, setTotalRowsAmount } = usePagination();

  const { error, audits, total, status } =
    useGetCertificateAdditionalAuditListQuery({
      certificateUuid,
      limit,
      offset,
    });

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const { getColumnConfig } = useColumnConfig('20%');

  const columns: Column<AdditionalAudit>[] = [
    {
      headerName: t('Supplier', { ns: 'auditAppointment' }),
      customCell: ({ supplier: { name, coid } }) => `${name} (COID: ${coid})`,
      ...getColumnConfig('35%'),
    },
    {
      headerName: t('Audit'),
      customCell: ({ auid, module }) => (
        <>
          {auid && `(${auid}) -`} {module.name}
        </>
      ),
      ...getColumnConfig('20%'),
    },
    {
      headerName: t('Audit Timeframe'),
      customCell: ({ auditTimeframe }) =>
        auditTimeframe
          ? renderDateRange(auditTimeframe.startDate, auditTimeframe.endDate)
          : '-',
      ...getColumnConfig(),
    },
    {
      headerName: '',
      customCell: (audit) => (
        <AdditionalAuditActionsDropdown
          audit={audit}
          certificateUuid={certificateUuid}
        />
      ),
      ...getColumnConfig('5%'),
    },
  ];

  return (
    <div data-testid="additional-audits-table">
      <StyledHeading>{getTranslatedValue(headerText)}</StyledHeading>
      <StyledTable
        columns={columns}
        rows={audits}
        status={status}
        error={error}
        pagination={pagination}
        dense
      />
    </div>
  );
};
