import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ErrorState } from 'components/molecules/ErrorState';
import Loader from 'components/molecules/Loader';
import { DraftStatusIndicator } from 'components/organisms/DraftStatusIndicator';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import {
  AUDITS_ASSESSMENTS_TAB_SLUG,
  UNEXPECTED_ERROR_TRY_AGAIN,
} from 'config/constants';
import { useResetDraftStepper } from 'hooks/useResetDraftStepper';
import { DraftStatusEnum, LoaderStatusEnum } from 'types';
import { getSupplierPathname } from 'utils/getSupplierPathname';

import { MappedAuditDraftSteps } from '../../components/organisms/MappedAuditDraftSteps';
import { useAuditDraftStatusContent } from '../../hooks/useAuditDraftStatusContent';
import { useAuditDraftDetailsQuery } from '../../state/auditDraftDetails/api';
import { useAuditDraftTemplateQuery } from '../../state/auditDraftTemplate/api';
import { setCurrentStep } from '../../state/auditDraftTemplate/slice';
import { AuditDraftURLParams } from '../../types';

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 4rem 10.5rem;
`;

const StyledErrorState = styled(ErrorState)`
  justify-content: flex-start;
`;

export const PageAuditDraftForm: FC = () => {
  const [t] = useTranslation('auditDraft');
  const { auditDraftId } = useParams<AuditDraftURLParams>();
  const {
    auditDraftDetails,
    isLoading: isAuditDraftDetailsLoading,
    isError: isAuditDraftError,
    isSuccess,
  } = useAuditDraftDetailsQuery(auditDraftId);
  const auditDraftStatus = auditDraftDetails?.status.id;
  const skipAuditDraftTemplateQuery =
    !isSuccess || auditDraftStatus !== DraftStatusEnum.UNSUBMITTED;
  const {
    isError: isAuditDraftTemplateError,
    isLoading: isAuditDraftTemplateLoading,
  } = useAuditDraftTemplateQuery(auditDraftId, skipAuditDraftTemplateQuery);
  useResetDraftStepper(setCurrentStep);

  const isLoading = isAuditDraftDetailsLoading || isAuditDraftTemplateLoading;
  const finalStatus = isLoading
    ? LoaderStatusEnum.LOADING
    : LoaderStatusEnum.SUCCESS;

  if (isAuditDraftTemplateError || isAuditDraftError) {
    return (
      <TemplatePageDefault hidePageHeader>
        <StyledErrorState
          heading={t('Failed to load Data.', { ns: 'components' })}
          description={t(UNEXPECTED_ERROR_TRY_AGAIN, { ns: 'common' })}
        />
      </TemplatePageDefault>
    );
  }

  let component = (
    <DraftStatusIndicator
      details={auditDraftDetails}
      useStatusContent={useAuditDraftStatusContent}
      getPathname={(supplierId: string) =>
        getSupplierPathname(supplierId, AUDITS_ASSESSMENTS_TAB_SLUG)
      }
    />
  );

  if (auditDraftStatus === DraftStatusEnum.UNSUBMITTED) {
    component = <MappedAuditDraftSteps auditDraftId={auditDraftId} />;
  }

  return (
    <TemplatePageDefault hidePageHeader>
      <ContentWrapper>
        <Loader status={finalStatus} minHeight="0px">
          {component}
        </Loader>
      </ContentWrapper>
    </TemplatePageDefault>
  );
};
