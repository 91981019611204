import { omit } from 'lodash';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { forceMockHeader } from 'mock/constants';
import { apiSlice } from 'store/apiSlice';
import { LoaderStatusEnum, Supplier } from 'types';
import { invalidateRTKTags } from 'utils/invalidateRTKTags';

import { AdditionalSupplierOptionsParams, SupplierManageParams } from './types';
import endpoints from '../../config/endpoints';

const supplierManageApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    addSupplier: build.mutation<unknown, SupplierManageParams>({
      query({ certificateUuid, supplierUuid }) {
        return {
          url: endpoints.GROUP_CERTIFICATE.ADDITIONAL_SUPPLIER.ADD(
            certificateUuid
          ),
          headers: forceMockHeader,
          method: 'PUT',
          data: {
            supplierId: supplierUuid,
          },
        };
      },
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_DETAILS_ADDITIONAL_SUPPLIER_LIST',
        'CERTIFICATE_DETAILS_ACTIONS',
      ]),
    }),
    removeSupplier: build.mutation<unknown, SupplierManageParams>({
      query({ certificateUuid, supplierUuid }) {
        return {
          url: endpoints.GROUP_CERTIFICATE.ADDITIONAL_SUPPLIER.REMOVE(
            certificateUuid,
            supplierUuid
          ),
          headers: forceMockHeader,
          method: 'DELETE',
        };
      },
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_DETAILS_ADDITIONAL_SUPPLIER_LIST',
        'CERTIFICATE_DETAILS_ACTIONS',
      ]),
    }),
    getAdditionalSupplierOptions: build.query<
      Supplier[],
      AdditionalSupplierOptionsParams
    >({
      query: (params) => ({
        url: endpoints.GROUP_CERTIFICATE.ADDITIONAL_SUPPLIER.OPTIONS(
          params.certificateUuid
        ),
        headers: forceMockHeader,
        params: omit(params, ['certificateUuid']),
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const useAddSupplierMutation = () => {
  const [addSupplier, mutation] = supplierManageApi.useAddSupplierMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: SupplierManageParams) =>
    addSupplier(params).unwrap();

  return {
    ...mutation,
    addSupplier: requestMethod,
    status,
    error,
  };
};

export const useLazyGetAdditionalSupplierOptionsQuery = () => {
  const [trigger, query] =
    supplierManageApi.useLazyGetAdditionalSupplierOptionsQuery();
  const status = useRTKQueryStatus(query);
  const isLoading = status === LoaderStatusEnum.LOADING;

  const requestMethod = async (params: AdditionalSupplierOptionsParams) =>
    trigger(params);

  return {
    searchSupplier: requestMethod,
    suppliers: query.data || [],
    isLoading,
  };
};

export const useRemoveSupplierMutation = () => {
  const [removeSupplier, mutation] =
    supplierManageApi.useRemoveSupplierMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: SupplierManageParams) =>
    removeSupplier(params).unwrap();

  return {
    ...mutation,
    removeSupplier: requestMethod,
    status,
    error,
  };
};
