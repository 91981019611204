import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { TranslatedText, WidgetProcessingStepsGeneralConfig } from 'types';

import type { SaveProcessingStepsParams } from './ProcessingStepsWidget';
import { ProcessingStepsWidget } from './ProcessingStepsWidget';
import { SaveProductScopesParams } from '../WidgetProductScopesGeneral/ProductScopeWidget';

type WidgetProductScopesGeneralProps = {
  data: SaveProcessingStepsParams['processingStepsAudit'];
  element: WidgetProcessingStepsGeneralConfig;
  errors: Record<string, TranslatedText>;
  setData: (
    data: SaveProcessingStepsParams['processingStepsAudit'],
    element: WidgetProcessingStepsGeneralConfig
  ) => void;
  productScopesCertificate?: SaveProductScopesParams['productScopesAudit'];
};

export const WidgetProcessingStepsGeneral: FC<WidgetProductScopesGeneralProps> =
  ({ data, errors, element, setData, productScopesCertificate = [] }) => {
    const { i18n } = useTranslation();
    const { name, scopes, id } = element.props;

    const saveScopes = useCallback(
      ({ processingStepsAudit }: SaveProcessingStepsParams) => {
        setData(processingStepsAudit, element);
      },
      [element, setData]
    );

    return (
      <ProcessingStepsWidget
        errors={errors}
        language={i18n.language}
        saveScopes={saveScopes}
        processingStepsAudit={data}
        productScopesCertificate={productScopesCertificate}
        widget={{
          id,
          text: name,
          scopes,
        }}
      />
    );
  };
