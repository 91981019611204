import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { ActionComment, ActionReasonOption, SelectOption } from 'types';
import { isActionCommentFieldValid } from 'utils/validation/isActionCommentFieldValid';

import { useActionReasonOptions } from './useActionReasonOptions';
import { useActionInformationData } from '../../components/organisms/ActionInformationSection';

interface UseCommentStepProps {
  actionReasonOptions: ActionReasonOption[];
  draftDetails?: {
    comment?: string;
    actionComment?: ActionComment;
  };
}

interface UseCommentStep {
  comment: string;
  adminReason: string;
  adminComment: string;
  handleCommentChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleAdminReasonChange: (event: SelectChangeEvent<unknown>) => void;
  handleAdminCommentChange: (value: string) => void;
  options: SelectOption[];
  disableNextButton: boolean;
  isAdmin: boolean;
}

export const useCommentStep = (props: UseCommentStepProps): UseCommentStep => {
  const { actionReasonOptions, draftDetails } = props;
  const isAdmin = useSelector(selectUserIsAdmin);
  const [isInitialDataSet, setIsInitialDataSet] = useState(false);
  const [comment, setComment] = useState('');

  const {
    adminReason,
    adminComment,
    handleAdminReasonChange,
    handleAdminCommentChange,
  } = useActionInformationData();

  const { options, optionsWithRequiredDescription } =
    useActionReasonOptions(actionReasonOptions);
  const disableNextButton =
    isAdmin &&
    !isActionCommentFieldValid(
      adminReason,
      adminComment,
      optionsWithRequiredDescription
    );

  const handleCommentChange = (ev: ChangeEvent<HTMLTextAreaElement>) =>
    setComment(ev.target.value);

  useEffect(() => {
    if (!isInitialDataSet && draftDetails) {
      // set initial values
      const { comment: existingComment, actionComment } = draftDetails;
      const existingAdminComment = actionComment?.comment;
      const existingAdminReason = actionComment?.reason;

      if (existingComment) setComment(existingComment);
      if (existingAdminComment) handleAdminCommentChange(existingAdminComment);
      if (
        existingAdminReason &&
        actionReasonOptions.find(({ id }) => id === existingAdminReason)
      )
        handleAdminReasonChange(existingAdminReason);

      setIsInitialDataSet(true);
    }
  }, [
    draftDetails,
    actionReasonOptions,
    isInitialDataSet,
    handleAdminReasonChange,
    handleAdminCommentChange,
  ]);

  return {
    comment,
    adminReason,
    adminComment,
    handleCommentChange,
    handleAdminReasonChange,
    handleAdminCommentChange,
    options,
    disableNextButton,
    isAdmin,
  };
};
