import { styled } from '@mui/material';
import { addDays, format, subDays } from 'date-fns';
import { get } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isStartEndDates, Nullable, WithUndefined } from 'types';
import { findDate, formatDate, getDateFromString } from 'utils/dates';

import { StyledSubItemsContainer } from './StyledElements';
import {
  DynamicFormCommonProps,
  DynamicFormItemCommonProps,
  TimeFrameType,
} from './types';
import { DatePicker } from '../../atoms/DatePicker';
import { DynamicFormItemLabel } from '../../atoms/DynamicFormItemLabel';

const StyledContainer = styled('div')`
  && {
    display: flex;
    gap: 20px;
    margin-top: 0;
  }

  & > div {
    width: calc(50% - 10px);
    .MuiFormControl-root {
      width: 100%;
    }
  }
`;

const StyledDynamicFormItemLabel = styled(DynamicFormItemLabel)`
  font-size: 1.4rem;
  padding-bottom: 0;
`;

type TimeFrameProps = TimeFrameType &
  DynamicFormCommonProps &
  DynamicFormItemCommonProps;

export const TimeFrame: FC<TimeFrameProps> = ({
  props,
  setValue,
  values,
  validationState,
  validateFormItem,
  subItems,
}) => {
  const [t] = useTranslation('components');
  const value = get(values, props.dataRef);
  const { dataRef, startSettings, endSettings } = props;
  const [isValidationNeeded, setIsValidationNeeded] =
    useState<Nullable<'start' | 'end'>>(null);

  useEffect(() => {
    if (isValidationNeeded) {
      const valueWithBypass = {
        start: format(new Date(), 'yyyy-MM-dd'),
        end: format(new Date(), 'yyyy-MM-dd'),
        ...(value || {}),
      };
      validateFormItem(valueWithBypass, dataRef);
      setIsValidationNeeded(null);
    }
  }, [isValidationNeeded, value, dataRef, validateFormItem]);

  if (!isStartEndDates(value)) return null;

  const changeDate = (key: string) => (date: Nullable<Date>) => {
    const newValue = {
      ...value,
      [key]: formatDate(date, 'yyyy-MM-dd'),
    };
    setValue(dataRef, newValue);
    validateFormItem(newValue, dataRef);
  };

  const startError = get(
    validationState,
    startSettings.id
  ) as WithUndefined<string>;

  const endError = get(
    validationState,
    endSettings.id
  ) as WithUndefined<string>;

  const endDate = value.end ? subDays(new Date(value.end), 1) : null;
  const startDate = value.start ? addDays(new Date(value.start), 1) : null;

  const maxStartDate = findDate([startSettings.latest, endDate], 'earliest');
  const minEndDate = findDate([endSettings.earliest, startDate], 'latest');

  return (
    <>
      <DynamicFormItemLabel text={props.label} />
      <StyledContainer>
        <div>
          <StyledDynamicFormItemLabel
            text={startSettings.label}
            error={startError}
            hideColon
          />
          <DatePicker
            inputPlaceholder={t('select date')}
            value={value.start}
            onChange={changeDate('start')}
            minDate={getDateFromString(startSettings.earliest)}
            maxDate={maxStartDate}
            onClose={() => setIsValidationNeeded('start')}
          />
        </div>
        <div>
          <StyledDynamicFormItemLabel
            text={endSettings.label}
            error={endError}
            hideColon
          />
          <DatePicker
            inputPlaceholder={t('select date')}
            value={value.end}
            onChange={changeDate('end')}
            minDate={minEndDate}
            maxDate={getDateFromString(endSettings.latest)}
            onClose={() => setIsValidationNeeded('end')}
          />
        </div>
      </StyledContainer>
      {!!subItems && (
        <StyledSubItemsContainer>{subItems}</StyledSubItemsContainer>
      )}
    </>
  );
};
