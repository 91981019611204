import languages from 'assets/languages.json';
import i18next from 'i18n';
import {
  DocumentDefinition,
  LanguageEnum,
  LanguageListIdEnum,
  Option,
} from 'types';

const getLanguageNameUsingISO = (iso: string): string =>
  languages.find((lang) => lang.iso === iso)?.name[
    i18next.language as LanguageEnum
  ] ?? '';

const getLanguageOptionsUsingISOList = (isoList: string[]): Option[] =>
  isoList.map((iso) => ({
    label: getLanguageNameUsingISO(iso),
    value: iso,
  }));

export const getLanguagesForDocDefinition = (
  docDef: DocumentDefinition
): Option[] => {
  if (docDef.languageListId === LanguageListIdEnum.ALL_LANGUAGES) {
    return languages.map((lang) => ({
      label: lang.name[i18next.language as LanguageEnum],
      value: lang.iso,
    }));
  }
  if (docDef.languageListId === LanguageListIdEnum.IFS_BASIC_LANGUAGES) {
    return getLanguageOptionsUsingISOList(Object.values(LanguageEnum));
  }
  if (docDef.languages) {
    return getLanguageOptionsUsingISOList(docDef.languages);
  }

  return [];
};
