import { omit } from 'lodash';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { forceMockHeader } from 'mock/constants';
import { apiSlice } from 'store/apiSlice';
import { LoaderStatusEnum } from 'types';
import { invalidateRTKTags } from 'utils/invalidateRTKTags';

import {
  AdditionalAudit,
  AdditionalAuditManageParams,
  AdditionalAuditOptionsParams,
} from './types';
import endpoints from '../../config/endpoints';

const additionalAuditManageApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    addAdditionalAudit: build.mutation<unknown, AdditionalAuditManageParams>({
      query({ certificateUuid, auditUuid }) {
        return {
          url: endpoints.GROUP_CERTIFICATE.ADDITIONAL_AUDIT.ADD(
            certificateUuid
          ),
          headers: forceMockHeader,
          method: 'PUT',
          data: {
            auditId: auditUuid,
          },
        };
      },
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_DETAILS_ADDITIONAL_AUDIT_LIST',
        'CERTIFICATE_DETAILS_ACTIONS',
      ]),
    }),
    removeAudit: build.mutation<unknown, AdditionalAuditManageParams>({
      query({ certificateUuid, auditUuid }) {
        return {
          url: endpoints.GROUP_CERTIFICATE.ADDITIONAL_AUDIT.REMOVE(
            certificateUuid,
            auditUuid
          ),
          headers: forceMockHeader,
          method: 'DELETE',
        };
      },
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_DETAILS_ADDITIONAL_AUDIT_LIST',
        'CERTIFICATE_DETAILS_ACTIONS',
      ]),
    }),
    getAdditionalAuditOptions: build.query<
      AdditionalAudit[],
      AdditionalAuditOptionsParams
    >({
      query: (params) => ({
        url: endpoints.GROUP_CERTIFICATE.ADDITIONAL_AUDIT.OPTIONS(
          params.certificateUuid
        ),
        headers: forceMockHeader,
        params: omit(params, ['certificateUuid']),
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const useAddAdditionalAuditMutation = () => {
  const [addAdditionalAudit, mutation] =
    additionalAuditManageApi.useAddAdditionalAuditMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: AdditionalAuditManageParams) =>
    addAdditionalAudit(params).unwrap();

  return {
    ...mutation,
    addAdditionalAudit: requestMethod,
    status,
    error,
  };
};

export const useLazyGetAdditionalAuditOptionsQuery = () => {
  const [trigger, query] =
    additionalAuditManageApi.useLazyGetAdditionalAuditOptionsQuery();
  const status = useRTKQueryStatus(query);
  const isLoading = status === LoaderStatusEnum.LOADING;

  const requestMethod = async (params: AdditionalAuditOptionsParams) =>
    trigger(params);

  return {
    searchAudit: requestMethod,
    audits: query.data || [],
    isLoading,
  };
};

export const useRemoveAuditMutation = () => {
  const [removeAudit, mutation] =
    additionalAuditManageApi.useRemoveAuditMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: AdditionalAuditManageParams) =>
    removeAudit(params).unwrap();

  return {
    ...mutation,
    removeAudit: requestMethod,
    status,
    error,
  };
};
