import React, { FC } from 'react';

import { ConditionalCancelAuditAppointmentModal } from './ConditionalCancelAuditAppointmentModal';
import { ConditionalReactivateAuditAppointmentModal } from './ConditionalReactivateAuditAppointmentModal';
import { ConditionalResetDueDateModal } from './ConditionalResetDueDateModal';
import { ConditionalSetDueDateModal } from './ConditionalSetDueDateModal';
import { ConditionalUpdateDescriptionModal } from './ConditionalUpdateDescriptionModal';

interface ConditionalModalsProps {
  appointmentId: string;
}

export const ConditionalModals: FC<ConditionalModalsProps> = ({
  appointmentId,
}) => (
  <>
    <ConditionalSetDueDateModal appointmentId={appointmentId} />
    <ConditionalResetDueDateModal appointmentId={appointmentId} />
    <ConditionalCancelAuditAppointmentModal appointmentId={appointmentId} />
    <ConditionalUpdateDescriptionModal appointmentId={appointmentId} />
    <ConditionalReactivateAuditAppointmentModal appointmentId={appointmentId} />
  </>
);
