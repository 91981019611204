import { format as dfnsFormat } from 'date-fns';

import { DATE_FORMAT } from 'config/constants';
import { Nullable } from 'types';
import { addHourToDate, extractDate } from 'utils/dates';

/**
 * AFTER ANY CHANGE HERE PLEASE CHECK DISPLAYING DATES FOR DIFFERENT TIMEZONES
 * TO CHANGE TIMEZONE YOU CAN USE SENSORS TAB IN CHROME BROWSER
 */
export const formatDate = (
  date?: Nullable<string | Date>,
  format = DATE_FORMAT
): string => {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    const extractedDate = extractDate(date);
    const dateParsed = Date.parse(addHourToDate(extractedDate));
    if (Number.isNaN(dateParsed)) {
      return '';
    }
    return dfnsFormat(dateParsed, format); // if type is string, parse date and then format it
  }

  return dfnsFormat(date, format); // if type is date, format it
};
