import routes from 'config/routes';
import iframeRoutes from 'domains/widgets/config/routes';
import isIframe from 'utils/isIframe';

import { UUID_V4 } from '../regex/uuid';

export const getAuditDetailsPathname = (
  supplierId: string,
  auditUuid: string,
  tabName?: string
): string => {
  const finalRoutes = isIframe ? iframeRoutes : routes;
  let pathname = finalRoutes.AUDIT_DETAILS.replace(
    `:supplierId(${UUID_V4})`,
    supplierId
  ).replace(isIframe ? `:auditUuid` : `:auditUuid(${UUID_V4})`, auditUuid);

  if (tabName) {
    pathname = `${pathname}?tab=${tabName}`;
  }

  return pathname;
};
