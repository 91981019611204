import React, { FC } from 'react';

import { DiaryAuditAppointmentsFilters } from '../DiaryAuditAppointmentsFilters';
import { DiaryAuditAppointmentsTable } from '../DiaryAuditAppointmentsTable';

export const DiaryAuditAppointmentsTab: FC = () => (
  <>
    <DiaryAuditAppointmentsFilters />
    <DiaryAuditAppointmentsTable />
  </>
);
