import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { routesWithLangRegex } from 'config/routes';

const map = {
  [routesWithLangRegex.SUPPLIER_OVERVIEW]: 'Supplier Overview',
};

const DynamicBreadcrumb: FC<{ match: { path: string } }> = ({
  match: { path },
}) => {
  const [t] = useTranslation('components');

  return <>{t(map[path])}</>;
};

export default DynamicBreadcrumb;
