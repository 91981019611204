import { PartialThunkApi } from 'types';

import { CreateCommonParams } from './types';
import { AuditAppointmentForm } from '../create/types';

export const getSupplierIdAndCertificationBodyId = (
  thunkApi: PartialThunkApi
): CreateCommonParams => {
  const state = thunkApi.getState();
  const supplierId = state.supplier.details.details?.uuid;
  const { value: customCertificationBody } = state.certificationBody.search;
  const userCertificationBodyId =
    state.user.auth.userDetails?.currentContext.companyId;

  return {
    supplierId,
    certificationBodyId:
      customCertificationBody?.uuid || userCertificationBodyId,
  };
};

export const getCreateValues = (
  thunkApi: PartialThunkApi
): AuditAppointmentForm => thunkApi.getState().auditAppointment.create.values;
