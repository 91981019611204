import { useTranslation } from 'react-i18next';

import {
  AlertVariantEnum,
  DraftStatus,
  DraftStatusContent,
  DraftStatusEnum,
  Nullable,
} from 'types';

export const useCertificateDraftStatusContent = (
  draftStatus?: DraftStatus
): Nullable<DraftStatusContent> => {
  const [t] = useTranslation('certificateDraft');
  const headerTitlePrefix = t('Create Certificate');

  switch (draftStatus?.id) {
    case DraftStatusEnum.CANCELLED: {
      return {
        headerTitlePrefix,
        title: draftStatus.name,
        status: AlertVariantEnum.WARNING,
        label: t('The Process was cancelled', { ns: 'auditDraft' }), // @ToDo - currently 'WIP' in docs
      };
    }
    case DraftStatusEnum.SUBMITTED: {
      return {
        headerTitlePrefix,
        title: draftStatus.name,
        status: AlertVariantEnum.SUCCESS,
        label: t(
          'The data is successfully submitted and the system will analyse the data and create the related entries in the list. This might take a couple of minutes. You can close now the process.'
        ),
      };
    }
    case DraftStatusEnum.UNSUBMITTED: {
      return null;
    }
    default: {
      return {
        headerTitlePrefix,
        title: '',
        status: AlertVariantEnum.ERROR,
        label: t('Unsupported status value'),
      };
    }
  }
};
