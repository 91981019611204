import { alpha, Paper, styled, Table, TableContainer } from '@mui/material';
import React, { FC, ReactNode, useRef } from 'react';

import { EmptyState } from 'components/molecules/EmptyState';
import { ErrorState } from 'components/molecules/ErrorState';
import { HEADER_HEIGHT as APP_HEADER_HEIGHT } from 'components/templates/TemplatePageDefault';
import { BLACK, LIGHT_GRAY, LIGHT_GRAY_1, PRIMARY } from 'config/appColors';
import { UNEXPECTED_ERROR } from 'config/constants';
import { LoaderStatusEnum, Nullable } from 'types';

import { ALL_AUDITS_HEADER_HEIGHT } from '../../../config/constants';
import {
  ExtendedLoaderStatusEnum,
  LoaderStatusWithEmptyEnum,
} from '../../../types';
import { AdminTableSkeleton } from '../../molecules/AdminTableSkeleton';
import { AllAuditsTableErrorBoundary } from '../../organisms/AllAuditsTable/AllAuditsTableErrorBoundary';

const StyledTableContainer = styled(TableContainer)`
  & {
    position: relative;
    height: calc(
      100vh - ${APP_HEADER_HEIGHT}px - ${ALL_AUDITS_HEADER_HEIGHT}px
    );
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }

  * {
    font-size: 1.4rem;
  }

  .table-head {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .table-head__first-row,
  .table-head__first-row th {
    height: 36px;
    padding: 0 16px;
    color: white;
    background-color: ${PRIMARY.MAIN};
    line-height: 36px;
  }

  .sortable {
    cursor: pointer;
    svg {
      fill: white;
    }
  }

  .table {
    overflow-x: scroll;
    table-layout: fixed;
    /* border-collapse: separate - is necessary because of incorrect behavior of border when element has sticky position */
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }

  .table tbody {
    height: 100%;
  }

  .table tbody tr {
    height: fit-content;
  }

  .table-head__second-row th,
  tbody td {
    vertical-align: top;
  }

  .bg-gray {
    background-color: ${LIGHT_GRAY_1};
  }

  th,
  td {
    padding: 6px 10px;
    border: none;
    border-bottom: 1px solid ${LIGHT_GRAY};
    border-left: 1px solid ${LIGHT_GRAY};
  }

  .table-head__first-row th {
    border-left: 1px solid ${alpha(BLACK, 0.3)};
  }

  th:last-child,
  td:last-child {
    border-right: 1px solid ${LIGHT_GRAY};
  }

  .footer {
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 16px;
    display: flex;
    justify-content: flex-end;
    z-index: 100;
  }

  .state-container {
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    flex-grow: 1;

    > div {
      width: 100%;
    }
  }

  .error-state {
    background: ${alpha(BLACK, 0.04)};
  }

  .empty-state,
  .error-state {
    * {
      font-size: 1.2rem;
    }

    svg {
      font-size: 2.5rem;
    }
  }
` as typeof TableContainer;

interface AdminTableTemplateProps {
  thead: ReactNode;
  tbody: ReactNode;
  tfoot: ReactNode;
  state: ExtendedLoaderStatusEnum;
}

export const AdminTableTemplate: FC<AdminTableTemplateProps> = ({
  thead,
  tbody,
  tfoot,
  state,
}) => {
  const showBody = state === LoaderStatusEnum.SUCCESS;
  const showEmptyState = state === LoaderStatusWithEmptyEnum.EMPTY;
  const showLoader = state === LoaderStatusEnum.LOADING;
  const showError = state === LoaderStatusEnum.ERROR;

  const ref = useRef<Nullable<HTMLDivElement>>(null);

  return (
    <StyledTableContainer component={Paper} ref={ref}>
      <Table className="table">
        {thead}
        {showBody && (
          <AllAuditsTableErrorBoundary width={ref.current?.clientWidth}>
            {tbody}
          </AllAuditsTableErrorBoundary>
        )}
        {showLoader && <AdminTableSkeleton />}
      </Table>

      <div className="state-container">
        {showEmptyState && <EmptyState className="empty-state" />}
        {showError && (
          <ErrorState
            className="error-state"
            heading={UNEXPECTED_ERROR} // TODO it should come from BE
            dense
          />
        )}
      </div>
      <div className="bg-gray footer">{tfoot}</div>
    </StyledTableContainer>
  );
};
