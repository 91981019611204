import { LANG_PARAM } from 'config/languages';

const endpoints = {
  ACTIONS: {
    DROPDOWN_OPTIONS: (certificateUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/actions`,
    REQUEST_AUDIT_ACCESS: '/suppliers/request-release-access',
  },
  CERTIFICATE_LOCK: {
    LOCK_CERTIFICATE: (certificateUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/locks`,
    LOCK_HISTORY: (certificateUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/locks`,
    UNLOCK_CERTIFICATE: (certificateUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/reinstate`,
    UPDATE_LOCK_REASON: (certificateUuid: string, lockUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/locks/${lockUuid}`,
    UPDATE_UNLOCK_REASON: (certificateUuid: string, lockUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/locks/${lockUuid}/unlock`,
  },
  CERTIFICATE_TAB: {
    DETAILS: (certificateUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}`,
    DOWNLOAD: (certificateUuid: string, documentUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/documents/${documentUuid}`,
    LIST: (certificateUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/documents`,
  },
  DELETE_CERTIFICATE: (certificateUuid: string): string =>
    `/${LANG_PARAM}/certificates/${certificateUuid}`,
  EXCEPTIONAL_CIRCUMSTANCE: {
    ADD: (certificateUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/exceptional-circumstances`,
    CLOSE: (certificateUuid: string, caseUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/exceptional-circumstances/${caseUuid}/close`,
    UPDATE: (certificateUuid: string, caseUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/exceptional-circumstances/${caseUuid}`,
  },
  GROUP_CERTIFICATE: {
    ADDITIONAL_AUDIT: {
      ADD: (certificateUuid: string): string =>
        `/${LANG_PARAM}/group-certificates/${certificateUuid}/additional-audits`,
      LIST: (certificateUuid: string): string =>
        `/${LANG_PARAM}/group-certificates/${certificateUuid}/additional-audits`,
      OPTIONS: (certificateUuid: string): string =>
        `/${LANG_PARAM}/group-certificates/${certificateUuid}/additional-audits-options`,
      REMOVE: (certificateUuid: string, auditUuid: string): string =>
        `/${LANG_PARAM}/group-certificates/${certificateUuid}/additional-audits/${auditUuid}`,
    },
    ADDITIONAL_SUPPLIER: {
      ADD: (certificateUuid: string): string =>
        `/${LANG_PARAM}/group-certificates/${certificateUuid}/additional-suppliers`,
      LIST: (certificateUuid: string): string =>
        `/${LANG_PARAM}/group-certificates/${certificateUuid}/additional-suppliers`,
      OPTIONS: (certificateUuid: string): string =>
        `/${LANG_PARAM}/group-certificates/${certificateUuid}/additional-suppliers-options`,
      REMOVE: (certificateUuid: string, supplierUuid: string): string =>
        `/${LANG_PARAM}/group-certificates/${certificateUuid}/additional-suppliers/${supplierUuid}`,
    },
    REMOVE: (certificateUuid: string): string =>
      `/${LANG_PARAM}/group-certificates/${certificateUuid}`,
  },
  LOGS_TAB: {
    LIST: (certificateUuid: string): string =>
      `/${LANG_PARAM}/certificates/${certificateUuid}/logs`,
  },
  TEMPLATE: (certificateUuid: string): string =>
    `/${LANG_PARAM}/certificates/${certificateUuid}/templates/certificateDetails`,
};

export default endpoints;
