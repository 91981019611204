import { ReactNode } from 'react';

import { Nullable, RawResultDetails, TranslatedText } from 'types';

export enum DynamicFormItemTypesEnum {
  SINGLE_SELECT = 'single-select',
  TIME_FRAME = 'time-frame',
  DATE_INPUT = 'date-input',
  RESULT_SELECT = 'result-select',
  TEXTAREA = 'textarea',
  RADIO = 'radio',
}

export interface ConditionObject {
  type: string; // 'dataConditionEqual'
  key: string;
  value: unknown;
}

export interface DynamicFormCommonTemplateProps {
  dataRef: string;
  label: TranslatedText;
  requiredCondition: ConditionObject | boolean;
  visibilityCondition: ConditionObject | boolean;
}

interface DynamicFormItemCommon {
  children: Nullable<DynamicFormItem[]>;
}

interface SingleSelectOptionType {
  value: string;
  text: TranslatedText;
  selected?: boolean;
}

export interface SingleSelectType extends DynamicFormItemCommon {
  type: DynamicFormItemTypesEnum.SINGLE_SELECT;
  props: DynamicFormCommonTemplateProps & {
    id: string;
    name: string;
    options: SingleSelectOptionType[];
  };
}

interface Settings {
  id: string;
  label: TranslatedText;
  earliest: Nullable<string>;
  latest: Nullable<string>;
}

export interface TimeFrameType extends DynamicFormItemCommon {
  type: DynamicFormItemTypesEnum.TIME_FRAME;
  props: DynamicFormCommonTemplateProps & {
    startSettings: Settings;
    endSettings: Settings;
  };
}

export interface DateInputType extends DynamicFormItemCommon {
  type: DynamicFormItemTypesEnum.DATE_INPUT;
  props: DynamicFormCommonTemplateProps & {
    id: string;
    earliest: Nullable<string>;
    latest: Nullable<string>;
  };
}

interface ResultSelectOptionType {
  value: string;
  resultObject: RawResultDetails;
  text: TranslatedText;
  selected?: boolean;
}

export interface ResultSelectType extends DynamicFormItemCommon {
  type: DynamicFormItemTypesEnum.RESULT_SELECT;
  props: DynamicFormCommonTemplateProps & {
    id: string;
    name: string;
    options: ResultSelectOptionType[];
  };
}

export interface TextareaType extends DynamicFormItemCommon {
  type: DynamicFormItemTypesEnum.TEXTAREA;
  props: DynamicFormCommonTemplateProps & {
    id: string;
    name: string;
    minLength: number;
    maxLength: number;
  };
}

interface RadioOption {
  value: string;
  text: TranslatedText;
  selected?: boolean;
}

export interface RadioType extends DynamicFormItemCommon {
  type: DynamicFormItemTypesEnum.RADIO;
  props: DynamicFormCommonTemplateProps & {
    id: string;
    name: string;
    options: RadioOption[];
  };
}

export type DynamicFormItem =
  | SingleSelectType
  | TimeFrameType
  | DateInputType
  | ResultSelectType
  | TextareaType
  | RadioType;

export type ValidationStateType = Nullable<
  Record<string, Record<string, string> | string>
>;

export interface DynamicFormCommonProps {
  values: Nullable<Record<string, unknown>>;
  setValue: (key: string, value: unknown) => void;
  formItems?: Nullable<DynamicFormItem[]>;
  validationState: ValidationStateType;
  validateFormItem: (value: unknown, dataRef: string) => void;
  isVisible: (dataRef: string) => boolean;
}

export interface DynamicFormItemCommonProps {
  subItems?: ReactNode;
}
