import { styled } from '@mui/material';

const StyledLabel = styled('label')`
  display: block;
  padding-bottom: 15px;
`;

StyledLabel.displayName = 'StyledLabel';

export { StyledLabel };
