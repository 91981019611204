import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
  TemplateModalWithSnackbar,
  TemplateModalWithSnackbarProps,
} from 'components/templates/TemplateModalWithSnackbar';

import { closeAndResetCertificateModal } from '../../../state/modal/slice';

type CertificateDetailsModalBaseProps = Omit<
  TemplateModalWithSnackbarProps,
  'closeMethod'
>;

export const CertificateDetailsModalBase: FC<CertificateDetailsModalBaseProps> =
  (props) => {
    const dispatch = useDispatch();
    const closeMethod = () => {
      dispatch(closeAndResetCertificateModal());
    };

    return <TemplateModalWithSnackbar {...props} closeMethod={closeMethod} />;
  };
