import React, { FC } from 'react';

import { DiaryAuditRegistrationsTable } from 'domains/auditAppointment/components/organisms/DiaryAuditRegistrationsTable';

import { DiaryAuditRegistrationsFilters } from '../DiaryAuditRegistrationsFilters';

export const DiaryAuditRegistrationsTab: FC = () => (
  <>
    <DiaryAuditRegistrationsFilters />
    <DiaryAuditRegistrationsTable />
  </>
);
