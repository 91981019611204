import { useSelector } from 'react-redux';

import { removeSpacesFromString } from 'utils/removeSpacesFromString';
import { isActionCommentFieldValid } from 'utils/validation/isActionCommentFieldValid';

import { selectUserIsAdmin } from '../../user/state/auth/slice';
import { MODAL_FORM_FIELD_MIN_CHARS } from '../config/constants';
import { selectCertificateDetailsModalFormValues } from '../state/modal/slice';

interface UseModalFormFieldsAreValidParams {
  categoriesForRequiredDescription?: string[];
  actionValuesForCommentRequired?: string[];
}

export const useModalFormFieldsAreValid = (
  params?: UseModalFormFieldsAreValidParams
): boolean => {
  const {
    actionValuesForCommentRequired,
    categoriesForRequiredDescription = ['others'],
  } = params || {};
  const isAdmin = useSelector(selectUserIsAdmin);
  const fields = useSelector(selectCertificateDetailsModalFormValues);
  const { type, category, actionComment } = fields;
  const { reason, comment = '' } = actionComment;
  const descriptionEnglish = removeSpacesFromString(fields.descriptionEnglish);
  const categoryDescription = removeSpacesFromString(
    fields.categoryOthersDescription || ''
  );

  const isDescriptionEnglishValid =
    descriptionEnglish.length >= MODAL_FORM_FIELD_MIN_CHARS;
  const isCategoryDescriptionValid = !(
    categoriesForRequiredDescription.includes(category) &&
    (!categoryDescription ||
      categoryDescription.length < MODAL_FORM_FIELD_MIN_CHARS)
  );
  const isActionCommentValid = isAdmin
    ? isActionCommentFieldValid(reason, comment, actionValuesForCommentRequired)
    : true;

  return !!(
    type &&
    category &&
    isCategoryDescriptionValid &&
    isDescriptionEnglishValid &&
    isActionCommentValid
  );
};
