import { SelectChangeEvent } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DynamicFormItemLabel } from 'components/atoms/DynamicFormItemLabel';
import { Nullable, RawResult, WithUndefined } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { StyledSelect, StyledSubItemsContainer } from './StyledElements';
import {
  DynamicFormCommonProps,
  DynamicFormItemCommonProps,
  ResultSelectType,
} from './types';

type ResultSelectProps = ResultSelectType &
  DynamicFormCommonProps &
  DynamicFormItemCommonProps;

export const ResultSelect: FC<ResultSelectProps> = ({
  props,
  setValue,
  values,
  validateFormItem,
  validationState,
  subItems,
}) => {
  const { dataRef, label, options } = props;
  const [t] = useTranslation('components');
  const value = get(values, dataRef) as Nullable<RawResult>;

  const optionsToDisplay = options.map((option) => ({
    value: option.value,
    label: getTranslatedValue(option.text),
  }));
  const error = get(validationState, dataRef) as WithUndefined<string>;

  const getRawResultDetails = (rawResultDetailsValue: string) =>
    options.find((option) => option.value === rawResultDetailsValue)
      ?.resultObject;

  const handleChange = ({ target }: SelectChangeEvent<unknown>) => {
    const newValue = target.value as string;
    const overall = getRawResultDetails(newValue);
    const newResult = {
      overall,
      levels: [overall],
    };
    setValue(dataRef, newResult);
    validateFormItem(newResult, dataRef);
  };

  return (
    <div>
      <DynamicFormItemLabel text={label} error={error} />
      <StyledSelect
        placeholder={t('please select')}
        value={value?.overall?.id || ''}
        options={optionsToDisplay}
        onChange={handleChange}
      />
      {!!subItems && (
        <StyledSubItemsContainer>{subItems}</StyledSubItemsContainer>
      )}
    </div>
  );
};
