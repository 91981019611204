import { styled } from '@mui/material';
import React, { FC } from 'react';

import { Column, Table, useColumnConfig } from 'components/organisms/Table';
import { useAuditParticipantsToDisplay } from 'hooks/useAuditParticipantsToDisplay';
import { AuditorParticipant } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useGetAuditorParticipantsQuery } from '../../../state/auditorParticipantsTab/api';
import { AuditAuditorParticipantsTab } from '../../../types';
import { AuditParticipantDateTime } from '../../atoms/AuditParticipantDateTime';
import { AuditParticipantRole } from '../../atoms/AuditParticipantRole';
import { TabSectionHeading } from '../../atoms/TabSectionHeading';

const StyledTable = styled(Table)`
  table td {
    vertical-align: top;
  }
` as typeof Table;

type AuditorParticipantsTabProps = AuditAuditorParticipantsTab['props'] & {
  auditUuid: string;
};

export const AuditorParticipantsTab: FC<AuditorParticipantsTabProps> = (
  props
) => {
  const { headerTextAuditor, auditorColumnText, auditUuid, roleMapper } = props;
  const { auditorParticipants, status, error } = useGetAuditorParticipantsQuery(
    { auditUuid }
  );
  const auditorParticipantsToDisplay = useAuditParticipantsToDisplay(
    auditorParticipants,
    roleMapper
  );
  const { getColumnConfig } = useColumnConfig('32%');

  const getHeaderName = (translatedTextKey: string, fallbackText: string) => {
    if (translatedTextKey in auditorColumnText) {
      return getTranslatedValue(auditorColumnText[translatedTextKey]);
    }

    console.warn(
      `No matching value for following key ${translatedTextKey} in the template in auditorColumnText object`
    );

    return fallbackText;
  };

  const columns: Column<AuditorParticipant>[] = [
    {
      customCell: ({ userId }) => userId,
      headerName: getHeaderName('id', 'ID'),
      ...getColumnConfig('15%'),
    },
    {
      customCell: ({ firstName, lastName }) => `${firstName} ${lastName}`,
      headerName: getHeaderName('name', 'Name'),
      ...getColumnConfig('20%'),
    },
    {
      customCell: ({ role }) => (
        <AuditParticipantRole roleMapper={roleMapper} role={role} />
      ),
      headerName: getHeaderName('role', 'Role'),
      ...getColumnConfig(),
    },
    {
      customCell: AuditParticipantDateTime,
      headerName: getHeaderName('dateTime', 'Date/Time'),
      ...getColumnConfig(),
    },
  ];

  return (
    <>
      <TabSectionHeading text={headerTextAuditor} />
      <StyledTable
        rows={auditorParticipantsToDisplay}
        columns={columns}
        status={status}
        error={error}
        propertyAsAKey="key"
        dense
      />
    </>
  );
};
