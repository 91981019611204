export const countries = [
  {
    id: 'AD',
    text: {
      en: 'Andorra',
      de: 'Andorra',
      es: 'Andorra',
      fr: 'Andorre',
      it: 'Andorra',
    },
  },
  {
    id: 'AE',
    text: {
      en: 'United Arab Emirates',
      de: 'Vereinigte Arabische Emirate',
      es: 'Emiratos Árabes Unidos',
      fr: 'Émirats arabes unis',
      it: 'Emiratos Árabes Unidos',
    },
  },
  {
    id: 'AF',
    text: {
      en: 'Afghanistan',
      de: 'Afghanistan',
      es: 'Afganistán',
      fr: 'Afghanistan',
      it: 'Afghanistan',
    },
  },
  {
    id: 'AG',
    text: {
      en: 'Antigua & Barbuda',
      de: 'Antigua und Barbuda',
      es: 'Antigua y Barbuda',
      fr: 'Antigua-et-Barbuda',
      it: 'Antigua e Barbuda',
    },
  },
  {
    id: 'AI',
    text: {
      en: 'Anguilla',
      de: 'Anguilla',
      es: 'Anguila',
      fr: 'Anguilla',
      it: 'Anguilla',
    },
  },
  {
    id: 'AL',
    text: {
      en: 'Albania',
      de: 'Albanien',
      es: 'Albania',
      fr: 'Albanie',
      it: 'Albania',
    },
  },
  {
    id: 'AM',
    text: {
      en: 'Armenia',
      de: 'Armenien',
      es: 'Armenia',
      fr: 'Arménie',
      it: 'Armenia',
    },
  },
  {
    id: 'AN',
    text: {
      en: 'Netherland Antilles',
      de: 'Niederländische Antillen',
      es: 'Antillas Neerlandesas',
      fr: 'Antilles néerlandaises',
      it: 'Antille Olandesi',
    },
  },
  {
    id: 'AO',
    text: {
      en: 'Angola',
      de: 'Angola',
      es: 'Angola',
      fr: 'Angola',
      it: 'Angola',
    },
  },
  {
    id: 'AQ',
    text: {
      en: 'Antartica',
      de: 'Antarktis',
      es: 'Antártida',
      fr: 'Antarctique',
      it: 'Antartide',
    },
  },
  {
    id: 'AR',
    text: {
      en: 'Argentina',
      de: 'Argentinien',
      es: 'Argentina',
      fr: 'Argentine',
      it: 'Argentina',
    },
  },
  {
    id: 'AS',
    text: {
      en: 'American Samoa',
      de: 'Amerikanisch-Samoa',
      es: 'Samoa Americana',
      fr: 'Samoa américaines',
      it: 'Samoa Americane',
    },
  },
  {
    id: 'AT',
    text: {
      en: 'Austria',
      de: 'Österreich',
      es: 'Austria',
      fr: 'Autriche',
      it: 'Austria',
    },
  },
  {
    id: 'AU',
    text: {
      en: 'Australia',
      de: 'Australien',
      es: 'Australia',
      fr: 'Australie',
      it: 'Australia',
    },
  },
  {
    id: 'AW',
    text: {
      en: 'Aruba',
      de: 'Aruba',
      es: 'Aruba',
      fr: 'Aruba',
      it: 'Aruba',
    },
  },
  {
    id: 'AX',
    text: {
      en: 'Åland Islands',
      de: 'Aland',
      es: 'Åland',
      fr: 'Åland',
      it: 'Isole Åland',
    },
  },
  {
    id: 'AZ',
    text: {
      en: 'Azerbaijan',
      de: 'Aserbaidschan',
      es: 'Azerbaiyán',
      fr: 'Azerbaïdjan',
      it: 'Azerbaigian',
    },
  },
  {
    id: 'BA',
    text: {
      en: 'Bosnia and Herzegovina',
      de: 'Bosnien und Herzegowina',
      es: 'Bosnia-Herzegovina',
      fr: 'Bosnie-Herzégovine',
      it: 'Bosnia Erzegovina',
    },
  },
  {
    id: 'BB',
    text: {
      en: 'Barbados',
      de: 'Barbados',
      es: 'Barbados',
      fr: 'Barbade',
      it: 'Barbados',
    },
  },
  {
    id: 'BD',
    text: {
      en: 'Bangladesh',
      de: 'Bangladesch',
      es: 'Bangladesh',
      fr: 'Bangladesh',
      it: 'Bangladesh',
    },
  },
  {
    id: 'BE',
    text: {
      en: 'Belgium',
      de: 'Belgien',
      es: 'Bélgica',
      fr: 'Belgique',
      it: 'Belgio',
    },
  },
  {
    id: 'BF',
    text: {
      en: 'Burkina Faso',
      de: 'Burkina Faso',
      es: 'Burkina Faso',
      fr: 'Burkina Faso',
      it: 'Burkina Faso',
    },
  },
  {
    id: 'BG',
    text: {
      en: 'Bulgaria',
      de: 'Bulgarien',
      es: 'Bulgaria',
      fr: 'Bulgarie',
      it: 'Bulgaria',
    },
  },
  {
    id: 'BH',
    text: {
      en: 'Bahrain',
      de: 'Bahrain',
      es: 'Bahráin',
      fr: 'Bahreïn',
      it: 'Bahrein',
    },
  },
  {
    id: 'BI',
    text: {
      en: 'Burundi',
      de: 'Burundi',
      es: 'Burundi',
      fr: 'Burundi',
      it: 'Burundi',
    },
  },
  {
    id: 'BJ',
    text: {
      en: 'Benin',
      de: 'Benin',
      es: 'Benín',
      fr: 'Bénin',
      it: 'Benin',
    },
  },
  {
    id: 'BL',
    text: {
      en: 'Saint Barthélemy',
      de: 'Saint Barthélemy',
      es: 'Saint Barthélemy',
      fr: 'Saint-Barthélemy',
      it: 'Saint Barthélemy',
    },
  },
  {
    id: 'BM',
    text: {
      en: 'Bermuda',
      de: 'Bermuda',
      es: 'Bermudas',
      fr: 'Bermudes',
      it: 'Bermuda',
    },
  },
  {
    id: 'BN',
    text: {
      en: 'Brunei Darussalam',
      de: 'Brunei',
      es: 'Brunéi',
      fr: 'Brunéi',
      it: 'Brunei',
    },
  },
  {
    id: 'BO',
    text: {
      en: 'Bolivia',
      de: 'Bolivien',
      es: 'Bolivia',
      fr: 'Bolivie',
      it: 'Bolivia',
    },
  },
  {
    id: 'BQ',
    text: {
      en: 'Boniare, Sint Eustatius and saba',
      de: 'Boniare, Sint Eustatius und saba',
      es: 'Bonaire, San Eustaquio y Saba',
      fr: 'Boniare, Saint-Eustache et saba',
      it: 'Bonaire, Sint Eustatius e Saba',
    },
  },
  {
    id: 'BR',
    text: {
      en: 'Brazil',
      de: 'Brasilien',
      es: 'Brasil',
      fr: 'Brésil',
      it: 'Brasile',
    },
  },
  {
    id: 'BS',
    text: {
      en: 'Bahamas',
      de: 'Bahamas',
      es: 'Bahamas',
      fr: 'Bahamas',
      it: 'Bahamas',
    },
  },
  {
    id: 'BT',
    text: {
      en: 'Bhutan',
      de: 'Bhutan',
      es: 'Bután',
      fr: 'Bhoutan',
      it: 'Bhutan',
    },
  },
  {
    id: 'BV',
    text: {
      en: 'Bouvet Island',
      de: 'Bouvetinsel',
      es: 'Isla Bouvet',
      fr: 'Île Bouvet',
      it: 'Isola Bouvet',
    },
  },
  {
    id: 'BW',
    text: {
      en: 'Botswana',
      de: 'Botswana',
      es: 'Botsuana',
      fr: 'Botswana',
      it: 'Botswana',
    },
  },
  {
    id: 'BY',
    text: {
      en: 'Belarus',
      de: 'Weißrussland',
      es: 'Bielorrusia',
      fr: 'Biélorussie',
      it: 'Bielorussia',
    },
  },
  {
    id: 'BZ',
    text: {
      en: 'Belize',
      de: 'Belize',
      es: 'Belice',
      fr: 'Belize',
      it: 'Belize',
    },
  },
  {
    id: 'CA',
    text: {
      en: 'Canada',
      de: 'Kanada',
      es: 'Canadá',
      fr: 'Canada',
      it: 'Canada',
    },
  },
  {
    id: 'CC',
    text: {
      en: 'Cocos [Keeling] Isl',
      de: 'Kokosinseln',
      es: 'Islas Cocos (Keeling)',
      fr: 'Îles Cocos',
      it: 'Isole Cocos (Keeling)',
    },
  },
  {
    id: 'CD',
    text: {
      en: 'Congo, Democratic Republic',
      de: 'Kongo, Demokratische Republik',
      es: 'República Democrática del Congo',
      fr: 'République démocratique du Congo / (Congo-Kinshasa)',
      it: 'Repubblica Democratica del Congo',
    },
  },
  {
    id: 'CF',
    text: {
      en: 'Central African Republic',
      de: 'Zentralafrikanische Republik',
      es: 'República Centroafricana',
      fr: 'Centrafrique',
      it: 'Repubblica Centrafricana',
    },
  },
  {
    id: 'CG',
    text: {
      en: 'Congo',
      de: 'Kongo, Republik',
      es: 'Congo',
      fr: 'Congo / (Congo-Brazzaville)',
      it: 'Congo',
    },
  },
  {
    id: 'CH',
    text: {
      en: 'Switzerland',
      de: 'Schweiz',
      es: 'Suiza',
      fr: 'Suisse',
      it: 'Svizzera',
    },
  },
  {
    id: 'CI',
    text: {
      en: "Cote D'Ivoire",
      de: "Cote d'Ivoire",
      es: 'Costa de Marfil',
      fr: "Côte d'Ivoire",
      it: 'Costa d’Avorio',
    },
  },
  {
    id: 'CK',
    text: {
      en: 'Cook Islands',
      de: 'Cookinseln',
      es: 'Islas Cook',
      fr: 'Cook',
      it: 'Isole Cook',
    },
  },
  {
    id: 'CL',
    text: {
      en: 'Chile',
      de: 'Chile',
      es: 'Chile',
      fr: 'Chili',
      it: 'Cile',
    },
  },
  {
    id: 'CM',
    text: {
      en: 'Cameroon',
      de: 'Kamerun',
      es: 'Camerún',
      fr: 'Cameroun',
      it: 'Camerun',
    },
  },
  {
    id: 'CN',
    text: {
      en: 'China',
      de: 'China, Volksrepublik',
      es: 'China',
      fr: 'Chine',
      it: 'Cina',
    },
  },
  {
    id: 'CO',
    text: {
      en: 'Colombia',
      de: 'Kolumbien',
      es: 'Colombia',
      fr: 'Colombie',
      it: 'Colombia',
    },
  },
  {
    id: 'CR',
    text: {
      en: 'Costa Rica',
      de: 'Costa Rica',
      es: 'Costa Rica',
      fr: 'Costa Rica',
      it: 'Costa Rica',
    },
  },
  {
    id: 'CS',
    text: {
      en: '(Inactive: Country does not exist anymore) Serbia and Montenegro',
      de: '(Inaktiv: Land existiert nicht mehr) Serbien und Montenegro',
      es: '(Inactive: El país ya no existe) Serbia y Montenegro',
      fr: '(Inactive: Le pays n’existe plus) Serbie et Monténégro',
      it: '(Inactive: Il paese non esiste più) Serbia e Montenegro',
    },
  },
  {
    id: 'CU',
    text: {
      en: 'Cuba',
      de: 'Kuba',
      es: 'Cuba',
      fr: 'Cuba',
      it: 'Cuba',
    },
  },
  {
    id: 'CV',
    text: {
      en: 'Cabo Verde',
      de: 'Kap Verde, Republik',
      es: 'Cabo Verde',
      fr: 'Cabo Verde',
      it: 'Capo Verde',
    },
  },
  {
    id: 'CW',
    text: {
      en: 'Curaçao',
      de: 'Curaçao',
      es: 'Curaçao',
      fr: 'Curaçao',
      it: 'Curaçao',
    },
  },
  {
    id: 'CX',
    text: {
      en: 'Christmas Island',
      de: 'Weihnachtsinsel',
      es: 'Isla Navidad',
      fr: 'Île Christmas',
      it: 'Isola di Christmas',
    },
  },
  {
    id: 'CY',
    text: {
      en: 'Cyprus',
      de: 'Zypern, Republik',
      es: 'Chipre',
      fr: 'Chypre (pays)',
      it: 'Cipro',
    },
  },
  {
    id: 'CZ',
    text: {
      en: 'Czechia',
      de: 'Tschechische Republik',
      es: 'República Checa',
      fr: 'Tchéquie',
      it: 'Repubblica Ceca',
    },
  },
  {
    id: 'DE',
    text: {
      en: 'Germany',
      de: 'Deutschland',
      es: 'Alemania',
      fr: 'Allemagne',
      it: 'Germania',
    },
  },
  {
    id: 'DJ',
    text: {
      en: 'Djibouti',
      de: 'Dschibuti',
      es: 'Yibuti',
      fr: 'Djibouti',
      it: 'Gibuti',
    },
  },
  {
    id: 'DK',
    text: {
      en: 'Denmark',
      de: 'Dänemark',
      es: 'Dinamarca',
      fr: 'Danemark',
      it: 'Danimarca',
    },
  },
  {
    id: 'DM',
    text: {
      en: 'Dominica',
      de: 'Dominica',
      es: 'Dominica',
      fr: 'Dominique',
      it: 'Dominica',
    },
  },
  {
    id: 'DO',
    text: {
      en: 'Dominican Republic',
      de: 'Dominikanische Republik',
      es: 'República Dominicana',
      fr: 'République dominicaine',
      it: 'Repubblica Dominicana',
    },
  },
  {
    id: 'DZ',
    text: {
      en: 'Algeria',
      de: 'Algerien',
      es: 'Argelia',
      fr: 'Algérie',
      it: 'Algeria',
    },
  },
  {
    id: 'EC',
    text: {
      en: 'Ecuador',
      de: 'Ecuador',
      es: 'Ecuador',
      fr: 'Équateur',
      it: 'Ecuador',
    },
  },
  {
    id: 'EE',
    text: {
      en: 'Estonia',
      de: 'Estland',
      es: 'Estonia',
      fr: 'Estonie',
      it: 'Estonia',
    },
  },
  {
    id: 'EG',
    text: {
      en: 'Egypt',
      de: 'Ägypten',
      es: 'Egipto',
      fr: 'Égypte',
      it: 'Egitto',
    },
  },
  {
    id: 'EH',
    text: {
      en: 'Western Sahara',
      de: 'Westsahara',
      es: 'Sáhara Occidental',
      fr: 'Sahara occidental',
      it: 'Sahara Occidentale',
    },
  },
  {
    id: 'ER',
    text: {
      en: 'Eritrea',
      de: 'Eritrea',
      es: 'Eritrea',
      fr: 'Érythrée',
      it: 'Eritrea',
    },
  },
  {
    id: 'ES',
    text: {
      en: 'Spain',
      de: 'Spanien',
      es: 'España',
      fr: 'Espagne',
      it: 'Spagna',
    },
  },
  {
    id: 'ET',
    text: {
      en: 'Ethiopia',
      de: 'Äthiopien',
      es: 'Etiopía',
      fr: 'Éthiopie',
      it: 'Etiopia',
    },
  },
  {
    id: 'FI',
    text: {
      en: 'Finland',
      de: 'Finnland',
      es: 'Finlandia',
      fr: 'Finlande',
      it: 'Finlandia',
    },
  },
  {
    id: 'FJ',
    text: {
      en: 'Fiji',
      de: 'Fidschi',
      es: 'Fiyi',
      fr: 'Fidji',
      it: 'Figi',
    },
  },
  {
    id: 'FK',
    text: {
      en: 'Falkland Isl. [Malvinas]',
      de: 'Falklandinseln',
      es: 'Islas Falkland (Malvinas)',
      fr: 'Îles Malouines (Falkland)',
      it: 'Isole Falkland',
    },
  },
  {
    id: 'FM',
    text: {
      en: 'Micronesia',
      de: 'Mikronesien, Föderierte Staaten von',
      es: 'Micronesia',
      fr: 'Micronésie (pays)',
      it: 'Micronesia',
    },
  },
  {
    id: 'FO',
    text: {
      en: 'Faroe Islands',
      de: 'Färöer',
      es: 'Islas Feroe',
      fr: 'Féroé',
      it: 'Isole Faroe',
    },
  },
  {
    id: 'FR',
    text: {
      en: 'France',
      de: 'Frankreich',
      es: 'Francia',
      fr: 'France',
      it: 'Francia',
    },
  },
  {
    id: 'GA',
    text: {
      en: 'Gabon',
      de: 'Gabun',
      es: 'Gabón',
      fr: 'Gabon',
      it: 'Gabon',
    },
  },
  {
    id: 'GB',
    text: {
      en: 'United Kingdom of Great Britain and Northern Ireland',
      de: 'Vereinigtes Königreich von Großbritannien und Nordirland',
      es: 'Reino Unido',
      fr: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
      it: 'Regno Unito',
    },
  },
  {
    id: 'GD',
    text: {
      en: 'Grenada',
      de: 'Grenada',
      es: 'Granada',
      fr: 'Grenade (pays)',
      it: 'Grenada',
    },
  },
  {
    id: 'GE',
    text: {
      en: 'Georgia',
      de: 'Georgien',
      es: 'Georgia',
      fr: 'Géorgie (pays)',
      it: 'Georgia',
    },
  },
  {
    id: 'GF',
    text: {
      en: 'French Guiana',
      de: 'Französisch-Guayana',
      es: 'Guayana Francesa',
      fr: 'Guyane',
      it: 'Guiana Francese',
    },
  },
  {
    id: 'GG',
    text: {
      en: 'Guernsey',
      de: 'Guernsey, Vogtei',
      es: 'Guernsey',
      fr: 'Guernesey',
      it: 'Guernsey',
    },
  },
  {
    id: 'GH',
    text: {
      en: 'Ghana',
      de: 'Ghana, Republik',
      es: 'Ghana',
      fr: 'Ghana',
      it: 'Ghana',
    },
  },
  {
    id: 'GI',
    text: {
      en: 'Gibraltar',
      de: 'Gibraltar',
      es: 'Gibraltar',
      fr: 'Gibraltar',
      it: 'Gibilterra',
    },
  },
  {
    id: 'GL',
    text: {
      en: 'Greenland',
      de: 'Grönland',
      es: 'Groenlandia',
      fr: 'Groenland',
      it: 'Groenlandia',
    },
  },
  {
    id: 'GM',
    text: {
      en: 'Gambia',
      de: 'Gambia',
      es: 'Gambia',
      fr: 'Gambie',
      it: 'Gambia',
    },
  },
  {
    id: 'GN',
    text: {
      en: 'Guinea',
      de: 'Guinea, Republik',
      es: 'Guinea',
      fr: 'Guinée',
      it: 'Guinea',
    },
  },
  {
    id: 'GP',
    text: {
      en: 'Guadeloupe',
      de: 'Guadeloupe',
      es: 'Guadalupe',
      fr: 'Guadeloupe',
      it: 'Guadalupa',
    },
  },
  {
    id: 'GQ',
    text: {
      en: 'Equatorial Guinea',
      de: 'Äquatorialguinea, Republik',
      es: 'Guinea Ecuatorial',
      fr: 'Guinée équatoriale',
      it: 'Guinea Equatoriale',
    },
  },
  {
    id: 'GR',
    text: {
      en: 'Greece',
      de: 'Griechenland',
      es: 'Grecia',
      fr: 'Grèce',
      it: 'Grecia',
    },
  },
  {
    id: 'GS',
    text: {
      en: 'South Georgia and the South Sandwich Islands',
      de: 'Südgeorgien und die Südlichen Sandwichinseln',
      es: 'Islas Georgia del Sur y Sandwich del Sur',
      fr: 'Géorgie du Sud et Îles Sandwich du Sud',
      it: 'Georgia del Sud e Isole Sandwich del Sud',
    },
  },
  {
    id: 'GT',
    text: {
      en: 'Guatemala',
      de: 'Guatemala',
      es: 'Guatemala',
      fr: 'Guatemala',
      it: 'Guatemala',
    },
  },
  {
    id: 'GU',
    text: {
      en: 'Guam',
      de: 'Guam',
      es: 'Guam',
      fr: 'Guam',
      it: 'Guam',
    },
  },
  {
    id: 'GW',
    text: {
      en: 'Guinea-Bissau',
      de: 'Guinea-Bissau, Republik',
      es: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
      it: 'Guinea-Bissau',
    },
  },
  {
    id: 'GY',
    text: {
      en: 'Guyana',
      de: 'Guyana',
      es: 'Guyana',
      fr: 'Guyana',
      it: 'Guyana',
    },
  },
  {
    id: 'HK',
    text: {
      en: 'Hong Kong',
      de: 'Hongkong',
      es: 'Hong-Kong, Región administrativa especial de Chin',
      fr: 'Hong Kong',
      it: 'Regione Amministrativa Speciale di Hong Kong, Cina',
    },
  },
  {
    id: 'HM',
    text: {
      en: 'Heard Island and McDonald Islands',
      de: 'Heard und McDonaldinseln',
      es: 'Islas Heard y McDonald',
      fr: 'Heard-et-Îles MacDonald',
      it: 'Isole Heard e Isole McDonald',
    },
  },
  {
    id: 'HN',
    text: {
      en: 'Honduras',
      de: 'Honduras',
      es: 'Honduras',
      fr: 'Honduras',
      it: 'Honduras',
    },
  },
  {
    id: 'HR',
    text: {
      en: 'Croatia',
      de: 'Kroatien',
      es: 'Croacia',
      fr: 'Croatie',
      it: 'Croazia',
    },
  },
  {
    id: 'HT',
    text: {
      en: 'Haiti',
      de: 'Haiti',
      es: 'Haití',
      fr: 'Haïti',
      it: 'Haiti',
    },
  },
  {
    id: 'HU',
    text: {
      en: 'Hungary',
      de: 'Ungarn, Republik',
      es: 'Hungría',
      fr: 'Hongrie',
      it: 'Ungheria',
    },
  },
  {
    id: 'IC',
    text: {
      en: 'Canary Islands',
      de: 'Kanarische Inseln',
      es: 'Islas Canarias',
      fr: 'Iles Canaries',
      it: 'Canarie',
    },
  },
  {
    id: 'ID',
    text: {
      en: 'Indonesia',
      de: 'Indonesien',
      es: 'Indonesia',
      fr: 'Indonésie',
      it: 'Indonesia',
    },
  },
  {
    id: 'IE',
    text: {
      en: 'Ireland',
      de: 'Irland, Republik',
      es: 'Irlanda',
      fr: 'Irlande (pays)',
      it: 'Irlanda',
    },
  },
  {
    id: 'IL',
    text: {
      en: 'Israel',
      de: 'Israel',
      es: 'Israel',
      fr: 'Israël',
      it: 'Israele',
    },
  },
  {
    id: 'IM',
    text: {
      en: 'Isle of Man',
      de: 'Insel Mann',
      es: 'Isla de Man',
      fr: 'Île de Man',
      it: 'Isola di Man',
    },
  },
  {
    id: 'IN',
    text: {
      en: 'India',
      de: 'Indien',
      es: 'India',
      fr: 'Inde',
      it: 'India',
    },
  },
  {
    id: 'IO',
    text: {
      en: 'British Indian Ocean Territory',
      de: 'Britisches Territorium im Indischen Ozean',
      es: 'Territorios Británico del Océano Índico',
      fr: 'Indien',
      it: 'Territorio Britannico dell’Oceano Indiano',
    },
  },
  {
    id: 'IQ',
    text: {
      en: 'Iraq',
      de: 'Irak',
      es: 'Iraq',
      fr: 'Irak',
      it: 'Iraq',
    },
  },
  {
    id: 'IR',
    text: {
      en: 'Iran (Islamic Republic of)',
      de: 'Iran',
      es: 'Irán',
      fr: 'Iran',
      it: 'Iran',
    },
  },
  {
    id: 'IS',
    text: {
      en: 'Iceland',
      de: 'Island',
      es: 'Islandia',
      fr: 'Islande',
      it: 'Islanda',
    },
  },
  {
    id: 'IT',
    text: {
      en: 'Italy',
      de: 'Italien',
      es: 'Italia',
      fr: 'Italie',
      it: 'Italia',
    },
  },
  {
    id: 'JE',
    text: {
      en: 'Jersey',
      de: 'Jersey',
      es: 'Jersey',
      fr: 'Jersey',
      it: 'Jersey',
    },
  },
  {
    id: 'JM',
    text: {
      en: 'Jamaica',
      de: 'Jamaika',
      es: 'Jamaica',
      fr: 'Jamaïque',
      it: 'Giamaica',
    },
  },
  {
    id: 'JO',
    text: {
      en: 'Jordan',
      de: 'Jordanien',
      es: 'Jordania',
      fr: 'Jordanie',
      it: 'Giordania',
    },
  },
  {
    id: 'JP',
    text: {
      en: 'Japan',
      de: 'Japan',
      es: 'Japón',
      fr: 'Japon',
      it: 'Giappone',
    },
  },
  {
    id: 'KE',
    text: {
      en: 'Kenya',
      de: 'Kenia',
      es: 'Kenia',
      fr: 'Kenya',
      it: 'Kenya',
    },
  },
  {
    id: 'KG',
    text: {
      en: 'Kyrgyzstan',
      de: 'Kirgisistan',
      es: 'Kirguizistán',
      fr: 'Kirghizistan',
      it: 'Kirghizistan',
    },
  },
  {
    id: 'KH',
    text: {
      en: 'Cambodia',
      de: 'Kambodscha',
      es: 'Camboya',
      fr: 'Cambodge',
      it: 'Cambogia',
    },
  },
  {
    id: 'KI',
    text: {
      en: 'Kiribati',
      de: 'Kiribati',
      es: 'Kiribati',
      fr: 'Kiribati',
      it: 'Kiribati',
    },
  },
  {
    id: 'KM',
    text: {
      en: 'Comoros',
      de: 'Komoren, Islamische Bundesrepublik',
      es: 'Comoras',
      fr: 'Comores',
      it: 'Comore',
    },
  },
  {
    id: 'KN',
    text: {
      en: 'Saint Kitts and Nevis',
      de: 'St. Kitts und Nevis',
      es: 'San Cristóbal y Nieves',
      fr: 'Saint-Kitts-et-Nevis',
      it: 'Saint Kitts e Nevis',
    },
  },
  {
    id: 'KP',
    text: {
      en: "Korea (the Democratic People's Republic of)",
      de: 'Korea, Demokratische Volkrepublik',
      es: 'Corea del Norte',
      fr: 'Corée (la République populaire démocratique de)',
      it: 'Corea del Nord',
    },
  },
  {
    id: 'KR',
    text: {
      en: 'Korea, Republic of',
      de: 'Korea, Republik',
      es: 'Corea del Sur',
      fr: 'Corée du Sud',
      it: 'Corea del Sud',
    },
  },
  {
    id: 'KW',
    text: {
      en: 'Kuwait',
      de: 'Kuwait',
      es: 'Kuwait',
      fr: 'Koweït',
      it: 'Kuwait',
    },
  },
  {
    id: 'KY',
    text: {
      en: 'Cayman Islands',
      de: 'Kaimaninseln',
      es: 'Islas Caimán',
      fr: 'Îles Caïmanes',
      it: 'Isole Cayman',
    },
  },
  {
    id: 'KZ',
    text: {
      en: 'Kazakhstan',
      de: 'Kasachstan',
      es: 'Kazajstán',
      fr: 'Kazakhstan',
      it: 'Kazakistan',
    },
  },
  {
    id: 'LA',
    text: {
      en: "Lao People's Democratic Republic",
      de: 'Laos',
      es: 'Laos',
      fr: 'Lao, République démocratique populaire',
      it: 'Laos',
    },
  },
  {
    id: 'LB',
    text: {
      en: 'Lebanon',
      de: 'Libanon',
      es: 'Líbano',
      fr: 'Liban',
      it: 'Libano',
    },
  },
  {
    id: 'LC',
    text: {
      en: 'Saint Lucia',
      de: 'St. Lucia',
      es: 'Saint Lucia',
      fr: 'Sainte-Lucie',
      it: 'Saint Lucia',
    },
  },
  {
    id: 'LI',
    text: {
      en: 'Liechtenstein',
      de: 'Liechtenstein, Fürstentum',
      es: 'Liechtenstein',
      fr: 'Liechtenstein',
      it: 'Liechtenstein',
    },
  },
  {
    id: 'LK',
    text: {
      en: 'Sri Lanka',
      de: 'Sri Lanka',
      es: 'Sri Lanka',
      fr: 'Sri Lanka',
      it: 'Sri Lanka',
    },
  },
  {
    id: 'LR',
    text: {
      en: 'Liberia',
      de: 'Liberia, Republik',
      es: 'Liberia',
      fr: 'Libéria',
      it: 'Liberia',
    },
  },
  {
    id: 'LS',
    text: {
      en: 'Lesotho',
      de: 'Lesotho',
      es: 'Lesotho',
      fr: 'Lesotho',
      it: 'Lesotho',
    },
  },
  {
    id: 'LT',
    text: {
      en: 'Lithuania',
      de: 'Litauen',
      es: 'Lituania',
      fr: 'Lituanie',
      it: 'Lituania',
    },
  },
  {
    id: 'LU',
    text: {
      en: 'Luxembourg ',
      de: 'Luxemburg',
      es: 'Luxemburgo',
      fr: 'Luxembourg (pays)',
      it: 'Lussemburgo',
    },
  },
  {
    id: 'LV',
    text: {
      en: 'Latvia',
      de: 'Lettland',
      es: 'Letonia',
      fr: 'Lettonie',
      it: 'Lettonia',
    },
  },
  {
    id: 'LY',
    text: {
      en: 'Lybia',
      de: 'Libyen',
      es: 'Libia',
      fr: 'Libye',
      it: 'Libia',
    },
  },
  {
    id: 'MA',
    text: {
      en: 'Morocco',
      de: 'Marokko',
      es: 'Marruecos',
      fr: 'Maroc',
      it: 'Marocco',
    },
  },
  {
    id: 'MC',
    text: {
      en: 'Monaco',
      de: 'Monaco',
      es: 'Mónaco',
      fr: 'Monaco',
      it: 'Monaco',
    },
  },
  {
    id: 'MD',
    text: {
      en: 'Moldova, Republic of',
      de: 'Moldawien',
      es: 'Moldova',
      fr: 'Moldavie',
      it: 'Moldavia',
    },
  },
  {
    id: 'ME',
    text: {
      en: 'Montenegro',
      de: 'Montenegro',
      es: 'Montenegro',
      fr: 'Monténégro',
      it: 'Montenegro',
    },
  },
  {
    id: 'MF',
    text: {
      en: 'Saint Martin (French part)',
      de: 'Saint Martin (Französischer Teil)',
      es: 'San Martín (parte francesa)',
      fr: 'Saint-Martin (partie française)',
      it: 'Saint Martin (parte francese)',
    },
  },
  {
    id: 'MG',
    text: {
      en: 'Madagascar',
      de: 'Madagaskar, Republik',
      es: 'Madagascar',
      fr: 'Madagascar',
      it: 'Madagascar',
    },
  },
  {
    id: 'MH',
    text: {
      en: 'Marshall Islands',
      de: 'Marshallinseln',
      es: 'Islas Marshall',
      fr: 'Marshall',
      it: 'Isole Marshall',
    },
  },
  {
    id: 'MK',
    text: {
      en: 'North Macedonia',
      de: 'Nordmazedonien',
      es: 'Macedonia del Norte',
      fr: 'Macédoine du Nord',
      it: 'Macedonia settentrionale',
    },
  },
  {
    id: 'ML',
    text: {
      en: 'Mali',
      de: 'Mali, Republik',
      es: 'Malí',
      fr: 'Mali',
      it: 'Mali',
    },
  },
  {
    id: 'MM',
    text: {
      en: 'Myanmar',
      de: 'Myanmar, Union',
      es: 'Myanmar',
      fr: 'Birmanie',
      it: 'Myanmar',
    },
  },
  {
    id: 'MN',
    text: {
      en: 'Mongolia',
      de: 'Mongolei',
      es: 'Mongolia',
      fr: 'Mongolie',
      it: 'Mongolia',
    },
  },
  {
    id: 'MO',
    text: {
      en: 'Macao',
      de: 'Macao',
      es: 'Macao, Región administrativa especial de China',
      fr: 'Macao',
      it: 'Regione Amministrativa Speciale di Macao, Cina',
    },
  },
  {
    id: 'MP',
    text: {
      en: 'Northern Mariana Isl',
      de: 'Nördliche Marianen',
      es: 'Islas Marianas del Norte',
      fr: 'Îles Mariannes du Nord',
      it: 'Isole Marianne Settentrionali',
    },
  },
  {
    id: 'MQ',
    text: {
      en: 'Martinique',
      de: 'Martinique',
      es: 'Martinica',
      fr: 'Martinique',
      it: 'Martinica',
    },
  },
  {
    id: 'MR',
    text: {
      en: 'Mauritania',
      de: 'Mauretanien',
      es: 'Mauritania',
      fr: 'Mauritanie',
      it: 'Mauritania',
    },
  },
  {
    id: 'MS',
    text: {
      en: 'Montserrat',
      de: 'Montserrat',
      es: 'Montserrat',
      fr: 'Montserrat',
      it: 'Montserrat',
    },
  },
  {
    id: 'MT',
    text: {
      en: 'Malta',
      de: 'Malta, Republik',
      es: 'Malta',
      fr: 'Malte',
      it: 'Malta',
    },
  },
  {
    id: 'MU',
    text: {
      en: 'Mauritius',
      de: 'Mauritius, Republik',
      es: 'Mauricio',
      fr: 'Maurice (pays)',
      it: 'Maurizio',
    },
  },
  {
    id: 'MV',
    text: {
      en: 'Maldives',
      de: 'Malediven',
      es: 'Maldivas',
      fr: 'Maldives',
      it: 'Maldive',
    },
  },
  {
    id: 'MW',
    text: {
      en: 'Malawi',
      de: 'Malawi, Republik',
      es: 'Malawi',
      fr: 'Malawi',
      it: 'Malawi',
    },
  },
  {
    id: 'MX',
    text: {
      en: 'Mexico',
      de: 'Mexiko',
      es: 'México',
      fr: 'Mexique',
      it: 'Messico',
    },
  },
  {
    id: 'MY',
    text: {
      en: 'Malaysia',
      de: 'Malaysia',
      es: 'Malasia',
      fr: 'Malaisie',
      it: 'Malaysia',
    },
  },
  {
    id: 'MZ',
    text: {
      en: 'Mozambique',
      de: 'Mosambik',
      es: 'Mozambique',
      fr: 'Mozambique',
      it: 'Mozambico',
    },
  },
  {
    id: 'NA',
    text: {
      en: 'Namibia',
      de: 'Namibia, Republik',
      es: 'Namibia',
      fr: 'Namibie',
      it: 'Namibia',
    },
  },
  {
    id: 'NC',
    text: {
      en: 'New Caledonia',
      de: 'Neukaledonien',
      es: 'Nueva Caledonia',
      fr: 'Nouvelle-Calédonie',
      it: 'Nuova Caledonia',
    },
  },
  {
    id: 'NE',
    text: {
      en: 'Niger',
      de: 'Niger',
      es: 'Níger',
      fr: 'Niger',
      it: 'Niger',
    },
  },
  {
    id: 'NF',
    text: {
      en: 'Norfolk Island',
      de: 'Norfolkinsel',
      es: 'Isla Norfolk',
      fr: 'Norfolk',
      it: 'Isola Norfolk',
    },
  },
  {
    id: 'NG',
    text: {
      en: 'Nigeria',
      de: 'Nigeria',
      es: 'Nigeria',
      fr: 'Nigeria',
      it: 'Nigeria',
    },
  },
  {
    id: 'NI',
    text: {
      en: 'Nicaragua',
      de: 'Nicaragua',
      es: 'Nicaragua',
      fr: 'Nicaragua',
      it: 'Nicaragua',
    },
  },
  {
    id: 'NL',
    text: {
      en: 'Netherlands',
      de: 'Niederlande',
      es: 'Países Bajos',
      fr: 'Pays-Bas',
      it: 'Paesi Bassi',
    },
  },
  {
    id: 'NO',
    text: {
      en: 'Norway',
      de: 'Norwegen',
      es: 'Noruega',
      fr: 'Norvège',
      it: 'Norvegia',
    },
  },
  {
    id: 'NP',
    text: {
      en: 'Nepal',
      de: 'Nepal',
      es: 'Nepal',
      fr: 'Népal',
      it: 'Nepal',
    },
  },
  {
    id: 'NR',
    text: {
      en: 'Nauru',
      de: 'Nauru',
      es: 'Nauru',
      fr: 'Nauru',
      it: 'Nauru',
    },
  },
  {
    id: 'NU',
    text: {
      en: 'Niue',
      de: 'Niue',
      es: 'Isla Niue',
      fr: 'Niué',
      it: 'Niue',
    },
  },
  {
    id: 'NZ',
    text: {
      en: 'New Zealand',
      de: 'Neuseeland',
      es: 'Nueva Zelanda',
      fr: 'Nouvelle-Zélande',
      it: 'Nuova Zelanda',
    },
  },
  {
    id: 'OM',
    text: {
      en: 'Oman',
      de: 'Oman',
      es: 'Omán',
      fr: 'Oman',
      it: 'Oman',
    },
  },
  {
    id: 'PA',
    text: {
      en: 'Panama',
      de: 'Panama',
      es: 'Panamá',
      fr: 'Panama',
      it: 'Panama',
    },
  },
  {
    id: 'PE',
    text: {
      en: 'Peru',
      de: 'Peru',
      es: 'Perú',
      fr: 'Pérou',
      it: 'Perù',
    },
  },
  {
    id: 'PF',
    text: {
      en: 'French Polynesia',
      de: 'Französisch-Polynesien',
      es: 'Polinesia Francesa',
      fr: 'Polynésie française',
      it: 'Polinesia Francese',
    },
  },
  {
    id: 'PG',
    text: {
      en: 'Papua New Guinea',
      de: 'Papua-Neuguinea',
      es: 'Papúa Nueva Guinea',
      fr: 'Papouasie-Nouvelle-Guinée',
      it: 'Papua Nuova Guinea',
    },
  },
  {
    id: 'PH',
    text: {
      en: 'Philippines',
      de: 'Philippinen',
      es: 'Filipinas',
      fr: 'Philippines',
      it: 'Filippine',
    },
  },
  {
    id: 'PK',
    text: {
      en: 'Pakistan',
      de: 'Pakistan',
      es: 'Pakistán',
      fr: 'Pakistan',
      it: 'Pakistan',
    },
  },
  {
    id: 'PL',
    text: {
      en: 'Poland',
      de: 'Polen',
      es: 'Polonia',
      fr: 'Pologne',
      it: 'Polonia',
    },
  },
  {
    id: 'PM',
    text: {
      en: 'St. Pierre and Miquelon',
      de: 'St. Pierre und Miquelon',
      es: 'San Pedro y Miquelón',
      fr: 'Saint-Pierre-et-Miquelon',
      it: 'Saint Pierre e Miquelon',
    },
  },
  {
    id: 'PN',
    text: {
      en: 'Pitcairn',
      de: 'Pitcairninseln',
      es: 'Pitcairn',
      fr: 'Pitcairn',
      it: 'Pitcairn',
    },
  },
  {
    id: 'PR',
    text: {
      en: 'Puerto Rico',
      de: 'Puerto Rico',
      es: 'Puerto Rico',
      fr: 'Porto Rico',
      it: 'Portorico',
    },
  },
  {
    id: 'PS',
    text: {
      en: 'Palestine, State of',
      de: 'Palästinensische Autonomiegebiete',
      es: 'Territorios Palestinos',
      fr: 'Palestine, État de',
      it: 'Palestina',
    },
  },
  {
    id: 'PT',
    text: {
      en: 'Portugal',
      de: 'Portugal',
      es: 'Portugal',
      fr: 'Portugal',
      it: 'Portogallo',
    },
  },
  {
    id: 'PW',
    text: {
      en: 'Palau',
      de: 'Palau',
      es: 'Palau',
      fr: 'Palaos',
      it: 'Palau',
    },
  },
  {
    id: 'PY',
    text: {
      en: 'Paraguay',
      de: 'Paraguay',
      es: 'Paraguay',
      fr: 'Paraguay',
      it: 'Paraguay',
    },
  },
  {
    id: 'PZ',
    text: {
      en: 'Panama Canal Zone',
      de: 'Panamakanal Zone',
      es: 'Zona del Canal de Panamá',
      fr: 'Zone du Canal de Panama',
      it: 'Panama Canal Zone',
    },
  },
  {
    id: 'QA',
    text: {
      en: 'Qatar',
      de: 'Katar',
      es: 'Qatar',
      fr: 'Qatar',
      it: 'Qatar',
    },
  },
  {
    id: 'RE',
    text: {
      en: 'Reunion',
      de: 'Réunion',
      es: 'Réunion',
      fr: 'Réunion',
      it: 'Réunion',
    },
  },
  {
    id: 'RO',
    text: {
      en: 'Romania',
      de: 'Rumänien',
      es: 'Rumanía',
      fr: 'Roumanie',
      it: 'Romania',
    },
  },
  {
    id: 'RS',
    text: {
      en: 'Serbia',
      de: 'Serbien',
      es: 'Serbia',
      fr: 'Serbie',
      it: 'Serbia',
    },
  },
  {
    id: 'RU',
    text: {
      en: 'Russian Federation',
      de: 'Russische Föderation',
      es: 'Rusia',
      fr: 'Russie',
      it: 'Federazione Russa',
    },
  },
  {
    id: 'RW',
    text: {
      en: 'Rwanda',
      de: 'Ruanda, Republik',
      es: 'Ruanda',
      fr: 'Rwanda',
      it: 'Ruanda',
    },
  },
  {
    id: 'SA',
    text: {
      en: 'Saudi Arabia',
      de: 'Saudi-Arabien, Königreich',
      es: 'Arabia Saudí',
      fr: 'Arabie saoudite',
      it: 'Arabia Saudita',
    },
  },
  {
    id: 'SB',
    text: {
      en: 'Solomon Islands',
      de: 'Salomonen',
      es: 'Islas Salomón',
      fr: 'Îles Salomon, Îles',
      it: 'Isole Solomon',
    },
  },
  {
    id: 'SC',
    text: {
      en: 'Seychelles',
      de: 'Seychellen, Republik der',
      es: 'Seychelles',
      fr: 'Seychelles',
      it: 'Seychelles',
    },
  },
  {
    id: 'SD',
    text: {
      en: 'Sudan',
      de: 'Sudan',
      es: 'Sudán',
      fr: 'Soudan',
      it: 'Sudan',
    },
  },
  {
    id: 'SE',
    text: {
      en: 'Sweden',
      de: 'Schweden',
      es: 'Suecia',
      fr: 'Suède',
      it: 'Svezia',
    },
  },
  {
    id: 'SG',
    text: {
      en: 'Singapore',
      de: 'Singapur',
      es: 'Singapur',
      fr: 'Singapour',
      it: 'Singapore',
    },
  },
  {
    id: 'SH',
    text: {
      en: 'Saint Helena, Ascension and Tristan da Cunha',
      de: 'Die Kronkolonie St. Helena und Nebengebiete',
      es: 'Santa Elena',
      fr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
      it: 'Sant’Elena',
    },
  },
  {
    id: 'SI',
    text: {
      en: 'Slovenia',
      de: 'Slowenien',
      es: 'Eslovenia',
      fr: 'Slovénie',
      it: 'Slovenia',
    },
  },
  {
    id: 'SJ',
    text: {
      en: 'Svalbard and Jan Mayen',
      de: 'Svalbard und Jan Mayen',
      es: 'Svalbard y Jan Mayen',
      fr: "Svalbard et l'Île Jan Mayen",
      it: 'Svalbard e Jan Mayen',
    },
  },
  {
    id: 'SK',
    text: {
      en: 'Slovakia',
      de: 'Slowakei',
      es: 'Eslovaquia',
      fr: 'Slovaquie',
      it: 'Slovacchia',
    },
  },
  {
    id: 'SL',
    text: {
      en: 'Sierra Leone',
      de: 'Sierra Leone, Republik',
      es: 'Sierra Leona',
      fr: 'Sierra Leone',
      it: 'Sierra Leone',
    },
  },
  {
    id: 'SM',
    text: {
      en: 'San Marino',
      de: 'San Marino',
      es: 'San Marino',
      fr: 'Saint-Marin',
      it: 'San Marino',
    },
  },
  {
    id: 'SN',
    text: {
      en: 'Senegal',
      de: 'Senegal',
      es: 'Senegal',
      fr: 'Sénégal',
      it: 'Senegal',
    },
  },
  {
    id: 'SO',
    text: {
      en: 'Somalia',
      de: 'Somalia, Demokratische Republik',
      es: 'Somalia',
      fr: 'Somalie',
      it: 'Somalia',
    },
  },
  {
    id: 'SR',
    text: {
      en: 'Suriname',
      de: 'Suriname',
      es: 'Suriname',
      fr: 'Suriname',
      it: 'Suriname',
    },
  },
  {
    id: 'SS',
    text: {
      en: 'South Sudan',
      de: 'Südsudan',
      es: 'Sudán del Sur',
      fr: 'Soudan du Sud',
      it: 'Sudan del Sud',
    },
  },
  {
    id: 'ST',
    text: {
      en: 'Sao Tome & Principe',
      de: 'São Tomé und Príncipe',
      es: 'Santo Tomé y Príncipe',
      fr: 'Sao Tomé-et-Principe',
      it: 'São Tomé e Príncipe',
    },
  },
  {
    id: 'SV',
    text: {
      en: 'El Salvador',
      de: 'El Salvador',
      es: 'El Salvador',
      fr: 'Salvador',
      it: 'El Salvador',
    },
  },
  {
    id: 'SX',
    text: {
      en: 'Sint Maarten (DUTCH PART)',
      de: 'Sint Maarten (niederländischen Teil)',
      es: 'Sint Maarten (HOLANDA PARTE)',
      fr: 'SAINT-MARTIN (PARTIE NÉERLANDAISE)',
      it: 'Sint Maarten (OLANDESE PARTE)',
    },
  },
  {
    id: 'SY',
    text: {
      en: 'Syrian Arab Republic',
      de: 'Syrien',
      es: 'Siria',
      fr: 'République arabe syrienne',
      it: 'Siria',
    },
  },
  {
    id: 'SZ',
    text: {
      en: 'Eswatini',
      de: 'Eswatini',
      es: 'Eswatini',
      fr: 'Eswatini',
      it: 'Eswatini',
    },
  },
  {
    id: 'TC',
    text: {
      en: 'Turks and Caicos Islands',
      de: 'Turks- und Caicosinseln',
      es: 'Islas Turcas y Caicos',
      fr: 'Turks-et-Caïcos',
      it: 'Isole Turks e Caicos',
    },
  },
  {
    id: 'TD',
    text: {
      en: 'Chad',
      de: 'Tschad, Republik',
      es: 'Chad',
      fr: 'Tchad',
      it: 'Ciad',
    },
  },
  {
    id: 'TF',
    text: {
      en: 'French Southern Territories',
      de: 'Französische Süd- und Antarktisgebiete',
      es: 'Territorios Australes Franceses',
      fr: 'Terres australes françaises',
      it: 'Territori australi francesi',
    },
  },
  {
    id: 'TG',
    text: {
      en: 'Togo',
      de: 'Togo, Republik',
      es: 'Togo',
      fr: 'Togo',
      it: 'Togo',
    },
  },
  {
    id: 'TH',
    text: {
      en: 'Thailand',
      de: 'Thailand',
      es: 'Tailandia',
      fr: 'Thaïlande',
      it: 'Tailandia',
    },
  },
  {
    id: 'TJ',
    text: {
      en: 'Tajikistan',
      de: 'Tadschikistan',
      es: 'Tayikistán',
      fr: 'Tadjikistan',
      it: 'Tagikistan',
    },
  },
  {
    id: 'TK',
    text: {
      en: 'Tokelau',
      de: 'Tokelau',
      es: 'Islas Tokelau',
      fr: 'Tokelau',
      it: 'Tokelau',
    },
  },
  {
    id: 'TL',
    text: {
      en: 'Timor-Leste',
      de: 'Timor-Leste, Demokratische Republik',
      es: 'Timor Oriental',
      fr: 'Timor-Leste',
      it: 'Timor Est',
    },
  },
  {
    id: 'TM',
    text: {
      en: 'Turkmenistan',
      de: 'Turkmenistan',
      es: 'Turkmenistán',
      fr: 'Turkménistan',
      it: 'Turkmenistan',
    },
  },
  {
    id: 'TN',
    text: {
      en: 'Tunisia',
      de: 'Tunesien',
      es: 'Túnez',
      fr: 'Tunisie',
      it: 'Tunisia',
    },
  },
  {
    id: 'TO',
    text: {
      en: 'Tonga',
      de: 'Tonga',
      es: 'Tonga',
      fr: 'Tonga',
      it: 'Tonga',
    },
  },
  {
    id: 'TR',
    text: {
      en: 'Türkiye',
      de: 'Türkei',
      es: 'Turquía',
      fr: 'Turquie',
      it: 'Turchia',
    },
  },
  {
    id: 'TT',
    text: {
      en: 'Trinidad and Tobago',
      de: 'Trinidad und Tobago',
      es: 'Trinidad y Tabago',
      fr: 'Trinité-et-Tobago',
      it: 'Trinidad e Tobago',
    },
  },
  {
    id: 'TV',
    text: {
      en: 'Tuvalu',
      de: 'Tuvalu',
      es: 'Tuvalu',
      fr: 'Tuvalu',
      it: 'Tuvalu',
    },
  },
  {
    id: 'TW',
    text: {
      en: 'Taiwan (Province of China)',
      de: 'Taiwan',
      es: 'Taiwán',
      fr: 'Taïwan (Province de Chine)',
      it: 'Taiwan',
    },
  },
  {
    id: 'TZ',
    text: {
      en: 'Tanzania, United Republic of',
      de: 'Tansania, Vereinigte Republik',
      es: 'Tanzania',
      fr: 'Tanzanie, République-Unie de',
      it: 'Tanzania',
    },
  },
  {
    id: 'UA',
    text: {
      en: 'Ukraine',
      de: 'Ukraine',
      es: 'Ucrania',
      fr: 'Ukraine',
      it: 'Ucraina',
    },
  },
  {
    id: 'UG',
    text: {
      en: 'Uganda',
      de: 'Uganda, Republik',
      es: 'Uganda',
      fr: 'Ouganda',
      it: 'Uganda',
    },
  },
  {
    id: 'UM',
    text: {
      en: 'United States Minor Outlying Islands',
      de: 'Amerikanisch-Ozeanien',
      es: 'Islas menores alejadas de los Estados Unidos',
      fr: 'Îles mineures éloignées des États-Unis',
      it: 'Isole Minori lontane dagli Stati Uniti',
    },
  },
  {
    id: 'US',
    text: {
      en: 'United States of America',
      de: 'Vereinigte Staaten von Amerika',
      es: 'Estados Unidos',
      fr: "États-Unis d'Amérique",
      it: 'Stati Uniti',
    },
  },
  {
    id: 'UY',
    text: {
      en: 'Uruguay',
      de: 'Uruguay',
      es: 'Uruguay',
      fr: 'Uruguay',
      it: 'Uruguay',
    },
  },
  {
    id: 'UZ',
    text: {
      en: 'Uzbekistan',
      de: 'Usbekistan',
      es: 'Uzbekistán',
      fr: 'Ouzbékistan',
      it: 'Uzbekistan',
    },
  },
  {
    id: 'VA',
    text: {
      en: 'Vatican City State',
      de: 'Vatikanstadt',
      es: 'Ciudad del Vaticano',
      fr: 'Vatican / (Saint-Siège)',
      it: 'Vaticano',
    },
  },
  {
    id: 'VC',
    text: {
      en: 'Saint Vincent and the Grenadines',
      de: 'St. Vincent und die Grenadinen (GB)',
      es: 'San Vicente y las Granadinas',
      fr: 'Saint-Vincent-et-les Grenadines',
      it: 'Saint Vincent e Grenadines',
    },
  },
  {
    id: 'VE',
    text: {
      en: 'Venezuela',
      de: 'Venezuela',
      es: 'Venezuela',
      fr: 'Venezuela',
      it: 'Venezuela',
    },
  },
  {
    id: 'VG',
    text: {
      en: 'Virgin Islands (British)',
      de: 'Britische Jungferninseln',
      es: 'Islas Vírgenes Británicas',
      fr: 'Vierges britanniques',
      it: 'Isole Vergini Britanniche',
    },
  },
  {
    id: 'VI',
    text: {
      en: 'Virgin Islands (U.S.)',
      de: 'Amerikanische Jungferninseln',
      es: 'Islas Vírgenes de los Estados Unidos',
      fr: 'Vierges des États-Unis',
      it: 'Isole Vergini Americane',
    },
  },
  {
    id: 'VN',
    text: {
      en: 'Viet Nam',
      de: 'Vietnam',
      es: 'Vietnam',
      fr: 'Viet Nam',
      it: 'Vietnam',
    },
  },
  {
    id: 'VU',
    text: {
      en: 'Vanuatu',
      de: 'Vanuatu',
      es: 'Vanuatu',
      fr: 'Vanuatu',
      it: 'Vanuatu',
    },
  },
  {
    id: 'WF',
    text: {
      en: 'Wallis and Futuna',
      de: 'Wallis und Futuna',
      es: 'Wallis y Futuna',
      fr: 'Wallis-et-Futuna',
      it: 'Wallis e Futuna',
    },
  },
  {
    id: 'WS',
    text: {
      en: 'Samoa',
      de: 'Samoa',
      es: 'Samoa',
      fr: 'Samoa',
      it: 'Samoa',
    },
  },
  {
    id: 'XK',
    text: {
      en: 'Kosovo',
      de: 'Kosovo',
      es: 'Kosovo',
      fr: 'Kosovo',
      it: 'Kosovo',
    },
  },
  {
    id: 'YE',
    text: {
      en: 'Yemen, Republic of',
      de: 'Jemen',
      es: 'Yemen',
      fr: 'Yémen',
      it: 'Yemen',
    },
  },
  {
    id: 'YT',
    text: {
      en: 'Mayotte',
      de: 'Mayotte',
      es: 'Mayotte',
      fr: 'Mayotte',
      it: 'Mayotte',
    },
  },
  {
    id: 'ZA',
    text: {
      en: 'South Africa',
      de: 'Südafrika, Republik',
      es: 'Sudáfrica',
      fr: 'Afrique du Sud',
      it: 'Sudafrica',
    },
  },
  {
    id: 'ZM',
    text: {
      en: 'Zambia',
      de: 'Sambia, Republik',
      es: 'Zambia',
      fr: 'Zambie',
      it: 'Zambia',
    },
  },
  {
    id: 'ZW',
    text: {
      en: 'Zimbabwe',
      de: 'Simbabwe, Republik',
      es: 'Zimbabue',
      fr: 'Zimbabwe',
      it: 'Zimbabwe',
    },
  },
];
