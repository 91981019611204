export type ConfirmationState = Record<string, boolean>;

export interface AuditDraftURLParams {
  supplierId: string;
  auditDraftId: string;
}

export interface AuditDraftCommonRequestParams {
  auditDraftId: string;
}

export enum RequestModeEnum {
  CREATE = 'create',
  UPDATE = 'update',
}
