import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { StandardOptionsListResponse } from './types';
import { endpoints } from '../../config/endpoints';

const certificateDraftStandardOptionsListApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getStandardOptionsList: build.query<StandardOptionsListResponse, void>({
      query: () => ({
        url: endpoints.STANDARD_OPTIONS_LIST,
      }),
      providesTags: ['CERTIFICATE_DRAFT_STANDARD_OPTIONS'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const useGetStandardOptionsListQuery = () => {
  const query =
    certificateDraftStandardOptionsListApi.useGetStandardOptionsListQuery();

  const error = useFormattedError(query, 'formatToAlerts');
  const status = useRTKQueryStatus(query);

  return {
    ...query,
    standards: query.data?.modules,
    status,
    error,
  };
};
