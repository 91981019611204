import { useState } from 'react';

import { Nullable } from 'types';

import { SortStateType } from './types';

interface UseSorting {
  sortState: Nullable<SortStateType>;
  onSortClick: (sortState: Nullable<SortStateType>) => void;
}

export const useSorting = (): UseSorting => {
  const [sortState, onSortClick] = useState<Nullable<SortStateType>>(null);

  return {
    sortState,
    onSortClick,
  };
};
