import React, { FC } from 'react';

import { DiaryAuditAppointmentsTable } from 'domains/auditAppointment/components/organisms/DiaryAuditAppointmentsTable';

import { DiaryAuditAppointmentsFilters } from '../DiaryAuditAppointmentsFilters';

export const DiaryAuditAppointmentsTab: FC = () => (
  <>
    <DiaryAuditAppointmentsFilters />
    <DiaryAuditAppointmentsTable />
  </>
);
