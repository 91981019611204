import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { Document } from 'types';
import { externalServiceInstance } from 'utils/apiClient/axiosInstance';
import { downloadFile } from 'utils/downloadFile';
import { getBaseQueryError } from 'utils/getBaseQueryError';

import { DocumentsParams, DownloadDocumentParams } from './types';
import endpoints from '../../config/endpoints';

const certificateDetailsDocumentsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateDetailsDocuments: build.query<Document[], DocumentsParams>({
      query: (certificateDetailsParams: DocumentsParams) => ({
        url: endpoints.CERTIFICATE_TAB.LIST(
          certificateDetailsParams.certificateUuid
        ),
      }),
      providesTags: ['CERTIFICATE_DETAILS_DOCUMENTS'],
      keepUnusedDataFor: 10,
    }),
    downloadAuditCertificateDocument: build.mutation<
      unknown,
      DownloadDocumentParams
    >({
      async queryFn(
        { certificateUuid, documentUuid, fileName },
        _,
        extraOptions,
        baseQuery
      ) {
        const res = (await baseQuery({
          url: endpoints.CERTIFICATE_TAB.DOWNLOAD(
            certificateUuid,
            documentUuid
          ),
        })) as QueryReturnValue<{ url: string }>;

        if (res?.data?.url) {
          try {
            const gcpResponse = await externalServiceInstance({
              url: res.data.url,
              responseType: 'arraybuffer',
            });

            await downloadFile(gcpResponse.data as ArrayBuffer, fileName);
          } catch {
            return getBaseQueryError();
          }
        }

        if (res.error) {
          return getBaseQueryError();
        }

        return { data: null };
      },
    }),
  }),
});

export const useGetCertificateDetailsDocumentsQuery = (
  certificateDetailsParams: DocumentsParams,
  skip = false
) => {
  const query =
    certificateDetailsDocumentsApi.useGetCertificateDetailsDocumentsQuery(
      certificateDetailsParams,
      { skip }
    );
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    certificateDocuments: query.data || [],
    error,
    status,
  };
};

export const useDownloadAuditCertificateDocumentMutation = () => {
  const [downloadFileViaAPI] =
    certificateDetailsDocumentsApi.useDownloadAuditCertificateDocumentMutation();

  const requestMethod = (params: DownloadDocumentParams) =>
    downloadFileViaAPI(params).unwrap();

  return {
    downloadFile: requestMethod,
  };
};
