import React, { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Select } from 'components/atoms/Select';
import { Textarea } from 'components/atoms/Textarea';
import { TextField } from 'components/atoms/TextField';
import { RadioGroup } from 'components/molecules/RadioGroup';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';
import { CertificateLockEnum, SelectOption } from 'types';

import { useModalForm } from '../../../hooks/useModalForm';
import { StyledInputWrapper } from '../../atoms/StyledInputWrapper';
import { StyledLabel } from '../../atoms/StyledLabel';
import { CertificateDetailsModalContentBase } from '../../templates/CertificateDetailsModalContentBase';

interface LockCertificateFormProps {
  reasonCategories: SelectOption[];
  actionReasonOptions: SelectOption[];
  customHeading?: ReactNode;
  showEmailCheckbox?: boolean;
  categoriesForRequiredDescription?: string[];
  errors?: string[];
  isUpdate?: boolean;
}

export const LockCertificateForm: FC<LockCertificateFormProps> = (props) => {
  const {
    reasonCategories,
    actionReasonOptions,
    customHeading,
    showEmailCheckbox = false,
    categoriesForRequiredDescription = ['others'],
    errors = [],
    isUpdate = false,
  } = props;
  const [t] = useTranslation('certificateDetails');
  const isAdmin = useSelector(selectUserIsAdmin);
  const {
    category,
    categoryOthersDescription,
    descriptionEnglish,
    descriptionOtherLanguage,
    type,
    setFormValue,
  } = useModalForm();
  const showCategoryDescription =
    categoriesForRequiredDescription.includes(category);
  const alertsState = useMemoizedAlerts(errors);
  const enableRadioButton = (isAdmin && isUpdate) || !isUpdate;
  const typeOptions = [
    {
      id: CertificateLockEnum.SUSPENSION,
      name: t('Suspension'),
      enabled: enableRadioButton,
    },
    {
      id: CertificateLockEnum.WITHDRAWAL,
      name: t('Withdrawal'),
      enabled: enableRadioButton,
    },
  ];

  useEffect(() => {
    if (!showCategoryDescription && categoryOthersDescription) {
      // reset value of hidable field if disappear
      setFormValue('categoryOthersDescription', '');
    }
  }, [showCategoryDescription, categoryOthersDescription, setFormValue]);

  return (
    <CertificateDetailsModalContentBase
      actionReasonOptions={actionReasonOptions}
      alertsState={alertsState}
      showEmailCheckbox={showEmailCheckbox}
    >
      {customHeading}
      <StyledInputWrapper>
        <StyledLabel>
          {t(
            'Do you want suspend or withdraw certificate/letter of confirmation?'
          )}
        </StyledLabel>
        <RadioGroup
          value={type}
          options={typeOptions}
          onChange={(val) => setFormValue('type', val)}
        />
      </StyledInputWrapper>

      <StyledInputWrapper $largerMargin>
        <StyledLabel htmlFor="category">
          {t('Lock reason category')}:
        </StyledLabel>
        <Select
          fullWidth
          variant="outlined"
          placeholder={t('Please choose!', { ns: 'components' })}
          options={reasonCategories}
          value={category}
          onChange={(ev) => setFormValue('category', ev.target.value as string)}
          id="category"
        />
      </StyledInputWrapper>

      {showCategoryDescription && (
        <StyledInputWrapper $largerMargin>
          <StyledLabel htmlFor="categoryOthersDescription">
            {t('Description (Others)')}:
          </StyledLabel>
          <TextField
            fullWidth
            value={categoryOthersDescription || ''}
            onChange={(ev) =>
              setFormValue('categoryOthersDescription', ev.target.value)
            }
            id="categoryOthersDescription"
          />
        </StyledInputWrapper>
      )}

      <StyledInputWrapper>
        <StyledLabel htmlFor="descriptionEnglish">
          {t('Description (English)')}:
        </StyledLabel>
        <Textarea
          fullWidth
          value={descriptionEnglish}
          onChange={(ev) => setFormValue('descriptionEnglish', ev.target.value)}
          multiline
          id="descriptionEnglish"
        />
      </StyledInputWrapper>

      <StyledInputWrapper>
        <StyledLabel htmlFor="descriptionOtherLanguage">
          {t('Description (other language)')}:
        </StyledLabel>
        <Textarea
          fullWidth
          value={descriptionOtherLanguage}
          onChange={(ev) =>
            setFormValue('descriptionOtherLanguage', ev.target.value)
          }
          multiline
          id="descriptionOtherLanguage"
        />
      </StyledInputWrapper>
    </CertificateDetailsModalContentBase>
  );
};
