import { styled } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import React, { FC } from 'react';

import { TEXT } from 'config/appColors';

const StyledStepDescription = styled(Typography)`
  color: ${TEXT.PRIMARY};
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  font-weight: 400;
`;

interface StepDescriptionProps {
  content: string;
  className?: string;
  variant?: TypographyProps['variant'];
}

export const StepDescription: FC<StepDescriptionProps> = ({
  content,
  className,
  variant,
}) => (
  <StyledStepDescription variant={variant} className={className}>
    {content}
  </StyledStepDescription>
);
