import {
  Card,
  CardContent,
  CardHeader,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/molecules/Loader';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import {
  BLACK,
  MOSTLY_WHITE_GRAY,
  SECONDARY,
  SPANISH_GRAY,
  WHITE,
} from 'config/appColors';
import { getSupplierPathname } from 'utils/getSupplierPathname';

import { useGetSuppliersQuery } from '../../state/overview/api';

const StyledContainer = styled('div')`
  padding: 0 4rem 2rem;
`;

const StyledCard = styled(Card)`
  background-color: ${WHITE};
`;

const StyledCardHeader = styled(CardHeader)`
  .MuiCardHeader-title {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.5rem;
    letter-spacing: 0.015rem;
    color: ${BLACK};
  }

  &.MuiCardHeader-root {
    border-bottom: 1px solid ${SPANISH_GRAY};
  }
`;

const StyledTableContainer = styled(TableContainer)`
  .MuiTableCell-root {
    color: ${BLACK};
    font-size: 1.4rem;
    font-weight: 400;
    border-bottom-color: ${SECONDARY[100]};

    &.MuiTableCell-head {
      font-weight: 500;
      border-bottom-width: 3px;
    }
  }

  .MuiTableRow-root {
    &.MuiTableRow-hover:hover {
      background-color: ${MOSTLY_WHITE_GRAY};
      cursor: pointer;
    }
  }
`;

export const PageSupplierOverview: FC = () => {
  const [t] = useTranslation('supplier');
  const { push } = useHistory();
  const { error, suppliersList, status } = useGetSuppliersQuery();

  return (
    <TemplatePageDefault
      pageTitle={t('Supplier Overview', { ns: 'components' })}
    >
      <StyledContainer>
        <StyledCard>
          <StyledCardHeader title={t('Suppliers')} />
          <CardContent>
            <Loader error={error?.message} status={status}>
              <StyledTableContainer>
                <Table aria-label="supplier-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('COID')}</TableCell>
                      <TableCell>{t('Name')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {suppliersList.map((supplier) => (
                      <TableRow
                        hover
                        key={supplier.uuid}
                        onClick={() => push(getSupplierPathname(supplier.uuid))}
                      >
                        <TableCell>{supplier.coid}</TableCell>
                        <TableCell>{supplier.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </Loader>
          </CardContent>
        </StyledCard>
      </StyledContainer>
    </TemplatePageDefault>
  );
};

export default PageSupplierOverview;
