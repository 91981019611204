import { useMemo } from 'react';

import { AlertsState, AlertVariantEnum } from '../types';

export const useMemoizedAlerts = (
  alerts: string[],
  variant = AlertVariantEnum.ERROR
): AlertsState =>
  useMemo(
    () => ({
      alerts,
      variant,
    }),
    [alerts, variant]
  );
