import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ProcessStatusIndicator } from 'components/molecules/ProcessStatusIndicator';
import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { historicalAuditStatuses } from 'domains/auditAppointment/config/constants';
import { AppointmentsDateRangeCell } from 'domains/supplier/components/atoms/AppointmentsDateRangeCell';
import { CertificationBodyCell } from 'domains/supplier/components/atoms/CertificationBodyCell';
import { useGetAuditsAppointmentsQuery } from 'domains/supplier/state/appointmentList/api';
import { selectSupplierDetails } from 'domains/supplier/state/details/slice';
import { AuditAppointment } from 'domains/supplier/types';
import { useAuditAppointmentDetailsLink } from 'hooks/useAuditAppointmentDetailsLink';
import { ProcessStatus } from 'types';

interface AuditsAppointmentsTableProps {
  historicalAudits?: boolean;
}

export const AuditsAppointmentsTable: FC<AuditsAppointmentsTableProps> = (
  props
) => {
  const { historicalAudits } = props;
  const [t] = useTranslation('supplier');

  const supplierId = useSelector(selectSupplierDetails)?.uuid || '';
  const { pagination, limit, offset, setTotalRowsAmount } = usePagination();
  const currentStatus = historicalAudits
    ? historicalAuditStatuses
    : [ProcessStatus.OPEN];

  const {
    auditsAppointments,
    status: requestStatus,
    error,
  } = useGetAuditsAppointmentsQuery({
    limit,
    offset,
    status: currentStatus,
    supplierId,
  });

  useEffect(() => {
    setTotalRowsAmount(auditsAppointments.total);
  }, [auditsAppointments.total, setTotalRowsAmount]);

  const handleRowClick = useAuditAppointmentDetailsLink();
  const { getColumnConfig, getColumnConfigWithEllipsis } =
    useColumnConfig<AuditAppointment>('10%');
  const columns: Column<AuditAppointment>[] = [
    {
      customCell: AppointmentsDateRangeCell,
      headerName: t('Date'),
      ...getColumnConfig('15%'),
    },
    {
      customCell: ProcessStatusIndicator,
      headerName: t('Status'),
      ...getColumnConfig(),
    },
    {
      customCell: ({ type }) => t(type),
      headerName: t('Type'),
      ...getColumnConfig(),
    },
    {
      customCell: ({ kind }) => t(kind),
      headerName: t('Kind'),
      ...getColumnConfig(),
    },
    {
      customCell: ({ module }) => module?.name,
      headerName: t('Standard/program', { ns: 'components' }),
      ...getColumnConfigWithEllipsis('15%'),
    },
    {
      customCell: ({ certificationBody }) => (
        <CertificationBodyCell>
          {certificationBody?.name || ''}
        </CertificationBodyCell>
      ),
      headerName: t('Certification Body', { ns: 'auditAppointment' }),
      ...getColumnConfigWithEllipsis('17.5%'),
    },
    {
      field: 'description',
      headerName: t('Comment'),
      ...getColumnConfigWithEllipsis('17.5%'),
    },
  ];

  return (
    <Table
      rows={auditsAppointments.appointments}
      columns={columns}
      onRowClick={handleRowClick}
      status={requestStatus}
      error={error}
      pagination={pagination}
      dense
    />
  );
};
