import React from 'react';
import { useTranslation } from 'react-i18next';

import { LogInfoCell } from 'components/atoms/LogInfoCell';
import { Column, useColumnConfig } from 'components/organisms/Table';
import { Log } from 'types';
import { formatDate } from 'utils/dates';

export const useLogColumns = (): Column<Log>[] => {
  const [t] = useTranslation('components');
  const { getColumnConfig } = useColumnConfig<Log>('15%');

  return [
    {
      field: 'action',
      headerName: t('Action'),
      ...getColumnConfig('20%'),
    },
    {
      field: 'executedAt',
      headerName: t('Executed at'),
      customCell: ({ executedAt }) =>
        `${formatDate(executedAt.split(' ')[0])} ${executedAt.split(' ')[1]}`,
      ...getColumnConfig('20%'),
    },
    {
      field: 'executedBy',
      headerName: t('Executed by'),
      ...getColumnConfig('20%'),
    },
    {
      field: 'info',
      headerName: t('Info'),
      customCell: ({ info }, cellId) => (
        <LogInfoCell info={info} cellId={cellId} />
      ),
      ...getColumnConfig('40%'),
    },
  ];
};
