import { styled } from '@mui/material';
import List from '@mui/material/List';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import routes from 'config/routes';
import { resetAllAuditsListState } from 'domains/adminReports/state/allAuditsList/slice';
// import { useSidebarMenuSubmenu } from 'hooks/useSidebarMenuSubmenu';

import { SidebarMenuItem } from '../../atoms/SidebarMenuItem';
// import { SidebarMenuSubmenu } from '../../molecules/SidebarMenuSubmenu';

const StyledListNav = styled(List)`
  width: 100%;
  padding-top: 0;
` as typeof List;

export const SidebarMenu: FC = () => {
  const [t] = useTranslation('components');
  // const { reportsSubmenuIsOpen, toggleReportsSubmenu, reportsSubmenuItems } =
  //   useSidebarMenuSubmenu();
  const dispatch = useDispatch();

  return (
    <StyledListNav component="nav">
      <SidebarMenuItem
        label={t('All Audits')}
        url={routes.ADMIN_REPORTS_ALL_AUDITS}
        actionBeforeClick={() => {
          // reset state when same page via menu is clicked
          dispatch(resetAllAuditsListState());
        }}
      />
      {/* <SidebarMenuSubmenu
        label={t('Reports')}
        open={reportsSubmenuIsOpen}
        toggleMethod={toggleReportsSubmenu}
        items={reportsSubmenuItems}
      /> */}
    </StyledListNav>
  );
};
