import { ReactNode } from 'react';

export enum BannerVariantEnum {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
}

export interface BannerProps {
  variant?: BannerVariantEnum;
  customTitle?: ReactNode;
  description?: ReactNode;
  hideCloseButton?: boolean;
  actionButtonLabel?: ReactNode;
  actionButtonClickHandler?: () => void;
  customIcon?: ReactNode;
  hideIcon?: boolean;
}

export interface StyledWrapperProps {
  $borderColor: string;
}

export interface StyledDescriptionProps {
  $color: string;
}

export interface StyledIconWrapperProps {
  $color: string;
}
