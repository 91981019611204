import { LANG_PARAM } from 'config/languages';

const endpoints = {
  SUPPLIERS: {
    LIST: `/${LANG_PARAM}/suppliers`,
    DETAILS: (supplierId: string): string =>
      `/${LANG_PARAM}/suppliers/${supplierId}`,
    DETAILS_ADDRESSES: (supplierId: string): string =>
      `/${LANG_PARAM}/suppliers/${supplierId}/addresses`, // TODO: adjust endpoint once it's established
    DETAILS_CONTACTS: (supplierId: string): string =>
      `/${LANG_PARAM}/suppliers/${supplierId}/contacts`, // TODO: adjust endpoint once established
    DETAILS_APPOINTMENTS: (supplierId: string): string =>
      `/${LANG_PARAM}/suppliers/${supplierId}/appointments`,
  },
  AUDIT: {
    LIST: (supplierId: string): string =>
      `/${LANG_PARAM}/suppliers/${supplierId}/audits`,
  },
  CERTIFICATE: {
    LIST: (supplierId: string): string =>
      `/${LANG_PARAM}/suppliers/${supplierId}/certificates`,
  },
};

export default endpoints;
