import { LANG_PARAM } from 'config/languages';

export const endpoints = {
  CREATE_CERTIFICATE_DRAFT: `/${LANG_PARAM}/certificate-drafts/create-by-form`,
  DATA: {
    ADD: (certificateDraftId: string): string =>
      `/${LANG_PARAM}/certificate-drafts/${certificateDraftId}/data`,
  },
  DETAILS: (certificateDraftId: string): string =>
    `/${LANG_PARAM}/certificate-drafts/${certificateDraftId}`,
  DOCUMENT_DEFINITIONS: (certificateDraftId: string): string =>
    `/${LANG_PARAM}/certificate-drafts/${certificateDraftId}/document-definitions`,
  DOCUMENT_MANAGE: {
    ADD_DOCUMENT: (certificateDraftId: string): string =>
      `/${LANG_PARAM}/certificate-drafts/${certificateDraftId}/documents`,
    DELETE_DOCUMENT: (certificateDraftId: string, documentId: string): string =>
      `/${LANG_PARAM}/certificate-drafts/${certificateDraftId}/documents/${documentId}`,
  },
  SET_ACTION_COMMENT: (certificateDraftId: string): string =>
    `/${LANG_PARAM}/certificate-drafts/${certificateDraftId}/action-comments`,
  SET_COMMENT: (certificateDraftId: string): string =>
    `/${LANG_PARAM}/certificate-drafts/${certificateDraftId}/comments`,
  SET_REASON: (certificateDraftId: string): string =>
    `/${LANG_PARAM}/certificate-drafts/${certificateDraftId}/update-reasons`,
  SUBMIT: (certificateDraftId: string): string =>
    `/${LANG_PARAM}/certificate-drafts/${certificateDraftId}/submit`,
  STANDARD_OPTIONS_LIST: `/${LANG_PARAM}/certificate-drafts/preflights/allowedModules`,
  TEMPLATE: (certificateDraftId: string): string =>
    `/${LANG_PARAM}/certificate-drafts/${certificateDraftId}/templates`,
};
