import { styled } from '@mui/material';
import React, {
  // ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';

// import { Checkbox } from 'components/atoms/Checkbox';
import Loader from 'components/molecules/Loader';
import { RadioGroup } from 'components/molecules/RadioGroup';
// import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { Nullable } from 'types';
import convertFileToBase64 from 'utils/convertFileToBase64';
import { FileTypeValidator } from 'utils/fileTypeValidator';
import { isAnyLoading } from 'utils/isAnyLoading';

import {
  useCreateAuditDraftViaAXPXFileMutation,
  useCreateAuditDraftViaFileMutation,
  useSendFileAndGetModulesMutation,
} from '../../../state/regularUpload/api';
import { TemplateFileUploadForm } from '../../templates/TemplateFileUploadForm';

const StyledRadioGroup = styled(RadioGroup)`
  margin-left: 11px;
`;

const StyledLoader = styled(Loader)`
  .loader {
    margin-top: 50px;
  }
`;

// const StyledGroupCertificateCheckboxTitle = styled('p')`
//   margin: 20px 0 10px;
//   font-weight: 600;
// `;
//
// const StyledCheckbox = styled(Checkbox)`
//   .MuiFormControlLabel-label {
//     margin-top: 3px;
//   }
// `;

interface AuditFileUploadFormProps {
  supplierId: string;
  selectedModule: string;
  setSelectedModule: Dispatch<SetStateAction<Nullable<string>>>;
  setAuditFile: Dispatch<SetStateAction<Nullable<File>>>;
  // PL4FE-412 - temporary disabled
  // eslint-disable-next-line react/no-unused-prop-types
  isGroupCertificationAudit: boolean;
  // PL4FE-412 - temporary disabled
  // eslint-disable-next-line react/no-unused-prop-types
  setIsGroupCertificationAudit: Dispatch<boolean>;
}

export const AuditFileUploadForm: FC<AuditFileUploadFormProps> = (props) => {
  const {
    supplierId,
    setAuditFile,
    selectedModule,
    setSelectedModule,
    // isGroupCertificationAudit,
    // setIsGroupCertificationAudit,
  } = props;
  const [t] = useTranslation('auditFileUpload');
  const {
    sendFileAndGetModules,
    modules,
    error: modulesError,
    hints: modulesHints,
    resetModules,
    status: modulesStatus,
  } = useSendFileAndGetModulesMutation();
  const {
    error: createRequestError,
    status: createRequestStatus,
    resetCreateAuditDraftState,
  } = useCreateAuditDraftViaFileMutation();
  const {
    error: createViaAxpxRequestError,
    status: createViaAxpxRequestStatus,
    resetCreateAuditDraftState: resetCreateViaAxpxAuditDraftState,
  } = useCreateAuditDraftViaAXPXFileMutation();
  const error = modulesError || createRequestError || createViaAxpxRequestError;
  const isLoading = isAnyLoading(
    modulesStatus,
    createRequestStatus,
    createViaAxpxRequestStatus
  );
  // const isAdmin = useSelector(selectUserIsAdmin);

  const onFileChange = async (files: File[]) => {
    if (files.length) {
      const file = files[0];
      const convertedFile = await convertFileToBase64(file);
      setAuditFile(file);
      if (FileTypeValidator.isIfs(file)) {
        sendFileAndGetModules({
          auditFile: convertedFile,
          supplierId,
        });
      }
    } else {
      setAuditFile(null);
      resetCreateAuditDraftState();
      resetCreateViaAxpxAuditDraftState();
      resetModules();
    }
  };

  // const handleCheckboxChange = (ev: ChangeEvent<HTMLInputElement>) =>
  //   setIsGroupCertificationAudit(ev.target.checked);

  useEffect(() => {
    const enabledModules = modules.filter(({ enabled }) => enabled);

    if (!modules.length && selectedModule) {
      // reset selected module when modules were reset
      setSelectedModule(null);
    } else if (!selectedModule && enabledModules.length === 1) {
      // set when is only one available module
      setSelectedModule(enabledModules[0].id);
    }
  }, [selectedModule, modules, setSelectedModule]);

  return (
    <TemplateFileUploadForm
      alerts={error || modulesHints}
      isErrorAlert={!!error}
      onFileChange={onFileChange}
      hideRemoveButton={isLoading}
    >
      <StyledLoader status={modulesStatus}>
        {modules.length > 0 && (
          <div>
            <p>{t('Please select the Standard/Program you like to import.')}</p>
            <StyledRadioGroup
              value={selectedModule}
              onChange={setSelectedModule}
              options={modules}
            />
          </div>
        )}
      </StyledLoader>
      {/* PL4FE-412 - temporary commented */}
      {/* {isAdmin && ( */}
      {/*   <div> */}
      {/*     <StyledGroupCertificateCheckboxTitle> */}
      {/*       {t('Admin option for Audit Upload')} */}
      {/*     </StyledGroupCertificateCheckboxTitle> */}
      {/*     <StyledCheckbox */}
      {/*       onChange={handleCheckboxChange} */}
      {/*       id="group-certificate" */}
      {/*       label={t('Audit is for Group Certificate')} */}
      {/*       value="true" */}
      {/*       checked={isGroupCertificationAudit} */}
      {/*     /> */}
      {/*   </div> */}
      {/* )} */}
    </TemplateFileUploadForm>
  );
};
