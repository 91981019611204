import {
  ActionReasonOption,
  BackAction,
  DraftStepCmp,
  NextAction,
  RoleMapper,
  TranslatedText,
} from 'types';

export enum AuditDraftStepEnum {
  DATA_CONFIRMATION = 'dataConfirmationStep',
  AUDITORS = 'auditorsStep',
  APPOINTMENT_SELECTION = 'appointmentSelectionStep',
  DOCUMENT_UPLOAD = 'documentUploadStep',
  COMMENT = 'commentStep',
  CONFIRMATION_SUBMIT = 'confirmationSubmitStep',
  REASON = 'reasonStep',
}

interface StepProps<Id extends AuditDraftStepEnum> {
  id: Id;
  title: TranslatedText;
  instructionText: TranslatedText;
  backAction: BackAction;
  nextAction: NextAction;
}

export interface FormDataConfirmationStep {
  type: 'step';
  props: StepProps<AuditDraftStepEnum.DATA_CONFIRMATION> & {
    noDataText: TranslatedText;
  };
  children: DraftStepCmp[];
}

export const isFormDataConfirmationStep = (
  step: unknown
): step is FormDataConfirmationStep =>
  !!step &&
  (step as FormDataConfirmationStep).props.id ===
    AuditDraftStepEnum.DATA_CONFIRMATION;

export interface FormAppointmentSelectionStep {
  type: 'step';
  props: StepProps<AuditDraftStepEnum.APPOINTMENT_SELECTION> & {
    noDataText: TranslatedText;
  };
  children: null;
}

export const isFormAppointmentSelectionStep = (
  step: unknown
): step is FormAppointmentSelectionStep =>
  !!step &&
  (step as FormAppointmentSelectionStep).props.id ===
    AuditDraftStepEnum.APPOINTMENT_SELECTION;

export interface FormDocumentUploadStep {
  type: 'step';
  props: StepProps<AuditDraftStepEnum.DOCUMENT_UPLOAD>;
  children: null;
}

export const isFormDocumentUploadStep = (
  step: unknown
): step is FormDocumentUploadStep =>
  !!step &&
  (step as FormDocumentUploadStep).props.id ===
    AuditDraftStepEnum.DOCUMENT_UPLOAD;

export interface FormCommentStep {
  type: 'step';
  props: StepProps<AuditDraftStepEnum.COMMENT> & {
    actionReasonOptions: ActionReasonOption[];
  };
  children: null;
}

export const isFormCommentStep = (step: unknown): step is FormCommentStep =>
  !!step && (step as FormCommentStep).props.id === AuditDraftStepEnum.COMMENT;

export interface FormConfirmationSubmitStep {
  type: 'step';
  props: StepProps<AuditDraftStepEnum.CONFIRMATION_SUBMIT> & {
    successMessage: TranslatedText;
    closeAction: NextAction;
  };
  children: DraftStepCmp[];
}

export const isFormConfirmationSubmitStep = (
  step: unknown
): step is FormConfirmationSubmitStep =>
  !!step &&
  (step as FormConfirmationSubmitStep).props.id ===
    AuditDraftStepEnum.CONFIRMATION_SUBMIT;

export interface FormReasonStep {
  type: 'step';
  props: Omit<StepProps<AuditDraftStepEnum.REASON>, 'instructionText'> & {
    actionReasonOptions: ActionReasonOption[];
    updateOptions: ActionReasonOption[];
    labelUpdateReasonSelect: TranslatedText;
    labelUpdateReasonDescription: TranslatedText;
    hintUpdateReasonDescription: TranslatedText;
  };
  children: null;
}

export const isFormReasonStep = (step: unknown): step is FormReasonStep =>
  !!step && (step as FormReasonStep).props.id === AuditDraftStepEnum.REASON;

export interface FormAuditorsStep {
  type: 'step';
  props: StepProps<AuditDraftStepEnum.AUDITORS> & {
    noDataText: TranslatedText;
    roleMapper: RoleMapper[];
  };
  children: null;
}

export const isFormAuditorsStep = (step: unknown): step is FormAuditorsStep =>
  !!step && (step as FormAuditorsStep).props.id === AuditDraftStepEnum.AUDITORS;

export type AuditDraftStep =
  | FormDataConfirmationStep
  | FormAuditorsStep
  | FormAppointmentSelectionStep
  | FormDocumentUploadStep
  | FormCommentStep
  | FormConfirmationSubmitStep
  | FormReasonStep;
