import { Card, styled } from '@mui/material';
import React, { FC } from 'react';

import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { ARGENT } from 'config/appColors';

import { StandardSelection } from '../../components/organisms/StandardSelection';

const ContentWrapper = styled('div')`
  padding: 4rem 10.5rem;
`;

const StyledCard = styled(Card)`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;

  .MuiDialogActions-root {
    padding-top: 16px;
    border-top: 1px ${ARGENT} solid;
  }
`;

export const PageCertificateDraftCreate: FC = () => (
  <TemplatePageDefault hidePageHeader>
    <ContentWrapper>
      <StyledCard>
        <StandardSelection />
      </StyledCard>
    </ContentWrapper>
  </TemplatePageDefault>
);
