import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { ResetAppointmentParams } from './types';
import { endpoints } from '../../config/endpoints';

const resetAppointmentApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    resetAuditDraftAppointment: build.mutation<void, string>({
      query: (auditDraftId) => ({
        url: endpoints.APPOINTMENTS(auditDraftId),
        method: 'DELETE',
      }),
    }),
  }),
});

export const useResetAuditDraftAppointmentMutation = () => {
  const [resetAppointment, mutation] =
    resetAppointmentApi.useResetAuditDraftAppointmentMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: ResetAppointmentParams) =>
    resetAppointment(params.auditDraftId).unwrap();

  return {
    ...mutation,
    resetAppointment: requestMethod,
    status,
    error,
  };
};
