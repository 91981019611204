import { omit } from 'lodash';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { certificateLockSchema, paginationWrapperSchema } from 'schemas';
import { apiSlice } from 'store/apiSlice';
import { validateAndLogErrors } from 'utils/schemaValidator';

import { LockHistoryParams, LockHistoryResponse } from './types';
import endpoints from '../../config/endpoints';

const certificateDetailsLockHistoryTabApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateLockHistory: build.query<
      LockHistoryResponse,
      LockHistoryParams
    >({
      query: (params) => ({
        url: endpoints.CERTIFICATE_LOCK.LOCK_HISTORY(params.certificateUuid),
        params: omit(params, ['certificateUuid']),
      }),
      providesTags: ['CERTIFICATE_DETAILS_LOCK_HISTORY'],
      keepUnusedDataFor: 10,
      transformResponse: validateAndLogErrors(
        paginationWrapperSchema(certificateLockSchema, 'locks')
      ),
    }),
  }),
});

export const useGetCertificateLockHistoryQuery = (
  params: LockHistoryParams
) => {
  const query =
    certificateDetailsLockHistoryTabApi.useGetCertificateLockHistoryQuery(
      params
    );
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    locks: query.data?.locks || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};
