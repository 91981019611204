import React, { FC } from 'react';

import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { FormDocumentUploadStep } from 'domains/auditDraft/types';
import { LoaderStatusEnum } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useGetAuditDraftDocumentDefinitionsQuery } from '../../../state/documentDefinitions/api';
import { TemplateAuditDraftStep } from '../../templates/TemplateAuditDraftStep';
import { DocumentUpload } from '../DocumentUpload';

type FormDocumentUploadProps = FormDocumentUploadStep['props'] & {
  auditDraftId: string;
};

export const FormDocumentUpload: FC<FormDocumentUploadProps> = (props) => {
  const { auditDraftId, backAction, nextAction, title } = props;

  const { allRequiredDocumentsFulfilled, status: documentDefinitionsStatus } =
    useGetAuditDraftDocumentDefinitionsQuery({ auditDraftId });

  const disableNextBtn =
    !allRequiredDocumentsFulfilled ||
    documentDefinitionsStatus !== LoaderStatusEnum.SUCCESS;

  return (
    <TemplateAuditDraftStep
      backAction={backAction}
      nextAction={nextAction}
      disableNext={disableNextBtn}
    >
      <StepFormTitle content={getTranslatedValue(title)} />
      <DocumentUpload {...props} />
    </TemplateAuditDraftStep>
  );
};
