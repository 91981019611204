import { AdminAudit } from 'domains/adminReports/types';
import { Nullable } from 'types';

export enum AdminReportsModalEnum {
  AUDIT_LOGS = 'audit-logs',
  LOCK_HISTORY = 'lock-history',
  SCOPES = 'scopes',
}

export interface AdminReportsModalState {
  openModalName: Nullable<AdminReportsModalEnum>;
  adminAudit: Nullable<AdminAudit>;
}
