import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { validateAndLogErrors } from 'utils/schemaValidator';

import { AuditDetailsParams } from './types';
import endpoints from '../../config/endpoints';
import { auditDetailsSchema } from '../../schemas/AuditDetails';
import { AuditDetails } from '../../types';

const auditDetailsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditDetails: build.query<AuditDetails, AuditDetailsParams>({
      query: (auditDetailsParams: AuditDetailsParams) => ({
        url: endpoints.DETAILS(auditDetailsParams.auditUuid),
        params: auditDetailsParams.auditVersion,
      }),
      providesTags: ['AUDIT_DETAILS'],
      transformResponse: validateAndLogErrors(auditDetailsSchema),
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetAuditDetailsQuery = (
  auditDetailsParams: AuditDetailsParams
) => {
  const query = auditDetailsApi.useGetAuditDetailsQuery(auditDetailsParams);
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    auditDetails: query.data,
    error,
    status,
  };
};
