import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getSupplierDetails } from 'domains/supplier/state/details/slice';

import { AuditsTab } from '../AuditsTab';

const StyledContainer = styled('div')`
  padding: 20px;
`;

export const WidgetAudits: FC = () => {
  const dispatch = useDispatch();
  const { supplierId } = useParams<{ supplierId: string }>();

  useEffect(() => {
    dispatch(getSupplierDetails({ supplierId }));
  }, [dispatch, supplierId]);

  return (
    <StyledContainer>
      <AuditsTab />
    </StyledContainer>
  );
};
