import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PRIMARY } from 'config/appColors';
import { yiiUrls } from 'config/yiiUrls';
import { selectIsLogged } from 'domains/user/state/auth/slice';

import { AppLogo } from '../../atoms/AppLogo';
import { UserAvatarButton } from '../../atoms/UserAvatarButton';
import { DropdownMenu } from '../../molecules/DropdownMenu';

const StyledTopbarWrapper = styled('header')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background: ${PRIMARY.MAIN};
`;
const StyledDropdownMenu = styled(DropdownMenu)`
  margin-left: auto;
`;

export interface AppTopbarProps {
  className?: string;
}

export const AppTopbar: FC<AppTopbarProps> = ({ className }) => {
  const isLogged = useSelector(selectIsLogged);
  const [t] = useTranslation('components');
  const options = [
    {
      label: t('Logout'),
      icon: <ExitToAppIcon />,
      onClickAction: () => {
        window.location.replace(yiiUrls.LOGOUT);
      },
    },
  ];

  return (
    <StyledTopbarWrapper className={className}>
      <AppLogo />
      {isLogged && (
        <StyledDropdownMenu
          options={options}
          renderOpenButton={({ onClick }) => (
            <UserAvatarButton onClick={onClick} testId="user-avatar-button" />
          )}
          menuProps={{
            anchorOrigin: { vertical: 39, horizontal: 'right' },
          }}
        />
      )}
    </StyledTopbarWrapper>
  );
};

export default AppTopbar;
