import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  closeAndResetCertificateModal,
  selectCertificateDetailsModalName,
} from '../../../state/modal/slice';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { CertificateModalCommonProps } from '../../../types';
import { AddAuditModal } from '../AddAuditModal';
import { AddExceptionalCircumstanceModal } from '../AddExceptionalCircumstanceModal';
import { AddSupplierModal } from '../AddSupplierModal';
import { CertificateCBChangeModal } from '../CertificateCBChangeModal';
import { CloseExceptionalCircumstancesModal } from '../CloseExceptionalCircumstancesModal';
import { DeleteCertificateModal } from '../DeleteCertificateModal';
import { DeleteGroupCertificateModal } from '../DeleteGroupCertificateModal';
import { LockCertificateModal } from '../LockCertificateModal';
import { RemoveAuditModal } from '../RemoveAuditModal';
import { RemoveSupplierModal } from '../RemoveSupplierModal';
import { RequestAuditAccessModal } from '../RequestAuditAccessModal';
import { UnlockModal } from '../UnlockModal';
import { UpdateExceptionalCircumstanceModal } from '../UpdateExceptionalCircumstanceModal';
import { UpdateLockReasonModal } from '../UpdateLockReasonModal';
import { UpdateUnlockModal } from '../UpdateUnlockModal';

const MODALS: Record<
  CertificateDetailsModalEnum,
  FC<CertificateModalCommonProps>
> = {
  [CertificateDetailsModalEnum.UPDATE_LOCK_REASON]: UpdateLockReasonModal,
  [CertificateDetailsModalEnum.UNLOCK_CERTIFICATE]: UnlockModal,
  [CertificateDetailsModalEnum.UPDATE_UNLOCK_REASON]: UpdateUnlockModal,
  [CertificateDetailsModalEnum.ADD_EXCEPTIONAL_CIRCUMSTANCE]:
    AddExceptionalCircumstanceModal,
  [CertificateDetailsModalEnum.UPDATE_EXCEPTIONAL_CIRCUMSTANCE]:
    UpdateExceptionalCircumstanceModal,
  [CertificateDetailsModalEnum.CLOSE_EXCEPTIONAL_CIRCUMSTANCE]:
    CloseExceptionalCircumstancesModal,
  [CertificateDetailsModalEnum.ADD_AUDIT]: AddAuditModal,
  [CertificateDetailsModalEnum.ADD_SUPPLIER]: AddSupplierModal,
  [CertificateDetailsModalEnum.REMOVE_AUDIT]: RemoveAuditModal,
  [CertificateDetailsModalEnum.REMOVE_SUPPLIER]: RemoveSupplierModal,
  [CertificateDetailsModalEnum.LOCK_CERTIFICATE]: LockCertificateModal,
  [CertificateDetailsModalEnum.REMOVE_GROUP_CERTIFICATE]:
    DeleteGroupCertificateModal,
  [CertificateDetailsModalEnum.REQUEST_AUDIT_ACCESS]: RequestAuditAccessModal,
  [CertificateDetailsModalEnum.REMOVE_CERTIFICATE]: DeleteCertificateModal,
  [CertificateDetailsModalEnum.SWITCH_CB_MODAL]: CertificateCBChangeModal,
};

export const ConditionalCertificateModals: FC = () => {
  const { certificateUuid, supplierId } =
    useParams<{ certificateUuid: string; supplierId: string }>();
  const openModalName = useSelector(selectCertificateDetailsModalName);
  const modalIsOpen = !!openModalName;
  const dispatch = useDispatch();

  useEffect(
    () => {
      // clear previously opened modal on mount for case when user is leaving without clicking 'x'
      if (modalIsOpen) {
        dispatch(closeAndResetCertificateModal());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (openModalName) {
    const Modal = MODALS[openModalName];

    return <Modal certificateUuid={certificateUuid} supplierId={supplierId} />;
  }

  return null;
};
