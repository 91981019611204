import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { omit } from 'lodash';

import { INCORRECT_DATA_ERROR, UNEXPECTED_ERROR } from 'config/constants';
import type { RootState } from 'store';
import { apiSlice } from 'store/apiSlice';
import {
  AsyncThunkOptions,
  LoaderStatusEnum,
  Nullable,
  UserDetails,
  UserRoleEnum,
} from 'types';
import { AccessToken } from 'utils/accessToken';
import apiClient from 'utils/apiClient';
import { refreshTokenInstance } from 'utils/apiClient/axiosInstance';
import { isFullMock } from 'utils/environments';
import { injectBaseMockUrl } from 'utils/injectMockUrl';
import { LangHandler } from 'utils/langHandler';
import { operationDelay } from 'utils/operationDelay';

import { LoginThunkParams, UserAuthState } from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';

const initialState: UserAuthState = {
  logged: false,
  loading: LoaderStatusEnum.IDLE,
  error: null,
  userDetails: null,
  accessToken: null,
};

const reducerName = `${parentReducerName}/auth`;

// thunks
export const login = createAsyncThunk<
  string,
  LoginThunkParams,
  AsyncThunkOptions<string>
>(
  `${reducerName}/login`,
  async ({ data }, { fulfillWithValue, rejectWithValue }) => {
    const startTime = new Date().getTime();
    try {
      const response = await apiClient.post(endpoints.AUTH.LOGIN, data);
      const token: string = response?.data?.jwtToken;
      return fulfillWithValue(token, null);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errCode = err?.response?.status;
        if (errCode === 401) {
          return rejectWithValue(INCORRECT_DATA_ERROR);
        }
      }

      return rejectWithValue(UNEXPECTED_ERROR);
    } finally {
      await operationDelay(startTime, 2500);
    }
  }
);

export const logout = createAsyncThunk(
  `${reducerName}/logout`,
  (_, { dispatch }) => {
    dispatch(apiSlice.util.resetApiState());
  }
);

export const loginByRefreshingAccessToken = createAsyncThunk<
  string,
  void,
  AsyncThunkOptions<string>
>(
  `${reducerName}/loginByRefreshingAccessToken`,
  async (_, { fulfillWithValue, rejectWithValue }) => {
    let refreshTokenEndpoint = LangHandler.getTextWithLang(
      endpoints.AUTH.REFRESH_TOKEN
    );

    if (isFullMock) {
      // mocked refresh token only for full mock env
      refreshTokenEndpoint = injectBaseMockUrl(refreshTokenEndpoint);
    }
    try {
      const response = await refreshTokenInstance.post(refreshTokenEndpoint);
      let token: string;

      if (typeof response?.data?.jwtToken === 'string') {
        token = response?.data?.jwtToken;
      } else {
        throw new Error();
      }

      return fulfillWithValue(token, null);
    } catch (err) {
      if (axios.isAxiosError(err) && err?.response?.status === 401) {
        return rejectWithValue(INCORRECT_DATA_ERROR);
      }

      return rejectWithValue(UNEXPECTED_ERROR);
    }
  }
);

export const loginWithAccessToken = createAsyncThunk<
  string,
  string,
  AsyncThunkOptions<string>
>(`${reducerName}/loginWithAccessToken`, (accessToken, { fulfillWithValue }) =>
  fulfillWithValue(accessToken, null)
);

const isLoggedIn = isAnyOf(
  login.fulfilled,
  loginWithAccessToken.fulfilled,
  loginByRefreshingAccessToken.fulfilled
);
const isAnyLoading = isAnyOf(
  loginByRefreshingAccessToken.pending,
  login.pending
);
const isAnyRejected = isAnyOf(
  loginByRefreshingAccessToken.rejected,
  login.rejected
);

// slice
export const userAuthSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetAuthLoaderState: (state) => {
      state.loading = LoaderStatusEnum.IDLE;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state.logged = false;
      state.userDetails = null;
      state.accessToken = null;
    });
    builder.addMatcher(isAnyLoading, (state) => {
      state.loading = LoaderStatusEnum.LOADING;
    });
    builder.addMatcher(isAnyRejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = LoaderStatusEnum.ERROR;
      state.logged = false;
      state.userDetails = null;
      state.accessToken = null;
    });
    builder.addMatcher(isLoggedIn, (state, action: PayloadAction<string>) => {
      const accessToken = action.payload;
      const decodedToken = AccessToken.decode(accessToken);
      state.userDetails = omit(decodedToken, ['iat', 'exp']);
      state.accessToken = accessToken;
      state.logged = true;
      state.loading = LoaderStatusEnum.IDLE;
      state.error = null;
    });
  },
});
// actions
export const { resetAuthLoaderState } = userAuthSlice.actions;

// selectors
export const selectIsLogged = (state: RootState): boolean =>
  state[parentReducerName].auth.logged;
export const selectAuthLoading = (state: RootState): LoaderStatusEnum =>
  state[parentReducerName].auth.loading;
export const selectAuthError = (state: RootState): Nullable<string> =>
  state[parentReducerName].auth.error;
export const selectUserDetails = (state: RootState): Nullable<UserDetails> =>
  state[parentReducerName].auth.userDetails;
export const selectUserCompanyId = (state: RootState): string | undefined =>
  state[parentReducerName].auth.userDetails?.currentContext.companyId;
export const selectUserCompanyName = (state: RootState): string | undefined =>
  state[parentReducerName].auth.userDetails?.currentContext.companyName;
export const selectUserIsAdmin = (state: RootState): boolean =>
  state[parentReducerName].auth.userDetails?.currentContext?.roles?.includes(
    UserRoleEnum.ADMIN
  ) || false;
export const selectUserPermissions = (state: RootState): string[] =>
  state[parentReducerName].auth.userDetails?.currentContext?.permissions || [];
export const selectAccessToken = (state: RootState): Nullable<string> =>
  state[parentReducerName].auth.accessToken;

// reducer
export default userAuthSlice.reducer;
