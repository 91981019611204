import * as yup from 'yup';

import { generalDetailsSchema } from 'schemas';
import { Document } from 'types';
import { PLAIN_DATE_REGEX_WITH_HYPHENS } from 'utils/regex/date';

export const documentSchema: yup.ObjectSchema<Document> = yup.object({
  uuid: yup.string().required(),
  docDefinitionId: yup.string().required(),
  type: generalDetailsSchema.required(),
  language: yup.string().nullable().defined(),
  fileName: yup.string().required(),
  displayName: yup.string().optional(),
  uploadedAt: yup
    .string()
    .matches(PLAIN_DATE_REGEX_WITH_HYPHENS, {})
    .required(),
});
