import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { UNEXPECTED_ERROR, UNEXPECTED_ERROR_EXTENDED } from 'config/constants';
import { forceMockHeader } from 'mock/constants';
import type { RootState } from 'store';
import {
  AsyncThunkOptions,
  ExtendedError,
  LoaderStatusEnum,
  Nullable,
} from 'types';
import apiClient from 'utils/apiClient';

import { SupplierContactsState } from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';
import { SupplierContact, SupplierDetailsParams } from '../../types';

const initialState: SupplierContactsState = {
  list: [],
  loading: LoaderStatusEnum.IDLE,
  error: null,
};

const reducerName = `${parentReducerName}/detailsContacts`;

export const getSupplierContacts = createAsyncThunk<
  SupplierContact[],
  SupplierDetailsParams,
  AsyncThunkOptions<ExtendedError>
>(`${reducerName}/list`, async ({ supplierId }, thunkApi) => {
  try {
    const response = await apiClient.get(
      endpoints.SUPPLIERS.DETAILS_CONTACTS(supplierId),
      {
        headers: forceMockHeader,
      }
    );

    if (!Array.isArray(response.data)) {
      return thunkApi.rejectWithValue({
        message: UNEXPECTED_ERROR,
      });
    }

    return thunkApi.fulfillWithValue(response.data, null);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const errCode = err?.response?.status;
      const errText = err?.response?.statusText;

      return thunkApi.rejectWithValue({
        message: UNEXPECTED_ERROR_EXTENDED,
        details: `${errCode} - ${errText}`,
      });
    }
    return thunkApi.rejectWithValue({
      message: UNEXPECTED_ERROR,
    });
  }
});

// slice
export const supplierContactsSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetSupplierContactsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSupplierContacts.fulfilled, (state, action) => {
      state.list = action.payload;
      state.loading = LoaderStatusEnum.SUCCESS;
      state.error = null;
    });
    builder.addCase(getSupplierContacts.pending, (state) => {
      state.loading = LoaderStatusEnum.LOADING;
    });
    builder.addCase(getSupplierContacts.rejected, (state, action) => {
      state.error = action.payload as ExtendedError;
      state.loading = LoaderStatusEnum.ERROR;
    });
  },
});

// actions
export const { resetSupplierContactsState } = supplierContactsSlice.actions;

// selectors
export const selectSupplierContacts = (state: RootState): SupplierContact[] =>
  state[parentReducerName].detailsContacts.list || [];

export const selectSupplierContactsLoading = (
  state: RootState
): LoaderStatusEnum => state[parentReducerName].detailsContacts.loading;

export const selectSupplierContactsError = (
  state: RootState
): Nullable<ExtendedError> => state[parentReducerName].detailsContacts.error;

// reducer
export default supplierContactsSlice.reducer;
