import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { t } from 'i18n';
import { apiSlice } from 'store/apiSlice';
import { GetOldLogsParams, LogsParams, LogsResponse } from 'types';
import { externalServiceInstance } from 'utils/apiClient/axiosInstance';
import { downloadFile } from 'utils/downloadFile';
import { getBaseQueryError } from 'utils/getBaseQueryError';

import endpoints from '../../config/endpoints';

const auditDetailsLogsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditDetailsLogs: build.query<LogsResponse, LogsParams>({
      query: ({ uuid, params }) => ({
        url: endpoints.LOGS_TAB.LIST(uuid),
        params,
      }),
      providesTags: ['AUDIT_DETAILS_LOGS'],
    }),
    downloadOldLogs: build.mutation<unknown, GetOldLogsParams>({
      async queryFn({ auditUuid }, _, extraOptions, baseQuery) {
        const res = (await baseQuery({
          url: endpoints.LOGS_TAB.OLD_LOGS_FILE(auditUuid),
        })) as QueryReturnValue<{ url: string }>;

        if (res?.data?.url) {
          try {
            const gcpResponse = await externalServiceInstance({
              url: res.data.url,
              responseType: 'arraybuffer',
            });

            const fileName = `${t('Old logs', 'auditDetails')} - ${auditUuid}`;

            await downloadFile(gcpResponse.data as ArrayBuffer, fileName);
          } catch {
            return getBaseQueryError();
          }
        }

        if (res.error) {
          return getBaseQueryError();
        }

        return { data: null };
      },
    }),
  }),
});

export const useGetAuditDetailsLogsQuery = (params: LogsParams) => {
  const query = auditDetailsLogsApi.useGetAuditDetailsLogsQuery(params);
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  return {
    ...query,
    logs: query.data?.logs || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};

export const useDownloadOldLogsMutation = () => {
  const [downloadOldLogsFile, query] =
    auditDetailsLogsApi.useDownloadOldLogsMutation();

  const requestMethod = (params: GetOldLogsParams) =>
    downloadOldLogsFile(params).unwrap();

  return {
    ...query,
    downloadOldLogsFile: requestMethod,
  };
};
