import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ModalFooterLoader } from 'components/atoms/ModalFooterLoader';
import { ModalTitleWithDetails } from 'components/atoms/ModalTitleWithDetails';
import { AbortModal } from 'components/organisms/AbortModal';
import {
  STEPPER_MODAL_WIDTH,
  TemplateStepperModal,
} from 'components/templates/TemplateStepperModal';
import { resetCertificationBodyValue } from 'domains/certificationBody/state/search/slice';
import { selectSupplierDetails } from 'domains/supplier/state/details/slice';
import { LoaderStatusEnum } from 'types';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';

import { useAuditPlanStepperForm } from '../../../hooks/useAuditPlanStepperForm';
import {
  INITIAL_REFETCH_TRIGGER_COUNTER,
  resetCreateState,
  selectLoadingStatus,
  selectRefetchTriggerCounter,
} from '../../../state/create/slice';
import { resetFormOptionState } from '../../../state/formOptions/slice';
import {
  resetModalState,
  setIsModalOpen,
} from '../../../state/stepperModal/slice';
import { AuditAppointmentStepEnum } from '../../../types';
import { FormSetDescription } from '../FormSetDescription';
import { FormSetExecutionMode } from '../FormSetExecutionMode';
import { FormSetKind } from '../FormSetKind';
import { FormSetModule } from '../FormSetModule';
import { FormSetTime } from '../FormSetTime';
import { FormSetType } from '../FormSetType';
import { FormSummary } from '../FormSummary';

const StyledDialog = styled(Dialog)<{ $isAbortModalOpen: boolean }>`
  &&.MuiDialog-root {
    left: 250px;
    top: 55px;
    overflow: auto;
  }

  .MuiDialog-container {
    min-width: max-content;
  }

  .MuiDialog-paper {
    width: ${(props) =>
      props.$isAbortModalOpen ? 600 : STEPPER_MODAL_WIDTH}px;
  }

  .MuiBackdrop-root {
    left: 250px;
    top: 55px;
  }

  .MuiDialogContent-root {
    padding: 12px 20px 12px 20px;
  }
`;

const STEPS = {
  [AuditAppointmentStepEnum.MODULE]: <FormSetModule />,
  [AuditAppointmentStepEnum.TYPE]: <FormSetType />,
  [AuditAppointmentStepEnum.KIND]: <FormSetKind />,
  [AuditAppointmentStepEnum.TIME]: <FormSetTime />,
  [AuditAppointmentStepEnum.EXECUTION_MODE]: <FormSetExecutionMode />,
  [AuditAppointmentStepEnum.DESCRIPTION]: <FormSetDescription />,
  [AuditAppointmentStepEnum.SUMMARY]: <FormSummary />,
};

export const CreateAuditAppointmentModal: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const successfullyAddedTrigger = useSelector(selectRefetchTriggerCounter);

  const {
    showStepper,
    auditPlanSteps,
    currentStep,
    buttonsConfig,
    isAbortModalOpen,
    openAbortModal,
    closeAbortModal,
    isModalOpen,
    nextAction,
    prevAction,
    handleStepClick,
  } = useAuditPlanStepperForm();

  const contentComponent = STEPS[currentStep as keyof typeof STEPS];
  const selectedSupplier = useSelector(selectSupplierDetails);
  const submitLoadingState = useSelector(selectLoadingStatus);

  const title = (
    <ModalTitleWithDetails
      title={t('Add Audit Appointment')}
      details={getSupplierDetailsTitle(selectedSupplier)}
    />
  );

  const handleAbortAction = () => {
    dispatch(resetCreateState());
    dispatch(setIsModalOpen(false));
    dispatch(resetFormOptionState());
    dispatch(resetCertificationBodyValue());
    dispatch(resetModalState());
    closeAbortModal();
  };

  useEffect(
    () => () => {
      dispatch(resetCreateState());
      dispatch(resetFormOptionState());
      dispatch(resetCertificationBodyValue());
      dispatch(resetModalState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (successfullyAddedTrigger > INITIAL_REFETCH_TRIGGER_COUNTER) {
      // reset form options after successfully added item
      dispatch(resetFormOptionState());
    }
  }, [successfullyAddedTrigger, dispatch]);

  const nextButtonComponent =
    submitLoadingState === LoaderStatusEnum.LOADING ? (
      <ModalFooterLoader />
    ) : undefined;

  return (
    <StyledDialog
      open={isModalOpen}
      $isAbortModalOpen={isAbortModalOpen}
      maxWidth="lg"
    >
      {isAbortModalOpen ? (
        <AbortModal
          title={title}
          message={t(
            'Do you want to cancel the process? The data will be deleted.',
            { ns: 'components' }
          )}
          continueButtonText={t('Continue audit plan')}
          continueAction={closeAbortModal}
          abortAction={handleAbortAction}
        />
      ) : (
        <TemplateStepperModal
          title={title}
          steps={showStepper ? auditPlanSteps : undefined}
          activeStep={currentStep}
          contentComponent={isModalOpen ? contentComponent : null}
          onStepClick={handleStepClick}
          closeAction={openAbortModal}
          nextAction={nextAction}
          prevAction={prevAction}
          nextButtonComponent={nextButtonComponent}
          noNextArrow
          noPrevArrow
          hidePrevButton={submitLoadingState === LoaderStatusEnum.LOADING}
          showCloseButton={submitLoadingState !== LoaderStatusEnum.LOADING}
          {...buttonsConfig}
        />
      )}
    </StyledDialog>
  );
};
