import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldIdentifier, GeneralDetails, Option } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useReleasesTemplate } from './useReleasesTemplate';
import { selectSelectedRelease } from '../state/modal/slice';

export type ContentCheckboxValues = Record<string, boolean>;

export interface ContentOption extends Option {
  disabled?: boolean;
}

interface UseReleaseCommonFormFields {
  contentOptions: ContentOption[];
  contentCheckboxValues: ContentCheckboxValues;
  content: string[];
  handleContentChange: (value: boolean, id: string) => void;
  timeFrameOptions: FieldIdentifier[];
  timeFrameId: string;
  setTimeFrameId: Dispatch<SetStateAction<string>>;
}

export const useReleaseCommonFormFields = (
  auditUuid: string,
  isUpdate = false
): UseReleaseCommonFormFields => {
  const { contentOptions, timeFrameOptions } = useReleasesTemplate(auditUuid);

  const {
    content: releaseToUpdateContent,
    timeFrame: releaseToUpdateTimeFrame,
  } = useSelector(selectSelectedRelease) || {};
  const [contentCheckboxValues, setContentCheckboxValues] =
    useState<ContentCheckboxValues>({});
  const [initialContentValuesAreSet, setInitialContentValuesAreSet] =
    useState(false);
  const content = Object.keys(contentCheckboxValues);

  const [timeFrameId, setTimeFrameId] = useState('');
  const [initialTimeFrameIsSet, setInitialTimeFrameIsSet] = useState(false);

  const handleContentChange = (value: boolean, id: string) => {
    setContentCheckboxValues((current) => {
      const state = { ...current };

      if (!value) {
        delete state[id];
      } else {
        state[id] = true;
      }

      return state;
    });
  };

  const preparedContentOptions = contentOptions
    .filter(({ allowedInForm }) => allowedInForm)
    .map(({ id, name, disabled = false }) => ({
      label: getTranslatedValue(name),
      value: id,
      disabled,
    }));

  useEffect(() => {
    if (!initialContentValuesAreSet) {
      const setValues = (items: GeneralDetails[]) => {
        items.forEach(({ id }) => handleContentChange(true, id));
        setInitialContentValuesAreSet(true);
      };
      const selectedCreateOptions = contentOptions.filter(
        ({ selected }) => selected
      );

      if (isUpdate && releaseToUpdateContent) {
        const values = releaseToUpdateContent.map((id) => ({ id, name: id }));
        // set initial content values for UPDATE
        setValues(values);
      } else if (!isUpdate && selectedCreateOptions.length > 0) {
        // set initial content values for CREATE
        const options = selectedCreateOptions.map(({ name, id }) => ({
          id,
          name: getTranslatedValue(name),
        }));
        setValues(options);
      }
    }
  }, [
    initialContentValuesAreSet,
    isUpdate,
    releaseToUpdateContent,
    contentOptions,
  ]);

  const preparedTimeFrameOptions = timeFrameOptions.map(
    ({ id, name, disabled }) => ({
      id,
      name: getTranslatedValue(name),
      enabled: !disabled,
    })
  );

  useEffect(() => {
    // set initial time frame value (only for update)
    if (!initialTimeFrameIsSet && isUpdate && releaseToUpdateTimeFrame) {
      setTimeFrameId(releaseToUpdateTimeFrame.id);
      setInitialTimeFrameIsSet(true);
    }
  }, [initialTimeFrameIsSet, isUpdate, releaseToUpdateTimeFrame]);

  return {
    contentOptions: preparedContentOptions,
    contentCheckboxValues,
    timeFrameOptions: preparedTimeFrameOptions,
    handleContentChange,
    content,
    timeFrameId,
    setTimeFrameId,
  };
};
