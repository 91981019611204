import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { UNEXPECTED_ERROR, UNEXPECTED_ERROR_EXTENDED } from 'config/constants';
import type { RootState } from 'store';
import { ExtendedError, LoaderStatusEnum, Nullable } from 'types';
import apiClient from 'utils/apiClient';

import { SupplierDetailsState } from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';
import { SupplierDetails, SupplierDetailsParams } from '../../types';

const initialState: SupplierDetailsState = {
  details: null,
  loading: LoaderStatusEnum.LOADING,
  error: null,
};

const reducerName = `${parentReducerName}/details`;

export const getSupplierDetails = createAsyncThunk(
  `${reducerName}/data`,
  async ({ supplierId }: SupplierDetailsParams, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        endpoints.SUPPLIERS.DETAILS(supplierId)
      );
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errCode = err?.response?.status;
        const errText = err?.response?.statusText;

        return rejectWithValue({
          message: UNEXPECTED_ERROR_EXTENDED,
          details: `${errCode} - ${errText}`,
        });
      }
      return rejectWithValue({
        message: UNEXPECTED_ERROR,
      });
    }
  }
);

// slice
export const supplierDetailsSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetSupplierDetailsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSupplierDetails.fulfilled, (state, action) => {
      state.details = action.payload as SupplierDetails;
      state.loading = LoaderStatusEnum.SUCCESS;
      state.error = null;
    });
    builder.addCase(getSupplierDetails.pending, (state) => {
      state.loading = LoaderStatusEnum.LOADING;
      state.details = null;
      state.error = null;
    });
    builder.addCase(getSupplierDetails.rejected, (state, action) => {
      state.error = action.payload as ExtendedError;
      state.loading = LoaderStatusEnum.ERROR;
    });
  },
});

// actions
export const { resetSupplierDetailsState } = supplierDetailsSlice.actions;

// selectors
export const selectSupplierDetails = (
  state: RootState
): Nullable<SupplierDetails> => state[parentReducerName].details.details;
export const selectSupplierDetailsLoading = (
  state: RootState
): LoaderStatusEnum => state[parentReducerName].details.loading;
export const selectSupplierDetailsError = (
  state: RootState
): Nullable<ExtendedError> => state[parentReducerName].details.error;

// reducer
export default supplierDetailsSlice.reducer;
