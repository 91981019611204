import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MODAL_FORM_FIELD_MIN_CHARS } from 'domains/certificateDetails/config/constants';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { removeSpacesFromString } from 'utils/removeSpacesFromString';
import { isActionCommentFieldValid } from 'utils/validation/isActionCommentFieldValid';

import { useUpdateUnlockReasonMutation } from '../../../state/certificateUnlock/api';
import {
  selectCertificateDetailsModalFormValues,
  selectCertificateDetailsUpdateUuid,
} from '../../../state/modal/slice';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { CertificateModalCommonProps } from '../../../types';
import {
  CertificateDetailsModalBase,
  NextAction,
} from '../../templates/CertificateDetailsModalBase';
import { UnlockCertificateForm } from '../UnlockCertificateForm';

export const UpdateUnlockModal: FC<CertificateModalCommonProps> = ({
  certificateUuid,
}) => {
  const [t] = useTranslation('certificateDetails');
  const { isLoading, updateUnlockReason, error } =
    useUpdateUnlockReasonMutation();
  const { actionComment, reasonDescription } = useSelector(
    selectCertificateDetailsModalFormValues
  );
  const isAdmin = useSelector(selectUserIsAdmin);
  const isActionCommentValid = isAdmin
    ? isActionCommentFieldValid(
        actionComment.reason,
        actionComment.comment || ''
      )
    : true;
  const isReasonDescriptionValid =
    removeSpacesFromString(reasonDescription).length >=
    MODAL_FORM_FIELD_MIN_CHARS;
  const isFormValid = isActionCommentValid && isReasonDescriptionValid;
  const lockUuid = useSelector(selectCertificateDetailsUpdateUuid) || '';

  const nextAction: NextAction = (openSnackbar, closeModal) => {
    updateUnlockReason({
      certificateUuid,
      lockUuid,
      data: { reasonDescription, ...(isAdmin ? { actionComment } : {}) },
    }).then(() => {
      openSnackbar(t('Reinstate updated.'), 'success');
      closeModal();
    });
  };

  return (
    <CertificateDetailsModalBase
      title={t('Update reinstatement')}
      nextAction={nextAction}
      disableNext={!isFormValid}
      isLoading={isLoading}
      nextLabel={t('Update')}
    >
      <UnlockCertificateForm
        modalType={CertificateDetailsModalEnum.UPDATE_UNLOCK_REASON}
        errors={error}
        certificateUuid={certificateUuid}
      />
    </CertificateDetailsModalBase>
  );
};
