import isIframe from '../isIframe';

type Payload = {
  message: string;
  payload: unknown;
};
export class PostMessage {
  static post(message: string | Payload): void {
    if (isIframe) {
      window.parent?.postMessage(
        message,
        process.env.REACT_APP_IFRAME_TARGET_ORIGIN || 'http://localhost:3000'
      );
    }
  }

  static isContentLoaded(): void {
    this.post('contentLoaded');
  }

  static redirect(url: string): void {
    this.post({ message: 'redirect', payload: url });
  }

  static reloadRequest(): void {
    this.post('reload');
  }
}
