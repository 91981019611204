import { Dispatch, SetStateAction, useState } from 'react';

type SelectChangeEventLikeType = {
  target: {
    value: unknown;
  };
};

interface UseActionInformationData {
  isAdminInfoValid: boolean;
  adminReason: string;
  adminComment: string;
  handleAdminReasonChange: (value: SelectChangeEventLikeType | string) => void;
  handleAdminCommentChange: (comment: string) => void;
  setIsAdminInfoValid: Dispatch<SetStateAction<boolean>>;
}

export const useActionInformationData = (): UseActionInformationData => {
  const [isAdminInfoValid, setIsAdminInfoValid] = useState(false);
  const [adminReason, setAdminReason] = useState('');
  const [adminComment, setAdminComment] = useState('');
  const handleAdminReasonChange = (
    valueParam: SelectChangeEventLikeType | string
  ) => {
    const value =
      typeof valueParam === 'string'
        ? valueParam
        : (valueParam.target.value as string);

    setAdminReason(value);
  };
  const handleAdminCommentChange = (comment: string) => {
    setAdminComment(comment);
  };

  return {
    isAdminInfoValid,
    adminReason,
    adminComment,
    handleAdminReasonChange,
    handleAdminCommentChange,
    setIsAdminInfoValid,
  };
};
