import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ERROR } from 'config/appColors';
import { ActionDropdownOption } from 'types';

import { AuditAppointmentActionsEnum } from '../config/actions';
import { setCancelAuditAppointmentIsModalOpen } from '../state/cancel/slice';
import {
  selectAuditAppointmentDetails,
  selectAuditAppointmentDetailsActions,
} from '../state/details/slice';
import {
  setDueDateModalOpen,
  setResetDueDateModalOpen,
} from '../state/dueDate/slice';
import { setReactivateAuditAppointmentIsModalOpen } from '../state/reactivate/slice';
import {
  setUpdateDescriptionDescription,
  setUpdateDescriptionIsModalOpen,
} from '../state/updateDescription/slice';

const dropdownItemStyles = {
  dropdownDeleteItemStyle: {
    color: `${ERROR.LIGHT}`,
  },
};

interface UseAuditAppointmentDetailsDropdownType {
  showDropdown: boolean;
  options: ActionDropdownOption<AuditAppointmentActionsEnum>[];
}

export const useAuditAppointmentDetailsDropdown =
  (): UseAuditAppointmentDetailsDropdownType => {
    const dispatch = useDispatch();
    const [t] = useTranslation('auditAppointment');

    const auditAppointmentDetails = useSelector(selectAuditAppointmentDetails);
    const permittedActions = useSelector(selectAuditAppointmentDetailsActions);
    const options = [
      {
        label: t('Reset Due Date'),
        onClickAction: () => {
          dispatch(setResetDueDateModalOpen(true));
        },
        permission: AuditAppointmentActionsEnum.RESET_DUE_DATE,
      },
      {
        label: t('Set Due Date'),
        onClickAction: () => {
          dispatch(setDueDateModalOpen(true));
        },
        permission: AuditAppointmentActionsEnum.SET_DUE_DATE,
      },
      {
        label: t('Update comment'),
        onClickAction: () => {
          dispatch(setUpdateDescriptionIsModalOpen(true));
          dispatch(
            setUpdateDescriptionDescription(
              auditAppointmentDetails?.description ?? ''
            )
          );
        },
        permission: AuditAppointmentActionsEnum.UPDATE_DESCRIPTION,
      },
      {
        label: t('Cancel', { ns: 'components' }),
        sx: dropdownItemStyles.dropdownDeleteItemStyle,
        onClickAction: () => {
          dispatch(setCancelAuditAppointmentIsModalOpen(true));
        },
        permission: AuditAppointmentActionsEnum.CANCEL,
      },
      {
        label: t('Reactivate', { ns: 'components' }),
        onClickAction: () => {
          dispatch(setReactivateAuditAppointmentIsModalOpen(true));
        },
        permission: AuditAppointmentActionsEnum.REACTIVATE,
      },
    ].filter(({ permission }) => permittedActions.includes(permission));

    const showDropdown = options.length > 0;

    return {
      showDropdown,
      options,
    };
  };
