import React, { FC, ReactElement } from 'react';

import { Logger } from 'components/atoms/Logger';

import { useCertificateDraftStepper } from '../../../hooks/useCertificateDraftStepper';
import {
  CertificateDraftStep,
  CertificateDraftStepEnum,
  isFormAdditionalDataStep,
  isFormBaseDataStep,
  isFormCommentStep,
  isFormConfirmationSubmitStep,
  isFormDocumentUploadStep,
  isFormProcessingServicesStep,
  isFormProductScopesStep,
  isFormReasonStep,
  isFormScopeDataStep,
  isFormTechScopesStep,
} from '../../../types';
import { FormAdditionalData } from '../FormAdditionalData';
import { FormBaseData } from '../FormBaseData';
import { FormComment } from '../FormComment';
import { FormConfirmationSubmit } from '../FormConfirmationSubmit';
import { FormDocumentUpload } from '../FormDocumentUpload';
import { FormReason } from '../FormReason';
import { FormScopeData } from '../FormScopeData';
import { FormScopes } from '../FormScopes';

const getFormBaseData = (
  certificateDraftId: string,
  step?: CertificateDraftStep
) =>
  isFormBaseDataStep(step) ? (
    <FormBaseData {...step} certificateDraftId={certificateDraftId} />
  ) : null;

const getProductScopes = (
  certificateDraftId: string,
  step?: CertificateDraftStep
) =>
  isFormProductScopesStep(step) ? (
    <FormScopes
      {...step}
      variant="productScope"
      key="productScope"
      certificateDraftId={certificateDraftId}
    />
  ) : null;

const getTechScopes = (
  certificateDraftId: string,
  step?: CertificateDraftStep
) =>
  isFormTechScopesStep(step) ? (
    <FormScopes
      {...step}
      variant="techScope"
      key="techScope"
      certificateDraftId={certificateDraftId}
    />
  ) : null;

const getFormScopeData = (
  certificateDraftId: string,
  step?: CertificateDraftStep
) =>
  isFormScopeDataStep(step) ? (
    <FormScopeData {...step} certificateDraftId={certificateDraftId} />
  ) : null;

const getFormProcessingServices = (
  certificateDraftId: string,
  step?: CertificateDraftStep
) =>
  isFormProcessingServicesStep(step) ? (
    <FormScopes
      {...step}
      variant="processingServices"
      key="processingServices"
      certificateDraftId={certificateDraftId}
    />
  ) : null;

const getFormAdditionalData = (
  certificateDraftId: string,
  step?: CertificateDraftStep
) =>
  isFormAdditionalDataStep(step) ? (
    <FormAdditionalData {...step} certificateDraftId={certificateDraftId} />
  ) : null;

const getFormDocumentUpload = (
  certificateDraftId: string,
  step?: CertificateDraftStep
) =>
  isFormDocumentUploadStep(step) ? (
    <FormDocumentUpload {...step} certificateDraftId={certificateDraftId} />
  ) : null;

const getFormComment = (
  certificateDraftId: string,
  step?: CertificateDraftStep
) =>
  isFormCommentStep(step) ? (
    <FormComment {...step.props} certificateDraftId={certificateDraftId} />
  ) : null;

const getFormReason = (
  certificateDraftId: string,
  step?: CertificateDraftStep
) =>
  isFormReasonStep(step) ? (
    <FormReason {...step.props} certificateDraftId={certificateDraftId} />
  ) : null;

const getFormConfirmationSubmit = (
  certificateDraftId: string,
  step?: CertificateDraftStep
) =>
  isFormConfirmationSubmitStep(step) ? (
    <FormConfirmationSubmit {...step} certificateDraftId={certificateDraftId} />
  ) : null;

const getTempPlaceHolder = (content: ReactElement) => () => content;

const CERTIFICATE_DRAFT_STEPS = {
  [CertificateDraftStepEnum.BASE_DATA]: getFormBaseData,
  [CertificateDraftStepEnum.PRODUCT_SCOPES]: getProductScopes,
  [CertificateDraftStepEnum.SCOPE_DATA]: getFormScopeData,
  [CertificateDraftStepEnum.ADDITIONAL_DATA]: getFormAdditionalData,
  [CertificateDraftStepEnum.DOCUMENT_UPLOAD]: getFormDocumentUpload,
  [CertificateDraftStepEnum.COMMENT]: getFormComment,
  [CertificateDraftStepEnum.REASON]: getFormReason,
  [CertificateDraftStepEnum.CONFIRMATION_SUBMIT]: getFormConfirmationSubmit,
  [CertificateDraftStepEnum.TECH_SCOPES]: getTechScopes,
  [CertificateDraftStepEnum.PROCESSING_SERVICES]: getFormProcessingServices,
  // Temporary content below
  [CertificateDraftStepEnum.ADMIN_COMMENT]: getTempPlaceHolder(
    <div>admin comment</div>
  ),
};

interface MappedCertificateDraftStepsProps {
  certificateDraftId: string;
}

export const MappedCertificateDraftSteps: FC<MappedCertificateDraftStepsProps> =
  (props) => {
    const { certificateDraftId } = props;
    const { currentStep, currentStepConfig } = useCertificateDraftStepper();

    if (!currentStep) {
      return null;
    }

    return CERTIFICATE_DRAFT_STEPS[currentStep] ? (
      CERTIFICATE_DRAFT_STEPS[currentStep](
        certificateDraftId,
        currentStepConfig
      )
    ) : (
      <Logger
        message={`${currentStep} not supported step id, please check spelling`}
      />
    );
  };
