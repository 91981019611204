import { REASON_OTHER as OTHER_VALUE } from './constants';
import { t } from '../i18n';

export const REASON_CB_MISSED_DEADLINE = {
  label: t('CB missed deadline', 'components'),
  value: 'cb_missed_deadline',
};
export const REASON_TECHNICAL_ISSUE = {
  label: t('Technical issue', 'components'),
  value: 'technical_issue',
};
export const REASON_CB_CHANGE = {
  label: t('CB Change', 'components'),
  value: 'cb_change',
};
export const REASON_WRONG_DATE = {
  label: t('Wrong Date', 'components'),
  value: 'wrong_date',
};
export const REASON_CANCELLATION_BY_CUSTOMER = {
  label: t('Cancellation by Customer', 'components'),
  value: 'cancellation_by_customer',
};
export const REASON_CONTRACT_CANCELLED = {
  label: t('Contract cancelled', 'components'),
  value: 'contract_cancelled',
};
export const REASON_OTHER = {
  label: t('Other', 'components'),
  value: OTHER_VALUE,
};
export const REASON_AUDIT_CORRECTION = {
  label: t('Audit/assessment correction', 'components'),
  value: 'audit_correction',
};
