import * as yup from 'yup';

import { timeLogSchema } from 'schemas';
import { AuditorParticipant } from 'types';

export const auditorParticipantSchema: yup.ObjectSchema<AuditorParticipant> =
  yup.object({
    uuid: yup.string().optional(),
    userId: yup.string().optional(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    role: yup.string().required(),
    timeLog: yup.array().of(timeLogSchema).optional().default(undefined),
  });
