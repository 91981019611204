import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { ConfirmationItem, SetConfirmationsParams } from './types';
import { endpoints } from '../../config/endpoints';
import { AuditDraftCommonRequestParams } from '../../types';

const confirmationInformationApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditConfirmationsInformation: build.query<ConfirmationItem[], string>({
      query: (auditDraftId) => ({
        url: endpoints.CONFIRMATIONS(auditDraftId),
      }),
      providesTags: [],
      keepUnusedDataFor: 0,
    }),
    setConfirmations: build.mutation<void, SetConfirmationsParams>({
      query: ({ auditDraftId, confirmations }) => ({
        url: endpoints.CONFIRMATIONS(auditDraftId),
        method: 'PUT',
        data: confirmations,
      }),
    }),
  }),
});

export const useGetAuditConfirmationsInformationQuery = (
  params: AuditDraftCommonRequestParams
) => {
  const query =
    confirmationInformationApi.useGetAuditConfirmationsInformationQuery(
      params.auditDraftId
    );
  const error = useFormattedError(query, 'formatToAlerts');
  const status = useRTKQueryStatus(query);

  return {
    ...query,
    confirmationsInformation: query.data,
    status,
    error,
  };
};

export const useSetConfirmationsInformationMutation = () => {
  const [setConfirmations, mutation] =
    confirmationInformationApi.useSetConfirmationsMutation();
  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: SetConfirmationsParams) =>
    setConfirmations(params).unwrap();

  return {
    ...mutation,
    setConfirmations: requestMethod,
    status,
    error,
  };
};
