import { Paper } from '@mui/material';
import { styled } from '@mui/system';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { StyledResult } from 'components/atoms/StyledResult';
import { TableCellWithIcon } from 'components/atoms/TableCellWithIcon';
import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { CertificateDetails } from 'domains/certificateDetails/types';
import { useFilterableAuditsCertificatesFilters } from 'domains/widgets/hooks/useFilterableAuditsCertificatesFilters';
import { formatDate, renderDateRange } from 'utils/dates';

import { useGetFilterableCertificateListQuery } from '../../../state/filterableLists/api';
import { CustomLink } from '../../atoms/CustomLink';
import { AuditsCertificatesFilters } from '../../molecules/AuditsCertificatesFilters';

const StyledPaper = styled(Paper)`
  padding: 7px 2px 2px;
`;

const StyledTable = styled(Table)`
  table,
  .skeleton-container {
    min-width: 1220px;
  }
  .skeleton-container {
    padding: 0 10px;
  }
` as typeof Table;

export const FilterableCertificates: FC = () => {
  const [t] = useTranslation('widgets');
  const { certificationBodyUuid } =
    useParams<{ certificationBodyUuid: string }>();

  const {
    filter,
    sortState,
    filterErrors,
    handleFilterChange,
    handleResetClick,
    onSortClick,
  } = useFilterableAuditsCertificatesFilters('certificates');

  const { pagination, limit, offset, setTotalRowsAmount } = usePagination({
    initialRowsPerPage: 50,
  });

  const { getColumnConfig, getColumnConfigWithEllipsis } =
    useColumnConfig('10%');

  const skipGetFilterableAuditsRequest = filterErrors.searchError;

  const { total, certificates, status } = useGetFilterableCertificateListQuery(
    {
      limit,
      offset,
      filter,
      sort: sortState ? `${sortState.key}:${sortState.direction}` : '',
      certificationBodyUuid,
    },
    skipGetFilterableAuditsRequest
  );

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const columns: Column<CertificateDetails>[] = [
    {
      headerName: t('Certificate ID (CEID)'),
      customCell: ({ ceid, activeLock }) => (
        <TableCellWithIcon
          label={ceid}
          showLockIcon={!!activeLock}
          iconName="lock"
        />
      ),
      ...getColumnConfig('13%'),
    },

    {
      headerName: t('Standards/Programs'),
      customCell: ({ module }) => module.name,
      title: ({ module }) => module.name,
      onSortClick,
      sortKey: 'module',
      ...getColumnConfigWithEllipsis('13%'),
    },
    {
      headerName: t('Customer'),
      customCell: ({ supplier }) => (
        <CustomLink redirectPayload={supplier.uuid}>
          {`(COID: ${supplier.coid}) ${supplier.name}`}
        </CustomLink>
      ),
      title: ({ supplier }) => `(COID: ${supplier.coid}) ${supplier.name}`,
      ...getColumnConfigWithEllipsis('14%'),
    },
    {
      headerName: t('valid until'),
      customCell: ({ validity }) => formatDate(validity?.end) || '-',
      onSortClick,
      sortKey: 'validity',
      ...getColumnConfig(),
    },

    {
      headerName: t('next audit to perform announced'),
      customCell: ({ announcedRecertificationTimeframe }) =>
        renderDateRange(
          announcedRecertificationTimeframe?.start,
          announcedRecertificationTimeframe?.end
        ) || '-',
      onSortClick,
      sortKey: 'announcedRecertificationTimeframe.start',
      ...getColumnConfig('15%'),
    },
    {
      headerName: t('next audit to perform unannounced'),
      customCell: ({ unannouncedRecertificationTimeframe }) =>
        renderDateRange(
          unannouncedRecertificationTimeframe?.start,
          unannouncedRecertificationTimeframe?.end
        ) || '-',
      onSortClick,
      sortKey: 'unannouncedRecertificationTimeframe.start',
      ...getColumnConfig('15%'),
    },
    {
      headerName: t('Result'),
      customCell: ({ auditResult }) => (
        <StyledResult $color={auditResult?.overall?.color}>
          {auditResult?.overall?.text || '-'}
        </StyledResult>
      ),
      ...getColumnConfig('15%'),
    },
  ];

  return (
    <>
      <AuditsCertificatesFilters
        filter={filter}
        onFilterChange={handleFilterChange}
        errors={filterErrors}
        onResetClick={handleResetClick}
      />
      <StyledPaper>
        <StyledTable
          columns={columns}
          rows={certificates}
          status={status}
          pagination={pagination}
          sortState={sortState}
          dense
        />
      </StyledPaper>
    </>
  );
};
