import LockIcon from '@mui/icons-material/Lock';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { styled } from '@mui/material';
import React, { FC } from 'react';

import { Banner, BannerVariantEnum } from 'components/molecules/Banner';
import { InfoBox } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { InfoBoxBannerVariantEnum } from './types';
import { CertificateActionsEnum, CertificateDetails } from '../../../types';
import { InfoBoxDescription } from '../../atoms/InfoBoxDescription';

interface CertificateDetailsInfoBoxProps {
  data: InfoBox;
  certificateDetails: CertificateDetails;
  certificateDetailsActions: CertificateActionsEnum[];
  type: InfoBoxBannerVariantEnum;
}

const StyledLockIcon = styled(LockIcon)`
  font-size: 2.8rem;
`;
const StyledUnlockIcon = styled(LockOpenOutlinedIcon)`
  font-size: 2.8rem;
`;

export const CertificateDetailsInfoBox: FC<CertificateDetailsInfoBoxProps> = ({
  data,
  certificateDetails,
  certificateDetailsActions,
  type,
}) => {
  const { props } = data;

  const bannerData = {
    [InfoBoxBannerVariantEnum.LOCK]: {
      variant: BannerVariantEnum.ERROR,
      icon: <StyledLockIcon />,
    },
    [InfoBoxBannerVariantEnum.UNLOCK]: {
      variant: BannerVariantEnum.DEFAULT,
      icon: <StyledUnlockIcon />,
    },
  };

  return (
    <Banner
      variant={bannerData[type].variant}
      customTitle={getTranslatedValue(props.headerText)}
      description={
        <InfoBoxDescription
          data={data}
          certificateDetails={certificateDetails}
          certificateDetailsActions={certificateDetailsActions}
          type={type}
        />
      }
      customIcon={bannerData[type].icon}
      hideCloseButton
    />
  );
};
