import React, { ReactNode } from 'react';

import {
  AttributeRowAuditTimeFrame,
  AttributeRowBadges,
  AttributeRowCertificateIssuedAt,
  AttributeRowCertificateValidUntil,
  AttributeRowCertificationBody,
  AttributeRowCertificationBodyLink,
  AttributeRowComment,
  AttributeRowDate,
  AttributeRowKind,
  AttributeRowMappedText,
  AttributeRowMappedTextProps,
  AttributeRowModule,
  AttributeRowRawResult,
  AttributeRowRawResultProps,
  AttributeRowResult,
  AttributeRowResultProps,
  AttributeRowScope,
  AttributeRowStatusBadge,
  AttributeRowStatusBadgeProps,
  AttributeRowSupplier,
  AttributeRowSupplierLink,
  AttributeRowText,
  AttributeRowTimeFrame,
  AttributeRowType,
  isAttributeRowStatusBadgeProps,
} from 'components/atoms/AttributeRow';
import {
  AttributeRowCommonProps,
  AttributeRowTypeEnum,
} from 'components/atoms/AttributeRow/types';

type Props =
  | AttributeRowCommonProps
  | AttributeRowStatusBadgeProps
  | AttributeRowResultProps
  | AttributeRowRawResultProps
  | AttributeRowMappedTextProps;

type Data = Record<string, unknown>;

interface Config {
  data: Data;
  props: Props;
  children?: unknown;
}

const getProps = (config: Config) => ({
  ...config.props,
  children: config.children,
  data: config.data,
});

export const attributeRowsMap = {
  [AttributeRowTypeEnum.SUPPLIER]: (config: Config): ReactNode => (
    <AttributeRowSupplier {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.CERTIFICATION_BODY]: (config: Config): ReactNode => (
    <AttributeRowCertificationBody {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.MODULE]: (config: Config): ReactNode => (
    <AttributeRowModule {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.TYPE]: (config: Config): ReactNode => (
    <AttributeRowType {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.KIND]: (config: Config): ReactNode => (
    <AttributeRowKind {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.CERTIFICATE_ISSUED_AT]: (config: Config): ReactNode => (
    <AttributeRowCertificateIssuedAt {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.CERTIFICATE_VALID_UNTIL]: (
    config: Config
  ): ReactNode => <AttributeRowCertificateValidUntil {...getProps(config)} />,
  [AttributeRowTypeEnum.STATUS_BADGE]: ({ data, props }: Config): ReactNode =>
    isAttributeRowStatusBadgeProps(props) ? (
      <AttributeRowStatusBadge {...props} data={data} />
    ) : null,
  [AttributeRowTypeEnum.TEXT]: (config: Config): ReactNode => (
    <AttributeRowText {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.CERTIFICATION_BODY_LINK]: (): ReactNode => (
    <AttributeRowCertificationBodyLink />
  ),
  [AttributeRowTypeEnum.COMMENT]: (): ReactNode => <AttributeRowComment />,
  [AttributeRowTypeEnum.SCOPE]: (config: Config): ReactNode => (
    <AttributeRowScope {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.SUPPLIER_LINK]: (config: Config): ReactNode => (
    <AttributeRowSupplierLink {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.RAW_RESULT]: (config: Config): ReactNode => (
    <AttributeRowRawResult {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.RESULT]: (config: Config): ReactNode => (
    <AttributeRowResult {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.TIME_FRAME]: (config: Config): ReactNode => (
    <AttributeRowTimeFrame {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.DATE]: (config: Config): ReactNode => (
    <AttributeRowDate {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.AUDIT_TIME_FRAME]: (config: Config): ReactNode => (
    <AttributeRowAuditTimeFrame {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.BADGES]: (config: Config): ReactNode => (
    <AttributeRowBadges {...getProps(config)} />
  ),
  [AttributeRowTypeEnum.MAPPED_TEXT]: (config: Config): ReactNode => (
    <AttributeRowMappedText {...getProps(config)} />
  ),
};
