import { WithUndefined } from 'types';
import { formatToAlerts } from 'utils/formatToAlerts';
import { formatToExtendedError } from 'utils/formatToExtendedError';

export interface RTKFunctionPartialResult {
  isError: boolean;
  error?: unknown;
}

export function useFormattedError(
  mutationOrQuery: RTKFunctionPartialResult,
  errorFormat?: 'formatToExtendedError'
): WithUndefined<ReturnType<typeof formatToExtendedError>>;
export function useFormattedError(
  mutationOrQuery: RTKFunctionPartialResult,
  errorFormat?: 'formatToAlerts'
): WithUndefined<ReturnType<typeof formatToAlerts>>;
export function useFormattedError(
  mutationOrQuery: RTKFunctionPartialResult,
  errorFormat: unknown = 'formatToExtendedError'
): WithUndefined<unknown> {
  const { isError, error } = mutationOrQuery;

  if (!isError) return undefined;

  if (errorFormat === 'formatToExtendedError') {
    return formatToExtendedError(error);
  }

  if (errorFormat === 'formatToAlerts') {
    return formatToAlerts(error);
  }

  return undefined;
}
