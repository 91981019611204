import { Nullable, TranslatedText, WithUndefined } from 'types';

import i18next from '../../i18n';

type TranslatedTextKey = keyof TranslatedText;

export const getTranslatedValue = (
  text: WithUndefined<Nullable<TranslatedText>>,
  fallbackText = ''
): string => {
  if (text) {
    return text[i18next.language as TranslatedTextKey] || text.en;
  }

  return fallbackText;
};
