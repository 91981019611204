import get from 'lodash/get';
import React, { FC } from 'react';

import { formatDate } from 'utils/dates';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

const isDate = (date: unknown): date is string => typeof date === 'string';

interface AttributeRowDateProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
}

export const AttributeRowDate: FC<AttributeRowDateProps> = (props) => {
  const { data, dataRef, labelText } = props;
  const value = get(data, dataRef);
  const showAttributeRow = isDate(value);

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Date data" />
    );
  }

  return <AttributeRowBase label={labelText} value={formatDate(value)} />;
};
