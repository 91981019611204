import { Card, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { BaseModal } from 'components/atoms/BaseModal';
import { ModalTitleWithDetails } from 'components/atoms/ModalTitleWithDetails';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import Loader from 'components/molecules/Loader';
import { AbortModal } from 'components/organisms/AbortModal';
import { DraftStatusIndicator } from 'components/organisms/DraftStatusIndicator';
import { TemplateAuditModal } from 'components/templates/TemplateAuditModal';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { ARGENT } from 'config/appColors';
import { DocumentUpload } from 'domains/auditDraft/components/organisms/DocumentUpload';
import { getUseAuditDraftDocumentUpdateStatusContent } from 'domains/auditDraft/hooks/useAuditDraftDocumentUpdateStatusContent';
import { useAuditDraftDetailsQuery } from 'domains/auditDraft/state/auditDraftDetails/api';
import { useAuditDraftTemplateQuery } from 'domains/auditDraft/state/auditDraftTemplate/api';
import { useGetAuditDraftDocumentDefinitionsQuery } from 'domains/auditDraft/state/documentDefinitions/api';
import { useSubmitAuditDraftMutation } from 'domains/auditDraft/state/submitAuditDraft/api';
import { useAbortModal } from 'hooks/useAbortModal';
import { DraftStatusEnum } from 'types';
import { getAuditDetailsPathname } from 'utils/getAuditDetailsPathname';
import { TARGET_AUDIT_SEARCH } from 'utils/getAuditDraftFormPathname';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';
import { getTranslatedValue } from 'utils/getTranslatedValue';

const ContentWrapper = styled('div')`
  padding: 4rem 10.5rem;
`;

const StyledCard = styled(Card)`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;

  .MuiDialogActions-root {
    padding-top: 16px;
    border-top: 1px ${ARGENT} solid;
  }
`;

const StyledContainer = styled('div')`
  margin-top: 20px;
  width: 100%;
`;

export const WidgetAuditDraftUpdateDocuments: FC = () => {
  const [t] = useTranslation('components');
  const { push, location } = useHistory();
  const targetAudit = new URLSearchParams(location.search).get(
    TARGET_AUDIT_SEARCH
  );
  const { isAbortModalOpen, openAbortModal, closeAbortModal } = useAbortModal();
  const { supplierId, auditDraftId } =
    useParams<{ supplierId: string; auditDraftId: string }>();

  const { auditDraftDetails } = useAuditDraftDetailsQuery(auditDraftId);

  const { auditDraftTemplate } = useAuditDraftTemplateQuery(auditDraftId);

  const { allRequiredDocumentsFulfilled } =
    useGetAuditDraftDocumentDefinitionsQuery({ auditDraftId });

  const {
    isLoading,
    status: submitAuditDraftStatus,
    isSuccess: isSubmitAuditDraftSuccess,
    error: submitError = [],
    submitAuditDraft,
  } = useSubmitAuditDraftMutation();

  const translatedTitle = getTranslatedValue(
    auditDraftTemplate?.template.props.headerText
  );

  const title = (
    <ModalTitleWithDetails
      title={`${translatedTitle} ${auditDraftDetails?.module.name || ''}`}
      details={
        auditDraftDetails?.supplier
          ? getSupplierDetailsTitle(auditDraftDetails.supplier)
          : ''
      }
    />
  );

  const draftIsSubmitted =
    auditDraftDetails?.status.id === DraftStatusEnum.SUBMITTED;

  if (
    auditDraftDetails &&
    (draftIsSubmitted || isSubmitAuditDraftSuccess || submitError.length > 0)
  ) {
    // fixed status for situation when draft has unsubmitted status but request succeed
    const fixedSuccessStatus = {
      id: DraftStatusEnum.SUBMITTED,
      name: t('Submitted', { ns: 'auditDraft' }),
    };
    const status = submitError.length > 0 ? undefined : fixedSuccessStatus;
    return (
      <TemplatePageDefault hidePageHeader hideAside hideHeader hideSupport>
        <DraftStatusIndicator
          details={{
            ...auditDraftDetails,
            status,
          }}
          useStatusContent={getUseAuditDraftDocumentUpdateStatusContent(
            submitError
          )}
          getPathname={() =>
            getAuditDetailsPathname(supplierId, targetAudit || '')
          }
        />
      </TemplatePageDefault>
    );
  }

  const handleSaveClick = () => {
    submitAuditDraft({ auditDraftId });
  };

  return (
    <TemplatePageDefault hidePageHeader hideAside hideHeader hideSupport>
      <BaseModal open={isAbortModalOpen}>
        <AbortModal
          title={title}
          message={t(
            'Do you want to cancel the process? The data will be deleted.'
          )}
          continueAction={closeAbortModal}
          abortAction={() => {
            if (targetAudit) {
              push(
                getAuditDetailsPathname(supplierId, targetAudit),
                location.state
              );
            }
          }}
        />
      </BaseModal>
      <ContentWrapper>
        <StyledCard>
          <TemplateAuditModal
            title={title}
            contentComponent={
              <Loader status={submitAuditDraftStatus}>
                <StyledContainer>
                  <StepFormTitle
                    content={t('Update Documents', { ns: 'auditDraft' })}
                  />
                  <DocumentUpload auditDraftId={auditDraftId} />
                </StyledContainer>
              </Loader>
            }
            prevAction={openAbortModal}
            nextAction={handleSaveClick}
            nextLabel={t('Save')}
            prevLabel={t('Cancel')}
            prevColor="modalPrevButton"
            closeAction={openAbortModal}
            disableNext={isLoading || !allRequiredDocumentsFulfilled}
            disablePrev={isLoading}
            showCloseButton
            noNextArrow
            noPrevArrow
          />
        </StyledCard>
      </ContentWrapper>
    </TemplatePageDefault>
  );
};
