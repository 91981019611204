import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'components/molecules/PageHeader';
import { Tabs } from 'components/organisms/Tabs';
import { TabItem } from 'components/organisms/Tabs/types';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { WHITE } from 'config/appColors';

import { GeneralAuditsTab } from '../../components/organisms/GeneralAuditsTab';

const StyledPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;
const StyledPageHeaderWrapper = styled('div')`
  background: ${WHITE};
`;
const StyledPageHeader = styled(PageHeader)`
  h2 {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
`;
const StyledTabContentWrapper = styled('div')`
  min-height: 500px;
  padding: 40px 20px;
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-root {
    padding: 13px 16px 0 42px;
  }
`;

export const PageAdminReportsGeneral: FC = () => {
  const [t] = useTranslation('adminReports');
  const pageTitle = t('Reports: General');

  const tabs: TabItem[] = [
    {
      label: t('Audits'),
      content: (
        <StyledTabContentWrapper>
          <GeneralAuditsTab />
        </StyledTabContentWrapper>
      ),
    },
  ];

  return (
    <TemplatePageDefault hidePageHeader>
      <StyledPageWrapper>
        <StyledPageHeaderWrapper>
          <StyledPageHeader title={pageTitle} hideBreadcrumbs />
        </StyledPageHeaderWrapper>
        <StyledTabs items={tabs} routeParamName="tab" />
      </StyledPageWrapper>
    </TemplatePageDefault>
  );
};
