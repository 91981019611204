export const auditorRoles = [
  {
    role: 'lead_auditor',
    name: {
      en: 'Lead auditor/assessor',
      de: 'Lead-Auditor/Assessor',
      fr: 'Auditeur/évaluateur principal',
      es: 'Auditor/evaluador principal',
      it: 'Lead auditor/valutatore',
    },
  },
  {
    role: 'co_auditor_no_split',
    name: {
      en: 'Co-auditor/assessor',
      de: 'Co-Auditor/Assessor',
      fr: 'Co-auditeur/évaluateur',
      es: 'Co-auditor/evaluador',
      it: 'Co-auditor/valutatore',
    },
  },
  {
    role: 'co_auditor_split',
    name: {
      en: 'Co-auditor/assessor',
      de: 'Co-Auditor/Assessor',
      fr: 'Co-auditeur/évaluateur',
      es: 'Co-auditor/evaluador',
      it: 'Co-auditor/valutatore',
    },
  },
  {
    role: 'trainee',
    name: {
      en: 'Trainee',
      de: 'Trainee',
      fr: 'Stagiaire',
      es: 'en formación',
      it: 'Tirocinante',
    },
  },
  {
    role: 'aip',
    name: {
      en: 'AIP',
      de: 'AIP',
      fr: 'AIP',
      es: 'AIP',
      it: 'AIP',
    },
  },
  {
    role: 'translator',
    name: {
      en: 'Interpreter',
      de: 'Dolmetscher',
      fr: 'Interprète',
      es: 'Intérprete',
      it: 'Interprete',
    },
  },
  {
    role: 'expert',
    name: {
      en: 'Technical expert',
      de: 'Technischer Experte',
      fr: 'Expert technique',
      es: 'Experto técnico',
      it: 'Esperto tecnico',
    },
  },
  {
    role: 'expert_split',
    name: {
      en: 'Technical expert',
      de: 'Technischer Experte',
      fr: 'Expert technique',
      es: 'Experto técnico',
      it: 'Esperto tecnico',
    },
  },
  {
    role: 'witnesser',
    name: {
      en: 'Witness auditor/assessor',
      de: 'Witness-Auditor/Assessor',
      fr: 'Auditeur/évaluateur witness',
      es: 'Auditor/evaluador witness',
      it: 'Auditor/valutatore testimone',
    },
  },
  {
    role: 'reviewer',
    name: {
      en: 'Reviewer',
      de: 'Reviewer',
      fr: 'Reviewer',
      es: 'Revisor',
      it: 'Revisore',
    },
  },
];
