import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import Loader from 'components/molecules/Loader';
import { RadioGroup } from 'components/molecules/RadioGroup';
import { FieldIdentifier } from 'types';

import {
  selectAlertsState,
  selectLoadingStatus,
} from '../../../state/formOptions/slice';
import { StepAlertList } from '../../atoms/StepAlertList';

interface FormWithRadioProps {
  title: string;
  description: string;
  ariaLabel: string;
  options: FieldIdentifier[];
  value: string;
  onChange: (value: string) => void;
}

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledStepDescription = styled(StepDescription)`
  margin: 15px 0 20px 10px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  margin-left: 20px;
`;

export const FormWithRadio: FC<FormWithRadioProps> = ({
  title,
  description,
  ariaLabel,
  onChange,
  options,
  value,
}) => {
  const loadingState = useSelector(selectLoadingStatus);
  const alertsState = useSelector(selectAlertsState);
  const showAlerts = alertsState && alertsState.alerts.length > 0;

  return (
    <Loader status={loadingState}>
      <StyledContainer>
        <StepFormTitle content={title} />
        {showAlerts && (
          <StepAlertList
            variant={alertsState.variant}
            alerts={alertsState.alerts}
          />
        )}
        <StyledStepDescription content={description} />
        <StyledRadioGroup
          value={value}
          ariaLabel={ariaLabel}
          onChange={onChange}
          options={options}
        />
      </StyledContainer>
    </Loader>
  );
};
