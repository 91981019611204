import * as yup from 'yup';

import {
  Certificate,
  CertificateLock,
  CertificateLockEnum,
  CertificationBody,
  Message,
  RawResult,
  RawResultDetails,
  Result,
  ResultDetails,
  Supplier,
  TimeLog,
} from 'types';

import {
  dateSchema,
  generalDetailsSchema,
  generalDetailsWithEnumSchema,
  startEndDatesSchema,
} from './utils';

export const certificateLockSchema: yup.ObjectSchema<CertificateLock> =
  yup.object({
    uuid: yup.string().required(),
    type: generalDetailsWithEnumSchema(CertificateLockEnum).required(),
    lockDate: dateSchema.required(),
    unlockDate: dateSchema.optional().nullable().default(undefined),
    category: generalDetailsSchema.required(),
    categoryOthersDescription: yup.string().nullable(),
    descriptionEnglish: yup.string().required(),
    descriptionOtherLanguage: yup.string().nullable(),
    unlockReasonDescription: yup.string().nullable().optional(),
  });

export const certificateLockFullSchema = yup
  .mixed<false | CertificateLock>()
  .nullable()
  .test('lock', (value) => {
    if (value === false) return yup.boolean().isValidSync(value);
    return certificateLockSchema
      .optional()
      .nullable()
      .default(undefined)
      .isValidSync(value);
  });

export const relatedSupplierSchema: yup.ObjectSchema<Supplier> = yup.object({
  uuid: yup.string().required(),
  name: yup.string().required(),
  coid: yup.string().required(),
});

export const relatedCertificationBodySchema: yup.ObjectSchema<CertificationBody> =
  yup.object({
    uuid: yup.string().required(),
    name: yup.string().required(),
    cbid: yup.string().optional(),
    legalForm: yup.string().optional(),
  });

export const relatedCertificateSchema: yup.ObjectSchema<Certificate> =
  yup.object({
    id: yup.string().required(),
    certificateIsAllowed: yup.bool().required(),
    userHasIssuedCertificate: yup.bool().optional(),
    issuedAt: yup.string().optional(),
    validity: startEndDatesSchema.optional().default(undefined),
    announcedRecertificationTimeframe: startEndDatesSchema
      .optional()
      .default(undefined),
    unannouncedRecertificationTimeframe: startEndDatesSchema
      .optional()
      .default(undefined),
    activeLock: certificateLockFullSchema,
    latestLock: certificateLockFullSchema,
  });

export const resultDetailsSchema: yup.ObjectSchema<ResultDetails> = yup.object({
  id: yup.string().required(),
  color: yup.string().required(),
  text: yup.string().required(),
  percentage: yup.string().optional(),
  level: yup.string().optional(),
});

export const resultSchema: yup.ObjectSchema<Result> = yup.object({
  overall: resultDetailsSchema.required(),
  levels: yup.array().of(resultDetailsSchema).optional().default(undefined),
});

export const rawResultDetailsSchema: yup.ObjectSchema<RawResultDetails> =
  yup.object({
    id: yup.string().required(),
    passed: yup.boolean().required(),
    percent: yup
      .mixed<string | number>()
      .test((value) => {
        if (typeof value === 'string' || typeof value === 'number') {
          return !Number.isNaN(value);
        }
        return false;
      })
      .required(),
    level: yup.string().required(),
  });

export const rawResultSchema: yup.ObjectSchema<RawResult> = yup.object({
  overall: rawResultDetailsSchema.required(),
  levels: yup.array().of(rawResultDetailsSchema).required(),
});

export const messageSchema: yup.ObjectSchema<Message> = yup.object({
  id: yup.string().required(),
  text: yup.string().required(),
});

export const timeLogSchema: yup.ObjectSchema<TimeLog> = yup.object({
  uuid: yup.string().optional(),
  date: dateSchema.required(),
  from: yup.string().nullable().default(null),
  to: yup.string().nullable().default(null),
  comment: yup.string().optional(),
  togetherWithLeadAuditor: yup.boolean().optional(),
});
