import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { SetAuditDraftActionCommentParams } from './types';
import { endpoints } from '../../config/endpoints';

const setActionCommentApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    setAuditDraftActionComment: build.mutation<
      void,
      SetAuditDraftActionCommentParams
    >({
      query: ({ actionComment, auditDraftId }) => ({
        url: endpoints.SET_ACTION_COMMENT(auditDraftId),
        data: { actionComment },
        method: 'PUT',
      }),
    }),
  }),
});

export const useSetAuditDraftActionCommentMutation = () => {
  const [setAuditDraftActionComment, mutation] =
    setActionCommentApi.useSetAuditDraftActionCommentMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: SetAuditDraftActionCommentParams) =>
    setAuditDraftActionComment(params).unwrap();

  return {
    ...mutation,
    setAuditDraftActionComment: requestMethod,
    status,
    error,
  };
};
