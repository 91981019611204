import { SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { ActionComment, ActionReasonOption, SelectOption } from 'types';
import { isActionCommentFieldValid } from 'utils/validation/isActionCommentFieldValid';

import { useActionReasonOptions } from './useActionReasonOptions';

interface UseReasonStepProps {
  actionReasonOptions: ActionReasonOption[];
  updateOptions: ActionReasonOption[];
  draftDetails?: {
    actionComment?: ActionComment;
    updateDescription?: string;
    updateReason?: string;
  };
}

interface UseReasonStep {
  updateReason: string;
  updateDescription: string;
  actionReason: string;
  actionComment: string;
  updateReasonOptions: SelectOption[];
  actionOptions: SelectOption[];
  handleReasonChange: (event: SelectChangeEvent<unknown>) => void;
  handleCommentChange: (value: string) => void;
  handleAdminReasonChange: (event: SelectChangeEvent<unknown>) => void;
  handleAdminCommentChange: (value: string) => void;
  disableNextButton: boolean;
  isAdmin: boolean;
}

export const useReasonStep = (props: UseReasonStepProps): UseReasonStep => {
  const { actionReasonOptions, updateOptions, draftDetails } = props;
  const isAdmin = useSelector(selectUserIsAdmin);

  const [isInitialDataSet, setIsInitialDataSet] = useState(false);
  const [updateReason, setUpdateReason] = useState('');
  const [updateDescription, setUpdateDescription] = useState('');
  const [actionReason, setActionReason] = useState('');
  const [actionComment, setActionComment] = useState('');

  const {
    options: updateReasonOptions,
    optionsWithRequiredDescription: updateReasonOptionsWithRequiredDescription,
  } = useActionReasonOptions(updateOptions);
  const {
    options: actionOptions,
    optionsWithRequiredDescription: actionOptionsWithRequiredDescription,
  } = useActionReasonOptions(actionReasonOptions);
  const disableNextButton =
    !isActionCommentFieldValid(
      updateReason,
      updateDescription,
      updateReasonOptionsWithRequiredDescription
    ) ||
    (isAdmin &&
      !isActionCommentFieldValid(
        actionReason,
        actionComment,
        actionOptionsWithRequiredDescription
      ));

  const handleReasonChange =
    (setValue: Dispatch<SetStateAction<string>>) =>
    (event: SelectChangeEvent<unknown>) =>
      setValue(event.target.value as string);
  const handleCommentChange =
    (setValue: Dispatch<SetStateAction<string>>) => (value: string) =>
      setValue(value);

  useEffect(() => {
    if (!isInitialDataSet && draftDetails) {
      // set initial values
      const {
        actionComment: reasonData,
        updateDescription: existingUpdateDescription,
        updateReason: existingUpdateReason,
      } = draftDetails;
      const existingActionComment = reasonData?.comment;
      const existingActionReason = reasonData?.reason;

      if (existingUpdateDescription)
        setUpdateDescription(existingUpdateDescription);
      if (
        existingUpdateReason &&
        updateOptions.find(({ id }) => id === existingUpdateReason)
      )
        setUpdateReason(existingUpdateReason);
      if (existingActionComment) setActionComment(existingActionComment);
      if (
        existingActionReason &&
        actionReasonOptions.find(({ id }) => id === existingActionReason)
      )
        setActionReason(existingActionReason);

      setIsInitialDataSet(true);
    }
  }, [draftDetails, actionReasonOptions, isInitialDataSet, updateOptions]);

  return {
    updateReason,
    updateDescription,
    actionReason,
    actionComment,
    updateReasonOptions,
    actionOptions,
    handleReasonChange: handleReasonChange(setUpdateReason),
    handleCommentChange: handleCommentChange(setUpdateDescription),
    handleAdminCommentChange: handleCommentChange(setActionComment),
    handleAdminReasonChange: handleReasonChange(setActionReason),
    disableNextButton,
    isAdmin,
  };
};
