import { styled } from '@mui/material';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import { omit } from 'lodash';
import React, { FC, ReactElement, ReactNode } from 'react';

import { PRIMARY, TEXT, TUNDORA } from 'config/appColors';

export interface RadioProps extends MuiRadioProps {
  label?: string;
  labelIcon?: ReactNode;
}

const StyledRadio = styled(MuiRadio)`
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }

  & + span {
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0.015rem;
    font-weight: 400;
    color: ${TEXT.PRIMARY};
  }

  & svg {
    width: 1.6em;
    height: 1.6em;
    color: ${TEXT.SECONDARY_LIGHT};
  }

  &.Mui-checked {
    svg {
      color: ${PRIMARY.MAIN};
    }
  }

  &.Mui-disabled {
    svg,
    & + span {
      color: ${TEXT.DISABLED};
    }
  }
`;

const StyledSpan = styled('span')`
  display: flex;
  align-items: center;

  & > svg {
    width: 1.2em;
    height: 1.2em;
    margin-left: 3px;
    color: ${TUNDORA};
  }
`;

export const Radio: FC<RadioProps> = (props): ReactElement => {
  const { label, labelIcon, value } = props;
  const input = <StyledRadio {...omit(props, 'labelIcon')} color="default" />;

  if (label) {
    return (
      <MuiFormControlLabel
        value={value}
        control={input}
        label={
          labelIcon ? (
            <StyledSpan>
              {label}
              {labelIcon}
            </StyledSpan>
          ) : (
            label
          )
        }
      />
    );
  }

  return input;
};

export default Radio;
