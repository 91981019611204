import {
  Nullable,
  StartEndDates,
  StartEndDatesSuffixedType,
  TranslatedText,
} from './utils';

export interface CertificationBody {
  uuid: string;
  name: string;
  cbid?: string; // human readable id
  legalForm?: string;
}

export interface UserDetails {
  actingUserId: string; // UUID
  userContexts: UserContext[];
  currentContext: UserContext;
}

export interface UserContext {
  userId: string; // UUID
  username: string;
  companyId?: string;
  companyType?: string;
  companyName?: string;
  roles: string[]; // TBD
  permissions: string[]; // TBD
}

export interface AccessTokenPayloadType extends UserDetails {
  iat: number;
  exp: number;
}

export enum UserPermissionEnum {
  AUDIT_APPOINTMENT_BLACKOUT_TIME_ADD = 'audit_appointment.blackout_time_add',
  AUDIT_APPOINTMENT_BLACKOUT_TIME_DELETE = 'audit_appointment.blackout_time_delete',
  AUDIT_APPOINTMENT_CANCEL = 'audit_appointment.cancel',
  AUDIT_APPOINTMENT_CREATE = 'audit_appointment.create',
  AUDIT_APPOINTMENT_DETAILS = 'audit_appointment.details',
  AUDIT_APPOINTMENT_LIST = 'audit_appointment.list',
  AUDIT_APPOINTMENT_LOG_VIEW = 'audit_appointment.log_view',
  AUDIT_APPOINTMENT_RESET_DUE_DATE = 'audit_appointment.reset_due_date',
  AUDIT_APPOINTMENT_SET_DUE_DATE = 'audit_appointment.set_due_date',
  AUDIT_APPOINTMENT_UPDATE = 'audit_appointment.update',
  AUDIT_DRAFT_CREATE = 'audit_draft.create',
}

export interface FieldIdentifier<IdType = string> {
  id: IdType;
  name: string;
  enabled: boolean;
}

export interface Supplier {
  uuid: string;
  name: string;
  coid: string;
}

export interface GeneralDetails<IdType = string> {
  id: IdType;
  name: string;
}

export interface NestedGeneralDetails<IdType = string>
  extends GeneralDetails<IdType> {
  children: NestedGeneralDetails<IdType>[];
}

export interface Document {
  uuid: string;
  docDefinitionId: string;
  type: GeneralDetails;
  language: Nullable<string>;
  fileName: string;
  displayName?: string;
  uploadedAt: string;
}

export interface ResultDetails {
  id: string;
  color: string;
  text: string;
  percentage?: string;
  level?: string;
}

export interface Result {
  overall: ResultDetails;
  levels?: ResultDetails[];
}

export interface RawResultDetails {
  level: string;
  percent: string | number;
  id: string;
  passed: boolean;
}

export interface RawResult {
  overall: RawResultDetails;
  levels: RawResultDetails[];
}

export enum ProcessStatus {
  OPEN = 'open',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  DEACTIVATED = 'deactivated',
}

export interface Message {
  id: string;
  text: string;
}

export interface TimeLog {
  uuid?: string;
  date: string;
  from: Nullable<string>;
  to: Nullable<string>;
  comment?: string;
  togetherWithLeadAuditor?: boolean;
}

export interface AuditorParticipant {
  uuid?: string;
  userId?: string;
  firstName: string;
  lastName: string;
  role: string;
  timeLog?: TimeLog[];
}

export interface RoleMapper {
  role: string;
  name: TranslatedText;
  description?: Nullable<TranslatedText>;
}

export interface Logo {
  type: string;
  value: string;
  logo: string;
}

export enum CertificateLockEnum {
  SUSPENSION = 'suspension',
  WITHDRAWAL = 'withdrawal',
}

export interface CertificateLock {
  uuid: string;
  type: GeneralDetails<CertificateLockEnum>;
  lockDate: string;
  unlockDate?: Nullable<string>;
  category: GeneralDetails;
  categoryOthersDescription?: Nullable<string>;
  descriptionEnglish: string;
  descriptionOtherLanguage?: Nullable<string>;
  unlockReasonDescription?: Nullable<string>;
}

export interface Certificate {
  id: string;
  certificateIsAllowed: boolean;
  userHasIssuedCertificate?: boolean;
  issuedAt?: string;
  validity?: StartEndDates;
  announcedRecertificationTimeframe?: StartEndDates;
  unannouncedRecertificationTimeframe?: StartEndDates;
  activeLock?: Nullable<CertificateLock> | false;
  latestLock?: Nullable<CertificateLock> | false;
}

export enum AuditStatusEnum {
  PUBLISHED = 'published',
  DELETED = 'deleted',
}

export interface Audit {
  uuid: string;
  ceid?: string;
  auid?: string;
  certificationBody: CertificationBody;
  supplier: Supplier;
  status: GeneralDetails<AuditStatusEnum>;
  module: GeneralDetails;
  type: GeneralDetails;
  kind: GeneralDetails;
  auditTimeframe: StartEndDatesSuffixedType;
  result: Result;
  certificate?: Certificate;
}

export interface Log {
  action: string;
  executedBy: string;
  executedAt: string;
  info: string[];
}

export enum DraftStatusEnum {
  UNSUBMITTED = 'unsubmitted',
  SUBMITTED = 'submitted',
  CANCELLED = 'cancelled',
}

export type DraftStatus = {
  id: DraftStatusEnum;
  name: string;
};

export interface DocumentDefinition {
  id: string;
  type: GeneralDetails;
  languageSensitive: boolean;
  languageListId: Nullable<LanguageListIdEnum>;
  languages: Nullable<string[]>;
  allowedFileTypes: FileExtensionEnum[];
  maxFileSize: number; // in MB
  isRequired: boolean;
  isUploadable: boolean;
  isNameRequired: boolean;
}

export enum LanguageListIdEnum {
  IFS_BASIC_LANGUAGES = 'ifs-basic-languages',
  ALL_LANGUAGES = 'all-languages',
}

export enum FileExtensionEnum {
  DOC = 'doc',
  DOCX = 'docx',
  PDF = 'pdf',
  XLS = 'xls',
  XLSX = 'xlsx',
  PPT = 'ppt',
  PPTX = 'pptx',
  TXT = 'txt',
  TIFF = 'tiff',
  PNG = 'png',
  JPG = 'jpg',
  JPEG = 'jpeg',
  MP4 = 'mp4',
  MOV = 'mov',
}

export interface ExceptionalCircumstance {
  uuid: string;
  type: GeneralDetails;
  description: string;
  closeReason?: Nullable<string>;
}

export interface Access {
  uuid: string;
  company: Supplier;
  companyType: GeneralDetails;
  role: GeneralDetails;
  createdAt: string;
}
