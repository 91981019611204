import React, { FC } from 'react';

export const AppLogo: FC = () => (
  <svg
    width="58px"
    height="24px"
    viewBox="0 0 58 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="_mainLogo_6ujxo_76"
  >
    <path
      d="M2.02416 1.01409C2.02416 1.01409 2.3123 0.509738 2.89114 0.508592L6.69849 0.5C6.69849 0.5 7.27705 0.498568 7.56775 1.00178L9.4784 4.31116C9.4784 4.31116 9.76939 4.81352 9.48069 5.31816L7.58485 8.63585C7.58485 8.63585 7.29614 9.14049 6.7173 9.14192L2.90995 9.15051C2.90995 9.15051 2.33111 9.15195 2.04126 8.64845L0.129753 5.33906C0.129753 5.33906 -0.160665 4.83585 0.127473 4.33207L2.02416 1.01409Z"
      fill="white"
    />
    <path
      d="M27.4938 22.6817H24.0501C23.8509 22.6817 23.6719 22.5479 23.6719 22.3469V6.63221C23.6719 6.45321 23.8269 6.34151 24.0501 6.31946L27.4938 5.89501C27.7357 5.87238 27.8483 6.05138 27.8483 6.22953V22.3469C27.8483 22.5476 27.6927 22.6817 27.4938 22.6817Z"
      fill="white"
    />
    <path
      d="M42.7151 9.17633H35.918V13.7078H41.9826C42.1818 13.7078 42.3369 13.8416 42.3369 14.0423V16.3642C42.3369 16.565 42.1818 16.6987 41.9826 16.6987H35.918V22.3239C35.918 22.5247 35.7391 22.6811 35.5188 22.6811H32.1415C31.9408 22.6811 31.7422 22.5247 31.7422 22.3239V6.542C31.7422 6.34123 31.9408 6.18457 32.1415 6.18457H42.7151C42.916 6.18457 43.0716 6.31918 43.0716 6.52023V8.84124C43.0716 9.0423 42.916 9.17633 42.7151 9.17633Z"
      fill="white"
    />
    <path
      d="M50.8325 22.9266C47.7237 22.9266 45.2581 22.212 44.8805 22.0788C44.6146 21.9895 44.4137 21.8334 44.5029 21.3416L44.8141 19.6003C44.8591 19.2661 45.1464 19.1544 45.4576 19.2432C45.9698 19.3551 48.2142 20.003 50.1462 20.003C51.8115 20.003 53.0324 19.6 53.0324 18.1056C53.0324 16.4759 51.5461 16.3642 49.658 16.0518C47.4133 15.6946 44.3262 14.98 44.3262 11.1188C44.3262 7.07848 47.0804 5.93945 50.79 5.93945C53.3893 5.93945 55.6556 6.54233 56.1658 6.69871C56.4553 6.78778 56.7209 6.94415 56.654 7.34655L56.3211 9.17667C56.2778 9.37772 56.1438 9.60026 55.7425 9.51118C54.2554 9.17667 52.7907 8.84157 51.2782 8.84157C49.8353 8.84157 48.502 9.10936 48.502 10.6499C48.502 12.2125 50.0795 12.3466 51.9223 12.6367C54.4763 13.0385 57.2981 13.5744 57.2981 17.8152C57.2972 21.677 54.2331 22.9266 50.8325 22.9266Z"
      fill="white"
    />
    <path
      d="M10.4243 16.3637C10.4243 16.3637 10.7124 15.8593 11.2912 15.8582L15.0989 15.8496C15.0989 15.8496 15.6772 15.8482 15.9676 16.3511L17.8788 19.6605C17.8788 19.6605 18.1692 20.1628 17.8811 20.6675L15.985 23.9852C15.985 23.9852 15.6962 24.4898 15.118 24.4912L11.3101 24.4998C11.3101 24.4998 10.7312 24.5013 10.4414 23.9975L8.53014 20.6887C8.53014 20.6887 8.23973 20.1855 8.52786 19.6817L10.4243 16.3637Z"
      fill="#B2C0CE"
    />
    <path
      d="M10.4245 6.3469C10.4245 6.3469 10.7127 5.84254 11.2915 5.84111L15.0991 5.83252C15.0991 5.83252 15.6774 5.83109 15.9678 6.33401L17.8791 9.6434C17.8791 9.6434 18.1692 10.146 17.8813 10.6504L15.9852 13.9681C15.9852 13.9681 15.6965 14.4727 15.1182 14.4742L11.3103 14.4827C11.3103 14.4827 10.7315 14.4842 10.4416 13.9804L8.5304 10.6713C8.5304 10.6713 8.23941 10.1684 8.52812 9.66431L10.4245 6.3469Z"
      fill="#B2C0CE"
    />
  </svg>
);

export default AppLogo;
