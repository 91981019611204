import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useModalCommonRequiredFields } from '../../../hooks/useModalCommonRequiredFields';
import { useModalFormFieldsAreValid } from '../../../hooks/useModalFormFieldsAreValid';
import { useTranslatedSelectOptions } from '../../../hooks/useTranslatedSelectOptions';
import { useGetCertificateDetailsQuery } from '../../../state/certificateDetails/api';
import { useLockCertificateMutation } from '../../../state/certificateLock/api';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { CertificateModalCommonProps } from '../../../types';
import { StyledCustomHeading } from '../../atoms/StyledCustomHeading';
import {
  CertificateDetailsModalBase,
  NextAction,
} from '../../templates/CertificateDetailsModalBase';
import { LockCertificateForm } from '../LockCertificateForm';

type LockCertificateModalProps = CertificateModalCommonProps;

export const LockCertificateModal: FC<LockCertificateModalProps> = (props) => {
  const { certificateUuid } = props;
  const [t] = useTranslation('certificateDetails');
  const { isLoading, lockCertificate, error } = useLockCertificateMutation();
  const { certificateDetails: { ceid } = {} } = useGetCertificateDetailsQuery({
    certificateUuid,
  });
  const isFormValid = useModalFormFieldsAreValid();
  const type = CertificateDetailsModalEnum.LOCK_CERTIFICATE;
  const fieldValues = useModalCommonRequiredFields();
  const reasonCategoriesOptions = useTranslatedSelectOptions({
    type,
    collectionName: 'reasonCategories',
  });
  const actionReasonOptions = useTranslatedSelectOptions({
    type,
    collectionName: 'actionReasons',
  });

  const nextAction: NextAction = (openSnackbar, closeModal) => {
    lockCertificate({
      certificateUuid,
      ...fieldValues,
    }).then(() => {
      openSnackbar(t('The Certificate is locked.'), 'success');
      closeModal();
    });
  };

  return (
    <CertificateDetailsModalBase
      title={t('Lock certificate/letter of confirmation')}
      nextAction={nextAction}
      nextLabel={t('Lock Certificate')}
      disableNext={!isFormValid}
      isLoading={isLoading}
      nextColor="error"
    >
      <LockCertificateForm
        errors={error}
        customHeading={
          ceid && (
            <StyledCustomHeading>
              {t('Lock certificate/letter of confirmation for CEID {{ceid}}', {
                ceid,
              })}
            </StyledCustomHeading>
          )
        }
        reasonCategories={reasonCategoriesOptions}
        actionReasonOptions={actionReasonOptions}
      />
    </CertificateDetailsModalBase>
  );
};
