import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import routes from 'config/routes';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';

export const HomePage: FC = () => {
  const isAdmin = useSelector(selectUserIsAdmin);
  return (
    <Redirect to={isAdmin ? routes.NOT_FOUND : routes.PERMISSION_DENIED} />
  );
};
