import { styled } from '@mui/material';
import React, { FC, useMemo } from 'react';

import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { Textarea } from 'components/atoms/Textarea';
import { AlertList } from 'components/molecules/AlertList';
import { ActionInformationSection } from 'components/organisms/ActionInformationSection';
import { useCommentStep } from 'hooks/draft/useCommentStep';
import { getStatus } from 'utils/getStatus';
import { getTranslatedValue } from 'utils/getTranslatedValue';
import { isAnyError } from 'utils/isAnyError';
import { isAnyLoading } from 'utils/isAnyLoading';

import { useAuditDraftDetailsQuery } from '../../../state/auditDraftDetails/api';
import { useSetAuditDraftActionCommentMutation } from '../../../state/setActionComment/api';
import { useSetAuditDraftCommentMutation } from '../../../state/setComment/api';
import { FormCommentStep } from '../../../types';
import { TemplateAuditDraftStep } from '../../templates/TemplateAuditDraftStep';

type FormCommentProps = FormCommentStep['props'] & { auditDraftId: string };

const StyledStepContent = styled('div')`
  margin: 0 10px;
`;
const StyledStepDescription = styled(StepDescription)`
  margin-bottom: 20px;
`;

export const FormComment: FC<FormCommentProps> = (props) => {
  const {
    actionReasonOptions,
    auditDraftId,
    backAction,
    instructionText,
    nextAction,
    title,
  } = props;
  const { auditDraftDetails: draftDetails, status: auditDraftDetailsStatus } =
    useAuditDraftDetailsQuery(auditDraftId);

  const {
    comment,
    adminReason,
    adminComment,
    handleCommentChange,
    handleAdminReasonChange,
    handleAdminCommentChange,
    options,
    disableNextButton,
    isAdmin,
  } = useCommentStep({ draftDetails, actionReasonOptions });

  const {
    error: setCommentError = [],
    setComment: setCommentRequest,
    status: setCommentStatus,
  } = useSetAuditDraftCommentMutation();
  const {
    error: setActionReasonError = [],
    setAuditDraftActionComment: setActionCommentRequest,
    status: actionCommentStatus,
  } = useSetAuditDraftActionCommentMutation();
  const error = isAnyError(setCommentStatus, actionCommentStatus);
  const errorMessages = useMemo(
    () => [...setCommentError, ...setActionReasonError],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(setCommentError), JSON.stringify(setActionReasonError)]
  );
  const nextButtonStatus = getStatus(
    isAnyLoading(actionCommentStatus, setCommentStatus)
  );

  const handleNextClick = (goNextStep: () => void) => {
    const commentRequest = comment
      ? setCommentRequest({
          auditDraftId,
          comment,
        })
      : Promise.resolve();

    commentRequest
      .then(() =>
        isAdmin
          ? setActionCommentRequest({
              auditDraftId,
              actionComment: {
                reason: adminReason,
                comment: adminComment,
              },
            })
          : Promise.resolve()
      )
      .then(goNextStep);
  };

  return (
    <TemplateAuditDraftStep
      contentStatus={auditDraftDetailsStatus}
      nextButtonStatus={nextButtonStatus}
      backAction={backAction}
      nextAction={nextAction}
      disableNext={disableNextButton}
      onNextClick={handleNextClick}
    >
      <StepFormTitle content={getTranslatedValue(title)} />
      <StyledStepContent>
        {error && <AlertList alerts={errorMessages} />}
        <StyledStepDescription content={getTranslatedValue(instructionText)} />
        <Textarea
          fullWidth
          multiline
          rows={6}
          value={comment}
          onChange={handleCommentChange}
          data-testid="comment-textarea"
        />
        {isAdmin && (
          <ActionInformationSection
            adminReason={adminReason}
            adminComment={adminComment}
            handleAdminReasonChange={handleAdminReasonChange}
            handleAdminCommentChange={handleAdminCommentChange}
            adminReasonOptions={options}
          />
        )}
      </StyledStepContent>
    </TemplateAuditDraftStep>
  );
};
