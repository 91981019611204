import { BASE64_OUTPUT_ERROR } from '../../config/constants';

const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result?.toString();

        if (result && file.name.endsWith('.ifs')) {
          // ensure .ifs file will have correct MIME type
          result = result.replace(
            'data:application/octet-stream',
            'data:application/json'
          );
        }
        resolve(result || '');
      };
      reader.onerror = () => reject(BASE64_OUTPUT_ERROR);
    } catch {
      reject(BASE64_OUTPUT_ERROR);
    }
  });

export default convertFileToBase64;
