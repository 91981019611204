import React from 'react';

import { getTranslatedValue } from 'utils/getTranslatedValue';

import { countries } from '../../../config/countries';
import { AdminTableCell } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

export const SupplierCountryCell: AdminTableCell = ({ supplier }) => {
  const countryName = countries.find(
    (country) =>
      country.id.toLocaleLowerCase() ===
      supplier.countryCode?.toLocaleLowerCase()
  );

  if (!countryName) {
    return null;
  }

  return (
    <StyledPlainText>{getTranslatedValue(countryName.text)}</StyledPlainText>
  );
};
