import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { invalidateRTKTags } from 'utils/invalidateRTKTags';

import { UnlockCertificateParams, UpdateUnlockReasonParams } from './types';
import endpoints from '../../config/endpoints';

const certificateLockUnlockApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    unlockCertificate: build.mutation<unknown, UnlockCertificateParams>({
      query({ certificateUuid, data }) {
        return {
          url: endpoints.CERTIFICATE_LOCK.UNLOCK_CERTIFICATE(certificateUuid),
          data,
          method: 'POST',
        };
      },
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_DETAILS',
        'CERTIFICATE_DETAILS_ACTIONS',
        'CERTIFICATE_DETAILS_LOCK_HISTORY',
      ]),
    }),
    updateUnlockReason: build.mutation<unknown, UpdateUnlockReasonParams>({
      query({ certificateUuid, lockUuid, data }) {
        return {
          url: endpoints.CERTIFICATE_LOCK.UPDATE_UNLOCK_REASON(
            certificateUuid,
            lockUuid
          ),
          data,
          method: 'PUT',
        };
      },
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_DETAILS',
        'CERTIFICATE_DETAILS_ACTIONS',
        'CERTIFICATE_DETAILS_LOCK_HISTORY',
      ]),
    }),
  }),
});

export const useUnlockCertificateMutation = () => {
  const [unlockCertificate, mutation] =
    certificateLockUnlockApi.useUnlockCertificateMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: UnlockCertificateParams) =>
    unlockCertificate(params).unwrap();

  return {
    ...mutation,
    unlockCertificate: requestMethod,
    status,
    error,
  };
};

export const useUpdateUnlockReasonMutation = () => {
  const [updateUnlockReason, mutation] =
    certificateLockUnlockApi.useUpdateUnlockReasonMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: UpdateUnlockReasonParams) =>
    updateUnlockReason(params).unwrap();

  return {
    ...mutation,
    updateUnlockReason: requestMethod,
    status,
    error,
  };
};
