import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { LoaderStatusEnum } from 'types';

import { ConditionalModalProps } from './types';
import {
  getAuditAppointment,
  selectAuditAppointmentDetails,
} from '../../../state/details/slice';
import {
  resetDueDateState,
  selectDueDateLoading,
  selectResetDueDateModalOpen,
  setResetDueDateModalOpen,
} from '../../../state/dueDate/slice';
import { ResetDueDateModal } from '../ResetDueDateModal';

export const ConditionalResetDueDateModal: FC<ConditionalModalProps> = ({
  appointmentId,
}) => {
  const [t] = useTranslation('auditAppointment');
  const notificationMessage = t('Due date is reset.');
  const { openSnackbar } = useSnackbarNotification();
  const dispatch = useDispatch();
  const open = useSelector(selectResetDueDateModalOpen);
  const loading = useSelector(selectDueDateLoading);
  const auditAppointmentDetails = useSelector(selectAuditAppointmentDetails);

  const closeModal = () => {
    dispatch(setResetDueDateModalOpen(false));
    dispatch(resetDueDateState());
  };

  useEffect(() => {
    if (loading === LoaderStatusEnum.SUCCESS && open) {
      dispatch(resetDueDateState()); // reset state to IDLE
      dispatch(getAuditAppointment(appointmentId)); // refetch audit appointment details if success
      openSnackbar(notificationMessage, 'success');
    }
  }, [
    loading,
    appointmentId,
    open,
    dispatch,
    openSnackbar,
    notificationMessage,
  ]);

  if (!open || !auditAppointmentDetails?.dueDate) return null;

  return (
    <ResetDueDateModal
      open={open}
      handleClose={closeModal}
      appointmentId={appointmentId}
      dateRange={auditAppointmentDetails.dueDate}
    />
  );
};
