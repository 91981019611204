import { useRef } from 'react';

import { LoaderStatusEnum, Nullable } from 'types';

export interface PrimitiveStatuses {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isUninitialized: boolean;
  isFetching?: boolean;
}

export const useRTKQueryStatus = (
  primitiveStatuses: PrimitiveStatuses,
  whenUninitializedHoldPrevValue = false,
  fetchingEqualsLoading = true
): LoaderStatusEnum => {
  const { isError, isFetching, isLoading, isSuccess, isUninitialized } =
    primitiveStatuses;
  const prevStatus = useRef<Nullable<LoaderStatusEnum>>(null);
  const saveAndPrintValue = (value: LoaderStatusEnum) => {
    prevStatus.current = value;
    return value;
  };

  if (whenUninitializedHoldPrevValue && isUninitialized && prevStatus.current) {
    return prevStatus.current;
  }

  if (isError) {
    return saveAndPrintValue(LoaderStatusEnum.ERROR);
  }
  if (isLoading || (isFetching && fetchingEqualsLoading)) {
    return saveAndPrintValue(LoaderStatusEnum.LOADING);
  }
  if (isSuccess) {
    return saveAndPrintValue(LoaderStatusEnum.SUCCESS);
  }

  return saveAndPrintValue(LoaderStatusEnum.IDLE);
};
