import { useState } from 'react';

interface UseAbortModal {
  isAbortModalOpen: boolean;
  openAbortModal: () => void;
  closeAbortModal: () => void;
}

export const useAbortModal = (): UseAbortModal => {
  const [isAbortModalOpen, setIsAbortModalOpen] = useState(false);
  const openAbortModal = () => setIsAbortModalOpen(true);
  const closeAbortModal = () => setIsAbortModalOpen(false);

  return {
    isAbortModalOpen,
    openAbortModal,
    closeAbortModal,
  };
};
