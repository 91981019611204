import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  PayloadAction,
} from '@reduxjs/toolkit';

import { MISSING_PARAMS_ERROR } from 'config/constants';
import type { RootState } from 'store';
import { apiSlice } from 'store/apiSlice';
import { startAppListening } from 'store/listenerMiddleware';
import {
  ActionComment,
  AlertsState,
  AsyncThunkOptions,
  LoaderStatusEnum,
  Nullable,
} from 'types';
import apiClient from 'utils/apiClient';
import { createErrorAlertState } from 'utils/createAlertsState';
import { isDateInRange } from 'utils/dates';
import { formatToAlerts } from 'utils/formatToAlerts';

import {
  AuditAppointmentCreateState,
  AuditAppointmentDescription,
  AuditAppointmentForm,
  AuditAppointmentTime,
} from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';
import {
  AuditAppointmentKindEnum,
  AuditAppointmentStepEnum,
  ExecutionModeIdentifier,
  KindIdentifier,
  ModuleIdentifier,
  TypeIdentifier,
} from '../../types';
import {
  fetchAnnouncedEndRange,
  fetchUnannouncedEndDate,
  fetchUnannouncedPeriodDates,
  validateSelectedTime,
} from '../formOptions/slice';
import {
  getCreateValues,
  getSupplierIdAndCertificationBodyId,
} from '../formOptions/utils';
import { resetModalState } from '../stepperModal/slice';

export const INITIAL_REFETCH_TRIGGER_COUNTER = 0;

const initialState: AuditAppointmentCreateState = {
  values: {
    // TODO: it might be problematic for some browser
    // Object.keys doesn't give us the same result in different browsers
    // order of keys is very important; please keep it as it is on design
    module: null,
    type: null,
    kind: null,
    time: null,
    executionMode: null,
    description: null,
  },
  refetchTriggerCounter: INITIAL_REFETCH_TRIGGER_COUNTER,
  loading: LoaderStatusEnum.IDLE,
  alertsState: null,
};

const reducerName = `${parentReducerName}/create`;

export const createAuditAppointment = createAsyncThunk<
  null,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/createAuditAppointment`, async (_, thunkApi) => {
  try {
    const { module, type, kind, executionMode, time, description } =
      getCreateValues(thunkApi);

    if (!module || !type || !kind || !executionMode || !time) {
      return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);
    }
    const isUnannounced = kind.id === AuditAppointmentKindEnum.UNANNOUNCED;

    const body = {
      moduleId: module.id,
      type: type.id,
      kind: kind.id,
      executionMode: executionMode.id,
      [isUnannounced ? 'unannouncedPeriod' : 'dueDate']: time,
      description: description?.description ?? null,
      ...(description?.actionComment && {
        actionComment: description?.actionComment,
      }),
      ...getSupplierIdAndCertificationBodyId(thunkApi),
    };

    const { data } = await apiClient.post(
      endpoints.CREATE_PROCESS.SUBMIT,
      body
    );
    thunkApi.dispatch(resetModalState());

    thunkApi.dispatch(apiSlice.util.invalidateTags(['APPOINTMENTS']));

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

// slice
export const auditAppointmentCreateSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setModule: (state, action: PayloadAction<ModuleIdentifier>) => {
      state.values.module = action.payload;
    },
    setType: (state, action: PayloadAction<TypeIdentifier>) => {
      state.values.type = action.payload;
    },
    setKind: (state, action: PayloadAction<KindIdentifier>) => {
      state.values.kind = action.payload;
    },

    setStartTime: (state, action: PayloadAction<string>) => {
      const end = state.values.time?.end || null;
      state.values.time = { start: action.payload, end };
    },
    setEndTime: (state, action: PayloadAction<Nullable<string>>) => {
      const start = state.values.time?.start || null;
      state.values.time = { start, end: action.payload };
    },
    setExecutionMode: (
      state,
      action: PayloadAction<ExecutionModeIdentifier>
    ) => {
      state.values.executionMode = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.values.description = {
        ...state.values.description,
        description: action.payload,
      };
    },
    setDescriptionActionComment: (
      state,
      action: PayloadAction<ActionComment>
    ) => {
      state.values.description = {
        description: state.values.description?.description ?? '',
        actionComment: action.payload,
      };
    },
    resetTimes: (state) => {
      state.values.time = null;
    },
    resetFormValuesToCurrentStep: (
      state,
      action: PayloadAction<AuditAppointmentStepEnum>
    ) => {
      const valueKeys = Object.keys(state.values) as AuditAppointmentStepEnum[];
      const indexOfCurrentStep = valueKeys.indexOf(action.payload);

      valueKeys.forEach((valueKey, index) => {
        if (index > indexOfCurrentStep) {
          state.values[valueKey] = null;
        }
      });
    },
    resetCreateState: () => initialState,
    resetAlertsState: (state) => {
      state.alertsState = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnnouncedEndRange.fulfilled, ({ values }, action) => {
      const {
        payload: { earliest, latest },
      } = action;

      if (
        values.time?.end &&
        !isDateInRange(values.time.end, earliest, latest)
      ) {
        const start = values.time?.start || null;
        values.time = { start, end: null };
      }
    });
    builder.addCase(fetchUnannouncedEndDate.fulfilled, ({ values }, action) => {
      if (values.time) {
        values.time.end = action.payload.end;
      }
    });
    builder.addCase(
      fetchUnannouncedPeriodDates.fulfilled,
      ({ values }, { payload: { start, end } }) => {
        values.time = {
          start,
          end,
        };
      }
    );
    builder.addCase(createAuditAppointment.fulfilled, (state) => {
      state.values = initialState.values;
      state.refetchTriggerCounter += 1;
      state.loading = LoaderStatusEnum.SUCCESS;
    });
    builder.addCase(createAuditAppointment.pending, (state) => {
      state.loading = LoaderStatusEnum.LOADING;
    });
    builder.addCase(createAuditAppointment.rejected, (state, action) => {
      state.alertsState = createErrorAlertState(action.payload);
      state.loading = LoaderStatusEnum.ERROR;
    });
  },
});

// actions
export const {
  setModule,
  setType,
  setKind,
  setExecutionMode,
  setDescription,
  setDescriptionActionComment,
  resetFormValuesToCurrentStep,
  setStartTime,
  setEndTime,
  resetTimes,
  resetCreateState,
  resetAlertsState,
} = auditAppointmentCreateSlice.actions;

// selectors
export const selectAuditPlanModule = (
  state: RootState
): Nullable<ModuleIdentifier> => state[parentReducerName].create.values.module;

export const selectAuditPlanType = (
  state: RootState
): Nullable<TypeIdentifier> => state[parentReducerName].create.values.type;

export const selectAuditPlanKind = (
  state: RootState
): Nullable<KindIdentifier> => state[parentReducerName].create.values.kind;

export const selectAuditPlanTime = (
  state: RootState
): Nullable<AuditAppointmentTime> =>
  state[parentReducerName].create.values.time;

export const selectAuditPlanExecutionMode = (
  state: RootState
): Nullable<ExecutionModeIdentifier> =>
  state[parentReducerName].create.values.executionMode;

export const selectAuditPlanDescription = (
  state: RootState
): Nullable<AuditAppointmentDescription> =>
  state[parentReducerName].create.values.description;

export const selectAuditAppointmentFormValues = (
  state: RootState
): AuditAppointmentForm => state[parentReducerName].create.values;

export const selectRefetchTriggerCounter = (state: RootState): number =>
  state[parentReducerName].create.refetchTriggerCounter;

export const selectLoadingStatus = (state: RootState): LoaderStatusEnum =>
  state[parentReducerName].create.loading;

export const selectAlertsState = (state: RootState): Nullable<AlertsState> =>
  state.auditAppointment.create.alertsState;

export default auditAppointmentCreateSlice.reducer;

// listeners
startAppListening({
  matcher: isFulfilled(fetchAnnouncedEndRange),
  effect: (_, { dispatch, getState }) => {
    const { time, kind } = getState().auditAppointment.create.values;

    if (time?.end && kind?.id === AuditAppointmentKindEnum.ANNOUNCED)
      dispatch(validateSelectedTime());
  },
});
