import routes from 'config/routes';

import { trigger } from '../customEvents';
import { REDIRECT_TO_FORBIDDEN_EVENT } from '../customEvents/eventNames';

export interface ForbiddenRedirectPayload {
  pathname: string;
  state: {
    redirectPath?: string;
  };
}

export const handleForbiddenError = (redirectPath?: string): void =>
  trigger<ForbiddenRedirectPayload>(REDIRECT_TO_FORBIDDEN_EVENT, {
    pathname: routes.PERMISSION_DENIED,
    state: {
      redirectPath,
    },
  });
