import { useEffect, useRef, useState } from 'react';

import { FormConfig, Nullable } from 'types';

import {
  filterOrResetCodependentItemsFromState,
  getInitialState,
  getTemplateChildByDataRef,
  isRequired,
  isVisible,
  validateDynamicForm,
  validateDynamicFormItem,
} from './utils';
import {
  DynamicFormCommonProps,
  ValidationStateType,
} from '../../molecules/DynamicFormItems/types';

interface UseDynamicFormProps {
  template?: Nullable<FormConfig>;
  draft?: Nullable<Record<string, unknown>>;
  isTemplateSuccess?: boolean;
  isDraftSuccess?: boolean;
}

export interface UseDynamicForm extends DynamicFormCommonProps {
  setValues: (newValues: Record<string, unknown>) => void;
  isFormValid: boolean;
}

type UseDynamicFormType = (props: UseDynamicFormProps) => UseDynamicForm;

/**
 * @param template json with information what inputs are in the form
 * @param draft key value structure where value can be string but also complex structure
 * @param isTemplateSuccess boolean status indicating template is ready to use
 * @param isDraftSuccess boolean status indicating draft is ready to use
 */

export const useDynamicForm: UseDynamicFormType = ({
  draft,
  template,
  isTemplateSuccess,
  isDraftSuccess,
}) => {
  const children = template?.children;
  const isInitialStateSet = useRef<boolean>(false);
  const [values, setValues] = useState<Nullable<Record<string, unknown>>>(null);
  const [initialValues, setInitialValues] =
    useState<Nullable<Record<string, unknown>>>(null);
  const [validationState, setValidationState] =
    useState<ValidationStateType>(null);
  const isFormValid = validateDynamicForm(() => null, values, children);

  const setValue = (key: string, value: unknown) => {
    const initialState = getInitialState(children, draft);
    const newState = values
      ? { ...initialState, ...values, [key]: value }
      : { ...initialState, [key]: value };

    const filteredState = filterOrResetCodependentItemsFromState(
      newState,
      children,
      initialValues
    );

    setValues(filteredState);
  };

  const validateFormItem = (value: unknown, dataRef: string) => {
    const formItem = getTemplateChildByDataRef(dataRef, children);
    const { props, type } = formItem || {};

    if (
      isVisible(values, dataRef, children) &&
      isRequired(values, dataRef, children) &&
      type &&
      props
    ) {
      const newValidationState = validateDynamicFormItem(value, props, type);
      setValidationState((prevState) => ({
        ...prevState,
        [dataRef]: newValidationState,
      }));
    }
  };

  useEffect(() => {
    if (
      values === null &&
      template &&
      !isInitialStateSet.current &&
      isDraftSuccess &&
      isTemplateSuccess
    ) {
      const initialState = getInitialState(children, draft);

      const finalState = filterOrResetCodependentItemsFromState(
        initialState,
        children
      );

      setValues(finalState);
      setInitialValues(finalState);

      isInitialStateSet.current = true;
    }
  }, [values, draft, children, isDraftSuccess, isTemplateSuccess, template]);

  return {
    formItems: template?.children,
    values,
    validationState,
    setValue,
    setValues,
    validateFormItem,
    isFormValid,
    isVisible: (dataRef: string) => isVisible(values, dataRef, children),
  };
};
