import { styled } from '@mui/material';
import React from 'react';

import { StyledLink } from 'components/atoms/StyledLink';
import { formatDate } from 'utils/dates';

import { yiiUrls } from '../../../config/yiiUrls';
import { AdminTableCell } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

const StyledCertificateInfo = styled(StyledPlainText)`
  margin-bottom: 3px;
`;

export const CertificateCell: AdminTableCell = (props) => {
  const { certificate, supplier, t } = props;

  if (!certificate) {
    return null;
  }

  const { ceid, validity, uuid } = certificate;

  return (
    <>
      {ceid && (
        <StyledCertificateInfo>
          CEID:{' '}
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            to={{
              pathname: yiiUrls.CERTIFICATE_DETAILS(supplier.uuid, uuid),
            }}
          >
            {ceid}
          </StyledLink>
        </StyledCertificateInfo>
      )}
      <StyledCertificateInfo>
        {t('Valid until', { ns: 'components' })}: {formatDate(validity.end)}
      </StyledCertificateInfo>
    </>
  );
};
