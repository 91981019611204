import { AnyObject, array, Maybe, ObjectSchema } from 'yup';

import { DATA_INCOSISTENCY_ERROR } from 'config/constants';
import { BaseQueryMeta } from 'store/apiSlice';

interface Options {
  throwErrorOnSchemaError?: boolean;
}

const defaultOptions: Options = {
  throwErrorOnSchemaError: false,
};

export const validateAndLogErrors =
  <ResponseType>(
    schema: ObjectSchema<Maybe<AnyObject>>,
    options: Options = defaultOptions
  ) =>
  (response: ResponseType, meta: BaseQueryMeta): ResponseType => {
    try {
      if (Array.isArray(response)) {
        array().of(schema).validateSync(response, { abortEarly: false });
      } else {
        schema.validateSync(response, { abortEarly: false });
      }
    } catch (error) {
      console.error(error);
      if (!!error && typeof error === 'object' && 'errors' in error) {
        console.error(`${meta.requestInfo} ---> ${error.errors}`);
      }
      if (options.throwErrorOnSchemaError) {
        throw new Error(DATA_INCOSISTENCY_ERROR, {
          cause: DATA_INCOSISTENCY_ERROR,
        });
      }
    }
    return response;
  };
