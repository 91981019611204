import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ActionDropdownOption } from 'types';

import { AdditionalAudit } from '../state/additionalAuditManage/types';
import { useGetCertificateDetailsActions } from '../state/certificateDetailsActions/api';
import { openCertificateModal, setAuditToRemove } from '../state/modal/slice';
import { CertificateDetailsModalEnum } from '../state/modal/types';
import { CertificateActionsEnum } from '../types';

interface UseAdditionalAuditActionsDropdownProps {
  audit: AdditionalAudit;
  certificateUuid: string;
}

export type { UseAdditionalAuditActionsDropdownProps as AdditionalAuditActionsDropdownProps };

export const useAdditionalAuditActionsDropdown = (
  props: UseAdditionalAuditActionsDropdownProps
): ActionDropdownOption[] => {
  const [t] = useTranslation('certificateDetails');
  const dispatch = useDispatch();
  const { audit, certificateUuid } = props;
  const { certificateDetailsActions } =
    useGetCertificateDetailsActions(certificateUuid);

  return [
    {
      label: t('Remove Audit'),
      onClickAction: () => {
        dispatch(setAuditToRemove(audit));
        dispatch(
          openCertificateModal({
            name: CertificateDetailsModalEnum.REMOVE_AUDIT,
          })
        );
      },
      permission: CertificateActionsEnum.CERTIFICATE_AUDIT_REMOVE,
    },
  ].filter(({ permission }) => certificateDetailsActions.includes(permission));
};
