import { SelectChangeEvent } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DatePicker } from 'components/atoms/DatePicker';
import { BaseModalContent } from 'components/organisms/BaseModalContent';
import { Nullable, StartEndDates } from 'types';
import { getDateFromString } from 'utils/dates';

import { actionReasons } from '../../../config/constants';
import { selectAddBlackoutTimeAlertsState } from '../../../state/addBlackoutTime/slice';
import { DatePickerRow } from '../../atoms/DatePickerRow';

interface AddBlackoutTimeModalContentProps {
  validDates?: Nullable<StartEndDates>;
  startDate: Nullable<string>;
  endDate: Nullable<string>;
  changeStartDate: (newDate: Date) => void;
  changeEndDate: (newDate: Date) => void;
  isAdmin?: boolean;
  setIsAdminInfoValid: Dispatch<SetStateAction<boolean>>;
  adminReason: string;
  adminComment: string;
  adminReasonChangeHandler: (event: SelectChangeEvent<unknown>) => void;
  adminCommentChangeHandler: (comment: string) => void;
}

export const AddBlackoutTimeModalContent: FC<AddBlackoutTimeModalContentProps> =
  (props) => {
    const {
      validDates,
      startDate,
      endDate,
      changeStartDate,
      changeEndDate,
      isAdmin,
      setIsAdminInfoValid,
      adminReason,
      adminComment,
      adminReasonChangeHandler,
      adminCommentChangeHandler,
    } = props;
    const [t] = useTranslation('auditAppointment');
    const alertsState = useSelector(selectAddBlackoutTimeAlertsState);

    const minDate = getDateFromString(validDates?.start);
    const maxDate = getDateFromString(validDates?.end);

    return (
      <BaseModalContent
        alertsState={alertsState}
        adminReasonOptions={actionReasons.ADD_BLACKOUT_TIME.ADMIN}
        setIsAdminInfoValid={setIsAdminInfoValid}
        adminReason={adminReason}
        adminComment={adminComment}
        adminReasonChangeHandler={adminReasonChangeHandler}
        adminCommentChangeHandler={adminCommentChangeHandler}
        isAdmin={isAdmin}
      >
        <DatePickerRow label={t('Start Date')}>
          <DatePicker
            inputPlaceholder={t('Select start date')}
            value={startDate}
            defaultCalendarMonth={minDate}
            onChange={changeStartDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        </DatePickerRow>
        <DatePickerRow label={t('End Date')}>
          <DatePicker
            inputPlaceholder={t('Select end date')}
            value={endDate}
            defaultCalendarMonth={minDate}
            onChange={changeEndDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        </DatePickerRow>
      </BaseModalContent>
    );
  };

export default AddBlackoutTimeModalContent;
