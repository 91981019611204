export const certificationBodyId = '36abbf44-8304-4206-8401-a26c81c987e8';
export const supplierId = 'b4f4a2e9-0a07-431c-9ab5-afba07060207';
export const TEMPORARY_MODULES = [
  { value: 'food_7', label: 'IFS Food 7' },
  { value: 'food_8', label: 'IFS Food 8' },
  { value: 'logistics_2_2', label: 'IFS Logistics 2.2' },
  { value: 'logistics_2_3', label: 'IFS Logistics 2.3' },
  { value: 'logistics_3', label: 'IFS Logistics 3' },
  { value: 'pacsecure_1_1', label: 'IFS PACsecure 1.1' },
  { value: 'pacsecure_2', label: 'IFS PACsecure 2' },
  { value: 'pacsecure_3', label: 'IFS PACsecure 3' },
  { value: 'hpc_2', label: 'IFS HPC 2' },
  { value: 'hpc_3', label: 'IFS HPC 3' },
  { value: 'cashCarry_2', label: 'IFS Cash & Carry 2' },
  { value: 'wholesale_2', label: 'IFS Wholesale 2' },
  { value: 'broker_3_1', label: 'IFS Broker 3.1' },
  { value: 'broker_3_2', label: 'IFS Broker 3.2' },
  { value: 'esg_check_1_1', label: 'IFS ESG Check' },
  { value: 'globalMarketsFood_2', label: 'IFS Progress Food 2' },
  { value: 'globalMarketsFood_3', label: 'IFS Progress Food 3' },
  { value: 'globalMarketsHpc_1', label: 'IFS Progress HPC' },
  { value: 'globalMarketsLogistics_1', label: 'IFS Progress Logistics' },
  { value: 'globalMarketsPacsecure_1', label: 'IFS Progress PACsecure' },
];
