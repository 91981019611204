import React, { FC, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectAuditPlanExecutionMode,
  selectAuditPlanModule,
  setExecutionMode,
} from '../../../state/create/slice';
import {
  fetchExecutionModes,
  selectExecutionModesFormOptions,
} from '../../../state/formOptions/slice';
import { setIsNextButtonDisabled } from '../../../state/stepperModal/slice';
import { FormWithRadio } from '../FormWithRadio';

export const FormSetExecutionMode: FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation('auditAppointment');

  const executionModes = useSelector(selectExecutionModesFormOptions);
  const selectedExecutionMode = useSelector(selectAuditPlanExecutionMode);
  const selectedModule = useSelector(selectAuditPlanModule);

  useLayoutEffect(() => {
    if (selectedModule?.id && !executionModes.length) {
      dispatch(fetchExecutionModes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedModule]);

  useEffect(() => {
    const enabledOptions = executionModes.filter(
      (executionMode) => executionMode.enabled
    );

    if (!selectedExecutionMode && enabledOptions.length === 1) {
      dispatch(setExecutionMode(enabledOptions[0]));
      dispatch(setIsNextButtonDisabled(false));
    }
  }, [executionModes.length, dispatch, selectedExecutionMode, executionModes]);

  const handleExecutionModeChange = (executionModeId: string) => {
    const newSelectedExecutionMode = executionModes.find(
      (item) => item.id === executionModeId
    );
    if (newSelectedExecutionMode) {
      dispatch(setExecutionMode(newSelectedExecutionMode));
      dispatch(setIsNextButtonDisabled(false));
    }
  };

  return (
    <FormWithRadio
      title={t('Execution Mode')}
      description={t('Please select the execution mode of the Audit.')}
      ariaLabel={t('Audit Execution Mode')}
      options={executionModes}
      value={selectedExecutionMode?.id || ''}
      onChange={handleExecutionModeChange}
    />
  );
};
