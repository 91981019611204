import clsx from 'clsx';
import { isFirstDayOfMonth, isLastDayOfMonth } from 'date-fns';

export const getClassName = (date: Date): string =>
  clsx({
    'first-day-of-month': isFirstDayOfMonth(date),
    'last-day-of-month': isLastDayOfMonth(date),
  });

export const getDate = (date: string): Date => {
  const [day, month, year] = date.split('.');
  return new Date(`${year}-${month}-${day}`);
};
