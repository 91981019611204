import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { LoaderStatusEnum } from 'types';

import {
  CertificationBodyListParams,
  CertificationBodyListResponse,
} from './types';
import endpoints from '../../config/endpoints';

const searchCertificationBodyApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificationBodyList: build.query<
      CertificationBodyListResponse,
      CertificationBodyListParams
    >({
      query: (params) => ({
        url: endpoints.SEARCH_CB,
        params,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const useLazyGetCertificationBodyListQuery = () => {
  const [trigger, query] =
    searchCertificationBodyApi.useLazyGetCertificationBodyListQuery();
  const error = useFormattedError(query, 'formatToAlerts');
  const status = useRTKQueryStatus(query);
  const isLoading = status === LoaderStatusEnum.LOADING;

  const searchCertificationBodyList = async (
    params: CertificationBodyListParams
  ) => trigger(params);

  return {
    searchCertificationBodyList,
    certificationBodyList: query.data?.certificationBodies || [],
    isLoading,
    error: error || [],
  };
};
