import { ArrowBack } from '@mui/icons-material';
import { Grid, styled, Typography, TypographyVariant } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs } from 'components/atoms/Breadcrumbs';
import { SPANISH_GRAY } from 'config/appColors';

import { useShowPageHeaderBackButton } from './useShowPagaHeaderBackButton';

interface PageHeaderProps {
  title?: string;
  details?: string;
  titleComponent?: ReactNode;
  hideBreadcrumbs?: boolean;
  className?: string;
  titleVariant?: TypographyVariant;
  withBackButton?: boolean;
  onBackClick?: () => void;
}

interface HeaderTitleProps {
  title?: string;
  details?: string;
  titleComponent?: ReactNode;
  titleVariant: TypographyVariant;
  showBackButton: boolean;
  onBackClick?: () => void;
}

interface StyledGridProps {
  $hideTitle: boolean;
}

const StyledGrid = styled(Grid)<StyledGridProps>`
  margin: 2.7rem 4rem;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.$hideTitle ? 'flex-end' : 'space-between'};
`;

const StyledHeading = styled(Typography)`
  font-size: 2.4rem;
  line-height: 2rem;
  letter-spacing: 0.018rem;
  font-weight: 400;
  color: ${SPANISH_GRAY};
`;

const StyledDetails = styled(`span`)`
  font-size: 1.6rem;
  line-height: 2.8rem;
  letter-spacing: 0.015rem;
  margin-left: 10px;
`;

const StyledArrowBack = styled(ArrowBack)`
  margin-right: 5px;
  font-size: 2.4rem;
  cursor: pointer;
  color: ${SPANISH_GRAY};
`;
const StyledTitleContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const HeaderTitle: FC<HeaderTitleProps> = (props) => {
  const {
    title,
    details,
    titleComponent,
    titleVariant,
    showBackButton,
    onBackClick,
  } = props;

  return titleComponent ? (
    <>{titleComponent}</>
  ) : (
    <StyledTitleContainer>
      {showBackButton && (
        <StyledArrowBack
          onClick={onBackClick}
          data-testid="header-back-button"
        />
      )}
      <StyledHeading variant={titleVariant}>
        {title}
        {details && <StyledDetails>{details}</StyledDetails>}
      </StyledHeading>
    </StyledTitleContainer>
  );
};

export const PageHeader: FC<PageHeaderProps> = (props) => {
  const {
    title,
    details,
    titleComponent,
    hideBreadcrumbs,
    className,
    titleVariant = 'h2',
    withBackButton = false,
    onBackClick,
  } = props;

  const { goBack } = useHistory();
  const showBackButtonBasedOnState = useShowPageHeaderBackButton();

  const showBackButton =
    withBackButton && (showBackButtonBasedOnState || !!onBackClick);
  const onBackClickMethod = onBackClick || goBack;
  const hideTitle = !title && !titleComponent;

  if (hideTitle && hideBreadcrumbs) return null;

  return (
    <StyledGrid $hideTitle={hideTitle} className={className}>
      {!hideTitle && (
        <HeaderTitle
          title={title}
          details={details}
          titleComponent={titleComponent}
          titleVariant={titleVariant}
          showBackButton={showBackButton}
          onBackClick={onBackClickMethod}
        />
      )}
      {!hideBreadcrumbs && <Breadcrumbs />}
    </StyledGrid>
  );
};
