import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { styled } from '@mui/material';
import React, { FC, MouseEvent } from 'react';

import { CYAN_BLUE } from 'config/appColors';

export interface UserAvatarButtonProps {
  onClick: (ev: MouseEvent<HTMLButtonElement>) => void;
  testId?: string;
}

const StyledButtonWrapper = styled('button')`
  display: flex;
  align-items: center;
  border: 0;
  padding: 0;
  color: ${CYAN_BLUE};
  cursor: pointer;
  background: 0;
`;
const StyledIconWrapper = styled('span')`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px ${CYAN_BLUE} solid;
  border-radius: 50%;
`;
const StyledArrowDown = styled('span')`
  width: 0;
  height: 0;
  margin-left: 6px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid ${CYAN_BLUE};
`;

export const UserAvatarButton: FC<UserAvatarButtonProps> = ({
  onClick,
  testId,
}) => (
  <StyledButtonWrapper type="button" onClick={onClick} data-testid={testId}>
    <StyledIconWrapper>
      <PersonOutlineOutlinedIcon fontSize="large" />
    </StyledIconWrapper>
    <StyledArrowDown />
  </StyledButtonWrapper>
);

export default UserAvatarButton;
