/* eslint-disable react/destructuring-assignment */
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import React, { Key, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'components/atoms/Checkbox';
import { ErrorBoundary } from 'components/molecules/ErrorBoundary';
import { TableSkeleton } from 'components/molecules/TableSkeleton';
import { NO_PERMISSION_ERROR } from 'config/constants';
import { Nullable, Only, LoaderStatusEnum as StatusEnum } from 'types';
import { generateId } from 'utils/generateId';

import {
  StyledArrowUpwardIcon,
  StyledErrorState,
  StyledMuiCell,
  StyledMuiHeaderCell,
  StyledMuiHeaderCellContent,
  StyledMuiTableContainer,
  StyledMuiTableRow,
} from './Table.styled';
import { TablePagination } from './TablePagination';
import { Column, SortDirectionEnum, SortStateType, TableProps } from './types';
import { EmptyState } from '../../molecules/EmptyState';

export const getNewDirection = (
  sortKey: Nullable<string>,
  sortState?: Nullable<SortStateType>
): Nullable<SortDirectionEnum> => {
  if (!sortKey) return null;

  if (
    !sortState ||
    sortState?.key !== sortKey ||
    sortState?.direction === SortDirectionEnum.DESC
  ) {
    return SortDirectionEnum.ASC;
  }

  return SortDirectionEnum.DESC;
};

interface RenderCellsProps<TableRow> {
  row: TableRow;
  columns: Column<TableRow>[];
  forwardKey: Key;
  propertyAsAKey?: Only<TableRow, string>;
}

function RenderCells<TableRow>(props: RenderCellsProps<TableRow>): JSX.Element {
  const { columns, row, forwardKey } = props;

  return (
    <>
      {columns.map((column, index) => {
        const cellId = generateId(`${forwardKey}`, index);

        return (
          <StyledMuiCell
            key={cellId}
            title={column.title && column.title(row)}
            {...column.cellProps}
          >
            {
              ('customCell' in column
                ? column.customCell(row, cellId) || ''
                : row[column.field]) as ReactNode
            }
          </StyledMuiCell>
        );
      })}
    </>
  );
}

/**
 * NOTE ON USAGE WITH MUI "styled"
 * There is an issue with MUI styled usage with generic components.
 * See https://stackoverflow.com/a/72163115 for more details.
 * Solution: Explicitly typecast styled component as below -
 * const StyledTable = styled(Table)`` as typeof Table;
 */
export function Table<TableRow>(props: TableProps<TableRow>): JSX.Element {
  const [t] = useTranslation('components');

  const {
    rows,
    columns,
    rowProps,
    maxHeight,
    status,
    error,
    emptyStateMsg,
    className,
    hideHeader = false,
    stickyHeader = false,
    dense = false,
    onRowClick,
    onCheckboxChange,
    onHeaderCheckboxChange,
    pagination,
    sortState,
    errorHeadingText = t<string, string>('Failed to load Data.'),
  } = props;

  const showLoadingState = status === StatusEnum.LOADING;
  const showErrorState = status === StatusEnum.ERROR;
  const showEmptyState = status === StatusEnum.SUCCESS && rows.length === 0;
  const showResult = status === StatusEnum.SUCCESS && rows.length > 0;
  const showPagination = pagination !== undefined;
  const changeBackgroundOnHover = !!onRowClick || !!onCheckboxChange;

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const getIsChecked = (record: Record<string, unknown>): boolean =>
    'checked' in record ? !!record.checked : false;

  useEffect(() => {
    if (onCheckboxChange) {
      const isHeaderCheckboxChecked = (
        [...rows] as Record<string, unknown>[]
      ).every((row) => getIsChecked(row));
      setIsChecked(isHeaderCheckboxChecked);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [rows]);

  const renderHeaderCells = () =>
    columns.map(
      ({ headerName, headerProps, onSortClick, sortKey = '' }, index) => (
        <StyledMuiHeaderCell
          onClick={() => {
            if (onSortClick) {
              onSortClick({
                key: sortKey,
                direction: getNewDirection(sortKey, sortState),
              });
            }
          }}
          $isSortable={!!onSortClick}
          key={generateId(`header-cell-${headerName}`, index)}
          {...headerProps}
        >
          <StyledMuiHeaderCellContent>
            <span>{headerName}</span>
            {sortState?.key === sortKey && (
              <StyledArrowUpwardIcon $direction={sortState?.direction} />
            )}
          </StyledMuiHeaderCellContent>
        </StyledMuiHeaderCell>
      )
    );

  const rowsToDisplay = rows.map((row, index) => (
    <StyledMuiTableRow
      $isLastColumn={rows.length - 1 === index}
      $cursorPointer={!!onRowClick}
      hover={changeBackgroundOnHover}
      key={
        row['propertyAsAKey' in props ? props.propertyAsAKey : 'uuid'] as Key
      }
      onClick={(event) => onRowClick && onRowClick(event, row)}
      {...rowProps}
    >
      {onCheckboxChange && (
        <StyledMuiCell
          padding="checkbox"
          align="center"
          className="table-checkbox"
          onClick={(e) => e.stopPropagation()}
        >
          <Checkbox
            className="checkbox"
            value={
              row[
                'propertyAsAKey' in props ? props.propertyAsAKey : 'uuid'
              ] as string
            }
            checked={getIsChecked(row)}
            onChange={onCheckboxChange}
          />
        </StyledMuiCell>
      )}
      <RenderCells
        columns={columns}
        row={row}
        forwardKey={
          row['propertyAsAKey' in props ? props.propertyAsAKey : 'uuid'] as Key
        }
      />
    </StyledMuiTableRow>
  ));

  return (
    <StyledMuiTableContainer $maxHeight={maxHeight} className={className}>
      <ErrorBoundary staticPosition>
        <MuiTable
          size={dense ? 'small' : 'medium'}
          stickyHeader={stickyHeader}
          aria-label={stickyHeader ? 'sticky table' : undefined}
        >
          {!hideHeader && (
            <MuiTableHead>
              <MuiTableRow>
                {onCheckboxChange && (
                  <StyledMuiHeaderCell
                    $isSortable={false}
                    padding="checkbox"
                    align="center"
                    className="table-checkbox"
                  >
                    {onHeaderCheckboxChange && (
                      <Checkbox
                        value="all"
                        checked={isChecked}
                        onChange={(event) => {
                          setIsChecked((prev) => !prev);
                          onHeaderCheckboxChange(event);
                        }}
                      />
                    )}
                  </StyledMuiHeaderCell>
                )}
                {renderHeaderCells()}
              </MuiTableRow>
            </MuiTableHead>
          )}

          <MuiTableBody>{showResult && rowsToDisplay}</MuiTableBody>

          {showResult && showPagination && <TablePagination {...pagination} />}
        </MuiTable>

        {showLoadingState && (
          <TableSkeleton showPaginationSkeleton={showPagination} />
        )}

        {showErrorState &&
          (error?.message === NO_PERMISSION_ERROR ? (
            <StyledErrorState
              heading={t(NO_PERMISSION_ERROR)}
              description=" "
            />
          ) : (
            <StyledErrorState
              heading={errorHeadingText}
              description={error?.message}
              errorCode={error?.details}
              dense={dense}
            />
          ))}

        {showEmptyState && <EmptyState heading={emptyStateMsg} dense={dense} />}
      </ErrorBoundary>
    </StyledMuiTableContainer>
  );
}
