import { styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BaseModal from 'components/atoms/BaseModal';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import {
  resetCertificationBodyValue,
  selectCertificationBodyValue,
} from 'domains/certificationBody/state/search/slice';
import { CertificationBody, Nullable } from 'types';

import { SelectCertificationBodyModalContent } from '../SelectCertificationBodyModalContent';

interface SelectCertificationBodyModalProps {
  open: boolean;
  handleClose: () => void;
  nextAction: (certificationBody: CertificationBody) => void;
}

const StyledBaseModal = styled(BaseModal)`
  .MuiDialog-container {
    height: auto;
    min-height: 100%;
  }

  .MuiDialog-paper {
    width: 600px;
    max-width: 600px;
    min-height: 400px;
    overflow-y: visible;
  }

  .MuiDialogContent-root {
    padding: 0;
    overflow-y: visible;
  }
`;

export const SelectCertificationBodyModal: FC<SelectCertificationBodyModalProps> =
  (props) => {
    const { open, handleClose, nextAction } = props;
    const [t] = useTranslation('certificationBody');
    const dispatch = useDispatch();
    const certificationBodyInRedux = useSelector(selectCertificationBodyValue);
    const [certificationBody, setCertificationBody] =
      useState<Nullable<CertificationBody>>(null);
    const { location, replace } = useHistory();

    const handleCloseAndClear = () => {
      handleClose();

      if (location.state === 'CB_REQUIRED') {
        replace({ state: null });
      }
    };

    const closeModalAndHandleNextAction = () => {
      if (certificationBody) {
        handleCloseAndClear();
        nextAction(certificationBody);
      }
    };

    useEffect(() => {
      dispatch(resetCertificationBodyValue());
    }, [dispatch]);

    useEffect(() => {
      // reset state when modal is opened
      if (open) {
        setCertificationBody(null);

        if (certificationBodyInRedux) {
          dispatch(resetCertificationBodyValue());
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
      <StyledBaseModal open={open}>
        <TemplateAuditModal
          title={t('Select Certification Body')}
          contentComponent={
            <SelectCertificationBodyModalContent
              setCertificationBody={setCertificationBody}
            />
          }
          closeAction={handleCloseAndClear}
          nextAction={closeModalAndHandleNextAction}
          nextLabel={t('Next', { ns: 'components' })}
          noNextArrow
          nextColor="info"
          disableNext={!certificationBody}
        />
      </StyledBaseModal>
    );
  };
