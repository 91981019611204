import { Logo, TabChildrenComponent, TranslatedText } from 'types';

import { CertificateActionsEnum } from './domain';

export enum CertificateDetailsTabEnum {
  CERTIFICATE = 'certificate-tab-details',
  GROUP_CERTIFICATE = 'certificate-tab-group-certificate',
  LOCK_HISTORY = 'certificate-tab-lock-history',
  LOGS = 'certificate-tab-logs',
  ACCESSES = 'certificate-tab-iam',
}

export interface CertificateDetailsTab {
  type: 'tab';
  props: {
    id: CertificateDetailsTabEnum.CERTIFICATE;
    name: TranslatedText;
    headerTextDetails: TranslatedText;
    headerTextDocuments: TranslatedText;
    logoResolver?: Logo[];
  };
  children: TabChildrenComponent[];
}

export const isCertificateTab = (tab: unknown): tab is CertificateDetailsTab =>
  !!tab &&
  (tab as CertificateDetailsTab).props.id ===
    CertificateDetailsTabEnum.CERTIFICATE;

export interface CertificateGroupCertificateTab {
  type: 'tab';
  props: {
    id: CertificateDetailsTabEnum.GROUP_CERTIFICATE;
    name: TranslatedText;
    headerTextSuppliers: TranslatedText;
    headerTextAudits: TranslatedText;
  };
  children: TabChildrenComponent[];
}

export const isGroupCertificateTab = (
  tab: unknown
): tab is CertificateGroupCertificateTab =>
  !!tab &&
  (tab as CertificateGroupCertificateTab).props.id ===
    CertificateDetailsTabEnum.GROUP_CERTIFICATE;

export interface CertificateLogsTab {
  type: 'tab';
  props: {
    id: CertificateDetailsTabEnum.LOGS;
    name: TranslatedText;
    headerText: TranslatedText;
    requiredAction: CertificateActionsEnum;
  };
  children: null;
}

export const isLogsTab = (tab: unknown): tab is CertificateLogsTab =>
  !!tab &&
  (tab as CertificateLogsTab).props.id === CertificateDetailsTabEnum.LOGS;

export interface CertificateLockHistoryTab {
  type: 'tab';
  props: {
    id: CertificateDetailsTabEnum.LOCK_HISTORY;
    name: TranslatedText;
    headerText: TranslatedText;
    requiredAction: CertificateActionsEnum;
  };
  children: null;
}

export const isLockHistoryTab = (
  tab: unknown
): tab is CertificateLockHistoryTab =>
  !!tab &&
  (tab as CertificateLockHistoryTab).props.id ===
    CertificateDetailsTabEnum.LOCK_HISTORY;

export interface CertificateAccessesTab {
  type: 'tab';
  props: {
    id: CertificateDetailsTabEnum.ACCESSES;
    name: TranslatedText;
    headerText: TranslatedText;
    requiredAction: CertificateActionsEnum;
  };
  children: null;
}

export const isAccessesTab = (tab: unknown): tab is CertificateAccessesTab =>
  !!tab &&
  (tab as CertificateAccessesTab).props.id ===
    CertificateDetailsTabEnum.ACCESSES;

export type CertificateDetailsTabType =
  | CertificateDetailsTab
  | CertificateGroupCertificateTab
  | CertificateLogsTab
  | CertificateLockHistoryTab;

export interface CertificateDetailsTemplate {
  type: string;
  props: {
    pageHeader: TranslatedText;
  };
  children: CertificateDetailsTabType[];
}
