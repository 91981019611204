import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useModalCircumstanceRequiredFields } from '../../../hooks/useModalCircumstanceRequiredFields';
import { useUpdateExceptionalCircumstanceMutation } from '../../../state/exceptionalCircumstance/api';
import { selectCertificateDetailsUpdateUuid } from '../../../state/modal/slice';
import { CertificateModalCommonProps } from '../../../types';
import {
  CertificateDetailsModalBase,
  NextAction,
} from '../../templates/CertificateDetailsModalBase';
import { ExceptionalCircumstanceModalForm } from '../ExceptionalCircumstanceModalForm';

type UpdateExceptionalCircumstanceModalProps = CertificateModalCommonProps;

export const UpdateExceptionalCircumstanceModal: FC<UpdateExceptionalCircumstanceModalProps> =
  (props) => {
    const { certificateUuid } = props;
    const [t] = useTranslation('certificateDetails');
    const { updateExceptionalCircumstance, isLoading, error } =
      useUpdateExceptionalCircumstanceMutation();
    const caseUuid = useSelector(selectCertificateDetailsUpdateUuid) || '';
    const { fieldValues, isFormValid } = useModalCircumstanceRequiredFields();

    const nextAction: NextAction = (openSnackbar, closeModal) => {
      updateExceptionalCircumstance({
        certificateUuid,
        caseUuid,
        ...fieldValues,
      }).then(() => {
        openSnackbar(t('The exceptional circumstance is updated.'), 'success');
        closeModal();
      });
    };

    return (
      <CertificateDetailsModalBase
        title={t('Update Exceptional Circumstance')}
        nextColor="primary"
        nextAction={nextAction}
        nextLabel={t('Update')}
        disableNext={!isFormValid}
        isLoading={isLoading}
      >
        <ExceptionalCircumstanceModalForm errors={error} isUpdateForm />
      </CertificateDetailsModalBase>
    );
  };
