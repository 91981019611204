/**
 * PLAIN_DATE_REGEX_WITH_HYPHENS - regex to extract YYYY-MM-DD format
 */

export const PLAIN_DATE_REGEX_WITH_HYPHENS = /^\d{4}-\d{2}-\d{2}/;

/**
 * PLAIN_DATE_REGEX_WITH_DOTS - regex to extract DD.MM.YYYY format
 */

export const PLAIN_DATE_REGEX_WITH_DOTS =
  /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/;
