import { debounce, DebouncedFunc } from 'lodash';
import { DependencyList, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMemoizedDebounce = <T extends (...args: any[]) => unknown>(
  callbackFn: T,
  wait = 500,
  deps: DependencyList = []
  // eslint-disable-next-line react-hooks/exhaustive-deps
): DebouncedFunc<T> => useCallback(debounce(callbackFn, wait), deps);
