import React, { FC } from 'react';

import { AccessList } from 'domains/accessManagement/components/organisms/AccessList';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { CertificateAccessesTab } from '../../../types';
import { StyledHeading } from '../../atoms/StyledHeading';

type CertificateAccessesTabProps = CertificateAccessesTab['props'] & {
  certificateUuid: string;
};

export const AccessesTab: FC<CertificateAccessesTabProps> = ({
  certificateUuid,
  headerText,
}) => (
  <>
    <StyledHeading variant="h3">{getTranslatedValue(headerText)}</StyledHeading>
    <AccessList resourceName="certificates" entityUuid={certificateUuid} />
  </>
);
