import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import { AlertVariantEnum } from 'types';

import { selectCertificateDetailsSupplierToRemove } from '../../../state/modal/slice';
import { useRemoveSupplierMutation } from '../../../state/supplierManage/api';
import { CertificateModalCommonProps } from '../../../types';
import {
  CertificateDetailsModalBase,
  NextAction,
} from '../../templates/CertificateDetailsModalBase';

type RemoveSupplierModalProps = CertificateModalCommonProps;

export const RemoveSupplierModal: FC<RemoveSupplierModalProps> = ({
  certificateUuid,
}) => {
  const [t] = useTranslation('certificateDetails');

  const { removeSupplier, isLoading, error } = useRemoveSupplierMutation();

  const supplierToRemove = useSelector(
    selectCertificateDetailsSupplierToRemove
  );

  const handleRemoveClick: NextAction = (openSnackbar, closeModal) => {
    if (supplierToRemove) {
      removeSupplier({
        certificateUuid,
        supplierUuid: supplierToRemove.uuid,
      }).then(() => {
        openSnackbar(t('Supplier is removed.'), 'success');
        closeModal();
      });
    }
  };

  return (
    <CertificateDetailsModalBase
      title={t('Remove Supplier')}
      nextColor="error"
      prevLabel={t('Cancel', { ns: 'components' })}
      nextLabel={t('Remove')}
      nextAction={handleRemoveClick}
      isLoading={isLoading}
    >
      <StyledBaseModalContent
        alertsState={{ alerts: error || [], variant: AlertVariantEnum.ERROR }}
      >
        <p>
          {t(
            'Do you want to remove the following Supplier from the group certificate?'
          )}
        </p>
        {supplierToRemove && (
          <p>
            {supplierToRemove.name} (COID: {supplierToRemove.coid})
          </p>
        )}
      </StyledBaseModalContent>
    </CertificateDetailsModalBase>
  );
};
