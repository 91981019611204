import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { LoaderStatusEnum } from 'types';

import { ConditionalModalProps } from './types';
import { getAuditAppointment } from '../../../state/details/slice';
import {
  resetDueDateState,
  selectDueDateLoading,
  selectDueDateModalOpen,
  setDueDateModalOpen,
} from '../../../state/dueDate/slice';
import { SetDueDateModal } from '../SetDueDateModal';

export const ConditionalSetDueDateModal: FC<ConditionalModalProps> = ({
  appointmentId,
}) => {
  const [t] = useTranslation('auditAppointment');
  const notificationMessage = t('The due date is set.');
  const { openSnackbar } = useSnackbarNotification();
  const dispatch = useDispatch();
  const open = useSelector(selectDueDateModalOpen);
  const loading = useSelector(selectDueDateLoading);

  const closeModal = () => {
    dispatch(setDueDateModalOpen(false));
    dispatch(resetDueDateState());
  };

  useEffect(() => {
    if (loading === LoaderStatusEnum.SUCCESS && open) {
      dispatch(resetDueDateState()); // reset state to IDLE
      dispatch(getAuditAppointment(appointmentId)); // refetch audit appointment details if success
      openSnackbar(notificationMessage, 'success');
    }
  }, [
    loading,
    appointmentId,
    open,
    dispatch,
    openSnackbar,
    notificationMessage,
  ]);

  if (!open) return null;

  return (
    <SetDueDateModal
      open={open}
      handleClose={closeModal}
      appointmentId={appointmentId}
    />
  );
};
