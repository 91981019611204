import { useSelector } from 'react-redux';

import { MISSING_PARAMS_ERROR } from 'config/constants';
import { RequestModeEnum } from 'domains/auditDraft/types';
import { selectCertificationBodyValue } from 'domains/certificationBody/state/search/slice';
import { selectUserCompanyId } from 'domains/user/state/auth/slice';
import {
  RTKFunctionPartialResult as FormattedErrorMutation,
  useFormattedError,
} from 'hooks/useFormattedError';
import {
  RTKFunctionPartialResult as QueryResetMutation,
  useRTKQueryReset,
} from 'hooks/useRTKQueryReset';
import { PrimitiveStatuses, useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { LoaderStatusEnum } from 'types';

import {
  CreateAuditAmendmentViaFileParams,
  CreateAuditDraftViaFileParams,
  GetAuditFileModulesParams,
  GetAuditFileModulesResponse,
  UseCreateAuditAmendmentViaFileParams,
  UseCreateAuditDraftViaFileParams,
  UseSendFileAndGetModulesMutationParams,
} from './types';
import endpoints from '../../config/endpoints';

const auditFileUploadApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    sendFileAndGetModules: build.mutation<
      GetAuditFileModulesResponse,
      GetAuditFileModulesParams
    >({
      query(data) {
        return {
          url: endpoints.REGULAR_UPLOAD.MODULES_LIST,
          method: 'POST',
          data,
        };
      },
    }),
    createAuditDraftViaFile: build.mutation<
      string,
      CreateAuditDraftViaFileParams | CreateAuditAmendmentViaFileParams
    >({
      query(data) {
        return {
          url: endpoints.CREATE_IFS,
          method: 'POST',
          data,
        };
      },
    }),
    createAuditDraftViaAxpxFile: build.mutation<
      string,
      | Omit<CreateAuditDraftViaFileParams, 'moduleId'>
      | CreateAuditAmendmentViaFileParams
    >({
      query(data) {
        return {
          url: endpoints.CREATE_AXPX,
          method: 'POST',
          data,
        };
      },
    }),
  }),
});

export const useSendFileAndGetModulesMutation = () => {
  const [sendFileAndGetModules, mutation] =
    auditFileUploadApi.useSendFileAndGetModulesMutation({
      fixedCacheKey: 'send-ifs-file-and-fetch-modules',
    });
  const userCertificationBodyId = useSelector(selectUserCompanyId);
  const customCertificationBody = useSelector(selectCertificationBodyValue);
  const certificationBodyId =
    customCertificationBody?.uuid || userCertificationBodyId;
  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);
  const resetModules = mutation.reset;
  useRTKQueryReset(mutation, { onUnmount: true });

  if (!certificationBodyId) {
    return {
      ...mutation,
      sendFileAndGetModules: () => {},
      modules: [],
      status: LoaderStatusEnum.ERROR,
      error: [MISSING_PARAMS_ERROR],
      hints: [],
      resetModules,
    };
  }

  const requestMethod = async (
    params: UseSendFileAndGetModulesMutationParams
  ) => {
    const { supplierId, auditFile } = params;
    const body = {
      auditFile,
      certificationBodyId,
      supplierId,
    };

    return sendFileAndGetModules(body);
  };

  return {
    ...mutation,
    sendFileAndGetModules: requestMethod,
    modules: mutation.data?.modules || [],
    status,
    error,
    hints: mutation.data?.hints || [],
    resetModules,
  };
};

type FinalPartialMutation = FormattedErrorMutation &
  QueryResetMutation &
  PrimitiveStatuses;

const useCreateAuditDraftShared = (
  isAmendment: boolean,
  mutation: FinalPartialMutation
) => {
  const requestMode = isAmendment
    ? RequestModeEnum.UPDATE
    : RequestModeEnum.CREATE;
  const userCertificationBodyId = useSelector(selectUserCompanyId);
  const customCertificationBody = useSelector(selectCertificationBodyValue);
  const selectedCertificationBodyId =
    customCertificationBody?.uuid || userCertificationBodyId;

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);
  const isLoading = status === LoaderStatusEnum.LOADING;
  const resetCreateAuditDraftState = mutation.reset;

  useRTKQueryReset(mutation, { onUnmount: true });

  return {
    requestMode,
    selectedCertificationBodyId,
    error,
    status,
    isLoading,
    resetCreateAuditDraftState,
  };
};

export const useCreateAuditDraftViaFileMutation = (isAmendment = false) => {
  const [createAuditDraftViaFile, mutation] =
    auditFileUploadApi.useCreateAuditDraftViaFileMutation({
      fixedCacheKey: 'create-audit-draft-via-file',
    });

  const {
    error,
    isLoading,
    status,
    requestMode,
    resetCreateAuditDraftState,
    selectedCertificationBodyId,
  } = useCreateAuditDraftShared(isAmendment, mutation);

  const requestMethod = async (
    params:
      | UseCreateAuditDraftViaFileParams
      | UseCreateAuditAmendmentViaFileParams
  ) => {
    const {
      supplierId,
      auditFile,
      certificationBodyId,
      isGroupCertificationAudit,
    } = params;
    const finalCertificationBodyId = isAmendment
      ? certificationBodyId
      : selectedCertificationBodyId;

    const idKey = isAmendment ? 'targetAuditId' : 'moduleId';
    const body = {
      auditFile,
      supplierId,
      mode: requestMode,
      ...(finalCertificationBodyId && {
        certificationBodyId: finalCertificationBodyId,
      }),
      ...(isGroupCertificationAudit && { isGroupCertificationAudit }),
      [idKey]: params[idKey as keyof typeof params],
    } as CreateAuditDraftViaFileParams | CreateAuditAmendmentViaFileParams;

    return createAuditDraftViaFile(body).unwrap();
  };

  return {
    ...mutation,
    createAuditDraftViaFile: requestMethod,
    status,
    isLoading,
    error,
    resetCreateAuditDraftState,
  };
};

export const useCreateAuditDraftViaAXPXFileMutation = (isAmendment = false) => {
  const [createAuditDraftViaAxpxFile, mutation] =
    auditFileUploadApi.useCreateAuditDraftViaAxpxFileMutation({
      fixedCacheKey: 'create-audit-draft-via-axpx-file',
    });

  const {
    error,
    isLoading,
    status,
    requestMode,
    resetCreateAuditDraftState,
    selectedCertificationBodyId,
  } = useCreateAuditDraftShared(isAmendment, mutation);

  const requestMethod = async (
    params:
      | Omit<UseCreateAuditDraftViaFileParams, 'moduleId'>
      | UseCreateAuditAmendmentViaFileParams
  ) => {
    const {
      supplierId,
      auditFile,
      certificationBodyId,
      isGroupCertificationAudit,
    } = params;
    const finalCertificationBodyId = isAmendment
      ? certificationBodyId
      : selectedCertificationBodyId;

    const body = {
      auditFile,
      supplierId,
      mode: requestMode,
      ...(finalCertificationBodyId && {
        certificationBodyId: finalCertificationBodyId,
      }),
      ...(isGroupCertificationAudit && { isGroupCertificationAudit }),
      ...(isAmendment && {
        targetAuditId: (params as UseCreateAuditAmendmentViaFileParams)
          .targetAuditId,
      }),
    } as CreateAuditDraftViaFileParams | CreateAuditAmendmentViaFileParams;

    return createAuditDraftViaAxpxFile(body).unwrap();
  };

  return {
    ...mutation,
    createAuditDraftViaAxpxFile: requestMethod,
    status,
    isLoading,
    error,
    resetCreateAuditDraftState,
  };
};
