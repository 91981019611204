import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { WithUndefined } from 'types';

import { parentReducerName } from '../../config/constants';
import { CertificateDraftStepEnum } from '../../types';

interface CertificateDraftStepperState {
  currentStep: WithUndefined<CertificateDraftStepEnum>;
}

const initialState: CertificateDraftStepperState = {
  currentStep: undefined,
};

const reducerName = `${parentReducerName}/certificateDraftStepper`;

// slice
export const certificateDraftStepperSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    resetStep: () => initialState,
  },
});

// actions
export const { setCurrentStep, resetStep } =
  certificateDraftStepperSlice.actions;

// selectors
export const selectCertificateDraftCurrentStep = (
  state: RootState
): WithUndefined<CertificateDraftStepEnum> =>
  state[parentReducerName].stepper.currentStep;

// reducer
export default certificateDraftStepperSlice.reducer;
