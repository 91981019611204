import i18next from 'i18n';

import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';

const accessManagementNs = 'accessManagement';

i18next.addResources('en', accessManagementNs, en);
i18next.addResources('de', accessManagementNs, de);
i18next.addResources('fr', accessManagementNs, fr);
i18next.addResources('es', accessManagementNs, es);
i18next.addResources('it', accessManagementNs, it);

export { accessManagementNs };
