import { styled } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import React, { FC, ReactNode } from 'react';

import { TEXT } from 'config/appColors';

const StyledModalContent = styled(DialogContent)`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${TEXT.PRIMARY};
  padding: ${(props: { $zeroPaddingContent?: boolean }) =>
    props.$zeroPaddingContent ? 0 : undefined};
`;

interface AuditModalContentProps {
  zeroPaddingContent?: boolean;
  children?: ReactNode;
}

export const AuditModalContent: FC<AuditModalContentProps> = (props) => {
  const { children, zeroPaddingContent } = props;

  return (
    <StyledModalContent $zeroPaddingContent={zeroPaddingContent}>
      {children}
    </StyledModalContent>
  );
};

export default AuditModalContent;
