import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCertificateDetailsModalFormValues,
  setActionCommentValue,
  setStringFormValue,
} from '../state/modal/slice';
import {
  ActionCommentPayload,
  CertificateDetailsModalState,
  StringFormValuePayload,
} from '../state/modal/types';

type UseModalForm = CertificateDetailsModalState['commonFormValues'] & {
  setFormValue: (name: StringFormValuePayload['name'], value: string) => void;
  setActionInfoValue: (
    name: ActionCommentPayload['name'],
    value: string | boolean
  ) => void;
};

export const useModalForm = (): UseModalForm => {
  const dispatch = useDispatch();

  const setFormValue = useCallback(
    (name: StringFormValuePayload['name'], value: string) => {
      dispatch(setStringFormValue({ name, value }));
    },
    [dispatch]
  );
  const setActionInfoValue = (
    name: ActionCommentPayload['name'],
    value: string | boolean
  ) => {
    dispatch(setActionCommentValue({ name, value }));
  };

  const certificateDetailsModalFormValues = useSelector(
    selectCertificateDetailsModalFormValues
  );

  return {
    ...certificateDetailsModalFormValues,
    setActionInfoValue,
    setFormValue,
  };
};
