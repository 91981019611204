import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Heading } from 'components/atoms/Heading';
import { BLACK } from 'config/appColors';
import { LoaderStatusEnum } from 'types';

import { resetSupplierDetailsState } from '../../../../supplier/state/details/slice';
import {
  resetAuthLoaderState,
  selectAuthError,
  selectAuthLoading,
} from '../../../state/auth/slice';
import { FormLogin } from '../../organisms/FormLogin';
import { LoginResponseStatus } from '../../organisms/LoginResponseStatus';

const StyledPageWrapper = styled('div')`
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
`;
const StyledModal = styled('div')`
  width: 532px;
  margin: 0 auto;
  color: ${BLACK};
`;
const StyledHeading = styled(Heading)`
  margin-bottom: 30px;
  font-weight: 500;
  color: ${BLACK};
`;

export const PageLogin: FC = () => {
  const [t] = useTranslation('user');
  const dispatch = useDispatch();
  const error = useSelector(selectAuthError);
  const loading = useSelector(selectAuthLoading);
  const closeAction = () => dispatch(resetAuthLoaderState());

  useEffect(() => {
    // for avoid case eg. after refresh
    dispatch(resetAuthLoaderState());
    dispatch(resetSupplierDetailsState());
  }, [dispatch]);

  return (
    <StyledPageWrapper>
      {loading === LoaderStatusEnum.IDLE ? (
        <StyledModal>
          <StyledHeading variant="h2">{t('Login')}</StyledHeading>
          <FormLogin />
        </StyledModal>
      ) : (
        <LoginResponseStatus
          status={loading}
          closeAction={closeAction}
          responseMessage={error ?? ''}
          title={loading === LoaderStatusEnum.ERROR ? 'Login failed' : ''}
        />
      )}
    </StyledPageWrapper>
  );
};

export default PageLogin;
