import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { LogsParams, LogsResponse } from 'types';

import endpoints from '../../config/endpoints';

const auditAppointmentLogsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditAppointmentLogs: build.query<LogsResponse, LogsParams>({
      query: ({ uuid, params }) => ({
        url: endpoints.DETAILS_LOGS(uuid),
        params,
      }),
      providesTags: ['AUDIT_APPOINTMENT_DETAILS_LOGS'],
    }),
  }),
});

export const useGetAuditAppointmentLogsQuery = (params: LogsParams) => {
  const query = auditAppointmentLogsApi.useGetAuditAppointmentLogsQuery(params);
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  return {
    ...query,
    logs: query.data?.logs || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};
