import { SelectChangeEvent } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseModalContent } from 'components/organisms/BaseModalContent';
import {
  selectAdminActionComment,
  selectDueDateAlertsState,
  setAdminActionComment,
} from 'domains/auditAppointment/state/dueDate/slice';
import { selectAlertsState as selectAuditPlanAlertsState } from 'domains/auditAppointment/state/formOptions/slice';

import { actionReasons } from '../../../config/constants';
import { SetDateRange } from '../SetDateRange';

interface SetDueDateModalContentProps {
  setIsAdminInfoValid: Dispatch<SetStateAction<boolean>>;
  isAdmin?: boolean;
}

export const SetDueDateModalContent: FC<SetDueDateModalContentProps> = (
  props
) => {
  const { setIsAdminInfoValid, isAdmin } = props;

  const dispatch = useDispatch();

  const selectedAuditPlanAlertsState = useSelector(selectAuditPlanAlertsState);
  const selectedDueDateAlertsState = useSelector(selectDueDateAlertsState);
  const selectedAdminActionComment = useSelector(selectAdminActionComment);

  const adminReason = selectedAdminActionComment?.reason;
  const adminComment = selectedAdminActionComment?.comment;

  const adminReasonChangeHandler = (event: SelectChangeEvent<unknown>) => {
    dispatch(
      setAdminActionComment({
        reason: event.target.value as string,
        comment: adminComment,
      })
    );
  };

  const adminCommentChangeHandler = (comment: string) => {
    dispatch(
      setAdminActionComment({
        reason: adminReason || '',
        comment,
      })
    );
  };

  return (
    <BaseModalContent
      alertsState={selectedDueDateAlertsState || selectedAuditPlanAlertsState}
      adminReasonOptions={actionReasons.SET_DUE_DATE.ADMIN}
      setIsAdminInfoValid={setIsAdminInfoValid}
      adminReason={adminReason}
      adminComment={adminComment}
      adminReasonChangeHandler={adminReasonChangeHandler}
      adminCommentChangeHandler={adminCommentChangeHandler}
      isAdmin={isAdmin}
    >
      <SetDateRange />
    </BaseModalContent>
  );
};
