import { Grid, styled } from '@mui/material';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { TFunction, withTranslation } from 'react-i18next';

import Button from 'components/atoms/Button';

import { ErrorState } from '../ErrorState';

interface ErrorBoundaryProps {
  children: ReactNode;
  t: TFunction;
  className?: string;
  staticPosition?: boolean;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

interface StyledGridContainerProps {
  $staticPosition: boolean;
}

const StyledErrorState = styled(ErrorState)`
  padding: 0;
`;

const StyledGrid = styled(Grid)`
  width: 80%;
  padding: 15px 0;
`;

const StyledGridContainer = styled(Grid)<StyledGridContainerProps>`
  position: ${({ $staticPosition }) =>
    $staticPosition ? 'static' : 'absolute'};
  top: 50%;
  left: 50%;
  transform: ${({ $staticPosition }) =>
    $staticPosition ? 'none' : 'translate(-50%, -50%)'};
`;

class ErrorBoundaryClass extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    // log error to console for customer support
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const {
      children,
      t,
      className,
      staticPosition = false,
      fallback,
    } = this.props;

    const fallbackComponent = fallback || (
      <StyledGridContainer
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={className}
        $staticPosition={staticPosition}
      >
        <StyledGrid item>
          <StyledErrorState
            heading={t('Unexpected Error')}
            description={t('An unexpected error occurred. Please try again.')}
          />
        </StyledGrid>
        <Grid item>
          <Button onClick={() => window.location.reload()} variant="contained">
            {t('Reload Page')}
          </Button>
        </Grid>
      </StyledGridContainer>
    );

    if (hasError) {
      return fallbackComponent;
    }

    return children;
  }
}

export const ErrorBoundary = withTranslation('components')(ErrorBoundaryClass);
