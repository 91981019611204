import React, { FC } from 'react';

import { Logger } from 'components/atoms/Logger';

import { useAuditDraftStepper } from '../../../hooks/useAuditDraftStepper';
import {
  AuditDraftStep,
  AuditDraftStepEnum,
  isFormAppointmentSelectionStep,
  isFormAuditorsStep,
  isFormCommentStep,
  isFormConfirmationSubmitStep,
  isFormDataConfirmationStep,
  isFormDocumentUploadStep,
  isFormReasonStep,
} from '../../../types';
import { FormAppointmentSelection } from '../FormAppointmentSelection';
import { FormAuditors } from '../FormAuditors';
import { FormComment } from '../FormComment';
import { FormConfirmationSubmit } from '../FormConfirmationSubmit';
import { FormDataConfirmation } from '../FormDataConfirmation';
import { FormDocumentUpload } from '../FormDocumentUpload';
import { FormReason } from '../FormReason';

const getFormAppointmentSelection = (
  auditDraftId: string,
  step?: AuditDraftStep
) =>
  isFormAppointmentSelectionStep(step) ? (
    <FormAppointmentSelection {...step.props} auditDraftId={auditDraftId} />
  ) : null;

const getFormAuditors = (auditDraftId: string, step?: AuditDraftStep) =>
  isFormAuditorsStep(step) ? (
    <FormAuditors {...step.props} auditDraftId={auditDraftId} />
  ) : null;

const getFormConfirmationSubmit = (
  auditDraftId: string,
  step?: AuditDraftStep
) =>
  isFormConfirmationSubmitStep(step) ? (
    <FormConfirmationSubmit {...step} auditDraftId={auditDraftId} />
  ) : null;

const getFormComment = (auditDraftId: string, step?: AuditDraftStep) =>
  isFormCommentStep(step) ? (
    <FormComment {...step.props} auditDraftId={auditDraftId} />
  ) : null;

const getFormDataConfirmation = (auditDraftId: string, step?: AuditDraftStep) =>
  isFormDataConfirmationStep(step) ? (
    <FormDataConfirmation {...step.props} auditDraftId={auditDraftId}>
      {step.children}
    </FormDataConfirmation>
  ) : null;

const getFormDocumentUpload = (auditDraftId: string, step?: AuditDraftStep) =>
  isFormDocumentUploadStep(step) ? (
    <FormDocumentUpload {...step.props} auditDraftId={auditDraftId} />
  ) : null;

const getFormReason = (auditDraftId: string, step?: AuditDraftStep) =>
  isFormReasonStep(step) ? (
    <FormReason {...step.props} auditDraftId={auditDraftId} />
  ) : null;

const AUDIT_DRAFT_STEPS = {
  [AuditDraftStepEnum.APPOINTMENT_SELECTION]: getFormAppointmentSelection,
  [AuditDraftStepEnum.AUDITORS]: getFormAuditors,
  [AuditDraftStepEnum.CONFIRMATION_SUBMIT]: getFormConfirmationSubmit,
  [AuditDraftStepEnum.COMMENT]: getFormComment,
  [AuditDraftStepEnum.DATA_CONFIRMATION]: getFormDataConfirmation,
  [AuditDraftStepEnum.DOCUMENT_UPLOAD]: getFormDocumentUpload,
  [AuditDraftStepEnum.REASON]: getFormReason,
};

interface MappedAuditDraftStepsProps {
  auditDraftId: string;
}

export const MappedAuditDraftSteps: FC<MappedAuditDraftStepsProps> = (
  props
) => {
  const { auditDraftId } = props;
  const { currentStep, currentStepConfig } = useAuditDraftStepper();

  if (!currentStep) {
    return null;
  }

  const stepComponent = AUDIT_DRAFT_STEPS[currentStep] ? (
    AUDIT_DRAFT_STEPS[currentStep](auditDraftId, currentStepConfig)
  ) : (
    <Logger
      message={`${currentStep} not supported step id, please check spelling`}
    />
  );

  return stepComponent;
};
