import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { StepperStepType } from 'components/atoms/Step';
import { WithUndefined } from 'types';
import { checkIfEnumValueExists } from 'utils/checkIfEnumValueExists';

import { useCertificateDraftTemplateQuery } from '../state/certificateDraftTemplate/api';
import {
  selectCertificateDraftCurrentStep,
  setCurrentStep,
} from '../state/certificateDraftTemplate/slice';
import { CertificateDraftTemplateResponse } from '../state/certificateDraftTemplate/types';
import {
  CertificateDraftStep,
  CertificateDraftStepEnum,
  CertificateDraftURLParams,
} from '../types';

const getIndexOfStep = (
  step: WithUndefined<CertificateDraftStepEnum>,
  steps: CertificateDraftStep[]
) => steps.findIndex((s) => s.props.id === step);

interface UseCertificateDraftStepper {
  certificateDraftTemplate: WithUndefined<CertificateDraftTemplateResponse>;
  steps: StepperStepType[];
  currentStep: WithUndefined<CertificateDraftStepEnum>;
  currentStepConfig: WithUndefined<CertificateDraftStep>;
  onStepClick: (step: StepperStepType) => void;
  setNextStep: () => void;
  setPrevStep: () => void;
}

export const useCertificateDraftStepper = (): UseCertificateDraftStepper => {
  const dispatch = useDispatch();
  const certificateDraftURLParams = useParams<CertificateDraftURLParams>();
  const currentStep = useSelector(selectCertificateDraftCurrentStep);
  const { certificateDraftTemplate } = useCertificateDraftTemplateQuery(
    certificateDraftURLParams
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const certificateDraftSteps = (
    certificateDraftTemplate?.template.children || []
  ).filter((step) => {
    const stepId = step?.props?.id;
    if (checkIfEnumValueExists(CertificateDraftStepEnum, stepId)) {
      return true;
    }

    console.warn(
      `${stepId} was removed from stepper (unsupported step id), please check spelling`
    );

    return false;
  });
  const indexOfCurrentStep = getIndexOfStep(currentStep, certificateDraftSteps);
  const currentStepConfig = certificateDraftSteps.find(
    ({ props }) => props.id === currentStep
  );

  useEffect(() => {
    if (!currentStep && certificateDraftSteps && certificateDraftSteps[0]) {
      // setting first step as an initial step in the process
      dispatch(setCurrentStep(certificateDraftSteps[0].props.id));
    }
  }, [certificateDraftSteps, currentStep, dispatch]);

  const steps: StepperStepType[] = certificateDraftSteps.map(
    ({ props: { id, title } }, index, stepsArr) => ({
      id,
      label: title.en,
      // when value is white space string stepper displays circle with background
      // without any text according to design otherwise it displays inactive circle
      value: index < getIndexOfStep(currentStep, stepsArr) ? ' ' : undefined,
    })
  );

  const onStepClick = (step: StepperStepType) => {
    dispatch(setCurrentStep(step.id));
  };

  const setNextStep = () => {
    if (certificateDraftSteps.length - 1 !== indexOfCurrentStep) {
      const nextStep = certificateDraftSteps[indexOfCurrentStep + 1];
      dispatch(setCurrentStep(nextStep.props.id));
    }
  };

  const setPrevStep = () => {
    if (indexOfCurrentStep !== 0) {
      const prevStep = certificateDraftSteps[indexOfCurrentStep - 1];
      dispatch(setCurrentStep(prevStep.props.id));
    }
  };

  return {
    certificateDraftTemplate,
    steps,
    currentStep,
    currentStepConfig,
    onStepClick,
    setNextStep,
    setPrevStep,
  };
};
