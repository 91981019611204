import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AttributeRowBase } from 'components/atoms/AttributeRow/AttributeRowBase';
import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import {
  NextAction,
  TemplateModalWithSnackbar,
} from 'components/templates/TemplateModalWithSnackbar';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';

import { useReleasesTemplate } from '../../../hooks/useReleasesTemplate';
import {
  closeAndResetAuditModal,
  selectSelectedRelease,
} from '../../../state/modal/slice';
import { AuditModalCommonProps } from '../../../state/modal/types';
import { useDeleteReleaseMutation } from '../../../state/releaseManage/api';
import { getReleaseContentToDisplay } from '../../../utils/getReleaseContentToDisplay';

const StyledDescription = styled('p')`
  margin: 0 0 20px 0;
  font-weight: 400;
`;

const StyledAttributeRow = styled(AttributeRowBase)`
  .AttributeRowBase-label {
    width: 220px;
  }
`;

type DeleteReleaseModalProps = AuditModalCommonProps;

export const DeleteReleaseModal: FC<DeleteReleaseModalProps> = ({
  auditUuid,
}) => {
  const [t] = useTranslation('auditDetails');
  const dispatch = useDispatch();
  const releaseToDelete = useSelector(selectSelectedRelease);
  const { translationsMap } = useReleasesTemplate(auditUuid);

  const { deleteRelease, isLoading, error = [] } = useDeleteReleaseMutation();
  const closeMethod = () => {
    dispatch(closeAndResetAuditModal());
  };
  const nextAction: NextAction = (openSnackbar, closeModal) => {
    if (releaseToDelete) {
      deleteRelease(releaseToDelete.uuid).then(() => {
        openSnackbar(t('Audit release deleted.'), 'success');
        closeModal();
      });
    }
  };

  const alertsState = useMemoizedAlerts(error);

  if (!releaseToDelete) {
    return null;
  }

  const { releasedForCompany, referencedAudit, timeFrame, moduleFamily } =
    releaseToDelete;

  return (
    <TemplateModalWithSnackbar
      title={`${t('Delete audit/assessment release')}: ${moduleFamily.name}`}
      nextAction={nextAction}
      nextLabel={t('Delete', { ns: 'components' })}
      prevLabel={t('Cancel', { ns: 'components' })}
      isLoading={isLoading}
      nextColor="error"
      closeMethod={closeMethod}
    >
      <StyledBaseModalContent alertsState={alertsState}>
        <StyledDescription>
          {t('Do you really want delete this audit release?')}
        </StyledDescription>

        <StyledAttributeRow
          label={t('Company')}
          value={`${releasedForCompany.name} (${releasedForCompany.coid})`}
        />
        <StyledAttributeRow
          label={t('Referenced Audit/Assessment')}
          value={referencedAudit.auid}
        />
        <StyledAttributeRow
          label={t('Audit content release')}
          value={getReleaseContentToDisplay(translationsMap)(releaseToDelete)}
        />
        <StyledAttributeRow
          label={t('Release time frame')}
          value={timeFrame.name}
        />
      </StyledBaseModalContent>
    </TemplateModalWithSnackbar>
  );
};
