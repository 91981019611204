import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { Nullable } from 'types';

import { AdminReportsModalEnum, AdminReportsModalState } from './types';
import { parentReducerName } from '../../config/constants';
import { AdminAudit } from '../../types';

const initialState: AdminReportsModalState = {
  openModalName: null,
  adminAudit: null,
};

const reducerName = `${parentReducerName}/modal`;

const adminReportsModalSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    openAdminReportsModal: (
      state,
      action: PayloadAction<AdminReportsModalEnum>
    ) => {
      state.openModalName = action.payload;
    },
    setAdminAudit: (state, action: PayloadAction<AdminAudit>) => {
      state.adminAudit = action.payload;
    },
    closeAndResetAdminReportsModal: () => initialState,
  },
});

// actions
export const {
  openAdminReportsModal,
  closeAndResetAdminReportsModal,
  setAdminAudit,
} = adminReportsModalSlice.actions;

// selectors
export const selectAdminReportsModalName = (
  state: RootState
): Nullable<AdminReportsModalEnum> =>
  state[parentReducerName].adminReportsModal.openModalName;

export const selectAdminAudit = (state: RootState): Nullable<AdminAudit> =>
  state[parentReducerName].adminReportsModal.adminAudit;

// reducer
export default adminReportsModalSlice.reducer;
