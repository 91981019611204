import { styled } from '@mui/material';
import { Variant as MuiVariant } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

import { BLACK } from 'config/appColors';

const StyledStepFormTitle = styled(Typography)`
  color: ${BLACK};
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 0.015rem;
  font-weight: 500;
`;

type StepFormTitleVariant = Extract<
  MuiVariant,
  'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
>;

interface StepFormTitleProps {
  content: string;
  className?: string;
  variant?: StepFormTitleVariant;
}

export const StepFormTitle: FC<StepFormTitleProps> = (props) => {
  const { content, className, variant = 'h3' } = props;

  return (
    <StyledStepFormTitle className={className} variant={variant}>
      {content}
    </StyledStepFormTitle>
  );
};
