import CloseIcon from '@mui/icons-material/Close';
import { IconButton, styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flag } from 'components/atoms/Flag';
import { DeleteFileModal } from 'components/organisms/DeleteFileModal';
import { Column, Table } from 'components/organisms/Table';
import { TEXT } from 'config/appColors';
import {
  AlertsState,
  Document,
  ExtendedError,
  LoaderStatusEnum,
  Nullable,
} from 'types';

interface DocumentTableProps {
  documents: Document[];
  documentsLoadingStatus: LoaderStatusEnum;
  documentsError?: Nullable<ExtendedError>;
  fileDeleteStatus?: LoaderStatusEnum;
  fileDeleteAlertsState?: Nullable<AlertsState>;
  onFileDelete?: (fileUUID: string) => Promise<void>;
  resetDeleteDocumentMutation: () => void;
}

const StyledTable = styled(Table)`
  table {
    tr {
      vertical-align: baseline;
    }
    td {
      vertical-align: middle;
    }
  }
` as typeof Table;

const StyledCloseButton = styled(IconButton)`
  color: ${TEXT.SECONDARY_DARK};
  padding: 0;

  > svg {
    font-size: 2rem;
  }
`;
const StyledDisplayName = styled('div')`
  margin-left: 5px;
  font-size: 1.2rem;
  line-height: 1.99rem;
`;

export const DocumentTable: FC<DocumentTableProps> = (props) => {
  const {
    documents,
    documentsLoadingStatus,
    documentsError,
    fileDeleteAlertsState,
    fileDeleteStatus,
    onFileDelete,
    resetDeleteDocumentMutation,
  } = props;
  const [t] = useTranslation('auditDraft');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteDocument, setDeleteDocument] = useState<Document>();
  const showDeleteFileButtonColumn =
    fileDeleteStatus && onFileDelete && fileDeleteAlertsState !== undefined;

  const deleteFileButtonColumn: Column<Document> = {
    customCell: (file) => (
      <StyledCloseButton
        data-testid="document-table-delete-button"
        onClick={() => {
          setDeleteDocument(file);
          setIsDeleteModalOpen(true);
        }}
      >
        <CloseIcon />
      </StyledCloseButton>
    ),
    cellProps: {
      align: 'right',
    },
  };
  const renderFileName = (fileName: string, displayName?: string) => (
    <>
      {displayName}
      {displayName ? (
        <StyledDisplayName>{fileName}</StyledDisplayName>
      ) : (
        fileName
      )}
    </>
  );

  const columns: Column<Document>[] = [
    {
      field: 'fileName',
      headerName: t('Filename'),
      cellProps: {
        sx: { width: '75%', overflowWrap: 'break-word' },
      },
      customCell: ({ fileName, displayName }) =>
        renderFileName(fileName, displayName),
    },
    {
      field: 'language',
      headerName: t('Language'),
      customCell: ({ language }) => <Flag country={language} />,
    },
    ...(showDeleteFileButtonColumn ? [deleteFileButtonColumn] : []),
  ];

  return (
    <>
      <StyledTable
        rows={documents}
        columns={columns}
        status={documentsLoadingStatus}
        error={documentsError}
        emptyStateMsg={t('No File uploaded')}
        hideHeader
        dense
      />
      {showDeleteFileButtonColumn && deleteDocument && (
        <DeleteFileModal
          open={isDeleteModalOpen}
          fileName={deleteDocument.fileName}
          fileUUID={deleteDocument.uuid}
          fileType={deleteDocument.type?.name}
          fileDeleteStatus={fileDeleteStatus}
          fileDeleteAlertsState={fileDeleteAlertsState}
          handleClose={() => {
            setIsDeleteModalOpen(false);
            // without reset can't open 2nd time (for success) and shows issue of previous file (for error)
            resetDeleteDocumentMutation();
          }}
          onDelete={onFileDelete}
        />
      )}
    </>
  );
};
