import { combineReducers } from 'redux';

import addBlackoutTimeReducer from './addBlackoutTime/slice';
import blackoutTimesReducer from './blackoutTimes/slice';
import cancelReducer from './cancel/slice';
import createReducer from './create/slice';
import deleteBlackoutTimeReducer from './deleteBlackoutTime/slice';
import detailsReducer from './details/slice';
import diaryAuditAppointmentsReducer from './diaryAuditAppointments/slice';
import diaryAuditRegistrationsReducer from './diaryAuditRegistrations/slice';
import diaryMissingEntriesReducer from './diaryMissingEntries/slice';
import dueDateReducer from './dueDate/slice';
import formOptionsReducer from './formOptions/slice';
import reactivateReducer from './reactivate/slice';
import stepperModalReducer from './stepperModal/slice';
import updateDescriptionReducer from './updateDescription/slice';

export const auditAppointmentReducer = combineReducers({
  blackoutTimes: blackoutTimesReducer,
  details: detailsReducer,
  create: createReducer,
  formOptions: formOptionsReducer,
  stepperModal: stepperModalReducer,
  addBlackoutTime: addBlackoutTimeReducer,
  dueDate: dueDateReducer,
  deleteBlackoutTime: deleteBlackoutTimeReducer,
  cancel: cancelReducer,
  updateDescription: updateDescriptionReducer,
  diaryAuditAppointments: diaryAuditAppointmentsReducer,
  diaryAuditRegistrations: diaryAuditRegistrationsReducer,
  diaryMissingEntries: diaryMissingEntriesReducer,
  reactivate: reactivateReducer,
});
