import { useDispatch } from 'react-redux';

import { openAdminReportsModal, setAdminAudit } from '../state/modal/slice';
import { AdminReportsModalEnum } from '../state/modal/types';
import { AdminAudit } from '../types';

interface UseAllAuditsModalType {
  openModal: (modalName: AdminReportsModalEnum) => void;
}

export const useAllAuditsModal = (
  adminAudit: AdminAudit
): UseAllAuditsModalType => {
  const dispatch = useDispatch();
  const openModal = (modalName: AdminReportsModalEnum) => {
    dispatch(openAdminReportsModal(modalName));
    dispatch(setAdminAudit(adminAudit));
  };

  return {
    openModal,
  };
};
