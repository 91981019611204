import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { forceMockHeader } from 'mock/constants';
import { apiSlice } from 'store/apiSlice';
import { invalidateRTKTags } from 'utils/invalidateRTKTags';

import { DeleteGroupCertificateParams } from './types';
import endpoints from '../../config/endpoints';

const deleteGroupCertificateApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    removeGroupCertificate: build.mutation<void, DeleteGroupCertificateParams>({
      query: ({ certificateUuid, ...data }) => ({
        url: endpoints.GROUP_CERTIFICATE.REMOVE(certificateUuid),
        method: 'DELETE',
        data,
        headers: forceMockHeader,
      }),
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_DETAILS',
        'CERTIFICATE_DETAILS_TEMPLATE',
        'CERTIFICATE_DETAILS_ACTIONS',
      ]),
    }),
  }),
});

export const useRemoveGroupCertificateMutation = () => {
  const [removeGroupCertificate, mutation] =
    deleteGroupCertificateApi.useRemoveGroupCertificateMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: DeleteGroupCertificateParams) =>
    removeGroupCertificate(params).unwrap();

  return {
    ...mutation,
    removeGroupCertificate: requestMethod,
    status,
    error,
  };
};
