import routes from 'config/routes';

import { UUID_V4 } from '../regex/uuid';

interface GetAuditDraftFormPathnameParams {
  supplierId: string;
  auditDraftId: string;
  targetAuditId?: string;
  isAmendment?: boolean;
}

export const TARGET_AUDIT_SEARCH = 'target-audit';

export const getAuditDraftFormPathname = ({
  supplierId,
  auditDraftId,
  targetAuditId,
  isAmendment = false,
}: GetAuditDraftFormPathnameParams): string => {
  const baseRoute = isAmendment
    ? routes.AUDIT_AMENDMENT_FORM
    : routes.AUDIT_DRAFT_FORM;

  let pathname = baseRoute
    .replace(`:supplierId(${UUID_V4})`, supplierId)
    .replace(`:auditDraftId(${UUID_V4})`, auditDraftId);

  if (targetAuditId) {
    pathname = `${pathname}?${TARGET_AUDIT_SEARCH}=${targetAuditId}`;
  }

  return pathname;
};
