import { Logo, RoleMapper, TabChildrenComponent, TranslatedText } from 'types';

import { AuditActionsEnum } from './domain';

export enum AuditDetailsTabEnum {
  DETAILS = 'audit-tab-details',
  DOCUMENTS = 'audit-tab-documents',
  AUDITOR_PARTICIPANTS = 'audit-tab-auditor-participants',
  CERTIFICATE = 'audit-tab-certificate',
  RELEASES = 'audit-tab-releases',
  LOGS = 'audit-tab-logs',
  ACCESSES = 'audit-tab-iam',
}

export interface AuditDetailsTab {
  type: 'tab';
  props: {
    id: AuditDetailsTabEnum.DETAILS;
    name: TranslatedText;
    headerText: TranslatedText;
    logoResolver?: Logo[];
  };
  children: TabChildrenComponent[];
}

export const isDetailsTab = (tab: unknown): tab is AuditDetailsTab =>
  !!tab && (tab as AuditDetailsTab).props.id === AuditDetailsTabEnum.DETAILS;

export interface AuditDocumentsTab {
  type: 'tab';
  props: {
    id: AuditDetailsTabEnum.DOCUMENTS;
    name: TranslatedText;
    headerText: TranslatedText;
  };
  children: null;
}

export const isDocumentsTab = (tab: unknown): tab is AuditDocumentsTab =>
  !!tab &&
  (tab as AuditDocumentsTab).props.id === AuditDetailsTabEnum.DOCUMENTS;

export interface AuditAuditorParticipantsTab {
  type: 'tab';
  props: {
    id: AuditDetailsTabEnum.AUDITOR_PARTICIPANTS;
    name: TranslatedText;
    headerTextAuditor: TranslatedText;
    auditorColumnText: Record<string, TranslatedText>;
    roleMapper: RoleMapper[];
  };
  children: null;
}

export const isAuditorParticipantsTab = (
  tab: unknown
): tab is AuditAuditorParticipantsTab =>
  !!tab &&
  (tab as AuditAuditorParticipantsTab).props.id ===
    AuditDetailsTabEnum.AUDITOR_PARTICIPANTS;

export interface AuditCertificateTab {
  type: 'tab';
  props: {
    id: AuditDetailsTabEnum.CERTIFICATE;
    name: TranslatedText;
    headerTextDetails: TranslatedText;
    headerTextDocuments: TranslatedText;
    logoResolver?: Logo[];
  };
  children: TabChildrenComponent[];
}

export const isCertificateTab = (step: unknown): step is AuditCertificateTab =>
  !!step &&
  (step as AuditCertificateTab).props.id === AuditDetailsTabEnum.CERTIFICATE;

export interface ReleaseOption {
  id: string;
  name: TranslatedText;
  disabled?: boolean;
  selected?: boolean;
  allowedInForm?: boolean;
}

export interface AuditReleasesTab {
  type: 'tab';
  props: {
    id: AuditDetailsTabEnum.RELEASES;
    name: TranslatedText;
    headerText: TranslatedText;
    requiredAction: AuditActionsEnum;
    contentOptions: ReleaseOption[];
    timeFrameOptions: ReleaseOption[];
  };
  children: null;
}

export const isReleasesTab = (step: unknown): step is AuditReleasesTab =>
  !!step &&
  (step as AuditReleasesTab).props.id === AuditDetailsTabEnum.RELEASES;

export interface AuditAccessesTab {
  type: 'tab';
  props: {
    id: AuditDetailsTabEnum.ACCESSES;
    name: TranslatedText;
    headerText: TranslatedText;
    requiredAction: AuditActionsEnum;
  };
  children: null;
}

export const isAccessesTab = (tab: unknown): tab is AuditAccessesTab =>
  !!tab && (tab as AuditAccessesTab).props.id === AuditDetailsTabEnum.ACCESSES;

export type AuditDetailsTabType =
  | AuditDetailsTab
  | AuditDocumentsTab
  | AuditAuditorParticipantsTab
  | AuditCertificateTab
  | AuditReleasesTab
  | AuditAccessesTab;

export interface AuditDetailsTemplate {
  type: string;
  props: {
    pageHeader: TranslatedText;
  };
  children: AuditDetailsTabType[];
}

export interface AuditLogsTab {
  type: 'tab';
  props: {
    id: AuditDetailsTabEnum.LOGS;
    name: TranslatedText;
    headerText: TranslatedText;
    requiredAction: AuditActionsEnum;
  };
  children: null;
}

export const isLogsTab = (step: unknown): step is AuditLogsTab =>
  !!step && (step as AuditLogsTab).props.id === AuditDetailsTabEnum.LOGS;
