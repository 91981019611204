import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { store } from 'store';
import { apiSlice } from 'store/apiSlice';
import { DraftStatusEnum } from 'types';

import {
  CertificateDraftDetails,
  GetCertificateDraftDetailsParams,
} from './types';
import { endpoints } from '../../config/endpoints';
import {
  selectCertificateDraftSubmitStatus,
  setSubmitStatus,
} from '../submitCertificateDraft/slice';

const certificateDraftDetailsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateDraftDetails: build.query<CertificateDraftDetails, string>({
      query: (certificateDraftId) => ({
        url: endpoints.DETAILS(certificateDraftId),
      }),
      providesTags: ['CERTIFICATE_DRAFT_DETAILS'],
      keepUnusedDataFor: 0,
      transformResponse: (response: CertificateDraftDetails) => {
        const certificateDraftLocalState = selectCertificateDraftSubmitStatus(
          store.getState()
        );
        if (certificateDraftLocalState === DraftStatusEnum.SUBMITTED) {
          store.dispatch(setSubmitStatus(DraftStatusEnum.UNSUBMITTED));

          return {
            ...response,
            status: {
              id: DraftStatusEnum.SUBMITTED,
              name: 'Submitted',
            },
          };
        }
        return response;
      },
    }),
  }),
});

export const useCertificateDraftDetailsQuery = (
  params: GetCertificateDraftDetailsParams,
  refetchOnMountOrArgChange = true
) => {
  const query = certificateDraftDetailsApi.useGetCertificateDraftDetailsQuery(
    params.certificateDraftId,
    {
      refetchOnMountOrArgChange,
    }
  );

  const error = useFormattedError(query, 'formatToAlerts');
  const status = useRTKQueryStatus(query);

  return {
    ...query,
    certificateDraftDetails: query.data,
    status,
    error,
  };
};
