import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getSupplierDetails } from 'domains/supplier/state/details/slice';

import { AuditPlannerTab } from '../AuditPlannerTab';

export const WidgetAuditAppointments: FC = () => {
  const dispatch = useDispatch();
  const { supplierId } = useParams<{ supplierId: string }>();

  useEffect(() => {
    dispatch(getSupplierDetails({ supplierId }));
  }, [dispatch, supplierId]);

  return (
    <>
      <AuditPlannerTab />;
    </>
  );
};
