import { isObject } from 'lodash';
import { ReactNode } from 'react';

import { ButtonProps } from 'components/atoms/Button';
import type { RootState } from 'store';

export type Nullable<T> = T | null;

export type WithUndefined<T> = T | undefined;

export interface Option {
  value: string;
  label: string;
}

export const isOption = (option: unknown): option is Option =>
  isObject(option) && 'value' in option && 'label' in option;

export interface ExtendedError {
  message: string;
  details?: string;
}

export interface SelectOption {
  value: string | number;
  label: string;
  ariaLabel?: string;
}

export interface GroupedOption {
  label: string;
  options: SelectOption[];
}

export type ButtonColorType = ButtonProps['color'];

export enum LanguageEnum {
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr',
  ITALIAN = 'it',
  SPANISH = 'es',
}

export interface UniqueIdentifier {
  name: string;
  uuid: string;
}

export type Size = number | string | undefined;

type Unit = 'px' | 'rem' | '%' | 'vw' | 'vh';

export type CSSSize = `${number}${Unit}`;

export type ButtonLoaderPositionType = ButtonProps['loadingPosition'];

export enum LoaderStatusEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  IDLE = 'idle',
}

export enum AlertVariantEnum {
  WARNING,
  ERROR,
  SUCCESS,
}

export interface AlertsState {
  alerts: string[];
  variant: AlertVariantEnum;
}

export enum UserRoleEnum {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
}

export interface PartialThunkApi {
  getState: () => RootState;
}

export interface AsyncThunkOptions<RejectedValue = string[]> {
  rejectValue: RejectedValue;
  fulfilledMeta: null;
  state: RootState;
}

/* 
    We can use "Only" to extract keys in interface that has given type as a value 
    
    eg.
  
    interface Temp1 {
      key1: string;
      key2: number;
      key3: ()=>void;
      key4: string;
    }
  
    type OnlyStringKeys = Only<Temp1, string>;
  
    // OnlyStringKeys output: 'key1' | 'key4'
  
  */
export type Only<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

export type TranslatedText = {
  en: string;
  de?: string;
  fr?: string;
  es?: string;
  it?: string;
};

export interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export interface ActionComment {
  reason: string;
  comment?: string;
  emailSend?: boolean;
}

/**
 * StartEndDates vs StartEndDatesSuffixedType:
 * the reason for the 2 very similar types is because StartEndDatesSuffixedType structure
 * comes from YII,
 * StartEndDates on the other hand is already existing Platform's structure - commonly used
 */

export interface StartEndDates {
  start: string;
  end: string;
}

export interface StartEndDatesSuffixedType {
  startDate: string;
  endDate: string;
  auditTimeInMinutes?: number;
}

export interface ActionDropdownOption<PermissionType = string> {
  label: string;
  icon?: ReactNode;
  onClickAction: () => void;
  permission: PermissionType;
}

export interface LocationWithBackButton {
  showBackButton?: boolean;
}

export interface DraftStatusContent {
  headerTitlePrefix: string;
  title: string;
  status: AlertVariantEnum;
  label: string | string[];
}

export interface AuditDetailsURLParams {
  supplierId: string;
  auditId: string;
}

export interface QueryOptions {
  refetchOnMountOrArgChange?: boolean;
  skip?: boolean;
}

/**
 * Utility type to mark particular fields as optional
 * eg.
 *
 * interface  User {
 *    name: string;
 *    lastName: string;
 *    email: string;
 * }
 *
 * type PartialUser = PartialBy<User, 'email' | 'lastName'>;
 *
 */

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface SidebarMenuItem {
  label: string;
  url: string;
}
