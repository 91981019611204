import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import { NextAction } from 'components/templates/TemplateModalWithSnackbar';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';
import { useMemoizedDebounce } from 'hooks/useMemoizedDebounce';
import { Nullable, Option } from 'types';
import { renderDateRange } from 'utils/dates';

import {
  useAddAdditionalAuditMutation,
  useLazyGetAdditionalAuditOptionsQuery,
} from '../../../state/additionalAuditManage/api';
import { closeAndResetCertificateModal } from '../../../state/modal/slice';
import { CertificateModalCommonProps } from '../../../types';
import { StyledSearchField } from '../StyledSearchField';
import { StyledTemplateModalWithSnackbar } from '../StyledTemplateModalWithSnackbar';

type AddAuditModalProps = CertificateModalCommonProps;

export const AddAuditModal: FC<AddAuditModalProps> = (props) => {
  const { certificateUuid } = props;
  const [t] = useTranslation('certificateDetails');
  const dispatch = useDispatch();
  const [auditUuid, setAuditUuid] = useState('');

  const {
    addAdditionalAudit,
    isLoading,
    error = [],
  } = useAddAdditionalAuditMutation();
  const closeMethod = () => {
    dispatch(closeAndResetCertificateModal());
  };
  const nextAction: NextAction = (openSnackbar, closeModal) => {
    addAdditionalAudit({
      certificateUuid,
      auditUuid,
    }).then(() => {
      openSnackbar(t('Audit is added.'), 'success');
      closeModal();
    });
  };
  const alertsState = useMemoizedAlerts(error);
  const disableNextButton = !auditUuid;

  const {
    searchAudit,
    audits,
    isLoading: isAuditSearchLoading,
  } = useLazyGetAdditionalAuditOptionsQuery();

  const fetchAuditList = (searchTerm: string) =>
    searchAudit({ searchTerm, certificateUuid });
  const fetchAuditListDebounced = useMemoizedDebounce(fetchAuditList);
  const handleAuditSelect = (option: Nullable<Option>) => {
    setAuditUuid(option ? option.value : '');
  };
  const auditOptions = audits.map(
    ({ uuid, auid = '', supplier, auditTimeframe }) => {
      const timeframeRange = renderDateRange(
        auditTimeframe?.startDate || '',
        auditTimeframe?.endDate || ''
      );

      return {
        value: uuid,
        label: `${auid} ${supplier.name} (COID: ${supplier.coid}) ${timeframeRange}`,
      };
    }
  );

  return (
    <StyledTemplateModalWithSnackbar
      title={t('Add Audit/Assessment')}
      prevLabel={t('Cancel', { ns: 'components' })}
      nextAction={nextAction}
      nextLabel={t('Add')}
      disableNext={disableNextButton}
      isLoading={isLoading}
      nextColor="primary"
      closeMethod={closeMethod}
    >
      <StyledBaseModalContent alertsState={alertsState}>
        <StyledSearchField
          options={auditOptions}
          loading={isAuditSearchLoading}
          onValueSelect={handleAuditSelect}
          onSearchTermChange={fetchAuditListDebounced}
          description={t('Please select the Audit/Assessment')}
          placeholder={t('please select', { ns: 'components' })}
          infoMessage
          noOptionsMessage={t('No Audit found.')}
          dataTestId="audit-search"
        />
      </StyledBaseModalContent>
    </StyledTemplateModalWithSnackbar>
  );
};
