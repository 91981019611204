import { styled } from '@mui/material';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import React, { FC, ReactElement } from 'react';

import { ARGENT, TEXT } from 'config/appColors';

export type ModalTitleProps = DialogTitleProps;

const StyledDialogTitle = styled(DialogTitle)`
  border-bottom: 1px solid ${ARGENT};
  position: relative;
  padding: 15px 20px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.015rem;
  color: ${TEXT.PRIMARY};
`;

export const ModalTitle: FC<ModalTitleProps> = (props): ReactElement => (
  <StyledDialogTitle {...props} />
);

export default ModalTitle;
