import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Nullable } from 'types';
import { QueryParams } from 'utils/queryParams';

import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  PAGE_QUERY_KEY,
  ROWS_QUERY_KEY,
} from './config';
import { UsePagination, UsePaginationProps } from './types';

const isStateSyncWithParam = (state: number, queryParam: Nullable<string>) =>
  QueryParams.isQueryParamValid(queryParam) && Number(queryParam) !== state;

/**
 * helper functions to pay attention
 * pages should be counted from 1 in query params
 * getIncreasedByOne used with page from query params
 * getDecreasedByOne used with page from useState
 */
const getIncreasedByOne = (number: number) => number + 1;

const getDecreasedByOne = (number: number) => number - 1;

const useQueryParams = () => {
  const { search: queryParams } = useLocation();

  const { replace } = useHistory();

  const setParam = (key: string, value: string) => {
    const search = QueryParams.updateQueryParams({ [key]: value });
    replace({ search });
  };

  const deleteParam = (key: string) => {
    const search = QueryParams.removeParams(queryParams, [key]);
    replace({ search });
  };

  const { getParam } = QueryParams;

  return { getParam, setParam, deleteParam };
};

export const usePagination = (
  props: UsePaginationProps = {}
): UsePagination => {
  const {
    enabledQueryParams = false,
    initialRowsPerPage = DEFAULT_ROWS_PER_PAGE,
  } = props;

  const { getParam, setParam, deleteParam } = useQueryParams();

  const [totalRowsAmount, setTotalRowsAmount] = useState(0);

  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE);

  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  useLayoutEffect(() => {
    if (!enabledQueryParams) return;

    const newPage = getParam(PAGE_QUERY_KEY);
    const newRowsPerPage = getParam(ROWS_QUERY_KEY);

    const shouldSyncPageWithParam = isStateSyncWithParam(
      getDecreasedByOne(page),
      newPage
    );
    const shouldSyncRowsPerPageWithParam = isStateSyncWithParam(
      rowsPerPage,
      newRowsPerPage
    );
    const isPageParamValid = QueryParams.isQueryParamValid(newPage);
    const isRowsPerPageParamValid =
      QueryParams.isQueryParamValid(newRowsPerPage);
    const shouldSyncParamsWithState =
      !isPageParamValid || !isRowsPerPageParamValid;

    if (shouldSyncPageWithParam) {
      setPage(getDecreasedByOne(Number(newPage)));
    }

    if (shouldSyncRowsPerPageWithParam) {
      setRowsPerPage(Number(newRowsPerPage));
    }

    if (shouldSyncParamsWithState) {
      setParam(ROWS_QUERY_KEY, rowsPerPage.toString());
      setParam(PAGE_QUERY_KEY, getIncreasedByOne(page).toString());
    }

    /**
     * remove query params on component unmount - required when user switch tab (with query parameter)
     *
     * rule consistent-return not required in that case
     */
    // eslint-disable-next-line consistent-return
    return () => {
      deleteParam(ROWS_QUERY_KEY);
      deleteParam(PAGE_QUERY_KEY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPaginationState = () => {
    setPage(DEFAULT_INITIAL_PAGE);
    setRowsPerPage(initialRowsPerPage);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);

    if (enabledQueryParams) {
      setParam(PAGE_QUERY_KEY, getIncreasedByOne(newPage).toString());
    }
  };

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);

    if (enabledQueryParams) {
      setParam(ROWS_QUERY_KEY, newRowsPerPage.toString());
    }
  };

  /**
   * effect for handling case when page query param in URL is out of range
   */
  useEffect(() => {
    const isSearchingOutOfRange = totalRowsAmount < page * rowsPerPage;

    if (isSearchingOutOfRange) {
      onPageChange(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRowsAmount]);

  return {
    offset: rowsPerPage * page,
    limit: rowsPerPage,
    pagination: {
      page,
      rowsPerPage,
      totalRowsAmount,
      onPageChange,
      onRowsPerPageChange,
    },
    resetPaginationState,
    setTotalRowsAmount,
  };
};
