import {
  addMonths,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subWeeks,
} from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from 'types';

import { StyledButton, StyledButtonContainer } from './DateRangePicker.styled';

const today = new Date();
const dateRanges = {
  thisWeek: [
    startOfWeek(today, { weekStartsOn: 1 }), // Week starts on Monday
    endOfWeek(today, { weekStartsOn: 1 }),
  ],
  lastWeek: [
    startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
    endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
  ],
  last7Days: [
    subDays(today, 6), // 7 days ago (including today)
    today,
  ],
  currentMonth: [startOfMonth(today), endOfMonth(today)],
  nextMonth: [
    startOfMonth(addMonths(today, 1)),
    endOfMonth(addMonths(today, 1)),
  ],
};

const { currentMonth, last7Days, lastWeek, nextMonth, thisWeek } = dateRanges;

const config = [
  { payload: thisWeek, label: 'This Week' },
  { payload: lastWeek, label: 'Last Week' },
  { payload: last7Days, label: 'Last 7 Days' },
  { payload: currentMonth, label: 'Current Month' },
  { payload: nextMonth, label: 'Next Month' },
  { payload: [null, null], label: 'Reset' },
];

interface PreselectedDateRangePickerProps {
  onChange: (dates: Nullable<Date>[]) => void;
}

export const PreselectedDateRangePicker: FC<PreselectedDateRangePickerProps> =
  ({ onChange }) => {
    const [t] = useTranslation('components');
    return (
      <StyledButtonContainer>
        {config.map(({ label, payload }) => (
          <StyledButton key={label} onClick={() => onChange(payload)}>
            {t(label)}
          </StyledButton>
        ))}
      </StyledButtonContainer>
    );
  };
