import React, { FC } from 'react';

import { AlertList } from 'components/molecules/AlertList';
import { AlertVariantEnum, WarningBoxConfig } from 'types';

import { useWarnings } from '../../../hooks/useWarnings';

interface WarningBoxProps {
  config: WarningBoxConfig;
  warningIds?: string[];
}

export const WarningBox: FC<WarningBoxProps> = ({ config, warningIds }) => {
  const { showWarnings, warningsToDisplay } = useWarnings(config, warningIds);

  return showWarnings ? (
    <AlertList
      alerts={warningsToDisplay}
      variant={AlertVariantEnum.WARNING}
      noAlertsTranslation
    />
  ) : null;
};
