import React, { FC } from 'react';

import { DottedButton } from 'components/atoms/DottedButton';
import { DropdownMenu } from 'components/molecules/DropdownMenu';

import {
  AdditionalSupplierActionsDropdownProps,
  useAdditionalSupplierActionsDropdown,
} from '../../../hooks/useAdditionalSupplierActionsDropdown';

export const AdditionalSupplierActionsDropdown: FC<AdditionalSupplierActionsDropdownProps> =
  (props) => {
    const dropdownOptions = useAdditionalSupplierActionsDropdown(props);

    return (
      <DropdownMenu
        options={dropdownOptions}
        renderOpenButton={({ onClick }) => <DottedButton onClick={onClick} />}
      />
    );
  };
