import { getReactEnvVar } from './getReactEnvVar';
import isIframe from './isIframe';

export const isProd = process.env.NODE_ENV === 'production';
export const isDev = process.env.NODE_ENV === 'development';

export enum MockSettingsEnum {
  OFF = 'off',
  MIX = 'mix',
  FULL = 'full',
}
/**
 * TODO: Once iframe gets removed remove code related to it
 */
export enum YIIOnlyEnum {
  TRUE = 'true',
  FALSE = 'false',
}

const iframeInFirefox =
  isIframe && navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const MOCKED_DATA = getReactEnvVar('MOCKED_DATA');
export const isFullMock = MOCKED_DATA === MockSettingsEnum.FULL;
export const isAnyMock =
  (isFullMock || MOCKED_DATA === MockSettingsEnum.MIX) && !iframeInFirefox;
/**
 * TODO: Once iframe gets removed remove code related to it
 */
export const isYiiOnly = getReactEnvVar('YII_ONLY') === YIIOnlyEnum.TRUE;
