import { get, isObject } from 'lodash';
import React, { FC } from 'react';

import { TranslatedText } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

export interface AttributeRowMappedTextProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
  valueMapper?: Record<string, TranslatedText>;
}

export const AttributeRowMappedText: FC<AttributeRowMappedTextProps> = (
  props
) => {
  const { data, dataRef, labelText, valueMapper } = props;
  const value = get(data, dataRef);

  const translatedValue =
    isObject(valueMapper) && isObject(valueMapper[value])
      ? getTranslatedValue(valueMapper[value])
      : '';

  if (!translatedValue) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Mapped Text data or no data" />
    );
  }

  return <AttributeRowBase label={labelText} value={translatedValue} />;
};
