import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import React, { FC, ReactNode } from 'react';

import { ErrorState } from 'components/molecules/ErrorState';
import Loader from 'components/molecules/Loader';
import { PageHeader } from 'components/molecules/PageHeader';
import { CSSSize, LoaderStatusEnum } from 'types';

const StyledPageHeader = styled(PageHeader)`
  margin-left: 0;
  margin-right: 0;
`;
const StyledContainer = styled('div')`
  width: 100%;
`;

interface TemplateBasicPageContentProps {
  pageTitle?: string;
  className?: string;
  customTitleComponent?: ReactNode;
  loaderStatus?: LoaderStatusEnum;
  minHeight?: CSSSize;
  error?: {
    heading: string;
    description?: string;
    errorCode?: string;
  };
  children?: ReactNode;
}

export const TemplateBasicPageContent: FC<TemplateBasicPageContentProps> = (
  props
) => {
  const {
    pageTitle,
    className,
    customTitleComponent,
    children,
    loaderStatus = LoaderStatusEnum.IDLE,
    minHeight,
    error,
  } = props;
  return (
    <Loader
      minHeight={minHeight}
      status={loaderStatus}
      error={
        error && (
          <ErrorState
            heading={error.heading}
            description={error.description}
            errorCode={error?.errorCode}
          />
        )
      }
    >
      <StyledContainer className={className}>
        <StyledPageHeader
          title={pageTitle}
          titleVariant="h3"
          titleComponent={customTitleComponent}
          hideBreadcrumbs
        />
        <Paper>{children}</Paper>
      </StyledContainer>
    </Loader>
  );
};
