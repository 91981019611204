export const auditTypes = [
  {
    id: 'initial-audit',
    text: {
      en: 'Initial audit',
      de: 'Erstaudit',
      fr: 'Audit initiale',
      es: 'Auditoría inicial',
      it: 'Audit iniziale',
    },
  },
  {
    id: 'recertification-audit',
    text: {
      en: 'Recertification audit',
      de: 'Rezertifizierungsaudit',
      fr: 'Audit de recertification',
      es: 'Auditoría de recertificación',
      it: 'Audit di ricertificazione',
    },
  },
  {
    id: 'follow-up-audit',
    text: {
      en: 'Follow-up audit',
      de: 'Ergänzungsaudit',
      fr: 'Audit complémentaire',
      es: 'Auditoría de seguimiento',
      it: 'Audit di follow-up',
    },
  },
  {
    id: 'extension-audit',
    text: {
      en: 'Extension audit',
      de: 'Erweiterungsaudit',
      fr: 'Audit d’extension',
      es: 'Auditoría de ampliación',
      it: 'Audit di estensione',
    },
  },
  {
    id: 'improvement-audit',
    text: {
      en: 'Improvement Audit',
      de: '',
      fr: '',
      es: '',
      it: '',
    },
  },
];
