import get from 'lodash/get';
import React, { FC } from 'react';

import { GeneralDetails } from 'types';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

// TODO: TBD are there situation when there is no field (optional??) what should happen
type Type = GeneralDetails;

const isType = (type: unknown): type is Type =>
  !!type &&
  typeof type === 'object' &&
  'id' in (type as Type) &&
  'name' in (type as Type);

interface AttributeRowTypeProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
  //   children: null; // TBD it might be used in the future
}

export const AttributeRowType: FC<AttributeRowTypeProps> = (props) => {
  const { data, dataRef, labelText } = props;
  const value = get(data, dataRef);
  const showAttributeRow = isType(value);

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Type data" />
    );
  }

  return <AttributeRowBase label={labelText} value={value.name} />;
};
