import { styled } from '@mui/material';
import { parse } from 'date-fns';
import { sortBy } from 'lodash';
import React, { FC, Fragment } from 'react';

import { TimeLog } from 'types';
import { generateId } from 'utils/generateId';

const StyledContainer = styled('div')`
  margin-left: 10px;
`;

const StyledHoursListItem = styled('span')`
  letter-spacing: 0.017rem;

  & + & {
    margin-left: 6px;
  }
`;

const shouldBreakLine = (index: number) => index % 2 !== 0;

const getDateWithHoursAndMin = (hour: string) =>
  parse(hour, 'HH:mm', new Date()).getTime();

const getTimeWithoutSeconds = (time: string): string => {
  const [hours, minutes] = time.split(':');
  return `${hours}:${minutes}`;
};

interface AuditParticipantHoursListProps {
  timeLog: TimeLog[];
}

export const AuditParticipantHoursList: FC<AuditParticipantHoursListProps> = ({
  timeLog,
}) => {
  const logsWithoutNullHours = timeLog.filter(({ from, to }) => from && to);
  const sortedHours = sortBy(logsWithoutNullHours, ({ from }) =>
    getDateWithHoursAndMin(getTimeWithoutSeconds(from as string))
  );

  return (
    <StyledContainer>
      {sortedHours.map(({ from, to, uuid = '' }, index) => (
        <Fragment key={generateId(uuid, index)}>
          <StyledHoursListItem data-testid="time-log-hours">
            {getTimeWithoutSeconds(from as string)}-
            {getTimeWithoutSeconds(to as string)}
          </StyledHoursListItem>
          {index !== sortedHours.length - 1 && ','}
          {shouldBreakLine(index) && <br />}
        </Fragment>
      ))}
    </StyledContainer>
  );
};
