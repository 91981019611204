import { alpha, styled } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React, { FC, ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { BLACK, PRIMARY } from 'config/appColors';
import { SidebarMenuItem as SidebarMenuItemType } from 'types';

type SidebarMenuItemProps = SidebarMenuItemType & {
  actionBeforeClick?: () => void;
};

export const MENU_ITEM_CLASSNAME = 'SidebarMenu-item';

export const StyledListItem = styled(ListItem)`
  padding: 6px 20px;
  border-left: 3px transparent solid;
  transition: background-color 0.3s;

  &.${MENU_ITEM_CLASSNAME} a,
  &.${MENU_ITEM_CLASSNAME} span {
    display: block;
    font-weight: 500;
    text-decoration: none;
    color: ${PRIMARY.MAIN};
  }

  &:not(.${MENU_ITEM_CLASSNAME}--with-submenu):hover,
  &.${MENU_ITEM_CLASSNAME}--active {
    background: ${alpha(BLACK, 0.04)};
  }

  &.${MENU_ITEM_CLASSNAME}--active {
    border-color: ${PRIMARY.MAIN};
  }
`;

export const SidebarMenuItem: FC<SidebarMenuItemProps> = (
  props
): ReactElement => {
  const { label, url, actionBeforeClick } = props;
  const location = useLocation();
  const menuItemClassName =
    location.pathname === url
      ? `${MENU_ITEM_CLASSNAME} ${MENU_ITEM_CLASSNAME}--active`
      : MENU_ITEM_CLASSNAME;

  return (
    <StyledListItem className={menuItemClassName}>
      <ListItemText>
        <NavLink exact to={url} onClick={actionBeforeClick}>
          {label}
        </NavLink>
      </ListItemText>
    </StyledListItem>
  );
};
