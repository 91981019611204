import get from 'lodash/get';
import React, { FC } from 'react';

import { StartEndDatesSuffixedType } from 'types';
import { renderDateRange } from 'utils/dates';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

type AuditTimeFrame = StartEndDatesSuffixedType;

const isAuditTimeFrame = (
  auditTimeFrame: unknown
): auditTimeFrame is AuditTimeFrame =>
  !!auditTimeFrame &&
  'startDate' in (auditTimeFrame as AuditTimeFrame) &&
  'endDate' in (auditTimeFrame as AuditTimeFrame);

interface AttributeRowAuditTimeFrameProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
}

export const AttributeRowAuditTimeFrame: FC<AttributeRowAuditTimeFrameProps> = (
  props
) => {
  const { data, dataRef, labelText } = props;
  const value = get(data, dataRef);
  const showAttributeRow =
    isAuditTimeFrame(value) &&
    !!value.startDate.trim() &&
    !!value.endDate.trim();

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong AuditTimeFrame data" />
    );
  }

  return (
    <AttributeRowBase
      label={labelText}
      value={renderDateRange(value.startDate, value.endDate)}
    />
  );
};
