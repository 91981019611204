import { styled } from '@mui/material';

import { SearchField } from 'components/molecules/SearchField';

const StyledSearchField = styled(SearchField)`
  .SearchField-description {
    margin-top: 0;
  }

  .MuiFormControl-root {
    width: 100%;
  }
`;

StyledSearchField.displayName = 'StyledSearchField';

export { StyledSearchField };
