import { LANG_PARAM } from 'config/languages';

import { ResourceName } from '../state/accesses/types';

const endpoints = {
  ACCESSES: {
    LIST: (entityUuid: string, resourceName: ResourceName): string =>
      `/${LANG_PARAM}/${resourceName}/${entityUuid}/iam-accesses`,
  },
};

export default endpoints;
