import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { AlertsState, GroupedOption, Nullable, SelectOption } from 'types';

import { useModalForm } from '../../../hooks/useModalForm';

interface CertificateDetailsModalContentBaseProps {
  adminCommentHeading?: string;
  adminCommentRows?: string | number;
  actionReasonOptions: (SelectOption | GroupedOption)[];
  alertsState: Nullable<AlertsState>;
  showEmailCheckbox?: boolean;
  className?: string;
  children?: ReactNode;
}

export const CertificateDetailsModalContentBase: FC<CertificateDetailsModalContentBaseProps> =
  (props) => {
    const [t] = useTranslation('certificateDetails');
    const {
      children,
      adminCommentHeading = t('Action Information', { ns: 'components' }),
      adminCommentRows,
      actionReasonOptions,
      alertsState,
      showEmailCheckbox = false,
      className,
    } = props;

    const isAdmin = useSelector(selectUserIsAdmin);

    const { actionComment, setActionInfoValue } = useModalForm();

    return (
      <StyledBaseModalContent
        className={className}
        adminCommentHeading={adminCommentHeading}
        adminCommentRows={adminCommentRows}
        adminReasonOptions={actionReasonOptions}
        alertsState={alertsState}
        adminReason={actionComment.reason}
        adminComment={actionComment.comment}
        adminReasonChangeHandler={({ target }) =>
          setActionInfoValue('reason', target.value as string)
        }
        adminCommentChangeHandler={(value) =>
          setActionInfoValue('comment', value)
        }
        adminSendEmailValue={!!actionComment.emailSend}
        adminSendEmailChangeHandler={
          showEmailCheckbox
            ? (_, value) => setActionInfoValue('emailSend', value)
            : undefined
        }
        isAdmin={isAdmin}
      >
        {children}
      </StyledBaseModalContent>
    );
  };
