import get from 'lodash/get';
import React, { FC } from 'react';

import { formatDate } from 'utils/dates';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

const isDate = (date: unknown): date is string => typeof date === 'string';

interface AttributeRowCertificateValidUntilProps
  extends AttributeRowCommonProps {
  data: Record<string, unknown>;
  //   children: null; // TBD it might be used in the future
}

export const AttributeRowCertificateValidUntil: FC<AttributeRowCertificateValidUntilProps> =
  (props) => {
    const { data, dataRef, labelText } = props;
    const value = get(data, dataRef);
    const showAttributeRow = isDate(value);

    if (!showAttributeRow) {
      return (
        <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong CertificateValidUntil data" />
      );
    }

    return <AttributeRowBase label={labelText} value={formatDate(value)} />;
  };
