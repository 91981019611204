import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { TranslatedText, WidgetTechScopesGeneralConfig } from 'types';

import type { SaveTechScopesParams } from './TechScopeWidget';
import { TechScopeWidget } from './TechScopeWidget';

type WidgetTechScopesGeneralProps = {
  data: SaveTechScopesParams['techScopesAudit'];
  element: WidgetTechScopesGeneralConfig;
  errors: Record<string, TranslatedText>;
  setData: (data: SaveTechScopesParams['techScopesAudit']) => void;
};

export const WidgetTechScopesGeneral: FC<WidgetTechScopesGeneralProps> = ({
  data,
  errors,
  element,
  setData,
}) => {
  const { i18n } = useTranslation();
  const { name, id, scopes } = element.props;

  const saveScopes = ({ techScopesAudit }: SaveTechScopesParams) => {
    setData(techScopesAudit);
  };

  return (
    <TechScopeWidget
      title={name}
      scopes={scopes}
      widgetId={id}
      errors={errors}
      language={i18n.language}
      saveScopes={saveScopes}
      techScopesAudit={data}
      techScopesCertificate={data}
    />
  );
};
