import React, { FC, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectAuditPlanKind,
  selectAuditPlanModule,
  setKind,
} from '../../../state/create/slice';
import {
  fetchKinds,
  selectKindFormOptions,
} from '../../../state/formOptions/slice';
import { setIsNextButtonDisabled } from '../../../state/stepperModal/slice';
import { FormWithRadio } from '../FormWithRadio';

export const FormSetKind: FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation('auditAppointment');

  const kinds = useSelector(selectKindFormOptions);
  const selectedKind = useSelector(selectAuditPlanKind);
  const selectedModule = useSelector(selectAuditPlanModule);

  useLayoutEffect(() => {
    if (selectedModule?.id && !kinds.length) {
      dispatch(fetchKinds());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedModule]);

  useEffect(() => {
    const enabledOptions = kinds.filter((kind) => kind.enabled);

    if (!selectedKind && enabledOptions.length === 1) {
      dispatch(setKind(enabledOptions[0]));
      dispatch(setIsNextButtonDisabled(false));
    }
  }, [kinds.length, dispatch, selectedKind, kinds]);

  const handleKindChange = (kindId: string) => {
    const newSelectedKind = kinds.find((kind) => kind.id === kindId);
    if (newSelectedKind) {
      dispatch(setKind(newSelectedKind));
      dispatch(setIsNextButtonDisabled(false));
    }
  };

  return (
    <FormWithRadio
      title={t('Kind')}
      description={t('Please select the kind of the Audit.')}
      ariaLabel={t('Audit Kind')}
      options={kinds}
      value={selectedKind?.id || ''}
      onChange={handleKindChange}
    />
  );
};
