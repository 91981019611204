import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Select } from 'components/atoms/Select';
import { Textarea } from 'components/atoms/Textarea';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';

import { useModalForm } from '../../../hooks/useModalForm';
import { useTranslatedSelectOptions } from '../../../hooks/useTranslatedSelectOptions';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { StyledInputWrapper } from '../../atoms/StyledInputWrapper';
import { StyledLabel } from '../../atoms/StyledLabel';
import { CertificateDetailsModalContentBase } from '../../templates/CertificateDetailsModalContentBase';

interface ExceptionalCircumstanceModalFormProps {
  errors?: string[];
  isUpdateForm?: boolean;
}

export const ExceptionalCircumstanceModalForm: FC<ExceptionalCircumstanceModalFormProps> =
  ({ errors = [], isUpdateForm = false }) => {
    const [t] = useTranslation('certificateDetails');
    const { ADD_EXCEPTIONAL_CIRCUMSTANCE, UPDATE_EXCEPTIONAL_CIRCUMSTANCE } =
      CertificateDetailsModalEnum;
    const isAdmin = useSelector(selectUserIsAdmin);

    const { circumstanceType, circumstanceDescription, setFormValue } =
      useModalForm();

    const type = isUpdateForm
      ? UPDATE_EXCEPTIONAL_CIRCUMSTANCE
      : ADD_EXCEPTIONAL_CIRCUMSTANCE;
    const circumstanceTypes = useTranslatedSelectOptions({
      type,
      collectionName: 'circumstanceTypes',
    });
    const actionReasonOptions = useTranslatedSelectOptions({
      type,
      collectionName: 'actionReasons',
    });
    const alertsState = useMemoizedAlerts(errors);

    return (
      <CertificateDetailsModalContentBase
        actionReasonOptions={actionReasonOptions}
        alertsState={alertsState}
      >
        <StyledInputWrapper $largerMargin>
          <StyledLabel htmlFor="circumstanceType">
            {t('Please select the type.')}
          </StyledLabel>
          <Select
            fullWidth
            variant="outlined"
            placeholder={t('Please select', { ns: 'components' })}
            options={circumstanceTypes}
            value={circumstanceType}
            onChange={({ target }) =>
              setFormValue('circumstanceType', target.value as string)
            }
            id="circumstanceType"
          />
        </StyledInputWrapper>

        <StyledInputWrapper $largerMargin={isAdmin}>
          <StyledLabel htmlFor="circumstanceDescription">
            {t('Comment')}
          </StyledLabel>
          <Textarea
            value={circumstanceDescription}
            onChange={({ target }) =>
              setFormValue('circumstanceDescription', target.value)
            }
            id="circumstanceDescription"
            fullWidth
            multiline
            rows={6}
          />
        </StyledInputWrapper>
      </CertificateDetailsModalContentBase>
    );
  };
