import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';

import { MISSING_PARAMS_ERROR, UNEXPECTED_ERROR } from 'config/constants';
import type { RootState } from 'store';
import {
  AlertsState,
  AsyncThunkOptions,
  LoaderStatusEnum,
  Nullable,
} from 'types';
import apiClient from 'utils/apiClient';
import {
  createErrorAlertState,
  createWarningAlertState,
} from 'utils/createAlertsState';
import { formatToAlerts } from 'utils/formatToAlerts';

import {
  AuditAppointmentFormOptionsState,
  AuditAppointmentFormOptionsValues,
  FixedDatesResponse,
  KindsResponse,
  TimeFormOptions,
  TypesResponse,
} from './types';
import { getCreateValues, getSupplierIdAndCertificationBodyId } from './utils';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';
import {
  AuditAppointmentStepEnum,
  ExecutionModeIdentifier,
  KindIdentifier,
  ModuleIdentifier,
  TimeRange,
  TimeSelectPlaceholderEnum,
  TypeIdentifier,
} from '../../types';
import { setIsNextButtonDisabled } from '../stepperModal/slice';

const initialTimeState = {
  loading: LoaderStatusEnum.IDLE,
  placeholder: TimeSelectPlaceholderEnum.SELECT,
  range: null,
};

const initialTimes = {
  start: initialTimeState,
  end: initialTimeState,
};

const initialState: AuditAppointmentFormOptionsState = {
  loading: LoaderStatusEnum.IDLE,
  alertsState: null,
  modules: [],
  types: [],
  kinds: [],
  times: initialTimes,
  executionModes: [],
};

const reducerName = `${parentReducerName}/formOptions`;

export const fetchModules = createAsyncThunk<
  ModuleIdentifier[],
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchModules`, async (_, thunkApi) => {
  try {
    const { data } = await apiClient.get(
      endpoints.CREATE_PROCESS.OPTION_LIST.MODULES,
      {
        params: { ...getSupplierIdAndCertificationBodyId(thunkApi) },
      }
    );

    if (!Array.isArray(data)) {
      return thunkApi.rejectWithValue([UNEXPECTED_ERROR]);
    }

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

export const fetchTypes = createAsyncThunk<
  TypesResponse,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchTypes`, async (_, thunkApi) => {
  try {
    const { module } = getCreateValues(thunkApi);

    if (!module) {
      return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);
    }

    const { data } = await apiClient.get<TypesResponse>(
      endpoints.CREATE_PROCESS.OPTION_LIST.TYPES(module.id),
      {
        params: { ...getSupplierIdAndCertificationBodyId(thunkApi) },
      }
    );

    if (!Array.isArray(data?.types)) {
      return thunkApi.rejectWithValue([UNEXPECTED_ERROR]);
    }

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

export const fetchKinds = createAsyncThunk<
  KindsResponse,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchKinds`, async (_, thunkApi) => {
  try {
    const { module, type } = getCreateValues(thunkApi);

    if (!module || !type) {
      return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);
    }

    const { data } = await apiClient.get<KindsResponse>(
      endpoints.CREATE_PROCESS.OPTION_LIST.KINDS(module.id),
      {
        params: {
          typeId: type.id,
          ...getSupplierIdAndCertificationBodyId(thunkApi),
        },
      }
    );

    if (!Array.isArray(data?.kinds)) {
      return thunkApi.rejectWithValue([UNEXPECTED_ERROR]);
    }

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

export const fetchAnnouncedStartRange = createAsyncThunk<
  TimeRange,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchAnnouncedStartRange`, async (_, thunkApi) => {
  try {
    const { module, type, kind } = getCreateValues(thunkApi);

    if (!module || !type || !kind) {
      return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);
    }

    const { data } = await apiClient.get(
      endpoints.CREATE_PROCESS.TIMES.ANNOUNCED_START_RANGE(module.id),
      {
        params: {
          typeId: type.id,
          kindId: kind.id,
          ...getSupplierIdAndCertificationBodyId(thunkApi),
        },
      }
    );

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

export const fetchAnnouncedEndRange = createAsyncThunk<
  TimeRange,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchAnnouncedEndRange`, async (_, thunkApi) => {
  try {
    const { module, type, kind, time } = getCreateValues(thunkApi);

    if (!module || !type || !kind || !time?.start) {
      return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);
    }

    const { data } = await apiClient.get(
      endpoints.CREATE_PROCESS.TIMES.ANNOUNCED_END_RANGE(module.id),
      {
        params: {
          typeId: type.id,
          kindId: kind.id,
          start: time.start,
          ...getSupplierIdAndCertificationBodyId(thunkApi),
        },
      }
    );

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

export const validateSelectedTime = createAsyncThunk<
  Pick<TimeRange, 'hints'>,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/validateSelectedTime`, async (_, thunkApi) => {
  try {
    const { module, type, kind, time } = getCreateValues(thunkApi);

    if (!module || !type || !kind || !time?.start || !time?.end) {
      return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);
    }

    const { data } = await apiClient.get(
      endpoints.CREATE_PROCESS.TIMES.ANNOUNCED_START_END_DATE_VALIDATION(
        module.id
      ),
      {
        params: {
          typeId: type.id,
          kindId: kind.id,
          start: time.start,
          end: time.end,
          ...getSupplierIdAndCertificationBodyId(thunkApi),
        },
      }
    );

    thunkApi.dispatch(setIsNextButtonDisabled(false));

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    thunkApi.dispatch(setIsNextButtonDisabled(true));

    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

export const fetchUnannouncedStartRange = createAsyncThunk<
  TimeRange,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchUnannouncedStartRange`, async (_, thunkApi) => {
  try {
    const { module, type, kind } = getCreateValues(thunkApi);

    if (!module || !type || !kind) {
      return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);
    }

    const { data } = await apiClient.get(
      endpoints.CREATE_PROCESS.TIMES.UNANNOUNCED_START_RANGE(module.id),
      {
        params: {
          typeId: type.id,
          kindId: kind.id,
          ...getSupplierIdAndCertificationBodyId(thunkApi),
        },
      }
    );

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

export const fetchUnannouncedEndDate = createAsyncThunk<
  Omit<FixedDatesResponse, 'start'>,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchUnannouncedEndDate`, async (_, thunkApi) => {
  try {
    const { module, type, kind, time } = getCreateValues(thunkApi);

    if (!module || !type || !kind || !time?.start) {
      return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);
    }

    const { data } = await apiClient.get(
      endpoints.CREATE_PROCESS.TIMES.UNANNOUNCED_END_DATE(module.id),
      {
        params: {
          typeId: type.id,
          kindId: kind.id,
          start: time.start,
          ...getSupplierIdAndCertificationBodyId(thunkApi),
        },
      }
    );

    thunkApi.dispatch(setIsNextButtonDisabled(false));

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    thunkApi.dispatch(setIsNextButtonDisabled(true));

    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

export const fetchUnannouncedPeriodDates = createAsyncThunk<
  FixedDatesResponse,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchUnannouncedPeriodDates`, async (_, thunkApi) => {
  try {
    const { module, type, kind } = getCreateValues(thunkApi);

    if (!module || !type || !kind) {
      return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);
    }

    const { data } = await apiClient.get(
      endpoints.CREATE_PROCESS.TIMES.UNANNOUNCED_PERIOD_DATES(module.id),
      {
        params: {
          typeId: type.id,
          kindId: kind.id,
          ...getSupplierIdAndCertificationBodyId(thunkApi),
        },
      }
    );

    thunkApi.dispatch(setIsNextButtonDisabled(false));

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    thunkApi.dispatch(setIsNextButtonDisabled(true));

    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

export const fetchExecutionModes = createAsyncThunk<
  ExecutionModeIdentifier[],
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchExecutionModes`, async (_, thunkApi) => {
  try {
    const { module, type, kind } = getCreateValues(thunkApi);

    if (!module || !type || !kind) {
      return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);
    }

    const { data } = await apiClient.get(
      endpoints.CREATE_PROCESS.OPTION_LIST.EXECUTION_MODES(module.id),
      {
        params: {
          typeId: type.id,
          kindId: kind.id,
          ...getSupplierIdAndCertificationBodyId(thunkApi),
        },
      }
    );

    if (!Array.isArray(data)) {
      return thunkApi.rejectWithValue([UNEXPECTED_ERROR]);
    }

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

const formOptionsToAuditEnumStepMapper: Record<
  keyof AuditAppointmentFormOptionsValues,
  AuditAppointmentStepEnum
> = {
  modules: AuditAppointmentStepEnum.MODULE,
  types: AuditAppointmentStepEnum.TYPE,
  kinds: AuditAppointmentStepEnum.KIND,
  times: AuditAppointmentStepEnum.TIME,
  executionModes: AuditAppointmentStepEnum.EXECUTION_MODE,
};

const commonActions = [
  fetchModules,
  fetchTypes,
  fetchKinds,
  fetchAnnouncedStartRange,
  fetchAnnouncedEndRange,
  validateSelectedTime,
  fetchExecutionModes,
  fetchUnannouncedPeriodDates,
] as const;

const isFetchingPending = isPending(...commonActions);
const isFetchingFulfilled = isFulfilled(...commonActions);
const isFetchingRejected = isRejected(...commonActions);
const isStartRangeDatePending = isPending(
  fetchUnannouncedStartRange,
  fetchAnnouncedStartRange
);
const isStartRangeDateFulfilled = isFulfilled(
  fetchUnannouncedStartRange,
  fetchAnnouncedStartRange
);
const isStartRangeDateRejected = isRejected(
  fetchUnannouncedStartRange,
  fetchAnnouncedStartRange
);

const isEndRangeDatePending = isPending(
  fetchAnnouncedEndRange,
  fetchUnannouncedEndDate
);

const isEndRangeDateRejected = isRejected(
  fetchAnnouncedEndRange,
  fetchUnannouncedEndDate
);

// slice
export const auditAppointmentFormOptionsSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetRanges: (state) => {
      state.times = initialTimes;
    },
    resetFormOptionState: () => initialState,
    resetFormOptionsStateToCurrentStep: (
      state,
      action: PayloadAction<AuditAppointmentStepEnum>
    ) => {
      const formOptionKeys = Object.keys(
        formOptionsToAuditEnumStepMapper
      ) as Array<keyof AuditAppointmentFormOptionsValues>;
      const mappedKeys = Object.values(formOptionsToAuditEnumStepMapper);
      const indexOfCurrentStep = mappedKeys.indexOf(action.payload);

      state.alertsState = null;
      state.loading = LoaderStatusEnum.SUCCESS;
      formOptionKeys.forEach((key, index) => {
        if (index > indexOfCurrentStep) {
          (state[key] as AuditAppointmentFormOptionsValues[typeof key]) =
            initialState[key];
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModules.fulfilled, (state, action) => {
      state.modules = action.payload;
    });
    builder.addCase(fetchTypes.fulfilled, (state, { payload }) => {
      state.types = payload?.types;
      state.alertsState = createWarningAlertState(payload?.hints || []);
    });
    builder.addCase(fetchKinds.fulfilled, (state, { payload }) => {
      state.kinds = payload?.kinds;
      state.alertsState = createWarningAlertState(payload?.warnings || []);
    });
    builder.addCase(fetchAnnouncedEndRange.fulfilled, (state, action) => {
      state.alertsState = createWarningAlertState(action.payload.hints);
      state.times.end.loading = LoaderStatusEnum.SUCCESS;
      state.times.end.range = action.payload;
      state.times.end.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder.addCase(validateSelectedTime.fulfilled, (state, action) => {
      state.alertsState = createWarningAlertState(action.payload.hints);
      state.times.start.loading = LoaderStatusEnum.SUCCESS;
      state.times.start.placeholder = TimeSelectPlaceholderEnum.SELECT;
      state.times.end.loading = LoaderStatusEnum.SUCCESS;
      state.times.end.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder.addCase(validateSelectedTime.rejected, ({ times }) => {
      times.start.loading = LoaderStatusEnum.ERROR;
      times.start.placeholder = TimeSelectPlaceholderEnum.SELECT;
      times.end.loading = LoaderStatusEnum.ERROR;
      times.end.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder.addCase(validateSelectedTime.pending, ({ times }) => {
      times.start.loading = LoaderStatusEnum.LOADING;
      times.start.placeholder = TimeSelectPlaceholderEnum.CHECKING;
      times.end.loading = LoaderStatusEnum.LOADING;
      times.end.placeholder = TimeSelectPlaceholderEnum.CHECKING;
    });
    builder.addCase(fetchUnannouncedEndDate.fulfilled, (state, action) => {
      state.alertsState = createWarningAlertState(action.payload.hints);
      state.times.end.loading = LoaderStatusEnum.SUCCESS;
      state.times.end.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder.addCase(fetchExecutionModes.fulfilled, (state, action) => {
      state.executionModes = action.payload;
    });
    builder.addCase(fetchUnannouncedPeriodDates.fulfilled, (state, action) => {
      state.alertsState = createWarningAlertState(action.payload.hints);
    });
    builder.addMatcher(isStartRangeDateFulfilled, (state, action) => {
      state.alertsState = createWarningAlertState(action.payload.hints);
      state.times.start.loading = LoaderStatusEnum.SUCCESS;
      state.times.start.range = action.payload;
      state.times.start.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder.addMatcher(isStartRangeDatePending, (state) => {
      state.alertsState = null;
      state.times.start.loading = LoaderStatusEnum.LOADING;
      state.times.start.placeholder = TimeSelectPlaceholderEnum.CALCULATING;
    });
    builder.addMatcher(isStartRangeDateRejected, (state, action) => {
      state.alertsState = createErrorAlertState(action.payload);
      state.times.start.loading = LoaderStatusEnum.ERROR;
      state.times.start.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder.addMatcher(isEndRangeDatePending, (state) => {
      state.alertsState = null;
      state.times.end.loading = LoaderStatusEnum.LOADING;
      state.times.end.placeholder = TimeSelectPlaceholderEnum.CALCULATING;
    });
    builder.addMatcher(isEndRangeDateRejected, (state, action) => {
      state.alertsState = createErrorAlertState(action.payload);
      state.times.end.loading = LoaderStatusEnum.ERROR;
      state.times.end.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder
      .addMatcher(isFetchingFulfilled, (state) => {
        state.loading = LoaderStatusEnum.SUCCESS;
      })
      .addMatcher(isFetchingRejected, (state, action) => {
        state.alertsState = createErrorAlertState(action.payload);
        state.loading = LoaderStatusEnum.ERROR;
      })
      .addMatcher(isFetchingPending, (state) => {
        state.alertsState = null;
        state.loading = LoaderStatusEnum.LOADING;
      });
  },
});

// actions
export const {
  resetRanges,
  resetFormOptionState,
  resetFormOptionsStateToCurrentStep,
} = auditAppointmentFormOptionsSlice.actions;

// selectors
export const selectModuleFormOptions = (state: RootState): ModuleIdentifier[] =>
  state[parentReducerName].formOptions.modules;

export const selectTypeFormOptions = (state: RootState): TypeIdentifier[] =>
  state[parentReducerName].formOptions.types;

export const selectKindFormOptions = (state: RootState): KindIdentifier[] =>
  state[parentReducerName].formOptions.kinds;

export const selectExecutionModesFormOptions = (
  state: RootState
): ExecutionModeIdentifier[] =>
  state[parentReducerName].formOptions.executionModes;

export const selectLoadingStatus = (state: RootState): LoaderStatusEnum =>
  state[parentReducerName].formOptions.loading;

export const selectAlertsState = (state: RootState): Nullable<AlertsState> =>
  state[parentReducerName].formOptions.alertsState;

export const selectTimeFormOptions = (state: RootState): TimeFormOptions =>
  state[parentReducerName].formOptions.times;

// reducer
export default auditAppointmentFormOptionsSlice.reducer;
