import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { alpha, styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Heading from 'components/atoms/Heading';
import { BLACK, SPANISH_GRAY, TEXT } from 'config/appColors';


interface Dense {
  $dense?: boolean;
}

const StyledCenteredContent = styled('div')<Dense>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  padding: ${({ $dense }) => ($dense ? '22px 170px' : '50px 170px')};
  background: ${alpha(BLACK, 0.04)};
`;

const StyledErrorIcon = styled(InfoOutlinedIcon)<Dense>`
  font-size: ${({ $dense }) => ($dense ? '2.4rem' : '5rem')};
  margin: 0;
  color: ${SPANISH_GRAY};
`;

const StyledResponseStatusHeading = styled(Heading)<Dense>`
  line-height: 2rem;
  letter-spacing: 0.04rem;
  font-weight: ${({ $dense }) => ($dense ? '400' : '500')};
  color: ${TEXT.PRIMARY};
  margin-left: 20px;
  && {
    font-size: ${({ $dense }) => ($dense ? '1.2rem' : '1.8rem')};
  }
`;

interface EmptyStateProps {
  heading?: string;
  customIcon?: ReactNode;
  className?: string;
  dense?: boolean;
}

export const EmptyState: FC<EmptyStateProps> = (props) => {
  const { heading, customIcon, className, dense } = props;

  const [t] = useTranslation('components');

  return (
    <StyledCenteredContent className={className} $dense={dense}>
      {customIcon || <StyledErrorIcon $dense={dense} />}
      <StyledResponseStatusHeading variant="h3" $dense={dense}>
        {heading || t('No Data Found')}
      </StyledResponseStatusHeading>
    </StyledCenteredContent>
  );
};
