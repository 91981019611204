import { Breadcrumbs as MuiBreadcrumbs, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { match as MatchType, useHistory } from 'react-router-dom';

import { SPANISH_GRAY } from 'config/appColors';
import { breadcrumbRoutes } from 'config/breadcrumbs';

// Types of the react-router-breadcrumbs-hoc library seem incorrect
// Custom defined BreadcrumbType for array below
interface BreadcrumbType {
  match: MatchType;
  breadcrumb: ReactNode;
}

const StyledSpan = styled('span')`
  color: ${SPANISH_GRAY};
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.018rem;
  cursor: pointer;
`;

export const Breadcrumbs = withBreadcrumbs(breadcrumbRoutes, {
  excludePaths: ['/:lang'],
})(({ breadcrumbs }) => {
  const { push } = useHistory();

  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      {(breadcrumbs as unknown as BreadcrumbType[]).map(
        ({ match, breadcrumb }) => (
          <StyledSpan
            key={match.url}
            onClick={() => push(match.url)}
            aria-hidden="true"
          >
            {breadcrumb}
          </StyledSpan>
        )
      )}
    </MuiBreadcrumbs>
  );
});

export default Breadcrumbs;
