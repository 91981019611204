import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PageHeader } from 'components/molecules/PageHeader';
import { Tabs } from 'components/organisms/Tabs';
import { TabItem } from 'components/organisms/Tabs/types';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { TEXT, WHITE } from 'config/appColors';
import { selectUserCompanyName } from 'domains/user/state/auth/slice';

import { DiaryAuditAppointmentsTab } from '../../components/organisms/DiaryAuditAppointmentsTab';
import { DiaryAuditRegistrationsTab } from '../../components/organisms/DiaryAuditRegistrationsTab';
// import { DiaryAuditMissingEntriesTab } from '../../components/organisms/DiaryAuditMissingEntriesTab';

const StyledPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;
const StyledPageHeaderWrapper = styled('div')`
  background: ${WHITE};
`;

const StyledTabContentWrapper = styled('div')`
  min-height: 500px;
  padding: 40px;
`;

const StyledPageHeader = styled(PageHeader)`
  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
    color: ${TEXT.PRIMARY};
  }
`;

export const PageDiary: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const certificationBodyName = useSelector(selectUserCompanyName);

  const tabs: TabItem[] = [
    {
      label: t('Audit Appointments'),
      content: (
        <StyledTabContentWrapper>
          <DiaryAuditAppointmentsTab />
        </StyledTabContentWrapper>
      ),
    },
    {
      label: t('Audit Registrations'),
      content: (
        <StyledTabContentWrapper>
          <DiaryAuditRegistrationsTab />
        </StyledTabContentWrapper>
      ),
    },
    // Missing entries tab is hidden right now. Please uncomment this code and its import when it should be visible again.
    // {
    //   label: t('Missing Entries'),
    //   content: (
    //     <StyledTabContentWrapper>
    //       <DiaryAuditMissingEntriesTab />
    //     </StyledTabContentWrapper>
    //   ),
    // },
  ];

  return (
    <TemplatePageDefault hidePageHeader>
      <StyledPageWrapper>
        <StyledPageHeaderWrapper>
          <StyledPageHeader
            title={t('Diary', { ns: 'components' })}
            details={certificationBodyName}
            hideBreadcrumbs
          />
        </StyledPageHeaderWrapper>
        <Tabs items={tabs} />
      </StyledPageWrapper>
    </TemplatePageDefault>
  );
};
