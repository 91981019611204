import { AttributeRow } from 'components/atoms/AttributeRow/types';
import { DynamicFormItem } from 'components/molecules/DynamicFormItems/types';
import { ProcessingStepsWidgetData } from 'components/molecules/WidgetProcessingStepsGeneral/ProcessingStepsWidget';

import { TranslatedText } from './utils';
import { ConfirmationSectionItem } from '../components/molecules/ConfirmationSection/types';

export enum InfoBoxTypeEnum {
  WITHDRAW_BOX = 'withdraw-box',
  SUSPENSION_BOX = 'suspension-box',
  REINSTATE_BOX = 'reinstate-box',
  EXCEPTIONAL_CIRCUMSTANCES_BOX = 'exceptional-circumstances-box',
}

export interface InfoBox {
  type: InfoBoxTypeEnum;
  props: {
    headerText: TranslatedText;
    messageText: TranslatedText;
    lockDateText?: TranslatedText;
    unlockDateText?: TranslatedText;
    lockHistoryLinkText: TranslatedText;
    noLink?: boolean;
  };
  children: null;
}

export enum TemplateCmpTypeEnum {
  ATTRIBUTE_TABLE = 'attribute-table',
  CONFIRMATION_SECTION = 'confirmation-section',
  FORM = 'form',
  WARNING_BOX = 'warning-box',
  WIDGET_PRODUCT_SCOPES_GENERAL = 'widget-product-scopes-general',
  WIDGET_TECH_SCOPES_GENERAL = 'widget-tech-scopes-general',
  WIDGET_PROCESSING_SERVICES_GENERAL = 'widget-processing-services-general',
}

interface ProductScope {
  type: 'productScope' | 'subProductScope' | 'subProductScopeOthers';
  uuid: string;
  text: TranslatedText;
  isText: boolean;
  childrenRequired: boolean;
  branchKey: string;
  hierarchyLevel: string;
  relationToProductScope: string;
  children: ProductScope[];
}

export interface WidgetProductScopesGeneralConfig {
  type: TemplateCmpTypeEnum.WIDGET_PRODUCT_SCOPES_GENERAL;
  props: {
    dataRef: string;
    id: string;
    type: 'product-scopes';
    name: TranslatedText;
    scopes: ProductScope[];
  };
}

interface TechScope {
  type: 'techScope';
  uuid: string;
  text: TranslatedText;
  branchKey: string;
}

export interface WidgetTechScopesGeneralConfig {
  type: TemplateCmpTypeEnum.WIDGET_TECH_SCOPES_GENERAL;
  props: {
    dataRef: string;
    id: string;
    type: 'tech-scopes';
    name: TranslatedText;
    scopes: TechScope[];
  };
}

export interface WidgetProcessingStepsGeneralConfig {
  type: TemplateCmpTypeEnum.WIDGET_PROCESSING_SERVICES_GENERAL;
  props: {
    dataRef: string;
    id: string;
    type: 'processing-steps';
    name: TranslatedText;
    scopes: ProcessingStepsWidgetData['scopes'];
  };
}

export interface AttributeConfig {
  type: TemplateCmpTypeEnum.ATTRIBUTE_TABLE;
  props: unknown;
  children: AttributeRow[];
}

export interface ConfirmationsInformationConfig {
  type: TemplateCmpTypeEnum.CONFIRMATION_SECTION;
  props: {
    instructionText: TranslatedText;
  };
  children: ConfirmationSectionItem[];
}

export interface WarningBoxChild {
  type: 'message';
  props: {
    messageText: TranslatedText;
    messageKey: string;
  };
  children: null;
}

export interface WarningBoxConfig {
  type: TemplateCmpTypeEnum.WARNING_BOX;
  props: {
    headerText: TranslatedText;
    allowedMessage: string[];
  };
  children: WarningBoxChild[];
}

export interface FormConfig {
  type: TemplateCmpTypeEnum.FORM;
  props: unknown;
  children: DynamicFormItem[];
}

export type TabChildrenComponent = AttributeConfig | InfoBox;

type NextButtonType = 'next' | 'submit' | 'close';
type BackButtonType = 'back';

export interface BtnConfig {
  enabled: boolean;
  btnText?: TranslatedText;
}

export interface NextAction extends BtnConfig {
  type?: NextButtonType;
}

export interface BackAction extends BtnConfig {
  type?: BackButtonType;
}

export interface ActionReasonOption {
  id: string;
  text: TranslatedText;
  requiredDescription: boolean;
}

export type DraftStepCmp =
  | AttributeConfig
  | ConfirmationsInformationConfig
  | FormConfig
  | WarningBoxConfig
  | WidgetProductScopesGeneralConfig
  | WidgetTechScopesGeneralConfig
  | WidgetProcessingStepsGeneralConfig;
