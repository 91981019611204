import { AxiosError } from 'axios';

import { UNEXPECTED_ERROR } from 'config/constants';

export const formatToAlerts = (errParam: unknown): string[] => {
  const err = errParam as AxiosError;
  const errors = err?.response?.data;

  if (errors) {
    if (Array.isArray(errors)) {
      return errors;
    }

    if (typeof errors === 'string') {
      return [errors];
    }
  }

  return [UNEXPECTED_ERROR];
};
