import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ERROR } from 'config/appColors';
import { useGetAuditDetailsActionsQuery } from 'domains/auditDetails/state/auditDetailsActions/api';
import { openAuditModal } from 'domains/auditDetails/state/modal/slice';
import { AuditDetailsModalEnum } from 'domains/auditDetails/state/modal/types';
import { AuditActionsEnum } from 'domains/auditDetails/types';
import { ActionDropdownOption } from 'types';

import { getAuditDraftInitUpdateDocsPathname } from '../utils/getAuditDraftInitUpdateDocsPathname';
import { getAuditFileUploadPathname } from '../utils/getAuditFileUploadPathname';

const dropdownItemStyles = {
  dropdownDeleteItemStyle: {
    color: `${ERROR.LIGHT}`,
  },
};

interface UseAuditDetailsDropdownType {
  showDropdown: boolean;
  options: ActionDropdownOption[];
}

export const useAuditDetailsDropdown = (
  auditUuid: string,
  supplierId: string
): UseAuditDetailsDropdownType => {
  const { push, location } = useHistory();
  const dispatch = useDispatch();
  const dispatchOpenAuditModal = (name: AuditDetailsModalEnum) =>
    dispatch(
      openAuditModal({
        name,
      })
    );

  const [t] = useTranslation('auditDetails');
  const { auditDetailsActions } = useGetAuditDetailsActionsQuery(auditUuid);

  const openAuditAmendmentFileUploadPage = () =>
    push(
      getAuditFileUploadPathname({
        supplierId,
        auditId: auditUuid,
      }),
      location.state
    );

  const openAuditDraftUpdateDocumentsPage = () =>
    push(
      getAuditDraftInitUpdateDocsPathname(supplierId, auditUuid),
      location.state
    );

  const options: ActionDropdownOption[] = [
    {
      label: t('Update audit/assessment via file'),
      onClickAction: openAuditAmendmentFileUploadPage,
      permission: AuditActionsEnum.AUDIT_AMENDMENT_VIA_FILE,
    },
    {
      label: t('Update documents only'),
      onClickAction: openAuditDraftUpdateDocumentsPage,
      permission: AuditActionsEnum.AUDIT_AMENDMENT_VIA_FILE,
    },
    // {
    //   label: t('Issue Group Certificate'),
    //   onClickAction: () => {
    //     dispatchOpenAuditModal(AuditDetailsModalEnum.ISSUE_GROUP_CERTIFICATE);
    //   },
    //   permission: AuditActionsEnum.AUDIT_ISSUE_GROUP_CERTIFICATE,
    // },
    {
      label: t('Change certification body'),
      onClickAction: () => {
        dispatchOpenAuditModal(AuditDetailsModalEnum.SWITCH_CB_MODAL);
      },
      permission: AuditActionsEnum.AUDIT_CB_SWITCH,
    },
    {
      label: t('Create release'),
      onClickAction: () => {
        dispatchOpenAuditModal(AuditDetailsModalEnum.CREATE_RELEASE);
      },
      permission: AuditActionsEnum.AUDIT_RELEASE_CREATE,
    },
    {
      label: t('Delete', { ns: 'components' }),
      sx: dropdownItemStyles.dropdownDeleteItemStyle,
      onClickAction: () => {
        dispatchOpenAuditModal(AuditDetailsModalEnum.DELETE_AUDIT);
      },
      permission: AuditActionsEnum.AUDIT_DELETE,
    },
  ].filter(({ permission }) => auditDetailsActions.includes(permission));

  const showDropdown = options.length > 0;

  return {
    showDropdown,
    options,
  };
};
