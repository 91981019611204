import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import {
  CreateCertificateDraftParams,
  UpdateCertificateDraftParams,
} from './types';
import { endpoints } from '../../config/endpoints';

const createCertificateDraftApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createCertificateDraft: build.mutation<
      string,
      CreateCertificateDraftParams
    >({
      query: (data) => ({
        url: endpoints.CREATE_CERTIFICATE_DRAFT,
        data,
        method: 'POST',
      }),
    }),
    updateCertificateDraft: build.mutation<
      string,
      UpdateCertificateDraftParams
    >({
      query: (data) => ({
        url: endpoints.CREATE_CERTIFICATE_DRAFT,
        data,
        method: 'POST',
        delayTime: 3000,
      }),
    }),
  }),
});

export const useCreateCertificateDraftMutation = () => {
  const [createCertificateDraft, mutation] =
    createCertificateDraftApi.useCreateCertificateDraftMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: CreateCertificateDraftParams) =>
    createCertificateDraft(params).unwrap();

  return {
    ...mutation,
    createCertificateDraft: requestMethod,
    status,
    error,
  };
};

export const useUpdateCertificateDraftMutation = () => {
  const [updateCertificateDraft, mutation] =
    createCertificateDraftApi.useUpdateCertificateDraftMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: UpdateCertificateDraftParams) =>
    updateCertificateDraft(params).unwrap();

  return {
    ...mutation,
    updateCertificateDraft: requestMethod,
    status,
    error,
  };
};
