import { useSelector } from 'react-redux';

import { AuditAppointmentActionsEnum } from '../config/actions';
import { selectAuditAppointmentDetailsActions } from '../state/details/slice';

interface UseIsPermittedToType {
  isPermittedTo: (action: AuditAppointmentActionsEnum) => boolean;
  isPermittedToOneOf: (actions: AuditAppointmentActionsEnum[]) => boolean;
}

export const useIsPermittedTo = (): UseIsPermittedToType => {
  const availableActions = useSelector(selectAuditAppointmentDetailsActions);

  const isPermittedTo = (action: AuditAppointmentActionsEnum) =>
    availableActions.includes(action);

  const isPermittedToOneOf = (actions: AuditAppointmentActionsEnum[]) =>
    actions.some((action) => availableActions.includes(action));

  return {
    isPermittedTo,
    isPermittedToOneOf,
  };
};
