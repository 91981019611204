import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Column,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { TranslatedText } from 'types';
import { formatDate } from 'utils/dates';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useGetCertificateAdditionalSupplierListQuery } from '../../../state/certificateGroupCertificateTab/api';
import { AdditionalSupplier } from '../../../state/certificateGroupCertificateTab/types';
import { StyledTable } from '../../atoms/StyledAdditionalDataTable';
import { StyledHeading } from '../../atoms/StyledHeading';
import { AdditionalSupplierActionsDropdown } from '../../molecules/AdditionalSupplierActionsDropdown';

interface AdditionalSuppliersTableProps {
  certificateUuid: string;
  headerText: TranslatedText;
}

export const AdditionalSuppliersTable: FC<AdditionalSuppliersTableProps> = (
  props
) => {
  const { certificateUuid, headerText } = props;
  const [t] = useTranslation('certificateDetails');

  const { pagination, limit, offset, setTotalRowsAmount } = usePagination();

  const { error, suppliers, total, status } =
    useGetCertificateAdditionalSupplierListQuery({
      certificateUuid,
      limit,
      offset,
    });

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const { getColumnConfig } = useColumnConfig('65%');

  const columns: Column<AdditionalSupplier>[] = [
    {
      headerName: t('Supplier', { ns: 'auditAppointment' }),
      customCell: ({ name, coid }) => `${name} (COID: ${coid})`,
      ...getColumnConfig(),
    },
    {
      headerName: t('added at'),
      customCell: ({ addedAt }) =>
        formatDate(new Date(addedAt), 'MMM d, yyyy, h:mm:ss aa'),
      ...getColumnConfig('23%'),
    },
    {
      headerName: '',
      customCell: (supplier) => (
        <AdditionalSupplierActionsDropdown
          supplier={supplier}
          certificateUuid={certificateUuid}
        />
      ),
      ...getColumnConfig('7%'),
    },
  ];

  return (
    <div data-testid="additional-suppliers-table">
      <StyledHeading>{getTranslatedValue(headerText)}</StyledHeading>
      <StyledTable
        columns={columns}
        rows={suppliers}
        status={status}
        error={error}
        pagination={pagination}
        dense
      />
    </div>
  );
};
