import { DateRange } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import React, { FC, forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

import { TextField, TextFieldProps } from '../TextField';

const StyledTextField = styled(TextField)`
  svg {
    font-size: 2rem;
    cursor: pointer;
  }
`;

interface MaskProps {
  value?: string;
  onChange: (value: string) => void;
}

const Mask = forwardRef<HTMLInputElement, MaskProps>(
  ({ value, onChange, ...restProps }, innerRef) => (
    <IMaskInput
      {...restProps}
      value={value}
      mask="00.00.0000"
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={innerRef}
      onAccept={onChange}
      overwrite
    />
  )
);

interface DateMaskedInputProps extends Omit<TextFieldProps, 'onChange'> {
  onChange?: (value: string) => void;
  showCalendarIcon?: boolean;
}

export const DateMaskedInput: FC<DateMaskedInputProps> = ({
  value,
  onChange,
  showCalendarIcon = false,
  ...props
}) => (
  <StyledTextField
    {...props}
    value={value}
    onChange={(event) => {
      if (typeof event?.target?.value === 'string') {
        onChange?.(event.target.value);
      }
    }}
    InputProps={{
      // assertion to any is taken from docs
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputComponent: Mask as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputProps: { value, onChange: onChange as any },
      ...(showCalendarIcon && {
        endAdornment: (
          <IconButton size="small">
            <DateRange />
          </IconButton>
        ),
      }),
    }}
  />
);
