import {
  REASON_CANCELLATION_BY_CUSTOMER,
  REASON_CB_CHANGE,
  REASON_CB_MISSED_DEADLINE,
  REASON_CONTRACT_CANCELLED,
  REASON_OTHER,
  REASON_TECHNICAL_ISSUE,
  REASON_WRONG_DATE,
} from 'config/actionReasons';
import { Option, ProcessStatus } from 'types';

export const parentReducerName = 'auditAppointment';
export const STATUSES: Option[] = [
  {
    label: ProcessStatus.OPEN,
    value: ProcessStatus.OPEN,
  },
  {
    label: ProcessStatus.COMPLETED,
    value: ProcessStatus.COMPLETED,
  },
  {
    label: ProcessStatus.CANCELLED,
    value: ProcessStatus.CANCELLED,
  },
  {
    label: ProcessStatus.DEACTIVATED,
    value: ProcessStatus.DEACTIVATED,
  },
];
export const DIARY_MIN_SEARCH_CHARS = 3;

const COMMON_OPTIONS = [REASON_TECHNICAL_ISSUE, REASON_CB_CHANGE, REASON_OTHER];

export const actionReasons = {
  CREATE_AUDIT_APPOINTMENT: {
    ADMIN: [REASON_CB_MISSED_DEADLINE, ...COMMON_OPTIONS],
  },
  CANCEL_AUDIT_APPOINTMENT: {
    ADMIN: [
      REASON_WRONG_DATE,
      REASON_CONTRACT_CANCELLED,
      REASON_CB_CHANGE,
      REASON_TECHNICAL_ISSUE,
      REASON_OTHER,
    ],
    CB_USER: [
      REASON_WRONG_DATE,
      REASON_CANCELLATION_BY_CUSTOMER,
      REASON_CONTRACT_CANCELLED,
      REASON_OTHER,
    ],
  },
  UPDATE_DESCRIPTION: {
    ADMIN: [REASON_WRONG_DATE, REASON_OTHER],
  },
  SET_DUE_DATE: {
    ADMIN: [REASON_CB_MISSED_DEADLINE, ...COMMON_OPTIONS],
  },
  RESET_DUE_DATE: {
    ADMIN: [REASON_WRONG_DATE, ...COMMON_OPTIONS],
  },
  ADD_BLACKOUT_TIME: {
    ADMIN: [REASON_CB_MISSED_DEADLINE, ...COMMON_OPTIONS],
  },
  DELETE_BLACKOUT_TIME: {
    ADMIN: [REASON_WRONG_DATE, ...COMMON_OPTIONS],
  },
  REACTIVATE_AUDIT_APPOINTMENT: {
    ADMIN: [REASON_TECHNICAL_ISSUE, REASON_OTHER],
  },
};

export const historicalAuditStatuses = [
  ProcessStatus.COMPLETED,
  ProcessStatus.CANCELLED,
  ProcessStatus.DEACTIVATED,
];
