import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { TEXT } from 'config/appColors';

interface DatePickerRowProps {
  label: string;
  children: ReactNode; // DatePicker
}

const StyledDatePickerRow = styled('div')`
  display: grid;
  gap: 30px;
  grid-template-columns: 2fr 4fr;

  > div {
    margin: 0 20px 10px 0;
  }
`;

const StyledLabel = styled('label')`
  font-size: 1.6rem;
  line-height: 2.8rem;
  letter-spacing: 0.015rem;
  font-weight: 400;
  color: ${TEXT.PRIMARY};
`;

export const DatePickerRow: FC<DatePickerRowProps> = ({ children, label }) => (
  <StyledDatePickerRow>
    <StyledLabel>{label}</StyledLabel>
    {children}
  </StyledDatePickerRow>
);
