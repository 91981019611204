import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  closeAndResetAuditModal,
  selectAuditDetailsModalName,
} from '../../../state/modal/slice';
import {
  AuditDetailsModalEnum,
  AuditModalCommonProps,
} from '../../../state/modal/types';
import { AuditCBChangeModal } from '../AuditCBChangeModal';
import { CreateReleaseModal } from '../CreateReleaseModal';
import { DeleteAuditModal } from '../DeleteAuditModal';
import { DeleteReleaseModal } from '../DeleteReleaseModal';
import { IssueGroupCertificateModal } from '../IssueGroupCertificateModal';
import { UpdateReleaseModal } from '../UpdateReleaseModal';

const MODALS: Record<AuditDetailsModalEnum, FC<AuditModalCommonProps>> = {
  [AuditDetailsModalEnum.CREATE_RELEASE]: CreateReleaseModal,
  [AuditDetailsModalEnum.DELETE_AUDIT]: DeleteAuditModal,
  [AuditDetailsModalEnum.DELETE_RELEASE]: DeleteReleaseModal,
  [AuditDetailsModalEnum.ISSUE_GROUP_CERTIFICATE]: IssueGroupCertificateModal,
  [AuditDetailsModalEnum.UPDATE_RELEASE]: UpdateReleaseModal,
  [AuditDetailsModalEnum.SWITCH_CB_MODAL]: AuditCBChangeModal,
};

export const ConditionalAuditModals: FC = () => {
  const { auditUuid, supplierId } =
    useParams<{ auditUuid: string; supplierId: string }>(); // @ToDo - unify type with AuditDetailsURLParams
  const openModalName = useSelector(selectAuditDetailsModalName);
  const modalIsOpen = !!openModalName;
  const dispatch = useDispatch();

  useEffect(
    () => {
      // clear previously opened modal on mount for case when user is leaving without clicking 'x'
      if (modalIsOpen) {
        dispatch(closeAndResetAuditModal());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (openModalName) {
    const Modal = MODALS[openModalName];

    return <Modal auditUuid={auditUuid} supplierId={supplierId} />;
  }

  return null;
};
