import React, { FC } from 'react';

import { StyledLink } from 'components/atoms/StyledLink';
import { CustomLink } from 'domains/widgets/components/atoms/CustomLink';
import { Supplier } from 'types';
import isIframe from 'utils/isIframe';

interface CustomerLinkProps {
  supplier: Supplier;
}

export const CustomerLink: FC<CustomerLinkProps> = ({ supplier }) => {
  const { coid, name, uuid } = supplier;
  const linkText = `(COID: ${coid}) ${name}`;

  return isIframe ? (
    <CustomLink redirectPayload={uuid}>{linkText}</CustomLink>
  ) : (
    <StyledLink to="#">{linkText}</StyledLink>
  );
};
