import { SaveTechScopesParams } from 'components/molecules/WidgetTechScopesGeneral/TechScopeWidget';
import { TranslatedText } from 'types';

import { MISSING_DATA_ERROR } from '../config/constants';

export const validateTechScopes =
  (elementId: string, value: SaveTechScopesParams['techScopesAudit']) =>
  (errors: Record<string, TranslatedText>): Record<string, TranslatedText> =>
    !value.length
      ? {
          ...errors,
          [elementId]: MISSING_DATA_ERROR,
        }
      : {};
