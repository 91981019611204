import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import React, { FC, MouseEvent } from 'react';

import { WHITE } from 'config/appColors';

interface SnackbarCloseButtonProps {
  onClick: (ev: MouseEvent<HTMLButtonElement>) => void;
}

const StyledCloseButton = styled('button')`
  color: ${WHITE};
  border: 0;
  cursor: pointer;
  background: 0;

  > svg {
    font-size: 2.2rem;
  }
`;

export const SnackbarCloseButton: FC<SnackbarCloseButtonProps> = (props) => {
  const { onClick } = props;

  return (
    <StyledCloseButton type="button" onClick={onClick}>
      <CloseIcon />
    </StyledCloseButton>
  );
};
