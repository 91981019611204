import {
  REASON_AUDIT_CORRECTION,
  REASON_CB_CHANGE,
  REASON_OTHER,
  REASON_TECHNICAL_ISSUE,
} from 'config/actionReasons';

import { CertificateDetailsModalEnum } from '../state/modal/types';

export const parentReducerName = 'certificateDetails';

export const CATEGORY_OTHERS_REASON = 'others';

export const COMMON_REASON_CATEGORIES = [
  {
    label: 'IFS Audit', // translated in useTranslatedSelectOptions
    value: 'ifs_audit',
  },
  {
    label: 'Payment',
    value: 'payment',
  },

  {
    label: 'General close down of company site/ no longer production/ move',
    value: 'general_close_company',
  },
  {
    label: 'Insolvency/ bankruptcy',
    value: 'insolvency',
  },

  {
    label: 'Voluntary withdrawal by client',
    value: 'voluntary_withdrawal_by_client',
  },
  {
    label: 'Force majeur (fire, earthquakes, ...)',
    value: 'force_majeur',
  },
  {
    label: 'CB change/ contractual issues',
    value: 'cb_change',
  },
  {
    label: 'Long term maintenance',
    value: 'long_term_maintenance',
  },
  {
    label: 'Substantial changes of processes and organization',
    value: 'substantial_changes_processes_organization',
  },
  {
    label: 'Food safety issue (public recall, authority order etc.)',
    value: 'food_safety_issue',
  },
  {
    label: 'Failed Food Safety Check',
    value: 'failed_fsc',
  },
  {
    label: 'Failed Integrity onsite check',
    value: 'Failed_integrity_onsite_check',
  },
  {
    label: 'Others',
    value: CATEGORY_OTHERS_REASON,
  },
  {
    label: 'Any other audit',
    value: 'other_audit',
  },
];

export const reasonCategories = {
  [CertificateDetailsModalEnum.LOCK_CERTIFICATE]: COMMON_REASON_CATEGORIES,
  [CertificateDetailsModalEnum.UPDATE_LOCK_REASON]: COMMON_REASON_CATEGORIES,
};

export const COMMON_ACTION_REASON_OPTIONS = [
  {
    label: 'Technical issue', // translated in useTranslatedSelectOptions
    value: REASON_TECHNICAL_ISSUE.value,
  },
  {
    label: 'CB Change',
    value: REASON_CB_CHANGE.value,
  },
  {
    label: 'Audit/assessment correction',
    value: REASON_AUDIT_CORRECTION.value,
  },
  {
    label: 'Other',
    value: REASON_OTHER.value,
  },
];

export const actionReasons = {
  [CertificateDetailsModalEnum.ADD_EXCEPTIONAL_CIRCUMSTANCE]:
    COMMON_ACTION_REASON_OPTIONS,
  [CertificateDetailsModalEnum.UNLOCK_CERTIFICATE]:
    COMMON_ACTION_REASON_OPTIONS,
  [CertificateDetailsModalEnum.CLOSE_EXCEPTIONAL_CIRCUMSTANCE]:
    COMMON_ACTION_REASON_OPTIONS,
  [CertificateDetailsModalEnum.LOCK_CERTIFICATE]: COMMON_ACTION_REASON_OPTIONS,
  [CertificateDetailsModalEnum.UPDATE_EXCEPTIONAL_CIRCUMSTANCE]:
    COMMON_ACTION_REASON_OPTIONS,
  [CertificateDetailsModalEnum.UPDATE_LOCK_REASON]:
    COMMON_ACTION_REASON_OPTIONS,
  [CertificateDetailsModalEnum.UPDATE_UNLOCK_REASON]:
    COMMON_ACTION_REASON_OPTIONS,
  [CertificateDetailsModalEnum.REMOVE_GROUP_CERTIFICATE]:
    COMMON_ACTION_REASON_OPTIONS,
  [CertificateDetailsModalEnum.REMOVE_CERTIFICATE]:
    COMMON_ACTION_REASON_OPTIONS,
};

export const MODAL_FORM_FIELD_MIN_CHARS = 5;

export const COMMON_CIRCUMSTANCE_TYPES = [
  {
    label: 'Pandemic situations', // translated in useTranslatedSelectOptions
    value: 'pandemic',
  },
  {
    label: 'Political conflicts',
    value: 'political_conflicts',
  },
  {
    label: 'Natural disaster',
    value: 'natural_disasters',
  },
  {
    label: 'Others',
    value: 'others',
  },
];

export const circumstanceTypes = {
  [CertificateDetailsModalEnum.ADD_EXCEPTIONAL_CIRCUMSTANCE]:
    COMMON_CIRCUMSTANCE_TYPES,
  [CertificateDetailsModalEnum.UPDATE_EXCEPTIONAL_CIRCUMSTANCE]:
    COMMON_CIRCUMSTANCE_TYPES,
};
