import { styled } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import React, { FC, ReactElement } from 'react';

const StyledDialog = styled(Dialog)`
  &&.MuiDialog-root {
    left: 250px;
    top: 55px;
    overflow: auto;
  }

  .MuiDialog-container {
    min-width: max-content;
  }

  .MuiBackdrop-root {
    left: 250px;
    top: 55px;
  }

  .MuiDialogTitle-root {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .MuiDialogContent-root {
    padding: 8px 24px;
  }

  .MuiDialogContentText-root {
    margin-bottom: 12px;
  }
`;

export const BaseModal: FC<DialogProps> = (props): ReactElement => (
  <StyledDialog {...props} />
);

export default BaseModal;
