import { WithUndefined } from 'types';

import { BaseQueryError, TAG_TYPES } from '../../store/apiSlice';

export type Tag = typeof TAG_TYPES[number];

type Resolver<T> = (arg: T) => Tag[];

export const invalidateRTKTags =
  <T>(successTagsOrResolver: Tag[] | Resolver<T>, errorTags: Tag[] = []) =>
  (_: unknown, error: WithUndefined<BaseQueryError>, arg: T): Tag[] => {
    if (error) {
      return errorTags;
    }

    if (Array.isArray(successTagsOrResolver)) {
      return successTagsOrResolver;
    }

    return successTagsOrResolver(arg);
  };
