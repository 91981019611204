import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from 'components/organisms/Tabs';

import { TemplateBasicPageContent } from '../../templates/TemplateBasicPageContent';
import { CompanyDataTabContacts } from '../CompanyDataTabContacts';
import { CompanyDataTabGeneral } from '../CompanyDataTabGeneral';

const StyledTemplateBasicPageContent = styled(TemplateBasicPageContent)`
  margin-top: -40px;
`;

export const CompanyDataTab: FC = () => {
  const [t] = useTranslation('supplier');

  return (
    <StyledTemplateBasicPageContent pageTitle={t('Company Data')}>
      <Tabs
        items={[
          {
            label: t('General'),
            content: <CompanyDataTabGeneral />,
          },
          {
            label: t('Contacts'),
            content: <CompanyDataTabContacts />,
          },
        ]}
      />
    </StyledTemplateBasicPageContent>
  );
};
