import React, { FC, Fragment } from 'react';

import { AttributeRow } from 'components/atoms/AttributeRow/types';
import { Logger } from 'components/atoms/Logger';
import { generateId } from 'utils/generateId';

import { attributeRowsMap } from './AttributeRowsMap';
import { ErrorBoundary } from '../ErrorBoundary';

interface AttributeTableProps {
  data?: Record<string, unknown>;
  rows?: AttributeRow[];
  className?: string;
}

const AttributeTable: FC<AttributeTableProps> = (props) => {
  const { data, rows, className } = props;
  if (!data || !rows) {
    return <Logger message="The data or template does not exist" />;
  }

  if (rows.some((row) => !attributeRowsMap[row.type])) {
    const unsupportedRowTypes = rows
      .filter((row) => !attributeRowsMap[row.type])
      .map((row) => row.type)
      .join(', ');

    console.error(
      `We encountered unsupported types of row ${unsupportedRowTypes}`
    );
  }
  return (
    <div className={className}>
      {rows.map((row, index) =>
        attributeRowsMap[row.type] ? (
          <Fragment key={generateId(row.type, index)}>
            {attributeRowsMap[row.type]({
              data,
              props: row.props,
              children: row.children,
            })}
          </Fragment>
        ) : null
      )}
    </div>
  );
};

export const AttributeTableWithErrorBoundary: FC<AttributeTableProps> = (
  props
) => (
  <ErrorBoundary staticPosition>
    <AttributeTable {...props} />
  </ErrorBoundary>
);
