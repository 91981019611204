import { styled } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';

import { TEXT, WHITE } from 'config/appColors';
import { GeneralDetails } from 'types';
import { generateId } from 'utils/generateId';
import { getTranslatedValue } from 'utils/getTranslatedValue';
import { isColorRgbRgbaHexFormat } from 'utils/isColorRgbRgbaHexFormat';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';
import { StatusIndicator } from '../StatusIndicator';

const StyledStatusIndicator = styled(StatusIndicator)`
  & + & {
    margin-left: 3px;
  }
`;

const isColorFormatValid = (color: string) => {
  const isValid = isColorRgbRgbaHexFormat(color);

  if (!isValid) {
    console.warn(
      "Invalid format of Badge's color - please use hexadecimal, rgb or rgba format"
    );
  }

  return isValid;
};

type Badge = GeneralDetails;

const isBadge = (badge: unknown): badge is Badge =>
  !!badge && 'id' in (badge as Badge) && 'name' in (badge as Badge);

export const isAttributeRowStatusBadgeProps = (
  props: unknown
): props is AttributeRowStatusBadgeProps =>
  'colorMapper' in (props as AttributeRowStatusBadgeProps);

const getColors = (colorMapper: Record<string, unknown>, id: string) => {
  const bgColor =
    typeof colorMapper[id] === 'string' &&
    isColorFormatValid(colorMapper[id] as string)
      ? (colorMapper[id] as string)
      : 'transparent';
  const textColor = bgColor === 'transparent' ? TEXT.PRIMARY : WHITE;

  if (bgColor === 'transparent') {
    console.warn(
      'colorMapper does not have matching key - color not specified'
    );
  }

  return { bgColor, textColor };
};

export interface AttributeRowStatusBadgeProps extends AttributeRowCommonProps {
  colorMapper: Record<string, unknown>;
  data: Record<string, unknown>;
  allowedValues?: string[];
}

export const AttributeRowStatusBadge: FC<AttributeRowStatusBadgeProps> = (
  props
) => {
  const { data, colorMapper, dataRef, labelText, allowedValues } = props;
  let value = get(data, dataRef);
  const isValidArrayOfBadges =
    Array.isArray(value) && value.every(isBadge) && value.length > 0;
  const isValidBadge = isBadge(value) && value.name.trim().length > 0;
  const showAttributeRow = isValidArrayOfBadges || isValidBadge;

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Badge data" />
    );
  }

  if (isValidBadge) {
    value = [value];
  }

  const filteredValues = allowedValues
    ? (value as Badge[]).filter(({ id }) => allowedValues.includes(id))
    : (value as Badge[]);

  if (filteredValues.length === 0) {
    return null;
  }

  const badgeWithValue = filteredValues.map(({ id, name }, index) => {
    const { bgColor, textColor } = getColors(colorMapper, id);
    return (
      <StyledStatusIndicator
        key={generateId(id, index)}
        bgColor={bgColor}
        textColor={textColor}
        label={name}
      />
    );
  });

  return (
    <AttributeRowBase
      label={getTranslatedValue(labelText)}
      value={badgeWithValue}
    />
  );
};
