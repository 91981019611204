import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { TEXT } from 'config/appColors';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 0 20px 10px;
`;

const StyledLabel = styled('label')`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 0.014rem;
  color: ${TEXT.PRIMARY};
`;

interface DatePickerContainerProps {
  label: string;
  children: ReactNode; // DatePicker
}

export const DatePickerContainer: FC<DatePickerContainerProps> = ({
  children, // DatePicker
  label,
}) => (
  <StyledContainer>
    <StyledLabel>{label}</StyledLabel>
    {children}
  </StyledContainer>
);
