import React, { FC, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectAuditPlanModule,
  selectAuditPlanType,
  setType,
} from '../../../state/create/slice';
import {
  fetchTypes,
  selectTypeFormOptions,
} from '../../../state/formOptions/slice';
import { setIsNextButtonDisabled } from '../../../state/stepperModal/slice';
import { FormWithRadio } from '../FormWithRadio';

export const FormSetType: FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation('auditAppointment');

  const types = useSelector(selectTypeFormOptions);
  const selectedType = useSelector(selectAuditPlanType);
  const selectedModule = useSelector(selectAuditPlanModule);

  useLayoutEffect(() => {
    if (selectedModule?.id && !types.length) {
      dispatch(fetchTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedModule]);

  useEffect(() => {
    const enabledOptions = types.filter((type) => type.enabled);

    if (!selectedType && enabledOptions.length === 1) {
      dispatch(setType(enabledOptions[0]));
      dispatch(setIsNextButtonDisabled(false));
    }
  }, [types.length, dispatch, selectedType, types]);

  const handleTypeChange = (typeId: string) => {
    const newSelectedType = types.find((type) => type.id === typeId);
    if (newSelectedType) {
      dispatch(setType(newSelectedType));
      dispatch(setIsNextButtonDisabled(false));
    }
  };

  return (
    <FormWithRadio
      title={t('Type')}
      description={t('Please select the audit type.')}
      ariaLabel={t('Audit Type')}
      options={types}
      value={selectedType?.id || ''}
      onChange={handleTypeChange}
    />
  );
};
