import React, { FC } from 'react';

import { CertificateGroupCertificateTab } from '../../../types';
import { AdditionalAuditsTable } from '../AdditionalAuditsTable';
import { AdditionalSuppliersTable } from '../AdditionalSuppliersTable';

type LockHistoryTabProps = CertificateGroupCertificateTab['props'] & {
  certificateUuid: string;
};

export const GroupCertificateTab: FC<LockHistoryTabProps> = (props) => {
  const { certificateUuid, headerTextSuppliers, headerTextAudits } = props;

  return (
    <>
      <AdditionalSuppliersTable
        certificateUuid={certificateUuid}
        headerText={headerTextSuppliers}
      />
      <AdditionalAuditsTable
        certificateUuid={certificateUuid}
        headerText={headerTextAudits}
      />
    </>
  );
};
