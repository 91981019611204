import * as yup from 'yup';

import {
  NestedGeneralDetails,
  StartEndDates,
  StartEndDatesSuffixedType,
} from 'types';
import { PLAIN_DATE_REGEX_WITH_HYPHENS } from 'utils/regex/date';

export const generalDetailsSchema = yup.object({
  id: yup.string().required(),
  name: yup.string().required(),
});

export const dateSchema = yup.string().matches(PLAIN_DATE_REGEX_WITH_HYPHENS);

export const startEndDatesSchema: yup.ObjectSchema<StartEndDates> = yup.object({
  start: dateSchema.required(),
  end: dateSchema.required(),
});

export const prefixedStartEndDatesSchema: yup.ObjectSchema<StartEndDatesSuffixedType> =
  yup.object({
    startDate: dateSchema.required(),
    endDate: dateSchema.required(),
    auditTimeInMinutes: yup.number().optional(),
  });

export const enumSchema = <EnumLike extends Record<string, string>>(
  rec: EnumLike
) =>
  yup
    .mixed<typeof rec[keyof typeof rec]>()
    .oneOf(Object.values(rec) as typeof rec[keyof typeof rec][]);

export const generalDetailsWithEnumSchema = <
  EnumLike extends Record<string, string>
>(
  rec: EnumLike
) =>
  yup
    .object({
      id: enumSchema(rec).required(),
      name: yup.string().required(),
    })
    .required();

export const nestedGeneralDetailsSchema =
  (): yup.ObjectSchema<NestedGeneralDetails> =>
    yup.object().shape({
      id: yup.string().required(),
      name: yup.string().required('Name is required'),
      children: yup
        .mixed<NestedGeneralDetails[]>()
        .test((value) => {
          if (!value) return false;
          if (value.length === 0) return true;
          return yup
            .array()
            .of(nestedGeneralDetailsSchema())
            .isValidSync(value);
        })
        .required(),
    });

export const paginationWrapperSchema = (
  schema: yup.AnyObjectSchema,
  key: string
) =>
  yup.object({
    total: yup.number().required(),
    [key]: yup.array().of(schema).required(),
  });

// empty string doesn't pass native yup validation - customStringSchema allows to use/get empty string
export const customStringSchema = yup
  .mixed<string>()
  .test((value) => typeof value === 'string');
