import { styled } from '@mui/material';
import React from 'react';

import { shortenText } from 'utils/shortenText';

import { AdminTableCell } from '../../../types';
import { DescriptionTooltip } from '../../atoms/DescriptionTooltip';
import { StyledPlainText as PlainText } from '../../atoms/StyledPlainText';

const StyledPlainText = styled(PlainText)`
  line-height: 2rem;
`;

export const ScopesDescriptionCell: AdminTableCell = ({
  scopeDescriptionAudit_en = '',
  scopeDescriptionAudit = '',
  t,
}) => {
  const showTooltip =
    scopeDescriptionAudit_en.length >= 150 ||
    scopeDescriptionAudit.length >= 150;

  const getContent = (shorten = false) => (
    <>
      <StyledPlainText>
        {shorten
          ? shortenText(scopeDescriptionAudit_en)
          : scopeDescriptionAudit_en}
      </StyledPlainText>
      <br />
      <StyledPlainText>
        {shorten ? shortenText(scopeDescriptionAudit) : scopeDescriptionAudit}
      </StyledPlainText>
    </>
  );

  return showTooltip ? (
    <DescriptionTooltip
      title={t('Scope Description')}
      tooltipContent={getContent()}
    >
      <div>{getContent(true)}</div>
    </DescriptionTooltip>
  ) : (
    getContent(true)
  );
};
