import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { usePagination } from 'components/organisms/Table';
import { TablePagination } from 'components/organisms/Table/TablePagination';

import { AllAuditsTableBody } from './AllAuditsTableBody';
import { AllAuditsTableButtons } from './AllAuditsTableButtons';
import { AllAuditsTableHead } from './AllAuditsTableHead';
import { useFiltersAndSorting } from '../../../hooks/useFiltersAndSorting';
import { useGetAdminAuditListQuery } from '../../../state/allAuditsList/api';
import { resetAllAuditsListState } from '../../../state/allAuditsList/slice';
import { AdminTableTemplate } from '../../templates/AdminTableTemplate';

export const AllAuditsTable: FC = () => {
  const {
    pagination,
    limit,
    offset,
    setTotalRowsAmount,
    resetPaginationState,
  } = usePagination({
    enabledQueryParams: true,
    initialRowsPerPage: 50,
  });
  const dispatch = useDispatch();

  const { URLFilters, URLSortState, filtersReadyToFetch } =
    useFiltersAndSorting({
      resetPaginationState,
    });

  const { auditList, total, status, getAllAudits } =
    useGetAdminAuditListQuery();

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  useEffect(
    () => () => {
      // reset state on unmount
      dispatch(resetAllAuditsListState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (filtersReadyToFetch) {
      getAllAudits(
        {
          filter: URLFilters,
          limit,
          offset,
          ...(URLSortState
            ? { sort: `${URLSortState.key}:${URLSortState.direction}` }
            : {}),
        },
        true
      );
    }
  }, [
    getAllAudits,
    URLFilters,
    URLSortState,
    limit,
    offset,
    filtersReadyToFetch,
  ]);

  return (
    <div>
      <AllAuditsTableButtons />
      <AdminTableTemplate
        thead={<AllAuditsTableHead />}
        tbody={<AllAuditsTableBody data={auditList} />}
        tfoot={<TablePagination {...pagination} outsideOfTable />}
        state={status}
      />
    </div>
  );
};
