import { createAsyncThunk, createSlice, isFulfilled } from '@reduxjs/toolkit';
import axios from 'axios';
import { validate as validateUUID } from 'uuid';

import { UNEXPECTED_ERROR, UNEXPECTED_ERROR_EXTENDED } from 'config/constants';
import type { RootState } from 'store';
import { startAppListening } from 'store/listenerMiddleware';
import { ExtendedError, LoaderStatusEnum, Nullable } from 'types';
import apiClient from 'utils/apiClient';
import { getSupplierPathname } from 'utils/getSupplierPathname';
import { handleForbiddenError } from 'utils/handleForbiddenError';

import { AuditAppointmentDetailsState } from './types';
import { AuditAppointmentActionsEnum } from '../../config/actions';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';
import { AuditAppointment } from '../../types';
import { cancelAuditAppointment } from '../cancel/slice';
import { resetDueDate, setDueDate } from '../dueDate/slice';

const initialState: AuditAppointmentDetailsState = {
  data: null,
  detailsLoading: LoaderStatusEnum.IDLE,
  actionsLoading: LoaderStatusEnum.LOADING,
  error: null,
  actions: [],
};

const reducerName = `${parentReducerName}/details`;

// thunks
export const getAuditAppointmentActions = createAsyncThunk(
  `${reducerName}/actions`,
  async (appointmentId: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(endpoints.ACTIONS(appointmentId));

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAuditAppointment = createAsyncThunk(
  `${reducerName}/data`,
  async (appointmentId: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(endpoints.DETAILS(appointmentId));
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errCode = err?.response?.status;
        const errText = err?.response?.statusText;

        if (errCode === 403) {
          // parse url to get supplier id
          const url = window.location.href;
          const splittedUrl = url.split('/');
          const parsedSupplierId =
            (splittedUrl[4] === 'suppliers' && splittedUrl[5]) || '';
          const idIsValid = validateUUID(parsedSupplierId);

          handleForbiddenError(
            (idIsValid && getSupplierPathname(parsedSupplierId)) || ''
          );
        }

        return rejectWithValue({
          message: UNEXPECTED_ERROR_EXTENDED,
          details: `${errCode} - ${errText}`,
        });
      }
      return rejectWithValue({
        message: UNEXPECTED_ERROR,
      });
    }
  }
);

// slice
export const auditAppointmentDetailsSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetAuditAppointmentDetailsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAuditAppointment.pending, (state) => {
      state.detailsLoading = LoaderStatusEnum.LOADING;
    });
    builder.addCase(getAuditAppointment.fulfilled, (state, action) => {
      state.data = action.payload as AuditAppointment;
      state.detailsLoading = LoaderStatusEnum.SUCCESS;
      state.error = null;
    });
    builder.addCase(getAuditAppointment.rejected, (state, action) => {
      state.detailsLoading = LoaderStatusEnum.ERROR;
      state.error = action.payload as ExtendedError;
    });
    builder.addCase(getAuditAppointmentActions.fulfilled, (state, action) => {
      state.actions = action.payload;
      state.actionsLoading = LoaderStatusEnum.SUCCESS;
    });
    builder.addCase(getAuditAppointmentActions.pending, (state) => {
      state.actions = [];
      state.actionsLoading = LoaderStatusEnum.LOADING;
    });
    builder.addCase(getAuditAppointmentActions.rejected, (state) => {
      state.actionsLoading = LoaderStatusEnum.ERROR;
    });
  },
});

// actions
export const { resetAuditAppointmentDetailsState } =
  auditAppointmentDetailsSlice.actions;

// selectors
export const selectAuditAppointmentDetails = (
  state: RootState
): Nullable<AuditAppointment> => state[parentReducerName].details.data;
export const selectAuditAppointmentDetailsLoading = (
  state: RootState
): LoaderStatusEnum => state[parentReducerName].details.detailsLoading;
export const selectFinalAuditDetailAndActionsLoading = (
  state: RootState
): LoaderStatusEnum => {
  const { SUCCESS, LOADING, ERROR } = LoaderStatusEnum;
  const isDetailsLoading = selectAuditAppointmentDetailsLoading(state);
  const isActionsLoading = state[parentReducerName].details.actionsLoading;
  const statuses = [isDetailsLoading, isActionsLoading];

  if (statuses.includes(ERROR)) {
    return ERROR;
  }

  if (statuses.includes(LOADING)) {
    return LOADING;
  }

  return SUCCESS;
};
export const selectAuditAppointmentDetailsError = (
  state: RootState
): Nullable<ExtendedError> => state[parentReducerName].details.error;
export const selectAuditAppointmentDetailsActions = (
  state: RootState
): AuditAppointmentActionsEnum[] => state[parentReducerName].details.actions;

// reducer
export default auditAppointmentDetailsSlice.reducer;

// listeners
startAppListening({
  matcher: isFulfilled(
    getAuditAppointment,
    cancelAuditAppointment,
    setDueDate,
    resetDueDate
  ),
  effect: (_, { dispatch, getState }) => {
    const auditAppointmentDetails = getState().auditAppointment.details.data;

    if (auditAppointmentDetails?.uuid) {
      dispatch(getAuditAppointmentActions(auditAppointmentDetails.uuid));
    }
  },
});
