import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';

import {
  selectAuditPlanKind,
  selectAuditPlanType,
} from '../../../state/create/slice';
import { selectAlertsState } from '../../../state/formOptions/slice';
import {
  AuditAppointmentKindEnum,
  AuditAppointmentTypeEnum,
} from '../../../types';
import { StepAlertList } from '../../atoms/StepAlertList';
import { SetAnnouncedTime } from '../SetAnnouncedTime';
import { SetUnannouncedRecertification } from '../SetUnannouncedRecertification';
import { SetUnannouncedTimeInitial } from '../SetUnannouncedTimeInitial';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledStepDescription = styled(StepDescription)`
  margin: 15px 0 20px 10px;
  display: inline-block;
  max-width: 500px;
`;

export const FormSetTime: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const selectedType = useSelector(selectAuditPlanType);
  const selectedKind = useSelector(selectAuditPlanKind);
  const isAnnounced = selectedKind?.id === AuditAppointmentKindEnum.ANNOUNCED;
  const isInitial = selectedType?.id === AuditAppointmentTypeEnum.INITIAL;
  const isRecertification =
    selectedType?.id === AuditAppointmentTypeEnum.RECERTIFICATION;
  const alertsState = useSelector(selectAlertsState);
  const showAlerts = alertsState?.alerts && alertsState.alerts.length > 0;
  const unannouncedAndRecertification = !isAnnounced && isRecertification;
  const descriptionContent = unannouncedAndRecertification
    ? t(
        'The system will calculate the start and end date for the unannounced period.'
      )
    : t('Please select the start and end date of the Audit.');

  return (
    <StyledContainer>
      <StepFormTitle content={t('Time')} />
      {showAlerts && <StepAlertList {...alertsState} />}
      <StyledStepDescription content={descriptionContent} />
      {isAnnounced && <SetAnnouncedTime />}
      {!isAnnounced && isInitial && <SetUnannouncedTimeInitial />}
      {unannouncedAndRecertification && <SetUnannouncedRecertification />}
    </StyledContainer>
  );
};
