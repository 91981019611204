import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useModalCircumstanceRequiredFields } from '../../../hooks/useModalCircumstanceRequiredFields';
import { useAddExceptionalCircumstanceMutation } from '../../../state/exceptionalCircumstance/api';
import { CertificateModalCommonProps } from '../../../types';
import {
  CertificateDetailsModalBase,
  NextAction,
} from '../../templates/CertificateDetailsModalBase';
import { ExceptionalCircumstanceModalForm } from '../ExceptionalCircumstanceModalForm';

type AddExceptionalCircumstanceModalProps = CertificateModalCommonProps;

export const AddExceptionalCircumstanceModal: FC<AddExceptionalCircumstanceModalProps> =
  (props) => {
    const { certificateUuid } = props;
    const [t] = useTranslation('certificateDetails');
    const { addExceptionalCircumstance, isLoading, error } =
      useAddExceptionalCircumstanceMutation();
    const { fieldValues, isFormValid } = useModalCircumstanceRequiredFields();

    const nextAction: NextAction = (openSnackbar, closeModal) => {
      addExceptionalCircumstance({
        certificateUuid,
        ...fieldValues,
      }).then(() => {
        openSnackbar(t('The exceptional circumstance is added.'), 'success');
        closeModal();
      });
    };

    return (
      <CertificateDetailsModalBase
        title={t('Add exceptional circumstances')}
        nextColor="primary"
        nextAction={nextAction}
        disableNext={!isFormValid}
        isLoading={isLoading}
      >
        <ExceptionalCircumstanceModalForm errors={error} />
      </CertificateDetailsModalBase>
    );
  };
