import { styled } from '@mui/material';
import React, { FC, Fragment } from 'react';

import { ImageLoader } from 'components/atoms/ImageLoader';
import { Logger } from 'components/atoms/Logger';
import { AttributeTable } from 'components/molecules/AttributeTable';
import { Banner } from 'components/molecules/Banner';
import { Loader } from 'components/molecules/Loader';
import { CertificateDetailsBanner } from 'domains/certificateDetails/components/molecules/CertificateDetailsBanner';
import { FileDetailsTable } from 'domains/certificateDetails/components/organisms/FileDetailsTable';
import { useGetCertificateDetailsQuery } from 'domains/certificateDetails/state/certificateDetails/api';
import { useGetCertificateDetailsDocumentsQuery } from 'domains/certificateDetails/state/certificateDocuments/api';
import { InfoBox, InfoBoxTypeEnum, TemplateCmpTypeEnum } from 'types';
import { checkIfEnumValueExists } from 'utils/checkIfEnumValueExists';
import { generateId } from 'utils/generateId';
import { getModuleLogoSrc } from 'utils/getModuleLogoSrc';

import { useCertificateTabBanner } from '../../../hooks/useCertificateTabBanner';
import { useGetAuditDetailsQuery } from '../../../state/auditDetails/api';
import { AuditCertificateTab } from '../../../types';
import { TabSectionHeading } from '../../atoms/TabSectionHeading';

const StyledContainer = styled('div')`
  width: 100%;
  position: relative;
`;

const StyledTabSectionHeading = styled(TabSectionHeading)`
  margin-bottom: 20px;
`;

const StyledAttributeTable = styled(AttributeTable)`
  margin-left: 10px;
  margin-bottom: 30px;
`;

const StyledImageWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
`;

type CertificateTabProps = AuditCertificateTab['props'] & {
  children: AuditCertificateTab['children'];
  auditUuid: string;
};

export const CertificateTab: FC<CertificateTabProps> = (
  props: CertificateTabProps
) => {
  const {
    children,
    headerTextDetails,
    headerTextDocuments,
    auditUuid,
    logoResolver,
  } = props;

  const { isSuccess: isAuditDetailsSuccess, auditDetails } =
    useGetAuditDetailsQuery({
      auditUuid,
      // auditVersion: 2 // TODO: it's going to be handled in the future
    });

  const skipCertificateRequests =
    !isAuditDetailsSuccess || !auditDetails?.certificate?.id;
  const certificateUuid = auditDetails?.certificate?.id || '';

  const {
    status: certificateDetailsStatus,
    certificateDetails,
    httpErrorStatusCode,
  } = useGetCertificateDetailsQuery(
    {
      certificateUuid,
      // certificateVersion: 2 // TODO: it's going to be handled in the future
    },
    skipCertificateRequests
  );
  const {
    status: certificateDocumentsStatus,
    error,
    certificateDocuments,
  } = useGetCertificateDetailsDocumentsQuery(
    { certificateUuid },
    skipCertificateRequests
  );

  const bannerConfig = useCertificateTabBanner(
    auditDetails?.certificate,
    httpErrorStatusCode
  );

  const attributeTableData = {
    audit: auditDetails,
    certificate: certificateDetails,
  };

  return (
    <Loader status={certificateDetailsStatus} minHeight="200px">
      <StyledContainer>
        {bannerConfig ? (
          <Banner
            variant={bannerConfig.variant}
            description={bannerConfig.label}
            hideCloseButton
          />
        ) : (
          <>
            <StyledImageWrapper>
              {certificateDetails && (
                <ImageLoader
                  src={getModuleLogoSrc(certificateDetails, logoResolver)}
                  width="150px"
                  height="100px"
                />
              )}
            </StyledImageWrapper>
            {children.map((childConfig, index) => {
              if (checkIfEnumValueExists(InfoBoxTypeEnum, childConfig.type)) {
                return (
                  <CertificateDetailsBanner
                    key={childConfig.type}
                    certificateUuid={certificateUuid}
                    config={childConfig as InfoBox}
                  />
                );
              }
              if (childConfig.type === TemplateCmpTypeEnum.ATTRIBUTE_TABLE) {
                return (
                  <Fragment key={childConfig.type}>
                    <StyledTabSectionHeading text={headerTextDetails} />
                    <StyledAttributeTable
                      rows={childConfig.children}
                      data={attributeTableData}
                    />
                  </Fragment>
                );
              }
              /**
               * we don't expect other types of children here;
               * however once we have additional types we need to handle them here
               */

              return (
                <Logger
                  key={generateId(childConfig.type, index)}
                  message={`Unsupported child type - ${childConfig.type}.`}
                />
              );
            })}
            {certificateDetails && (
              <>
                <StyledTabSectionHeading text={headerTextDocuments} />
                <FileDetailsTable
                  certificateUuid={certificateDetails.uuid}
                  documents={certificateDocuments}
                  loadingStatus={certificateDocumentsStatus}
                  error={error}
                />
              </>
            )}
          </>
        )}
      </StyledContainer>
    </Loader>
  );
};
