import { styled } from '@mui/material';
import React, { FC } from 'react';

import { StatusIndicatorWithIcon } from 'components/atoms/StatusIndicator';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { auditTags } from '../../../config/auditTags';
import { AdminAudit } from '../../../types';
import { DescriptionTooltip, TooltipBoundaryEnum } from '../DescriptionTooltip';

interface AuditTagProps extends AdminAudit {
  tag: string;
}

const StyledTagWrapper = styled('div')`
  margin-bottom: 4px;

  span {
    white-space: nowrap;
  }
`;

const descriptionFieldNames = [
  {
    type: 'outsourced-production',
    values: [
      'outsourcedProcessesProductsDescription_en',
      'outsourcedProcessesProductsDescription',
    ],
  },
  {
    type: 'exclusions',
    values: ['exclusionsDescription_en', 'exclusionsDescription'],
  },
  {
    type: 'fsma',
    values: ['fsmaDescription_en', 'fsmaDescription'],
  },
] as const;

export const AuditTag: FC<AuditTagProps> = ({
  certificate,
  tag,
  ...restProps
}) => {
  const iconData = auditTags.find((option) => option.id === tag);

  if (!iconData) {
    return null;
  }

  const objectWithDescriptions = certificate?.tags ? certificate : restProps;

  const description = descriptionFieldNames
    .find(({ type }) => type === iconData.id)
    ?.values.map((descName) => objectWithDescriptions[descName]);

  const descriptionWithoutEmptyValues = (description || []).filter(
    (desc) => desc
  );
  const showTooltip = descriptionWithoutEmptyValues.length > 0;
  const title = getTranslatedValue(iconData.text);

  const tagButton = (
    <StyledTagWrapper>
      <StatusIndicatorWithIcon iconData={iconData} />
    </StyledTagWrapper>
  );

  return showTooltip ? (
    <DescriptionTooltip
      title={title}
      tooltipContent={
        <div>
          {descriptionWithoutEmptyValues.map((desc, descIndex) => (
            <p key={`${desc?.slice(0, 10 + descIndex)}`}>{desc}</p>
          ))}
        </div>
      }
      tooltipPadding={{ top: 21, left: 38 }}
      boundary={TooltipBoundaryEnum.BUTTON}
    >
      {tagButton}
    </DescriptionTooltip>
  ) : (
    <>{tagButton}</>
  );
};
