import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';

import { ReactivateAuditAppointmentState } from './types';
import { parentReducerName } from '../../config/constants';

const initialState: ReactivateAuditAppointmentState = {
  isModalOpen: false,
};

const reducerName = `${parentReducerName}/reactivate`;

// slice
export const reactivateAuditAppointmentSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setReactivateAuditAppointmentIsModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isModalOpen = action.payload;
    },
  },
});

// actions
export const { setReactivateAuditAppointmentIsModalOpen } =
  reactivateAuditAppointmentSlice.actions;

// selectors
export const selectReactivateAuditAppointmentIsModalOpen = (
  state: RootState
): boolean => state[parentReducerName].reactivate.isModalOpen;

// reducer
export default reactivateAuditAppointmentSlice.reducer;
