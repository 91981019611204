import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import WarningIcon from 'components/atoms/WarningIcon';

import { AdminAudit, SupportedMessageEnum } from '../../../types';
import { DateWarningTooltipContent } from '../DateWarningTooltipContent';
import {
  DescriptionTooltip,
  TooltipTitleVariantEnum,
} from '../DescriptionTooltip';
import { StyledPlainText } from '../StyledPlainText';

const StyledWarningIcon = styled(WarningIcon)`
  margin-right: 10px;
`;

interface DateWarningTooltipProps {
  date: string;
  auditProps: AdminAudit;
  supportedMessages: SupportedMessageEnum[];
}

export const DateWarningTooltip: FC<DateWarningTooltipProps> = (props) => {
  const [t] = useTranslation('adminReports');
  const { date, auditProps, supportedMessages } = props;
  const { messages = [] } = auditProps;

  const filteredMessages = messages.filter((message) =>
    supportedMessages.includes(message.id as SupportedMessageEnum)
  );

  const showWarning = filteredMessages.length > 0;
  const dateLabel = (
    <StyledPlainText>
      {showWarning && <StyledWarningIcon />}
      {date}
    </StyledPlainText>
  );

  const tooltipContent = (
    <>
      {filteredMessages.map((message) => (
        <DateWarningTooltipContent
          key={message.id}
          message={message}
          auditProps={auditProps}
        />
      ))}
    </>
  );

  return showWarning ? (
    <DescriptionTooltip
      title={t('Warning', { ns: 'components' })}
      titleVariant={TooltipTitleVariantEnum.WARNING}
      tooltipContent={tooltipContent}
      tooltipPadding={{ top: 26, left: 20 }}
      width={290}
    >
      {dateLabel}
    </DescriptionTooltip>
  ) : (
    dateLabel
  );
};
