import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import React, { FC } from 'react';

type LoaderIconProps = CircularProgressProps;

export const LoaderIcon: FC<LoaderIconProps> = (props) => {
  const { color = 'primary' } = props;

  return <CircularProgress {...props} color={color} />;
};

export default LoaderIcon;
