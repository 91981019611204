import { alpha, styled } from '@mui/material';
import MuiTextField, {
  StandardTextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import React, { FC, ReactElement } from 'react';

import { BLACK, TEXT } from 'config/appColors';

export interface TextAreaProps extends MuiTextFieldProps {
  inputPlaceholder?: string;
  maxLength?: number;
}

const StyledTextarea = styled(MuiTextField)`
  label {
    position: static;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: 0.015rem;
    text-align: left;
    color: ${TEXT.PRIMARY};
    padding: 0;
    transform: none;
    margin-bottom: 10px;

    &.Mui-disabled.MuiInputLabel-root {
      color: ${TEXT.PRIMARY};
    }
  }

  > div {
    padding: 0;

    textarea {
      padding: 1.2rem 1.6rem 1.2rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.015rem;
      font-weight: 400;
      color: ${TEXT.PRIMARY};
      border: 1px solid ${alpha(BLACK, 0.23)};
      border-radius: 4px;

      &::placeholder {
        color: ${TEXT.SECONDARY_LIGHT};
      }
    }

    fieldset {
      display: none;
    }

    &.Mui-focused > textarea {
      border-color: ${TEXT.PRIMARY};
    }
  }

  &&& > div > {
    textarea {
      &::placeholder {
        opacity: 1 !important; // necessary because default styles use !important too
      }

      &.Mui-disabled {
        border-style: dotted;
        -webkit-text-fill-color: ${TEXT.DISABLED}; // necessary because this has precedence over color and MUI uses this
      }
    }
  }
`;

export const Textarea: FC<TextAreaProps> = (props): ReactElement => {
  const { multiline, inputPlaceholder, maxLength, rows, ...rest } = props;

  return (
    <StyledTextarea
      {...rest}
      multiline={multiline}
      inputProps={{
        placeholder: inputPlaceholder,
        maxLength,
      }}
      rows={rows}
      minRows={multiline && !rows ? 3 : undefined}
      variant="outlined"
    />
  );
};
