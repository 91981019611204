import { compareAsc, compareDesc } from 'date-fns';

import { dateSchema } from 'schemas';
import { Nullable, WithUndefined } from 'types';

import { extractDate } from './extractDate';

type FindDate = (
  dates: WithUndefined<Nullable<string | Date>>[],
  option: 'latest' | 'earliest'
) => WithUndefined<Date>;

export const findDate: FindDate = (dates, option) => {
  const datesToProcess = dates
    .map((date) => {
      if (typeof date === 'string' && dateSchema.isValidSync(date)) {
        return new Date(extractDate(date));
      }
      return date;
    })
    .filter((date) => date instanceof Date) as Date[];

  if (datesToProcess.length === 0) {
    return undefined;
  }

  const compareFunction = option === 'latest' ? compareAsc : compareDesc;

  return datesToProcess.reduce<Date>(
    (result, current) =>
      compareFunction(current, result) === -1 ? result : current,
    datesToProcess[0]
  );
};
