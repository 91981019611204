import { styled } from '@mui/material';

import { BaseModalContent } from 'components/organisms/BaseModalContent';
import { TEXT } from 'config/appColors';

const StyledBaseModalContent = styled(BaseModalContent)`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  color: ${TEXT.PRIMARY};

  .MuiDivider-root {
    margin-top: 30px;
  }

  .AlertList {
    div:first-child {
      margin-top: 0;
    }
  }
`;

StyledBaseModalContent.displayName = 'StyledBaseModalContent';

export { StyledBaseModalContent };
