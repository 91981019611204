import { CertificateVariantEnum } from '../../types';

export enum RequestModeEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

export interface CreateCertificateDraftParams {
  mode: RequestModeEnum.CREATE;
  supplierId: string;
  certificationBodyId: string;
  moduleId: string;
  certificateVariant: CertificateVariantEnum;
}

export interface UpdateCertificateDraftParams {
  mode: RequestModeEnum.UPDATE;
  certificateId: string;
}
