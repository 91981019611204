import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { SetAppointmentParams } from './types';
import { endpoints } from '../../config/endpoints';

const setAppointmentApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    setAuditDraftAppointment: build.mutation<void, SetAppointmentParams>({
      query: ({ appointmentId, auditDraftId }) => ({
        url: endpoints.APPOINTMENTS(auditDraftId),
        method: 'PUT',
        data: {
          appointmentId,
        },
      }),
    }),
  }),
});

export const useSetAuditDraftAppointmentMutation = () => {
  const [setAppointment, mutation] =
    setAppointmentApi.useSetAuditDraftAppointmentMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: SetAppointmentParams) =>
    setAppointment(params).unwrap();

  return {
    ...mutation,
    setAppointment: requestMethod,
    status,
    error,
  };
};
