import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Logger } from 'components/atoms/Logger';
import { useShowPageHeaderBackButton } from 'components/molecules/PageHeader';
import { Tabs } from 'components/organisms/Tabs';
import { TabItem } from 'components/organisms/Tabs/types';
import { WHITE } from 'config/appColors';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useGetCertificateDetailsActions } from '../../../state/certificateDetailsActions/api';
import { useGetCertificateDetailsTemplateQuery } from '../../../state/certificateDetailsTemplate/api';
import {
  CertificateDetailsTabEnum,
  isAccessesTab,
  isCertificateTab,
  isGroupCertificateTab,
  isLockHistoryTab,
  isLogsTab,
} from '../../../types';
import { CertificateDetailsTabActions } from '../../molecules/CertificateDetailsTabActions';
import { AccessesTab } from '../AccessesTab';
import { CertificateTab } from '../CertificateTab';
import { GroupCertificateTab } from '../GroupCertificateTab';
import { LockHistoryTab } from '../LockHistoryTab';
import { LogsTab } from '../LogsTab';

const getCertificateTab = (tab: unknown, certificateUuid: string) =>
  isCertificateTab(tab) ? (
    <CertificateTab {...tab.props} certificateUuid={certificateUuid}>
      {tab.children}
    </CertificateTab>
  ) : null;

const getGroupCertificateTab = (tab: unknown, certificateUuid: string) =>
  isGroupCertificateTab(tab) ? (
    <GroupCertificateTab {...tab.props} certificateUuid={certificateUuid} />
  ) : null;

const getLogsTab = (tab: unknown, certificateUuid: string) =>
  isLogsTab(tab) ? (
    <LogsTab {...tab.props} certificateUuid={certificateUuid} />
  ) : null;

const getLockHistoryTab = (tab: unknown, certificateUuid: string) =>
  isLockHistoryTab(tab) ? (
    <LockHistoryTab certificateUuid={certificateUuid} />
  ) : null;

const getAccessesTab = (tab: unknown, certificateUuid: string) =>
  isAccessesTab(tab) ? (
    <AccessesTab {...tab.props} certificateUuid={certificateUuid} />
  ) : null;

const CERTIFICATE_TABS = {
  [CertificateDetailsTabEnum.CERTIFICATE]: getCertificateTab,
  [CertificateDetailsTabEnum.GROUP_CERTIFICATE]: getGroupCertificateTab,
  [CertificateDetailsTabEnum.LOCK_HISTORY]: getLockHistoryTab,
  [CertificateDetailsTabEnum.LOGS]: getLogsTab,
  [CertificateDetailsTabEnum.ACCESSES]: getAccessesTab,
};

const StyledTabContentWrapper = styled('div')`
  min-height: 500px;
  padding: 25px;
  background: ${WHITE};
`;

export const MappedCertificateTabs: FC = () => {
  const showBackButton = useShowPageHeaderBackButton();
  const { certificateUuid, supplierId } =
    useParams<{ certificateUuid: string; supplierId: string }>();
  const { certificateDetailsTemplate } =
    useGetCertificateDetailsTemplateQuery(certificateUuid);
  const { certificateDetailsActions } =
    useGetCertificateDetailsActions(certificateUuid);

  const tabs: TabItem[] = certificateDetailsTemplate
    ? certificateDetailsTemplate.template.children
        .filter(({ props }) =>
          'requiredAction' in props
            ? certificateDetailsActions.includes(props.requiredAction)
            : true
        )
        .map((tab) => ({
          label: getTranslatedValue(tab.props.name),
          content: (
            <StyledTabContentWrapper>
              {CERTIFICATE_TABS[tab.props.id] ? (
                CERTIFICATE_TABS[tab.props.id](tab, certificateUuid)
              ) : (
                <Logger
                  message={`${tab.props.id} not supported tab id, please check spelling`}
                />
              )}
            </StyledTabContentWrapper>
          ),
        }))
    : [];

  return (
    <>
      {!!certificateDetailsTemplate && (
        <Tabs
          items={tabs}
          tabNavChildComponent={
            <CertificateDetailsTabActions
              certificateUuid={certificateUuid}
              supplierId={supplierId}
            />
          }
          routeParamName="tab"
          routeState={{ showBackButton }}
        />
      )}
    </>
  );
};
