import { styled } from '@mui/material';
import clsx from 'clsx';
import React, { CSSProperties, FC, ReactNode } from 'react';

import { TEXT } from 'config/appColors';

import { StyledLabelValueListItem } from '../../atoms/StyledLabelValueListItem';

const StyledLabel = styled('span')<{ $color: CSSProperties['color'] }>`
  color: ${({ $color }) => $color};
`;

interface LabelValueListProps {
  data: {
    label?: string;
    value?: ReactNode;
    labelColor?: CSSProperties['color'];
  }[];
  withDivider?: boolean;
}

export const LabelValueList: FC<LabelValueListProps> = ({
  data,
  withDivider = false,
}) => (
  <>
    {data.map(({ label, value, labelColor = TEXT.PRIMARY }) =>
      value ? (
        <StyledLabelValueListItem
          key={`${value}-${label}`}
          className={clsx({ withDivider })}
        >
          {label && <StyledLabel $color={labelColor}>{label}: </StyledLabel>}
          {value}
        </StyledLabelValueListItem>
      ) : null
    )}
  </>
);
