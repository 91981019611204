import { CertificateStatusEnum } from 'domains/supplier/types';
import {
  ActionComment,
  CertificateLock,
  CertificationBody,
  ExceptionalCircumstance,
  GeneralDetails,
  Nullable,
  Result,
  StartEndDates,
  Supplier,
} from 'types';

import {
  AuditAppointmentKindEnum,
  AuditAppointmentTypeEnum,
} from '../../auditAppointment/types';

// Enum for the response we get from Backend API
export enum CertificateActionsEnum {
  CERTIFICATE_DETAILS_VIEW = 'certificate-details-view',
  CERTIFICATE_DETAILS_LIST = 'certificate-details-list',
  CERTIFICATE_UPDATE = 'certificate-standalone-update',
  CERTIFICATE_DELETE = 'certificate-delete',
  CERTIFICATE_CHECK_ADD = 'certificate-check-add',
  CERTIFICATE_CHECK_UPDATE = 'certificate-check-update',
  CERTIFICATE_CHECK_DETAILS_VIEW = 'certificate-check-details-view',
  CERTIFICATE_CHECK_LIST_VIEW = 'certificate-check-list-view',
  CERTIFICATE_LOCK_UPDATE = 'certificate-lock-update',
  CERTIFICATE_REINSTATE_ADD = 'certificate-reinstate',
  CERTIFICATE_REINSTATE_UPDATE = 'certificate-reinstate-update',
  CERTIFICATE_LOCK_HISTORY_VIEW = 'certificate-lock-history-view',
  CERTIFICATE_EXCEPTIONAL_CIRCUMSTANCE_ADD = 'certificate-exceptional-circumstance-add',
  CERTIFICATE_EXCEPTIONAL_CIRCUMSTANCE_UPDATE = 'certificate-exceptional-circumstance-update',
  CERTIFICATE_EXCEPTIONAL_CIRCUMSTANCE_CLOSE = 'certificate-exceptional-circumstance-close',
  CERTIFICATE_LOGS_VIEW = 'certificate-logs-view',
  CERTIFICATE_GROUP_CERTIFICATE_VIEW = 'group-certificate-view',
  CERTIFICATE_SUPPLIER_REMOVE = 'group-certificate-supplier-remove',
  CERTIFICATE_AUDIT_REMOVE = 'group-certificate-audit-remove',
  CERTIFICATE_REMOVE = 'certificate-standalone-delete',
  CERTIFICATE_SUPPLIER_ADD = 'group-certificate-supplier-add',
  CERTIFICATE_AUDIT_ADD = 'group-certificate-audit-add',
  CERTIFICATE_LOCK = 'certificate-lock',
  CERTIFICATE_GROUP_CERTIFICATE_REMOVE = 'group-certificate-remove', // @ToDo - temporary assumption, to confirm in future
  REQUEST_AUDIT_ACCESS = 'request-audit-access',
  CERTIFICATE_CB_SWITCH = 'certificate-cb-switch',
  CERTIFICATE_ACCESSES = 'audit-iam-accesses-view',
}

export type CertificateDetails = {
  uuid: string;
  version?: number;
  ceid?: string;
  // TODO: PL4FE-205 remove union type and adjust other places related to this union type
  status:
    | GeneralDetails<CertificateStatusEnum>
    | GeneralDetails<CertificateStatusEnum>[];
  certificationBody: CertificationBody;
  supplier: Supplier;
  module: GeneralDetails;
  moduleFamily?: GeneralDetails;
  type?: GeneralDetails;
  issuedAt: string;
  validity: StartEndDates;
  auditType?: GeneralDetails<AuditAppointmentTypeEnum>;
  auditResult?: Result;
  auditKind?: GeneralDetails<AuditAppointmentKindEnum>;
  announcedRecertificationTimeframe?: StartEndDates;
  unannouncedRecertificationTimeframe?: StartEndDates;
  activeLock?: Nullable<CertificateLock> | false;
  latestLock?: Nullable<CertificateLock> | false;
  exceptionalCircumstances?: ExceptionalCircumstance[];
  tags?: string[];
};

export interface ReasonDescriptionModalFormData {
  reasonDescription: string;
  actionComment?: ActionComment;
}
