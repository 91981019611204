import { t } from 'i18n';
import { SelectOption } from 'types';

import {
  actionReasons,
  circumstanceTypes,
  reasonCategories,
} from '../config/constants';

const getSelectOption = (
  { label, value }: SelectOption,
  collectionName: string
): SelectOption => {
  const ns =
    collectionName === 'actionReasons' ? 'components' : 'certificateDetails';

  return {
    label: t(label, ns),
    value,
    ariaLabel: t(label, ns),
  };
};

type UseTranslatedOptionsProps =
  | {
      type: keyof typeof reasonCategories;
      collectionName: 'reasonCategories';
    }
  | {
      type: keyof typeof actionReasons;
      collectionName: 'actionReasons';
    }
  | {
      type: keyof typeof circumstanceTypes;
      collectionName: 'circumstanceTypes';
    };

export function useTranslatedSelectOptions({
  type,
  collectionName,
}: UseTranslatedOptionsProps): SelectOption[] {
  let rawOptions: SelectOption[] = [];

  if (collectionName === 'actionReasons') {
    rawOptions = actionReasons[type as keyof typeof actionReasons];
  }

  if (collectionName === 'circumstanceTypes') {
    rawOptions = circumstanceTypes[type as keyof typeof circumstanceTypes];
  }

  if (collectionName === 'reasonCategories') {
    rawOptions = reasonCategories[type as keyof typeof reasonCategories];
  }

  return rawOptions.map((option) => getSelectOption(option, collectionName));
}
