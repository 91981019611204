import { styled } from '@mui/material';
import React, { FC } from 'react';

import { Logger } from 'components/atoms/Logger';
import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { AlertList } from 'components/molecules/AlertList';
import { AttributeTable } from 'components/molecules/AttributeTable';
import { FormDataConfirmationStep } from 'domains/auditDraft/types';
import { TemplateCmpTypeEnum } from 'types';
import { generateId } from 'utils/generateId';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useAuditDraftDetailsQuery } from '../../../state/auditDraftDetails/api';
import { WarningBox } from '../../molecules/WarningBox';
import { TemplateAuditDraftStep } from '../../templates/TemplateAuditDraftStep';

const StyledStepContent = styled('div')`
  margin: 0 10px;
`;

const StyledStepDescription = styled(StepDescription)`
  margin: 20px 0 30px 0;
`;

type StepProps = FormDataConfirmationStep['props'];
type StepChildren = FormDataConfirmationStep['children'];
interface FormDataConfirmationProps extends StepProps {
  children: StepChildren;
  auditDraftId: string;
}

export const FormDataConfirmation: FC<FormDataConfirmationProps> = (
  props: FormDataConfirmationProps
) => {
  const {
    backAction,
    children,
    instructionText,
    nextAction,
    title,
    auditDraftId,
  } = props;
  const { auditDraftDetails, status, isFetching, error } =
    useAuditDraftDetailsQuery(auditDraftId);

  return (
    <TemplateAuditDraftStep
      backAction={backAction}
      nextAction={nextAction}
      contentStatus={status}
      disableNext={isFetching}
    >
      <StepFormTitle content={getTranslatedValue(title)} />
      <StyledStepContent>
        {children.map((child, index) => {
          if (child.type === TemplateCmpTypeEnum.WARNING_BOX) {
            return (
              <WarningBox
                key={generateId(child.type, index)}
                config={child}
                warningIds={auditDraftDetails?.warnings}
              />
            );
          }
          return null;
        })}

        {error && <AlertList alerts={error} />}
        <StyledStepDescription content={getTranslatedValue(instructionText)} />

        {children.map((child, index) => {
          if (child.type === TemplateCmpTypeEnum.ATTRIBUTE_TABLE) {
            return (
              <AttributeTable
                key={child.type}
                data={auditDraftDetails}
                rows={child.children}
              />
            );
          }
          /**
           * we don't expect other types of children here;
           * however once we have additional types we need to handle them here
           */
          return (
            <Logger
              key={generateId(child.type, index)}
              message={`Unsupported child type - ${child.type}.`}
            />
          );
        })}
      </StyledStepContent>
    </TemplateAuditDraftStep>
  );
};
