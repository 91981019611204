import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { endpoints } from '../../config/endpoints';
import { AuditDraftCommonRequestParams } from '../../types';

const auditDraftSubmitApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    submitAuditDraft: build.mutation<void, string>({
      query: (auditDraftId) => ({
        url: endpoints.SUBMIT(auditDraftId),
        method: 'PUT',
      }),
    }),
  }),
});

export const useSubmitAuditDraftMutation = () => {
  const [submitAuditDraft, mutation] =
    auditDraftSubmitApi.useSubmitAuditDraftMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: AuditDraftCommonRequestParams) =>
    submitAuditDraft(params.auditDraftId).unwrap();

  return {
    ...mutation,
    submitAuditDraft: requestMethod,
    status,
    error,
  };
};
