import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { DraftStatusEnum } from 'types';

import { parentReducerName } from '../../config/constants';

const initialState = {
  status: DraftStatusEnum.UNSUBMITTED,
};

const reducerName = `${parentReducerName}/submit`;

// slice
export const certificateDraftSubmitSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setSubmitStatus: (state, action: PayloadAction<DraftStatusEnum>) => {
      state.status = action.payload;
    },
  },
});

// actions
export const { setSubmitStatus } = certificateDraftSubmitSlice.actions;

// selectors
export const selectCertificateDraftSubmitStatus = (
  state: RootState
): DraftStatusEnum => state[parentReducerName].submit.status;

export default certificateDraftSubmitSlice.reducer;
