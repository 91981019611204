import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store';

import { UserSidebarState } from './types';
import { parentReducerName } from '../../config/constants';

const initialState: UserSidebarState = {
  reportsSubmenuIsOpen: false,
};

const reducerName = `${parentReducerName}/sidebar`;

export const userSidebarSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    openReportsSubmenu: (state) => {
      state.reportsSubmenuIsOpen = true;
    },
    toggleReportsSubmenu: (state) => {
      state.reportsSubmenuIsOpen = !state.reportsSubmenuIsOpen;
    },
  },
});

// actions
export const { openReportsSubmenu, toggleReportsSubmenu } =
  userSidebarSlice.actions;

// selectors
export const selectReportsSubmenuIsOpen = (state: RootState): boolean =>
  state[parentReducerName].sidebar.reportsSubmenuIsOpen;

// reducer
export default userSidebarSlice.reducer;
