import iframeRoutes from 'domains/widgets/config/routes';
import { TARGET_AUDIT_SEARCH } from 'utils/getAuditDraftFormPathname';
import { UUID_V4 } from 'utils/regex/uuid';

export const getAuditDraftUpdateDocsPathname = (
  supplierId: string,
  auditDraftId: string,
  targetAuditId: string
): string => {
  const pathname = iframeRoutes.AUDIT_DRAFT_UPDATE_DOCUMENTS.replace(
    `:supplierId(${UUID_V4})`,
    supplierId
  ).replace(`:auditDraftId(${UUID_V4})`, auditDraftId);

  return `${pathname}?${TARGET_AUDIT_SEARCH}=${targetAuditId}`;
};
