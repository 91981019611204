import { MouseEvent as ReactMouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import routes from 'config/routes';
import { Audit } from 'types';
import { UUID_V4 } from 'utils/regex/uuid';

type UseAuditListDetailsLinkType = (
  event: ReactMouseEvent<HTMLTableRowElement, MouseEvent>,
  row: Audit
) => void;

export const useAuditListDetailsLink = (): UseAuditListDetailsLinkType => {
  const { push } = useHistory();

  return (
    event: ReactMouseEvent<HTMLTableRowElement, MouseEvent>,
    row: Audit
  ) => {
    const pathname = routes.AUDIT_DETAILS.replace(
      `:supplierId(${UUID_V4})`,
      row.supplier.uuid
    ).replace(`:auditUuid(${UUID_V4})`, row.uuid);
    push({ pathname, state: { showBackButton: true } });
  };
};
