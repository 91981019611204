import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, usePagination } from 'components/organisms/Table';
import { useLogColumns } from 'hooks/useLogColumns';
import { TranslatedText } from 'types';

import { useGetAuditDetailsActionsQuery } from '../../../state/auditDetailsActions/api';
import { useGetAuditDetailsLogsQuery } from '../../../state/logsTab/api';
import { AuditActionsEnum } from '../../../types';
import { TabSectionHeading } from '../../atoms/TabSectionHeading';
import { DownloadOldLogsButton } from '../../molecules/DownloadOldLogsButton';

interface LogsTabProps {
  auditUuid: string;
  headerText: TranslatedText | string;
}

const StyledTable = styled(Table)`
  tbody td {
    vertical-align: top;
  }
` as typeof Table;

const StyledTabSectionHeading = styled(TabSectionHeading)`
  margin-bottom: 15px;
`;

const StyledHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogsTab: FC<LogsTabProps> = (props) => {
  const { auditUuid, headerText } = props;
  const [t] = useTranslation('auditDetails');
  const { pagination, limit, offset, setTotalRowsAmount } = usePagination({
    initialRowsPerPage: 20,
  });
  const { auditDetailsActions } = useGetAuditDetailsActionsQuery(auditUuid);
  const { error, status, logs, total } = useGetAuditDetailsLogsQuery({
    params: {
      limit,
      offset,
    },
    uuid: auditUuid,
  });
  const columns = useLogColumns();

  useEffect(() => {
    if (total > 0) {
      setTotalRowsAmount(total);
    }
  }, [setTotalRowsAmount, total]);

  const showDownloadOldLogsButton = auditDetailsActions.includes(
    AuditActionsEnum.AUDIT_LOGS_OLD_FILE
  );

  return (
    <>
      <StyledHeader>
        <StyledTabSectionHeading text={headerText} />
        {showDownloadOldLogsButton && (
          <DownloadOldLogsButton auditUuid={auditUuid} />
        )}
      </StyledHeader>
      <StyledTable
        rows={logs}
        columns={columns}
        status={status}
        error={error}
        errorHeadingText={t('Failed to load audit logs.')}
        pagination={pagination}
        propertyAsAKey="executedAt"
        dense
      />
    </>
  );
};
