import { alpha, styled, SxProps } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DistributiveOmit } from '@mui/types';
import React, {
  FC,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useState,
} from 'react';

import { DropdownMenuItem } from 'components/atoms/DropdownMenuItem';
import {
  OpenModalButton,
  OpenModalButtonProps,
} from 'components/molecules/OpenModalButton';
import { BLACK } from 'config/appColors';
import { generateId } from 'utils/generateId';

const DEFAULT_Z_INDEX = 1301;

interface StyledMenuProps {
  $menuListZIndex?: number;
}

/**
 * important because we want to override inline styles
 * that MUI apply by default once we migrate to MUI 5
 * we can use && instead important
 */
const StyledMenu = styled(Menu)<StyledMenuProps>`
  .MuiList-root {
    padding: 0;
  }
  z-index: ${({ $menuListZIndex }) => $menuListZIndex || DEFAULT_Z_INDEX};
`;

export interface DropdownMenuProps {
  options: DropdownItem[];
  renderOpenButton: (props: OpenButtonProps) => ReactNode;
  menuProps?: DistributiveOmit<MenuProps, 'anchorEl' | 'open' | 'onClose'>;
  className?: string;
  menuListZIndex?: number;
}

export interface OpenButtonProps {
  onClick: (ev: MouseEvent<HTMLButtonElement>) => void;
}

export type OpenModalProps = DistributiveOmit<
  OpenModalButtonProps,
  'buttonProps' | 'renderCustomOpenComponent' | 'modalComponent'
> &
  Partial<Pick<OpenModalButtonProps, 'modalComponent'>>;

export interface DropdownItem extends OpenModalProps {
  label: string;
  icon?: ReactNode;
  onClickAction?: () => void;
  sx?: SxProps;
}

const StyledMenuItem = styled(MenuItem)`
  padding: 0;

  &:hover {
    background: ${alpha(BLACK, 0.04)};
  }
`;

const menuSx: SxProps = {
  '&': {
    top: '5px',
  },
};

export const DropdownMenu: FC<DropdownMenuProps> = ({
  renderOpenButton,
  options,
  menuProps = {},
  className,
  menuListZIndex,
}) => {
  const {
    anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
    transformOrigin = { vertical: 'top', horizontal: 'right' },
    elevation = 4,
    keepMounted = true,
    ...restMenuProps
  } = menuProps;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderDropdownItem = (
    label: string,
    onClick?: MouseEventHandler,
    icon?: ReactNode,
    sx?: SxProps
  ) => (
    <DropdownMenuItem icon={icon} onClick={onClick} sx={sx}>
      {label}
    </DropdownMenuItem>
  );

  if (options.length === 0) return null;

  return (
    <div className={className}>
      {renderOpenButton && renderOpenButton({ onClick: handleOpen })}
      <StyledMenu
        $menuListZIndex={menuListZIndex}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        keepMounted={keepMounted}
        elevation={elevation}
        sx={menuSx}
        {...restMenuProps}
      >
        {options.map(
          (
            {
              modalComponent,
              beforeModalClose,
              beforeModalOpen,
              onClickAction,
              label,
              icon = null,
              sx,
            },
            index
          ) => (
            <StyledMenuItem
              key={generateId(label, index)}
              onClick={handleClose}
              data-testid={generateId(label, index)}
            >
              {modalComponent ? (
                <OpenModalButton
                  modalComponent={modalComponent}
                  beforeModalOpen={beforeModalOpen}
                  beforeModalClose={beforeModalClose}
                  renderCustomOpenComponent={({ handleClickOpen }) =>
                    renderDropdownItem(label, handleClickOpen, icon, sx)
                  }
                />
              ) : (
                renderDropdownItem(label, onClickAction, icon, sx)
              )}
            </StyledMenuItem>
          )
        )}
      </StyledMenu>
    </div>
  );
};

export default DropdownMenu;
