import { WarningBoxConfig } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';
import { isArrayOfString } from 'utils/isArrayOfString';

interface UseWarnings {
  showWarnings: boolean;
  warningsToDisplay: string[];
}

export const useWarnings = (
  config?: WarningBoxConfig,
  warningsId?: string[]
): UseWarnings => {
  if (
    !config ||
    !isArrayOfString(warningsId) ||
    (isArrayOfString(warningsId) && warningsId.length === 0)
  ) {
    return {
      showWarnings: false,
      warningsToDisplay: [],
    };
  }

  const matchingWarningIds =
    config?.props?.allowedMessage?.filter((allowedMessage) =>
      warningsId?.includes(allowedMessage)
    ) || [];

  const warningsToDisplay =
    config?.children
      ?.filter((child) => matchingWarningIds.includes(child.props.messageKey))
      .map((matchingChild) =>
        getTranslatedValue(matchingChild?.props?.messageText)
      ) || [];

  const showWarnings = warningsToDisplay.length > 0;

  return {
    showWarnings,
    warningsToDisplay,
  };
};
