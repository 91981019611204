import { styled } from '@mui/material';
import React, { FC } from 'react';

import { generateId } from 'utils/generateId';

import { Logger } from '../../atoms/Logger';
import {
  DateInput,
  Radio,
  ResultSelect,
  SingleSelect,
  Textarea,
  TimeFrame,
} from '../../molecules/DynamicFormItems';
import {
  DynamicFormCommonProps,
  DynamicFormItemTypesEnum,
} from '../../molecules/DynamicFormItems/types';
import { ErrorBoundary } from '../../molecules/ErrorBoundary';

type DynamicFormProps = DynamicFormCommonProps;

const StyledDynamicFormContainer = styled('div')`
  > * {
    margin-top: 20px;
  }

  > *:last-child {
    margin-bottom: 20px;
  }
`;

const DynamicForm: FC<DynamicFormProps> = ({ formItems, ...rest }) => {
  if (!formItems || !rest.values) {
    return null;
  }

  if (!Array.isArray(formItems)) {
    return (
      <Logger message="System encountered problem with the format of template the template.children." />
    );
  }

  return (
    <StyledDynamicFormContainer>
      {formItems.map((props, index) => {
        const {
          type,
          props: { dataRef },
        } = props;
        if (!rest.isVisible(dataRef)) {
          return null;
        }
        const subItems = <DynamicForm {...rest} formItems={props.children} />;
        switch (type) {
          case DynamicFormItemTypesEnum.TIME_FRAME:
            return (
              <TimeFrame
                key={generateId(type, index)}
                {...props}
                {...rest}
                subItems={subItems}
              />
            );
          case DynamicFormItemTypesEnum.DATE_INPUT:
            return (
              <DateInput
                key={generateId(type, index)}
                {...props}
                {...rest}
                subItems={subItems}
              />
            );
          case DynamicFormItemTypesEnum.RESULT_SELECT:
            return (
              <ResultSelect
                key={generateId(type, index)}
                {...props}
                {...rest}
                subItems={subItems}
              />
            );
          case DynamicFormItemTypesEnum.SINGLE_SELECT:
            return (
              <SingleSelect
                key={generateId(type, index)}
                {...props}
                {...rest}
                subItems={subItems}
              />
            );
          case DynamicFormItemTypesEnum.TEXTAREA:
            return (
              <Textarea
                key={generateId(type, index)}
                {...props}
                {...rest}
                subItems={subItems}
              />
            );
          case DynamicFormItemTypesEnum.RADIO:
            return (
              <Radio
                key={generateId(type, index)}
                {...props}
                {...rest}
                subItems={subItems}
              />
            );
          default:
            return (
              <Logger
                message={`System encountered unsupported type: ${type}`}
              />
            );
        }
      })}
    </StyledDynamicFormContainer>
  );
};

export const DynamicFormWithErrorBoundary: FC<DynamicFormProps> = (props) => (
  <ErrorBoundary staticPosition>
    <DynamicForm {...props} />
  </ErrorBoundary>
);
