import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxFilter, UseFiltersAndSorting } from '../../../types';
import { AdminTableCheckbox } from '../../atoms/AdminTableCheckbox';

type CheckboxFilterCellProps = CheckboxFilter & UseFiltersAndSorting;

export const CheckboxFilterCell: FC<CheckboxFilterCellProps> = ({
  label,
  filterKey,
  filters,
  setFilterValue,
}) => {
  const [t] = useTranslation('adminReports');
  const value = filters[filterKey];

  return (
    <AdminTableCheckbox
      label={t(label || '')}
      checked={!!value}
      onChange={() => {
        setFilterValue({
          filterKey,
          value: !value ? true : null,
        });
      }}
    />
  );
};
