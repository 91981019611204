import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { LoaderStatusEnum } from 'types';

import { ConditionalModalProps } from './types';
import {
  resetCancelAuditAppointmentState,
  selectCancelAuditAppointmentIsModalOpen,
  selectCancelAuditAppointmentLoading,
  setCancelAuditAppointmentIsModalOpen,
} from '../../../state/cancel/slice';
import { getAuditAppointment } from '../../../state/details/slice';
import { CancelAuditAppointmentModal } from '../CancelAuditAppointmentModal';

export const ConditionalCancelAuditAppointmentModal: FC<ConditionalModalProps> =
  ({ appointmentId }) => {
    const [t] = useTranslation('auditAppointment');
    const notificationMessage = t('Appointment cancelled');
    const { openSnackbar } = useSnackbarNotification();
    const dispatch = useDispatch();
    const open = useSelector(selectCancelAuditAppointmentIsModalOpen);
    const loading = useSelector(selectCancelAuditAppointmentLoading);

    const closeModal = () => {
      dispatch(setCancelAuditAppointmentIsModalOpen(false));
      dispatch(resetCancelAuditAppointmentState());
    };

    useEffect(() => {
      if (loading === LoaderStatusEnum.SUCCESS) {
        dispatch(resetCancelAuditAppointmentState()); // reset state to IDLE
        dispatch(getAuditAppointment(appointmentId)); // refetch audit appointment details if success
        openSnackbar(notificationMessage, 'success');
      }
    }, [loading, appointmentId, dispatch, openSnackbar, notificationMessage]);

    if (!open) return null;

    return (
      <CancelAuditAppointmentModal
        open={open}
        appointmentId={appointmentId}
        handleClose={closeModal}
      />
    );
  };
