import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store';

import { ModalState } from './types';
import { parentReducerName } from '../../config/constants';

const initialState: ModalState = {
  isOpen: false,
};

const reducerName = `${parentReducerName}/modal`;

export const certificationBodyModalSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    closeModal: (state) => {
      state.isOpen = false;
    },
    openModal: (state) => {
      state.isOpen = true;
    },
  },
});

// actions
export const { closeModal, openModal } = certificationBodyModalSlice.actions;

// selectors
export const selectCertificationModalIsOpen = (state: RootState): boolean =>
  state[parentReducerName].modal.isOpen;

// reducer
export default certificationBodyModalSlice.reducer;
