import { REASON_OTHER } from 'config/constants';
import { Nullable } from 'types';

export const isActionCommentFieldValid = (
  reason: Nullable<string>,
  comment: Nullable<string>,
  valuesForCommentRequired: string[] = [REASON_OTHER]
): boolean => {
  const trimmedComment = comment?.trim();

  return !!(
    reason &&
    !(
      valuesForCommentRequired.includes(reason) &&
      (!trimmedComment || trimmedComment.length < 5)
    )
  );
};
