import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
} from '@mui/material';
import React, { FC } from 'react';

export type SkeletonProps = MuiSkeletonProps;

export const Skeleton: FC<SkeletonProps> = ({
  variant = 'rectangular',
  ...rest
}) => <MuiSkeleton variant={variant} {...rest} />;
