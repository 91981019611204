import { styled } from '@mui/material';
import MuiStepper from '@mui/material/Stepper';
import React, { FC } from 'react';

import { Step, StepperStepType } from 'components/atoms/Step';

const StyledStepper = styled(MuiStepper)`
  padding: 24px;

  .MuiStepConnector-vertical {
    padding: 5px 0;
  }

  .MuiStepButton-vertical {
    padding: 0;
    margin: 0;
    height: 26px;
  }
`;
interface StepperProps {
  steps: StepperStepType[];
  activeStep: string;
  onStepClick?: (step: StepperStepType) => void;
}

export const Stepper: FC<StepperProps> = (props) => {
  const { steps, activeStep, onStepClick } = props;

  return (
    // Stepper is non-linear (user can navigate back to previous steps) only if onStepClick handler is passed
    <StyledStepper nonLinear={!!onStepClick} orientation="vertical">
      {steps.map((step, index) => (
        <Step
          key={step.id}
          isActive={activeStep === step.id}
          step={step}
          stepNumber={index + 1}
          onStepClick={onStepClick}
        />
      ))}
    </StyledStepper>
  );
};
