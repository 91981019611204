import { get, isEqual } from 'lodash';
import * as yup from 'yup';

import {
  DateInputType,
  DynamicFormItem,
  DynamicFormItemTypesEnum,
  RadioType,
  ResultSelectType,
  SingleSelectType,
  TextareaType,
  TimeFrameType,
  ValidationStateType,
} from 'components/molecules/DynamicFormItems/types';
import { dateSchema, rawResultSchema, startEndDatesSchema } from 'schemas';
import { Nullable, StartEndDates, WithUndefined } from 'types';
import { removeSpacesFromString } from 'utils/removeSpacesFromString';

const {
  TIME_FRAME,
  DATE_INPUT,
  RESULT_SELECT,
  SINGLE_SELECT,
  TEXTAREA,
  RADIO,
} = DynamicFormItemTypesEnum;

type GetInitialState<ChildType> = (
  child: ChildType,
  state: Record<string, unknown>,
  draft?: Nullable<Record<string, unknown>>
) => Record<string, unknown>;

const getKeyAndDraftValue = (
  child: DynamicFormItem,
  draft?: Nullable<Record<string, unknown>>
) => {
  const key = child?.props?.dataRef;
  const draftValue = get(draft, key);
  return { key, draftValue };
};

const getGenericState: GetInitialState<DynamicFormItem> = (
  child,
  state,
  draft
) => {
  const { key, draftValue } = getKeyAndDraftValue(child, draft);

  return { ...state, [key]: draftValue || '' };
};

const getTimeFrameState: GetInitialState<TimeFrameType> = (
  child,
  state,
  draft
) => {
  const { key, draftValue } = getKeyAndDraftValue(child, draft);
  const finalValue = startEndDatesSchema.isValidSync(draftValue)
    ? draftValue
    : {
        start: '',
        end: '',
      };

  return { ...state, [key]: finalValue };
};

const getDateInputState: GetInitialState<DateInputType> = (
  child,
  state,
  draft
) => {
  const { key, draftValue } = getKeyAndDraftValue(child, draft);

  const finalValue = dateSchema.required().isValidSync(draftValue)
    ? draftValue
    : '';

  return { ...state, [key]: finalValue };
};

const getResultSelectState: GetInitialState<ResultSelectType> = (
  child,
  state,
  draft
) => {
  const { key, draftValue } = getKeyAndDraftValue(child, draft);
  const templateDefaultValue = child?.props?.options?.find(
    (option) => option?.selected
  );
  const overall = templateDefaultValue?.resultObject;
  const finalTemplateDefaultValue = templateDefaultValue
    ? {
        overall,
        levels: [overall],
      }
    : null;

  const finalValue = draftValue ||
    finalTemplateDefaultValue || {
      overall: {
        id: '',
      },
      levels: [],
    };

  return { ...state, [key]: finalValue };
};

const getSingleSelectState: GetInitialState<SingleSelectType> = (
  child,
  state,
  draft
) => {
  const { key, draftValue } = getKeyAndDraftValue(child, draft);
  const value =
    !!draftValue && typeof draftValue === 'object' && 'id' in draftValue
      ? draftValue.id
      : undefined;
  const templateDefaultValue = child?.props?.options.find(
    (option) => option?.selected
  );
  const finalValue = value || templateDefaultValue?.value || '';

  return { ...state, [key]: finalValue };
};

const getRadioButtonState: GetInitialState<RadioType> = (
  child,
  state,
  draft
) => {
  const { key, draftValue } = getKeyAndDraftValue(child, draft);
  const value =
    typeof draftValue === 'string' && !!draftValue ? draftValue : undefined;
  const templateDefaultValue = child?.props?.options.find(
    (option) => option?.selected
  );
  const finalValue = value || templateDefaultValue?.value || '';

  return { ...state, [key]: finalValue };
};

export const getInitialState = (
  items?: Nullable<DynamicFormItem[]>,
  draft?: Nullable<Record<string, unknown>>
): Nullable<Record<string, unknown>> => {
  if (!items) {
    return null;
  }
  /**
   * state might look different for different types of child
   * if we want to set initial state
   * first we try to take it from draft
   * second if there is no data in the draft we try to get it from template
   * third we set it to some default empty string so there is no change from uncontrolled to controlled input
   */

  return items.reduce<Record<string, unknown>>((state, child) => {
    const { type, children } = child;

    const nestedState = Array.isArray(children)
      ? getInitialState(children, draft)
      : {};
    const stateWithNested = { ...state, ...nestedState };

    switch (type) {
      case TIME_FRAME:
        return getTimeFrameState(child, stateWithNested, draft);
      case DATE_INPUT:
        return getDateInputState(child, stateWithNested, draft);
      case RESULT_SELECT:
        return getResultSelectState(child, stateWithNested, draft);
      case SINGLE_SELECT:
        return getSingleSelectState(child, stateWithNested, draft);
      case TEXTAREA:
        return getGenericState(child, stateWithNested, draft);
      case RADIO:
        return getRadioButtonState(child, stateWithNested, draft);
      default:
        return { ...stateWithNested, [type]: null };
    }
  }, {});
};

export const getKeysOfVisibleItems = (
  values: Nullable<Record<string, unknown>>,
  items?: Nullable<DynamicFormItem[]>
): string[] =>
  items?.reduce<string[]>((accKeys, child) => {
    const { dataRef, visibilityCondition } = child.props;

    const nestedKeys = child.children
      ? getKeysOfVisibleItems(values, child.children)
      : [];

    if (typeof visibilityCondition === 'object') {
      const isMatching = isEqual(
        get(values, visibilityCondition.key),
        visibilityCondition.value
      );

      return isMatching
        ? [...accKeys, ...nestedKeys, dataRef]
        : [...accKeys, ...nestedKeys];
    }

    if (visibilityCondition) {
      return [...accKeys, ...nestedKeys, dataRef];
    }

    return [...accKeys, ...nestedKeys];
  }, []) || [];

/**
 *
 * @param state - recursively passed new state
 * @param items - dynamic form items
 * @param stateToVerify - initial state that should be created on the first load of draft details and template
 * required to check what fields should be set to null
 *
 * "reset" - there are certain fields that require null values to empty them in the backend state
 *
 */
export const filterOrResetVisibleItemsFromState = (
  state: Nullable<Record<string, unknown>>,
  items?: Nullable<DynamicFormItem[]>,
  stateToVerify?: Nullable<Record<string, unknown>>
): Nullable<Record<string, unknown>> => {
  if (!state) return null;
  const keysFromVerificationState = Object.keys(stateToVerify || {});
  const visibleItems = getKeysOfVisibleItems(state, items);

  return Object.keys(state).reduce<Record<string, unknown>>((acc, key) => {
    // if fields are visible their values should be normally persist
    if (visibleItems.includes(key)) {
      return { ...acc, [key]: state[key] };
    }
    // if fields are NOT visible but their keys initially had some values system should reset them (set null)
    if (
      !visibleItems.includes(key) &&
      keysFromVerificationState.includes(key)
    ) {
      return { ...acc, [key]: null };
    }

    return acc;
  }, {});
};

export const filterOrResetCodependentItemsFromState = (
  initialState: Nullable<Record<string, unknown>>,
  items?: Nullable<DynamicFormItem[]>,
  stateToVerify?: Nullable<Record<string, unknown>>
): Nullable<Record<string, unknown>> => {
  const processedState = filterOrResetVisibleItemsFromState(
    initialState,
    items,
    stateToVerify
  );

  if (isEqual(initialState, processedState)) {
    return processedState;
  }

  return filterOrResetCodependentItemsFromState(
    processedState,
    items,
    stateToVerify
  );
};

export const getTemplateChildByDataRef = (
  dataRef: string,
  items?: Nullable<DynamicFormItem[]>
): WithUndefined<DynamicFormItem> => {
  if (!items) {
    return undefined;
  }

  const matchingChild = items.find(
    ({ props: childProps }) => childProps.dataRef === dataRef
  );

  if (matchingChild) {
    return matchingChild;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const child of items) {
    if (child && Array.isArray(child.children)) {
      const result = getTemplateChildByDataRef(dataRef, child.children);
      if (result) {
        return result;
      }
    }
  }
  return undefined;
};

const checkCondition = (
  state: Nullable<Record<string, unknown>>,
  dataRef: string,
  conditionField: 'visibilityCondition' | 'requiredCondition',
  items?: Nullable<DynamicFormItem[]>
) => {
  const child = getTemplateChildByDataRef(dataRef, items);

  if (!child) return false;

  const conditionValue = child.props[conditionField];

  if (typeof conditionValue === 'boolean') return conditionValue;

  return isEqual(get(state, conditionValue.key), conditionValue.value);
};

export const isVisible = (
  state: Nullable<Record<string, unknown>>,
  dataRef: string,
  items?: Nullable<DynamicFormItem[]>
): boolean => checkCondition(state, dataRef, 'visibilityCondition', items);

export const isRequired = (
  state: Nullable<Record<string, unknown>>,
  dataRef: string,
  items?: Nullable<DynamicFormItem[]>
): boolean => checkCondition(state, dataRef, 'requiredCondition', items);

type ValidationFunction<T = unknown> = (
  value: T,
  itemProps: DynamicFormItem['props']
) => string | Record<string, string>;

const REQUIRED_FIELD = 'required';
const MISSING_DATE = 'missing Date';
const MIN_LENGTH = (num: number) => `min. ${num} characters`;

export const validateTimeFrame: ValidationFunction<StartEndDates> = (
  value
) => ({
  start: dateSchema.required().isValidSync(value?.start) ? '' : MISSING_DATE,
  end: dateSchema.required().isValidSync(value?.end) ? '' : MISSING_DATE,
});

export const validateDateInput: ValidationFunction = (value) => {
  const result = dateSchema.required().isValidSync(value);
  return result ? '' : REQUIRED_FIELD;
};

export const validateResultSelect: ValidationFunction = (value) => {
  const result = rawResultSchema.isValidSync(value);
  return result ? '' : REQUIRED_FIELD;
};

export const validateGenericValue: ValidationFunction = (value) => {
  const result = yup.string().required().isValidSync(value);
  return result ? '' : REQUIRED_FIELD;
};

export const validateTextarea: ValidationFunction = (value, itemProps) => {
  const { minLength } = itemProps as TextareaType['props'];
  const valueWithoutSpaces = removeSpacesFromString((value || '') as string);

  if (!valueWithoutSpaces) {
    return REQUIRED_FIELD;
  }

  if (valueWithoutSpaces.length < minLength) {
    return MIN_LENGTH(minLength);
  }

  return '';
};

export const validateDynamicFormItem = (
  value: unknown,
  itemProps: DynamicFormItem['props'],
  type: DynamicFormItemTypesEnum
): ReturnType<ValidationFunction> => {
  switch (type) {
    case TIME_FRAME:
      return validateTimeFrame(value as StartEndDates, itemProps);
    case DATE_INPUT:
      return validateDateInput(value, itemProps);
    case RESULT_SELECT:
      return validateResultSelect(value, itemProps);
    case SINGLE_SELECT:
    case RADIO:
      return validateGenericValue(value, itemProps);
    case TEXTAREA:
      return validateTextarea(value, itemProps);
    default:
      return '';
  }
};

export const isAnyInvalid = (validationState: ValidationStateType): boolean =>
  !!validationState &&
  Object.values(validationState).every((stateItem) =>
    typeof stateItem === 'string'
      ? !stateItem
      : Object.values(stateItem).every((nestedItem) => !nestedItem)
  );

const validateDynamicFormValues = (
  values: Nullable<Record<string, unknown>>,
  items?: Nullable<DynamicFormItem[]>
): ValidationStateType => {
  if (!items) return null;

  return items.reduce<ValidationStateType>((acc, child) => {
    const { props, type } = child;
    const { dataRef } = props;

    const validationState: ValidationStateType = child.children
      ? validateDynamicFormValues(values, child.children)
      : {};

    if (
      !isVisible(values, dataRef, items) ||
      !isRequired(values, dataRef, items)
    ) {
      return { ...acc, ...validationState };
    }

    const value = get(values, dataRef);

    return {
      ...acc,
      ...validationState,
      [dataRef]: validateDynamicFormItem(value, props, type),
    };
  }, {});
};

export const validateDynamicForm = (
  callback: (newState: ValidationStateType) => void,
  values: Nullable<Record<string, unknown>>,
  items?: Nullable<DynamicFormItem[]>
): boolean => {
  if (!items) return false;

  const newValidationState = validateDynamicFormValues(values, items);

  callback(newValidationState);

  return isAnyInvalid(newValidationState);
};
