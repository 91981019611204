import { SelectChangeEvent, styled } from '@mui/material';
import React, { Dispatch, FC, ReactNode, SetStateAction } from 'react';

import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import { AlertsState, GroupedOption, Nullable, SelectOption } from 'types';

const StyledDescription = styled('p')`
  margin: 0;
`;

interface DeleteModalFormProps {
  setIsAdminInfoValid: Dispatch<SetStateAction<boolean>>;
  adminReason: string;
  adminComment: string;
  adminReasonChangeHandler: (event: SelectChangeEvent<unknown>) => void;
  adminCommentChangeHandler: (comment: string) => void;
  adminReasonOptions: (SelectOption | GroupedOption)[];
  alertsState: Nullable<AlertsState>;
  description: ReactNode;
}

export const DeleteModalForm: FC<DeleteModalFormProps> = (props) => {
  const {
    setIsAdminInfoValid,
    adminReason,
    adminComment,
    adminReasonChangeHandler,
    adminCommentChangeHandler,
    adminReasonOptions,
    alertsState,
    description,
  } = props;

  return (
    <StyledBaseModalContent
      adminReasonOptions={adminReasonOptions}
      setIsAdminInfoValid={setIsAdminInfoValid}
      alertsState={alertsState}
      adminReason={adminReason}
      adminComment={adminComment}
      adminReasonChangeHandler={adminReasonChangeHandler}
      adminCommentChangeHandler={adminCommentChangeHandler}
      isAdmin
    >
      <StyledDescription>{description}</StyledDescription>
    </StyledBaseModalContent>
  );
};
