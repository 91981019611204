import { NO_PERMISSION_ERROR } from 'config/constants';
import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { useUserPermission } from 'hooks/useUserPermission';
import { paginationWrapperSchema } from 'schemas';
import { apiSlice } from 'store/apiSlice';
import { LoaderStatusEnum, UserPermissionEnum } from 'types';
import { validateAndLogErrors } from 'utils/schemaValidator';

import {
  AuditAppointmentsDetailsParams,
  AuditAppointmentsResponse,
} from './types';
import endpoints from '../../config/endpoints';
import { auditAppointmentSchema } from '../../schemas/AuditAppointment';
import { formatQueryParams } from '../../utils/formatQueryParams';

const initialState: AuditAppointmentsResponse = {
  appointments: [],
  total: 0,
};

const appointmentsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditsAppointments: build.query<
      AuditAppointmentsResponse,
      AuditAppointmentsDetailsParams
    >({
      query: ({ supplierId = '', ...params }) => ({
        url: endpoints.SUPPLIERS.DETAILS_APPOINTMENTS(supplierId),
        params: formatQueryParams(params),
      }),
      providesTags: ['APPOINTMENTS'],
      keepUnusedDataFor: 10,
      transformResponse: validateAndLogErrors(
        paginationWrapperSchema(auditAppointmentSchema, 'appointments')
      ),
    }),
  }),
});

export const useGetAuditsAppointmentsQuery = (
  params: AuditAppointmentsDetailsParams
) => {
  const isPermitted = useUserPermission(
    UserPermissionEnum.AUDIT_APPOINTMENT_LIST
  );
  const query = appointmentsApi.useGetAuditsAppointmentsQuery(params, {
    skip: !isPermitted,
  });
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  if (!isPermitted) {
    return {
      ...query,
      auditsAppointments: initialState,
      status: LoaderStatusEnum.ERROR,
      error: {
        message: NO_PERMISSION_ERROR,
      },
    };
  }

  return {
    ...query,
    auditsAppointments: query.data || initialState,
    status,
    error,
  };
};
