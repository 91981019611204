/**
 * TODO: Once confirmations feature is implemented on BE please adjust imports
 */
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Logger } from 'components/atoms/Logger';
import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { AlertList } from 'components/molecules/AlertList';
import { AttributeTable } from 'components/molecules/AttributeTable';
// import { ConfirmationSection } from 'components/molecules/ConfirmationSection';
import {
  DraftStatusEnum,
  TemplateCmpTypeEnum /* WithUndefined */,
} from 'types';
import { generateId } from 'utils/generateId';
import { getStatus } from 'utils/getStatus';
import { getTranslatedValue } from 'utils/getTranslatedValue';
import { isAnyLoading } from 'utils/isAnyLoading';

// import { useConfirmationsInformation } from '../../../hooks/useConfirmationsInformation';
import { useAuditDraftDetailsQuery } from '../../../state/auditDraftDetails/api';
import {
  // useGetAuditConfirmationsInformationQuery,
  useSetConfirmationsInformationMutation,
} from '../../../state/confirmationSubmit/api';
import { useSubmitAuditDraftMutation } from '../../../state/submitAuditDraft/api';
import { setSubmitStatus } from '../../../state/submitAuditDraft/slice';
import {
  // ConfirmationsInformationConfig,
  FormConfirmationSubmitStep,
} from '../../../types';
import { WarningBox } from '../../molecules/WarningBox';
import { TemplateAuditDraftStep } from '../../templates/TemplateAuditDraftStep';

const StyledStepContent = styled('div')`
  margin: 0 10px;
`;

const StyledContainer = styled('div')`
  margin-top: 30px;
`;

interface FormConfirmationSubmitProps extends FormConfirmationSubmitStep {
  auditDraftId: string;
}

export const FormConfirmationSubmit: FC<FormConfirmationSubmitProps> = ({
  children,
  auditDraftId,
  props,
}) => {
  const { backAction, instructionText, nextAction, title } = props;
  const dispatch = useDispatch();
  const {
    auditDraftDetails,
    status: auditDraftDetailsStatus,
    error: auditDraftError = [],
    refetch,
  } = useAuditDraftDetailsQuery(auditDraftId);
  /**
   * TODO: Once confirmations feature is implemented on BE please adjust code below
   */
  // const {
  //   confirmationsInformation,
  //   status: confirmationsInformationStatus,
  //   error: confirmationsInformationError = [],
  // } = useGetAuditConfirmationsInformationQuery({ auditDraftId });
  // const possibleConfirmations = children.find(
  //   (step) => step.type === StepCmpTypeEnum.CONFIRMATION_SECTION
  // ) as WithUndefined<ConfirmationsInformationConfig>;
  // const {
  //   allMandatoryAreChecked,
  //   confirmationsState,
  //   getConfirmationsToDisplay,
  //   handleChange,
  // } = useConfirmationsInformation(
  //   possibleConfirmations,
  //   confirmationsInformation,
  //   auditDraftDetails?.confirmations
  // );
  const {
    status: confirmationStatus,
    error: confirmationError = [],
    // setConfirmations,
  } = useSetConfirmationsInformationMutation();
  const {
    status: submitStatus,
    error: submitError = [],
    submitAuditDraft,
  } = useSubmitAuditDraftMutation();

  const isLoading = isAnyLoading(
    auditDraftDetailsStatus
    // confirmationsInformationStatus
  );
  const isSubmitLoading = isAnyLoading(confirmationStatus, submitStatus);
  const finalError = [
    ...auditDraftError,
    // ...confirmationsInformationError,
    ...confirmationError,
    ...submitError,
  ];
  const showError = finalError.length > 0;
  /**
   * TODO: Once confirmations feature is implemented on BE please adjust code below
   */
  // const disableNext = isLoading || !allMandatoryAreChecked;
  const disableNext = isLoading;

  const handleNextClick = () => {
    /**
     * TODO: Once confirmations feature is implemented on BE please uncomment/adjust code below
     */
    // setConfirmations({ confirmations: confirmationsState, auditDraftId })
    //   .then(() => submitAuditDraft({ auditDraftId }))
    //   .then(refetch);
    /**
     * TODO: Once confirmations feature is implemented on BE remove code below
     */
    submitAuditDraft({ auditDraftId }).then(() => {
      dispatch(setSubmitStatus(DraftStatusEnum.SUBMITTED));
      refetch();
    });
  };

  return (
    <TemplateAuditDraftStep
      contentStatus={getStatus(isLoading)}
      onNextClick={handleNextClick}
      nextButtonStatus={getStatus(isSubmitLoading)}
      backAction={backAction}
      nextAction={nextAction}
      disableNext={disableNext}
    >
      <StepFormTitle content={getTranslatedValue(title)} />

      <StyledStepContent>
        {children.map((child, index) => {
          if (child.type === TemplateCmpTypeEnum.WARNING_BOX) {
            return (
              <WarningBox
                key={generateId(child.type, index)}
                config={child}
                warningIds={auditDraftDetails?.warnings}
              />
            );
          }
          return null;
        })}

        {showError && <AlertList alerts={finalError} />}

        <StepDescription content={getTranslatedValue(instructionText)} />

        {children.map((step, index) => {
          if (step.type === TemplateCmpTypeEnum.ATTRIBUTE_TABLE) {
            return (
              <StyledContainer key={step.type}>
                <AttributeTable data={auditDraftDetails} rows={step.children} />
              </StyledContainer>
            );
          }
          /**
           * TODO: Uncomment once it's implemented on BE
           */
          // if (step.type === StepCmpTypeEnum.CONFIRMATION_SECTION) {
          //   return (
          //     <StyledContainer key={step.type}>
          //       <ConfirmationSection
          //         onConfirmationChange={handleChange}
          //         title={getTranslatedValue(step.props.instructionText)}
          //         items={getConfirmationsToDisplay(step)}
          //       />
          //     </StyledContainer>
          //   );
          // }
          return (
            <Logger
              key={generateId(step.type, index)}
              message={`Unsupported child type - ${step.type}.`}
            />
          );
        })}
      </StyledStepContent>
    </TemplateAuditDraftStep>
  );
};
