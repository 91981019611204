import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { AddDataParams } from './types';
import { endpoints } from '../../config/endpoints';

const auditDraftDataApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    addAuditDraftData: build.mutation<void, AddDataParams>({
      query: ({ auditDraftId, otherAuditorsDescription }) => ({
        url: endpoints.DATA.ADD(auditDraftId),
        data: {
          otherAuditorsDescription,
        },
        method: 'PUT',
      }),
    }),
  }),
});

export const useAddAuditDraftDataMutation = () => {
  const [addData, mutation] = auditDraftDataApi.useAddAuditDraftDataMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: AddDataParams) =>
    addData(params).unwrap();

  return {
    ...mutation,
    addData: requestMethod,
    status,
    error,
  };
};
