import { TranslatedText } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { Release } from '../../types';

export const getReleaseContentToDisplay =
  (translationsMap: Record<string, TranslatedText>) =>
  (release: Release): string =>
    release.content
      .map((contentId) => {
        if (!translationsMap[contentId]) {
          console.warn(
            `There is no available translations for ${contentId}, please review the template`
          );
          return contentId;
        }
        return getTranslatedValue(translationsMap[contentId]);
      })
      .join(', ');
