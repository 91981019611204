import { FileTypeResult, fromBuffer } from 'file-type';

const excludedExtensions = [
  { extension: '.ifs', type: 'application/json' },
  { extension: '.axpx', type: 'application/octet-stream' },
];

export const downloadFile = async (
  fileBuffer: ArrayBuffer,
  orgFilename: string
): Promise<void> => {
  let filename = orgFilename;
  const customFileConfig = excludedExtensions.find(({ extension }) =>
    filename.endsWith(extension)
  );

  // unsupported formats: .doc, .xls, .ppt, .msi, .csv, .svg
  const { ext, mime } =
    ((await fromBuffer(fileBuffer)) as FileTypeResult) || {};
  const type = customFileConfig ? customFileConfig.type : mime;
  const blob = new Blob([fileBuffer], { type });
  const url = window.URL.createObjectURL(blob);

  // add extension if there is no custom option and original filename doesn't consist of extracted ext
  if (!customFileConfig && ext && !orgFilename.endsWith(`.${ext}`)) {
    filename = `${orgFilename}.${ext}`;
    console.warn(
      `The system has not detected a file extension in the filename. Consequently, there is a chance that it may not be able to recognize the correct MIME type and file extension.`
    );
  }

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();

  window.URL.revokeObjectURL(url);
};
