import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useActionInformationData } from 'components/organisms/ActionInformationSection';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { LoaderStatusEnum, ModalProps, Nullable, StartEndDates } from 'types';
import { formatDate } from 'utils/dates';

import {
  addBlackoutTime,
  selectAddBlackoutTimeLoading,
} from '../../../state/addBlackoutTime/slice';
import { AddBlackoutTimeParams } from '../../../state/addBlackoutTime/types';
import { AddBlackoutTimeModalContent } from '../AddBlackoutTimeModalContent';
import { AuditAppointmentBaseModal } from '../AuditAppointmentBaseModal';

interface AddBlackoutTimeModalProps extends ModalProps {
  validDates?: Nullable<StartEndDates>;
  appointmentId?: string;
}

export const AddBlackoutTimeModal: FC<AddBlackoutTimeModalProps> = (props) => {
  const { open, handleClose, validDates, appointmentId } = props;
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const loadingStatus = useSelector(selectAddBlackoutTimeLoading);
  const isAdmin = useSelector(selectUserIsAdmin);
  const {
    isAdminInfoValid,
    adminReason,
    adminComment,
    handleAdminReasonChange,
    handleAdminCommentChange,
    setIsAdminInfoValid,
  } = useActionInformationData();

  const [startDate, setStartDate] = useState<Nullable<string>>(null);
  const [endDate, setEndDate] = useState<Nullable<string>>(null);

  const handleSave = () => {
    if (appointmentId && startDate && endDate) {
      const actionComment = { reason: adminReason, comment: adminComment };
      const data: AddBlackoutTimeParams = {
        appointmentId,
        startDate,
        endDate,
        actionComment: isAdminInfoValid ? actionComment : undefined,
      };
      dispatch(addBlackoutTime(data));
    }
  };

  const changeStartDate = (value: Date) => {
    setStartDate(formatDate(value, 'yyyy-MM-dd'));
  };

  const changeEndDate = (value: Date) => {
    setEndDate(formatDate(value, 'yyyy-MM-dd'));
  };

  return (
    <AuditAppointmentBaseModal open={open}>
      <TemplateAuditModal
        title={t('Add Blackout Period')}
        contentComponent={
          <AddBlackoutTimeModalContent
            validDates={validDates}
            startDate={startDate}
            endDate={endDate}
            changeStartDate={changeStartDate}
            changeEndDate={changeEndDate}
            isAdmin={isAdmin}
            setIsAdminInfoValid={setIsAdminInfoValid}
            adminReason={adminReason}
            adminComment={adminComment}
            adminReasonChangeHandler={handleAdminReasonChange}
            adminCommentChangeHandler={handleAdminCommentChange}
          />
        }
        closeAction={handleClose}
        prevAction={handleClose}
        nextAction={handleSave}
        noPrevArrow
        noNextArrow
        prevLabel={t('Cancel')}
        nextLabel={t('Save', { ns: 'components' })}
        prevColor="modalPrevButton"
        nextColor="primary"
        disableNext={!startDate || !endDate || (isAdmin && !isAdminInfoValid)}
        nextButtonLoading={loadingStatus === LoaderStatusEnum.LOADING}
        hidePrevButton={loadingStatus === LoaderStatusEnum.LOADING}
      />
    </AuditAppointmentBaseModal>
  );
};

export default AddBlackoutTimeModal;
