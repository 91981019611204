import {
  Certificate,
  CertificationBody,
  GeneralDetails,
  Message,
  NestedGeneralDetails,
  Result,
  StartEndDatesSuffixedType,
  Supplier,
} from 'types';

// Enum for the response we get from Backend API
export enum AuditActionsEnum {
  AUDIT_AMENDMENT_VIA_FILE = 'audit-amendment-via-audit-file',
  AUDIT_CB_SWITCH = 'audit-cb-switch',
  AUDIT_DELETE = 'audit-delete',
  AUDIT_DETAILVIEW = 'audit-detailview',
  AUDIT_ISSUE_GROUP_CERTIFICATE = 'audit-issue-group-certificate',
  AUDIT_LIST = 'audit-list',
  AUDIT_LOGS = 'audit-logs-view',
  AUDIT_RELEASES = 'audit-releases-view',
  AUDIT_RELEASE_CREATE = 'audit-release-create',
  AUDIT_RELEASE_UPDATE = 'audit-release-update',
  AUDIT_RELEASE_DELETE = 'audit-release-delete',
  CERTIFICATE_DETAILS = 'certificate-details',
  AUDIT_LOGS_OLD_FILE = 'audit-logs-old-file',
  AUDIT_ACCESSES = 'audit-iam-accesses-view',
}

export type AuditDetails = {
  uuid: string;
  version: number;
  status: GeneralDetails;
  certificationBody: CertificationBody;
  supplier: Supplier;
  module: GeneralDetails;
  type: GeneralDetails;
  kind: GeneralDetails;
  executionMode: GeneralDetails;
  auditTimeframe: StartEndDatesSuffixedType;
  appointmentUuid?: string;
  result: Result;
  certificate?: Certificate;
  messages?: Message[];
  productScopesAudit?: NestedGeneralDetails[];
};

export type Company = Supplier;

export interface Release {
  uuid: string;
  type: string;
  supplier: Supplier;
  releasedForCompany: Company;
  timeFrame: GeneralDetails;
  referencedAudit: {
    uuid: string;
    auid: string;
  };
  moduleFamily: GeneralDetails;
  content: string[];
}
