import { styled } from '@mui/material';
import { get } from 'lodash';
import React, { ChangeEvent, FC } from 'react';

import { DynamicFormItemLabel } from 'components/atoms/DynamicFormItemLabel';
import { Textarea as NativeTextarea } from 'components/atoms/Textarea';
import { WithUndefined } from 'types';

import { StyledSubItemsContainer } from './StyledElements';
import {
  DynamicFormCommonProps,
  DynamicFormItemCommonProps,
  TextareaType,
} from './types';

const StyledTextarea = styled(NativeTextarea)`
  width: 100%;
`;

type TextareaProps = TextareaType &
  DynamicFormCommonProps &
  DynamicFormItemCommonProps;

export const Textarea: FC<TextareaProps> = ({
  props,
  setValue,
  values,
  validateFormItem,
  validationState,
  subItems,
}) => {
  const { dataRef, label, maxLength } = props;

  const value = get(values, dataRef) || '';
  const error = get(validationState, dataRef) as WithUndefined<string>;

  const handleChange = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = ev.target.value;

    setValue(dataRef, newValue);
    validateFormItem(newValue, dataRef);
  };

  return (
    <div>
      <DynamicFormItemLabel text={label} error={error} />
      <StyledTextarea
        value={value}
        onChange={handleChange}
        multiline
        rows={4}
        maxLength={maxLength}
      />
      {!!subItems && (
        <StyledSubItemsContainer>{subItems}</StyledSubItemsContainer>
      )}{' '}
    </div>
  );
};
