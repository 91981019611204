import { styled } from '@mui/material';

interface StyledInputWrapperProps {
  $largerMargin?: boolean;
}

const StyledInputWrapper = styled('div')<StyledInputWrapperProps>`
  margin-bottom: ${({ $largerMargin }) => ($largerMargin ? '32px' : '18px')};
`;

StyledInputWrapper.displayName = 'StyledInputWrapper';

export { StyledInputWrapper };
