import { ButtonProps } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import ModalHeader from 'components/molecules/ModalHeader';
import { ButtonColorType, ButtonLoaderPositionType } from 'types';

import ModalContent from '../../atoms/AuditModalContent';
import ActionButtons from '../../molecules/AuditModalActionButtons';

interface TemplateAuditModalProps {
  title: ReactNode;
  stepperComponent?: ReactNode;
  contentComponent: ReactNode;
  prevButtonComponent?: ReactNode;
  nextButtonComponent?: ReactNode;
  disablePrev?: boolean;
  disableNext?: boolean;
  closeAction?: () => void;
  prevAction?: () => void;
  nextAction?: () => void;
  prevLabel?: ReactNode | string;
  nextLabel?: ReactNode | string;
  noPrevArrow?: boolean;
  noNextArrow?: boolean;
  prevColor?: ButtonColorType;
  nextColor?: ButtonColorType;
  hidePrevButton?: boolean;
  zeroPaddingContent?: boolean;
  showCloseButton?: boolean;
  nextButtonLoading?: boolean;
  nextButtonLoadingPosition?: ButtonLoaderPositionType;
  prevVariant?: ButtonProps['variant'];
  nextVariant?: ButtonProps['variant'];
}

export const TemplateAuditModal: FC<TemplateAuditModalProps> = (props) => {
  const {
    title,
    stepperComponent,
    contentComponent,
    disableNext,
    disablePrev,
    closeAction,
    prevAction,
    nextAction,
    prevLabel,
    nextLabel,
    prevButtonComponent,
    nextButtonComponent,
    noNextArrow,
    noPrevArrow,
    prevColor,
    nextColor,
    zeroPaddingContent,
    hidePrevButton,
    showCloseButton = true,
    nextButtonLoading,
    nextButtonLoadingPosition,
    nextVariant,
    prevVariant,
  } = props;

  return (
    <>
      <ModalHeader
        title={title}
        closeAction={closeAction}
        showCloseButton={showCloseButton && !hidePrevButton} // don't show close button when prev button is hidden (i.e. status is loading)
      />
      {stepperComponent}
      <ModalContent zeroPaddingContent={zeroPaddingContent}>
        {contentComponent}
      </ModalContent>
      <ActionButtons
        prevAction={prevAction}
        nextAction={nextAction}
        disabledPrev={disablePrev}
        disabledNext={disableNext}
        prevLabel={prevLabel}
        nextLabel={nextLabel}
        prevButtonComponent={prevButtonComponent}
        nextButtonComponent={nextButtonComponent}
        noNextArrow={noNextArrow}
        noPrevArrow={noPrevArrow}
        prevColor={prevColor}
        nextColor={nextColor}
        hidePrevButton={hidePrevButton}
        nextButtonLoading={nextButtonLoading}
        nextButtonLoadingPosition={nextButtonLoadingPosition}
        nextVariant={nextVariant}
        prevVariant={prevVariant}
      />
    </>
  );
};

export default TemplateAuditModal;
