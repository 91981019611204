import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { LoaderStatusEnum } from 'types';

import { ConditionalModalProps } from './types';
import { getAuditAppointment } from '../../../state/details/slice';
import { useReactivateAuditAppointmentMutation } from '../../../state/reactivate/api';
import {
  selectReactivateAuditAppointmentIsModalOpen,
  setReactivateAuditAppointmentIsModalOpen,
} from '../../../state/reactivate/slice';
import { ReactivateAuditAppointmentModal } from '../ReactivateAuditAppointmentModal';

export const ConditionalReactivateAuditAppointmentModal: FC<ConditionalModalProps> =
  ({ appointmentId }) => {
    const [t] = useTranslation('auditAppointment');
    const notificationMessage = t('The Audit Appointment is reactivated.');
    const { openSnackbar } = useSnackbarNotification();
    const dispatch = useDispatch();
    const open = useSelector(selectReactivateAuditAppointmentIsModalOpen);
    const { status: loading, reset } = useReactivateAuditAppointmentMutation();

    const closeModal = () => {
      dispatch(setReactivateAuditAppointmentIsModalOpen(false));
      reset?.();
    };

    useEffect(() => {
      if (loading === LoaderStatusEnum.SUCCESS) {
        dispatch(setReactivateAuditAppointmentIsModalOpen(false));
        reset?.();
        dispatch(getAuditAppointment(appointmentId)); // refetch audit appointment details if success
        openSnackbar(notificationMessage, 'success');
      }
    }, [
      reset,
      loading,
      appointmentId,
      dispatch,
      openSnackbar,
      notificationMessage,
    ]);

    if (!open) return null;

    return (
      <ReactivateAuditAppointmentModal
        open={open}
        appointmentId={appointmentId}
        handleClose={closeModal}
      />
    );
  };
