import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { PRIMARY } from 'config/appColors';
import { LanguageEnum, Nullable } from 'types';
import { checkIfEnumValueExists } from 'utils/checkIfEnumValueExists';

import { flags } from './FlagsIcons';

interface FlagProps {
  size?: number;
  country?: Nullable<string>;
  fallbackComponent?: ReactNode;
  noFallbackComponent?: boolean;
}

type StyledElementProps = Required<Pick<FlagProps, 'size'>>;

const StyledFlagWrapper = styled('span')<StyledElementProps>`
  display: inline-flex;
  align-items: center;
  width: ${(props) => `${props.size}px`};

  & > svg {
    width: 100%;
  }
`;
const StyledIsoText = styled('span')<StyledElementProps>`
  width: ${(props) => `${props.size + 2}px`};
  aspect-ratio: 1.26/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px ${PRIMARY.MAIN} solid;
  border-radius: 2px;
  font-size: ${(props) => `${props.size / 2}px`};
  font-weight: 400;
  color: ${PRIMARY.MAIN};
  text-transform: uppercase;
`;

export const Flag: FC<FlagProps> = (props) => {
  const {
    country,
    fallbackComponent = 'n/a',
    size = 20,
    noFallbackComponent = false,
  } = props;

  if (!country) return <>{!noFallbackComponent && fallbackComponent}</>;

  if (!checkIfEnumValueExists(LanguageEnum, country)) {
    return <StyledIsoText size={size}>{country.toLowerCase()}</StyledIsoText>;
  }

  return (
    <StyledFlagWrapper size={size}>
      {flags[country as LanguageEnum]}
    </StyledFlagWrapper>
  );
};
