import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { BaseModal } from 'components/atoms/BaseModal';
import { ARGENT } from 'config/appColors';

interface AuditAppointmentBaseModalProps {
  open: boolean;
  children?: ReactNode;
}

const StyledBaseModal = styled(BaseModal)`
  .MuiDialog-container {
    height: auto;
    min-height: 100%;
  }
  .MuiDialog-paper {
    width: 600px;
    max-width: 600px;
    min-height: 303px;
  }
  .MuiDialogContent-root {
    min-height: auto;
    padding: 0;
    border-bottom: 1px solid ${ARGENT};
    margin-bottom: 16px;
  }
`;

export const AuditAppointmentBaseModal: FC<AuditAppointmentBaseModalProps> = (
  props
) => {
  const { open, children } = props;
  return <StyledBaseModal open={open}>{children}</StyledBaseModal>;
};
