import { CertificateLock } from 'types';

import { CommonFormValues } from '../../state/modal/types';

export const getFormValuesFromLock = (
  lock: CertificateLock
): Omit<CommonFormValues, 'actionComment'> => {
  const {
    category: { id: category },
    categoryOthersDescription,
    descriptionEnglish,
    descriptionOtherLanguage,
    type: { id: type },
  } = lock;

  return {
    category,
    descriptionEnglish,
    ...(categoryOthersDescription && {
      categoryOthersDescription,
    }),
    ...(descriptionOtherLanguage && {
      descriptionOtherLanguage,
    }),
    type,
  };
};
