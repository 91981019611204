import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { invalidateRTKTags } from 'utils/invalidateRTKTags';

import { LockCertificateParams, UpdateLockReasonParams } from './types';
import endpoints from '../../config/endpoints';

const certificateLockApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    lockCertificate: build.mutation<void, LockCertificateParams>({
      query: ({ certificateUuid, ...data }) => ({
        url: endpoints.CERTIFICATE_LOCK.LOCK_CERTIFICATE(certificateUuid),
        data,
        method: 'POST',
      }),
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_DETAILS',
        'CERTIFICATE_DETAILS_ACTIONS',
      ]),
    }),
    updateCertificateLockReason: build.mutation<void, UpdateLockReasonParams>({
      query: ({ certificateUuid, lockUuid, ...data }) => ({
        url: endpoints.CERTIFICATE_LOCK.UPDATE_LOCK_REASON(
          certificateUuid,
          lockUuid
        ),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_DETAILS',
        'CERTIFICATE_DETAILS_ACTIONS',
        'CERTIFICATE_DETAILS_LOCK_HISTORY',
      ]),
    }),
  }),
});

export const useLockCertificateMutation = () => {
  const [lockCertificate, mutation] =
    certificateLockApi.useLockCertificateMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: LockCertificateParams) =>
    lockCertificate(params).unwrap();

  return {
    ...mutation,
    lockCertificate: requestMethod,
    status,
    error,
  };
};

export const useUpdateLockReasonMutation = () => {
  const [updateLockReason, mutation] =
    certificateLockApi.useUpdateCertificateLockReasonMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: UpdateLockReasonParams) =>
    updateLockReason(params).unwrap();

  return {
    ...mutation,
    updateLockReason: requestMethod,
    status,
    error,
  };
};
