import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/atoms/Button';
import { DottedButton } from 'components/atoms/DottedButton';
import { SectionTitle } from 'components/atoms/SectionTitle';
import DropdownMenu from 'components/molecules/DropdownMenu';
import { Column, Table } from 'components/organisms/Table';
import {
  resetDeleteBlackoutTimeState,
  selectDeleteBlackoutTimeLoading,
} from 'domains/auditAppointment/state/deleteBlackoutTime/slice';
import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { LoaderStatusEnum } from 'types';

import { AuditAppointmentActionsEnum } from '../../../config/actions';
import { useIsPermittedTo } from '../../../hooks/useIsPermittedTo';
import {
  resetAddBlackoutTimeState,
  selectAddBlackoutTimeLoading,
} from '../../../state/addBlackoutTime/slice';
import {
  fetchBlackoutTimes,
  selectBlackoutTimes,
  selectBlackoutTimesError,
  selectBlackoutTimesLoading,
} from '../../../state/blackoutTimes/slice';
import { selectAuditAppointmentDetails } from '../../../state/details/slice';
import { BlackoutTime } from '../../../types';
import { AddBlackoutTimeModal } from '../AddBlackoutTimeModal';
import { DeleteBlackoutTimeModal } from '../DeleteBlackoutTimeModal';
import { dropdownItemStyles } from '../DetailsTabGeneralSection';

interface DetailsTabBlackoutTimesSectionProps {
  appointmentId: string;
}

const StyledHeaderGrid = styled(Grid)`
  margin-bottom: 2rem;
  padding-right: 1rem;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsTabBlackoutTimesSection: FC<DetailsTabBlackoutTimesSectionProps> =
  ({ appointmentId }) => {
    const [t] = useTranslation('auditAppointment');
    const dispatch = useDispatch();
    const { openSnackbar } = useSnackbarNotification();
    const blackoutTimes = useSelector(selectBlackoutTimes);
    const blackoutTimesLoading = useSelector(selectBlackoutTimesLoading);
    const blackoutTimesError = useSelector(selectBlackoutTimesError);
    const [openAddBlackoutTimeModal, setOpenAddBlackoutTimeModal] =
      useState(false);
    const [openDeleteBlackoutTimeModal, setOpenDeleteBlackoutTimeModal] =
      useState(false);
    const [selectedBlackoutTime, setSelectedBlackoutTime] =
      useState<BlackoutTime>();
    const { isPermittedTo, isPermittedToOneOf } = useIsPermittedTo();

    const auditAppointmentDetails = useSelector(selectAuditAppointmentDetails);
    const addBlackoutTimeLoading = useSelector(selectAddBlackoutTimeLoading);
    const deleteBlackoutTimeLoading = useSelector(
      selectDeleteBlackoutTimeLoading
    );

    const getDropdownOptions = (uuid: string, start: string, end: string) => [
      ...(isPermittedTo(AuditAppointmentActionsEnum.BLACKOUT_DELETE)
        ? [
            {
              label: t('Delete', { ns: 'components' }),
              sx: dropdownItemStyles.dropdownDeleteItemStyle,
              onClickAction: () => {
                setSelectedBlackoutTime({ uuid, start, end });
                setOpenDeleteBlackoutTimeModal(true);
              },
            },
          ]
        : []),
    ];

    const getActionColumn = (): Column<BlackoutTime>[] =>
      isPermittedToOneOf([AuditAppointmentActionsEnum.BLACKOUT_DELETE])
        ? [
            {
              headerName: '',
              cellProps: { align: 'right' },
              customCell: ({ uuid, start, end }) => (
                <DropdownMenu
                  options={getDropdownOptions(uuid, start, end)}
                  renderOpenButton={({ onClick }) => (
                    <DottedButton
                      onClick={onClick}
                      dataTestId="audit-appointment-blackout-dropdown-button"
                    />
                  )}
                />
              ),
            },
          ]
        : [];

    const columns: Column<BlackoutTime>[] = [
      {
        field: 'start',
        headerName: t('Start Date'),
        cellProps: { width: 200 },
        headerProps: { width: 200 },
      },
      {
        field: 'end',
        headerName: t('End Date'),
        cellProps: { width: 200 },
        headerProps: { width: 200 },
      },
      ...getActionColumn(),
    ];

    const closeAddBlackoutTimeModal = () => {
      setOpenAddBlackoutTimeModal(false);
      dispatch(resetAddBlackoutTimeState()); // reset state to IDLE
    };

    const closeDeleteBlackoutTimeModal = () => {
      setOpenDeleteBlackoutTimeModal(false);
      dispatch(resetDeleteBlackoutTimeState()); // reset state to IDLE
    };

    useEffect(() => {
      dispatch(fetchBlackoutTimes(appointmentId));
    }, [appointmentId, dispatch]);

    useEffect(() => {
      if (addBlackoutTimeLoading === LoaderStatusEnum.SUCCESS) {
        setOpenAddBlackoutTimeModal(false); // close modal
        dispatch(fetchBlackoutTimes(appointmentId)); // refetch blackout times if success
        dispatch(resetAddBlackoutTimeState()); // reset addBlackoutTime state to IDLE
        openSnackbar('Blackout time is added.', 'success');
      }
    }, [addBlackoutTimeLoading, appointmentId, dispatch, openSnackbar]);

    useEffect(() => {
      if (deleteBlackoutTimeLoading === LoaderStatusEnum.SUCCESS) {
        setOpenDeleteBlackoutTimeModal(false); // close modal
        dispatch(fetchBlackoutTimes(appointmentId)); // refetch  blackout times if success
        dispatch(resetDeleteBlackoutTimeState()); // reset deleteBlackoutTime state to IDLE
        openSnackbar('Blackout time is deleted.', 'success');
      }
    }, [deleteBlackoutTimeLoading, appointmentId, dispatch, openSnackbar]);

    return (
      <>
        <StyledHeaderGrid container>
          <SectionTitle>{t('Blackout periods')}</SectionTitle>
          {isPermittedTo(AuditAppointmentActionsEnum.BLACKOUT_ADD) && (
            <Button
              variant="contained"
              color="info"
              data-testid="add-blackout-time-btn"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddBlackoutTimeModal(true)}
            >
              {t('Add period')}
            </Button>
          )}
        </StyledHeaderGrid>
        <Table
          rows={blackoutTimes}
          columns={columns}
          status={blackoutTimesLoading}
          error={blackoutTimesError}
          dense
        />
        {openAddBlackoutTimeModal && (
          <AddBlackoutTimeModal
            appointmentId={appointmentId}
            open={openAddBlackoutTimeModal}
            handleClose={closeAddBlackoutTimeModal}
            validDates={auditAppointmentDetails?.unannouncedPeriod}
          />
        )}
        {openDeleteBlackoutTimeModal && (
          <DeleteBlackoutTimeModal
            appointmentId={appointmentId}
            open={openDeleteBlackoutTimeModal}
            handleClose={closeDeleteBlackoutTimeModal}
            blackoutTime={selectedBlackoutTime}
          />
        )}
      </>
    );
  };
