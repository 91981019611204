import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from 'components/atoms/SectionTitle';

import { AuditListTable } from '../AuditListTable';

const StyledContainer = styled('div')`
  padding: 25px;
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 25px;
`;

export const DeletedAuditsTab: FC = () => {
  const [t] = useTranslation('supplier');

  return (
    <StyledContainer>
      <StyledSectionTitle>{t('Deleted Audits/Assessments')}</StyledSectionTitle>
      <AuditListTable showDeletedAudits />
    </StyledContainer>
  );
};
