import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Pagination, SortStateType } from 'components/organisms/Table/types';
import { UNEXPECTED_ERROR } from 'config/constants';
import { RootState } from 'store';
import {
  AsyncThunkOptions,
  LoaderStatusEnum,
  Nullable,
  ProcessStatus,
} from 'types';
import apiClient from 'utils/apiClient';

import {
  DiaryAuditRegistrationsFilter,
  DiaryAuditRegistrationsState,
  StringValuePayload,
} from './types';
import {
  DIARY_MIN_SEARCH_CHARS,
  parentReducerName,
} from '../../config/constants';
import endpoints from '../../config/endpoints';
import { DiaryExportParams, ProcessValuePayload } from '../../types';

const initialFilterState: DiaryAuditRegistrationsState['filter'] = {
  status: [ProcessStatus.OPEN],
  moduleId: '',
  supplierNameCoidSearchString: '',
};

const initialState: DiaryAuditRegistrationsState = {
  exportLoading: LoaderStatusEnum.IDLE,
  sort: null,
  filter: initialFilterState,
  pagination: null,
};

const reducerName = `${parentReducerName}/diaryRegistrations`;

// thunks

// TODO: placeholder for exporting audit appointments to excel - solution TBD
export const exportDiaryAuditRegistrationsList = createAsyncThunk<
  boolean, // temporary returned type
  DiaryExportParams,
  AsyncThunkOptions
>(
  `${reducerName}/exportDiaryAuditRegistrationsList`,
  async (
    { onExportErrorCallback },
    { rejectWithValue, fulfillWithValue, getState }
  ) => {
    const certificationBodyId =
      getState().user.auth.userDetails?.currentContext.companyId || '';
    const { filter, sort, pagination } =
      getState().auditAppointment.diaryAuditRegistrations;
    const params = {
      certificationBodyId,
      offset: pagination?.offset,
      limit: pagination?.limit,
      export: 'excel',
      filter: { ...filter, status: [filter.status] },
      ...(sort && { sort: `${sort.key}:${sort.direction}` }),
    };
    try {
      const response = await apiClient.get<boolean>(
        endpoints.DIARY.AUDIT_REGISTRATIONS_LIST(certificationBodyId),
        {
          params,
        }
      );
      return fulfillWithValue(response.data, null);
    } catch (err) {
      onExportErrorCallback();
      return rejectWithValue([UNEXPECTED_ERROR]);
    }
  }
);

// slice
export const diaryAuditRegistrationsSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setFilterState: (
      state,
      { payload }: PayloadAction<StringValuePayload | ProcessValuePayload>
    ) => {
      if (payload.key === 'status') {
        state.filter[payload.key] = [payload.value];
      } else {
        state.filter[payload.key] = payload.value;
      }
    },
    setSortState: (state, action: PayloadAction<Nullable<SortStateType>>) => {
      state.sort = action.payload;
    },
    setPaginationState: (
      state,
      action: PayloadAction<Nullable<Pagination>>
    ) => {
      state.pagination = action.payload;
    },
    resetFilterAndSortState: (state) => {
      state.sort = null;
      state.filter = initialFilterState;
    },
    resetDiaryAuditAppointmentExportLoading: (state) => {
      state.exportLoading = LoaderStatusEnum.IDLE;
    },
    resetDiaryAuditAppointmentState: () => initialState,
    resetSearchPhrase: (state) => {
      state.filter.supplierNameCoidSearchString = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(exportDiaryAuditRegistrationsList.fulfilled, (state) => {
      state.exportLoading = LoaderStatusEnum.SUCCESS;
    });
    builder.addCase(exportDiaryAuditRegistrationsList.rejected, (state) => {
      state.exportLoading = LoaderStatusEnum.ERROR;
    });
    builder.addCase(exportDiaryAuditRegistrationsList.pending, (state) => {
      state.exportLoading = LoaderStatusEnum.LOADING;
    });
  },
});

// actions
export const {
  resetDiaryAuditAppointmentState,
  setSortState,
  setFilterState,
  setPaginationState,
  resetDiaryAuditAppointmentExportLoading,
  resetFilterAndSortState,
  resetSearchPhrase,
} = diaryAuditRegistrationsSlice.actions;

// selectors
export const selectDiaryAuditRegistrationsFilter = (
  state: RootState
): DiaryAuditRegistrationsFilter =>
  state[parentReducerName].diaryAuditRegistrations.filter;
export const selectDiaryAuditRegistrationsSortState = (
  state: RootState
): Nullable<SortStateType> =>
  state[parentReducerName].diaryAuditRegistrations.sort;
export const selectDiaryAuditRegistrationsExportLoading = (
  state: RootState
): LoaderStatusEnum =>
  state[parentReducerName].diaryAuditRegistrations.exportLoading;
export const selectSearchMinCharError = (state: RootState): boolean => {
  const searchPhraseLength =
    state[parentReducerName].diaryAuditRegistrations.filter
      .supplierNameCoidSearchString.length;

  return searchPhraseLength > 0 && searchPhraseLength < DIARY_MIN_SEARCH_CHARS;
};

export default diaryAuditRegistrationsSlice.reducer;
