import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { SetAuditDraftUpdateReasonParams } from './types';
import { endpoints } from '../../config/endpoints';

const setUpdateReasonApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    setAuditDraftUpdateReason: build.mutation<
      void,
      SetAuditDraftUpdateReasonParams
    >({
      query: ({ auditDraftId, reason }) => ({
        url: endpoints.SET_REASON(auditDraftId),
        data: reason,
        method: 'PUT',
      }),
    }),
  }),
});

export const useAuditDraftSetUpdateReasonMutation = () => {
  const [setAuditDraftUpdateReason, mutation] =
    setUpdateReasonApi.useSetAuditDraftUpdateReasonMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: SetAuditDraftUpdateReasonParams) =>
    setAuditDraftUpdateReason(params).unwrap();

  return {
    ...mutation,
    setAuditDraftUpdateReason: requestMethod,
    status,
    error,
  };
};
