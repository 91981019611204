import routes from 'config/routes';

import { UUID_V4 } from '../regex/uuid';

export const getAuditDraftInitUpdateDocsPathname = (
  supplierId: string,
  auditId: string
): string =>
  routes.AUDIT_DRAFT_UPDATE_DOCUMENTS_INIT.replace(
    `:supplierId(${UUID_V4})`,
    supplierId
  ).replace(`:auditId(${UUID_V4})`, auditId);
