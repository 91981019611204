import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { WithUndefined } from 'types';

import { parentReducerName } from '../../config/constants';
import { AuditDraftStepEnum } from '../../types';

interface AuditDraftStepperState {
  currentStep: WithUndefined<AuditDraftStepEnum>;
}

const initialState: AuditDraftStepperState = {
  currentStep: undefined,
};

const reducerName = `${parentReducerName}/auditDraftStepper`;

// slice
export const auditDraftStepperSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    resetStep: () => initialState,
  },
});

// actions
export const { setCurrentStep, resetStep } = auditDraftStepperSlice.actions;

// selectors
export const selectAuditDraftCurrentStep = (
  state: RootState
): WithUndefined<AuditDraftStepEnum> =>
  state[parentReducerName].stepper.currentStep;

// reducer
export default auditDraftStepperSlice.reducer;
