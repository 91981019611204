import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ActionDropdownOption, CertificateLock } from 'types';

import { useGetCertificateDetailsActions } from '../state/certificateDetailsActions/api';
import { openCertificateModal, setUpdateUuid } from '../state/modal/slice';
import { CertificateDetailsModalEnum } from '../state/modal/types';
import { CertificateActionsEnum } from '../types';
import { getFormValuesFromLock } from '../utils/getFormValuesFromLock';

interface UseLockHistoryActionsDropdownProps {
  lockHistoryItem: CertificateLock;
  certificateUuid: string;
}

export type { UseLockHistoryActionsDropdownProps as LockHistoryActionsDropdownProps };

export const useLockHistoryActionsDropdown = (
  props: UseLockHistoryActionsDropdownProps
): ActionDropdownOption[] => {
  const [t] = useTranslation('certificateDetails');
  const {
    lockHistoryItem: {
      /**
       * uuid - depending on action it is suspensionUuid or withdrawUuid etc. that is required in performed action
       */
      uuid,
      unlockReasonDescription,
    },
    certificateUuid,
  } = props;
  const { certificateDetailsActions } =
    useGetCertificateDetailsActions(certificateUuid);
  const dispatch = useDispatch();

  return [
    {
      label: t('Update lock reason'),
      onClickAction: () => {
        dispatch(setUpdateUuid(uuid));
        dispatch(
          openCertificateModal({
            name: CertificateDetailsModalEnum.UPDATE_LOCK_REASON,
            values: getFormValuesFromLock(props.lockHistoryItem),
          })
        );
      },
      permission: CertificateActionsEnum.CERTIFICATE_LOCK_UPDATE,
    },
    {
      label: t('Update reinstatement'),
      onClickAction: () => {
        dispatch(setUpdateUuid(uuid));
        dispatch(
          openCertificateModal({
            name: CertificateDetailsModalEnum.UPDATE_UNLOCK_REASON,
            values: { reasonDescription: unlockReasonDescription || '' },
          })
        );
      },
      permission: CertificateActionsEnum.CERTIFICATE_REINSTATE_UPDATE,
      additionalConditions: !!unlockReasonDescription,
    },
  ].filter(
    ({ permission, additionalConditions = true }) =>
      certificateDetailsActions.includes(permission) && additionalConditions
  );
};
