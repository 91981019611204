import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { AuditDetailsTemplateResponse } from './types';
import endpoints from '../../config/endpoints';

const auditDetailsTemplateApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditDetailsTemplate: build.query<AuditDetailsTemplateResponse, string>({
      query: (auditUuid: string) => ({
        url: endpoints.TEMPLATE(auditUuid),
      }),
      providesTags: ['AUDIT_DETAILS_TEMPLATE'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetAuditDetailsTemplateQuery = (
  auditUuid: string,
  skip = false
) => {
  const query = auditDetailsTemplateApi.useGetAuditDetailsTemplateQuery(
    auditUuid,
    { skip }
  );
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    auditDetailsTemplate: query.data,
    error,
    status,
  };
};
