import React, { FC } from 'react';

import { ChangeCertificationBodyModal } from 'domains/certificationBody/components/organisms/ChangeCertificationBodyModal';

import { closeAndResetAuditModal } from '../../../state/modal/slice';
import { AuditModalCommonProps } from '../../../state/modal/types';

type AuditCBChangeModalProps = AuditModalCommonProps;

export const AuditCBChangeModal: FC<AuditCBChangeModalProps> = ({
  auditUuid,
}) => (
  <ChangeCertificationBodyModal
    collection="audits"
    relatedEntityUuid={auditUuid}
    closeAction={closeAndResetAuditModal}
  />
);
