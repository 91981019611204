import Group from '@mui/icons-material/Group';
import { styled } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

const StyledGroupIcon = styled(Group)`
  margin-bottom: -5px;
`;

export const CertificationBodyCell: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <>
      <StyledGroupIcon fontSize="large" /> <span>{children}</span>
    </>
  );
};
