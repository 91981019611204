import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';

import {
  ActionCommentPayload,
  CertificateDetailsModalState,
  FormValuesState,
  OpenCertificateModalPayload,
  StringFormValuePayload,
} from './types';
import { parentReducerName } from '../../config/constants';
import { AdditionalAudit } from '../additionalAuditManage/types';
import { AdditionalSupplier } from '../certificateGroupCertificateTab/types';

const initialFormValues: FormValuesState = {
  category: '',
  descriptionEnglish: '',
  descriptionOtherLanguage: '',
  reasonDescription: '',
  categoryOthersDescription: '',
  circumstanceType: '',
  circumstanceDescription: '',
  actionComment: {
    reason: '',
  },
  type: '',
};
const initialState: CertificateDetailsModalState = {
  openModalName: null,
  commonFormValues: initialFormValues,
  updateUuid: null, // used to lock certificate, update lock reason, etc.
  supplierToRemove: null,
  auditToRemove: null,
};

const reducerName = `${parentReducerName}/modal`;

export const certificateDetailsModalSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    openCertificateModal: (
      state,
      action: PayloadAction<OpenCertificateModalPayload>
    ) => {
      const { name, values } = action.payload;

      state.openModalName = name;

      if (values) {
        state.commonFormValues = {
          ...state.commonFormValues,
          ...values,
        };
      }
    },
    closeAndResetCertificateModal: () => initialState,
    setStringFormValue: (
      state,
      action: PayloadAction<StringFormValuePayload>
    ) => {
      state.commonFormValues[action.payload.name] = action.payload.value;
    },
    setActionCommentValue: (
      state,
      action: PayloadAction<ActionCommentPayload>
    ) => {
      const { name, value } = action.payload;

      state.commonFormValues.actionComment = {
        ...state.commonFormValues.actionComment,
        [name]: value,
      };
    },
    setUpdateUuid: (state, action) => {
      state.updateUuid = action.payload;
    },
    setSupplierToRemove: (state, action: PayloadAction<AdditionalSupplier>) => {
      state.supplierToRemove = action.payload;
    },
    setAuditToRemove: (state, action: PayloadAction<AdditionalAudit>) => {
      state.auditToRemove = action.payload;
    },
  },
});

// actions
export const {
  openCertificateModal,
  closeAndResetCertificateModal,
  setStringFormValue,
  setActionCommentValue,
  setUpdateUuid,
  setSupplierToRemove,
  setAuditToRemove,
} = certificateDetailsModalSlice.actions;

// selectors
export const selectCertificateDetailsModalName = (
  state: RootState
): CertificateDetailsModalState['openModalName'] =>
  state[parentReducerName].openModalName;
export const selectCertificateDetailsModalFormValues = (
  state: RootState
): CertificateDetailsModalState['commonFormValues'] =>
  state[parentReducerName].commonFormValues;
export const selectCertificateDetailsUpdateUuid = (
  state: RootState
): CertificateDetailsModalState['updateUuid'] =>
  state[parentReducerName].updateUuid;
export const selectCertificateDetailsSupplierToRemove = (
  state: RootState
): CertificateDetailsModalState['supplierToRemove'] =>
  state[parentReducerName].supplierToRemove;
export const selectCertificateDetailsAuditToRemove = (
  state: RootState
): CertificateDetailsModalState['auditToRemove'] =>
  state[parentReducerName].auditToRemove;

// reducer
export default certificateDetailsModalSlice.reducer;
