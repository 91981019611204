import { TableCellProps, TableRowProps } from '@mui/material';
import {
  CSSProperties,
  ChangeEvent as ReactChangeEvent,
  MouseEvent as ReactMouseEvent,
  ReactNode,
} from 'react';

import { ExtendedError, LoaderStatusEnum, Nullable, Only } from 'types';

export enum SortDirectionEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Pagination {
  limit: number;
  offset: number;
}

interface ColumnCommonProperty<TableRow> {
  cellProps?: TableCellProps;
  headerName?: string;
  headerProps?: TableCellProps;
  sortKey?: string;
  onSortClick?: (sortState: Nullable<SortStateType>) => void;
  title?: (rowData: TableRow) => string;
}

interface ColumnWithField<TableRow> {
  field: keyof TableRow;
}

interface ColumnWithCustomCell<TableRow> {
  customCell: (rowData: TableRow, cellId: string) => ReactNode;
}

export type Column<TableRow> = ColumnCommonProperty<TableRow> &
  (ColumnWithField<TableRow> | ColumnWithCustomCell<TableRow>);

export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: ReactMouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

export interface PaginationProps {
  totalRowsAmount: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  page: number;
  rowsPerPage: number;
  outsideOfTable?: boolean;
}

export type SortStateType = {
  key: string;
  direction: Nullable<SortDirectionEnum>;
};

interface TablePropsWithUUIDAsAKey<TableRow> {
  columns: Column<TableRow>[];
  rows: TableRow[];
  status: LoaderStatusEnum;
  rowProps?: Omit<TableRowProps, 'onClick'>;
  onRowClick?: (
    event: ReactMouseEvent<HTMLTableRowElement, MouseEvent>,
    row: TableRow
  ) => void;
  onCheckboxChange?: (event: ReactChangeEvent<HTMLInputElement>) => void;
  onHeaderCheckboxChange?: (event: ReactChangeEvent<HTMLInputElement>) => void;
  hideHeader?: boolean;
  stickyHeader?: boolean;
  maxHeight?: CSSProperties['maxHeight'];
  error?: Nullable<ExtendedError>;
  emptyStateMsg?: string;
  dense?: boolean;
  className?: string;
  pagination?: PaginationProps;
  sortState?: Nullable<SortStateType>;
  errorHeadingText?: string;
}

interface TablePropsWithPropertyAsAKey<TableRow>
  extends TablePropsWithUUIDAsAKey<TableRow> {
  propertyAsAKey: Only<TableRow, string>;
}

export type TableProps<TableRow> = TableRow extends { uuid: string }
  ? TablePropsWithUUIDAsAKey<TableRow>
  : TablePropsWithPropertyAsAKey<TableRow>;

export interface UsePaginationProps {
  enabledQueryParams?: boolean;
  initialRowsPerPage?: number;
}

export interface UsePagination {
  offset: number;
  limit: number;
  pagination: PaginationProps;
  resetPaginationState: () => void;
  setTotalRowsAmount: (total: number) => void;
}

export interface StyledMuiTableRowProps {
  $cursorPointer: boolean;
  $isLastColumn: boolean;
}

export interface StyledMuiTableContainerProps {
  $maxHeight: CSSProperties['maxHeight'];
}

export interface StyledMuiHeaderCellProps {
  $isSortable: boolean;
}

export interface StyledArrowUpwardIconProps {
  $direction?: Nullable<SortDirectionEnum>;
}
