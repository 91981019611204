import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Pagination, SortStateType } from 'components/organisms/Table/types';
import { UNEXPECTED_ERROR } from 'config/constants';
import { forceMockHeader } from 'mock/constants';
import { RootState } from 'store';
import {
  AsyncThunkOptions,
  LoaderStatusEnum,
  Nullable,
  ProcessStatus,
} from 'types';
import apiClient from 'utils/apiClient';

import {
  DiaryMissingEntriesFilter,
  DiaryMissingEntriesState,
  StringValuePayload,
} from './types';
import {
  DIARY_MIN_SEARCH_CHARS,
  parentReducerName,
} from '../../config/constants';
import endpoints from '../../config/endpoints';
import { DiaryExportParams } from '../../types';

const initialFilterState: DiaryMissingEntriesState['filter'] = {
  supplierNameCoidSearchString: '',
};

const initialState: DiaryMissingEntriesState = {
  exportLoading: LoaderStatusEnum.IDLE,
  sort: null,
  filter: initialFilterState,
  pagination: null,
};

const reducerName = `${parentReducerName}/diaryMissingEntries`;

// thunks

// TODO: placeholder for exporting missing entries to excel - solution TBD
export const exportDiaryMissingEntriesList = createAsyncThunk<
  boolean, // temporary returned type
  DiaryExportParams,
  AsyncThunkOptions
>(
  `${reducerName}/exportDiaryMissingEntriesList`,
  async (
    { onExportErrorCallback },
    { rejectWithValue, fulfillWithValue, getState }
  ) => {
    const certificationBodyId =
      getState().user.auth.userDetails?.currentContext.companyId;
    const { filter, sort, pagination } =
      getState().auditAppointment.diaryMissingEntries;
    const params = {
      certificationBodyId,
      offset: pagination?.offset,
      limit: pagination?.limit,
      export: 'excel',
      filter,
      ...(sort && { sort: `${sort.key}:${sort.direction}` }),
    };
    try {
      const response = await apiClient.get<boolean>(
        endpoints.DIARY.MISSING_ENTRIES_LIST,
        {
          headers: forceMockHeader,
          params,
        }
      );
      return fulfillWithValue(response.data, null);
    } catch (err) {
      onExportErrorCallback();
      return rejectWithValue([UNEXPECTED_ERROR]);
    }
  }
);

// slice
export const diaryMissingEntriesSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<StringValuePayload>) => {
      state.filter[action.payload.key] = action.payload.value as ProcessStatus;
    },
    setSortState: (state, action: PayloadAction<Nullable<SortStateType>>) => {
      state.sort = action.payload;
    },
    setPaginationState: (
      state,
      action: PayloadAction<Nullable<Pagination>>
    ) => {
      state.pagination = action.payload;
    },
    resetFilterAndSortState: (state) => {
      state.sort = null;
      state.filter = initialFilterState;
    },
    resetDiaryMissingEntriesExportLoading: (state) => {
      state.exportLoading = LoaderStatusEnum.IDLE;
    },
    resetDiaryMissingEntriesState: () => initialState,
    resetSearchPhrase: (state) => {
      state.filter.supplierNameCoidSearchString = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(exportDiaryMissingEntriesList.fulfilled, (state) => {
      state.exportLoading = LoaderStatusEnum.SUCCESS;
    });
    builder.addCase(exportDiaryMissingEntriesList.rejected, (state) => {
      state.exportLoading = LoaderStatusEnum.ERROR;
    });
    builder.addCase(exportDiaryMissingEntriesList.pending, (state) => {
      state.exportLoading = LoaderStatusEnum.LOADING;
    });
  },
});

// actions
export const {
  resetDiaryMissingEntriesState,
  setSortState,
  setFilterState,
  setPaginationState,
  resetDiaryMissingEntriesExportLoading,
  resetFilterAndSortState,
  resetSearchPhrase,
} = diaryMissingEntriesSlice.actions;

// selectors
export const selectDiaryMissingEntriesFilter = (
  state: RootState
): DiaryMissingEntriesFilter =>
  state[parentReducerName].diaryMissingEntries.filter;
export const selectDiaryMissingEntriesSortState = (
  state: RootState
): Nullable<SortStateType> => state[parentReducerName].diaryMissingEntries.sort;
export const selectDiaryMissingEntriesExportLoading = (
  state: RootState
): LoaderStatusEnum =>
  state[parentReducerName].diaryMissingEntries.exportLoading;
export const selectSearchMinCharError = (state: RootState): boolean => {
  const searchPhraseLength =
    state[parentReducerName].diaryMissingEntries.filter
      .supplierNameCoidSearchString.length;

  return searchPhraseLength > 0 && searchPhraseLength < DIARY_MIN_SEARCH_CHARS;
};

export default diaryMissingEntriesSlice.reducer;
