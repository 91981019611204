import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import { NextAction } from 'components/templates/TemplateModalWithSnackbar';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';
import { useMemoizedDebounce } from 'hooks/useMemoizedDebounce';
import { Nullable, Option } from 'types';

import { closeAndResetCertificateModal } from '../../../state/modal/slice';
import {
  useAddSupplierMutation,
  useLazyGetAdditionalSupplierOptionsQuery,
} from '../../../state/supplierManage/api';
import { CertificateModalCommonProps } from '../../../types';
import { StyledSearchField } from '../StyledSearchField';
import { StyledTemplateModalWithSnackbar } from '../StyledTemplateModalWithSnackbar';

type AddSupplierModalProps = CertificateModalCommonProps;

export const AddSupplierModal: FC<AddSupplierModalProps> = (props) => {
  const { certificateUuid } = props;
  const [t] = useTranslation('certificateDetails');
  const dispatch = useDispatch();
  const [supplierUuid, setSupplierUuid] = useState('');

  const { addSupplier, isLoading, error = [] } = useAddSupplierMutation();
  const closeMethod = () => {
    dispatch(closeAndResetCertificateModal());
  };
  const nextAction: NextAction = (openSnackbar, closeModal) => {
    addSupplier({
      certificateUuid,
      supplierUuid,
    }).then(() => {
      openSnackbar(t('Supplier is added.'), 'success');
      closeModal();
    });
  };

  const alertsState = useMemoizedAlerts(error);
  const disableNextButton = !supplierUuid;

  const {
    searchSupplier,
    suppliers,
    isLoading: isSupplierSearchLoading,
  } = useLazyGetAdditionalSupplierOptionsQuery();

  const fetchSupplierList = (searchTerm: string) =>
    searchSupplier({ searchTerm, certificateUuid });
  const fetchSupplierListDebounced = useMemoizedDebounce(fetchSupplierList);
  const handleSupplierSelect = (option: Nullable<Option>) => {
    setSupplierUuid(option ? option.value : '');
  };
  const supplierOptions = suppliers.map(({ name, uuid }) => ({
    value: uuid,
    label: name,
  }));

  return (
    <StyledTemplateModalWithSnackbar
      title={t('Add Supplier')}
      prevLabel={t('Cancel', { ns: 'components' })}
      nextAction={nextAction}
      nextLabel={t('Add')}
      disableNext={disableNextButton}
      isLoading={isLoading}
      nextColor="primary"
      closeMethod={closeMethod}
    >
      <StyledBaseModalContent alertsState={alertsState}>
        <StyledSearchField
          options={supplierOptions}
          loading={isSupplierSearchLoading}
          onValueSelect={handleSupplierSelect}
          onSearchTermChange={fetchSupplierListDebounced}
          description={t('Please select the Supplier')}
          placeholder={t('please select', { ns: 'components' })}
          infoMessage
          noOptionsMessage={t('No Supplier found.')}
          dataTestId="supplier-search"
        />
      </StyledBaseModalContent>
    </StyledTemplateModalWithSnackbar>
  );
};
