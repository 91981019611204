import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material';
import React, { FC, MouseEvent } from 'react';

import { TOWER_GRAY } from 'config/appColors';

interface DottedButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  dataTestId?: string;
  className?: string;
}

const StyledOpenButton = styled('button')`
  padding: 0;
  border: 0;
  background: 0;
  cursor: pointer;
`;

const StyledDotsIcon = styled(MoreVertIcon)`
  font-size: 2.4rem;
  vertical-align: middle;
  color: ${TOWER_GRAY};
`;

export const DottedButton: FC<DottedButtonProps> = ({
  onClick,
  dataTestId,
  className,
}) => (
  <StyledOpenButton
    type="button"
    onClick={onClick}
    data-testid={dataTestId}
    className={className}
  >
    <StyledDotsIcon />
  </StyledOpenButton>
);
