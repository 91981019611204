import {
  AVAILABLE_LANGS,
  DEFAULT_LANG,
  LANG_PARAM,
} from '../../config/languages';

export class LangHandler {
  static getLangParam(): string {
    return window.location.pathname.split('/')[1];
  }

  static urlHasLang(): boolean {
    return AVAILABLE_LANGS.includes(this.getLangParam());
  }

  static getLang(): string {
    if (this.urlHasLang()) {
      return this.getLangParam();
    }
    const browserLang = navigator.language.slice(0, 2);
    if (AVAILABLE_LANGS.includes(browserLang)) {
      return browserLang;
    }

    return DEFAULT_LANG;
  }

  static getURLWithLang = (urlParam?: string): string => {
    let url = urlParam;
    if (!url) {
      const { pathname, search, hash } = window.location;
      url = pathname + search + hash;
    }
    const lang = this.getLang();

    return `/${lang}${url}`;
  };

  static getTextWithLang = (text: string): string =>
    text.replaceAll(LANG_PARAM, this.getLang());
}
