import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ErrorState } from 'components/molecules/ErrorState';
import Loader from 'components/molecules/Loader';
import { DraftStatusIndicator } from 'components/organisms/DraftStatusIndicator';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import {
  CERTIFICATES_CONFIRMATIONS_TAB_SLUG,
  UNEXPECTED_ERROR_TRY_AGAIN,
} from 'config/constants';
import { useResetDraftStepper } from 'hooks/useResetDraftStepper';
import { DraftStatusEnum, LoaderStatusEnum } from 'types';
import { getSupplierPathname } from 'utils/getSupplierPathname';

import { MappedCertificateDraftSteps } from '../../components/organisms/MappedCertificateDraftSteps';
import { useCertificateDraftStatusContent } from '../../hooks/useCertificateDraftStatusContent';
import { useCertificateDraftDetailsQuery } from '../../state/certificateDraftDetails/api';
import { useCertificateDraftTemplateQuery } from '../../state/certificateDraftTemplate/api';
import { setCurrentStep } from '../../state/certificateDraftTemplate/slice';
import { CertificateDraftURLParams } from '../../types';

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 4rem 10.5rem;
`;

const StyledErrorState = styled(ErrorState)`
  justify-content: flex-start;
`;

export const PageCertificateDraftForm: FC = () => {
  const [t] = useTranslation('certificateDraft');
  const certificateDraftURLParams = useParams<CertificateDraftURLParams>();
  const { certificateDraftId } = certificateDraftURLParams;

  const {
    certificateDraftDetails,
    isLoading: isCertificateDraftDetailsLoading,
    isError: isCertificateDraftError,
    isSuccess,
  } = useCertificateDraftDetailsQuery({ certificateDraftId });
  const certificateDraftStatus = certificateDraftDetails?.status.id;
  const skipCertificateDraftTemplateQuery =
    !isSuccess || certificateDraftStatus !== DraftStatusEnum.UNSUBMITTED;

  const {
    isError: isCertificateDraftTemplateError,
    isLoading: isCertificateDraftTemplateLoading,
  } = useCertificateDraftTemplateQuery(
    certificateDraftURLParams,
    skipCertificateDraftTemplateQuery
  );

  useResetDraftStepper(setCurrentStep);

  const isLoading =
    isCertificateDraftDetailsLoading || isCertificateDraftTemplateLoading;
  const finalStatus = isLoading
    ? LoaderStatusEnum.LOADING
    : LoaderStatusEnum.SUCCESS;

  if (isCertificateDraftTemplateError || isCertificateDraftError) {
    return (
      <TemplatePageDefault hidePageHeader>
        <StyledErrorState
          heading={t('Failed to load Data.', { ns: 'components' })}
          description={t(UNEXPECTED_ERROR_TRY_AGAIN, { ns: 'common' })}
        />
      </TemplatePageDefault>
    );
  }

  let component = (
    <DraftStatusIndicator
      details={certificateDraftDetails}
      useStatusContent={useCertificateDraftStatusContent}
      getPathname={(supplierId: string) =>
        getSupplierPathname(supplierId, CERTIFICATES_CONFIRMATIONS_TAB_SLUG)
      }
    />
  );

  if (certificateDraftStatus === DraftStatusEnum.UNSUBMITTED) {
    component = (
      <MappedCertificateDraftSteps certificateDraftId={certificateDraftId} />
    );
  }

  return (
    <TemplatePageDefault hidePageHeader>
      <ContentWrapper>
        <Loader status={finalStatus} minHeight="0px">
          {component}
        </Loader>
      </ContentWrapper>
    </TemplatePageDefault>
  );
};
