import get from 'lodash/get';
import React, { FC } from 'react';

import { GeneralDetails } from 'types';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

// TODO: TBD are there situation when there is no field (optional??) what should happen
type Kind = GeneralDetails;

const isKind = (kind: unknown): kind is Kind =>
  !!kind && 'id' in (kind as Kind) && 'name' in (kind as Kind);

interface AttributeRowKindProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
  //   children: null; // TBD it might be used in the future
}

export const AttributeRowKind: FC<AttributeRowKindProps> = (props) => {
  const { data, dataRef, labelText } = props;
  const value = get(data, dataRef);
  const showAttributeRow = isKind(value);

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Kind data" />
    );
  }

  return <AttributeRowBase label={labelText} value={value.name} />;
};
