import SuccessIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { styled } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ERROR, SUCCESS, WARNING } from 'config/appColors';
import { AlertVariantEnum } from 'types';

interface AlertConfig {
  color: CSSProperties['color'];
  $hideBullets?: boolean;
}

const StyledContainer = styled('div')`
  ul {
    margin-top: 12px;
    margin-bottom: 15px;
    padding-left: 20px;
    overflow-wrap: break-word;
  }
`;

const StyledHeader = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
`;

const StyledAlertListItem = styled('li')<AlertConfig>`
  color: ${({ color }) => color};
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.017rem;
  list-style: ${({ $hideBullets }) => ($hideBullets ? 'none' : 'initial')};
`;

const StyledAlertText = styled('span')<AlertConfig>`
  color: ${({ color }) => color};
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.01rem;
`;

const StyledReportProblemOutlinedIcon = styled(ReportProblemOutlinedIcon)`
  fill: ${WARNING.MAIN};
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  fill: ${SUCCESS.MAIN};
`;

export interface AlertListProps {
  alerts: string[];
  className?: string;
  variant?: AlertVariantEnum;
  alertHeading?: string;
  noAlertsTranslation?: boolean;
}

export const AlertList: FC<AlertListProps> = (props) => {
  const {
    alerts,
    className,
    variant = AlertVariantEnum.ERROR,
    alertHeading,
    noAlertsTranslation,
  } = props;

  const [t] = useTranslation('components');

  const alertVariants = {
    [AlertVariantEnum.ERROR]: {
      alertText: alertHeading || t('Error'),
      icon: <ErrorIcon fontSize="large" color="error" />,
      color: ERROR.LIGHT,
    },
    [AlertVariantEnum.WARNING]: {
      alertText: alertHeading || t('Warning'),
      icon: <StyledReportProblemOutlinedIcon fontSize="large" />,
      color: WARNING.MAIN,
    },
    [AlertVariantEnum.SUCCESS]: {
      alertText: alertHeading || t('Success'),
      icon: <StyledSuccessIcon fontSize="large" />,
      color: SUCCESS.MAIN,
    },
  };

  const currentVariant = alertVariants[variant];
  const finalClassName = clsx(['AlertList', className]);

  const [alertsToDisplayOnList, alertsToDisplayInConsole] = alerts.reduce<
    [string[], string[]]
  >(
    (allAlerts, alert) => {
      const index = alert.includes('debug') ? 1 : 0;
      allAlerts[index] = [...allAlerts[index], alert];
      return allAlerts;
    },
    [[], []]
  );

  alertsToDisplayInConsole.forEach((alert) => {
    console.warn(alert);
  });

  return (
    <StyledContainer className={finalClassName}>
      <StyledHeader>
        {currentVariant.icon}
        <StyledAlertText color={currentVariant.color}>
          {currentVariant.alertText}
        </StyledAlertText>
      </StyledHeader>
      <ul>
        {alertsToDisplayOnList.map((alert) => (
          <StyledAlertListItem
            key={alert}
            color={currentVariant.color}
            $hideBullets={alertsToDisplayOnList.length === 1}
          >
            {noAlertsTranslation ? alert : t(alert, { ns: 'common' })}
          </StyledAlertListItem>
        ))}
      </ul>
    </StyledContainer>
  );
};
