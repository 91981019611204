import { Paper, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ProcessStatusIndicator } from 'components/molecules/ProcessStatusIndicator';
import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { SortStateType } from 'components/organisms/Table/types';
import {
  selectDiaryAuditsAppointmentFilter,
  selectDiaryAuditsAppointmentSortState,
  setPaginationState,
  setSortState,
} from 'domains/auditAppointment/state/diaryAuditAppointments/slice';
import { DiaryAuditAppointment } from 'domains/auditAppointment/types';
import { useAuditAppointmentDetailsLink } from 'hooks/useAuditAppointmentDetailsLink';
import { Nullable } from 'types';
import { renderDateRange } from 'utils/dates';

import { useGetDiaryAppointmentsQuery } from '../../../state/diaryAuditAppointments/api';
import { CustomerLink } from '../../atoms/CustomerLink';

const StyledTable = styled(Table)`
  table,
  .skeleton-container {
    min-width: 1220px;
  }

  .skeleton-container {
    padding: 0 10px;
  }
` as typeof Table;

export const DiaryAuditAppointmentsTable: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();

  const filter = useSelector(selectDiaryAuditsAppointmentFilter);
  const sortState = useSelector(selectDiaryAuditsAppointmentSortState);
  const { pagination, limit, offset, setTotalRowsAmount } = usePagination({
    initialRowsPerPage: 25,
  });
  const handleRowClick = useAuditAppointmentDetailsLink();
  const { getColumnConfig, getColumnConfigWithEllipsis } =
    useColumnConfig<DiaryAuditAppointment>('10%');

  const { certificationBodyUuid } =
    useParams<{ certificationBodyUuid: string }>();

  const {
    diaryAppointments,
    status: requestStatus,
    error,
    total,
  } = useGetDiaryAppointmentsQuery({
    limit,
    offset,
    certificationBodyUuid,
    filter,
    ...(sortState && { sort: `${sortState.key}:${sortState.direction}` }),
  });

  useEffect(() => {
    dispatch(setPaginationState({ limit, offset }));
  }, [dispatch, limit, offset]);

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const onSortClick = (newSortState: Nullable<SortStateType>) => {
    dispatch(setSortState(newSortState));
  };

  const columns: Column<DiaryAuditAppointment>[] = [
    {
      customCell: ({ dueDate }) =>
        renderDateRange(dueDate?.start, dueDate?.end),
      headerName: t('Due Date'),
      sortKey: 'dueDate.startDate',
      onSortClick,
      ...getColumnConfig('15%', {
        otherCellProps: {
          style: {
            whiteSpace: 'nowrap',
          },
        },
      }),
    },
    {
      customCell: CustomerLink,
      headerName: t('Customer'),
      ...getColumnConfigWithEllipsis('20%'),
    },
    {
      customCell: ProcessStatusIndicator,
      headerName: t('Status'),
      sortKey: 'status',
      ...getColumnConfig(),
    },
    {
      customCell: ({ type }) => t(type, { ns: 'supplier' }),
      headerName: t('Type'),
      sortKey: 'type',
      onSortClick,
      ...getColumnConfig(),
    },
    {
      customCell: ({ module }) => module.name,
      headerName: t('Standard/program', { ns: 'components' }),
      sortKey: 'module',
      onSortClick,
      ...getColumnConfigWithEllipsis('15%'),
    },
    {
      field: 'description',
      headerName: t('Comment'),
      ...getColumnConfigWithEllipsis('25%'),
    },
  ];

  return (
    <Paper>
      <StyledTable
        rows={diaryAppointments}
        columns={columns}
        onRowClick={handleRowClick}
        status={requestStatus}
        error={error}
        pagination={pagination}
        sortState={sortState}
      />
    </Paper>
  );
};
