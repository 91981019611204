export const auditTags = [
  {
    id: 'fsc',
    text: {
      en: 'Food Safety Check',
      de: '',
      fr: '',
      es: '',
      it: '',
    },
  },
  {
    id: 'ua-star',
    text: {
      en: 'Unannounced star',
      de: '',
      fr: '',
      es: '',
      it: '',
    },
  },
  {
    id: 'outsourced-production',
    text: {
      en: 'Outsourced Production',
      de: '',
      fr: '',
      es: '',
      it: '',
    },
  },
  {
    id: 'exclusions',
    text: {
      en: 'Exclusions',
      de: '',
      fr: '',
      es: '',
      it: '',
    },
  },
  {
    id: 'fsma',
    text: {
      en: 'FSMA',
      de: '',
      fr: '',
      es: '',
      it: '',
    },
  },
  {
    id: 'hkz-de',
    text: {
      en: 'HKZ-DE',
      de: '',
      fr: '',
      es: '',
      it: '',
    },
  },
];
