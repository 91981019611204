import * as yup from 'yup';

import {
  AuditAppointmentKindEnum,
  AuditAppointmentTypeEnum,
} from 'domains/auditAppointment/types';
import { CertificateDetails } from 'domains/certificateDetails/types';
import { certificateSchema } from 'domains/supplier/schemas/Certificate';
import {
  generalDetailsSchema,
  generalDetailsWithEnumSchema,
  startEndDatesSchema,
} from 'schemas';

import { exceptionalCircumstanceSchema } from '../ExceptionalCircumstance';

export const certificateDetailsSchema: yup.ObjectSchema<CertificateDetails> =
  certificateSchema.shape({
    moduleFamily: generalDetailsSchema.required(),
    issuedAt: yup.string().required(),
    auditType: generalDetailsWithEnumSchema(AuditAppointmentTypeEnum)
      .optional()
      .default(undefined),
    auditKind: generalDetailsWithEnumSchema(AuditAppointmentKindEnum)
      .optional()
      .default(undefined),
    announcedRecertificationTimeframe: startEndDatesSchema
      .optional()
      .default(undefined),
    unannouncedRecertificationTimeframe: startEndDatesSchema
      .optional()
      .default(undefined),

    exceptionalCircumstances: yup
      .array()
      .of(exceptionalCircumstanceSchema)
      .optional()
      .default(undefined),
  });
