import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'domains/user/state/auth/slice';

import { selectCertificateDetailsModalFormValues } from '../state/modal/slice';
import { CommonFormValues } from '../state/modal/types';

export const useModalCommonRequiredFields = (): CommonFormValues => {
  const {
    category,
    categoryOthersDescription,
    descriptionEnglish,
    descriptionOtherLanguage,
    actionComment,
    type,
  } = useSelector(selectCertificateDetailsModalFormValues);
  const isAdmin = useSelector(selectUserIsAdmin);

  return {
    category,
    ...(categoryOthersDescription && { categoryOthersDescription }),
    descriptionEnglish,
    ...(descriptionOtherLanguage && { descriptionOtherLanguage }),
    ...(isAdmin ? { actionComment } : {}),
    type,
  };
};
