import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, Table, useColumnConfig } from 'components/organisms/Table';
import { DownloadFileButton } from 'domains/auditDetails/components/molecules/DownloadFileButton';
import { useDownloadAuditCertificateDocumentMutation } from 'domains/certificateDetails/state/certificateDocuments/api';
import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import {
  Document,
  ExtendedError,
  LoaderStatusEnum,
  WithUndefined,
} from 'types';
import { formatDate } from 'utils/dates';

interface FileDetailsTableProps {
  certificateUuid: string;
  documents: Document[];
  loadingStatus: LoaderStatusEnum;
  error?: WithUndefined<ExtendedError>;
}

export const FileDetailsTable: FC<FileDetailsTableProps> = (props) => {
  const { certificateUuid, documents, loadingStatus, error } = props;
  const [t] = useTranslation('components');
  const { downloadFile } = useDownloadAuditCertificateDocumentMutation();
  const { openSnackbar } = useSnackbarNotification();
  const handleFileDownload = (documentUuid: string, fileName: string) => {
    if (certificateUuid) {
      downloadFile({
        certificateUuid,
        documentUuid,
        fileName,
      }).catch(() =>
        openSnackbar(
          t('Cannot download a file.', { ns: 'components' }),
          'error'
        )
      );
    }
  };

  const { getColumnConfig, getColumnConfigWithEllipsis } =
    useColumnConfig<Document>('25%');
  const columns: Column<Document>[] = [
    {
      customCell: ({ type }) => type?.name ?? '-',
      headerName: t('Document type'),
      ...getColumnConfig(),
    },
    {
      customCell: (document) => (
        <DownloadFileButton onClick={handleFileDownload} document={document} />
      ),
      headerName: t('Filename'),
      ...getColumnConfigWithEllipsis('50%'),
    },
    {
      customCell: ({ uploadedAt }) =>
        uploadedAt
          ? formatDate(new Date(uploadedAt), 'MMM d, yyyy, h:mm:ss aa')
          : '-',
      headerName: t('Uploaded at'),
      ...getColumnConfig(),
    },
  ];

  return (
    <Table
      rows={documents}
      columns={columns}
      status={loadingStatus}
      error={error}
      dense
    />
  );
};
