import React, { FC } from 'react';

import { ActionsDropdown } from 'components/molecules/ActionDropdown';

import { useCertificateDetailsDropdown } from '../../../hooks/useCertificateDetailsDropdown';

interface ActionsDropdownProps {
  certificateUuid: string;
  supplierId: string;
}

export const CertificateActionsDropdown: FC<ActionsDropdownProps> = ({
  certificateUuid,
  supplierId,
}) => {
  const dropdownConfig = useCertificateDetailsDropdown(
    certificateUuid,
    supplierId
  );

  return <ActionsDropdown {...dropdownConfig} />;
};
