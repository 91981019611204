import React, { FC } from 'react';

import { DiaryAuditRegistrationsFilters } from '../DiaryAuditRegistrationsFilters';
import { DiaryAuditRegistrationsTable } from '../DiaryAuditRegistrationsTable';

export const DiaryAuditRegistrationsTab: FC = () => (
  <>
    <DiaryAuditRegistrationsFilters />
    <DiaryAuditRegistrationsTable />
  </>
);
