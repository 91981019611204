import { Divider, SelectChangeEvent, styled } from '@mui/material';
import React, { ChangeEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { Textarea } from 'components/atoms/Textarea';
import { ActionInformationSection } from 'components/organisms/ActionInformationSection';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { ActionComment } from 'types';
import { isActionCommentFieldValid } from 'utils/validation/isActionCommentFieldValid';

import { actionReasons } from '../../../config/constants';
import {
  selectAuditPlanDescription,
  setDescription,
  setDescriptionActionComment,
} from '../../../state/create/slice';
import { selectAlertsState } from '../../../state/formOptions/slice';
import { setIsNextButtonDisabled } from '../../../state/stepperModal/slice';
import { StepAlertList } from '../../atoms/StepAlertList';

const CONTAINER_HORIZONTAL_PADDING = 20;

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const StyledStepDescription = styled(StepDescription)`
  margin: 15px 0 15px 10px;
`;
const StyledDivider = styled(Divider)`
  width: calc(100% + ${CONTAINER_HORIZONTAL_PADDING * 2}px);
  margin-left: -${CONTAINER_HORIZONTAL_PADDING}px;
  margin-top: 20px;
`;
const StyledAdminInformationContainer = styled('div')`
  margin: 0 10px 20px;
`;
const StyledTextarea = styled(Textarea)`
  margin: 0 10px;
`;

export const FormSetDescription: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const alertsState = useSelector(selectAlertsState);
  const showAlerts = alertsState && alertsState.alerts.length > 0;
  const isAdmin = useSelector(selectUserIsAdmin);

  const description = useSelector(selectAuditPlanDescription)?.description;
  const actionComment = useSelector(selectAuditPlanDescription)?.actionComment;
  const actionCommentReason = actionComment?.reason || '';
  const dispatchSetDescription = (val: string) => dispatch(setDescription(val));
  const dispatchSetActionComment = (val: ActionComment) =>
    dispatch(setDescriptionActionComment(val));
  const onDescriptionChange = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => dispatchSetDescription(ev.target.value);
  const handleAdminReasonChange = (event: SelectChangeEvent<unknown>) => {
    dispatchSetActionComment({
      comment: actionComment?.comment ?? '',
      reason: event.target.value as string,
    });
  };
  const handleAdminCommentChange = (comment: string) => {
    dispatchSetActionComment({
      comment,
      reason: actionCommentReason,
    });
  };

  const actionCommentFieldsAreValid = isActionCommentFieldValid(
    actionCommentReason,
    actionComment?.comment || null
  );

  useEffect(() => {
    const enableNextButton = !isAdmin || actionCommentFieldsAreValid;
    dispatch(setIsNextButtonDisabled(!enableNextButton));
  }, [dispatch, isAdmin, actionCommentFieldsAreValid]);

  return (
    <StyledContainer>
      <StepFormTitle content={t('Comment')} />

      {showAlerts && (
        <StepAlertList
          variant={alertsState.variant}
          alerts={alertsState.alerts}
        />
      )}

      <StyledStepDescription content={t('Additional information')} />

      <StyledTextarea
        value={description}
        multiline
        rows={7}
        onChange={onDescriptionChange}
      />

      {isAdmin && (
        <>
          <StyledDivider />
          <StyledAdminInformationContainer>
            <ActionInformationSection
              adminReason={actionCommentReason}
              adminComment={actionComment?.comment}
              handleAdminReasonChange={handleAdminReasonChange}
              handleAdminCommentChange={handleAdminCommentChange}
              adminReasonOptions={actionReasons.CREATE_AUDIT_APPOINTMENT.ADMIN}
            />
          </StyledAdminInformationContainer>
        </>
      )}
    </StyledContainer>
  );
};
