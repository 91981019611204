import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import type { AxiosError, AxiosRequestConfig } from 'axios';

import apiClient from 'utils/apiClient';
import { LangHandler } from 'utils/langHandler';
import { operationDelay } from 'utils/operationDelay';

interface BaseQueryArgs {
  url: string;
  method?: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
  responseType?: AxiosRequestConfig['responseType'];
  delayTime?: number;
}
export interface BaseQueryError {
  response: {
    status?: number;
    statusText?: string;
    data?: unknown;
  };
}

export interface BaseQueryMeta {
  requestInfo: string;
}

export const axiosBaseQuery =
  (): BaseQueryFn<
    BaseQueryArgs,
    unknown,
    BaseQueryError,
    unknown,
    BaseQueryMeta
  > =>
  async ({
    url,
    method = 'GET',
    data,
    params,
    headers,
    responseType,
    delayTime,
  }) => {
    const startTime = new Date().getTime();
    const { getTextWithLang } = LangHandler;
    const listedParams = params ? `, params: ${JSON.stringify(params)}` : '';
    const requestInfo = `[${method}] ${getTextWithLang(url)}${listedParams}`;

    try {
      const result = await apiClient({
        url,
        method,
        data,
        params,
        headers,
        responseType,
      });
      if (delayTime) {
        await operationDelay(startTime, delayTime);
      }
      return {
        data: result.data,
        meta: {
          requestInfo,
        },
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      const error = {
        response: {
          status: err?.response?.status,
          statusText: err?.response?.statusText,
          data: err?.response?.data,
        },
      };

      if (delayTime) {
        await operationDelay(startTime, delayTime);
      }

      return {
        error,
        meta: { requestInfo },
      };
    }
  };

export const TAG_TYPES = [
  'ADMIN_AUDIT_LIST',
  'APPOINTMENTS',
  'AUDIT_ACCESSES',
  'AUDIT_APPOINTMENT_DETAILS_LOGS',
  'AUDIT_CERTIFICATE_DETAILS',
  'AUDIT_CERTIFICATE_DOCUMENTS',
  'AUDIT_DETAILS',
  'AUDIT_DETAILS_ACTIONS',
  'AUDIT_DETAILS_DOCUMENTS',
  'AUDIT_DETAILS_LOGS',
  'AUDIT_DETAILS_TEMPLATE',
  'AUDIT_DRAFT_AUDIT_APPOINTMENT_OPTIONS',
  'AUDIT_DRAFT_DETAILS',
  'AUDIT_DRAFT_DOCUMENT_DEFINITIONS',
  'AUDIT_DRAFT_TEMPLATE',
  'AUDIT_LIST',
  'AUDIT_APPOINTMENT_DETAILS_LOGS',
  'AUDIT_RELEASES',
  'AUDITOR_PARTICIPANTS',
  'CERTIFICATE_DETAILS',
  'CERTIFICATE_ACCESSES',
  'CERTIFICATE_DETAILS_ACTIONS',
  'CERTIFICATE_DETAILS_ADDITIONAL_AUDIT_LIST',
  'CERTIFICATE_DETAILS_ADDITIONAL_SUPPLIER_LIST',
  'CERTIFICATE_DETAILS_DOCUMENTS',
  'CERTIFICATE_DETAILS_LOCK_HISTORY',
  'CERTIFICATE_DETAILS_LOGS',
  'CERTIFICATE_DETAILS_TEMPLATE',
  'CERTIFICATE_DRAFT_DETAILS',
  'CERTIFICATE_DRAFT_DOCUMENT_DEFINITIONS',
  'CERTIFICATE_DRAFT_STANDARD_OPTIONS',
  'CERTIFICATE_DRAFT_TEMPLATE',
  'CERTIFICATE_LIST',
  'DIARY_APPOINTMENTS',
  'DIARY_REGISTRATIONS',
  'DIARY_MISSING_ENTRIES',
  'SUPPLIERS',
  'FILTERABLE_AUDIT_LIST',
  'FILTERABLE_CERTIFICATE_LIST',
] as const;

export const apiSlice = createApi({
  keepUnusedDataFor: 30, // CUSTOM TIME FOR ALL QUERIES DEFAULT IS 60 it might be too long
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  tagTypes: TAG_TYPES,
  endpoints: () => ({}),
});

export const { invalidateTags } = apiSlice.util;
