import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SelectChangeEvent, styled } from '@mui/material';
import React, { FC, ChangeEvent as ReactChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button';
import DatePicker from 'components/atoms/DatePicker';
import { Select } from 'components/atoms/Select';
import { StyledFiltersContainer } from 'components/atoms/StyledFiltersContainer';
import { WHITE } from 'config/appColors';
import { FilterSearchInput } from 'domains/auditAppointment/components/atoms/FilterSearchInput';
import { TEMPORARY_MODULES } from 'domains/auditAppointment/config/temporalConstants';
import { MIN_SEARCH_CHARS } from 'domains/widgets/state/filterableLists/slice';
import {
  CommonFilterableAuditsCertificatesFilters,
  FilterErrors,
  FilterStringValuePayload,
} from 'domains/widgets/state/filterableLists/types';
import { useMemoizedDebounce } from 'hooks/useMemoizedDebounce';
import { Nullable } from 'types';
import { formatDate } from 'utils/dates';

interface StyledSelectProps {
  $minWidth?: string;
  $maxWidth?: string;
  $width?: string;
}

const StyledLabel = styled('label')`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 10px;
`;

const StyledLabelText = styled('span')`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.015rem;
`;

const StyledSelect = styled(Select)<StyledSelectProps>`
  height: 40px;
  background: ${WHITE};
  width: ${({ $width }) => $width ?? 'auto'};
  min-width: ${({ $minWidth }) => $minWidth ?? 'none'};
  max-width: ${({ $maxWidth }) => $maxWidth ?? 'none'};
`;

const StyledResetButton = styled(Button)`
  margin: 20px 10px 10px 0;
`;

interface AuditsCertificatesFiltersProps {
  filter: CommonFilterableAuditsCertificatesFilters;
  onFilterChange: (key: FilterStringValuePayload['key'], value: string) => void;
  errors: FilterErrors;
  onResetClick: () => void;
}

export const AuditsCertificatesFilters: FC<AuditsCertificatesFiltersProps> = (
  props
) => {
  const [t] = useTranslation('supplier');
  const searchTermInputRef = useRef<Nullable<HTMLInputElement>>(null);

  const {
    filter: { startDate, endDate, moduleId, supplierNameCoidSearchString },
    onFilterChange,
    errors,
    onResetClick,
  } = props;

  const handleDateChange = (key: 'startDate' | 'endDate') => (date: Date) => {
    onFilterChange(key, formatDate(date, 'yyyy-MM-dd'));
  };

  const handleModuleChange = (event: SelectChangeEvent<unknown>) => {
    onFilterChange('moduleId', event.target.value as string);
  };

  const handleSearchChange = (
    event: ReactChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
    onFilterChange('supplierNameCoidSearchString', event.target.value.trim());

  const debouncedHandleChange = useMemoizedDebounce(handleSearchChange);

  const handleResetClick = () => {
    onResetClick();
    if (searchTermInputRef && searchTermInputRef.current) {
      searchTermInputRef.current.value = '';
    }
  };

  return (
    <StyledFiltersContainer>
      <StyledLabel>
        <StyledLabelText>{t('Date')}</StyledLabelText>
        <div className="date-pickers">
          <DatePicker
            value={startDate}
            inputWidth="125px"
            datePickerOffset="95px"
            onChange={handleDateChange('startDate')}
          />
          <DatePicker
            value={endDate}
            inputWidth="125px"
            datePickerOffset="95px"
            onChange={handleDateChange('endDate')}
          />
        </div>
      </StyledLabel>
      <StyledLabel>
        <StyledLabelText>
          {t('Standard/program', { ns: 'components' })}
        </StyledLabelText>
        <StyledSelect
          data-testid="module"
          icon={ArrowDropDownIcon}
          value={moduleId}
          placeholder="---"
          options={TEMPORARY_MODULES}
          onChange={handleModuleChange}
          $minWidth="230px"
          $maxWidth="300px"
        />
      </StyledLabel>
      <StyledLabel>
        <StyledLabelText>
          {t('Search Name/COID', { ns: 'auditAppointment' })}
        </StyledLabelText>
        <FilterSearchInput
          data-testid="search"
          inputRef={searchTermInputRef}
          placeholder={t('search', { ns: 'auditAppointment' })}
          defaultValue={supplierNameCoidSearchString}
          onChange={debouncedHandleChange}
          helperText={
            errors.searchError
              ? t('Please enter at least {{number}} characters', {
                  number: MIN_SEARCH_CHARS,
                  ns: 'auditAppointment',
                })
              : ''
          }
          error={errors.searchError}
          minWidth="200px"
          maxWidth="400px"
        />
      </StyledLabel>
      <StyledResetButton
        variant="contained"
        color="info"
        onClick={handleResetClick}
      >
        {t('Reset', { ns: 'auditAppointment' })}
      </StyledResetButton>
    </StyledFiltersContainer>
  );
};
