import { useSelector } from 'react-redux';

import { selectUserPermissions } from '../domains/user/state/auth/slice';

// For Redux toolkit use: utils/checkUserPermission
export const useUserPermission = (
  ...requiredPermissions: string[]
): boolean => {
  const userPermissions = useSelector(selectUserPermissions);

  return requiredPermissions.every((permission) =>
    userPermissions.includes(permission)
  );
};
