import { styled } from '@mui/material';
import React, { FC } from 'react';

import { ActionsDropdown } from 'components/molecules/ActionDropdown';
import { useAuditDetailsDropdown } from 'domains/widgets/hooks/useAuditDetailsDropdown';

const StyledDropdownMenu = styled(ActionsDropdown)`
  margin-left: auto;
`;

interface ActionsDropdownProps {
  auditUuid: string;
  supplierId: string;
}

export const AuditDetailsActionsDropdown: FC<ActionsDropdownProps> = ({
  auditUuid,
  supplierId,
}) => {
  const dropdownConfig = useAuditDetailsDropdown(auditUuid, supplierId);

  return <StyledDropdownMenu {...dropdownConfig} />;
};
