import { styled } from '@mui/material';

import { TEXT } from 'config/appColors';

const StyledResult = styled('span')`
  color: ${(props: { $color?: string }) => props.$color ?? TEXT.PRIMARY};
`;

StyledResult.displayName = 'StyledResult';

export { StyledResult };
