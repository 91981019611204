import jwtDecode from 'jwt-decode';

import { AccessTokenPayloadType, Nullable } from 'types';

export class AccessToken {
  static decode<DecodedToken = AccessTokenPayloadType>(
    token: string
  ): DecodedToken {
    return jwtDecode(token);
  }

  static isAlive(token: Nullable<string>): boolean {
    if (!token) {
      return false;
    }

    try {
      const { exp } = this.decode(token);

      return new Date(exp * 1000) > new Date();
    } catch {
      return false;
    }
  }
}
