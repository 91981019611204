import { styled } from '@mui/material';
import React, { ComponentProps, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { BaseModal } from 'components/atoms/BaseModal';
import { ModalFooterLoader } from 'components/atoms/ModalFooterLoader';
import { ModalTitleWithDetails } from 'components/atoms/ModalTitleWithDetails';
import Loader from 'components/molecules/Loader';
import { AbortModal } from 'components/organisms/AbortModal';
import { TemplateStepperModal } from 'components/templates/TemplateStepperModal';
import { WHITE } from 'config/appColors';
import { AUDITS_ASSESSMENTS_TAB_SLUG } from 'config/constants';
import { getSupplierPathname as YII_getSupplierPathname } from 'domains/widgets/utils/getSupplierPathname';
import { useAbortModal } from 'hooks/useAbortModal';
import {
  BackAction,
  BtnConfig,
  LoaderStatusEnum,
  NextAction,
  TranslatedText,
} from 'types';
import { getAuditDetailsPathname } from 'utils/getAuditDetailsPathname';
import { TARGET_AUDIT_SEARCH } from 'utils/getAuditDraftFormPathname';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';
import { getSupplierPathname } from 'utils/getSupplierPathname';
import { getTranslatedValue } from 'utils/getTranslatedValue';
import isIframe from 'utils/isIframe';

import { useAuditDraftStepper } from '../../../hooks/useAuditDraftStepper';
import { useIsAmendment } from '../../../hooks/useIsAmendment';
import { useAuditDraftDetailsQuery } from '../../../state/auditDraftDetails/api';
import { AuditDraftURLParams } from '../../../types';

const StyledStepperModalWrapper = styled('div')`
  background: ${WHITE};
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14),
    0 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 980px;
  margin: 0 auto;
`;

const StyledContentComponentWrapper = styled('div')`
  width: 100%;
  max-width: 980px;
`;

type TemplateStepperModalProps = ComponentProps<typeof TemplateStepperModal>;

interface TemplateAuditDraftStepProps
  extends Pick<
    TemplateStepperModalProps,
    | 'prevLabel'
    | 'nextLabel'
    | 'noNextArrow'
    | 'noPrevArrow'
    | 'prevButtonComponent'
    | 'nextButtonComponent'
    | 'hideNextButton'
    | 'hidePrevButton'
    | 'disablePrev'
    | 'disableNext'
  > {
  contentStatus?: LoaderStatusEnum;
  nextButtonStatus?: LoaderStatusEnum;
  nextAction?: NextAction;
  backAction?: BackAction;
  onPrevClick?: (changeCurrentStep: () => void) => void;
  onNextClick?: (changeCurrentStep: () => void) => void;
  children?: ReactNode;
}

export const TemplateAuditDraftStep: FC<TemplateAuditDraftStepProps> = (
  props
) => {
  const {
    children,
    nextAction,
    backAction,
    onPrevClick,
    onNextClick,
    noNextArrow = true,
    noPrevArrow = true,
    prevLabel,
    nextLabel,
    contentStatus = LoaderStatusEnum.SUCCESS,
    nextButtonStatus = LoaderStatusEnum.SUCCESS,
    hideNextButton,
    hidePrevButton,
    prevButtonComponent,
    nextButtonComponent,
    disablePrev,
    disableNext,
  } = props;

  const {
    steps,
    currentStep,
    auditDraftTemplate,
    onStepClick,
    setNextStep,
    setPrevStep,
  } = useAuditDraftStepper();
  const { auditDraftId } = useParams<AuditDraftURLParams>();
  const isAmendment = useIsAmendment();
  const { auditDraftDetails } = useAuditDraftDetailsQuery(auditDraftId, {
    refetchOnMountOrArgChange: false,
  });
  const { push, location } = useHistory();
  const targetAudit = new URLSearchParams(location.search).get(
    TARGET_AUDIT_SEARCH
  );
  const [t] = useTranslation('components');
  const { isAbortModalOpen, openAbortModal, closeAbortModal } = useAbortModal();
  const handleAbortAction = () => {
    const supplierUuid = auditDraftDetails?.supplier.uuid || '';
    // TODO: once we get rid of iframe things ternary&YII_getSupplierPathname should be removed
    const supplierPathname = isIframe
      ? YII_getSupplierPathname(supplierUuid, 'AUDITS')
      : getSupplierPathname(supplierUuid, AUDITS_ASSESSMENTS_TAB_SLUG);
    const pathname = isAmendment
      ? getAuditDetailsPathname(
          supplierUuid,
          targetAudit || '',
          AUDITS_ASSESSMENTS_TAB_SLUG
        )
      : supplierPathname;
    push(pathname, location.state);
  };

  const handlePrevClick = () => {
    if (onPrevClick) {
      onPrevClick(setPrevStep);
    } else {
      setPrevStep();
    }
  };

  const handleNextClick = () => {
    if (onNextClick) {
      onNextClick(setNextStep);
    } else {
      setNextStep();
    }
  };

  const finalNextButtonComponent =
    nextButtonStatus === LoaderStatusEnum.LOADING ? (
      <ModalFooterLoader />
    ) : (
      nextButtonComponent
    );

  const translatedTitle = getTranslatedValue(
    auditDraftTemplate?.template.props.headerText
  );

  const title = (
    <ModalTitleWithDetails
      title={`${translatedTitle} ${auditDraftDetails?.module.name}`}
      details={
        auditDraftDetails?.supplier
          ? getSupplierDetailsTitle(auditDraftDetails.supplier)
          : ''
      }
    />
  );

  const getButtonLabel = (text?: TranslatedText) =>
    text ? getTranslatedValue(text) : undefined;

  const isButtonHidden = (btnConfig?: BtnConfig, hideProp?: boolean) =>
    hideProp !== undefined ? hideProp : !btnConfig?.enabled;

  return (
    <StyledStepperModalWrapper>
      <BaseModal open={isAbortModalOpen}>
        <AbortModal
          title={title}
          message={t(
            'Do you want to cancel the process? The data will be deleted.'
          )}
          continueAction={closeAbortModal}
          abortAction={handleAbortAction}
        />
      </BaseModal>
      <TemplateStepperModal
        activeStep={currentStep || ''}
        steps={steps}
        onStepClick={onStepClick}
        closeAction={openAbortModal}
        title={title}
        contentComponent={
          <Loader status={contentStatus}>
            <StyledContentComponentWrapper>
              {children}
            </StyledContentComponentWrapper>
          </Loader>
        }
        prevLabel={prevLabel || getButtonLabel(backAction?.btnText)}
        nextLabel={nextLabel || getButtonLabel(nextAction?.btnText)}
        prevAction={handlePrevClick}
        nextAction={handleNextClick}
        noNextArrow={noNextArrow}
        noPrevArrow={noPrevArrow}
        prevButtonComponent={prevButtonComponent}
        nextButtonComponent={finalNextButtonComponent}
        hidePrevButton={isButtonHidden(backAction, hidePrevButton)}
        hideNextButton={isButtonHidden(nextAction, hideNextButton)}
        disablePrev={disablePrev}
        disableNext={disableNext}
        nextColor={nextAction?.type === 'submit' ? 'primary' : 'info'}
        prevColor="modalPrevButton"
      />
    </StyledStepperModalWrapper>
  );
};
