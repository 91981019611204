import { Grid, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/atoms/Button';
import { ErrorState } from 'components/molecules/ErrorState';
import { WithUndefined } from 'types';
import { PostMessage } from 'utils/postMessage';

import { WidgetErrorPayload, WidgetErrorTypeEnum } from './types';

const StyledErrorState = styled(ErrorState)`
  padding: 0;
`;

const StyledGrid = styled(Grid)`
  width: 80%;
  padding: 15px 0;
`;

const StyledGridContainer = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const WidgetErrorScreen: FC = () => {
  const [t] = useTranslation('components');
  const history = useHistory();
  const getErrorContent = () => {
    const errorType = (
      history.location.state as WithUndefined<WidgetErrorPayload>
    )?.type;
    switch (errorType) {
      case WidgetErrorTypeEnum.SESSION_EXPIRED: {
        return {
          heading: t('Session expired'),
          description: '',
        };
      }
      default: {
        return {
          heading: t('Unexpected Error'),
          description: t('An unexpected error occurred. Please try again.'),
        };
      }
    }
  };
  const { heading, description } = getErrorContent();

  return (
    <StyledGridContainer
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <StyledGrid item>
        <StyledErrorState heading={heading} description={description} />
      </StyledGrid>
      <Grid item>
        <Button onClick={() => PostMessage.reloadRequest()} variant="contained">
          {t('Reload Page')}
        </Button>
      </Grid>
    </StyledGridContainer>
  );
};
