import * as yup from 'yup';

import { auditSchema as auditSchemaBase } from 'domains/supplier/schemas/Audit';
import { dateSchema, generalDetailsSchema } from 'schemas';

import { Audit } from '../../state/filterableLists/types';

export const auditSchema: yup.ObjectSchema<Audit> = auditSchemaBase.shape({
  appointmentUuid: yup.string().optional(),
  moduleFamily: generalDetailsSchema.optional().default(undefined),
  uploadedAt: dateSchema.required(),
  version: yup.number().required(),
});
