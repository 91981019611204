import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PageHeader } from 'components/molecules/PageHeader';
import { Tabs } from 'components/organisms/Tabs';
import { TabItem } from 'components/organisms/Tabs/types';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { WHITE } from 'config/appColors';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';

import { AuditPlannerTab } from '../../components/organisms/AuditPlannerTab';
import { AuditsTab } from '../../components/organisms/AuditsTab';
import { CertificatesTab } from '../../components/organisms/CertificatesTab';
import { CompanyDataTab } from '../../components/organisms/CompanyDataTab';
import {
  getSupplierDetails,
  resetSupplierDetailsState,
  selectSupplierDetails,
} from '../../state/details/slice';

const StyledPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;
const StyledPageHeaderWrapper = styled('div')`
  background: ${WHITE};
`;
const StyledPageHeader = styled(PageHeader)`
  margin-bottom: 4rem;

  h2 {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
`;
const StyledTabContentWrapper = styled('div')`
  min-height: 500px;
  padding: 40px;
`;

export const PageSupplierDetails: FC = () => {
  const [t] = useTranslation('supplier');
  const dispatch = useDispatch();
  const { supplierId } = useParams<{ supplierId: string }>();
  const selectedSupplier = useSelector(selectSupplierDetails);
  const pageTitle =
    t('Supplier Details') + getSupplierDetailsTitle(selectedSupplier);

  useEffect(() => {
    dispatch(getSupplierDetails({ supplierId }));

    return () => {
      dispatch(resetSupplierDetailsState());
    };
  }, [dispatch, supplierId]);

  const tabs: TabItem[] = [
    {
      label: t('Company Data'),
      content: (
        <StyledTabContentWrapper>
          <CompanyDataTab />
        </StyledTabContentWrapper>
      ),
    },
    {
      label: t('Certificates/Confirmations'),
      content: (
        <StyledTabContentWrapper>
          <CertificatesTab />
        </StyledTabContentWrapper>
      ),
    },
    {
      label: t('Audits/Assessments'),
      content: (
        <StyledTabContentWrapper>
          <AuditsTab />
        </StyledTabContentWrapper>
      ),
    },
    {
      label: t('Audit Assessment overview'),
      content: (
        <StyledTabContentWrapper>
          <AuditPlannerTab />
        </StyledTabContentWrapper>
      ),
    },
  ];

  return (
    <TemplatePageDefault hidePageHeader>
      <StyledPageWrapper>
        <StyledPageHeaderWrapper>
          <StyledPageHeader title={pageTitle} hideBreadcrumbs />
        </StyledPageHeaderWrapper>
        <Tabs items={tabs} routeParamName="tab" />
      </StyledPageWrapper>
    </TemplatePageDefault>
  );
};

export default PageSupplierDetails;
