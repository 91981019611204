import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ErrorState } from 'components/molecules/ErrorState';

import { AuditAppointmentActionsEnum } from '../../../config/actions';
import { useIsPermittedTo } from '../../../hooks/useIsPermittedTo';
import { selectAuditAppointmentDetailsError } from '../../../state/details/slice';
import { DetailsTabBlackoutTimesSection } from '../DetailsTabBlackoutTimesSection';
import { DetailsTabGeneralSection } from '../DetailsTabGeneralSection';

interface DetailsDetailsTabProps {
  appointmentId: string;
}

const StyledContainer = styled('div')`
  padding: 25px;
  width: 100%;
`;

export const DetailsDetailsTab: FC<DetailsDetailsTabProps> = (props) => {
  const { appointmentId } = props;
  const [t] = useTranslation('auditAppointment');
  const { isPermittedTo } = useIsPermittedTo();
  const error = useSelector(selectAuditAppointmentDetailsError);

  if (error)
    return (
      <ErrorState
        heading={t('Failed to load audit appointment.')}
        description={error.message}
        errorCode={error.details}
      />
    );

  return (
    <StyledContainer>
      <DetailsTabGeneralSection />
      {isPermittedTo(AuditAppointmentActionsEnum.BLACKOUT_VIEW) && (
        <DetailsTabBlackoutTimesSection appointmentId={appointmentId} />
      )}
    </StyledContainer>
  );
};
