import { TranslatedText } from 'types';

import { useGetAuditDetailsTemplateQuery } from '../state/auditDetailsTemplate/api';
import { AuditDetailsTabEnum, AuditReleasesTab, ReleaseOption } from '../types';

interface UseReleasesTemplate {
  contentOptions: ReleaseOption[];
  timeFrameOptions: ReleaseOption[];
  translationsMap: Record<string, TranslatedText>;
}

export const useReleasesTemplate = (auditUuid: string): UseReleasesTemplate => {
  const { auditDetailsTemplate } = useGetAuditDetailsTemplateQuery(auditUuid);
  const releasesTemplate = auditDetailsTemplate?.template?.children?.find(
    ({ props: { id } }) => id === AuditDetailsTabEnum.RELEASES
  ) as AuditReleasesTab;
  const { contentOptions = [], timeFrameOptions = [] } =
    releasesTemplate?.props || {};

  const translationsMap = contentOptions.reduce(
    (currentTranslationsMap, option) => ({
      ...currentTranslationsMap,
      [option.id]: option.name,
    }),
    {}
  );

  return {
    contentOptions,
    timeFrameOptions,
    translationsMap,
  };
};
