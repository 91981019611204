import * as yup from 'yup';

import {
  generalDetailsSchema,
  generalDetailsWithEnumSchema,
  prefixedStartEndDatesSchema,
  relatedCertificateSchema,
  relatedCertificationBodySchema,
  relatedSupplierSchema,
  resultSchema,
} from 'schemas';
import { Audit, AuditStatusEnum } from 'types';

export const auditSchema: yup.ObjectSchema<Audit> = yup.object({
  uuid: yup.string().required(),
  ceid: yup.string(),
  auid: yup.string(),
  certificationBody: relatedCertificationBodySchema.required(),
  supplier: relatedSupplierSchema.required(),
  module: generalDetailsSchema.required(),
  auditTimeframe: prefixedStartEndDatesSchema.required(),
  status: generalDetailsWithEnumSchema(AuditStatusEnum).required(),
  type: generalDetailsSchema.required(),
  kind: generalDetailsSchema.required(),
  result: resultSchema.required(),
  certificate: relatedCertificateSchema.optional().default(undefined),
});
