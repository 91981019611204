const dateRegexp = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/; // format: yyyy-MM-dd, 2022-10-18

/**
 * @param date format: yyyy-MM-dd, 2022-10-18 otherwise return empty string
 * @returns string
 */
export const addHourToDate = (date: string): string => {
  if (date.match(dateRegexp)) {
    return `${date} 00:00:00`;
  }

  console.warn('Invalid string date value.');
  return '';
};
