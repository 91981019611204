import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { AddDataParams } from './types';
import { endpoints } from '../../config/endpoints';

const certificateDraftDataApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    addData: build.mutation<unknown, AddDataParams>({
      query: ({ certificateDraftId, data }) => ({
        url: endpoints.DATA.ADD(certificateDraftId),
        method: 'PUT',
        data,
      }),
    }),
  }),
});

export const useCertificateDraftAddDataMutation = () => {
  const [addData, mutation] = certificateDraftDataApi.useAddDataMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: AddDataParams) =>
    addData(params).unwrap();

  return {
    ...mutation,
    addData: requestMethod,
    status,
    error,
  };
};
