import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useActionInformationData } from 'components/organisms/ActionInformationSection';
import { DeleteModalForm } from 'components/organisms/DeleteModalForm';
import { NextAction } from 'components/templates/TemplateModalWithSnackbar';
import { CERTIFICATES_CONFIRMATIONS_TAB_SLUG } from 'config/constants';
import { getSupplierPathname as YII_getSupplierPathname } from 'domains/widgets/utils/getSupplierPathname';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';
import { getSupplierPathname } from 'utils/getSupplierPathname';
import isIframe from 'utils/isIframe';

import { actionReasons } from '../../../config/constants';
import { useDeleteCertificateMutation } from '../../../state/deleteCertificate/api';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { CertificateModalCommonProps } from '../../../types';
import { CertificateDetailsModalBase } from '../../templates/CertificateDetailsModalBase';

type DeleteCertificateModalProps = CertificateModalCommonProps;

export const DeleteCertificateModal: FC<DeleteCertificateModalProps> = (
  props
) => {
  const { certificateUuid, supplierId } = props;
  const [t] = useTranslation('certificateDetails');
  const history = useHistory();

  const {
    isAdminInfoValid,
    adminReason,
    adminComment,
    handleAdminReasonChange,
    handleAdminCommentChange,
    setIsAdminInfoValid,
  } = useActionInformationData();

  const {
    deleteCertificate,
    isLoading,
    error = [],
  } = useDeleteCertificateMutation();
  const nextAction: NextAction = (openSnackbar, closeModal) => {
    deleteCertificate({
      certificateUuid,
      actionComment: {
        reason: adminReason,
        ...(adminComment && {
          comment: adminComment,
        }),
      },
    }).then(() => {
      openSnackbar(t('Certificate is deleted.'), 'success');
      closeModal();
      // TODO: once we get rid of iframe things ternary&YII_getSupplierPathname should be removed
      const supplierPathname = isIframe
        ? YII_getSupplierPathname(supplierId, 'CERTIFICATES')
        : getSupplierPathname(supplierId, CERTIFICATES_CONFIRMATIONS_TAB_SLUG);

      history.push(supplierPathname);
    });
  };

  const alertsState = useMemoizedAlerts(error);
  const description = t('Do you really want to delete the certificate?');

  return (
    <CertificateDetailsModalBase
      title={t('Delete Certificate')}
      nextAction={nextAction}
      nextLabel={t('Delete', { ns: 'components' })}
      disableNext={!isAdminInfoValid}
      isLoading={isLoading}
      nextColor="error"
    >
      <DeleteModalForm
        setIsAdminInfoValid={setIsAdminInfoValid}
        adminReason={adminReason}
        adminComment={adminComment}
        adminReasonChangeHandler={handleAdminReasonChange}
        adminCommentChangeHandler={handleAdminCommentChange}
        adminReasonOptions={
          actionReasons[CertificateDetailsModalEnum.REMOVE_CERTIFICATE]
        }
        alertsState={alertsState}
        description={description}
      />
    </CertificateDetailsModalBase>
  );
};
