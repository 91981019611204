export enum AuditAppointmentActionsEnum {
  UPDATE_DESCRIPTION = 'audit-appointment-update-description',
  CANCEL = 'audit-appointment-cancel',
  SET_DUE_DATE = 'audit-appointment-set-due-date',
  RESET_DUE_DATE = 'audit-appointment-reset-due-date',
  BLACKOUT_ADD = 'audit-appointment-blackout-add',
  BLACKOUT_DELETE = 'audit-appointment-blackout-delete',
  LOGS = 'audit-appointment-logs',
  BLACKOUT_VIEW = 'audit-appointment-blackout-view',
  REACTIVATE = 'audit-appointment-reactivate',
}
