import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { Nullable } from 'types';

import { AuditDetailsModalState, OpenAuditModalPayload } from './types';
import { parentReducerName } from '../../config/constants';
import { Release } from '../../types';

const initialState: AuditDetailsModalState = {
  openModalName: null,
  selectedRelease: null,
};

const reducerName = `${parentReducerName}/modal`;

export const auditDetailsModalSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    openAuditModal: (state, action: PayloadAction<OpenAuditModalPayload>) => {
      const { name } = action.payload;

      state.openModalName = name;
    },
    setSelectedRelease: (state, action: PayloadAction<Release>) => {
      state.selectedRelease = action.payload;
    },
    closeAndResetAuditModal: () => initialState,
  },
});

// actions
export const { openAuditModal, setSelectedRelease, closeAndResetAuditModal } =
  auditDetailsModalSlice.actions;

// selectors
export const selectAuditDetailsModalName = (
  state: RootState
): AuditDetailsModalState['openModalName'] =>
  state[parentReducerName].openModalName;

export const selectSelectedRelease = (state: RootState): Nullable<Release> =>
  state[parentReducerName].selectedRelease;

// reducer
export default auditDetailsModalSlice.reducer;
