import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { invalidateRTKTags, Tag } from 'utils/invalidateRTKTags';

import { ChangeCertificationBodyParams, Collection } from './types';
import endpoints from '../../config/endpoints';

const variants: Record<Collection, Tag[]> = {
  certificates: ['CERTIFICATE_DETAILS', 'CERTIFICATE_DETAILS_ACTIONS'],
  audits: ['AUDIT_DETAILS', 'AUDIT_DETAILS_ACTIONS'],
};

const invalidateResolver = (params: ChangeCertificationBodyParams) =>
  variants[params.collection];

const changeCertificationBodyApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    changeCertificationBody: build.mutation<
      void,
      ChangeCertificationBodyParams
    >({
      query: ({ collection, relatedEntityUuid, ...data }) => ({
        url: endpoints.CHANGE_CB(collection, relatedEntityUuid),
        data,
        method: 'PUT',
      }),
      invalidatesTags:
        invalidateRTKTags<ChangeCertificationBodyParams>(invalidateResolver),
    }),
  }),
});

export const useChangeCertificationBodyMutation = () => {
  const [changeCertificationBody, mutation] =
    changeCertificationBodyApi.useChangeCertificationBodyMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: ChangeCertificationBodyParams) =>
    changeCertificationBody(params).unwrap();

  return {
    ...mutation,
    changeCertificationBody: requestMethod,
    status,
    error: error || [],
  };
};
