import globalRoutes, { AUDIT_AMENDMENT_FORM_GENERAL_URL } from 'config/routes';
import { UUID_V4 } from 'utils/regex/uuid';

const parentRoute = globalRoutes.IFRAME;

const WidgetRoutes = {
  AUDIT_APPOINTMENTS: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/audit-appointments`,
  AUDIT_APPOINTMENT_DETAILS: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/audit-appointments/:auditAppointmentId`,
  AUDIT_DETAILS: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/audits/:auditUuid`,
  AUDITS: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/audits`,
  DIARY: `${parentRoute}/diary`,
  CB_DIARY: `${parentRoute}/certification-bodies/:certificationBodyUuid(${UUID_V4})/diary`,
  AUDIT_FILE_UPLOAD_REGULAR_UPLOAD: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/audit-file-upload-general`,
  AUDIT_FILE_UPLOAD_AMENDMENT_UPLOAD: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/audit-amendment-create-form-ifs-file-general/:auditId(${UUID_V4})`,
  AUDIT_DRAFT_FORM: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/audit-draft-form-general/:auditDraftId(${UUID_V4})`,
  AUDIT_AMENDMENT_FORM: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/${AUDIT_AMENDMENT_FORM_GENERAL_URL}/:auditDraftId(${UUID_V4})`,
  AUDIT_DRAFT_UPDATE_DOCUMENTS_INIT: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/audit/:auditId(${UUID_V4})/document-update`,
  AUDIT_DRAFT_UPDATE_DOCUMENTS: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/audit-draft-document-update/:auditDraftId(${UUID_V4})`,
  CERTIFICATES: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/certificates`,
  CERTIFICATE_DETAILS: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/certificates/:certificateUuid`,
  CERTIFICATE_DRAFT_FORM: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/certificate-draft-form-general/:certificateDraftId(${UUID_V4})`,
  CERTIFICATE_DRAFT_CREATE: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/certificate-draft-form-general`,
  CERTIFICATE_DRAFT_UPDATE: `${parentRoute}/suppliers/:supplierId(${UUID_V4})/certificate-draft-create-update-general/:certificateId(${UUID_V4})`,
  ERROR_PAGE: `${parentRoute}/iframe-error`,
  FILTERABLE_AUDITS_CERTIFICATES: `${parentRoute}/certification-bodies/:certificationBodyUuid(${UUID_V4})/audits-certificates`,
};

export default WidgetRoutes;
