import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { CloseButton } from 'components/atoms/CloseButton/CloseButton';
import { ModalTitle } from 'components/atoms/ModalTitle/ModalTitle';

interface ModalHeaderProps {
  title: ReactNode;
  closeAction?: () => void;
  showCloseButton?: boolean;
}

interface ShowCloseButtonProp {
  $showCloseButton: boolean;
}

export const MODAL_HEADER_HEIGHT = 54;

const StyledModalTitle = styled(ModalTitle)<ShowCloseButtonProp>`
  padding-right: ${(props) => (props.$showCloseButton ? '48px' : undefined)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: ${MODAL_HEADER_HEIGHT}px;
`;

const StyledCloseButton = styled(CloseButton)`
  top: 50%;
  right: 2px;
  margin-top: -23px;

  &:hover {
    background-color: transparent;
  }
`;

export const ModalHeader: FC<ModalHeaderProps> = (props) => {
  const { showCloseButton = true, closeAction, title } = props;

  return (
    <StyledModalTitle $showCloseButton={showCloseButton}>
      {title}
      {showCloseButton && closeAction && (
        <StyledCloseButton onClick={closeAction} />
      )}
    </StyledModalTitle>
  );
};

export default ModalHeader;
