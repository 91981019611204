import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useActionInformationData } from 'components/organisms/ActionInformationSection';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { LoaderStatusEnum, ModalProps } from 'types';

import { useReactivateAuditAppointmentMutation } from '../../../state/reactivate/api';
import { AuditAppointmentBaseModal } from '../AuditAppointmentBaseModal';
import { ReactivateAuditAppointmentModalContent } from '../ReactivateAuditAppointmentModalContent';

interface ReactivateAuditAppointmentModalProps extends ModalProps {
  appointmentId: string;
}

export const ReactivateAuditAppointmentModal: FC<ReactivateAuditAppointmentModalProps> =
  (props) => {
    const { open, handleClose, appointmentId } = props;
    const [t] = useTranslation('auditAppointment');
    const { status: loadingStatus, reactivateAuditAppointment } =
      useReactivateAuditAppointmentMutation();

    const {
      isAdminInfoValid,
      setIsAdminInfoValid,
      adminReason,
      adminComment,
      handleAdminReasonChange,
      handleAdminCommentChange,
    } = useActionInformationData();

    const nextAction = () => {
      if (appointmentId && isAdminInfoValid) {
        reactivateAuditAppointment({
          appointmentId,
          actionComment: { reason: adminReason, comment: adminComment },
        });
      }
    };

    return (
      <AuditAppointmentBaseModal open={open}>
        <TemplateAuditModal
          title={t('Reactivate Audit Appointment')}
          contentComponent={
            <ReactivateAuditAppointmentModalContent
              setIsAdminInfoValid={setIsAdminInfoValid}
              adminReason={adminReason}
              adminComment={adminComment}
              adminReasonChangeHandler={handleAdminReasonChange}
              adminCommentChangeHandler={handleAdminCommentChange}
            />
          }
          closeAction={handleClose}
          prevAction={handleClose}
          nextAction={nextAction}
          noPrevArrow
          noNextArrow
          prevLabel={t('Close', { ns: 'components' })}
          nextLabel={t('Reactivate', { ns: 'components' })}
          prevColor="modalPrevButton"
          nextColor="primary"
          disableNext={!isAdminInfoValid}
          nextButtonLoading={loadingStatus === LoaderStatusEnum.LOADING}
          hidePrevButton={loadingStatus === LoaderStatusEnum.LOADING}
        />
      </AuditAppointmentBaseModal>
    );
  };
