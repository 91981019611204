import { useTranslation } from 'react-i18next';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryReset } from 'hooks/useRTKQueryReset';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { apiSlice, invalidateTags } from 'store/apiSlice';
import { createErrorAlertState } from 'utils/createAlertsState';
import { formatToAlerts } from 'utils/formatToAlerts';
import { getBaseQueryError } from 'utils/getBaseQueryError';
import { invalidateRTKTags } from 'utils/invalidateRTKTags';

import { AddMultipleDocumentsParams, DeleteDocumentParams } from './types';
import { endpoints } from '../../config/endpoints';

const documentManageApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    addAuditDraftDocuments: build.mutation<unknown, AddMultipleDocumentsParams>(
      {
        async queryFn(
          { docDefinitionId, files, language = null, auditDraftId },
          { dispatch },
          extraOptions,
          baseQuery
        ) {
          const errorMessages = [];
          let someError = false;
          let someSucceed = false;

          for (let i = 0; i < files.length; i += 1) {
            const { fileName, displayName, file } = files[i];

            // intentionally send requests in sequential order
            // eslint-disable-next-line no-await-in-loop
            const res = await baseQuery({
              url: endpoints.DOCUMENT_MANAGE.ADD_DOCUMENT(auditDraftId),
              method: 'PUT',
              data: {
                docDefinitionId,
                language,
                file,
                fileName,
                ...(displayName && { displayName }),
              },
            });

            if (res.error) {
              someError = true;
              errorMessages.push(...formatToAlerts(res.error));
            } else {
              someSucceed = true;
            }
          }

          if (someSucceed) {
            dispatch(
              invalidateTags([
                'AUDIT_DRAFT_DETAILS',
                'AUDIT_DRAFT_DOCUMENT_DEFINITIONS',
              ])
            );
          }

          if (someError) {
            return getBaseQueryError(errorMessages);
          }

          return { data: null };
        },
      }
    ),
    deleteAuditDraftDocument: build.mutation<unknown, DeleteDocumentParams>({
      query({ auditDraftId, documentId }) {
        return {
          url: endpoints.DOCUMENT_MANAGE.DELETE_DOCUMENT(
            auditDraftId,
            documentId
          ),
          method: 'DELETE',
        };
      },
      invalidatesTags: invalidateRTKTags([
        'AUDIT_DRAFT_DETAILS',
        'AUDIT_DRAFT_DOCUMENT_DEFINITIONS',
      ]),
    }),
  }),
});

export const useAddDocumentsMutation = () => {
  const [addDocuments, mutation] =
    documentManageApi.useAddAuditDraftDocumentsMutation({
      fixedCacheKey: 'add-audit-draft-document',
    });
  const error = useFormattedError(mutation, 'formatToAlerts');
  const [t] = useTranslation('auditDraft');
  const { openSnackbar } = useSnackbarNotification();
  useRTKQueryReset(mutation, { onUnmount: true }); // to avoid notification twice

  const requestMethod = async (params: AddMultipleDocumentsParams) => {
    const fileAmount = params.files.length;
    const successMessage = t(
      fileAmount > 1 ? 'Files are uploaded' : 'File is uploaded'
    );
    const errorMessage = t(
      fileAmount > 1 ? 'Files are not uploaded' : 'File is not uploaded'
    );

    return addDocuments(params)
      .unwrap()
      .then(() => openSnackbar(successMessage, 'success'))
      .catch(() => openSnackbar(errorMessage, 'error'));
  };

  return {
    ...mutation,
    addDocuments: requestMethod,
    error,
  };
};

export const useDeleteDocumentMutation = () => {
  const [deleteDocument, mutation] =
    documentManageApi.useDeleteAuditDraftDocumentMutation({
      fixedCacheKey: 'delete-audit-draft-document',
    });
  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: DeleteDocumentParams) =>
    deleteDocument(params).unwrap();

  return {
    ...mutation,
    deleteDocument: requestMethod,
    status,
    error: error ? createErrorAlertState(error) : null,
    reset: mutation.reset,
  };
};
