import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from 'components/atoms/Button';

import { useGetCertificateDetailsActions } from '../../../state/certificateDetailsActions/api';
import { openCertificateModal } from '../../../state/modal/slice';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { CertificateActionsEnum } from '../../../types';

interface RequestAuditAccessActionProps {
  certificateUuid: string;
}

export const RequestAuditAccessAction: FC<RequestAuditAccessActionProps> = ({
  certificateUuid,
}) => {
  const [t] = useTranslation('certificateDetails');
  const { certificateDetailsActions: permittedActions } =
    useGetCertificateDetailsActions(certificateUuid);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      openCertificateModal({
        name: CertificateDetailsModalEnum.REQUEST_AUDIT_ACCESS,
      })
    );
  };

  if (!permittedActions.includes(CertificateActionsEnum.REQUEST_AUDIT_ACCESS)) {
    return null;
  }

  return (
    <Button variant="contained" color="info" size="small" onClick={handleClick}>
      {t('Request detail access')}
    </Button>
  );
};
