import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from 'components/atoms/SectionTitle';
import { Table, usePagination } from 'components/organisms/Table';
import { useLogColumns } from 'hooks/useLogColumns';

import { useGetAuditAppointmentLogsQuery } from '../../../state/detailsLogs/api';

interface DetailsDetailsTabProps {
  appointmentId: string;
}

const StyledTable = styled(Table)`
  table,
  .skeleton-container {
    min-width: 910px;
  }

  .skeleton-container {
    padding: 0 10px;
  }
` as typeof Table;

const StyledContainer = styled('div')`
  width: 100%;
  padding: 25px;

  tbody td {
    vertical-align: top;
  }
`;
const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 10px;
`;

export const DetailsLogsTab: FC<DetailsDetailsTabProps> = (props) => {
  const { appointmentId } = props;
  const [t] = useTranslation('auditAppointment');
  const { pagination, limit, offset, setTotalRowsAmount } = usePagination();
  const { error, status, logs, total } = useGetAuditAppointmentLogsQuery({
    params: {
      limit,
      offset,
    },
    uuid: appointmentId,
  });
  const columns = useLogColumns();

  useEffect(() => {
    if (total > 0) {
      setTotalRowsAmount(total);
    }
  }, [setTotalRowsAmount, total]);

  return (
    <StyledContainer>
      <StyledSectionTitle>{t('Logs')}</StyledSectionTitle>
      <StyledTable
        rows={logs}
        columns={columns}
        status={status}
        error={error}
        errorHeadingText={t('Failed to load audit appointment logs.')}
        pagination={pagination}
        propertyAsAKey="executedAt"
        dense
      />
    </StyledContainer>
  );
};
