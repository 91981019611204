import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Tabs from 'components/organisms/Tabs';
import { TemplateBasicPageContent } from 'domains/supplier/components/templates/TemplateBasicPageContent';
import {
  getSupplierDetails,
  selectSupplierDetailsError,
  selectSupplierDetailsLoading,
} from 'domains/supplier/state/details/slice';

import { CustomTitle } from '../../molecules/CustomTitle';
import { ActiveCertificatesTab } from '../ActiveCertificatesTab';
import { InactiveCertificatesTab } from '../InactiveCertificatesTab';

const StyledTemplateBasicPageContent = styled(TemplateBasicPageContent)`
  padding: 20px;
`;

export const WidgetCertificates: FC = () => {
  const [t] = useTranslation('supplier');
  const dispatch = useDispatch();
  const { supplierId } = useParams<{ supplierId: string }>();

  useEffect(() => {
    dispatch(getSupplierDetails({ supplierId }));
  }, [dispatch, supplierId]);
  const supplierDetailsLoading = useSelector(selectSupplierDetailsLoading);
  const supplierDetailsError = useSelector(selectSupplierDetailsError);

  return (
    <StyledTemplateBasicPageContent
      customTitleComponent={<CustomTitle variant="certificateDraft" />}
      loaderStatus={supplierDetailsLoading}
      minHeight="230px"
      error={{
        heading: t('Failed to load supplier details'),
        description: supplierDetailsError?.message,
        errorCode: supplierDetailsError?.details,
      }}
    >
      <Tabs
        items={[
          {
            label: t('Active'),
            content: <ActiveCertificatesTab />,
          },
          {
            label: t('Inactive'),
            content: <InactiveCertificatesTab />,
          },
        ]}
      />
    </StyledTemplateBasicPageContent>
  );
};
