import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { NextAction } from 'components/templates/TemplateModalWithSnackbar';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';
import { isActionCommentFieldValid } from 'utils/validation/isActionCommentFieldValid';

import { useTranslatedSelectOptions } from '../../../hooks/useTranslatedSelectOptions';
import { useRemoveGroupCertificateMutation } from '../../../state/deleteGroupCertificate/api';
import { selectCertificateDetailsModalFormValues } from '../../../state/modal/slice';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { CertificateModalCommonProps } from '../../../types';
import { CertificateDetailsModalBase } from '../../templates/CertificateDetailsModalBase';
import { CertificateDetailsModalContentBase } from '../../templates/CertificateDetailsModalContentBase';

type DeleteGroupCertificateModalProps = CertificateModalCommonProps;

const StyledCertificateDetailsModalContentBase = styled(
  CertificateDetailsModalContentBase
)`
  hr {
    display: none;
  }

  .AlertList {
    padding-bottom: 5px;
  }

  .ActionInformationSection {
    margin-top: -20px;
  }
`;

export const DeleteGroupCertificateModal: FC<DeleteGroupCertificateModalProps> =
  (props) => {
    const { certificateUuid } = props;
    const [t] = useTranslation('certificateDetails');
    const { actionComment } = useSelector(
      selectCertificateDetailsModalFormValues
    );

    const {
      removeGroupCertificate,
      isLoading,
      error = [],
    } = useRemoveGroupCertificateMutation();
    const nextAction: NextAction = (openSnackbar, closeModal) => {
      removeGroupCertificate({
        certificateUuid,
        actionComment,
      }).then(() => {
        openSnackbar(t('The group certificate is deleted.'), 'success');
        closeModal();
      });
    };
    const actionReasonOptions = useTranslatedSelectOptions({
      type: CertificateDetailsModalEnum.REMOVE_GROUP_CERTIFICATE,
      collectionName: 'actionReasons',
    });
    const alertsState = useMemoizedAlerts(error);
    const { reason, comment = null } = actionComment;
    const disableNextButton = !isActionCommentFieldValid(reason, comment);

    return (
      <CertificateDetailsModalBase
        title={t('Delete Group Certificate')}
        prevLabel={t('Close', { ns: 'components' })}
        nextAction={nextAction}
        nextLabel={t('Delete', { ns: 'components' })}
        disableNext={disableNextButton}
        isLoading={isLoading}
        nextColor="error"
      >
        <StyledCertificateDetailsModalContentBase
          actionReasonOptions={actionReasonOptions}
          adminCommentRows={6}
          alertsState={alertsState}
        />
      </CertificateDetailsModalBase>
    );
  };
