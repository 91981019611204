import * as yup from 'yup';

import { generalDetailsSchema } from 'schemas';
import { ExceptionalCircumstance } from 'types';

export const exceptionalCircumstanceSchema: yup.ObjectSchema<ExceptionalCircumstance> =
  yup.object({
    uuid: yup.string().required(),
    type: generalDetailsSchema.required(),
    description: yup.string().required(),
    closeReason: yup.string().nullable().optional(),
  });
