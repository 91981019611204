import { styled } from '@mui/material';
import { groupBy, sortBy } from 'lodash';
import React, { FC, Fragment } from 'react';

import { TimeLog } from 'types';
import { extractDate, formatDate } from 'utils/dates';
import { generateId } from 'utils/generateId';

import { AuditParticipantHoursList } from '../AuditParticipantHoursList';

const StyledDate = styled('p')`
  font-weight: 700;
  margin: 0;
`;

const getTime = (dateAsAString: string) => new Date(dateAsAString).getTime();

interface AuditParticipantDateTimeProps {
  timeLog?: TimeLog[];
}

export const AuditParticipantDateTime: FC<AuditParticipantDateTimeProps> = (
  props
) => {
  const { timeLog = [] } = props;

  const logsGroupedByDateMap = groupBy(timeLog, ({ date }) =>
    extractDate(date)
  );
  const logsGroupedByDateArr = Object.values(logsGroupedByDateMap);
  const logsSortedByDate = sortBy(logsGroupedByDateArr, (logs) =>
    getTime(logs[0].date)
  );

  return (
    <>
      {logsSortedByDate.map((logs, index) => (
        <Fragment key={generateId(logs[0].uuid || '', index)}>
          <StyledDate data-testid="time-log-date">
            {formatDate(logs[0].date)}
          </StyledDate>
          <AuditParticipantHoursList timeLog={logs} />
        </Fragment>
      ))}
    </>
  );
};
