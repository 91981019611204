import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ERROR } from 'config/appColors';
import { ActionDropdownOption } from 'types';

import { useGetAuditDetailsActionsQuery } from '../state/auditDetailsActions/api';
import { openAuditModal, setSelectedRelease } from '../state/modal/slice';
import { AuditDetailsModalEnum } from '../state/modal/types';
import { AuditActionsEnum, Release } from '../types';

const dropdownItemStyles = {
  dropdownDeleteItemStyle: {
    color: `${ERROR.LIGHT}`,
  },
};

interface UseReleaseDropdownType {
  showDropdown: boolean;
  options: ActionDropdownOption[];
}

export const useReleaseDropdown = (
  release: Release,
  auditUuid: string
): UseReleaseDropdownType => {
  const [t] = useTranslation('auditDetails');
  const { auditDetailsActions } = useGetAuditDetailsActionsQuery(auditUuid);

  const dispatch = useDispatch();

  const options: ActionDropdownOption[] = [
    {
      label: t('Update Release'),
      onClickAction: () => {
        dispatch(setSelectedRelease(release));
        dispatch(
          openAuditModal({ name: AuditDetailsModalEnum.UPDATE_RELEASE })
        );
      },
      permission: AuditActionsEnum.AUDIT_RELEASE_UPDATE,
    },
    {
      label: t('Delete Release'),
      onClickAction: () => {
        dispatch(setSelectedRelease(release));
        dispatch(
          openAuditModal({ name: AuditDetailsModalEnum.DELETE_RELEASE })
        );
      },
      sx: dropdownItemStyles.dropdownDeleteItemStyle,
      permission: AuditActionsEnum.AUDIT_RELEASE_DELETE,
    },
  ].filter(({ permission }) => auditDetailsActions.includes(permission));

  const showDropdown = options.length > 0;

  return {
    showDropdown,
    options,
  };
};
