import { styled } from '@mui/material';
import React, { FC } from 'react';

import { CertificateActionsDropdown } from '../CertificateActionsDropdown';
import { RequestAuditAccessAction } from '../RequestAuditAccessAction';

const StyledActionsContainer = styled('div')`
  margin-left: auto;
  display: flex;
  gap: 7px;
  align-items: flex-start;
`;

interface CertificateDetailsTabActionsProps {
  certificateUuid: string;
  supplierId: string;
}

export const CertificateDetailsTabActions: FC<CertificateDetailsTabActionsProps> =
  ({ certificateUuid, supplierId }) => (
    <StyledActionsContainer>
      <RequestAuditAccessAction certificateUuid={certificateUuid} />
      <CertificateActionsDropdown
        certificateUuid={certificateUuid}
        supplierId={supplierId}
      />
    </StyledActionsContainer>
  );
