import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { CertificateDetailsParams } from './types';
import endpoints from '../../config/endpoints';
import { CertificateDetails } from '../../types';

const certificateDetailsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateDetails: build.query<
      CertificateDetails,
      CertificateDetailsParams
    >({
      query: (certificateDetailsParams: CertificateDetailsParams) => ({
        url: endpoints.CERTIFICATE_TAB.DETAILS(
          certificateDetailsParams.certificateUuid
        ),
        params: certificateDetailsParams.certificateVersion,
      }),
      providesTags: ['CERTIFICATE_DETAILS'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetCertificateDetailsQuery = (
  certificateDetailsParams: CertificateDetailsParams,
  skip = false
) => {
  const query = certificateDetailsApi.useGetCertificateDetailsQuery(
    certificateDetailsParams,
    { skip }
  );
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);
  let httpErrorStatusCode; // status is required for ifs custom logic behind the banner visibility

  if (!!query.error && 'response' in query.error) {
    httpErrorStatusCode = query.error.response.status;
  }

  return {
    ...query,
    certificateDetails: query.data,
    error,
    status,
    httpErrorStatusCode,
  };
};
