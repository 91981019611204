import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { createErrorAlertState } from 'utils/createAlertsState';

import { ReactivateAuditAppointmentParams } from './types';
import endpoints from '../../config/endpoints';

const auditAppointmentReactivateApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    reactivateAuditAppointment: build.mutation<
      string,
      ReactivateAuditAppointmentParams
    >({
      query: (params) => ({
        url: endpoints.REACTIVATE(params.appointmentId),
        data: { actionComment: params.actionComment },
        method: 'PUT',
      }),
    }),
  }),
});

export const useReactivateAuditAppointmentMutation = () => {
  const [reactivateAuditAppointment, mutation] =
    auditAppointmentReactivateApi.useReactivateAuditAppointmentMutation({
      fixedCacheKey: 'reactivate-audit-appointment',
    });
  const reset = mutation?.reset;

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: ReactivateAuditAppointmentParams) =>
    reactivateAuditAppointment(params);

  return {
    ...mutation,
    reactivateAuditAppointment: requestMethod,
    status,
    error: error ? createErrorAlertState(error) : null,
    reset,
  };
};
