import { styled } from '@mui/material';

import { Select } from 'components/atoms/Select';

const StyledSelect = styled(Select)`
  width: 100%;
  height: 56px;
`;

StyledSelect.displayName = 'StyledSelect';

const StyledSubItemsContainer = styled('div')`
  padding-left: 10px;
`;

StyledSubItemsContainer.displayName = 'StyledSubItemsContainer';

export { StyledSelect, StyledSubItemsContainer };
