import { BreadcrumbsRoute } from 'react-router-breadcrumbs-hoc';

import { routesWithLangRegex } from './routes';
import DynamicBreadcrumb from '../components/atoms/Breadcrumbs/DynamicBreadcrumb';

export const breadcrumbRoutes: BreadcrumbsRoute[] = [
  {
    path: routesWithLangRegex.SUPPLIER_OVERVIEW,
    breadcrumb: DynamicBreadcrumb,
  },
];
