import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { linkStyles } from 'components/atoms/StyledLink';
import { PostMessage } from 'utils/postMessage';

const StyledLink = styled('span')`
  ${linkStyles};
`;

interface CustomLinkProps {
  children: ReactNode;
  redirectPayload?: string;
}

export const CustomLink: FC<CustomLinkProps> = ({
  children,
  redirectPayload = '',
}) => (
  <StyledLink
    onClick={(e) => {
      e.stopPropagation();
      PostMessage.redirect(redirectPayload);
    }}
  >
    {children}
  </StyledLink>
);
