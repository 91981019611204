import { styled } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import slugify from 'slugify';

import { generateId } from 'utils/generateId';
import { QueryParams } from 'utils/queryParams';

import { TabsProps } from './types';
import { TabPanel } from '../../atoms/TabPanel';
import { ErrorBoundary } from '../../molecules/ErrorBoundary';
import { TabNav } from '../../molecules/TabNav';

const StyledErrorBoundary = styled(ErrorBoundary)`
  padding: 100px 20px;
`;

export const Tabs: FC<TabsProps> = ({
  items,
  routeParamName,
  initialSelectedTabLabel,
  onTabChange,
  className,
  routeState,
  tabNavChildComponent,
}) => {
  const getSlug = (text: string) =>
    slugify(text, { lower: true, strict: true });
  const { getParam, updateQueryParams } = QueryParams;
  const tabQueryParam = routeParamName ? getParam(routeParamName) : '';
  const initialSelectedTabSlug =
    tabQueryParam ||
    (initialSelectedTabLabel ? getSlug(initialSelectedTabLabel) : '');
  const getInitialSelectedTabIndex = () => {
    const index = items.findIndex(
      ({ label }) => initialSelectedTabSlug === getSlug(label)
    );

    return index > -1 ? index : 0;
  };
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    getInitialSelectedTabIndex()
  );
  const selectedTabSlug = getSlug(items[selectedTabIndex].label);
  const { replace } = useHistory();
  const extendedItems = items.map((tab, index) => ({
    ...tab,
    navId: generateId(`tab-${tab.label}`, index),
    panelId: generateId(`tabpanel-${tab.label}`, index),
  }));

  const changeTab = (event: ChangeEvent<unknown>, tabIndex: number) => {
    setSelectedTabIndex(tabIndex);

    if (onTabChange) {
      const slug = getSlug(items[tabIndex].label);
      onTabChange(tabIndex, slug);
    }
  };

  useEffect(() => {
    if (routeParamName && tabQueryParam !== selectedTabSlug) {
      replace({
        search: updateQueryParams({ [routeParamName]: selectedTabSlug }),
        state: routeState,
      });
    }
  }, [
    selectedTabSlug,
    replace,
    routeParamName,
    tabQueryParam,
    updateQueryParams,
    routeState,
  ]);

  return (
    <div className={className}>
      <TabNav
        items={extendedItems}
        selectedIndex={selectedTabIndex}
        onChange={changeTab}
        tabNavChildComponent={tabNavChildComponent}
      />
      <div className="tab-content">
        {extendedItems.map(({ content, panelId, navId }, index) => (
          <TabPanel
            selectedIndex={selectedTabIndex}
            index={index}
            key={panelId}
            id={panelId}
            navId={navId}
          >
            <StyledErrorBoundary staticPosition>{content}</StyledErrorBoundary>
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
