import React, { FC } from 'react';

import { MultiSelect } from 'components/atoms/MultiSelect';
import { isArrayOfString } from 'utils/isArrayOfString';

import { MultiSelectFilter, UseFiltersAndSorting } from '../../../types';
import { StyledTextField } from '../../atoms/StyledTextField';

type MultiSelectFilterCellProps = MultiSelectFilter & UseFiltersAndSorting;

export const MultiSelectFilterCell: FC<MultiSelectFilterCellProps> = (
  props
) => {
  const {
    removeValueFromFilterList,
    setFilterValue,
    options,
    filterKey,
    filters,
  } = props;
  const value = filters[filterKey];
  const valueToDisplay = isArrayOfString(value) ? value : [];

  return (
    <MultiSelect
      renderInput={(params) => <StyledTextField {...params} />}
      onChange={(newValue) => {
        setFilterValue({ filterKey, value: newValue });
      }}
      onDelete={(valueToDelete) => {
        removeValueFromFilterList({ filterKey, value: valueToDelete });
      }}
      value={valueToDisplay}
      options={options}
    />
  );
};
