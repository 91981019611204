import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { AlertsState, LoaderStatusEnum, Nullable } from 'types';
import apiClient from 'utils/apiClient';
import { createErrorAlertState } from 'utils/createAlertsState';
import { formatToAlerts } from 'utils/formatToAlerts';

import { AddBlackoutTimeParams, AddBlackoutTimeState } from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';

const initialState: AddBlackoutTimeState = {
  loading: LoaderStatusEnum.IDLE,
  alertsState: null,
};

const reducerName = `${parentReducerName}/addBlackoutTime`;

// thunks
export const addBlackoutTime = createAsyncThunk(
  reducerName,
  async (
    params: AddBlackoutTimeParams,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { appointmentId, startDate, endDate, actionComment } = params;
      const data = { startDate, endDate, actionComment };
      const response = await apiClient.post(
        endpoints.BLACKOUT_TIME.ADD(appointmentId),
        data
      );
      return fulfillWithValue(response.data);
    } catch (err) {
      const errors = formatToAlerts(err);
      return rejectWithValue(errors);
    }
  }
);

// slice
export const addBlackoutTimeSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetAddBlackoutTimeState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(addBlackoutTime.pending, (state) => {
      state.loading = LoaderStatusEnum.LOADING;
      state.alertsState = null;
    });
    builder.addCase(addBlackoutTime.fulfilled, (state) => {
      state.loading = LoaderStatusEnum.SUCCESS;
      state.alertsState = null;
    });
    builder.addCase(addBlackoutTime.rejected, (state, action) => {
      state.loading = LoaderStatusEnum.ERROR;
      state.alertsState = createErrorAlertState(action.payload);
    });
  },
});

// actions
export const { resetAddBlackoutTimeState } = addBlackoutTimeSlice.actions;

// selectors
export const selectAddBlackoutTimeLoading = (
  state: RootState
): LoaderStatusEnum => state[parentReducerName].addBlackoutTime.loading;

export const selectAddBlackoutTimeAlertsState = (
  state: RootState
): Nullable<AlertsState> =>
  state[parentReducerName].addBlackoutTime.alertsState;

export default addBlackoutTimeSlice.reducer;
