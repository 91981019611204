import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { ERROR, SUCCESS } from 'config/appColors';
import { CertificateLock } from 'types';
import { formatDate } from 'utils/dates';

import { CATEGORY_OTHERS_REASON } from '../../../config/constants';
import { useGetCertificateLockHistoryQuery } from '../../../state/certificateLockHistoryTab/api';
import { LabelValueList } from '../../molecules/LabelValueList';
import { LockHistoryActionsDropdown } from '../../molecules/LockHistoryActionsDropdown';

const StyledTable = styled(Table)`
  table td {
    vertical-align: top;
  }
` as typeof Table;

interface LockHistoryTabProps {
  certificateUuid: string;
  hideActions?: boolean;
}

export const LockHistoryTab: FC<LockHistoryTabProps> = ({
  certificateUuid,
  hideActions = false,
}) => {
  const [t] = useTranslation('certificateDetails');

  const { offset, limit, pagination, setTotalRowsAmount } = usePagination();
  const { error, locks, total, status } = useGetCertificateLockHistoryQuery({
    certificateUuid,
    offset,
    limit,
  });
  const { getColumnConfig } = useColumnConfig('40%');

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const lockHistoryColumns: Column<CertificateLock>[] = [
    {
      headerName: t('Date / Type'),
      customCell: ({
        type,
        lockDate,
        unlockDate,
        category,
        categoryOthersDescription = '',
      }) => (
        <LabelValueList
          data={[
            {
              label: t('Type', { ns: 'components' }),
              value: type.name,
            },
            {
              label: t('Category'),
              value: (
                <>
                  {category.name}
                  {category.id === CATEGORY_OTHERS_REASON && (
                    <>
                      <br />
                      {categoryOthersDescription}
                    </>
                  )}
                </>
              ),
            },
            {
              label: t('Unlock date'),
              value: formatDate(unlockDate) || ' ',
            },
            {
              label: t('Lock date'),
              value: formatDate(lockDate),
            },
          ]}
        />
      ),
      ...getColumnConfig('25%'),
    },
    {
      headerName: t('Lock Reason / Unlock Reason'),
      customCell: ({
        descriptionEnglish,
        descriptionOtherLanguage,
        unlockReasonDescription,
      }) => (
        <LabelValueList
          withDivider
          data={[
            {
              label: t('Unlock Description'),
              value: unlockReasonDescription,
              labelColor: SUCCESS.MAIN,
            },
            {
              label: t('Lock Description (en)'),
              value: descriptionEnglish,
              labelColor: ERROR.MAIN,
            },
            {
              label: t('Lock Description (other language)'),
              value: descriptionOtherLanguage,
              labelColor: ERROR.MAIN,
            },
          ]}
        />
      ),
      ...getColumnConfig(),
    },
    ...(hideActions
      ? []
      : [
          {
            headerName: '',
            customCell: (lockHistoryItem: CertificateLock) => (
              <LockHistoryActionsDropdown
                lockHistoryItem={lockHistoryItem}
                certificateUuid={certificateUuid}
              />
            ),
            ...getColumnConfig('5%', {
              otherCellProps: {
                align: 'right',
              },
            }),
          },
        ]),
  ];

  return (
    <StyledTable
      columns={lockHistoryColumns}
      rows={locks}
      status={status}
      error={error}
      pagination={pagination}
      dense
    />
  );
};
