import { styled } from '@mui/material';
import React, { FC } from 'react';

import { DottedButton } from 'components/atoms/DottedButton';
import { DropdownMenu } from 'components/molecules/DropdownMenu';

import { useReleaseDropdown } from '../../../hooks/useReleaseDropdown';
import { Release } from '../../../types';

const StyledDropdownMenu = styled(DropdownMenu)`
  display: flex;
  justify-content: flex-end;
`;

interface ReleaseActionsDropdownProps {
  release: Release;
  auditUuid: string;
}

export const ReleaseActionsDropdown: FC<ReleaseActionsDropdownProps> = ({
  release,
  auditUuid,
}) => {
  const { options, showDropdown } = useReleaseDropdown(release, auditUuid);

  return showDropdown ? (
    <StyledDropdownMenu
      options={options}
      renderOpenButton={({ onClick }) => <DottedButton onClick={onClick} />}
    />
  ) : null;
};
