import { styled } from '@mui/material';
import React, { ComponentProps, FC, ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ModalFooterLoader } from 'components/atoms/ModalFooterLoader';
import { ModalTitleWithDetails } from 'components/atoms/ModalTitleWithDetails';
import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { AlertList } from 'components/molecules/AlertList';
import Loader from 'components/molecules/Loader';
import { TemplateStepperModal } from 'components/templates/TemplateStepperModal';
import { WHITE } from 'config/appColors';
import { FileUploadAbortModal } from 'domains/auditFileUpload/components/organisms/FileUploadAbortModal';
import {
  BackAction,
  BtnConfig,
  LoaderStatusEnum,
  NextAction,
  TranslatedText,
} from 'types';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useCertificateDraftAbortModal } from '../../../hooks/useCertificateDraftAbortModal';
import { useCertificateDraftStepper } from '../../../hooks/useCertificateDraftStepper';
import { useCertificateDraftDetailsQuery } from '../../../state/certificateDraftDetails/api';
import { CertificateDraftURLParams } from '../../../types';

const StyledStepperModalWrapper = styled('div')`
  background: ${WHITE};
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14),
    0 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 980px;
  margin: 0 auto;
`;

const StyledContentComponentWrapper = styled('div')`
  width: 100%;
  max-width: 980px;

  .main-container {
    padding-left: 15px;
  }
`;

const StyledStepDescription = styled(StepDescription)`
  margin: 20px 0;
`;

type TemplateStepperModalProps = ComponentProps<typeof TemplateStepperModal>;

interface TemplateCertificateDraftStepProps
  extends Pick<
    TemplateStepperModalProps,
    | 'prevLabel'
    | 'nextLabel'
    | 'noNextArrow'
    | 'noPrevArrow'
    | 'prevButtonComponent'
    | 'nextButtonComponent'
    | 'hideNextButton'
    | 'hidePrevButton'
    | 'disablePrev'
    | 'disableNext'
  > {
  contentStatus?: LoaderStatusEnum;
  nextButtonStatus?: LoaderStatusEnum;
  nextAction?: NextAction;
  backAction?: BackAction;
  onPrevClick?: (changeCurrentStep: () => void) => void;
  onNextClick?: (changeCurrentStep: () => void) => void;
  children?: ReactNode;
  error?: string[];
  customStepTitle?: ReactNode;
  customStepInstruction?: ReactNode;
  customAlertList?: ReactNode;
  warningBox?: ReactNode;
}

export const TemplateCertificateDraftStep: FC<TemplateCertificateDraftStepProps> =
  (props) => {
    const {
      children,
      nextAction,
      backAction,
      onPrevClick,
      onNextClick,
      noNextArrow = true,
      noPrevArrow = true,
      prevLabel,
      nextLabel,
      contentStatus = LoaderStatusEnum.SUCCESS,
      nextButtonStatus = LoaderStatusEnum.SUCCESS,
      hideNextButton,
      hidePrevButton,
      prevButtonComponent,
      nextButtonComponent,
      disablePrev,
      disableNext,
      error,
      customStepTitle,
      customStepInstruction,
      customAlertList,
      warningBox,
    } = props;

    const {
      steps,
      currentStep,
      certificateDraftTemplate,
      currentStepConfig,
      onStepClick,
      setNextStep,
      setPrevStep,
    } = useCertificateDraftStepper();
    const certificateDraftURLParams = useParams<CertificateDraftURLParams>();
    const { certificateDraftDetails } = useCertificateDraftDetailsQuery(
      certificateDraftURLParams,
      false
    );
    const [t] = useTranslation('components');
    const containerRef = useRef<HTMLDivElement>(null);
    const {
      openAbortModal,
      closeAbortModal,
      handleAbortAction,
      isAbortModalOpen,
    } = useCertificateDraftAbortModal(certificateDraftDetails?.supplier.uuid);
    const handlePrevClick = () => {
      if (onPrevClick) {
        onPrevClick(setPrevStep);
      } else {
        setPrevStep();
      }
    };

    const handleNextClick = () => {
      if (onNextClick) {
        onNextClick(setNextStep);
      } else {
        setNextStep();
      }
    };

    const finalNextButtonComponent =
      nextButtonStatus === LoaderStatusEnum.LOADING ? (
        <ModalFooterLoader />
      ) : (
        nextButtonComponent
      );

    const translatedTitle = getTranslatedValue(
      certificateDraftTemplate?.template.props.headerText
    );

    const title = (
      <ModalTitleWithDetails
        title={`${translatedTitle} ${certificateDraftDetails?.module.name}`}
        details={
          certificateDraftDetails?.supplier
            ? getSupplierDetailsTitle(certificateDraftDetails.supplier)
            : ''
        }
      />
    );

    const instructionText =
      currentStepConfig && 'instructionText' in currentStepConfig.props
        ? getTranslatedValue(currentStepConfig.props.instructionText)
        : '';

    const getButtonLabel = (text?: TranslatedText) =>
      text ? getTranslatedValue(text) : undefined;

    const isButtonHidden = (btnConfig?: BtnConfig, hideProp?: boolean) =>
      hideProp !== undefined ? hideProp : !btnConfig?.enabled;

    useEffect(() => {
      if (error && error.length > 0 && containerRef.current) {
        containerRef.current.scrollIntoView();
      }
    }, [error]);

    return (
      <StyledStepperModalWrapper>
        <FileUploadAbortModal
          abortAction={handleAbortAction}
          continueAction={closeAbortModal}
          open={isAbortModalOpen}
          title={title}
          continueButtonText={t('Continue')}
        />
        <TemplateStepperModal
          activeStep={currentStep || ''}
          steps={steps}
          onStepClick={onStepClick}
          closeAction={openAbortModal}
          title={title}
          contentComponent={
            <Loader status={contentStatus}>
              <StyledContentComponentWrapper ref={containerRef}>
                {customStepTitle || (
                  <StepFormTitle
                    content={
                      currentStepConfig
                        ? getTranslatedValue(currentStepConfig.props.title)
                        : ''
                    }
                  />
                )}
                <div className="main-container">
                  {warningBox}
                  {customAlertList ||
                    (error && error.length > 0 && <AlertList alerts={error} />)}
                  {customStepInstruction ||
                    (instructionText && (
                      <StyledStepDescription content={instructionText} />
                    ))}
                  {children}
                </div>
              </StyledContentComponentWrapper>
            </Loader>
          }
          prevLabel={prevLabel || getButtonLabel(backAction?.btnText)}
          nextLabel={nextLabel || getButtonLabel(nextAction?.btnText)}
          prevAction={handlePrevClick}
          nextAction={handleNextClick}
          noNextArrow={noNextArrow}
          noPrevArrow={noPrevArrow}
          prevButtonComponent={prevButtonComponent}
          nextButtonComponent={finalNextButtonComponent}
          hidePrevButton={isButtonHidden(backAction, hidePrevButton)}
          hideNextButton={isButtonHidden(nextAction, hideNextButton)}
          disablePrev={disablePrev}
          disableNext={disableNext}
          nextColor={nextAction?.type === 'submit' ? 'primary' : 'info'}
          prevColor="modalPrevButton"
        />
      </StyledStepperModalWrapper>
    );
  };
