import AdapterDateFns from '@date-io/date-fns';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { alpha, styled, StyledEngineProvider, TextField } from '@mui/material';
import {
  LocalizationProvider,
  DesktopDatePicker as MuiDatePicker,
  DesktopDatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import React, { FC, forwardRef, ReactElement, useRef, useState } from 'react';

import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { BLACK, PRIMARY, TEXT, WHITE } from 'config/appColors';
import { Nullable } from 'types';

type MuiDatePickerType = Omit<
  MuiDatePickerProps<Date, Date>,
  'renderInput' | 'onChange' | 'value'
>;
export interface DatePickerProps extends MuiDatePickerType {
  inputPlaceholder?: string;
  inputWidth?: string;
  inputTestId?: string;
  datePickerOffset?: string;
  onChange?: (date: Date) => void;
  onClose?: () => void;
  value?: Nullable<string>;
}

const defaultProps: Partial<DatePickerProps> = {
  // eslint-disable-next-line react/default-props-match-prop-types
  inputFormat: 'dd.MM.yyyy',
};

const poppersSx = (datePickerOffset: string) => ({
  '&': {
    left: `${datePickerOffset}!important`,
  },
  '& .MuiTypography-caption': {
    fontSize: '1.2rem',
    color: TEXT.SECONDARY_DARK,
  },
  '& .MuiPickersDay-dayWithMargin': {
    color: BLACK,
    letterSpacing: '0.04rem',
    fontSize: '1.2rem',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: alpha(PRIMARY[100], 0.2),
    },
  },
  '&&& .Mui-selected': {
    color: WHITE,
    backgroundColor: PRIMARY[500],
    '&:hover': {
      backgroundColor: PRIMARY[400],
    },
  },
  '& .MuiPaper-root': {
    boxShadow:
      '0px 1px 6px rgba(11, 60, 93, 0.12), 0px 4px 18px 3px rgba(46, 50, 52, 0.08)',
  },
  '&& .MuiPickersDay-today': {
    border: 'none',
  },
  '& .MuiPickersDay-root:disabled': {
    color: alpha(PRIMARY[800], 0.3),
  },
  '& .MuiPickersDay-root:enabled': {
    color: alpha(PRIMARY[800], 0.8),
  },
});

interface StyledTextFieldProps {
  $width: string;
  $isPopperOpen?: boolean;
}

const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  cursor: none;
  width: ${({ $width }) => $width};

  && > div {
    > input {
      cursor: pointer;
      color: ${TEXT.PRIMARY};
      font-weight: 400;

      &::-webkit-input-placeholder {
        color: ${TEXT.SECONDARY_LIGHT};
        opacity: inherit;
      }
    }

    > .MuiOutlinedInput-notchedOutline {
      top: 0;
      border-color: ${(props) =>
        props.$isPopperOpen ? TEXT.PRIMARY : alpha(BLACK, 0.23)};
      border-width: 1px;

      > legend > span {
        display: none;
      }
    }

    &.Mui-disabled {
      > input {
        cursor: default;

        &::-webkit-input-placeholder {
          -webkit-text-fill-color: ${TEXT.DISABLED}; // necessary because this has precedence over color and MUI uses this
        }
      }

      > .MuiOutlinedInput-notchedOutline {
        border-style: dotted;
      }
    }
  }

  svg {
    font-size: 2.5rem;
  }
`;

const Loader = forwardRef<HTMLDivElement>((_, ref) => (
  <div ref={ref}>
    <LoaderIcon size="2.5rem" />
  </div>
));

export const DatePicker: FC<DatePickerProps> = (props): ReactElement => {
  const [openPopper, setOpenPopper] = useState(false);
  const textFieldRef = useRef<Nullable<HTMLInputElement>>(null);
  const {
    inputPlaceholder,
    inputWidth = '320px',
    inputTestId,
    datePickerOffset = '25px',
    disabled,
    loading,
    onChange,
    onAccept,
    onClose,
    value,
  } = props;
  const OpenPickerIcon = loading ? Loader : DateRangeIcon;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledEngineProvider injectFirst>
        <MuiDatePicker
          {...props}
          onAccept={(date) => {
            setOpenPopper(false);
            onAccept?.(date);
          }}
          open={openPopper}
          PopperProps={{ sx: poppersSx(datePickerOffset) }}
          components={{
            OpenPickerIcon,
          }}
          onChange={(date) => {
            if (date) {
              onChange?.(date);
            }
          }}
          value={value ? parseISO(value) : null}
          onClose={() => {
            setOpenPopper(false);
            onClose?.();
          }}
          inputRef={textFieldRef}
          renderInput={(params) => (
            <StyledTextField
              $isPopperOpen={openPopper}
              {...params}
              $width={inputWidth}
              onClick={() => {
                if (disabled) return;
                setOpenPopper(!openPopper);
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: inputPlaceholder || params.inputProps?.placeholder,
                readOnly: true,
              }}
              data-testid={inputTestId}
            />
          )}
          mask="__.__.____"
        />
      </StyledEngineProvider>
    </LocalizationProvider>
  );
};

DatePicker.defaultProps = defaultProps;

export default DatePicker;
