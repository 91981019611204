import type {
  ProcessingStepsWidgetData,
  SaveProcessingStepsParams,
} from 'components/molecules/WidgetProcessingStepsGeneral/ProcessingStepsWidget';
import type { SaveProductScopesParams } from 'components/molecules/WidgetProductScopesGeneral/ProductScopeWidget';
import type { TranslatedText, WidgetProcessingStepsGeneralConfig } from 'types';

import { flattenScopes } from './validateProductScopes';
import { MISSING_DATA_ERROR } from '../config/constants';

export const validateProcessingSteps = (
  props: WidgetProcessingStepsGeneralConfig['props'],
  processingStepsCertificate: SaveProcessingStepsParams['processingStepsCertificate'],
  productScopesCertificate: SaveProductScopesParams['productScopesAudit'] = []
): Record<string, TranslatedText> => {
  const { id: widgetId, scopes } = props;
  if (scopes.some((scope) => scope.type === 'productScope')) {
    const topLevelProductScopes = productScopesCertificate.filter(
      (scope) => scope.type === 'productScope'
    );

    return topLevelProductScopes.reduce<Record<string, TranslatedText>>(
      (acc, { uuid }) => {
        // Validate if at least 1 processing step is selected for the scope
        const productScopeProcessingSteps = processingStepsCertificate.filter(
          ({ productScope }) => productScope === uuid
        );

        if (productScopeProcessingSteps.length === 0) {
          return {
            ...acc,
            [uuid]: MISSING_DATA_ERROR,
          };
        }

        return acc;
      },
      {}
    );
  }

  const scopeErrors = scopes.reduce<TranslatedText[]>((acc, curr) => {
    if (curr.type === 'productScope') {
      return acc;
    }

    const flattenedScopes = flattenScopes<
      ProcessingStepsWidgetData['scopes'][number]
    >(curr.children);

    const hasSubProcessingSteps = flattenedScopes.some(
      ({ type }) => type === 'subProcessingStep'
    );

    if (hasSubProcessingSteps) {
      const subProcessingStepsUuids = flattenedScopes.reduce<string[]>(
        (a, c) => {
          if (c.type === 'subProcessingStep') {
            a.push(c.uuid);
          }

          return a;
        },
        []
      );

      if (
        processingStepsCertificate.some((scopeAnswer) =>
          subProcessingStepsUuids.includes(scopeAnswer.uuid)
        )
      ) {
        return acc;
      }

      return [...acc, MISSING_DATA_ERROR];
    }

    const processingStepsUuids = flattenedScopes.map((scope) => scope.uuid, []);

    if (
      processingStepsCertificate.some((scopeAnswer) =>
        processingStepsUuids.includes(scopeAnswer.uuid)
      )
    ) {
      return acc;
    }

    return [...acc, MISSING_DATA_ERROR];
  }, []);

  /**
   * We only have to check if there is at least one valid
   * scope on the first level. If there is, the widget is valid
   */
  if (scopeErrors.length !== scopes.length) {
    return {};
  }

  // Filter out duplicate id errors
  return {
    [widgetId]: MISSING_DATA_ERROR,
  };
};
