import { UUID_V4 } from 'utils/regex/uuid';

import routes from '../../config/routes';

interface GetCertificateDraftCreatePathnameParams {
  supplierId: string;
  certificateId?: string;
}

export const getCertificateDraftCreatePathname = ({
  supplierId,
  certificateId,
}: GetCertificateDraftCreatePathnameParams): string => {
  const isAmendment = !!certificateId;
  let pathname = isAmendment
    ? routes.CERTIFICATE_DRAFT_UPDATE
    : routes.CERTIFICATE_DRAFT_CREATE;
  pathname = pathname.replace(`:supplierId(${UUID_V4})`, supplierId);

  if (isAmendment) {
    pathname = pathname.replace(`:certificateId(${UUID_V4})`, certificateId);
  }

  return pathname;
};
