import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { WHITE } from 'config/appColors';

interface StyledStatusIndicatorProps {
  $textColor?: string;
  $bgColor?: string;
}

const StyledStatusIndicator = styled('span')<StyledStatusIndicatorProps>`
  color: ${({ $textColor }) => $textColor || WHITE};
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.015rem;
  font-weight: 400;
  padding: 0 5px;
  border-radius: 5px;
  display: inline-block;
`;

interface StatusIndicatorProps {
  label: ReactNode;
  textColor?: string;
  bgColor?: string;
  className?: string;
}

export const StatusIndicator: FC<StatusIndicatorProps> = (props) => {
  const { label = '', textColor, bgColor, className } = props;

  return (
    <StyledStatusIndicator
      className={className}
      $textColor={textColor}
      $bgColor={bgColor}
    >
      {label}
    </StyledStatusIndicator>
  );
};
