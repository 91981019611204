import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { paginationWrapperSchema } from 'schemas';
import { apiSlice } from 'store/apiSlice';
import { validateAndLogErrors } from 'utils/schemaValidator';

import { AccessesParams, AccessesResponse } from './types';
import endpoints from '../../config/endpoints';
import { accessSchema } from '../../schemas/Access';

const accessesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAccesses: build.query<AccessesResponse, AccessesParams>({
      query: ({ entityUuid, resourceName, ...params }: AccessesParams) => ({
        url: endpoints.ACCESSES.LIST(entityUuid, resourceName),
        params,
      }),
      providesTags: (result, error, arg) =>
        arg.resourceName === 'certificates'
          ? ['CERTIFICATE_ACCESSES']
          : ['AUDIT_ACCESSES'],
      keepUnusedDataFor: 10,
      transformResponse: validateAndLogErrors(
        paginationWrapperSchema(accessSchema, 'accesses')
      ),
    }),
  }),
});

export const useGetAccessesQuery = (params: AccessesParams) => {
  const query = accessesApi.useGetAccessesQuery(params);
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    accesses: query.data?.accesses || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};
