import { useDispatch, useSelector } from 'react-redux';

import { SortStateType } from 'components/organisms/Table/types';
import { Nullable } from 'types';

import {
  resetFilterAndSortState,
  selectFilterableListsFilter,
  selectFilterableListsSortState,
  selectFilterErrors,
  setFilterState,
  setSortState,
} from '../state/filterableLists/slice';
import {
  CollectionName,
  CommonFilterableAuditsCertificatesFilters,
  FilterErrors,
  FilterStringValuePayload,
} from '../state/filterableLists/types';

interface UseFilterableAuditsCertificatesFilters {
  filter: CommonFilterableAuditsCertificatesFilters;
  sortState: Nullable<SortStateType>;
  filterErrors: FilterErrors;
  onSortClick: (newSortState: Nullable<SortStateType>) => void;
  handleFilterChange: (
    key: FilterStringValuePayload['key'],
    value: string
  ) => void;
  handleResetClick: () => void;
}

export const useFilterableAuditsCertificatesFilters = (
  collection: CollectionName
): UseFilterableAuditsCertificatesFilters => {
  const dispatch = useDispatch();

  const filter = useSelector(selectFilterableListsFilter(collection));
  const sortState = useSelector(selectFilterableListsSortState(collection));
  const filterErrors = useSelector(selectFilterErrors(collection));

  const onSortClick = (newSortState: Nullable<SortStateType>) => {
    dispatch(setSortState({ sortState: newSortState, collection }));
  };

  const handleFilterChange = (
    key: FilterStringValuePayload['key'],
    value: string
  ) => dispatch(setFilterState({ key, value, collection }));

  const handleResetClick = () => dispatch(resetFilterAndSortState(collection));

  return {
    filter,
    sortState,
    filterErrors,
    onSortClick,
    handleFilterChange,
    handleResetClick,
  };
};
