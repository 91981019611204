import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Logger } from 'components/atoms/Logger';
import { AttributeTable } from 'components/molecules/AttributeTable';
import { WarningBox } from 'domains/auditDraft/components/molecules/WarningBox';
import { DraftStatusEnum, TemplateCmpTypeEnum } from 'types';
import { checkIfEnumValueExists } from 'utils/checkIfEnumValueExists';
import { generateId } from 'utils/generateId';
import { getStatus } from 'utils/getStatus';
import { isAnyLoading } from 'utils/isAnyLoading';

import { useCertificateDraftDetailsQuery } from '../../../state/certificateDraftDetails/api';
import { useSubmitCertificateDraftMutation } from '../../../state/submitCertificateDraft/api';
import { setSubmitStatus } from '../../../state/submitCertificateDraft/slice';
import { FormConfirmationSubmitStep } from '../../../types';
import { TemplateCertificateDraftStep } from '../../templates/TemplateCertificateDraftStep';

const StyledContainer = styled('div')`
  margin-top: 30px;
`;

interface FormConfirmationSubmitProps extends FormConfirmationSubmitStep {
  certificateDraftId: string;
}

export const FormConfirmationSubmit: FC<FormConfirmationSubmitProps> = ({
  children,
  certificateDraftId,
  props,
}) => {
  const { backAction, nextAction } = props;
  const dispatch = useDispatch();
  const {
    certificateDraftDetails,
    status: certificateDraftDetailsStatus,
    error: certificateDraftError = [],
    refetch,
  } = useCertificateDraftDetailsQuery({ certificateDraftId });
  const {
    status: submitStatus,
    error: submitError = [],
    submitCertificateDraft,
  } = useSubmitCertificateDraftMutation();

  const isLoading = isAnyLoading(certificateDraftDetailsStatus);
  const isSubmitLoading = isAnyLoading(submitStatus);
  const finalError = [...certificateDraftError, ...submitError];
  const disableNext = isLoading;

  const handleNextClick = () => {
    submitCertificateDraft(certificateDraftId).then(() => {
      dispatch(setSubmitStatus(DraftStatusEnum.SUBMITTED));
      refetch();
    });
  };

  return (
    <TemplateCertificateDraftStep
      contentStatus={getStatus(isLoading)}
      onNextClick={handleNextClick}
      nextButtonStatus={getStatus(isSubmitLoading)}
      backAction={backAction}
      nextAction={nextAction}
      disableNext={disableNext}
      error={finalError}
      warningBox={
        <>
          {children.map((child, index) => {
            if (child.type === TemplateCmpTypeEnum.WARNING_BOX) {
              return (
                <WarningBox
                  key={generateId(child.type, index)}
                  config={child}
                  warningIds={certificateDraftDetails?.warnings}
                />
              );
            }
            return null;
          })}
        </>
      }
    >
      {children.map((step, index) => {
        if (step.type === TemplateCmpTypeEnum.ATTRIBUTE_TABLE) {
          return (
            <StyledContainer key={step.type}>
              <AttributeTable
                data={certificateDraftDetails}
                rows={step.children}
              />
            </StyledContainer>
          );
        }

        return checkIfEnumValueExists(TemplateCmpTypeEnum, step.type) ? null : (
          <Logger
            key={generateId(step.type, index)}
            message={`Unsupported child type - ${step.type}.`}
          />
        );
      })}
    </TemplateCertificateDraftStep>
  );
};
