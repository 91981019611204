import { Paper } from '@mui/material';
import { styled } from '@mui/system';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { StyledResult } from 'components/atoms/StyledResult';
import { TableCellWithIcon } from 'components/atoms/TableCellWithIcon';
import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { useFilterableAuditsCertificatesFilters } from 'domains/widgets/hooks/useFilterableAuditsCertificatesFilters';
import { formatDate, renderDateRange } from 'utils/dates';

import { useGetFilterableAuditListQuery } from '../../../state/filterableLists/api';
import { Audit } from '../../../state/filterableLists/types';
import { CustomLink } from '../../atoms/CustomLink';
import { AuditsCertificatesFilters } from '../../molecules/AuditsCertificatesFilters';

const StyledPaper = styled(Paper)`
  padding: 7px 2px 2px;
`;

const StyledTable = styled(Table)`
  table,
  .skeleton-container {
    min-width: 1220px;
  }
  .skeleton-container {
    padding: 0 10px;
  }
` as typeof Table;

export const FilterableAudits: FC = () => {
  const [t] = useTranslation('widgets');
  const { certificationBodyUuid } =
    useParams<{ certificationBodyUuid: string }>();

  const {
    filter,
    sortState,
    filterErrors,
    handleFilterChange,
    handleResetClick,
    onSortClick,
  } = useFilterableAuditsCertificatesFilters('audits');

  const { pagination, limit, offset, setTotalRowsAmount } = usePagination({
    initialRowsPerPage: 50,
  });

  const { getColumnConfig, getColumnConfigWithEllipsis } =
    useColumnConfig('10%');

  const skipGetFilterableAuditsRequest = filterErrors.searchError;

  const { total, audits, status } = useGetFilterableAuditListQuery(
    {
      limit,
      offset,
      filter,
      sort: sortState ? `${sortState.key}:${sortState.direction}` : '',
      certificationBodyUuid,
    },
    skipGetFilterableAuditsRequest
  );

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const columns: Column<Audit>[] = [
    {
      headerName: t('Audit ID (AUID)'),
      customCell: ({ auid, certificate }) => (
        <TableCellWithIcon
          label={auid}
          iconName="lock"
          showLockIcon={!!certificate?.activeLock}
        />
      ),
      ...getColumnConfigWithEllipsis('14%'),
    },
    {
      headerName: t('Customer'),
      customCell: ({ supplier }) => (
        <CustomLink redirectPayload={supplier.uuid}>
          {`(COID: ${supplier.coid}) ${supplier.name}`}
        </CustomLink>
      ),
      title: ({ supplier }) => `(COID: ${supplier.coid}) ${supplier.name}`,
      ...getColumnConfigWithEllipsis('14%'),
    },
    {
      headerName: t('Standards/Programs'),
      customCell: ({ module }) => module.name,
      title: ({ module }) => module.name,
      onSortClick,
      sortKey: 'module',
      ...getColumnConfigWithEllipsis('13%'),
    },
    {
      headerName: t('Dates'),
      customCell: ({ auditTimeframe }) =>
        renderDateRange(auditTimeframe?.startDate, auditTimeframe?.endDate) ||
        '-',
      onSortClick,
      sortKey: 'auditTimeframe.startDate',
      ...getColumnConfig('15%'),
    },
    {
      headerName: t('uploaded at'),
      customCell: ({ uploadedAt }) => formatDate(uploadedAt),
      ...getColumnConfig(),
    },
    {
      headerName: t('Result'),
      customCell: ({ result: { overall } }) => (
        <StyledResult $color={overall.color}>{overall.text}</StyledResult>
      ),
      ...getColumnConfig('15%'),
    },
    {
      headerName: t('Type', { ns: 'components' }),
      customCell: ({ type }) => type.name,
      onSortClick,
      sortKey: 'type',
      ...getColumnConfig(),
    },
    {
      headerName: t('Certificate Valid until'),
      customCell: ({ certificate }) =>
        formatDate(certificate?.validity?.end) || '-',
      ...getColumnConfig('14%'),
    },
  ];

  return (
    <>
      <AuditsCertificatesFilters
        filter={filter}
        onFilterChange={handleFilterChange}
        errors={filterErrors}
        onResetClick={handleResetClick}
      />
      <StyledPaper>
        <StyledTable
          columns={columns}
          rows={audits}
          status={status}
          pagination={pagination}
          sortState={sortState}
          dense
        />
      </StyledPaper>
    </>
  );
};
