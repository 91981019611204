import { styled } from '@mui/material';

import { WHITE } from 'config/appColors';

const StyledFiltersContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 35px;

  .date-pickers {
    display: flex;

    svg {
      display: none;
    }

    .MuiInputBase-formControl {
      height: 40px;
      background: ${WHITE};
      font-size: 1.6rem;

      &.MuiInputBase-root {
        padding-right: 0;

        &.MuiInputAdornment-root {
          display: none;
        }
      }
    }

    .MuiTextField-root:nth-of-type(1) .MuiInputBase-formControl {
      border-radius: 4px 0 0 4px;
    }

    .MuiTextField-root:nth-of-type(2) .MuiInputBase-formControl {
      border-radius: 0 4px 4px 0;
    }

    .MuiSelect-outlined {
      height: 40px;
    }

    input {
      height: 30px;
      padding: 12px;
    }
  }
`;

StyledFiltersContainer.displayName = 'StyledFiltersContainer';

export { StyledFiltersContainer };
