import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { LoaderStatusEnum, ModalProps } from 'types';

import {
  selectUpdateDescriptionActionComment,
  selectUpdateDescriptionDescription,
  selectUpdateDescriptionLoading,
  updateDescription,
} from '../../../state/updateDescription/slice';
import { UpdateDescriptionParams } from '../../../state/updateDescription/types';
import { AuditAppointmentBaseModal } from '../AuditAppointmentBaseModal';
import { UpdateDescriptionModalContent } from '../UpdateDescriptionModalContent';

interface UpdateDescriptionModalProps extends ModalProps {
  appointmentId: string;
}

export const UpdateDescriptionModal: FC<UpdateDescriptionModalProps> = (
  props
) => {
  const { open, handleClose, appointmentId } = props;
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectUserIsAdmin);
  const loadingStatus = useSelector(selectUpdateDescriptionLoading);
  const actionComment = useSelector(selectUpdateDescriptionActionComment);
  const description = useSelector(selectUpdateDescriptionDescription);
  const [isAdminInfoValid, setIsAdminInfoValid] = useState(false);
  const [isDescriptionTouched, setIsDescriptionTouched] = useState(false);

  const nextAction = () => {
    if (appointmentId) {
      const data: UpdateDescriptionParams = {
        description,
        appointmentId,
        actionComment:
          isAdminInfoValid && actionComment ? actionComment : undefined,
      };
      dispatch(updateDescription(data));
    }
  };

  return (
    <AuditAppointmentBaseModal open={open}>
      <TemplateAuditModal
        title={t('Update comment')}
        contentComponent={
          <UpdateDescriptionModalContent
            isAdmin={isAdmin}
            setIsAdminInfoValid={setIsAdminInfoValid}
            isDescriptionTouched={isDescriptionTouched}
            setIsDescriptionTouched={setIsDescriptionTouched}
          />
        }
        closeAction={handleClose}
        prevAction={handleClose}
        nextAction={nextAction}
        noPrevArrow
        noNextArrow
        prevLabel={t('Close', { ns: 'components' })}
        nextLabel={t('Save', { ns: 'components' })}
        prevColor="modalPrevButton"
        disableNext={(isAdmin && !isAdminInfoValid) || !isDescriptionTouched}
        nextButtonLoading={loadingStatus === LoaderStatusEnum.LOADING}
        hidePrevButton={loadingStatus === LoaderStatusEnum.LOADING}
      />
    </AuditAppointmentBaseModal>
  );
};
