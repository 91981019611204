import { styled } from '@mui/material';
import React, { FC } from 'react';

import { Skeleton } from 'components/atoms/Skeleton';

const StyledSkeleton = styled(Skeleton)`
  height: 42px;
  border-radius: 4px;
  margin: 10px 0 10px 0;
`;

const PaginationSkeleton = styled(StyledSkeleton)`
  width: 20%;
  margin-left: auto;
`;

interface TableSkeletonProps {
  showPaginationSkeleton?: boolean;
}

export const TableSkeleton: FC<TableSkeletonProps> = (props) => {
  const { showPaginationSkeleton } = props;

  return (
    <div className="skeleton-container">
      <StyledSkeleton />
      <StyledSkeleton />
      <StyledSkeleton />
      {showPaginationSkeleton && <PaginationSkeleton />}
    </div>
  );
};
