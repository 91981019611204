import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  selectCertificateDetailsModalFormValues,
  selectCertificateDetailsUpdateUuid,
} from 'domains/certificateDetails/state/modal/slice';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { removeSpacesFromString } from 'utils/removeSpacesFromString';
import { isActionCommentFieldValid } from 'utils/validation/isActionCommentFieldValid';

import { MODAL_FORM_FIELD_MIN_CHARS } from '../../../config/constants';
import { useCloseExceptionalCircumstanceMutation } from '../../../state/exceptionalCircumstance/api';
import { CertificateModalCommonProps } from '../../../types';
import {
  CertificateDetailsModalBase,
  NextAction,
} from '../../templates/CertificateDetailsModalBase';
import { CloseExceptionalCircumstancesForm } from '../CloseExceptionalCircumstancesForm';

export const CloseExceptionalCircumstancesModal: FC<CertificateModalCommonProps> =
  ({ certificateUuid }) => {
    const [t] = useTranslation('certificateDetails');

    const { isLoading, closeExceptionalCircumstance, error } =
      useCloseExceptionalCircumstanceMutation();
    const caseUuid = useSelector(selectCertificateDetailsUpdateUuid) || '';
    const { actionComment, reasonDescription } = useSelector(
      selectCertificateDetailsModalFormValues
    );
    const isAdmin = useSelector(selectUserIsAdmin);
    const isActionCommentValid = isAdmin
      ? isActionCommentFieldValid(
          actionComment.reason,
          actionComment.comment || ''
        )
      : true;
    const isReasonDescriptionValid =
      removeSpacesFromString(reasonDescription).length >=
      MODAL_FORM_FIELD_MIN_CHARS;
    const isFormValid = isActionCommentValid && isReasonDescriptionValid;

    const nextAction: NextAction = (openSnackbar, closeModal) => {
      closeExceptionalCircumstance({
        certificateUuid,
        caseUuid,
        data: { reasonDescription, ...(isAdmin ? { actionComment } : {}) },
      }).then(() => {
        openSnackbar(t('The exceptional circumstance is removed.'), 'success');
        closeModal();
      });
    };

    return (
      <CertificateDetailsModalBase
        title={t('Remove Exceptional Circumstance')}
        nextColor="primary"
        nextAction={nextAction}
        disableNext={!isFormValid}
        isLoading={isLoading}
      >
        <CloseExceptionalCircumstancesForm errors={error} />
      </CertificateDetailsModalBase>
    );
  };
