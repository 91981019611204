import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { AlertsState, LoaderStatusEnum, Nullable } from 'types';
import apiClient from 'utils/apiClient';
import { createErrorAlertState } from 'utils/createAlertsState';
import { formatToAlerts } from 'utils/formatToAlerts';

import {
  CancelAuditAppointmentParams,
  CancelAuditAppointmentState,
} from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';

const initialState: CancelAuditAppointmentState = {
  isModalOpen: false,
  loading: LoaderStatusEnum.IDLE,
  alertsState: null,
};

const reducerName = `${parentReducerName}/cancel`;

// thunks
export const cancelAuditAppointment = createAsyncThunk(
  `${reducerName}/cancelAuditAppointment`,
  async (params: CancelAuditAppointmentParams, { rejectWithValue }) => {
    try {
      const { appointmentId, actionComment } = params;
      const data = { actionComment };
      const response = await apiClient.put(
        endpoints.CANCEL(appointmentId),
        data
      );
      return response.data;
    } catch (err) {
      const errors = formatToAlerts(err);
      return rejectWithValue(errors);
    }
  }
);

// slice
export const cancelAuditAppointmentSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetCancelAuditAppointmentState: () => initialState,
    setCancelAuditAppointmentIsModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cancelAuditAppointment.pending, (state) => {
      state.loading = LoaderStatusEnum.LOADING;
      state.alertsState = null;
    });
    builder.addCase(cancelAuditAppointment.fulfilled, (state) => {
      state.loading = LoaderStatusEnum.SUCCESS;
      state.alertsState = null;
    });
    builder.addCase(cancelAuditAppointment.rejected, (state, action) => {
      state.loading = LoaderStatusEnum.ERROR;
      state.alertsState = createErrorAlertState(action.payload);
    });
  },
});

// actions
export const {
  resetCancelAuditAppointmentState,
  setCancelAuditAppointmentIsModalOpen,
} = cancelAuditAppointmentSlice.actions;

// selectors
export const selectCancelAuditAppointmentIsModalOpen = (
  state: RootState
): boolean => state[parentReducerName].cancel.isModalOpen;
export const selectCancelAuditAppointmentLoading = (
  state: RootState
): LoaderStatusEnum => state[parentReducerName].cancel.loading;
export const selectCancelAuditAppointmentAlertsState = (
  state: RootState
): Nullable<AlertsState> => state[parentReducerName].cancel.alertsState;

// reducer
export default cancelAuditAppointmentSlice.reducer;
