import React from 'react';

import { AdminTableCell } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

export const SupplierCityCell: AdminTableCell = ({ supplier }) => (
  <StyledPlainText>
    {supplier.zipCode} {supplier.city}
  </StyledPlainText>
);
