import { styled } from '@mui/material';
import React, { FC } from 'react';

import { ImageLoader } from 'components/atoms/ImageLoader';
import { Logger } from 'components/atoms/Logger';
import { AttributeTable } from 'components/molecules/AttributeTable';
import { useGetAuditDetailsQuery } from 'domains/auditDetails/state/auditDetails/api';
import { TemplateCmpTypeEnum } from 'types';
import { generateId } from 'utils/generateId';
import { getModuleLogoSrc } from 'utils/getModuleLogoSrc';

import { AuditDetailsTab } from '../../../types';
import { TabSectionHeading } from '../../atoms/TabSectionHeading';

type DetailsTabProps = AuditDetailsTab['props'] & {
  children: AuditDetailsTab['children'];
  auditUuid: string;
};

const StyledContent = styled('div')`
  position: relative;
`;

const StyledTabSectionHeading = styled(TabSectionHeading)`
  margin-bottom: 10px;
`;

const StyledImageWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
`;

export const DetailsTab: FC<DetailsTabProps> = (props: DetailsTabProps) => {
  const { headerText, children, auditUuid, logoResolver } = props;
  const { auditDetails } = useGetAuditDetailsQuery({ auditUuid });

  const attributeTableData = { audit: auditDetails };

  return (
    <>
      {auditDetails && (
        <StyledContent>
          <StyledTabSectionHeading text={headerText} />
          <StyledImageWrapper>
            <ImageLoader
              src={getModuleLogoSrc(auditDetails, logoResolver)}
              width="150px"
              height="100px"
            />
          </StyledImageWrapper>
          {children.map((child, index) => {
            if (child.type === TemplateCmpTypeEnum.ATTRIBUTE_TABLE) {
              return (
                <AttributeTable
                  key={child.type}
                  data={attributeTableData}
                  rows={child.children}
                />
              );
            }
            /**
             * we don't expect other types of children here;
             * however once we have additional types we need to handle them here
             */
            return (
              <Logger
                key={generateId(child.type, index)}
                message={`Unsupported child type - ${child.type}.`}
              />
            );
          })}
        </StyledContent>
      )}
    </>
  );
};
