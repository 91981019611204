import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';

import { Tabs } from 'components/organisms/Tabs';
import { TabItem } from 'components/organisms/Tabs/types';
import { MOSTLY_WHITE_GRAY } from 'config/appColors';

import { FilterableAudits } from '../FilterableAudits';
import { FilterableCertificates } from '../FilterableCertificates';

const GlobalStyle = createGlobalStyle`
  &&&.MuiTabs-root {
    background: transparent !important;
  }
`;

const StyledTabContentWrapper = styled('div')`
  min-height: 500px;
  padding: 40px 20px;
  background: ${MOSTLY_WHITE_GRAY};
`;

export const WidgetAuditsCertificates: FC = () => {
  const [t] = useTranslation('widgets');

  const tabs: TabItem[] = [
    {
      label: t('Audits/Assessments', { ns: 'supplier' }),
      content: (
        <StyledTabContentWrapper>
          <FilterableAudits />
        </StyledTabContentWrapper>
      ),
    },
    {
      label: t('Certificates'),
      content: (
        <StyledTabContentWrapper>
          <FilterableCertificates />
        </StyledTabContentWrapper>
      ),
    },
  ];

  return (
    <>
      <GlobalStyle />
      <Tabs items={tabs} routeParamName="tab" />
    </>
  );
};
