import React, {
  createRef,
  FC,
  ReactElement,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import Dropzone, {
  DropzoneRef,
  ErrorCode,
  FileRejection,
} from 'react-dropzone';
import { v4 as generateUUID } from 'uuid';

import { jsonValuesAreSame } from 'utils/jsonValuesAreSame';

export const DEFAULT_MAX_FILE_SIZE = 5242880; // 5MB

export interface FileUploadRendererProps {
  maxFileSize?: number; // bytes
  fileTypes?: string | string[];
  multipleFiles?: boolean;
  maxMultipleFiles?: number;
  render: (props: FileUploadUIComponentProps) => ReactNode;
  onFilesChange?: (files: ExtendedFile[]) => void;
  onErrors?: (errors: FileRejection[]) => void;
}

export interface FileUploadUIComponentProps {
  errors: FileRejection[];
  files: ExtendedFile[];
  removeFile: (fileId: string, ev: SyntheticEvent) => void;
  openFileBrowser: () => void;
  clearErrors: () => void;
  removeAllFiles: () => void;
  inputRef: RefObject<HTMLInputElement>;
  openFileBrowserWithoutDropzone: () => void;
}

export interface ExtendedFile {
  id: string;
  file: File;
}

export interface ErrorTranslation {
  [key: ErrorCode | string]: string;
}

export const defaultErrorTranslations: Record<string, string> = {
  [ErrorCode.FileInvalidType]:
    'The file has the wrong format. Allowed format(s): {{supportedFormats}}.',
  [ErrorCode.FileTooLarge]:
    'The file is too large. Maximum file size allowed is {{sizeInMB}} MB.',
  [ErrorCode.FileTooSmall]:
    'The file is too small. Minimum file size allowed is {{sizeInMB}} MB.',
  [ErrorCode.TooManyFiles]:
    'Too many files. Maximum of {{maxMultipleFiles}} files are allowed.',
};

export const FileUploadRenderer: FC<FileUploadRendererProps> = (
  props
): ReactElement => {
  const {
    maxFileSize = DEFAULT_MAX_FILE_SIZE,
    maxMultipleFiles = 20,
    fileTypes,
    multipleFiles = true,
    render,
    onFilesChange,
    onErrors,
  } = props;
  const [files, setFiles] = useState<ExtendedFile[]>([]);
  const [errors, setErrors] = useState<FileRejection[]>([]);
  const removeFile = (fileId: string, ev: SyntheticEvent) => {
    ev.stopPropagation();
    const updatedFileList = files.filter(({ id }) => fileId !== id);

    return setFiles(updatedFileList);
  };
  const clearErrors = () => setErrors([]);
  const removeAllFiles = () => setFiles([]);
  const onDrop = (droppedFiles: File[]) => {
    if (droppedFiles.length > 0) {
      const extendedFiles = droppedFiles.map((file) => ({
        id: generateUUID(),
        file,
      }));
      clearErrors();
      setFiles([...files, ...extendedFiles]);
    }
  };

  const dropzoneRef = createRef<DropzoneRef>();
  const openFileBrowser = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const prevFiles = useRef<ExtendedFile[]>([]);
  useEffect(() => {
    if (onFilesChange && !jsonValuesAreSame(prevFiles.current, files)) {
      onFilesChange(files);
      prevFiles.current = files;
    }
  }, [files, onFilesChange]);

  const prevErrors = useRef<FileRejection[]>([]);
  useEffect(() => {
    if (onErrors && !jsonValuesAreSame(prevErrors.current, errors)) {
      onErrors(errors);
      prevErrors.current = errors;
    }
  }, [errors, onErrors]);

  return (
    <Dropzone
      accept={fileTypes}
      maxSize={maxFileSize}
      onDrop={onDrop}
      onDropRejected={setErrors}
      multiple={multipleFiles}
      maxFiles={maxMultipleFiles}
      noClick
      noDrag
      noKeyboard
      ref={dropzoneRef}
    >
      {({ getRootProps, getInputProps, inputRef, open }) => (
        <div {...getRootProps}>
          <input {...getInputProps()} ref={inputRef} />
          {render({
            errors,
            files,
            removeFile,
            openFileBrowser,
            clearErrors,
            removeAllFiles,
            inputRef,
            openFileBrowserWithoutDropzone: open,
          })}
        </div>
      )}
    </Dropzone>
  );
};

export default FileUploadRenderer;
