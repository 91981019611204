import React from 'react';

import { AdminTableCell } from '../../../types';
import { AuditTag } from '../../atoms/AuditTag';

export const AuditTagsCell: AdminTableCell = (props) => {
  const { certificate, tags: auditTags } = props;
  const tags = certificate?.tags || auditTags || [];

  return (
    <>
      {tags.map((tag) => (
        <AuditTag key={tag} tag={tag} {...props} />
      ))}
    </>
  );
};
