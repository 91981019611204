import { styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ModalFooterLoader } from 'components/atoms/ModalFooterLoader';
import { ModalTitleWithDetails } from 'components/atoms/ModalTitleWithDetails';
import { Select } from 'components/atoms/Select';
import { AlertList } from 'components/molecules/AlertList';
import { Loader } from 'components/molecules/Loader';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { TEXT } from 'config/appColors';
import { CERTIFICATES_CONFIRMATIONS_TAB_SLUG } from 'config/constants';
import { FileUploadAbortModal } from 'domains/auditFileUpload/components/organisms/FileUploadAbortModal';
import { selectCertificationBodyValue } from 'domains/certificationBody/state/search/slice';
import {
  getSupplierDetails,
  selectSupplierDetails,
} from 'domains/supplier/state/details/slice';
import { getCertificateDraftFormPathname } from 'utils/getCertificateDraftFormPathname';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';
import { getSupplierPathname } from 'utils/getSupplierPathname';

import { useCertificateDraftAbortModal } from '../../../hooks/useCertificateDraftAbortModal';
import { useCreateCertificateDraftMutation } from '../../../state/createCertificateDraft/api';
import { RequestModeEnum } from '../../../state/createCertificateDraft/types';
import { useGetStandardOptionsListQuery } from '../../../state/standardOptionsList/api';
import { CertificateVariantEnum } from '../../../types';

const StyledContainer = styled('div')`
  width: 100%;
  padding: 50px 106px;
  font-weight: 400;
  letter-spacing: 0.015rem;
  color: ${TEXT.PRIMARY};
`;

const StyledSelect = styled(Select)`
  width: 70%;
`;

export const StandardSelection: FC = () => {
  const { push } = useHistory();
  const { supplierId } = useParams<{ supplierId: string }>();
  const dispatch = useDispatch();
  const [moduleId, setModuleId] = useState('');
  const [t] = useTranslation('certificateDraft');
  const selectedSupplier = useSelector(selectSupplierDetails);
  const customCertificationBody = useSelector(selectCertificationBodyValue);
  const titleDetails = getSupplierDetailsTitle(selectedSupplier);
  const {
    createCertificateDraft,
    isLoading,
    error: createCertificateDraftError = [],
  } = useCreateCertificateDraftMutation();

  const {
    standards = [],
    error: getStandardOptionsError = [],
    status,
  } = useGetStandardOptionsListQuery();
  const standardsOptions = standards.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const {
    openAbortModal,
    closeAbortModal,
    handleAbortAction,
    isAbortModalOpen,
  } = useCertificateDraftAbortModal(supplierId);

  useEffect(() => {
    if (!customCertificationBody) {
      push(
        getSupplierPathname(supplierId, CERTIFICATES_CONFIRMATIONS_TAB_SLUG),
        'CB_REQUIRED'
      );
    }
  }, [customCertificationBody, push, supplierId]);

  useEffect(() => {
    if (!selectedSupplier) {
      dispatch(getSupplierDetails({ supplierId }));
    }
  }, [selectedSupplier, supplierId, dispatch]);

  const handleContinueClick = () => {
    if (customCertificationBody) {
      createCertificateDraft({
        mode: RequestModeEnum.CREATE,
        certificateVariant: CertificateVariantEnum.STANDALONE_CERTIFICATE,
        moduleId,
        certificationBodyId: customCertificationBody.uuid,
        supplierId,
      }).then((certificateDraftId) => {
        push(
          getCertificateDraftFormPathname({ supplierId, certificateDraftId })
        );
      });
    }
  };

  const title = (
    <ModalTitleWithDetails
      title={t('Create Certificate/Letter of confirmation')}
      details={titleDetails}
    />
  );

  const error = [...createCertificateDraftError, ...getStandardOptionsError];

  const contentComponent = (
    <StyledContainer>
      {error.length > 0 && <AlertList alerts={error} />}
      <p>
        {t(
          'Please specify the needed data for the certificate/letter of confirmation.'
        )}
      </p>
      <p>{t('Standard/program', { ns: 'components' })}</p>
      <Loader status={status}>
        <StyledSelect
          placeholder={t('please choose')}
          options={standardsOptions}
          value={moduleId}
          onChange={({ target }) => setModuleId(target.value as string)}
        />
      </Loader>
    </StyledContainer>
  );

  const buttonLoader = isLoading ? <ModalFooterLoader /> : null;

  return (
    <>
      <FileUploadAbortModal
        abortAction={handleAbortAction}
        continueAction={closeAbortModal}
        open={isAbortModalOpen}
        title={title}
        continueButtonText={t('Continue', { ns: 'components' })}
      />
      <TemplateAuditModal
        title={title}
        contentComponent={contentComponent}
        closeAction={openAbortModal}
        nextAction={handleContinueClick}
        noNextArrow
        nextColor="info"
        nextLabel={t('Next', { ns: 'components' })}
        showCloseButton={!isLoading}
        nextButtonComponent={buttonLoader}
        disableNext={!moduleId}
      />
    </>
  );
};
