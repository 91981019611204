import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import React, { FC, ReactElement } from 'react';

import { Heading } from 'components/atoms/Heading';
import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { BLACK, ERROR, SUCCESS } from 'config/appColors';
import { LoaderStatusEnum } from 'types';

import { TemplateResponseStatusProps } from './types';

type TemplateResponseStatusVariantTwoProps = Pick<
  TemplateResponseStatusProps,
  'title' | 'type' | 'textList' | 'description' | 'children'
>;

const isError = (type: LoaderStatusEnum) => type === LoaderStatusEnum.ERROR;
const StyledModalContent = styled(DialogContent)<{ type: LoaderStatusEnum }>`
  width: 100%;
  height: 100%;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  align-items: ${({ type }) => (isError(type) ? 'flex-start' : 'center')};
  justify-content: ${({ type }) => (isError(type) ? 'flex-start' : 'center')};
  margin-top: ${({ type }) => (isError(type) ? '-40px' : '0')};
  text-align: ${({ type }) => (isError(type) ? 'left' : 'center')};
  letter-spacing: 0.05rem;
  color: ${BLACK};
`;
const StyledTitle = styled(Heading)`
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${BLACK};
  && {
    font-size: 2rem;
  }
`;
const StyledDescription = styled('p')`
  margin: 0;
  font-size: 1.6rem;
  font-weight: 400;
`;
const StyledSuccessIcon = styled(CheckIcon)`
  font-size: 2.6rem;
  margin-right: 10px;
  color: ${SUCCESS.MAIN};
`;
const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 2.6rem;
  margin-right: 10px;
  color: ${ERROR.MAIN};
`;
const StyledTextList = styled('ul')`
  margin-top: 0;
  font-weight: 400;
  line-height: 2.6rem;
  text-align: left;
`;

export const TemplateResponseStatusVariantTwo: FC<TemplateResponseStatusVariantTwoProps> =
  (props): ReactElement => {
    const {
      title,
      type = LoaderStatusEnum.LOADING,
      children,
      description,
      textList,
    } = props;
    const renderIcon = () => {
      switch (type) {
        case LoaderStatusEnum.SUCCESS: {
          return <StyledSuccessIcon />;
        }
        case LoaderStatusEnum.ERROR: {
          return <StyledErrorIcon />;
        }
        default: {
          return null;
        }
      }
    };

    return (
      <>
        {type === LoaderStatusEnum.IDLE && children}
        {type !== LoaderStatusEnum.IDLE && (
          <>
            <StyledModalContent type={type}>
              {title && (
                <StyledTitle variant="h3">
                  {renderIcon()}
                  {title}
                </StyledTitle>
              )}
              {description && (
                <StyledDescription>{description}</StyledDescription>
              )}
              {type === LoaderStatusEnum.LOADING && <LoaderIcon />}
              {textList && (
                <StyledTextList>
                  {textList.map((text) => (
                    <li key={text}>{text}</li>
                  ))}
                </StyledTextList>
              )}
            </StyledModalContent>
          </>
        )}
      </>
    );
  };

export default TemplateResponseStatusVariantTwo;
