import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { useGetAuditDetailsQuery } from 'domains/auditDetails/state/auditDetails/api';
import convertFileToBase64 from 'utils/convertFileToBase64';
import { FileTypeValidator } from 'utils/fileTypeValidator';
import { getAuditDraftFormPathname } from 'utils/getAuditDraftFormPathname';
import { isAnyLoading } from 'utils/isAnyLoading';

import {
  useCreateAuditDraftViaAXPXFileMutation,
  useCreateAuditDraftViaFileMutation,
} from '../../../state/regularUpload/api';
import { TemplateFileUploadForm } from '../../templates/TemplateFileUploadForm';

interface AuditAmendmentFileUploadFormProps {
  auditId: string;
  supplierId: string;
}

export const AuditAmendmentFileUploadForm: FC<AuditAmendmentFileUploadFormProps> =
  (props) => {
    const { auditId, supplierId } = props;
    const {
      createAuditDraftViaFile,
      error: createAuditDraftViaFileError,
      status: createAuditDraftStatus,
      resetCreateAuditDraftState,
    } = useCreateAuditDraftViaFileMutation(true);
    const {
      createAuditDraftViaAxpxFile,
      error: createAuditDraftViaAxpxFileError,
      status: createViaAxpxAuditDraftStatus,
      resetCreateAuditDraftState: resetCreateViaAxpxAuditDraftState,
    } = useCreateAuditDraftViaAXPXFileMutation(true);

    const { push, location } = useHistory();

    const { auditDetails, status: getAuditDetailsStatus } =
      useGetAuditDetailsQuery({ auditUuid: auditId });

    const isLoading = isAnyLoading(
      createAuditDraftStatus,
      getAuditDetailsStatus,
      createViaAxpxAuditDraftStatus
    );

    const error =
      createAuditDraftViaFileError || createAuditDraftViaAxpxFileError;

    const onFileChange = async (files: File[]) => {
      if (files.length) {
        const file = files[0];
        const convertedFile = await convertFileToBase64(file);
        const createMutation = FileTypeValidator.isIfs(file)
          ? createAuditDraftViaFile
          : createAuditDraftViaAxpxFile;
        const auditDraftId = await createMutation({
          auditFile: convertedFile,
          supplierId,
          targetAuditId: auditId,
          certificationBodyId: auditDetails?.certificationBody.uuid || '',
        });

        push(
          getAuditDraftFormPathname({
            supplierId,
            auditDraftId,
            isAmendment: true,
            targetAuditId: auditId,
          }),
          location.state
        );
      } else {
        resetCreateAuditDraftState();
        resetCreateViaAxpxAuditDraftState();
      }
    };

    return (
      <TemplateFileUploadForm
        alerts={error}
        isErrorAlert={!!error}
        onFileChange={onFileChange}
        hideRemoveButton={isLoading}
      />
    );
  };
