import { get, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { Logger } from 'components/atoms/Logger';
import { SaveProcessingStepsParams } from 'components/molecules/WidgetProcessingStepsGeneral/ProcessingStepsWidget';
import { WidgetProcessingStepsGeneral } from 'components/molecules/WidgetProcessingStepsGeneral/WidgetProcessingStepsGeneral';
import { SaveProductScopesParams } from 'components/molecules/WidgetProductScopesGeneral/ProductScopeWidget';
import { WidgetProductScopesGeneral } from 'components/molecules/WidgetProductScopesGeneral/WidgetProductScopesGeneral';
import { SaveTechScopesParams } from 'components/molecules/WidgetTechScopesGeneral/TechScopeWidget';
import { WidgetTechScopesGeneral } from 'components/molecules/WidgetTechScopesGeneral/WidgetTechScopesGeneral';
import { validateProcessingSteps } from 'domains/certificateDraft/utils/validateProcessingSteps';
import {
  DraftStepCmp,
  Nullable,
  TemplateCmpTypeEnum,
  TranslatedText,
  WidgetProcessingStepsGeneralConfig,
  WidgetProductScopesGeneralConfig,
  WidgetTechScopesGeneralConfig,
  WithUndefined,
} from 'types';

import { useCertificateDraftStepper } from '../../../hooks/useCertificateDraftStepper';
import { useCertificateDraftAddDataMutation } from '../../../state/certificateDraftData/api';
import { useCertificateDraftDetailsQuery } from '../../../state/certificateDraftDetails/api';
import {
  CertificateDraftStepEnum,
  FormProcessingServicesStep,
  FormProductScopesStep,
  FormTechScopesStep,
} from '../../../types';
import { validateProductScopes } from '../../../utils/validateProductScopes';
import { validateTechScopes } from '../../../utils/validateTechScopes';
import { TemplateCertificateDraftStep } from '../../templates/TemplateCertificateDraftStep';

type Variants = {
  productScope: {
    type: TemplateCmpTypeEnum.WIDGET_PRODUCT_SCOPES_GENERAL;
    saveAction: (
      element: WidgetProductScopesGeneralConfig
    ) => (value: SaveProductScopesParams['productScopesAudit']) => void;
  };
  techScope: {
    type: TemplateCmpTypeEnum.WIDGET_TECH_SCOPES_GENERAL;
    saveAction: (
      element: WidgetTechScopesGeneralConfig
    ) => (value: SaveTechScopesParams['techScopesAudit']) => void;
  };
  processingServices: {
    type: TemplateCmpTypeEnum.WIDGET_PROCESSING_SERVICES_GENERAL;
    saveAction: (
      value: SaveProcessingStepsParams['processingStepsAudit'],
      element: WidgetProcessingStepsGeneralConfig
    ) => void;
  };
};

type Config =
  | WidgetProductScopesGeneralConfig
  | WidgetTechScopesGeneralConfig
  | WidgetProcessingStepsGeneralConfig;

type FormScopesProps = (
  | FormProductScopesStep
  | FormTechScopesStep
  | FormProcessingServicesStep
) & {
  certificateDraftId: string;
  variant: keyof Variants;
};

export const FormScopes: FC<FormScopesProps> = ({
  props,
  children,
  certificateDraftId,
  variant,
}) => {
  const { certificateDraftTemplate } = useCertificateDraftStepper();
  const { backAction, nextAction } = props;

  const [data, setData] = useState<
    | SaveTechScopesParams['techScopesAudit']
    | SaveProductScopesParams['productScopesAudit']
    | SaveProcessingStepsParams['processingStepsAudit']
  >([]);
  const [dependentData, setDependentData] =
    useState<WithUndefined<SaveProductScopesParams['processingStepsAudit']>>();
  const [errors, setErrors] = useState<Record<string, TranslatedText>>({});

  const variants: Variants = {
    productScope: {
      type: TemplateCmpTypeEnum.WIDGET_PRODUCT_SCOPES_GENERAL,
      saveAction:
        (element: WidgetProductScopesGeneralConfig) =>
        (value: SaveProductScopesParams['productScopesAudit']) => {
          setData(value);
          setErrors(validateProductScopes(element, value));
        },
    },
    techScope: {
      type: TemplateCmpTypeEnum.WIDGET_TECH_SCOPES_GENERAL,
      saveAction:
        (element: WidgetTechScopesGeneralConfig) =>
        (value: SaveTechScopesParams['techScopesAudit']) => {
          setData(value);
          setErrors(validateTechScopes(element.props.id, value));
        },
    },
    processingServices: {
      type: TemplateCmpTypeEnum.WIDGET_PROCESSING_SERVICES_GENERAL,
      saveAction: useCallback(
        (
          value: SaveProcessingStepsParams['processingStepsAudit'],
          element: WidgetProcessingStepsGeneralConfig
        ) => {
          setData(value);
          setErrors(validateProcessingSteps(element.props, value));
        },
        []
      ),
    },
  };

  const { saveAction, type } = variants[variant];

  const getWidgetTemplateByType = (
    templateChildType: TemplateCmpTypeEnum,
    childrenComponent?: Nullable<DraftStepCmp[]>
  ) =>
    (childrenComponent?.find(
      (c) => c.type === templateChildType
    ) as WithUndefined<Config>) || {
      props: { dataRef: '' },
    };

  const widgetTemplate = getWidgetTemplateByType(type, children);
  const processingScopesChildren =
    certificateDraftTemplate?.template.children.find(
      (child) => child.props.id === CertificateDraftStepEnum.PROCESSING_SERVICES
    )?.children;
  const processingStepsTemplate = getWidgetTemplateByType(
    TemplateCmpTypeEnum.WIDGET_PROCESSING_SERVICES_GENERAL,
    processingScopesChildren
  );

  const {
    isSuccess: isDraftSuccess,
    certificateDraftDetails,
    status: draftDetailsStatus,
  } = useCertificateDraftDetailsQuery({ certificateDraftId });

  const {
    addData,
    error: addDataError,
    status: addDataStatus,
  } = useCertificateDraftAddDataMutation();

  const isArrayOfScopes = (scopes: unknown) =>
    Array.isArray(scopes) &&
    scopes.every((scope) => 'uuid' in scope && 'type' in scope);

  useEffect(() => {
    if (isDraftSuccess && widgetTemplate.props.dataRef) {
      const newData = get(
        certificateDraftDetails,
        widgetTemplate.props.dataRef
      );
      if (isArrayOfScopes(newData)) {
        setData(newData);
      }
      const newDependentData = get(
        certificateDraftDetails,
        processingStepsTemplate.props.dataRef
      );

      if (
        type === TemplateCmpTypeEnum.WIDGET_PRODUCT_SCOPES_GENERAL &&
        isArrayOfScopes(newDependentData)
      ) {
        setDependentData(newDependentData);
      }
    }
  }, [
    certificateDraftDetails,
    isDraftSuccess,
    processingStepsTemplate.props.dataRef,
    type,
    widgetTemplate.props.dataRef,
  ]);

  const handleNextClick = (goNextStep: () => void) => {
    if (widgetTemplate.props.dataRef) {
      const payload = {
        [widgetTemplate.props.dataRef]: data,
      };

      /**
       * system should adjust processing step values (if exist) when product
       * scopes are selected/unselected again
       * if dependentData === undefined widget decided that no adjustments are needed
       */

      const initialDependentData = get(
        certificateDraftDetails,
        processingStepsTemplate.props.dataRef
      );

      if (
        type === TemplateCmpTypeEnum.WIDGET_PRODUCT_SCOPES_GENERAL &&
        dependentData &&
        !isEqual(dependentData, initialDependentData)
      ) {
        payload[processingStepsTemplate.props.dataRef] = dependentData;
      }

      addData({
        certificateDraftId,
        data: payload,
      }).then(() => {
        setData([]);
        setDependentData([]);
        setErrors({});
        goNextStep();
      });
    }
  };

  const disableNext = Object.values(errors).length > 0 || data.length === 0;

  return (
    <TemplateCertificateDraftStep
      contentStatus={draftDetailsStatus}
      nextButtonStatus={addDataStatus}
      backAction={backAction}
      nextAction={nextAction}
      onNextClick={handleNextClick}
      disableNext={disableNext}
      error={addDataError}
    >
      {children?.map((child) => {
        if (
          child.type === TemplateCmpTypeEnum.WIDGET_TECH_SCOPES_GENERAL &&
          variant === 'techScope'
        ) {
          return (
            <WidgetTechScopesGeneral
              key={child.type}
              data={data as SaveTechScopesParams['techScopesAudit']}
              errors={errors}
              element={child}
              setData={(saveAction as Variants['techScope']['saveAction'])(
                child
              )}
            />
          );
        }
        if (
          child.type === TemplateCmpTypeEnum.WIDGET_PRODUCT_SCOPES_GENERAL &&
          variant === 'productScope'
        ) {
          return (
            <WidgetProductScopesGeneral
              key={child.type}
              data={data as SaveProductScopesParams['productScopesAudit']}
              errors={errors}
              element={child}
              setData={(saveAction as Variants['productScope']['saveAction'])(
                child
              )}
              dependentData={dependentData}
              setDependentData={setDependentData}
            />
          );
        }
        if (
          child.type ===
            TemplateCmpTypeEnum.WIDGET_PROCESSING_SERVICES_GENERAL &&
          variant === 'processingServices'
        ) {
          return (
            <WidgetProcessingStepsGeneral
              key={child.type}
              data={data as SaveProcessingStepsParams['processingStepsAudit']}
              element={child}
              errors={errors}
              productScopesCertificate={
                certificateDraftDetails?.productScopesCertificate
              }
              setData={
                saveAction as Variants['processingServices']['saveAction']
              }
            />
          );
        }

        return (
          <Logger
            key={child.type}
            message={`System encountered unsupported type of child: ${child.type}`}
          />
        );
      })}
    </TemplateCertificateDraftStep>
  );
};
