import InfoIcon from '@mui/icons-material/InfoOutlined';
import { styled, Tooltip, TooltipProps } from '@mui/material';
import React, {
  FC,
  ReactElement,
  ReactNode,
  useLayoutEffect,
  useReducer,
  useRef,
} from 'react';

import WarningIcon from 'components/atoms/WarningIcon';
import {
  CRYSTAL_BELL,
  INFO as INFO_COLOR,
  TEXT,
  WARNING as WARNING_COLOR,
} from 'config/appColors';

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{
      popper: props.className,
      tooltip: 'hintTooltip',
    }}
    {...props}
  />
))`
  & .hintTooltip {
    width: ${({ $width }) => `${$width}px`};
    max-width: ${({ $width }) => `${$width}px`};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.4rem;
    color: ${TEXT.PRIMARY};
    box-shadow: 0 4px 4px 0 #00000040;
    background: ${CRYSTAL_BELL};
  }
`;

interface StyledTooltipTitleProps {
  $color: string;
}

const StyledTooltipTitle = styled('h3')<StyledTooltipTitleProps>`
  display: flex;
  margin: 10px 0;
  align-items: center;
  font-size: 1.4rem;
  color: ${(props) => props.$color};

  svg {
    font-size: 1.6rem;
    margin-right: 10px;
  }
`;

interface Padding {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

interface DescriptionTooltipProps {
  title: string;
  titleVariant?: TooltipTitleVariantEnum;
  tooltipContent: ReactNode;
  placement?: TooltipProps['placement'];
  tooltipPadding?: number | Partial<Padding>;
  children: ReactElement;
  width?: number;
  boundary?: TooltipBoundaryEnum;
}

export enum TooltipTitleVariantEnum {
  INFO = 'info',
  WARNING = 'warning',
}

export enum TooltipBoundaryEnum {
  TABLE_CELL = 'table-cell',
  BUTTON = 'button',
}

const titleVariants = {
  [TooltipTitleVariantEnum.INFO]: {
    color: INFO_COLOR.LIGHT,
    icon: <InfoIcon />,
  },
  [TooltipTitleVariantEnum.WARNING]: {
    color: WARNING_COLOR.MAIN,
    icon: <WarningIcon />,
  },
};

export const DescriptionTooltip: FC<DescriptionTooltipProps> = (props) => {
  const {
    title,
    titleVariant = TooltipTitleVariantEnum.INFO,
    tooltipContent,
    placement = 'top-start',
    tooltipPadding = { top: 45, left: 45 },
    children,
    width = 430,
    boundary = TooltipBoundaryEnum.TABLE_CELL,
  } = props;

  const { color, icon } = titleVariants[titleVariant];

  const boundaryRef = useRef<HTMLInputElement>(null);
  const tableDataCell = boundaryRef.current?.parentElement?.closest('td');
  const boundaryElement =
    boundary === TooltipBoundaryEnum.TABLE_CELL
      ? tableDataCell
      : boundaryRef.current;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useLayoutEffect(() => {
    forceUpdate();
  }, []);

  return (
    <div ref={boundaryRef}>
      <StyledTooltip
        $width={width}
        title={
          <div>
            <StyledTooltipTitle $color={color}>
              {icon}
              {title}
            </StyledTooltipTitle>
            {tooltipContent}
          </div>
        }
        placement={placement}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  padding: tooltipPadding,
                  boundary: boundaryElement,
                  altAxis: true,
                },
              },
            ],
          },
        }}
      >
        {children}
      </StyledTooltip>
    </div>
  );
};
