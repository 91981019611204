import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { AuditDraftTemplateResponse } from './types';
import { endpoints } from '../../config/endpoints';

const auditDraftTemplateApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditDraftTemplate: build.query<AuditDraftTemplateResponse, string>({
      query: (auditDraftId) => ({
        url: endpoints.TEMPLATE(auditDraftId),
      }),
      providesTags: ['AUDIT_DRAFT_TEMPLATE'],
      keepUnusedDataFor: 60,
    }),
  }),
});

export const useAuditDraftTemplateQuery = (
  auditDraftId: string,
  skip = false
) => {
  const query = auditDraftTemplateApi.useGetAuditDraftTemplateQuery(
    auditDraftId,
    {
      skip,
    }
  );

  const error = useFormattedError(query, 'formatToAlerts');
  const status = useRTKQueryStatus(query);

  return {
    ...query,
    auditDraftTemplate: query.data,
    status,
    error,
  };
};
