import React from 'react';

import { StyledLink } from 'components/atoms/StyledLink';

import { yiiUrls } from '../../../config/yiiUrls';
import { AdminTableCell } from '../../../types';

export const AuditPlannedCell: AdminTableCell = ({
  appointmentUuid,
  supplier,
}) => (
  <>
    {appointmentUuid ? (
      <StyledLink
        target="_blank"
        rel="noopener noreferrer"
        to={{
          pathname: yiiUrls.AUDIT_APPOINTMENT_DETAILS(
            supplier.uuid,
            appointmentUuid
          ),
        }}
      >
        Appointment <br /> ({appointmentUuid})
      </StyledLink>
    ) : (
      ''
    )}
  </>
);
