import { LockClock, Toc } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';

import { StyledLink } from 'components/atoms/StyledLink';
import { TEXT } from 'config/appColors';

import { useAllAuditsModal } from '../../../hooks/useAllAuditsModal';
import { AdminReportsModalEnum } from '../../../state/modal/types';
import { AdminTableCell } from '../../../types';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const StyledLinkButton = styled(StyledLink)`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 6px;
    font-size: 2.1rem;
    color: ${TEXT.SECONDARY_DARK};
  }
`;

export const AdditionalInformationCell: AdminTableCell = ({
  t,
  ...adminAudit
}) => {
  const { openModal } = useAllAuditsModal(adminAudit);

  return (
    <StyledContainer>
      {adminAudit?.certificate?.uuid && (
        <StyledLinkButton
          as="button"
          onClick={() => openModal(AdminReportsModalEnum.LOCK_HISTORY)}
        >
          <LockClock fontSize="large" /> {t('Lock History')}
        </StyledLinkButton>
      )}
      <StyledLinkButton
        as="button"
        onClick={() => openModal(AdminReportsModalEnum.AUDIT_LOGS)}
      >
        <Toc fontSize="large" /> {t('Audit Logs')}
      </StyledLinkButton>
    </StyledContainer>
  );
};
