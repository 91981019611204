import { styled } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { Textarea } from 'components/atoms/Textarea';
import { AlertList } from 'components/molecules/AlertList';
import { Column, Table, useColumnConfig } from 'components/organisms/Table';
import { useAuditParticipantsToDisplay } from 'hooks/useAuditParticipantsToDisplay';
import { AuditorParticipant } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { AuditParticipantRole } from '../../../../auditDetails/components/atoms/AuditParticipantRole';
import { useAddAuditDraftDataMutation } from '../../../state/auditDraftData/api';
import { useAuditDraftDetailsQuery } from '../../../state/auditDraftDetails/api';
import { FormAuditorsStep } from '../../../types';
import { TemplateAuditDraftStep } from '../../templates/TemplateAuditDraftStep';

type FormAuditorsProps = FormAuditorsStep['props'] & { auditDraftId: string };

const StyledStepContent = styled('div')`
  margin: 0 10px;
`;
const StyledStepDescription = styled(StepDescription)`
  margin-bottom: 20px;
`;
const StyledCommentDescription = styled(StepDescription)`
  margin: 25px 0 12px;
`;

export const FormAuditors: FC<FormAuditorsProps> = (props) => {
  const {
    auditDraftId,
    backAction,
    instructionText,
    nextAction,
    title,
    roleMapper,
  } = props;
  const [t] = useTranslation('auditDraft');
  const {
    auditDraftDetails,
    status: auditDraftDetailsStatus,
    error: auditDraftDetailsError,
  } = useAuditDraftDetailsQuery(auditDraftId);
  const formattedAuditDraftDetailsError = auditDraftDetailsError
    ? { message: auditDraftDetailsError.join(' ') }
    : null;
  const [isInitialDataSet, setIsInitialDataSet] = useState(false);
  const auditorParticipantsToDisplay = useAuditParticipantsToDisplay(
    auditDraftDetails?.auditors || [],
    roleMapper
  );
  const [comment, setComment] = useState('');
  const { addData, error, status } = useAddAuditDraftDataMutation();

  useEffect(() => {
    // set initial data for update
    if (!isInitialDataSet && auditDraftDetails) {
      const existingValue = auditDraftDetails?.otherAuditorsDescription;

      if (existingValue) {
        setComment(existingValue);
        setIsInitialDataSet(true);
      }
    }
  }, [isInitialDataSet, auditDraftDetails]);

  const handleNextClick = (goNextStep: () => void) => {
    addData({
      auditDraftId,
      otherAuditorsDescription: comment.trim(),
    }).then(goNextStep);
  };

  const handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setComment(event.target.value);

  const { getColumnConfig } = useColumnConfig('32%');
  const columns: Column<AuditorParticipant>[] = [
    {
      customCell: ({ userId }) => userId,
      headerName: t('id'),
      ...getColumnConfig('15%'),
    },
    {
      customCell: ({ firstName }) => firstName,
      headerName: t('First name'),
      ...getColumnConfig('20%'),
    },
    {
      customCell: ({ lastName }) => lastName,
      headerName: t('Last name'),
      ...getColumnConfig('20%'),
    },
    {
      customCell: ({ role }) => (
        <AuditParticipantRole
          roleMapper={roleMapper}
          role={role}
          hideDescription
        />
      ),
      headerName: t('Role'),
      ...getColumnConfig(),
    },
  ];

  return (
    <TemplateAuditDraftStep
      contentStatus={auditDraftDetailsStatus}
      nextButtonStatus={status}
      backAction={backAction}
      nextAction={nextAction}
      onNextClick={handleNextClick}
    >
      <StepFormTitle content={getTranslatedValue(title)} />
      <StyledStepContent>
        {error && <AlertList alerts={error} />}
        <StyledStepDescription content={getTranslatedValue(instructionText)} />
        <Table
          rows={auditorParticipantsToDisplay}
          columns={columns}
          status={auditDraftDetailsStatus}
          error={formattedAuditDraftDetailsError}
          propertyAsAKey="key"
          dense
        />
        <StyledCommentDescription
          content={t('Other auditor/assessor and comments')}
        />
        <Textarea
          fullWidth
          data-testid="other-auditors-textarea"
          multiline
          rows={4}
          value={comment}
          onChange={handleCommentChange}
        />
      </StyledStepContent>
    </TemplateAuditDraftStep>
  );
};
