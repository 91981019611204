import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'components/atoms/Button';
import { ErrorState } from 'components/molecules/ErrorState';
import { TemplatePageDefault } from 'components/templates/TemplatePageDefault';
import { ForbiddenRedirectPayload } from 'utils/handleForbiddenError';
import isIframe from 'utils/isIframe';

const StyledBackButton = styled(Button)`
  margin-top: -30px;
`;
const StyledArrowIcon = styled(ArrowBackIcon)`
  margin-right: 6px;
`;

interface PageErrorProps {
  errorType: 403 | 404;
  hideDefaultTemplate?: boolean;
  hideBackButton?: boolean;
}

export const PageError: FC<PageErrorProps> = ({
  errorType,
  hideBackButton = false,
  hideDefaultTemplate = false,
}) => {
  const [t] = useTranslation('components');
  const { state } = useLocation<ForbiddenRedirectPayload['state']>();
  const redirectPath = state?.redirectPath;
  const { push } = useHistory();

  const errorMessage =
    errorType === 403 ? t('Permission denied') : t('Not Found');

  /**
   * TODO: Once iframe is gone please remove condition with isIframe boolean
   * as a reference please see commit ed7a6176d5b00ca756f9a35c148141e80f5b1cd0
   */
  const errorContent = (
    <Box
      display="flex"
      position="relative"
      flexDirection="column"
      alignItems="center"
    >
      <ErrorState heading={errorMessage} description="" />
      {redirectPath && !hideBackButton && (
        <StyledBackButton
          type="button"
          variant="contained"
          color="info"
          onClick={() => push(redirectPath)}
        >
          <StyledArrowIcon fontSize="large" /> {t('Back')}
        </StyledBackButton>
      )}
    </Box>
  );

  const finalContent =
    isIframe || hideDefaultTemplate ? (
      errorContent
    ) : (
      <TemplatePageDefault hideBreadcrumbs>{errorContent}</TemplatePageDefault>
    );

  return finalContent;
};
