import { AuditStatusEnum } from 'types';

import { CertificateStatusEnum } from '../types';

export const parentReducerName = 'supplier';

export const deletedAuditStatuses = [AuditStatusEnum.DELETED];

export const activeCertificateStatuses = [
  CertificateStatusEnum.ACTIVE,
  CertificateStatusEnum.SUSPENDED,
  CertificateStatusEnum.WITHDRAWN,
];

export const inactiveCertificateStatuses = [
  CertificateStatusEnum.DEPRECATED,
  CertificateStatusEnum.EXPIRED,
];
