import { useTranslation } from 'react-i18next';

import { BannerVariantEnum } from 'components/molecules/Banner';
import { WithUndefined } from 'types';

interface BannerConfig {
  label: string;
  variant: BannerVariantEnum.INFO | BannerVariantEnum.WARNING;
}

interface ObjectWithId {
  id: string;
}

type UseCertificateTabBanner = WithUndefined<BannerConfig>;

/**
 * Custom logic behind banners
 * 1) there is no certificate object or id in this object - info banner
 * 2) request resolved with 404 - info banner
 * 3) request resolved with 403 - warning banner
 */

export const useCertificateTabBanner = (
  certificate?: ObjectWithId,
  httpErrorStatusCode?: number
): UseCertificateTabBanner => {
  const [t] = useTranslation('auditDetails');

  const bannerConfigs: Record<string, BannerConfig> = {
    '404': {
      label: t('In this audit there was no certificate issued'),
      variant: BannerVariantEnum.INFO,
    },
    '403': {
      label: t('You are not allowed to read this certificate'),
      variant: BannerVariantEnum.WARNING,
    },
  };

  if (!certificate || (!!certificate && !certificate.id)) {
    return bannerConfigs['404'];
  }

  return httpErrorStatusCode
    ? bannerConfigs[httpErrorStatusCode.toString()]
    : undefined;
};
