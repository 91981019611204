import { styled } from '@mui/material';
import React, { FC, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/atoms/Select';
import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import Loader from 'components/molecules/Loader';
import { Option } from 'types';

import { selectAuditPlanModule, setModule } from '../../../state/create/slice';
import {
  fetchModules,
  selectAlertsState,
  selectLoadingStatus,
  selectModuleFormOptions,
} from '../../../state/formOptions/slice';
import { setIsNextButtonDisabled } from '../../../state/stepperModal/slice';
import { StepAlertList } from '../../atoms/StepAlertList';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledStepDescription = styled(StepDescription)`
  margin: 15px 0 24px 10px;
`;

const StyledSelect = styled(Select)`
  margin-left: 10px;
  margin-right: 10px;
  height: 56px;
`;

export const FormSetModule: FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation('auditAppointment');

  const modules = useSelector(selectModuleFormOptions);
  const selectedModule = useSelector(selectAuditPlanModule);
  const loadingState = useSelector(selectLoadingStatus);
  const alertsState = useSelector(selectAlertsState);
  const showAlerts = alertsState && alertsState.alerts.length > 0;

  const options: Option[] = modules.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  useLayoutEffect(() => {
    if (!modules.length) dispatch(fetchModules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!selectedModule && modules.length === 1) {
      dispatch(setModule(modules[0]));
      dispatch(setIsNextButtonDisabled(false));
    }
  }, [modules.length, dispatch, selectedModule, modules]);

  const handleModuleChange = (moduleId: string) => {
    const newSelectedModule = modules.find((module) => module.id === moduleId);
    if (newSelectedModule) {
      dispatch(setModule(newSelectedModule));
      dispatch(setIsNextButtonDisabled(false));
    }
  };

  return (
    <Loader status={loadingState}>
      <StyledContainer>
        <StepFormTitle content={t('Standard/program', { ns: 'components' })} />

        {showAlerts && (
          <StepAlertList
            variant={alertsState.variant}
            alerts={alertsState.alerts}
          />
        )}

        <StyledStepDescription
          content={t('Please select a standard/program')}
        />

        <StyledSelect
          variant="outlined"
          placeholder={t('please select', { ns: 'components' })}
          options={options}
          value={selectedModule?.id ?? ''}
          onChange={(event) => {
            const moduleId = event.target.value as string;
            handleModuleChange(moduleId);
          }}
          data-testid="standard-dropdown"
        />
      </StyledContainer>
    </Loader>
  );
};
