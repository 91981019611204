import React, { FC, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from 'components/atoms/DatePicker';
import { LoaderStatusEnum } from 'types';

import { useDatePickerPropsUtils } from '../../../hooks/useDatePickerPropsUtils';
import { resetTimes, selectAuditPlanTime } from '../../../state/create/slice';
import {
  fetchUnannouncedPeriodDates,
  selectLoadingStatus,
} from '../../../state/formOptions/slice';
import { setIsNextButtonDisabled } from '../../../state/stepperModal/slice';
import { DatePickerContainer } from '../../atoms/DatePickerContainer';

export const SetUnannouncedRecertification: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const selectedTime = useSelector(selectAuditPlanTime);
  const loadingState = useSelector(selectLoadingStatus);
  const { getValue } = useDatePickerPropsUtils();

  useLayoutEffect(() => {
    if (selectedTime?.start && selectedTime?.end) return;

    dispatch(setIsNextButtonDisabled(true));
    dispatch(resetTimes());
    dispatch(fetchUnannouncedPeriodDates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DatePickerContainer label={t('Start Date')}>
        <DatePicker
          value={getValue(loadingState, selectedTime?.start)}
          inputPlaceholder={t('calculating ...')}
          loading={loadingState === LoaderStatusEnum.LOADING}
          disabled
        />
      </DatePickerContainer>
      <DatePickerContainer label={t('End Date')}>
        <DatePicker
          value={getValue(loadingState, selectedTime?.end)}
          inputPlaceholder={t('calculating ...')}
          loading={loadingState === LoaderStatusEnum.LOADING}
          disabled
        />
      </DatePickerContainer>
    </>
  );
};
