import { SelectChangeEvent, styled, Typography } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BaseModalContent } from 'components/organisms/BaseModalContent';
import { TEXT } from 'config/appColors';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';

import { actionReasons } from '../../../config/constants';
import { selectCancelAuditAppointmentAlertsState } from '../../../state/cancel/slice';

interface CancelAuditAppointmentModalContentProps {
  setIsAdminInfoValid: Dispatch<SetStateAction<boolean>>;
  adminReason: string;
  adminComment: string;
  adminReasonChangeHandler: (event: SelectChangeEvent<unknown>) => void;
  adminCommentChangeHandler: (comment: string) => void;
}

const StyledHeading = styled(Typography)`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${TEXT.PRIMARY};
  margin: 3px 0 35px;
`;

export const CancelAuditAppointmentModalContent: FC<CancelAuditAppointmentModalContentProps> =
  (props) => {
    const {
      setIsAdminInfoValid,
      adminReason,
      adminComment,
      adminReasonChangeHandler,
      adminCommentChangeHandler,
    } = props;
    const [t] = useTranslation('auditAppointment');
    const alertsState = useSelector(selectCancelAuditAppointmentAlertsState);
    const isAdmin = useSelector(selectUserIsAdmin);

    return (
      <BaseModalContent
        isAdmin // for cancel audit appointment, action information section is mandatory for all users
        adminReasonOptions={
          actionReasons.CANCEL_AUDIT_APPOINTMENT[isAdmin ? 'ADMIN' : 'CB_USER']
        }
        setIsAdminInfoValid={setIsAdminInfoValid}
        alertsState={alertsState}
        adminReason={adminReason}
        adminComment={adminComment}
        adminReasonChangeHandler={adminReasonChangeHandler}
        adminCommentChangeHandler={adminCommentChangeHandler}
      >
        <StyledHeading>
          {t('Do you really want to cancel the audit appointment?')}
        </StyledHeading>
      </BaseModalContent>
    );
  };
