import { certificateDetailsSchema } from 'domains/certificateDetails/schemas/CertificateDetails';
import { auditSchema } from 'domains/widgets/schemas/Audit';
import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { forceMockHeader } from 'mock/constants';
import { paginationWrapperSchema } from 'schemas';
import { apiSlice } from 'store/apiSlice';
import { validateAndLogErrors } from 'utils/schemaValidator';

import {
  FilterableAuditListResponse,
  FilterableCertificateListResponse,
  FilterableListParams,
} from './types';
import endpoints from '../../config/endpoints';

const filterableListsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFilterableAuditList: build.query<
      FilterableAuditListResponse,
      FilterableListParams
    >({
      query: ({ certificationBodyUuid, ...restParams }) => ({
        url: endpoints.FILTERABLE_AUDITS(certificationBodyUuid),
        params: restParams,
        headers: forceMockHeader,
      }),
      transformResponse: validateAndLogErrors(
        paginationWrapperSchema(auditSchema, 'audits')
      ),
      providesTags: ['FILTERABLE_AUDIT_LIST'],
      keepUnusedDataFor: 10,
    }),
    getFilterableCertificateList: build.query<
      FilterableCertificateListResponse,
      FilterableListParams
    >({
      query: ({ certificationBodyUuid, ...restParams }) => ({
        url: endpoints.FILTERABLE_CERTIFICATES(certificationBodyUuid),
        params: restParams,
        headers: forceMockHeader,
      }),
      transformResponse: validateAndLogErrors(
        paginationWrapperSchema(certificateDetailsSchema, 'certificates')
      ),
      providesTags: ['FILTERABLE_CERTIFICATE_LIST'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetFilterableAuditListQuery = (
  params: FilterableListParams,
  skip = false
) => {
  const query = filterableListsApi.useGetFilterableAuditListQuery(params, {
    skip,
  });
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  return {
    ...query,
    audits: query.data?.audits || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};

export const useGetFilterableCertificateListQuery = (
  params: FilterableListParams,
  skip = false
) => {
  const query = filterableListsApi.useGetFilterableCertificateListQuery(
    params,
    {
      skip,
    }
  );
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  return {
    ...query,
    certificates: query.data?.certificates || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};
