import React, { FC } from 'react';

import { CertificateLockEnum, InfoBox, InfoBoxTypeEnum } from 'types';
import { isReinstate } from 'utils/isReinstate';

import { useGetCertificateDetailsQuery } from '../../../state/certificateDetails/api';
import { useGetCertificateDetailsActions } from '../../../state/certificateDetailsActions/api';
import { CertificateDetailsInfoBox } from '../CertificateDetailsInfoBox';
import { InfoBoxBannerVariantEnum } from '../CertificateDetailsInfoBox/types';
import { ExceptionalCircumstanceBox } from '../ExceptionalCircumstanceBox';

interface CertificateDetailsBannerProps {
  config: InfoBox;
  certificateUuid: string;
}

export const CertificateDetailsBanner: FC<CertificateDetailsBannerProps> = (
  props
) => {
  const { SUSPENSION, WITHDRAWAL } = CertificateLockEnum;
  const {
    SUSPENSION_BOX,
    WITHDRAW_BOX,
    EXCEPTIONAL_CIRCUMSTANCES_BOX,
    REINSTATE_BOX,
  } = InfoBoxTypeEnum;
  const { certificateUuid, config } = props;
  const { type } = config;

  const { certificateDetails } = useGetCertificateDetailsQuery({
    certificateUuid,
    // certificateVersion: 2 // TODO: it's going to be handled in the future
  });

  const { certificateDetailsActions } =
    useGetCertificateDetailsActions(certificateUuid);

  if (!certificateDetails) {
    return null;
  }

  const { activeLock, latestLock, exceptionalCircumstances } =
    certificateDetails;

  const activeLockTypeId =
    activeLock && typeof activeLock === 'object' ? activeLock.type.id : '';

  if (
    (type === WITHDRAW_BOX && activeLockTypeId === WITHDRAWAL) ||
    (type === SUSPENSION_BOX && activeLockTypeId === SUSPENSION)
  ) {
    return (
      <CertificateDetailsInfoBox
        data={config}
        certificateDetails={certificateDetails}
        certificateDetailsActions={certificateDetailsActions}
        type={InfoBoxBannerVariantEnum.LOCK}
      />
    );
  }

  if (type === REINSTATE_BOX && isReinstate(latestLock)) {
    return (
      <CertificateDetailsInfoBox
        data={config}
        certificateDetails={certificateDetails}
        certificateDetailsActions={certificateDetailsActions}
        type={InfoBoxBannerVariantEnum.UNLOCK}
      />
    );
  }

  if (
    type === EXCEPTIONAL_CIRCUMSTANCES_BOX &&
    Array.isArray(exceptionalCircumstances)
  ) {
    return (
      <>
        {exceptionalCircumstances
          .filter(({ closeReason }) => closeReason === null)
          .map((exceptionalCircumstance) => (
            <ExceptionalCircumstanceBox
              key={exceptionalCircumstance.uuid}
              data={config}
              exceptionalCircumstance={exceptionalCircumstance}
            />
          ))}
      </>
    );
  }

  return null;
};
