import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { TranslatedText, WidgetProductScopesGeneralConfig } from 'types';

import type { SaveProductScopesParams } from './ProductScopeWidget';
import { ProductScopeWidget } from './ProductScopeWidget';

type WidgetProductScopesGeneralProps = {
  data: SaveProductScopesParams['productScopesAudit'];
  element: WidgetProductScopesGeneralConfig;
  errors: Record<string, TranslatedText>;
  setData: (data: SaveProductScopesParams['productScopesAudit']) => void;
  dependentData: SaveProductScopesParams['processingStepsAudit'];
  setDependentData: (
    data: SaveProductScopesParams['processingStepsAudit']
  ) => void;
};

export const WidgetProductScopesGeneral: FC<WidgetProductScopesGeneralProps> =
  ({ data, errors, element, setData, dependentData, setDependentData }) => {
    const { i18n } = useTranslation();
    const { name, scopes, id } = element.props;

    const saveScopes = ({
      productScopesAudit,
      processingStepsAudit,
    }: SaveProductScopesParams) => {
      setData(productScopesAudit);
      // if processingStepsAudit === undefined system doesn't require to update processing steps
      // example: user unchecks nested product scope checkbox
      if (processingStepsAudit) {
        setDependentData(processingStepsAudit);
      }
    };

    return (
      <ProductScopeWidget
        errors={errors}
        language={i18n.language}
        saveScopes={saveScopes}
        productScopesAudit={data}
        productScopesCertificate={data}
        processingStepsAudit={dependentData}
        processingStepsCertificate={dependentData}
        title={name}
        scopes={scopes}
        widgetId={id}
      />
    );
  };
