import { AxiosError } from 'axios';

import {
  DATA_INCOSISTENCY_ERROR,
  UNEXPECTED_ERROR,
  UNEXPECTED_ERROR_EXTENDED,
} from 'config/constants';
import { ExtendedError } from 'types';

const isDataInconsistencyError = (err: unknown) =>
  !!err &&
  typeof err === 'object' &&
  'message' in err &&
  err.message === DATA_INCOSISTENCY_ERROR;

export const formatToExtendedError = (errParam: unknown): ExtendedError => {
  if (isDataInconsistencyError(errParam)) {
    return {
      message: UNEXPECTED_ERROR,
      details: DATA_INCOSISTENCY_ERROR,
    };
  }

  const err = errParam as AxiosError;
  const errCode = err?.response?.status;
  let errText = err?.response?.statusText;

  if (!errText && Array.isArray(err?.response?.data)) {
    errText = err.response?.data.join(' ');
  }

  if (errCode || errText) {
    return {
      message: UNEXPECTED_ERROR_EXTENDED,
      details: `${errCode} - ${errText}`,
    };
  }

  return {
    message: UNEXPECTED_ERROR,
  };
};
