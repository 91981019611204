/**
 * System uses multiple formats of dates, extractDate extracts the "YYYY-MM-DD" part
 */

import { PLAIN_DATE_REGEX_WITH_HYPHENS } from 'utils/regex/date';

export const WARNING_MESSAGE =
  "Encountered unknown format of date! System couldn't find YYYY-MM-DD part in the following date:";

export const extractDate = (date: string): string => {
  const match = date.match(PLAIN_DATE_REGEX_WITH_HYPHENS);

  if (match) {
    return match[0];
  }
  const dateToDisplayInConsole =
    typeof date === 'string' ? date : JSON.stringify(date);

  console.warn(`${WARNING_MESSAGE} ${dateToDisplayInConsole}`);
  return '';
};
