import { Card, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ModalTitleWithDetails } from 'components/atoms/ModalTitleWithDetails';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { AlertList } from 'components/molecules/AlertList';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { ARGENT } from 'config/appColors';
import {
  DraftStatus,
  DraftStatusContent,
  Nullable,
  Supplier,
  WithUndefined,
} from 'types';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';

const StyledStepContent = styled('div')`
  margin: 0 10px;
  width: 100%;
`;

const StyledContainer = styled('div')`
  padding: 40px;
  width: 100%;
`;
const StyledCard = styled(Card)`
  max-width: 980px;
  margin: 0 auto;

  .MuiDialogActions-root {
    border-top: 1px ${ARGENT} solid;
    padding-top: 16px;
  }
`;

interface Details {
  status?: DraftStatus;
  supplier: Supplier;
}

interface DraftStatusIndicatorProps {
  details: WithUndefined<Details>;
  useStatusContent: (
    status: WithUndefined<DraftStatus>
  ) => Nullable<DraftStatusContent>;
  getPathname: (supplierId: string) => string;
}

export const DraftStatusIndicator: FC<DraftStatusIndicatorProps> = (props) => {
  const [t] = useTranslation('components');
  const { push, location } = useHistory();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { details, useStatusContent, getPathname } = props;
  const content = useStatusContent(details?.status);

  if (!content) {
    return null;
  }

  const { headerTitlePrefix, title: subtitle, label, status } = content;

  const title = (
    <ModalTitleWithDetails
      title={headerTitlePrefix}
      details={
        details?.supplier ? getSupplierDetailsTitle(details.supplier) : ''
      }
    />
  );

  const handleNextClick = () => {
    const pathName = getPathname(supplierId);

    push(pathName, location.state);
  };

  return (
    <StyledContainer>
      <StyledCard>
        <TemplateAuditModal
          title={title}
          contentComponent={
            <StyledStepContent>
              <StepFormTitle content={subtitle} />
              <AlertList
                alerts={Array.isArray(label) ? label : [label]}
                variant={status}
                noAlertsTranslation
              />
            </StyledStepContent>
          }
          nextAction={handleNextClick}
          nextColor="info"
          nextLabel={t('Close')}
          showCloseButton={false}
          noNextArrow
        />
      </StyledCard>
    </StyledContainer>
  );
};
