import { css, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { PRIMARY } from 'config/appColors';

/* styles that can be applied to any text/element that has to resemble a link */
export const linkStyles = css`
  color: ${PRIMARY.MAIN};
  text-decoration: underline;
  font-weight: 400;
  letter-spacing: 0.017rem;
`;

const StyledLink = styled(Link)`
  ${linkStyles}

  /* styles that are required in actual links */
  cursor: pointer;
`;

StyledLink.displayName = 'StyledLink';

export { StyledLink };
