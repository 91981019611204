import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Tabs from 'components/organisms/Tabs';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';

import {
  selectSupplierDetailsError,
  selectSupplierDetailsLoading,
} from '../../../state/details/slice';
import { CustomTitle } from '../../molecules/CustomTitle';
import { TemplateBasicPageContent } from '../../templates/TemplateBasicPageContent';
import { DeletedAuditsTab } from '../DeletedAuditsTab';
import { PublishedAuditsTab } from '../PublishedAuditsTab';

const StyledTemplateBasicPageContent = styled(TemplateBasicPageContent)`
  margin-top: -40px;
`;

export const AuditsTab: FC = () => {
  const [t] = useTranslation('supplier');
  const supplierDetailsLoading = useSelector(selectSupplierDetailsLoading);
  const supplierDetailsError = useSelector(selectSupplierDetailsError);
  const isAdmin = useSelector(selectUserIsAdmin);

  return (
    <StyledTemplateBasicPageContent
      customTitleComponent={<CustomTitle variant="auditDraft" />}
      loaderStatus={supplierDetailsLoading}
      minHeight="230px"
      error={{
        heading: t('Failed to load supplier details'),
        description: supplierDetailsError?.message,
        errorCode: supplierDetailsError?.details,
      }}
    >
      <Tabs
        items={[
          {
            label: t('List'),
            content: <PublishedAuditsTab />,
          },
          ...(isAdmin
            ? [
                {
                  label: t('Deleted Audits/Assessments'),
                  content: <DeletedAuditsTab />,
                },
              ]
            : []),
        ]}
      />
    </StyledTemplateBasicPageContent>
  );
};
