import { styled, TableBody, TableCell, TableRow } from '@mui/material';
import React, { FC } from 'react';

import { TableSkeleton } from 'components/molecules/TableSkeleton';

const StyledTableCell = styled(TableCell)`
  && {
    border: none;
  }
`;

export const AdminTableSkeleton: FC = () => (
  <TableBody>
    <TableRow>
      <StyledTableCell colSpan={100}>
        <TableSkeleton />
        <TableSkeleton />
        <TableSkeleton />
      </StyledTableCell>
    </TableRow>
  </TableBody>
);
