import i18next from 'i18n';

import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import it from './locales/it.json';

const ns = 'widgets';

i18next.addResources('en', ns, en);
i18next.addResources('de', ns, de);
i18next.addResources('fr', ns, fr);
i18next.addResources('es', ns, es);
i18next.addResources('it', ns, it);

export * from './components/pages/PageWidgetBase';
