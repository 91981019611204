import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { AuditDetailsActionsResponse } from './types';
import endpoints from '../../config/endpoints';

const auditDetailsActionsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditDetailsActions: build.query<AuditDetailsActionsResponse, string>({
      query: (auditUuid: string) => ({
        url: endpoints.ACTIONS(auditUuid),
      }),
      providesTags: ['AUDIT_DETAILS_ACTIONS'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetAuditDetailsActionsQuery = (auditUuid: string) => {
  const query =
    auditDetailsActionsApi.useGetAuditDetailsActionsQuery(auditUuid);
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    auditDetailsActions: query.data?.actions || [],
    error,
    status,
  };
};
