import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { endpoints } from '../../config/endpoints';

const certificateDraftSubmitApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    submitCertificateDraft: build.mutation<void, string>({
      query: (certificateDraftId) => ({
        url: endpoints.SUBMIT(certificateDraftId),
        method: 'PUT',
      }),
    }),
  }),
});

export const useSubmitCertificateDraftMutation = () => {
  const [submitCertificateDraft, mutation] =
    certificateDraftSubmitApi.useSubmitCertificateDraftMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (certificateId: string) =>
    submitCertificateDraft(certificateId).unwrap();

  return {
    ...mutation,
    submitCertificateDraft: requestMethod,
    status,
    error,
  };
};
