import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import {
  NextAction,
  TemplateModalWithSnackbar,
} from 'components/templates/TemplateModalWithSnackbar';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';

import { AUDIT_RELEASE_TYPE } from '../../../config/constants';
import { useReleaseCommonFormFields } from '../../../hooks/useReleaseCommonFormFields';
import {
  closeAndResetAuditModal,
  selectSelectedRelease,
} from '../../../state/modal/slice';
import { AuditModalCommonProps } from '../../../state/modal/types';
import { useUpdateAuditReleaseMutation } from '../../../state/releaseManage/api';
import { ReleaseCommonFormFields } from '../ReleaseCommonFormFields';

type UpdateReleaseModalProps = AuditModalCommonProps;

const StyledParagraph = styled('p')`
  :first-child {
    margin-top: 0;
  }
`;

export const UpdateReleaseModal: FC<UpdateReleaseModalProps> = (props) => {
  const { auditUuid, supplierId } = props;
  const { moduleFamily, releasedForCompany, uuid, referencedAudit } =
    useSelector(selectSelectedRelease) || {};
  const {
    contentOptions,
    contentCheckboxValues,
    content,
    handleContentChange,
    timeFrameOptions,
    timeFrameId,
    setTimeFrameId,
  } = useReleaseCommonFormFields(auditUuid, true);
  const [t] = useTranslation('auditDetails');
  const dispatch = useDispatch();

  const {
    updateRelease,
    isLoading,
    error = [],
  } = useUpdateAuditReleaseMutation();
  const closeMethod = () => {
    dispatch(closeAndResetAuditModal());
  };
  const nextAction: NextAction = (openSnackbar, closeModal) => {
    if (uuid && releasedForCompany && referencedAudit) {
      updateRelease({
        releaseUuid: uuid,
        type: AUDIT_RELEASE_TYPE,
        supplierId,
        releasedForCompanyId: releasedForCompany.uuid,
        timeFrameId,
        referencedAuditId: referencedAudit.uuid,
        content,
      }).then(() => {
        openSnackbar(t('Audit release saved.'), 'success');
        closeModal();
      });
    }
  };

  const alertsState = useMemoizedAlerts(error);
  const modalTitle = t('Edit audit/assessment release: {{moduleFamily}}', {
    moduleFamily: moduleFamily?.name || '',
  });
  const company = releasedForCompany?.name
    ? `${releasedForCompany.name}${
        releasedForCompany?.coid ? ` (${releasedForCompany.coid})` : ''
      }`
    : '';
  const disableNextButton = !timeFrameId || !content.length;

  return (
    <TemplateModalWithSnackbar
      title={modalTitle}
      nextAction={nextAction}
      nextLabel={t('Save', { ns: 'components' })}
      disableNext={disableNextButton}
      isLoading={isLoading}
      nextColor="primary"
      closeMethod={closeMethod}
    >
      <StyledBaseModalContent alertsState={alertsState}>
        {company && (
          <StyledParagraph>
            {t('Company')}: {company}
          </StyledParagraph>
        )}
        {referencedAudit?.auid && (
          <StyledParagraph>
            {t('Referenced Audit/Assessment')}: {referencedAudit?.auid}
          </StyledParagraph>
        )}
        <ReleaseCommonFormFields
          contentOptions={contentOptions}
          handleContentChange={handleContentChange}
          contentCheckboxValues={contentCheckboxValues}
          timeFrameOptions={timeFrameOptions}
          timeFrameId={timeFrameId}
          setTimeFrameId={setTimeFrameId}
        />
      </StyledBaseModalContent>
    </TemplateModalWithSnackbar>
  );
};
