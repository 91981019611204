import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { paginationWrapperSchema } from 'schemas';
import { apiSlice } from 'store/apiSlice';
import { validateAndLogErrors } from 'utils/schemaValidator';

import { ReleasesParams, ReleasesResponse } from './types';
import endpoints from '../../config/endpoints';
import { releaseSchema } from '../../schemas/Release';

const releasesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getReleases: build.query<ReleasesResponse, ReleasesParams>({
      query: ({ auditUuid, limit, offset }) => ({
        url: endpoints.RELEASES.LIST(auditUuid),
        params: { limit, offset },
      }),
      transformResponse: validateAndLogErrors(
        paginationWrapperSchema(releaseSchema, 'releases')
      ),
      providesTags: ['AUDIT_RELEASES'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetReleasesQuery = (params: ReleasesParams) => {
  const query = releasesApi.useGetReleasesQuery(params);
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  return {
    ...query,
    releases: query.data?.releases || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};
