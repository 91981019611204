import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Checkbox } from 'components/atoms/Checkbox';
import { Textarea } from 'components/atoms/Textarea';
import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import { selectUserDetails } from 'domains/user/state/auth/slice';
import { AlertVariantEnum } from 'types';

import { useGetCertificateDetailsQuery } from '../../../state/certificateDetails/api';
import { useRequestAuditAccessMutation } from '../../../state/requestAuditAccess/api';
import { RequestAuditAccessParams } from '../../../state/requestAuditAccess/types';
import { CertificateModalCommonProps } from '../../../types';
import {
  CertificateDetailsModalBase,
  NextAction,
} from '../../templates/CertificateDetailsModalBase';

type RequestAuditAccessModalProps = CertificateModalCommonProps;

export const RequestAuditAccessModal: FC<RequestAuditAccessModalProps> = ({
  certificateUuid,
}) => {
  const [t] = useTranslation('certificateDetails');
  const userDetails = useSelector(selectUserDetails);
  const { certificateDetails } = useGetCertificateDetailsQuery({
    certificateUuid,
  });
  const { isLoading, alerts, requestAuditAccess } =
    useRequestAuditAccessMutation();

  const [messageText, setMessageText] = useState('');

  /**
   * Currently every checkbox is disabled - for simplicity use
   * checkboxesConfig array for configuring available checkboxes.
   * Once they become enabled component has to has additional state
   * for storing currently checked options
   */
  const checkboxesConfig = [
    {
      value: 'audit.details',
      checked: true,
      disabled: true,
      label: t('Details (e.g. Score)'),
    },
    {
      value: 'audit.details_documents',
      checked: true,
      disabled: true,
      label: t('Documents (e.g. PDF Report)'),
    },
  ];

  const handleRequestClick: NextAction = (openSnackbar, closeModal) => {
    if (!userDetails?.actingUserId || !certificateDetails) return;

    const content = checkboxesConfig
      .filter(({ checked }) => checked)
      .map(({ value }) => value);

    const { module, issuedAt, validity, ceid, supplier } = certificateDetails;

    const data: RequestAuditAccessParams = {
      requesterId: userDetails.actingUserId,
      certificate: {
        coid: supplier.coid,
        ceid,
        issuedAt,
        validityEnd: validity.end,
        moduleId: module.id,
      },
      content,
      messageText,
    };

    requestAuditAccess(data).then(() => {
      openSnackbar(t('Audit access requested.'), 'success');
      closeModal();
    });
  };

  return (
    <CertificateDetailsModalBase
      title={t('Request access for COID {{coid}}', {
        coid: certificateDetails?.supplier.coid || '',
      })}
      nextColor="primary"
      prevLabel={t('Close', { ns: 'components' })}
      nextLabel={t('Request')}
      nextAction={handleRequestClick}
      isLoading={isLoading}
    >
      <StyledBaseModalContent
        alertsState={{
          alerts,
          variant: AlertVariantEnum.ERROR,
        }}
      >
        <p>{t('Request the following information')}</p>
        {checkboxesConfig.map((checkboxConfig) => (
          <Checkbox {...checkboxConfig} key={checkboxConfig.value} />
        ))}

        <p>{t('Message to the company')}</p>
        <Textarea
          onChange={({ target }) => setMessageText(target.value)}
          value={messageText}
          fullWidth
          multiline
          rows={5}
        />
      </StyledBaseModalContent>
    </CertificateDetailsModalBase>
  );
};
