import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Textarea } from 'components/atoms/Textarea';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';

import { useModalForm } from '../../../hooks/useModalForm';
import { useTranslatedSelectOptions } from '../../../hooks/useTranslatedSelectOptions';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { StyledLabel } from '../../atoms/StyledLabel';
import { CertificateDetailsModalContentBase } from '../../templates/CertificateDetailsModalContentBase';

interface CloseExceptionalCircumstancesFormProps {
  errors?: string[];
}

export const CloseExceptionalCircumstancesForm: FC<CloseExceptionalCircumstancesFormProps> =
  ({ errors = [] }) => {
    const [t] = useTranslation('certificateDetails');
    const { reasonDescription, setFormValue } = useModalForm();
    const actionReasonOptions = useTranslatedSelectOptions({
      type: CertificateDetailsModalEnum.CLOSE_EXCEPTIONAL_CIRCUMSTANCE,
      collectionName: 'actionReasons',
    });
    const alertsState = useMemoizedAlerts(errors);

    return (
      <CertificateDetailsModalContentBase
        actionReasonOptions={actionReasonOptions}
        alertsState={alertsState}
      >
        <StyledLabel htmlFor="reasonDescription">
          {t('Please describe why you removing the exceptional circumstances.')}
        </StyledLabel>
        <Textarea
          id="reasonDescription"
          value={reasonDescription}
          onChange={({ target }) =>
            setFormValue('reasonDescription', target.value)
          }
          rows={7}
          fullWidth
          multiline
        />
      </CertificateDetailsModalContentBase>
    );
  };
