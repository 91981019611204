import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/atoms/Button';
import Tabs from 'components/organisms/Tabs';
import { SPANISH_GRAY } from 'config/appColors';
import { CreateAuditAppointmentModal } from 'domains/auditAppointment/components/organisms/CreateAuditAppointmentModal';
import { selectLoadingStatus } from 'domains/auditAppointment/state/create/slice';
import { setIsModalOpen } from 'domains/auditAppointment/state/stepperModal/slice';
import { SelectCertificationBodyModal } from 'domains/certificationBody';
import { useCertificationBodyModal } from 'domains/certificationBody/hooks/useCertificationBodyModal';
import { TemplateBasicPageContent } from 'domains/supplier/components/templates/TemplateBasicPageContent';
import {
  selectSupplierDetails,
  selectSupplierDetailsError,
  selectSupplierDetailsLoading,
} from 'domains/supplier/state/details/slice';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { useUserPermission } from 'hooks/useUserPermission';
import { LoaderStatusEnum, UserPermissionEnum } from 'types';

import { AuditPlannerTabHistory } from '../AuditPlannerTabHistory';
import { AuditPlannerTabUpcomingAudits } from '../AuditPlannerTabUpcomingAudits';

const StyledTemplateBasicPageContent = styled(TemplateBasicPageContent)`
  padding: 20px;
`;

const StyledHeading = styled(Typography)`
  font-size: 2.4rem;
  line-height: 2rem;
  letter-spacing: 0.018rem;
  font-weight: 400;
  color: ${SPANISH_GRAY};
`;

const AuditPlannerTitle: FC = () => {
  const [t] = useTranslation('supplier');
  const dispatch = useDispatch();
  const openStepperModal = () =>
    setTimeout(() => dispatch(setIsModalOpen(true)), 100);
  const { isCbModalOpen, openCbModal, closeCbModal, handleCbModalNextAction } =
    useCertificationBodyModal(openStepperModal);
  const isAdmin = useSelector(selectUserIsAdmin);
  const supplierDetails = useSelector(selectSupplierDetails);
  const showCreateButton =
    useUserPermission(UserPermissionEnum.AUDIT_APPOINTMENT_CREATE) &&
    supplierDetails?.uuid;
  const openAuditPlanModal = () => {
    dispatch(setIsModalOpen(true));
  };
  const handleCreateAuditPlanClick = isAdmin ? openCbModal : openAuditPlanModal;

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <StyledHeading variant="h3">
            {t('Audit Assessment overview')}
          </StyledHeading>
        </Grid>
        {showCreateButton && (
          <Grid item>
            <Button
              onClick={handleCreateAuditPlanClick}
              variant="contained"
              color="info"
              startIcon={<AddIcon />}
            >
              {t('Create audit plan')}
            </Button>
          </Grid>
        )}
      </Grid>
      <SelectCertificationBodyModal
        open={isCbModalOpen}
        handleClose={closeCbModal}
        nextAction={handleCbModalNextAction}
      />
    </>
  );
};

export const AuditPlannerTab: FC = () => {
  const [t] = useTranslation('supplier');
  const notificationMessage = t('Appointment created');
  const { openSnackbar } = useSnackbarNotification();
  const auditAppointmentCreateLoading = useSelector(selectLoadingStatus);
  const supplierDetailsLoading = useSelector(selectSupplierDetailsLoading);
  const supplierDetailsError = useSelector(selectSupplierDetailsError);

  useEffect(() => {
    if (auditAppointmentCreateLoading === LoaderStatusEnum.SUCCESS) {
      openSnackbar(notificationMessage, 'success');
    }
  }, [auditAppointmentCreateLoading, openSnackbar, notificationMessage]);

  return (
    <StyledTemplateBasicPageContent
      customTitleComponent={<AuditPlannerTitle />}
      loaderStatus={supplierDetailsLoading}
      minHeight="230px"
      error={{
        heading: t('Failed to load supplier details'),
        description: supplierDetailsError?.message,
        errorCode: supplierDetailsError?.details,
      }}
    >
      <Tabs
        items={[
          {
            label: t('Upcoming Audits'),
            content: <AuditPlannerTabUpcomingAudits />,
          },
          {
            label: t('History'),
            content: <AuditPlannerTabHistory />,
          },
        ]}
      />
      <CreateAuditAppointmentModal />
    </StyledTemplateBasicPageContent>
  );
};

export default AuditPlannerTab;
