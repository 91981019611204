import { styled } from '@mui/material';
import React from 'react';

import { ExceptionalCircumstance } from 'types';
import { shortenText } from 'utils/shortenText';

import { AdminTableCell } from '../../../types';
import { DescriptionTooltip } from '../../atoms/DescriptionTooltip';
import { StyledPlainText } from '../../atoms/StyledPlainText';

const StyledDescriptionTitle = styled('span')`
  font-weight: 600;
`;
const StyledTooltipContent = styled('div')`
  margin: 0 0 5px;
  line-height: 2rem;
`;

export const ExceptionalCircumstancesCell: AdminTableCell = ({
  certificate,
  t,
}) => {
  const exceptionalCircumstances = certificate?.exceptionalCircumstances;

  if (!Array.isArray(exceptionalCircumstances)) {
    return null;
  }

  const getContent = (
    exceptionalCircumstance: ExceptionalCircumstance,
    shorten = false
  ) => {
    const { type, description } = exceptionalCircumstance;

    return (
      <>
        <StyledDescriptionTitle>
          {t('Type', { ns: 'components' })}:
        </StyledDescriptionTitle>{' '}
        {type.name}
        <br />
        {shorten ? shortenText(description) : description}
      </>
    );
  };

  return (
    <>
      {exceptionalCircumstances.map((exceptionalCircumstance) => (
        <DescriptionTooltip
          key={exceptionalCircumstance.uuid}
          title={t('Exceptional Circumstances')}
          tooltipContent={
            <StyledTooltipContent>
              {getContent(exceptionalCircumstance)}
            </StyledTooltipContent>
          }
        >
          <StyledPlainText>
            {getContent(exceptionalCircumstance, true)}
          </StyledPlainText>
        </DescriptionTooltip>
      ))}
    </>
  );
};
