import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import React, { FC, MouseEvent, ReactElement, ReactNode } from 'react';

import { TEXT } from 'config/appColors';

export interface RemovableFileLabelProps {
  name: string;
  showRemoveButton?: boolean;
  onFileRemove?: (ev: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  removeButtonUtilityProps?: Record<string, string>;
  children?: ReactNode;
}

export const StyledFile = styled('div')`
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  display: flex;
  align-items: center;
  color: ${TEXT.SECONDARY_DARK};
`;
const StyledRemoveButton = styled('button')`
  display: flex;
  margin-left: 9px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: 0;

  svg {
    font-size: 2.3rem;
    fill: ${TEXT.SECONDARY_DARK};
  }
`;

export const RemovableFileLabel: FC<RemovableFileLabelProps> = ({
  name,
  showRemoveButton = true,
  onFileRemove,
  className,
  removeButtonUtilityProps,
  children,
}): ReactElement => (
  <StyledFile className={className}>
    {name}
    {children}
    {showRemoveButton && (
      <StyledRemoveButton
        {...removeButtonUtilityProps}
        type="button"
        onClick={(ev) => onFileRemove?.(ev)}
      >
        <CloseIcon />
      </StyledRemoveButton>
    )}
  </StyledFile>
);

export default RemovableFileLabel;
