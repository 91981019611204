import { styled } from '@mui/material';
import React, { FC, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from 'components/atoms/DatePicker';
import { LoaderStatusEnum } from 'types';
import { formatDate } from 'utils/dates';

import { useDatePickerPropsUtils } from '../../../hooks/useDatePickerPropsUtils';
import {
  fetchEndRange,
  fetchStartRange,
  resetDueDateSelected,
  selectDueDateRange,
  selectDueDateSelected,
  setDueDateEnd,
  setDueDateStart,
} from '../../../state/dueDate/slice';
import { DatePickerRow } from '../../atoms/DatePickerRow';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const SetDateRange: FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation('auditAppointment');

  const { start, end } = useSelector(selectDueDateRange);
  const selectedTime = useSelector(selectDueDateSelected);
  const { getValue, getPlaceholder, getDateForRange, getDefaultCalendarMonth } =
    useDatePickerPropsUtils();

  useLayoutEffect(() => {
    if (selectedTime?.start && selectedTime?.end) return;

    dispatch(resetDueDateSelected());
    dispatch(fetchStartRange());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartDateChange = (startDate: Date) => {
    const formattedStartDate = formatDate(startDate, 'yyyy-MM-dd');
    dispatch(setDueDateStart(formattedStartDate));
    dispatch(fetchEndRange());
  };

  const handleEndDateChange = (endDate: Date) => {
    const formattedEndDate = formatDate(endDate, 'yyyy-MM-dd');
    dispatch(setDueDateEnd(formattedEndDate));
  };

  return (
    <StyledContainer>
      <DatePickerRow label={t('Start Date')}>
        <DatePicker
          onChange={handleStartDateChange}
          value={getValue(start.loading, selectedTime?.start)}
          inputPlaceholder={getPlaceholder(
            start.placeholder,
            selectedTime?.start
          )}
          loading={start.loading === LoaderStatusEnum.LOADING}
          disabled={start.loading !== LoaderStatusEnum.SUCCESS}
          minDate={getDateForRange(start, 'earliest')}
          maxDate={getDateForRange(start, 'latest')}
          defaultCalendarMonth={getDefaultCalendarMonth(start)}
          inputTestId="set-due-start-date"
        />
      </DatePickerRow>
      <DatePickerRow label={t('End Date')}>
        <DatePicker
          onChange={handleEndDateChange}
          value={getValue(end.loading, selectedTime?.end)}
          inputPlaceholder={getPlaceholder(end.placeholder, selectedTime?.end)}
          loading={end.loading === LoaderStatusEnum.LOADING}
          disabled={!end.range || end.loading !== LoaderStatusEnum.SUCCESS}
          minDate={getDateForRange(end, 'earliest')}
          maxDate={getDateForRange(end, 'latest')}
          defaultCalendarMonth={getDefaultCalendarMonth(end)}
          inputTestId="set-due-end-date"
        />
      </DatePickerRow>
    </StyledContainer>
  );
};
