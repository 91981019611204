import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AttributeRowBase } from 'components/atoms/AttributeRow/AttributeRowBase';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { setIsNextButtonDisabled } from 'domains/auditAppointment/state/stepperModal/slice';
import { selectCertificationBodyValue } from 'domains/certificationBody/state/search/slice';
import { selectSupplierDetails } from 'domains/supplier/state/details/slice';
import { selectUserCompanyName } from 'domains/user/state/auth/slice';
import { renderDateRange } from 'utils/dates';

import {
  resetAlertsState,
  selectAlertsState,
  selectAuditAppointmentFormValues,
} from '../../../state/create/slice';
import { StepAlertList } from '../../atoms/StepAlertList';

const StyledAttributeRowsContainer = styled('div')`
  margin-left: 10px;
  margin-top: 20px;
  max-width: 500px;
`;

const StyledCommentAttributeRow = styled(AttributeRowBase)`
  flex-direction: column;
`;

export const FormSummary: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const { module, type, kind, time, executionMode, description } = useSelector(
    selectAuditAppointmentFormValues
  );
  const currentContextCompanyName = useSelector(selectUserCompanyName);
  const customCertificationBody = useSelector(selectCertificationBodyValue);
  const finalCompanyName =
    customCertificationBody?.name || currentContextCompanyName;
  const alertsState = useSelector(selectAlertsState);
  const selectedSupplier = useSelector(selectSupplierDetails);
  const showAlerts = alertsState && alertsState.alerts.length > 0;

  const attributeRowItems = [
    {
      label: t('Supplier'),
      value: selectedSupplier?.name,
    },
    {
      label: t('Certification Body'),
      value: finalCompanyName,
    },
    {
      label: t('Standard/program', { ns: 'components' }),
      value: module?.name,
    },
    {
      label: t('Type'),
      value: type?.name,
    },
    {
      label: t('Kind'),
      value: kind?.name,
    },
    {
      label: t('Time'),
      value: renderDateRange(time?.start, time?.end),
    },
    {
      label: t('Execution Mode'),
      value: executionMode?.name,
    },
  ];

  useEffect(() => {
    dispatch(setIsNextButtonDisabled(false));
    dispatch(resetAlertsState());
  }, [dispatch]);

  return (
    <>
      <StepFormTitle content={t('Summary')} />
      {showAlerts && (
        <StepAlertList
          variant={alertsState.variant}
          alerts={alertsState.alerts}
        />
      )}
      <StyledAttributeRowsContainer>
        {attributeRowItems.map(({ label, value }) => (
          <AttributeRowBase key={value} label={label} value={value} />
        ))}
        <StyledCommentAttributeRow
          label={t('Comment')}
          value={
            description?.description || t('No description', { ns: 'common' })
          }
        />
      </StyledAttributeRowsContainer>
    </>
  );
};
