import { alpha, Paper, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ErrorState } from 'components/molecules/ErrorState';
import Loader from 'components/molecules/Loader';
import {
  PageHeader,
  useShowPageHeaderBackButton,
} from 'components/molecules/PageHeader';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { BLACK, TEXT, WHITE } from 'config/appColors';
import {
  AUDITS_ASSESSMENTS_TAB_SLUG,
  UNEXPECTED_ERROR_TRY_AGAIN,
} from 'config/constants';
import {
  getSupplierDetails,
  selectSupplierDetails,
} from 'domains/supplier/state/details/slice';
import { LoaderStatusEnum } from 'types';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';
import { getSupplierPathname } from 'utils/getSupplierPathname';
import { getTemplatePageHeader } from 'utils/getTemplatePageHeader';

import { ConditionalAuditModals } from '../../components/organisms/ConditionalAuditModals';
import { MappedAuditTabs } from '../../components/organisms/MappedAuditTabs';
import { useGetAuditDetailsQuery } from '../../state/auditDetails/api';
import { useGetAuditDetailsTemplateQuery } from '../../state/auditDetailsTemplate/api';

const StyledPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledPageHeaderWrapper = styled('div')`
  background: ${WHITE};
  border-bottom: 1px solid ${alpha(BLACK, 0.25)};
`;

const StyledPageHeader = styled(PageHeader)`
  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
    color: ${TEXT.PRIMARY};

    span {
      color: ${TEXT.SECONDARY_LIGHT};
    }
  }
`;

const StyledTabWrapper = styled(Paper)`
  width: 960px;
  margin: 40px auto 0 auto;
`;

export const PageAuditDetails: FC = () => {
  const [t] = useTranslation('auditDetails');
  const dispatch = useDispatch();
  const showBackButton = useShowPageHeaderBackButton();
  const { push } = useHistory();
  const { supplierId, auditUuid } =
    useParams<{ supplierId: string; auditUuid: string }>(); // @ToDo - unify type with AuditDetailsURLParams
  const selectedSupplier = useSelector(selectSupplierDetails);
  const supplierDetails = getSupplierDetailsTitle(selectedSupplier, true);

  useEffect(() => {
    if (!selectedSupplier) {
      dispatch(getSupplierDetails({ supplierId }));
    }
  }, [selectedSupplier, supplierId, dispatch]);

  const {
    isLoading: isAuditDetailsLoading,
    isError: isAuditDetailsError,
    isSuccess: isAuditDetailsSuccess,
  } = useGetAuditDetailsQuery({
    auditUuid,
  });

  const {
    auditDetailsTemplate,
    isError: isAuditDetailsTemplateError,
    isLoading: isAuditDetailsTemplateLoading,
  } = useGetAuditDetailsTemplateQuery(auditUuid, !isAuditDetailsSuccess);
  const pageTitle = getTemplatePageHeader(auditDetailsTemplate?.template);

  const handleBackClick = showBackButton
    ? () => {
        push(getSupplierPathname(supplierId, AUDITS_ASSESSMENTS_TAB_SLUG));
      }
    : undefined;

  const loaderStatus =
    isAuditDetailsLoading || isAuditDetailsTemplateLoading
      ? LoaderStatusEnum.LOADING
      : LoaderStatusEnum.SUCCESS;

  if (isAuditDetailsError || isAuditDetailsTemplateError) {
    return (
      <TemplatePageDefault hidePageHeader>
        <ErrorState
          heading={t('Failed to load Data.', { ns: 'components' })}
          description={t(UNEXPECTED_ERROR_TRY_AGAIN, { ns: 'common' })}
        />
      </TemplatePageDefault>
    );
  }

  return (
    <>
      <TemplatePageDefault hidePageHeader>
        <StyledPageWrapper>
          <StyledPageHeaderWrapper>
            <StyledPageHeader
              title={pageTitle}
              details={supplierDetails}
              onBackClick={handleBackClick}
              withBackButton
              hideBreadcrumbs
            />
          </StyledPageHeaderWrapper>
          <Loader status={loaderStatus} minHeight="200px">
            <StyledTabWrapper>
              <MappedAuditTabs />
            </StyledTabWrapper>
          </Loader>
        </StyledPageWrapper>
      </TemplatePageDefault>
      <ConditionalAuditModals />
    </>
  );
};
