export class FileTypeValidator {
  static IFS = /.ifs$/i;

  static AXPX = /.axpx$/i;

  static isFile(file: unknown): file is File {
    return file instanceof File;
  }

  static isAxpx(file: unknown): boolean {
    return this.isFile(file) && this.AXPX.test(file.name);
  }

  static isIfs(file: unknown): boolean {
    return this.isFile(file) && this.IFS.test(file.name);
  }
}
