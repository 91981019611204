import { SaveProductScopesParams } from 'components/molecules/WidgetProductScopesGeneral/ProductScopeWidget';
import { TranslatedText, WidgetProductScopesGeneralConfig } from 'types';

import { MISSING_DATA_ERROR } from '../config/constants';

type BasicScope<T extends BasicScope<T>> = {
  uuid: string;
  children?: T[];
  childrenRequired?: boolean;
};

export function flattenScopes<T extends BasicScope<T>>(scopeList: T[]): T[] {
  return scopeList.flatMap<T>((scope) => [scope, ...flattenScopes(scope.children || [])]);
}

export const validateProductScopes = (
  element: WidgetProductScopesGeneralConfig,
  value: SaveProductScopesParams['productScopesAudit']
): Record<string, TranslatedText> => {
  const { id: elementId, scopes } = element.props;

  if (!value.length) {
    return {
      [elementId]: MISSING_DATA_ERROR,
    };
  }

  const flattenedScopes = flattenScopes(scopes);

  return value.reduce<Record<string, TranslatedText>>((acc, curr) => {
    if (curr.type === 'subProductScopeOthers' && !curr.text) {
      return {
        ...acc,
        [curr.uuid]: MISSING_DATA_ERROR,
      };
    }

    if (value.some((scope) => 'uuid' in scope && scope.uuid === curr.uuid)) {
      // Here we're validating only the product's direct sub scopes, so we search through top level scopes
      const correspondingScopeElement = flattenedScopes.find(
        (s) => s.uuid === curr.uuid
      );

      // Scope has sub-scopes
      if (
        correspondingScopeElement?.childrenRequired &&
        correspondingScopeElement?.children?.length
      ) {
        const subScopes = correspondingScopeElement.children;

        // At least one sub-scope is selected
        if (
          value.some(
            (scope) =>
              'uuid' in scope &&
              subScopes.some((subScope) => subScope.uuid === scope.uuid)
          )
        ) {
          return acc;
        }

        // No sub-scope selected
        return {
          ...acc,
          [curr.uuid]: MISSING_DATA_ERROR,
        };
      }

      return acc;
    }

    return acc;
  }, {});
};
