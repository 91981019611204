import i18next from 'i18next';

import { DEFAULT_LANG } from 'config/languages';
import { LangHandler } from 'utils/langHandler';

import commonDe from './locales/common/de.json';
import commonEn from './locales/common/en.json';
import componentsDe from './locales/components/de.json';
import componentsEn from './locales/components/en.json';
import componentsEs from './locales/components/es.json';
import componentsFr from './locales/components/fr.json';
import componentsIt from './locales/components/it.json';

i18next.init({
  debug: Boolean(process.env.NODE_ENV === 'development'),
  interpolation: { escapeValue: false },
  lng: LangHandler.getLang(),
  fallbackLng: DEFAULT_LANG,
  ns: ['common', 'components'],
  defaultNS: 'common',
  nsSeparator: '~',
  keySeparator: '|',
  resources: {
    en: {
      common: commonEn,
      components: componentsEn,
    },
    de: {
      common: commonDe,
      components: componentsDe,
    },
    fr: {
      components: componentsFr,
    },
    es: {
      components: componentsEs,
    },
    it: {
      components: componentsIt,
    },
  },
});

export const t = (key: string, ns: string): string =>
  i18next.t(key, undefined, { ns });

export default i18next;
