import { styled } from '@mui/material';

import { TemplateModalWithSnackbar } from 'components/templates/TemplateModalWithSnackbar';

const StyledTemplateModalWithSnackbar = styled(TemplateModalWithSnackbar)`
  .MuiDialog-paper {
    overflow-y: visible;
  }

  .MuiDialogContent-root {
    min-height: 300px;
    overflow-y: visible;

    > div {
      padding: 0 125px;
      overflow: visible;
    }
  }

  .MuiPopper-root {
    z-index: 2;
  }
`;

StyledTemplateModalWithSnackbar.displayName = 'StyledTemplateModalWithSnackbar';

export { StyledTemplateModalWithSnackbar };
