import axios from 'axios';

import { getReactEnvVar } from '../getReactEnvVar';

export const apiClient = axios.create({
  baseURL: getReactEnvVar('API_URL'),
});

export const refreshTokenInstance = axios.create({
  baseURL: getReactEnvVar('API_URL'),
});

export const yiiApiClientInstance = axios.create({
  baseURL: getReactEnvVar('YII_API_URL'),
});

export const externalServiceInstance = axios.create();
