import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { paginationWrapperSchema } from 'schemas';
import { apiSlice } from 'store/apiSlice';
import { validateAndLogErrors } from 'utils/schemaValidator';

import { AuditListParams, AuditListResponse } from './types';
import endpoints from '../../config/endpoints';
import { auditSchema } from '../../schemas/Audit';
import { formatQueryParams } from '../../utils/formatQueryParams';

const initialState: AuditListResponse = {
  audits: [],
  total: 0,
};

const auditListApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditList: build.query<AuditListResponse, AuditListParams>({
      query: ({ supplierId = '', ...params }) => ({
        url: endpoints.AUDIT.LIST(supplierId),
        params: formatQueryParams(params),
      }),
      providesTags: ['AUDIT_LIST'],
      keepUnusedDataFor: 10,
      transformResponse: validateAndLogErrors(
        paginationWrapperSchema(auditSchema, 'audits')
      ),
    }),
  }),
});

export const useGetAuditListQuery = (params: AuditListParams) => {
  const query = auditListApi.useGetAuditListQuery(params);
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    auditList: query.data?.audits || initialState.audits,
    total: query.data?.total || initialState.total,
    error,
    status,
  };
};
