import { UUID_V4 } from 'utils/regex/uuid';

import routes from '../../config/routes';

interface GetCertificateDetailsPathnameParams {
  supplierId: string;
  certificateId: string;
}

export const getCertificateDetailsPathname = ({
  supplierId,
  certificateId,
}: GetCertificateDetailsPathnameParams): string =>
  routes.CERTIFICATE_DETAILS.replace(
    `:supplierId(${UUID_V4})`,
    supplierId
  ).replace(`:certificateUuid`, certificateId);
