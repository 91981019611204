import { get, isObject } from 'lodash';
import React, { FC } from 'react';

import { RawResult, TranslatedText } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

const isRawResult = (result: unknown): result is RawResult =>
  isObject(result) &&
  'overall' in result &&
  isObject(result.overall) &&
  'id' in result.overall;

export interface AttributeRowRawResultProps extends AttributeRowCommonProps {
  dataMapper?: Record<string, { id: string; name: TranslatedText }>;
  data: unknown;
}

export const AttributeRowRawResult: FC<AttributeRowRawResultProps> = (
  props
) => {
  const { labelText, data, dataRef, dataMapper } = props;
  const value = get(data, dataRef);
  const showAttributeRow = isRawResult(value);

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong RawResult data" />
    );
  }

  if (!dataMapper) {
    return (
      <Logger message="Something went wrong! The dataMapper object is empty - please check config file." />
    );
  }

  const mappedValue = dataMapper[value?.overall?.id];

  if (!mappedValue || !mappedValue?.name?.en) {
    return (
      <Logger
        message={`Something went wrong! No such key ${value?.overall?.id} in dataMapper - please check config file.`}
      />
    );
  }

  return (
    <AttributeRowBase
      label={labelText}
      value={getTranslatedValue(mappedValue.name)}
    />
  );
};
