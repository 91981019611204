import get from 'lodash/get';
import React, { FC } from 'react';

import { GeneralDetails } from 'types';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

// TODO: TBD are there situation when there is no field (optional??) what should happen
type Module = GeneralDetails;

const isModule = (module: unknown): module is Module =>
  !!module && 'id' in (module as Module) && 'name' in (module as Module);

interface AttributeRowModuleProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
  //   children: null; // TBD it might be used in the future
}

export const AttributeRowModule: FC<AttributeRowModuleProps> = (props) => {
  const { data, dataRef, labelText } = props;
  const value = get(data, dataRef);
  const showAttributeRow = isModule(value);

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Module data" />
    );
  }

  return <AttributeRowBase label={labelText} value={value.name} />;
};
