import { useSelector } from 'react-redux';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { selectSearchMinCharError } from './slice';
import { DiaryAuditRegistrationsParams } from './types';
import endpoints from '../../config/endpoints';
import { DiaryAuditResponse } from '../../types';

const diaryRegistrationsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getDiaryRegistrations: build.query<
      DiaryAuditResponse,
      DiaryAuditRegistrationsParams
    >({
      query: ({ certificationBodyUuid, ...params }) => ({
        url: endpoints.DIARY.AUDIT_REGISTRATIONS_LIST(certificationBodyUuid),
        params,
      }),
      providesTags: ['DIARY_REGISTRATIONS'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetDiaryRegistrationsQuery = (
  params: DiaryAuditRegistrationsParams
) => {
  const searchMinCharError = useSelector(selectSearchMinCharError);
  const query = diaryRegistrationsApi.useGetDiaryRegistrationsQuery(params, {
    skip: searchMinCharError,
  });
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  return {
    ...query,
    diaryRegistrations: query.data?.appointments || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};
