import { LANG_PARAM } from 'config/languages';

const endpoints = {
  FILTERABLE_AUDITS: (certificationBodyUuid: string): string =>
    `/${LANG_PARAM}/certification-bodies/${certificationBodyUuid}/audits`,
  FILTERABLE_CERTIFICATES: (certificationBodyUuid: string): string =>
    `/${LANG_PARAM}/certification-bodies/${certificationBodyUuid}/certificates`,
};

export default endpoints;
