import { CertificateLock, ExceptionalCircumstance, WithUndefined } from 'types';

import { CertificateDetails } from '../types';

interface UseCertificateInfoBoxData {
  activeLock?: CertificateLock;
  latestLock?: CertificateLock;
  exceptionalCircumstance?: ExceptionalCircumstance;
}

export const useCertificateInfoBoxData = (
  certificateDetails?: WithUndefined<CertificateDetails>
): UseCertificateInfoBoxData => {
  if (!certificateDetails) {
    return {};
  }

  const { activeLock, latestLock, exceptionalCircumstances } =
    certificateDetails;
  const exceptionalCircumstance = exceptionalCircumstances?.slice(-1)[0];

  return {
    activeLock: activeLock || undefined,
    latestLock: latestLock || undefined,
    exceptionalCircumstance,
  };
};
