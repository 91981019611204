import { Paper, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ProcessStatusIndicator } from 'components/molecules/ProcessStatusIndicator';
import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { SortStateType } from 'components/organisms/Table/types';
import {
  selectDiaryAuditRegistrationsFilter,
  selectDiaryAuditRegistrationsSortState,
  setPaginationState,
  setSortState,
} from 'domains/auditAppointment/state/diaryAuditRegistrations/slice';
import { DiaryAuditAppointment } from 'domains/auditAppointment/types';
import { useAuditAppointmentDetailsLink } from 'hooks/useAuditAppointmentDetailsLink';
import { Nullable } from 'types';
import { renderDateRange } from 'utils/dates';

import { useGetDiaryRegistrationsQuery } from '../../../state/diaryAuditRegistrations/api';
import { CustomerLink } from '../../atoms/CustomerLink';

const StyledTable = styled(Table)`
  table,
  .skeleton-container {
    min-width: 1220px;
  }

  .skeleton-container {
    padding: 0 10px;
  }
` as typeof Table;

export const DiaryAuditRegistrationsTable: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();

  const filter = useSelector(selectDiaryAuditRegistrationsFilter);
  const sortState = useSelector(selectDiaryAuditRegistrationsSortState);
  const { pagination, limit, offset, setTotalRowsAmount } = usePagination({
    initialRowsPerPage: 25,
  });
  const handleRowClick = useAuditAppointmentDetailsLink();
  const { getColumnConfig, getColumnConfigWithEllipsis } =
    useColumnConfig<DiaryAuditAppointment>('10%');

  const { certificationBodyUuid } =
    useParams<{ certificationBodyUuid: string }>();

  const {
    diaryRegistrations,
    status: requestStatus,
    error,
    total,
  } = useGetDiaryRegistrationsQuery({
    limit,
    offset,
    certificationBodyUuid,
    filter,
    ...(sortState && { sort: `${sortState.key}:${sortState.direction}` }),
  });

  useEffect(() => {
    dispatch(setPaginationState({ limit, offset }));
  }, [dispatch, limit, offset]);

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const onSortClick = (newSortState: Nullable<SortStateType>) => {
    dispatch(setSortState(newSortState));
  };

  const columns: Column<DiaryAuditAppointment>[] = [
    {
      customCell: ({ unannouncedPeriod }) =>
        renderDateRange(unannouncedPeriod?.start, unannouncedPeriod?.end),
      headerName: t('Unannounced Period'),
      sortKey: 'unannouncedPeriod.startDate',
      onSortClick,
      ...getColumnConfig('20%', {
        otherCellProps: {
          style: {
            whiteSpace: 'nowrap',
          },
        },
      }),
    },
    {
      customCell: CustomerLink,
      headerName: t('Customer'),
      ...getColumnConfigWithEllipsis('20%'),
    },
    {
      customCell: ProcessStatusIndicator,
      headerName: t('Status'),
      sortKey: 'status',
      ...getColumnConfig('10%'),
    },
    {
      customCell: ({ type }) => t(type, { ns: 'supplier' }),
      headerName: t('Type'),
      sortKey: 'type',
      onSortClick,
      ...getColumnConfig('15%'),
    },
    {
      customCell: ({ module }) => module.name,
      headerName: t('Standard/program', { ns: 'components' }),
      sortKey: 'module',
      onSortClick,
      ...getColumnConfigWithEllipsis('15%'),
    },
    {
      customCell: ({ dueDate }) =>
        renderDateRange(dueDate?.start, dueDate?.end),
      headerName: t('Due Date'),
      ...getColumnConfig('15%', {
        otherCellProps: {
          style: {
            whiteSpace: 'nowrap',
          },
        },
      }),
    },
  ];

  return (
    <Paper>
      <StyledTable
        rows={diaryRegistrations}
        columns={columns}
        onRowClick={handleRowClick}
        status={requestStatus}
        error={error}
        pagination={pagination}
        sortState={sortState}
      />
    </Paper>
  );
};
