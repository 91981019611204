import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { StyledBaseModalContent as BaseModalContent } from 'components/organisms/StyledBaseModalContent';
import {
  TemplateModalWithSnackbar,
  TemplateModalWithSnackbarProps,
} from 'components/templates/TemplateModalWithSnackbar';
import { PartialBy } from 'types';

import { closeAndResetAdminReportsModal } from '../../../state/modal/slice';

const StyledTemplateModal = styled(TemplateModalWithSnackbar)`
  .MuiDialog-paper {
    width: 1200px;
    max-width: 100%;
    height: 80vh;

    .MuiDialogContent-root {
      align-items: flex-start;
    }
  }

  /* In case we need more just than close button we need to do this in more dynamic way */
  .next-button {
    display: none;
  }
  .previous-button {
    margin-left: auto;
  }
`;

const StyledBaseModalContent = styled(BaseModalContent)`
  max-height: 100%;
`;

type TemplateProps = PartialBy<
  TemplateModalWithSnackbarProps,
  'isLoading' | 'closeMethod' | 'nextAction'
>;

export const TemplateModal: FC<TemplateProps> = (props) => {
  const dispatch = useDispatch();

  const {
    children,
    isLoading = false,
    closeMethod = () => dispatch(closeAndResetAdminReportsModal()),
    nextAction = () => ({}),
    ...restProps
  } = props;

  return (
    <StyledTemplateModal
      {...restProps}
      closeMethod={closeMethod}
      nextAction={nextAction}
      isLoading={isLoading}
      prevVariant="contained"
      prevColor="info"
    >
      <StyledBaseModalContent alertsState={null}>
        {children}
      </StyledBaseModalContent>
    </StyledTemplateModal>
  );
};
