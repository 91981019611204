import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useActionInformationData } from 'components/organisms/ActionInformationSection';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { LoaderStatusEnum, ModalProps } from 'types';

import {
  cancelAuditAppointment,
  selectCancelAuditAppointmentLoading,
} from '../../../state/cancel/slice';
import { CancelAuditAppointmentParams } from '../../../state/cancel/types';
import { AuditAppointmentBaseModal } from '../AuditAppointmentBaseModal';
import { CancelAuditAppointmentModalContent } from '../CancelAuditAppointmentModalContent';

interface CancelAuditAppointmentModalProps extends ModalProps {
  appointmentId: string;
}

export const CancelAuditAppointmentModal: FC<CancelAuditAppointmentModalProps> =
  (props) => {
    const { open, handleClose, appointmentId } = props;
    const [t] = useTranslation('auditAppointment');
    const dispatch = useDispatch();
    const loadingStatus = useSelector(selectCancelAuditAppointmentLoading);

    const {
      isAdminInfoValid,
      setIsAdminInfoValid,
      adminReason,
      adminComment,
      handleAdminReasonChange,
      handleAdminCommentChange,
    } = useActionInformationData();

    const nextAction = () => {
      if (appointmentId) {
        const data: CancelAuditAppointmentParams = {
          appointmentId,
          actionComment: isAdminInfoValid
            ? { reason: adminReason, comment: adminComment }
            : undefined,
        };
        dispatch(cancelAuditAppointment(data));
      }
    };

    return (
      <AuditAppointmentBaseModal open={open}>
        <TemplateAuditModal
          title={t('Cancel Audit Appointment')}
          contentComponent={
            <CancelAuditAppointmentModalContent
              setIsAdminInfoValid={setIsAdminInfoValid}
              adminReason={adminReason}
              adminComment={adminComment}
              adminReasonChangeHandler={handleAdminReasonChange}
              adminCommentChangeHandler={handleAdminCommentChange}
            />
          }
          closeAction={handleClose}
          prevAction={handleClose}
          nextAction={nextAction}
          noPrevArrow
          noNextArrow
          prevLabel={t('Close', { ns: 'components' })}
          nextLabel={t('Cancel', { ns: 'components' })}
          prevColor="modalPrevButton"
          nextColor="error"
          disableNext={!isAdminInfoValid}
          nextButtonLoading={loadingStatus === LoaderStatusEnum.LOADING}
          hidePrevButton={loadingStatus === LoaderStatusEnum.LOADING}
        />
      </AuditAppointmentBaseModal>
    );
  };
