import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { store } from 'store';
import { apiSlice } from 'store/apiSlice';
import { DraftStatusEnum, QueryOptions } from 'types';

import { endpoints } from '../../config/endpoints';
import { AuditDraftDetails } from '../../types';
import {
  selectAuditDraftSubmitStatus,
  setSubmitStatus,
} from '../submitAuditDraft/slice';

const auditDraftDetailsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditDraftDetails: build.query<AuditDraftDetails, string>({
      query: (auditDraftId) => ({
        url: endpoints.DETAILS(auditDraftId),
      }),
      providesTags: ['AUDIT_DRAFT_DETAILS'],
      keepUnusedDataFor: 0,
      transformResponse: (response: AuditDraftDetails) => {
        const certificateDraftLocalState = selectAuditDraftSubmitStatus(
          store.getState()
        );
        if (certificateDraftLocalState === DraftStatusEnum.SUBMITTED) {
          store.dispatch(setSubmitStatus(DraftStatusEnum.UNSUBMITTED));

          return {
            ...response,
            status: {
              id: DraftStatusEnum.SUBMITTED,
              name: 'Submitted',
            },
          };
        }
        return response;
      },
    }),
  }),
});

const defaultOptions: QueryOptions = {
  refetchOnMountOrArgChange: true,
  skip: false,
};

export const useAuditDraftDetailsQuery = (
  auditDraftId: string,
  options?: QueryOptions
) => {
  const query = auditDraftDetailsApi.useGetAuditDraftDetailsQuery(
    auditDraftId,
    {
      ...defaultOptions,
      ...options,
    }
  );

  const error = useFormattedError(query, 'formatToAlerts');
  const status = useRTKQueryStatus(query);

  return {
    ...query,
    auditDraftDetails: query.data,
    status,
    error,
  };
};
