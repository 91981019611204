import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { paginationWrapperSchema } from 'schemas';
import { apiSlice } from 'store/apiSlice';
import { validateAndLogErrors } from 'utils/schemaValidator';

import { CertificateListParams, CertificateListResponse } from './types';
import endpoints from '../../config/endpoints';
import { certificateSchema } from '../../schemas/Certificate';
import { formatQueryParams } from '../../utils/formatQueryParams';

const initialState: CertificateListResponse = {
  certificates: [],
  total: 0,
};

const certificateListApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateList: build.query<
      CertificateListResponse,
      CertificateListParams
    >({
      query: ({ supplierId = '', ...params }) => ({
        url: endpoints.CERTIFICATE.LIST(supplierId),
        params: formatQueryParams(params),
      }),
      providesTags: ['CERTIFICATE_LIST'],
      keepUnusedDataFor: 10,
      transformResponse: validateAndLogErrors(
        paginationWrapperSchema(certificateSchema, 'certificates')
      ),
    }),
  }),
});

export const useGetCertificateListQuery = (params: CertificateListParams) => {
  const query = certificateListApi.useGetCertificateListQuery(params);
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    certificateList: query.data?.certificates || initialState.certificates,
    total: query.data?.total || initialState.total,
    error,
    status,
  };
};
