import * as yup from 'yup';

import { AuditDetails } from 'domains/auditDetails/types';
import {
  generalDetailsSchema,
  messageSchema,
  nestedGeneralDetailsSchema,
  prefixedStartEndDatesSchema,
  relatedCertificateSchema,
  relatedCertificationBodySchema,
  relatedSupplierSchema,
  resultSchema,
} from 'schemas';

export const auditDetailsSchema: yup.ObjectSchema<AuditDetails> = yup.object({
  uuid: yup.string().required(),
  version: yup.number().required(),
  status: generalDetailsSchema.required(),
  certificationBody: relatedCertificationBodySchema.required(),
  supplier: relatedSupplierSchema.required(),
  module: generalDetailsSchema.required(),
  type: generalDetailsSchema.required(),
  kind: generalDetailsSchema.required(),
  executionMode: generalDetailsSchema.required(),
  auditTimeframe: prefixedStartEndDatesSchema.required(),
  appointmentUuid: yup.string().optional(),
  result: resultSchema.required(),
  certificate: relatedCertificateSchema.optional().default(undefined),
  messages: yup.array().of(messageSchema).optional().default(undefined),
  productScopesAudit: yup
    .array()
    .of(nestedGeneralDetailsSchema())
    .optional()
    .default(undefined),
});
