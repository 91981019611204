import { get } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DynamicFormItemLabel } from 'components/atoms/DynamicFormItemLabel';
import { WithUndefined } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { StyledSelect, StyledSubItemsContainer } from './StyledElements';
import {
  DynamicFormCommonProps,
  DynamicFormItemCommonProps,
  SingleSelectType,
} from './types';

type SingleSelectProps = SingleSelectType &
  DynamicFormCommonProps &
  DynamicFormItemCommonProps;

export const SingleSelect: FC<SingleSelectProps> = ({
  props,
  setValue,
  values,
  validateFormItem,
  validationState,
  subItems,
}) => {
  const { dataRef, label, options } = props;

  const value = get(values, dataRef) || '';
  const [t] = useTranslation('components');
  const optionsToDisplay = options.map((option) => ({
    value: option.value,
    label: getTranslatedValue(option.text),
  }));
  const error = get(validationState, dataRef) as WithUndefined<string>;

  return (
    <div>
      <DynamicFormItemLabel text={label} error={error} />
      <StyledSelect
        placeholder={t('please select')}
        value={value}
        options={optionsToDisplay}
        onChange={(ev) => {
          const id = ev.target.value;

          setValue(dataRef, id);
          validateFormItem(id, dataRef);
        }}
      />
      {!!subItems && (
        <StyledSubItemsContainer>{subItems}</StyledSubItemsContainer>
      )}
    </div>
  );
};
