import { SelectChangeEvent, styled, Typography } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BaseModalContent } from 'components/organisms/BaseModalContent';
import { TEXT } from 'config/appColors';

import { actionReasons } from '../../../config/constants';
import { selectDeleteBlackoutTimeAlertsState } from '../../../state/deleteBlackoutTime/slice';
import { BlackoutTime } from '../../../types';
import { DateRangeGrid } from '../../atoms/DateRangeGrid';

interface DeleteBlackoutTimeModalContentProps {
  blackoutTime?: BlackoutTime;
  isAdmin?: boolean;
  setIsAdminInfoValid: Dispatch<SetStateAction<boolean>>;
  adminReason: string;
  adminComment: string;
  adminReasonChangeHandler: (event: SelectChangeEvent<unknown>) => void;
  adminCommentChangeHandler: (comment: string) => void;
}

const StyledHeading = styled(Typography)`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${TEXT.PRIMARY};
  margin-bottom: 14px;
`;

export const DeleteBlackoutTimeModalContent: FC<DeleteBlackoutTimeModalContentProps> =
  (props) => {
    const {
      blackoutTime,
      isAdmin,
      setIsAdminInfoValid,
      adminReason,
      adminComment,
      adminReasonChangeHandler,
      adminCommentChangeHandler,
    } = props;
    const [t] = useTranslation('auditAppointment');
    const alertsState = useSelector(selectDeleteBlackoutTimeAlertsState);

    return (
      <BaseModalContent
        adminReasonOptions={actionReasons.DELETE_BLACKOUT_TIME.ADMIN}
        setIsAdminInfoValid={setIsAdminInfoValid}
        alertsState={alertsState}
        adminReason={adminReason}
        adminComment={adminComment}
        adminReasonChangeHandler={adminReasonChangeHandler}
        adminCommentChangeHandler={adminCommentChangeHandler}
        isAdmin={isAdmin}
      >
        <StyledHeading>
          {t('Do you really want to delete the following blackout period?')}
        </StyledHeading>
        <DateRangeGrid start={blackoutTime?.start} end={blackoutTime?.end} />
      </BaseModalContent>
    );
  };

export default DeleteBlackoutTimeModalContent;
