import routes from 'config/routes';

import { UUID_V4 } from '../regex/uuid';

interface GetCertificateDetailsPathnameParams {
  supplierId: string;
  certificateId: string;
}

export const getCertificateDetailsPathname = ({
  supplierId,
  certificateId,
}: GetCertificateDetailsPathnameParams): string =>
  routes.CERTIFICATE_DETAILS.replace(
    `:supplierId(${UUID_V4})`,
    supplierId
  ).replace(`:certificateUuid(${UUID_V4})`, certificateId);
