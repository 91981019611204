import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadioGroup from '@mui/material/RadioGroup';
import React, { FC } from 'react';

import { Radio } from 'components/atoms/Radio';
import { FieldIdentifier } from 'types';

interface RadioGroupProps {
  value: string;
  onChange?: (value: string) => void;
  ariaLabel?: string;
  className?: string;
  options: FieldIdentifier[];
  onSingleInputClick?: (value: string) => void;
}

export const RadioGroup: FC<RadioGroupProps> = (props) => {
  const { value, onChange, ariaLabel, options, className, onSingleInputClick } =
    props;

  return (
    <MuiRadioGroup
      aria-label={ariaLabel}
      value={value}
      onChange={(ev) => onChange?.(ev.target.value)}
      className={className}
    >
      {options.map(({ id, name, enabled }) => (
        <FormControlLabel
          key={id}
          value={id}
          control={
            <Radio
              onClick={(ev) =>
                onSingleInputClick?.((ev.target as HTMLInputElement).value)
              }
            />
          }
          label={name}
          disabled={!enabled}
        />
      ))}
    </MuiRadioGroup>
  );
};
