import { useTranslation } from 'react-i18next';

import {
  AlertVariantEnum,
  DraftStatus,
  DraftStatusContent,
  DraftStatusEnum,
  Nullable,
} from 'types';

import { useIsAmendment } from './useIsAmendment';

export const useAuditDraftStatusContent = (
  draftStatus?: DraftStatus
): Nullable<DraftStatusContent> => {
  const [t] = useTranslation('auditDraft');
  const isAmendment = useIsAmendment();
  const headerTitlePrefix = t(
    isAmendment ? 'Audit/assessment update' : 'Upload Audit/Assessment'
  );

  switch (draftStatus?.id) {
    case DraftStatusEnum.CANCELLED: {
      return {
        headerTitlePrefix,
        title: draftStatus.name,
        status: AlertVariantEnum.WARNING,
        label: t('The Process was cancelled'),
      };
    }
    case DraftStatusEnum.SUBMITTED: {
      return {
        headerTitlePrefix,
        title: draftStatus.name,
        status: AlertVariantEnum.SUCCESS,
        label: t(
          'The data was successfully submitted. The system will analyse the data and create the related entries. This may take a few minutes. You can close the process now.'
        ),
      };
    }
    case DraftStatusEnum.UNSUBMITTED: {
      return null;
    }
    default: {
      return {
        headerTitlePrefix,
        title: '',
        status: AlertVariantEnum.ERROR,
        label: t('Unsupported status value'),
      };
    }
  }
};
