import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import routes from 'config/routes';
import { off, on } from 'utils/customEvents';
import { REDIRECT_TO_FORBIDDEN_EVENT } from 'utils/customEvents/eventNames';
import { ForbiddenRedirectPayload } from 'utils/handleForbiddenError';

export const useForbiddenRedirect = (): void => {
  const history = useHistory();

  useEffect(() => {
    const handleForbiddenRedirect = (
      ev: CustomEventInit<ForbiddenRedirectPayload>
    ) => {
      const redirectParams = ev.detail || {
        pathname: routes.PERMISSION_DENIED,
      };
      return history.push(redirectParams);
    };

    on(REDIRECT_TO_FORBIDDEN_EVENT, handleForbiddenRedirect);

    return () => {
      off(REDIRECT_TO_FORBIDDEN_EVENT, handleForbiddenRedirect);
    };
  }, [history]);
};
