import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { SetCertificateDraftActionCommentParams } from './types';
import { endpoints } from '../../config/endpoints';

const setActionCommentApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    setCertificateDraftActionComment: build.mutation<
      void,
      SetCertificateDraftActionCommentParams
    >({
      query: ({ actionComment, certificateDraftId }) => ({
        url: endpoints.SET_ACTION_COMMENT(certificateDraftId),
        data: { actionComment },
        method: 'PUT',
      }),
    }),
  }),
});

export const useSetCertificateDraftActionCommentMutation = () => {
  const [setCertificateDraftActionComment, mutation] =
    setActionCommentApi.useSetCertificateDraftActionCommentMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (
    params: SetCertificateDraftActionCommentParams
  ) => setCertificateDraftActionComment(params).unwrap();

  return {
    ...mutation,
    setCertificateDraftActionComment: requestMethod,
    status,
    error,
  };
};
