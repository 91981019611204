import * as yup from 'yup';

import { generalDetailsSchema, relatedSupplierSchema } from 'schemas';

import { Access } from '../../types';

export const accessSchema: yup.ObjectSchema<Access> = yup.object({
  uuid: yup.string().required(),
  company: relatedSupplierSchema.required(),
  companyType: generalDetailsSchema.required(),
  role: generalDetailsSchema.required(),
  createdAt: yup.string().required(),
});
