import * as yup from 'yup';

import {
  AuditAppointmentExecutionModeEnum,
  AuditAppointmentKindEnum,
  AuditAppointmentTypeEnum,
} from 'domains/auditAppointment/types';
import { AuditAppointment } from 'domains/supplier/types';
import {
  customStringSchema,
  enumSchema,
  generalDetailsWithEnumSchema,
  relatedSupplierSchema,
  startEndDatesSchema,
} from 'schemas';
import { ProcessStatus } from 'types';

export const auditAppointmentSchema: yup.ObjectSchema<AuditAppointment> =
  yup.object({
    uuid: yup.string().required(),
    certificationBody: yup
      .object({
        uuid: yup.string().required(),
        name: yup.string().required(),
      })
      .required(),
    supplier: relatedSupplierSchema.required(),
    status: generalDetailsWithEnumSchema(ProcessStatus).required(),
    module: yup
      .object({
        id: yup.string().required(),
        name: yup.string().required(),
        plannerFormId: yup.string().optional(),
      })
      .required(),
    type: enumSchema(AuditAppointmentTypeEnum).required(),
    kind: enumSchema(AuditAppointmentKindEnum).required(),
    executionMode: enumSchema(AuditAppointmentExecutionModeEnum).required(),
    dueDate: startEndDatesSchema.nullable(),
    unannouncedPeriod: startEndDatesSchema.nullable(),
    description: customStringSchema.required(),
  });
