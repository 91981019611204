import { styled } from '@mui/material';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TEXT } from 'config/appColors';
import { Nullable } from 'types';

const StyledContainer = styled('div')`
  margin: 10px 0;
  padding-left: 30px;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.014rem;
`;

const StyledOutput = styled('span')<{ $highlight: boolean }>`
  color: ${({ $highlight }) =>
    $highlight ? TEXT.PRIMARY : TEXT.SECONDARY_DARK};
`;

interface DateRangeProps {
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
}

export const DateRange: FC<DateRangeProps> = ({ startDate, endDate }) => {
  const [t] = useTranslation('components');

  const highlightStart = !!startDate === !!endDate;

  const getContent = (date: Nullable<Date>, label: string) =>
    date ? format(date, 'MMM d') : t(label);

  return (
    <StyledContainer>
      <StyledOutput $highlight={highlightStart}>
        {getContent(startDate, 'Start')}
      </StyledOutput>{' '}
      -{' '}
      <StyledOutput $highlight={!highlightStart}>
        {getContent(endDate, 'End')}
      </StyledOutput>
    </StyledContainer>
  );
};
