import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { styled, SvgIconProps } from '@mui/material';
import React, { FC } from 'react';

import { WARNING } from 'config/appColors';

const StyledWarningIcon = styled(ReportProblemOutlinedIcon)`
  font-size: 1.8rem;
  color: ${WARNING.LIGHT};
  vertical-align: text-bottom;
`;

export const WarningIcon: FC<SvgIconProps> = (props) => (
  <StyledWarningIcon {...props} />
);

export default WarningIcon;
