import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { alpha, createTheme, styled, ThemeProvider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { FC, ReactElement } from 'react';

import { BLACK, SECONDARY, TEXT } from 'config/appColors';
import { mainThemeOptions } from 'themes/main';

export type ButtonProps = LoadingButtonProps;

const overriddenTheme = createTheme({
  ...mainThemeOptions,
  palette: {
    ...mainThemeOptions.palette,
    secondary: {
      main: SECONDARY[600],
    },
  },
});

const StyledButton = styled(LoadingButton)<ButtonProps>`
  font-weight: 500;
  border-radius: 4px;
  box-shadow: ${(props) =>
    props.variant === 'contained'
      ? `0 3px 1px -2px ${alpha(BLACK, 0.2)},
        0 2px 2px ${alpha(BLACK, 0.14)},
        0 1px 5px ${alpha(BLACK, 0.12)}`
      : 'none'};
  font-size: ${(props) => (props.size === 'small' ? '1.3rem' : '1.4rem')};
  line-height: ${(props) => (props.size === 'small' ? '2.2rem' : '2.4rem')};
  letter-spacing: ${(props) =>
    props.size === 'small' ? '0.046rem' : '0.04rem'};

  .MuiLoadingButton-loadingIndicatorStart {
    margin-right: 10px;
  }

  .MuiLoadingButton-loadingIndicatorEnd {
    margin-left: 10px;
  }

  .MuiCircularProgress-root {
    width: 18px !important;
    height: 18px !important;
    margin-left: 5px;
    color: ${TEXT.SECONDARY_LIGHT};
  }
`;

export const Button: FC<ButtonProps> = (props): ReactElement => {
  const { loadingPosition = 'end', ...rest } = props;

  /**
   * MUI gives a warning for using loading, loadingPosition without a startIcon/endIcon.
   * The code below adds the icon when loading is true to avoid the warning.
   * https://github.com/mui/material-ui/issues/27586#issuecomment-891860712
   */
  const Loader = <CircularProgress color="inherit" size={20} />;
  let loaderProps: { startIcon?: ReactElement; endIcon?: ReactElement };
  if (loadingPosition === 'start') loaderProps = { startIcon: Loader };
  else loaderProps = { endIcon: Loader };

  return (
    <ThemeProvider theme={overriddenTheme}>
      <StyledButton
        loadingPosition={rest.loading ? loadingPosition : undefined}
        {...rest}
        {...(rest.loading && loaderProps)}
      />
    </ThemeProvider>
  );
};

export default Button;
