import { Nullable, WithUndefined } from 'types';

import { formatDate } from './formatDate';
import { DATE_FORMAT } from '../../config/constants';

export const renderDateRange = (
  start: WithUndefined<Nullable<string>>,
  end: WithUndefined<Nullable<string>>,
  format = DATE_FORMAT,
  splitter = ' - '
): string => {
  if (!start && !end) {
    return '';
  }
  const startDate = start ? formatDate(start, format) : 'N/A';
  const endDate = end ? formatDate(end, format) : 'N/A';

  return `${startDate}${splitter}${endDate}`;
};
