import React, { FC, useMemo } from 'react';

import { Textarea } from 'components/atoms/Textarea';
import { ActionInformationSection } from 'components/organisms/ActionInformationSection';
import { useCommentStep } from 'hooks/draft/useCommentStep';
import { getStatus } from 'utils/getStatus';
import { isAnyLoading } from 'utils/isAnyLoading';

import { useCertificateDraftDetailsQuery } from '../../../state/certificateDraftDetails/api';
import { useSetCertificateDraftActionCommentMutation } from '../../../state/setActionComment/api';
import { useSetCertificateDraftCommentMutation } from '../../../state/setComment/api';
import { FormCommentStep } from '../../../types';
import { TemplateCertificateDraftStep } from '../../templates/TemplateCertificateDraftStep';

type FormCommentProps = FormCommentStep['props'] & {
  certificateDraftId: string;
};

export const FormComment: FC<FormCommentProps> = (props) => {
  const { actionReasonOptions, certificateDraftId, backAction, nextAction } =
    props;
  const {
    certificateDraftDetails: draftDetails,
    status: certificateDraftDetailsStatus,
  } = useCertificateDraftDetailsQuery({ certificateDraftId });

  const {
    comment,
    adminReason,
    adminComment,
    handleCommentChange,
    handleAdminReasonChange,
    handleAdminCommentChange,
    options,
    disableNextButton,
    isAdmin,
  } = useCommentStep({ draftDetails, actionReasonOptions });

  const {
    error: setCommentError = [],
    setComment: setCommentRequest,
    status: setCommentStatus,
  } = useSetCertificateDraftCommentMutation();
  const {
    error: setActionReasonError = [],
    setCertificateDraftActionComment: setActionCommentRequest,
    status: actionCommentStatus,
  } = useSetCertificateDraftActionCommentMutation();
  const errorMessages = useMemo(
    () => [...setCommentError, ...setActionReasonError],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(setCommentError), JSON.stringify(setActionReasonError)]
  );
  const nextButtonStatus = getStatus(
    isAnyLoading(actionCommentStatus, setCommentStatus)
  );

  const handleNextClick = (goNextStep: () => void) => {
    const commentRequest = comment
      ? setCommentRequest({
          certificateDraftId,
          comment,
        })
      : Promise.resolve();

    commentRequest
      .then(() =>
        isAdmin
          ? setActionCommentRequest({
              certificateDraftId,
              actionComment: {
                reason: adminReason,
                comment: adminComment,
              },
            })
          : Promise.resolve()
      )
      .then(goNextStep);
  };

  return (
    <TemplateCertificateDraftStep
      contentStatus={certificateDraftDetailsStatus}
      nextButtonStatus={nextButtonStatus}
      backAction={backAction}
      nextAction={nextAction}
      disableNext={disableNextButton}
      onNextClick={handleNextClick}
      error={errorMessages}
    >
      <Textarea
        fullWidth
        multiline
        rows={6}
        value={comment}
        onChange={handleCommentChange}
        data-testid="comment-textarea"
      />
      {isAdmin && (
        <ActionInformationSection
          adminReason={adminReason}
          adminComment={adminComment}
          handleAdminReasonChange={handleAdminReasonChange}
          handleAdminCommentChange={handleAdminCommentChange}
          adminReasonOptions={options}
        />
      )}
    </TemplateCertificateDraftStep>
  );
};
