import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { WithUndefined } from '../types';

export const useResetDraftStepper = (
  resetMethod: ActionCreatorWithPayload<WithUndefined<string>>
): void => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(resetMethod(undefined));
    },
    [dispatch, resetMethod]
  );
};
