import ArrowLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowRightIcon from '@mui/icons-material/ChevronRight';
import { ButtonProps, styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms/Button';
import { ModalActions } from 'components/atoms/ModalActions';
import {
  AUDIT_MODAL_NEXT_ACTION_TEST_ID,
  AUDIT_MODAL_PREV_ACTION_TEST_ID,
} from 'config/constants';
import { ButtonColorType, ButtonLoaderPositionType } from 'types';

interface AuditModalActionButtonsProps {
  prevAction?: () => void;
  nextAction?: () => void;
  disabledPrev?: boolean;
  disabledNext?: boolean;
  customContent?: ReactNode;
  prevLabel?: ReactNode | string;
  nextLabel?: ReactNode | string;
  prevButtonComponent?: ReactNode;
  nextButtonComponent?: ReactNode;
  noPrevArrow?: boolean;
  noNextArrow?: boolean;
  prevColor?: ButtonColorType;
  nextColor?: ButtonColorType;
  prevVariant?: ButtonProps['variant'];
  nextVariant?: ButtonProps['variant'];
  hidePrevButton?: boolean;
  hideNextButton?: boolean;
  nextButtonLoading?: boolean;
  nextButtonLoadingPosition?: ButtonLoaderPositionType;
}

interface StyledModalActionsProps {
  $prevButtonPresent: boolean;
}

const StyledModalActions = styled(ModalActions)<StyledModalActionsProps>`
  justify-content: ${(props) =>
    props.$prevButtonPresent ? 'space-between' : 'flex-end'};
`;

export const AuditModalActionButtons: FC<AuditModalActionButtonsProps> = (
  props
) => {
  const [t] = useTranslation('components');
  const {
    prevAction,
    nextAction,
    customContent,
    disabledPrev,
    disabledNext,
    prevLabel,
    nextLabel,
    prevButtonComponent,
    nextButtonComponent,
    noPrevArrow,
    noNextArrow,
    prevColor,
    nextColor,
    hidePrevButton,
    hideNextButton,
    nextButtonLoading,
    nextButtonLoadingPosition,
    prevVariant = 'text',
    nextVariant = 'contained',
  } = props;

  const renderBackButton = () =>
    // if hidePrevButton is true, don't render back button
    !hidePrevButton &&
    // if hidePrevButton is false or undefined and prevButtonComponent is defined, render it
    (prevButtonComponent ||
      // if hidePrevButton is false or undefined and prevButtonComponent is undefined but prevAction is defined, render default button
      (prevAction && (
        <Button
          disabled={disabledPrev}
          variant={prevVariant}
          color={prevColor ?? 'primary'}
          onClick={prevAction}
          data-testid={AUDIT_MODAL_PREV_ACTION_TEST_ID}
          className="previous-button"
        >
          {!noPrevArrow && <ArrowLeftIcon fontSize="large" />}{' '}
          {prevLabel || t('Back')}
        </Button>
      )));

  const renderNextButton = () =>
    // if hideNextButton is true, don't render next button
    !hideNextButton &&
    // if nextButtonComponent is defined, render it
    (nextButtonComponent ||
      // if nextButtonComponent is undefined but nextAction is defined, render default button
      (nextAction && (
        <Button
          disabled={disabledNext}
          variant={nextVariant}
          loading={nextButtonLoading}
          loadingPosition={nextButtonLoadingPosition}
          color={nextColor ?? 'primary'}
          onClick={nextAction}
          data-testid={AUDIT_MODAL_NEXT_ACTION_TEST_ID}
          className="next-button"
        >
          {nextLabel || t('Continue')}{' '}
          {!noNextArrow && <ArrowRightIcon fontSize="large" />}
        </Button>
      )));

  return (
    <StyledModalActions
      className="modal-footer"
      $prevButtonPresent={
        !!(!hidePrevButton && (prevAction || prevButtonComponent))
      }
    >
      {renderBackButton()}
      {customContent}
      {renderNextButton()}
    </StyledModalActions>
  );
};

export default AuditModalActionButtons;
