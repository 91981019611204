import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

import { RootState } from 'store';
import { Nullable } from 'types';

import { apiClient } from './axiosInstance';
import { requestInterceptor, responseInterceptor } from './interceptors';

export const setupApiClientInterceptors = (
  store: ToolkitStore<RootState>
): void => {
  let initialSetupCompleted = false;
  let previousToken: Nullable<string> = null;
  let previousInterceptor: number;

  store.subscribe(() => {
    const state = store.getState();
    const currentToken = state.user.auth.accessToken;

    if (
      previousToken !== currentToken ||
      (!initialSetupCompleted && currentToken === null)
    ) {
      initialSetupCompleted = true;
      previousToken = currentToken;

      apiClient.interceptors.request.eject(previousInterceptor);

      previousInterceptor = apiClient.interceptors.request.use(
        requestInterceptor(store).success,
        requestInterceptor(store).reject
      );
    }
  });

  apiClient.interceptors.response.use(
    responseInterceptor.success,
    responseInterceptor.reject
  );
};

export default apiClient;
