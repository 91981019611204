import React, { FC, ReactNode, useState } from 'react';

import { Button, ButtonProps } from 'components/atoms/Button';

export interface OpenModalButtonProps {
  modalComponent: FC<{
    open: boolean;
    handleClose: () => void;
  }>; // written inline to show in the storybook UI
  buttonProps?: ButtonProps;
  renderCustomOpenComponent?: (props: CustomOpenComponentProps) => void;
  beforeModalOpen?: () => void;
  beforeModalClose?: () => void;
  children?: ReactNode;
}

export interface CustomOpenComponentProps {
  handleClickOpen: () => void;
}

export const OpenModalButton: FC<OpenModalButtonProps> = (props) => {
  const {
    children,
    modalComponent: ModalComponent,
    buttonProps,
    renderCustomOpenComponent,
    beforeModalClose,
    beforeModalOpen,
  } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    beforeModalOpen?.();
    setOpen(true);
  };

  const handleClose = () => {
    beforeModalClose?.();
    setOpen(false);
  };

  return (
    <>
      {renderCustomOpenComponent ? (
        renderCustomOpenComponent({ handleClickOpen })
      ) : (
        <Button {...buttonProps} onClick={handleClickOpen}>
          {children}
        </Button>
      )}
      {open ? <ModalComponent open={open} handleClose={handleClose} /> : null}
    </>
  );
};

export default OpenModalButton;
