import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseModal } from 'components/atoms/BaseModal';
import { AbortModal } from 'components/organisms/AbortModal';

interface FileUploadAbortModalProps {
  open: boolean;
  title: ReactNode;
  continueAction: () => void;
  abortAction: () => void;
  message?: string;
  closeButtonText?: string;
  continueButtonText?: string;
}

export const FileUploadAbortModal: FC<FileUploadAbortModalProps> = (props) => {
  const [t] = useTranslation('auditFileUpload');
  const {
    open,
    title,
    continueAction,
    abortAction,
    message,
    closeButtonText,
    continueButtonText,
  } = props;

  return (
    <BaseModal open={open}>
      <AbortModal
        title={title}
        message={
          message ||
          t('Do you want to cancel the process? The data will be deleted.', {
            ns: 'components',
          })
        }
        closeButtonText={closeButtonText}
        continueButtonText={continueButtonText || t('Continue upload')}
        continueAction={continueAction}
        abortAction={abortAction}
      />
    </BaseModal>
  );
};
