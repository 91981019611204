import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { ExtendedError, LoaderStatusEnum, Nullable } from 'types';
import apiClient from 'utils/apiClient';
import { formatDate } from 'utils/dates';
import { formatToExtendedError } from 'utils/formatToExtendedError';

import { BlackoutTimesState } from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';
import { BlackoutTime } from '../../types';

const initialState: BlackoutTimesState = {
  data: [],
  loading: LoaderStatusEnum.IDLE,
  error: null,
};

const reducerName = `${parentReducerName}/blackoutTimes`;

// thunks
export const fetchBlackoutTimes = createAsyncThunk(
  reducerName,
  async (appointmentId: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        endpoints.BLACKOUT_TIME.LIST(appointmentId)
      );
      return response.data;
    } catch (err) {
      const errors = formatToExtendedError(err);
      return rejectWithValue(errors);
    }
  }
);

// slice
export const blackoutTimesSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetBlackoutTimesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBlackoutTimes.pending, (state) => {
      state.loading = LoaderStatusEnum.LOADING;
      state.error = null;
    });
    builder.addCase(fetchBlackoutTimes.fulfilled, (state, action) => {
      state.data = action.payload.blackoutTimes as BlackoutTime[];
      state.loading = LoaderStatusEnum.SUCCESS;
      state.error = null;
    });
    builder.addCase(fetchBlackoutTimes.rejected, (state, action) => {
      state.loading = LoaderStatusEnum.ERROR;
      state.error = action.payload as ExtendedError;
    });
  },
});

// actions
export const { resetBlackoutTimesState } = blackoutTimesSlice.actions;

// selectors
export const selectBlackoutTimes = (state: RootState): BlackoutTime[] =>
  state[parentReducerName].blackoutTimes.data.map(({ uuid, start, end }) => ({
    uuid,
    start: formatDate(start),
    end: formatDate(end),
  }));

export const selectBlackoutTimesLoading = (
  state: RootState
): LoaderStatusEnum => state[parentReducerName].blackoutTimes.loading;

export const selectBlackoutTimesError = (
  state: RootState
): Nullable<ExtendedError> => state[parentReducerName].blackoutTimes.error;

export default blackoutTimesSlice.reducer;
