import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Textarea } from 'components/atoms/Textarea';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';

import { useModalForm } from '../../../hooks/useModalForm';
import { useTranslatedSelectOptions } from '../../../hooks/useTranslatedSelectOptions';
import { useGetCertificateDetailsQuery } from '../../../state/certificateDetails/api';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { StyledCustomHeading } from '../../atoms/StyledCustomHeading';
import { StyledLabel } from '../../atoms/StyledLabel';
import { CertificateDetailsModalContentBase } from '../../templates/CertificateDetailsModalContentBase';

interface UnlockCertificateFormProps {
  errors?: string[];
  modalType:
    | CertificateDetailsModalEnum.UNLOCK_CERTIFICATE
    | CertificateDetailsModalEnum.UPDATE_UNLOCK_REASON;
  certificateUuid: string;
}

export const UnlockCertificateForm: FC<UnlockCertificateFormProps> = ({
  errors = [],
  modalType,
  certificateUuid,
}) => {
  const [t] = useTranslation('certificateDetails');
  const { UPDATE_UNLOCK_REASON } = CertificateDetailsModalEnum;
  const { certificateDetails: { ceid } = {} } = useGetCertificateDetailsQuery({
    certificateUuid,
  });
  const { reasonDescription, setFormValue } = useModalForm();
  const actionReasonOptions = useTranslatedSelectOptions({
    type: modalType,
    collectionName: 'actionReasons',
  });
  const isUpdateReinstateForm = modalType === UPDATE_UNLOCK_REASON;
  const alertsState = useMemoizedAlerts(errors);

  return (
    <CertificateDetailsModalContentBase
      actionReasonOptions={actionReasonOptions}
      alertsState={alertsState}
      showEmailCheckbox={isUpdateReinstateForm}
    >
      {ceid && (
        <StyledCustomHeading
          $variant={isUpdateReinstateForm ? 'regular' : 'error'}
        >
          {isUpdateReinstateForm
            ? t('Update reinstatement for CEID {{ceid}}', {
                ceid,
              })
            : t(
                'Reinstate certificate/letter of confirmation for CEID {{ceid}}',
                { ceid }
              )}
        </StyledCustomHeading>
      )}
      <StyledLabel htmlFor="reasonDescription">
        {t('Reasons for reinstatement')}:
      </StyledLabel>
      <Textarea
        value={reasonDescription}
        onChange={({ target }) =>
          setFormValue('reasonDescription', target.value)
        }
        id="reasonDescription"
        fullWidth
        rows={7}
        multiline
      />
    </CertificateDetailsModalContentBase>
  );
};
