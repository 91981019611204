const on = <T>(
  eventType: string,
  listener: (data: CustomEventInit<T>) => void
): void => {
  document.addEventListener(eventType, listener);
};

const off = <T>(
  eventType: string,
  listener: (data: CustomEventInit<T>) => void
): void => {
  document.removeEventListener(eventType, listener);
};

const trigger = <T>(eventType: string, data?: T): void => {
  const event = new CustomEvent<T>(eventType, { detail: data });
  document.dispatchEvent(event);
};

export { on, off, trigger };
