import {
  REASON_AUDIT_CORRECTION,
  REASON_CB_CHANGE,
  REASON_OTHER,
  REASON_TECHNICAL_ISSUE,
} from 'config/actionReasons';

import { AuditDetailsModalEnum } from '../state/modal/types';

export const parentReducerName = 'auditDetails';

export const actionReasons = {
  [AuditDetailsModalEnum.DELETE_AUDIT]: [
    REASON_TECHNICAL_ISSUE,
    REASON_CB_CHANGE,
    REASON_AUDIT_CORRECTION,
    REASON_OTHER,
  ],
};

export const UNREMOVABLE_RELEASE_ID = '4433fbbe-f85e-4bf6-9e34-1bee6abdb5ef';
export const AUDIT_RELEASE_TYPE =
  'audit_family_release_by_reference_audit_only_passed';
export const AUDIT_RELEASE_FAILURE_CONTENT = 'audit.details_failure_content';
