import { alpha, styled } from '@mui/material';
import MuiTextField, { StandardTextFieldProps } from '@mui/material/TextField';
import React, { FC } from 'react';

import { BLACK, TEXT } from 'config/appColors';

export type TextFieldProps = StandardTextFieldProps;

const StyledTextField = styled(MuiTextField)`
  && .MuiOutlinedInput-notchedOutline {
    border-color: ${alpha(BLACK, 0.23)};
    border-width: 1px;
  }

  && .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${TEXT.PRIMARY};
    }
  }

  input {
    font-size: 1.6rem;
    letter-spacing: 0.015rem;
    font-weight: 400;
    color: ${TEXT.PRIMARY};
    padding: 8.5px 14px;

    &::placeholder {
      color: ${TEXT.SECONDARY_LIGHT};
      opacity: 1;
    }
  }

  && .Mui-disabled {
    input {
      &::placeholder {
        -webkit-text-fill-color: ${TEXT.DISABLED}; // necessary because this has precedence over color and MUI uses this
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-style: dotted;
    }
  }

  label {
    display: none;

    & + .MuiInput-formControl {
      margin-top: 0;
    }
  }

  .MuiFormHelperText-root {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.04rem;
  }
`;

export const TextField: FC<TextFieldProps> = (props) => (
  <StyledTextField {...props} />
);
