import { getReactEnvVar } from 'utils/getReactEnvVar';

import { LANG_PARAM } from './languages';

const baseUrl = getReactEnvVar('YII_URL');

export const yiiUrls = {
  LOGOUT: `${baseUrl}/site/logout`,
  SUPPORT: `https://www.ifs-certification.com/${LANG_PARAM}/contact`,
};
