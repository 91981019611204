import { LANG_PARAM } from 'config/languages';

const endpoints = {
  DETAILS: (appointmentId: string): string =>
    `/${LANG_PARAM}/audit-appointments/${appointmentId}`,
  DETAILS_LOGS: (appointmentId: string): string =>
    `/${LANG_PARAM}/audit-appointments-views/${appointmentId}/logs`,
  ACTIONS: (appointmentId: string): string =>
    `/${LANG_PARAM}/audit-appointments/${appointmentId}/actions`,
  BLACKOUT_TIME: {
    LIST: (appointmentId: string): string =>
      `/${LANG_PARAM}/audit-appointments/${appointmentId}/blackout-time`,
    ADD: (appointmentId: string): string =>
      `/${LANG_PARAM}/audit-appointments/${appointmentId}/blackout-time`,
    DELETE: (appointmentId: string, blackoutTime: string): string =>
      `/${LANG_PARAM}/audit-appointments/${appointmentId}/blackout-time/${blackoutTime}`,
  },
  CREATE_PROCESS: {
    SUBMIT: `/${LANG_PARAM}/audit-appointments`,
    OPTION_LIST: {
      MODULES: `/${LANG_PARAM}/audit-appointments-preflights/modules`,
      TYPES: (moduleId: string): string =>
        `/${LANG_PARAM}/audit-appointments-preflights/modules/${moduleId}/types`,
      KINDS: (moduleId: string): string =>
        `/${LANG_PARAM}/audit-appointments-preflights/modules/${moduleId}/kinds`,
      EXECUTION_MODES: (moduleId: string): string =>
        `/${LANG_PARAM}/audit-appointments-preflights/modules/${moduleId}/execution-modes`,
    },
    TIMES: {
      ANNOUNCED_START_RANGE: (moduleId: string): string =>
        `/${LANG_PARAM}/audit-appointments-preflights/modules/${moduleId}/announced-start-range`,
      ANNOUNCED_END_RANGE: (moduleId: string): string =>
        `/${LANG_PARAM}/audit-appointments-preflights/modules/${moduleId}/announced-end-range`,
      ANNOUNCED_START_END_DATE_VALIDATION: (moduleId: string): string =>
        `/${LANG_PARAM}/audit-appointments-preflights/modules/${moduleId}/announced-start-end-date-validation`,
      UNANNOUNCED_START_RANGE: (moduleId: string): string =>
        `/${LANG_PARAM}/audit-appointments-preflights/modules/${moduleId}/unannounced-period-start-range`,
      UNANNOUNCED_END_DATE: (moduleId: string): string =>
        `/${LANG_PARAM}/audit-appointments-preflights/modules/${moduleId}/unannounced-period-end-date`,
      UNANNOUNCED_PERIOD_DATES: (moduleId: string): string =>
        `/${LANG_PARAM}/audit-appointments-preflights/modules/${moduleId}/unannounced-period-dates`,
    },
  },
  CANCEL: (appointmentId: string): string =>
    `/${LANG_PARAM}/audit-appointments/${appointmentId}/cancel`,
  REACTIVATE: (appointmentId: string): string =>
    `/${LANG_PARAM}/audit-appointments/${appointmentId}/reactivate`,
  DUE_DATE: {
    SET_AND_RESET: (appointmentId: string): string =>
      `/${LANG_PARAM}/audit-appointments/${appointmentId}/due-date`,
    START_RANGE: (appointmentId: string): string =>
      `/${LANG_PARAM}/audit-appointments-preflights/appointments/${appointmentId}/due-date-start-range`,
    END_RANGE: (appointmentId: string): string =>
      `/${LANG_PARAM}/audit-appointments-preflights/appointments/${appointmentId}/due-date-end-range`,
  },
  UPDATE_DESCRIPTION: (appointmentId: string): string =>
    `/${LANG_PARAM}/audit-appointments/${appointmentId}/update-description`,
  DIARY: {
    AUDIT_APPOINTMENTS_LIST: (certificationBodyUuid: string): string =>
      `/${LANG_PARAM}/certification-bodies/${certificationBodyUuid}/audit-appointments-announced`,
    AUDIT_REGISTRATIONS_LIST: (certificationBodyUuid: string): string =>
      `/${LANG_PARAM}/certification-bodies/${certificationBodyUuid}/audit-appointments-unannounced`,
    MISSING_ENTRIES_LIST: `/${LANG_PARAM}/audit-appointments-views/missing-entries-view`, // @ToDo - endpoint url to update in future
  },
};

export default endpoints;
