import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';

import './index.css';
import App from './App';
import { forceMockHeader as mockHeader } from './mock/constants';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { setupApiClientInterceptors } from './utils/apiClient';
import { setupYiiApiClientInterceptors } from './utils/apiClient/yii';
import { isAnyMock, isDev } from './utils/environments';
import { getReactEnvVar } from './utils/getReactEnvVar';

setupApiClientInterceptors(store);
setupYiiApiClientInterceptors(store);

(async () => {
  if (isDev && !!getReactEnvVar('RUN_WITH_REFRESH_COOKIE')) {
    const jwt = await import('jsonwebtoken');
    const { REFRESH_TOKEN_COOKIE_NAME } = await import(
      'domains/user/config/constants'
    );
    const { setCookie } = await import('./utils/cookies');

    // set refresh cookie for dev mode
    const refreshToken = jwt.sign({ isAdmin: true }, 'secret', {
      expiresIn: '30 days',
    });
    setCookie(REFRESH_TOKEN_COOKIE_NAME, refreshToken, 30);
  }
})();

const mockWorker = isAnyMock
  ? (async () => {
      const { worker } = await import('./mock');
      await worker.start({
        onUnhandledRequest: ({ headers }, print) => {
          const forceMockHeader = Object.keys(mockHeader)[0];
          if (headers.get(forceMockHeader)) {
            print.warning();
          }
        },
      });
    })()
  : Promise.resolve();

mockWorker.then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);
  root.render(
    <StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
