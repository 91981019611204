import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ActionDropdownOption } from 'types';

import { useGetCertificateDetailsActions } from '../state/certificateDetailsActions/api';
import { AdditionalSupplier } from '../state/certificateGroupCertificateTab/types';
import {
  openCertificateModal,
  setSupplierToRemove,
} from '../state/modal/slice';
import { CertificateDetailsModalEnum } from '../state/modal/types';
import { CertificateActionsEnum } from '../types';

interface UseAdditionalSupplierActionsDropdownProps {
  supplier: AdditionalSupplier;
  certificateUuid: string;
}

export type { UseAdditionalSupplierActionsDropdownProps as AdditionalSupplierActionsDropdownProps };

export const useAdditionalSupplierActionsDropdown = (
  props: UseAdditionalSupplierActionsDropdownProps
): ActionDropdownOption[] => {
  const [t] = useTranslation('certificateDetails');
  const dispatch = useDispatch();
  const { supplier, certificateUuid } = props;
  const { certificateDetailsActions } =
    useGetCertificateDetailsActions(certificateUuid);

  return [
    {
      label: t('Remove From Group'),
      onClickAction: () => {
        dispatch(setSupplierToRemove(supplier));
        dispatch(
          openCertificateModal({
            name: CertificateDetailsModalEnum.REMOVE_SUPPLIER,
          })
        );
      },
      permission: CertificateActionsEnum.CERTIFICATE_SUPPLIER_REMOVE,
    },
  ].filter(({ permission }) => certificateDetailsActions.includes(permission));
};
