import { styled } from '@mui/material';
import get from 'lodash/get';
import React, { FC } from 'react';

import { NestedGeneralDetails } from 'types';
import { generateId } from 'utils/generateId';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

export type Scope = NestedGeneralDetails;

const MAX_NESTING_LEVEL = 10;

const StyledMainUnorderedList = styled('ul')`
  margin: 2px 0;
  padding-left: 20px;
`;

const StyledListItem = styled('li')`
  list-style-type: disc;
`;

const StyledNestedUnorderedList = styled('ul')`
  padding-left: 15px;
`;

const StyledOutput = styled('p')`
  margin: 0;
`;

const isScope = (scope: unknown): scope is Scope =>
  !!scope &&
  'id' in (scope as Scope) &&
  'name' in (scope as Scope) &&
  'children' in (scope as Scope) &&
  Array.isArray((scope as Scope).children) &&
  (scope as Scope).children.every(isScope);

interface AttributeRowModuleProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
}

interface ScopeValueProps {
  scope: Scope;
  nestingLevel?: number;
  maxNestingLevel?: number;
}

export const ScopeValue: FC<ScopeValueProps> = ({
  scope,
  nestingLevel = 0,
  maxNestingLevel = MAX_NESTING_LEVEL,
}) => {
  const hasChildren = scope.children.length > 0;
  const reachedMaxNestingLevel = nestingLevel === maxNestingLevel;
  const showNestedList = hasChildren && !reachedMaxNestingLevel;

  return (
    <StyledListItem>
      <StyledOutput>{scope.name}</StyledOutput>
      {showNestedList && (
        <StyledNestedUnorderedList>
          {scope.children.map((scopeItem, index) => (
            <ScopeValue
              nestingLevel={nestingLevel + 1}
              key={generateId(scopeItem.id, index)}
              scope={scopeItem}
              maxNestingLevel={maxNestingLevel}
            />
          ))}
        </StyledNestedUnorderedList>
      )}
    </StyledListItem>
  );
};

export const AttributeRowScope: FC<AttributeRowModuleProps> = (props) => {
  const { data, dataRef, labelText } = props;
  const value = get(data, dataRef);
  const isArray = Array.isArray(value);
  const showAttributeRow = isArray && value.every(isScope) && value.length > 0;

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Scope data" />
    );
  }

  const valueToDisplay = (
    <StyledMainUnorderedList>
      {value.map((scopeValue, index) => (
        <ScopeValue key={generateId(scopeValue.id, index)} scope={scopeValue} />
      ))}
    </StyledMainUnorderedList>
  );

  return <AttributeRowBase label={labelText} value={valueToDisplay} />;
};
