import React, { FC, ReactNode } from 'react';

import { Logger } from 'components/atoms/Logger';

interface PermissionGateProps {
  requiredValueToPass: string;
  children: ReactNode;
}

export const PermissionGate: FC<PermissionGateProps> = ({
  requiredValueToPass,
  children,
}) => {
  const permissions = process.env.REACT_APP_ENABLED_FEATURES || '';
  const permissionList = permissions.split(';');

  const showFeature =
    permissionList.length > 0 && permissionList.includes(requiredValueToPass);

  return (
    <>
      {showFeature ? (
        children
      ) : (
        <Logger message="You're not allowed to see this element" />
      )}
    </>
  );
};
