import { Grid, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AttributeRowBase } from 'components/atoms/AttributeRow/AttributeRowBase';

interface DateRangeGridProps {
  start?: string;
  end?: string;
}

const StyledGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const DateRangeGrid: FC<DateRangeGridProps> = (props) => {
  const { start, end } = props;
  const [t] = useTranslation('auditAppointment');

  return (
    <StyledGrid container>
      <Grid item xs={7}>
        <AttributeRowBase label={t('Start Date')} value={start} />
        <AttributeRowBase label={t('End Date')} value={end} />
      </Grid>
    </StyledGrid>
  );
};
