import { SelectChangeEvent, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { ChangeEvent, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'components/atoms/Checkbox';
import { Select } from 'components/atoms/Select';
import { Textarea } from 'components/atoms/Textarea';
import { TEXT } from 'config/appColors';
import { GroupedOption, SelectOption } from 'types';

interface ActionInformationSectionProps {
  adminReason: string;
  handleAdminReasonChange: (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => void;
  heading?: string;
  description?: string;
  adminComment?: string;
  commentLabel?: ReactNode;
  selectPlaceholder?: string;
  handleAdminCommentChange: (comment: string) => void;
  adminReasonOptions: (SelectOption | GroupedOption)[];
  textareaRows?: string | number;
  sendMail?: boolean;
  sendMailDescription?: string;
  sendMailLabel?: string;
  handleSendMailChange?: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  hideHeading?: boolean;
  hideDescription?: boolean;
  className?: string;
}

const StyledContainer = styled('div')`
  margin-top: 20px;
`;

const StyledHeading = styled('h3')`
  font-weight: 500;
  font-size: 2rem;
  color: ${TEXT.PRIMARY};
`;

const StyledFieldDescription = styled(Typography)`
  color: ${TEXT.PRIMARY};
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  font-weight: 400;
`;

const StyledSelectDescription = styled(StyledFieldDescription)`
  margin: 15px 0 10px 0;
`;

const StyledTextareaDescription = styled(StyledFieldDescription)`
  margin: 35px 0 10px 0;
`;

const StyledSelect = styled(Select)`
  height: 56px;
`;

const StyledCheckbox = styled(Checkbox)`
  .MuiFormControlLabel-label {
    margin-top: 3px;
  }
`;

export const ActionInformationSection: FC<ActionInformationSectionProps> = (
  props
) => {
  const [t] = useTranslation('components');
  const {
    heading = t('Action Information'),
    description = t('Please select a reason.'),
    adminReason,
    adminComment,
    commentLabel = t('Comment'),
    selectPlaceholder = t('Please select'),
    handleAdminReasonChange,
    handleAdminCommentChange,
    adminReasonOptions,
    textareaRows,
    sendMail,
    sendMailDescription = t('Send information email to subscribers'),
    sendMailLabel = t('Send email'),
    handleSendMailChange,
    hideHeading = false,
    hideDescription = false,
    className,
  } = props;

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    handleAdminCommentChange(e.target.value);
  };
  const finalClassName = clsx(['ActionInformationSection', className]);

  return (
    <StyledContainer className={finalClassName}>
      {!hideHeading && <StyledHeading>{heading}</StyledHeading>}
      {!hideDescription && (
        <StyledSelectDescription>{description}</StyledSelectDescription>
      )}
      <StyledSelect
        fullWidth
        variant="outlined"
        data-testid="admin-info-reason-select"
        placeholder={selectPlaceholder}
        options={adminReasonOptions}
        value={adminReason}
        onChange={handleAdminReasonChange}
      />
      <StyledTextareaDescription>{commentLabel}</StyledTextareaDescription>
      <Textarea
        fullWidth
        data-testid="admin-comment-textarea"
        value={adminComment}
        onChange={onChangeHandler}
        multiline
        rows={textareaRows}
      />
      {!!handleSendMailChange && (
        <>
          <StyledTextareaDescription>
            {sendMailDescription}
          </StyledTextareaDescription>
          <StyledCheckbox
            checked={sendMail}
            onChange={handleSendMailChange}
            data-testid="admin-send-mail"
            label={sendMailLabel}
          />
        </>
      )}
    </StyledContainer>
  );
};
