import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from 'components/atoms/Button';
import { SectionTitle } from 'components/atoms/SectionTitle';
import { Column, Table, useColumnConfig } from 'components/organisms/Table';
import { NEVADA } from 'config/appColors';

import {
  getSupplierContacts,
  selectSupplierContacts,
  selectSupplierContactsError,
  selectSupplierContactsLoading,
} from '../../../state/detailsContacts/slice';
import { SupplierContact } from '../../../types';

const StyledContainer = styled('div')`
  padding: 25px;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 15px;
`;

const StyledDotsIcon = styled(MoreVertIcon)`
  color: ${NEVADA};
`;

const StyledTable = styled(Table)`
  table {
    min-width: 1100px;
  }
` as typeof Table;

export const CompanyDataTabContacts: FC = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [t] = useTranslation('supplier');

  const dispatch = useDispatch();

  const contacts = useSelector(selectSupplierContacts);
  const loadingStatus = useSelector(selectSupplierContactsLoading);
  const errorState = useSelector(selectSupplierContactsError);

  const { getColumnConfig } = useColumnConfig<SupplierContact>('18%');

  useEffect(() => {
    dispatch(getSupplierContacts({ supplierId }));
  }, [dispatch, supplierId]);

  const columns: Column<SupplierContact>[] = [
    {
      field: 'type',
      headerName: t('Type'),
      ...getColumnConfig(),
    },
    {
      field: 'salutation',
      headerName: t('Salutation'),
      ...getColumnConfig('14%'),
    },
    {
      field: 'firstname',
      headerName: t('Name'),
      customCell: (row) => `${row.firstname} ${row.lastname}`,
      ...getColumnConfig(),
    },
    {
      field: 'phone',
      headerName: t('Phone'),
      ...getColumnConfig(),
    },
    {
      field: 'email',
      headerName: t('Email'),
      ...getColumnConfig('22%'),
    },
    {
      field: 'id',
      headerName: '',
      ...getColumnConfig('10%', { otherCellProps: { align: 'right' } }),
      customCell: () => (
        <Button>
          <StyledDotsIcon fontSize="large" />
        </Button>
      ),
    },
  ];

  return (
    <StyledContainer>
      <StyledGridContainer
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <SectionTitle>{t('Contacts')}</SectionTitle>
        </Grid>
        <Grid item>
          <Button variant="contained" color="info" startIcon={<AddIcon />}>
            {t('Add Contact')}
          </Button>
        </Grid>
      </StyledGridContainer>
      <StyledTable
        rows={contacts}
        columns={columns}
        status={loadingStatus}
        error={errorState}
        dense
      />
    </StyledContainer>
  );
};
