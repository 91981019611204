import { styled } from '@mui/material';
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';
import React, { FC, ReactNode } from 'react';

import { PRIMARY, TEXT } from 'config/appColors';

interface TabProps extends MuiTabProps {
  label: ReactNode;
  disabled?: boolean;
}

const StyledTab = styled(MuiTab)`
  min-width: 0;
  color: ${TEXT.SECONDARY_LIGHT};
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  text-transform: none;
  padding: 10px 15px;

  &.Mui-selected {
    color: ${PRIMARY.MAIN};
  }

  &.Mui-disabled {
    color: ${TEXT.DISABLED};
  }
`;

export const Tab: FC<TabProps> = ({ label, disabled, ...restProps }) => (
  <StyledTab label={label} disabled={disabled} {...restProps} />
);

export default Tab;
