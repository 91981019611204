import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { QueryParams } from 'utils/queryParams';

import { SortStateType } from '../../../components/organisms/Table/types';
import {
  removeValueFromFilterList,
  resetAllAuditsListState,
  selectAreFiltersReadyToFetch,
  selectFilters,
  selectSortState,
  setFiltersReadyToFetch,
  setFilterValue,
  setSortState,
  updateFiltersAndSortState,
} from '../state/allAuditsList/slice';
import { Filters, UseFiltersAndSorting } from '../types';
import { getInitialFilters } from '../utils/getInitialFilters';

interface UseFiltersAndSortingProps {
  resetPaginationState?: () => void;
}

export const useFiltersAndSorting = ({
  resetPaginationState,
}: UseFiltersAndSortingProps = {}): UseFiltersAndSorting => {
  const { replace } = useHistory();
  const d = useDispatch();
  const filtersReadyToFetch = useSelector(selectAreFiltersReadyToFetch);
  const UIFilters = useSelector(selectFilters);
  const UISortState = useSelector(selectSortState);

  const { getParam, parseParams, updateQueryParams, stringifyParams } =
    QueryParams;

  const initialFilters = getInitialFilters();
  const filtersInURL = getParam('filters');
  const parsedFilters = useMemo(
    () => parseParams(filtersInURL || '') as Filters,
    [parseParams, filtersInURL]
  );

  const sortStateInURL = getParam('sort');
  const parsedSortState = useMemo(
    () =>
      sortStateInURL ? (parseParams(sortStateInURL) as SortStateType) : null,
    [parseParams, sortStateInURL]
  );

  const updateURL = useCallback(
    (value: Record<string, unknown>, mergeParams = true) =>
      replace({
        search: updateQueryParams(value, mergeParams),
      }),
    [replace, updateQueryParams]
  );

  const resetFiltersAndSorting = () => {
    d(resetAllAuditsListState());
    updateURL(
      {
        filters: stringifyParams(initialFilters),
      },
      false
    );
    resetPaginationState?.();
  };

  const updateURLFiltersAndSorting = () => {
    updateURL({
      filters: stringifyParams(UIFilters),
      ...(UISortState && { sort: stringifyParams(UISortState) }),
    });
  };

  useEffect(() => {
    if (!filtersReadyToFetch) {
      // set initial values

      if (!filtersInURL) {
        // if param doesn't exist in URL set initial params
        updateURL({
          filters: stringifyParams(initialFilters),
        });
      } else {
        d(
          updateFiltersAndSortState({
            filters: parsedFilters,
            sortState: parsedSortState,
          })
        );
      }

      d(setFiltersReadyToFetch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersReadyToFetch, filtersInURL]);

  return {
    filters: UIFilters, // filters selected on UI, filters = URLFilters if user clicked "Search" button
    URLFilters: parsedFilters, // filters in URL, served to API request
    setFilterValue: (payload) => d(setFilterValue(payload)),
    removeValueFromFilterList: (payload) =>
      d(removeValueFromFilterList(payload)),
    sortState: UISortState, // sortState selected on UI, sortState = URLSortState if user clicked "Search" button
    URLSortState: parsedSortState, // sortState in URL, served to API request
    setSortState: (payload) => d(setSortState(payload)),
    resetFiltersAndSorting,
    filtersReadyToFetch,
    updateURLFiltersAndSorting,
  };
};
