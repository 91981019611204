import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { SetCommentParams } from './types';
import { endpoints } from '../../config/endpoints';

const setCommentApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    setCertificateDraftComment: build.mutation<void, SetCommentParams>({
      query: ({ certificateDraftId, comment }) => ({
        url: endpoints.SET_COMMENT(certificateDraftId),
        data: {
          comment,
        },
        method: 'PUT',
      }),
    }),
  }),
});

export const useSetCertificateDraftCommentMutation = () => {
  const [setComment, mutation] =
    setCommentApi.useSetCertificateDraftCommentMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: SetCommentParams) =>
    setComment(params).unwrap();

  return {
    ...mutation,
    setComment: requestMethod,
    status,
    error,
  };
};
