import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from 'components/atoms/SectionTitle';

import { CertificateListTable } from '../CertificateListTable';

const StyledContainer = styled('div')`
  padding: 25px;
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 25px;
`;

export const InactiveCertificatesTab: FC = () => {
  const [t] = useTranslation('supplier');

  return (
    <StyledContainer>
      <StyledSectionTitle>
        {t('Inactive certificates/letters of confirmation')}
      </StyledSectionTitle>
      <CertificateListTable showInactiveCertificates />
    </StyledContainer>
  );
};
