import { CSSSize } from 'types';

import { ellipsisColumnStyle } from './styles';
import { Column } from './types';

interface OtherProps<T> {
  otherHeaderProps?: Column<T>['headerProps'];
  otherCellProps?: Column<T>['cellProps'];
}

type GetColumnConfig = <T>(
  width?: CSSSize,
  props?: OtherProps<T>
) => Partial<Column<T>>;

interface UseColumnConfigType<T> {
  getColumnConfig: GetColumnConfig;
  getColumnConfigWithEllipsis: (width: CSSSize) => Partial<Column<T>>;
}

export const useColumnConfig = <T>(
  defaultWidth: CSSSize
): UseColumnConfigType<T> => {
  const getColumnConfig = (
    width: CSSSize = defaultWidth,
    props?: OtherProps<T>
  ) => ({
    headerProps: {
      width,
      ...props?.otherHeaderProps,
    },
    cellProps: {
      width,
      ...props?.otherCellProps,
    },
  });

  const getColumnConfigWithEllipsis = (width: CSSSize) =>
    getColumnConfig(width, {
      otherCellProps: {
        style: ellipsisColumnStyle,
      },
    });

  return { getColumnConfig, getColumnConfigWithEllipsis };
};
