import { SelectChangeEvent, styled, Typography } from '@mui/material';
import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Textarea } from 'components/atoms/Textarea';
import { BaseModalContent } from 'components/organisms/BaseModalContent';
import { TEXT } from 'config/appColors';
import { ActionComment } from 'types';

import { actionReasons } from '../../../config/constants';
import {
  selectUpdateDescriptionActionComment,
  selectUpdateDescriptionAlertsState,
  selectUpdateDescriptionDescription,
  setUpdateDescriptionActionComment,
  setUpdateDescriptionDescription,
} from '../../../state/updateDescription/slice';

interface UpdateDescriptionModalContentProps {
  isAdmin?: boolean;
  setIsAdminInfoValid: Dispatch<SetStateAction<boolean>>;
  isDescriptionTouched: boolean;
  setIsDescriptionTouched: Dispatch<SetStateAction<boolean>>;
}

const StyledTextareaDescription = styled(Typography)`
  color: ${TEXT.PRIMARY};
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  font-weight: 400;
  margin: 0 0 10px;
`;

const StyledTextarea = styled(Textarea)`
  margin: 0 0 20px;
`;

export const UpdateDescriptionModalContent: FC<UpdateDescriptionModalContentProps> =
  (props) => {
    const {
      isAdmin,
      setIsAdminInfoValid,
      isDescriptionTouched,
      setIsDescriptionTouched,
    } = props;
    const dispatch = useDispatch();
    const [t] = useTranslation('auditAppointment');
    const alertsState = useSelector(selectUpdateDescriptionAlertsState);
    const actionComment = useSelector(selectUpdateDescriptionActionComment);
    const description = useSelector(selectUpdateDescriptionDescription);
    const adminReason = actionComment?.reason ?? '';
    const adminComment = actionComment?.comment ?? '';

    const dispatchSetDescription = (val: string) =>
      dispatch(setUpdateDescriptionDescription(val));
    const dispatchSetActionComment = (val: ActionComment) =>
      dispatch(setUpdateDescriptionActionComment(val));

    const onDescriptionChange = (
      ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (!isDescriptionTouched) setIsDescriptionTouched(true);
      dispatchSetDescription(ev.target.value);
    };

    const adminReasonChangeHandler = (event: SelectChangeEvent<unknown>) => {
      dispatchSetActionComment({
        reason: event.target.value as string,
        comment: adminComment,
      });
    };

    const adminCommentChangeHandler = (comment: string) => {
      dispatchSetActionComment({
        reason: adminReason,
        comment,
      });
    };

    return (
      <BaseModalContent
        isAdmin={isAdmin}
        adminReasonOptions={actionReasons.UPDATE_DESCRIPTION.ADMIN}
        setIsAdminInfoValid={setIsAdminInfoValid}
        alertsState={alertsState}
        adminReason={adminReason}
        adminComment={adminComment}
        adminReasonChangeHandler={adminReasonChangeHandler}
        adminCommentChangeHandler={adminCommentChangeHandler}
      >
        <StyledTextareaDescription>{t('Comment')}</StyledTextareaDescription>
        <StyledTextarea
          data-testid="update-description"
          fullWidth
          value={description}
          multiline
          rows={7}
          onChange={onDescriptionChange}
        />
      </BaseModalContent>
    );
  };
