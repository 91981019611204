import * as yup from 'yup';

import { CertificateStatusEnum } from 'domains/supplier/types';
import {
  certificateLockFullSchema,
  generalDetailsSchema,
  generalDetailsWithEnumSchema,
  relatedCertificationBodySchema,
  relatedSupplierSchema,
  resultSchema,
  startEndDatesSchema,
} from 'schemas';
import { GeneralDetails } from 'types';

import { CertificateDetails } from '../../types';

export const certificateSchema: yup.ObjectSchema<CertificateDetails> =
  yup.object({
    uuid: yup.string().required(),
    version: yup.number().optional(),
    ceid: yup.string(),
    status: yup
      .mixed<
        | GeneralDetails<CertificateStatusEnum>
        | GeneralDetails<CertificateStatusEnum>[]
      >()
      .test('status', (value) => {
        if (Array.isArray(value))
          return yup
            .array()
            .of(generalDetailsWithEnumSchema(CertificateStatusEnum).required())
            .required()
            .isValidSync(value);
        return generalDetailsWithEnumSchema(CertificateStatusEnum)
          .required()
          .isValidSync(value);
      })
      .required(),
    certificationBody: relatedCertificationBodySchema.required(),
    supplier: relatedSupplierSchema.required(),
    module: generalDetailsSchema.required(),
    type: generalDetailsSchema,
    validity: startEndDatesSchema.required(),
    auditResult: resultSchema.optional().default(undefined),
    activeLock: certificateLockFullSchema,
    latestLock: certificateLockFullSchema,
    tags: yup.array().of(yup.string().required()).optional().default(undefined),
  });
