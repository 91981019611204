import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { Box, styled, Tooltip } from '@mui/material';
import React, { FC, useState } from 'react';

import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { ATHENS_GRAY } from 'config/appColors';
import { CSSSize } from 'types';

interface ImageLoaderProps {
  src: string;
  placeholderSrc?: string;
  alt?: string;
  height?: CSSSize;
  width?: CSSSize;
  loaderMinHeight?: CSSSize;
  loaderMinWidth?: CSSSize;
}

interface LoaderBoxStyleType {
  display: string;
  justifyContent: string;
  alignItems: string;
  width?: CSSSize;
  height?: CSSSize;
  minWidth?: CSSSize;
  minHeight?: CSSSize;
}

interface StyledImageProps {
  $loadingBool: boolean;
}

const StyledImage = styled('img')<StyledImageProps>`
  display: ${(props) => (props.$loadingBool ? 'none' : 'block')};
`;

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{
      popper: props.className,
      tooltip: 'hintTooltip',
    }}
    {...props}
  />
))`
  & .hintTooltip {
    border-radius: 2px;
    padding: 4px 8px;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.04rem;
    font-weight: 400;
    max-width: 200px;
  }
`;

export const ImageLoader: FC<ImageLoaderProps> = (props) => {
  const {
    src,
    placeholderSrc,
    alt,
    loaderMinHeight,
    loaderMinWidth,
    width,
    height,
  } = props;

  const [imgSrc, setImgSrc] = useState(src);
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [placeholderErrored, setPlaceholderErrored] = useState(false);
  const imgAlt = alt || '';

  const onErrorHandler = () => {
    if (!errored) {
      setErrored(true);
      if (placeholderSrc) setImgSrc(placeholderSrc);
    } else {
      setPlaceholderErrored(true);
    }
  };

  const onLoadHandler = () => {
    setLoading(false);
  };

  const loaderBoxStyle: LoaderBoxStyleType = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (width) loaderBoxStyle.width = width;
  else loaderBoxStyle.minWidth = loaderMinWidth;

  if (height) loaderBoxStyle.height = height;
  else loaderBoxStyle.minHeight = loaderMinHeight;

  const placeholderIcon = (
    <Box sx={loaderBoxStyle}>
      <BrokenImageIcon
        style={{
          color: ATHENS_GRAY,
          width: '3rem',
          height: '3rem',
        }}
      />
    </Box>
  );

  if ((errored && !placeholderSrc) || placeholderErrored) {
    if (alt) {
      return (
        <StyledTooltip title={alt} placement="top" arrow>
          {placeholderIcon}
        </StyledTooltip>
      );
    }
    return placeholderIcon;
  }

  return (
    <>
      {loading ? (
        <Box sx={loaderBoxStyle}>
          <LoaderIcon size={20} />
        </Box>
      ) : null}
      <StyledImage
        src={imgSrc}
        alt={imgAlt}
        onError={onErrorHandler}
        onLoad={onLoadHandler}
        $loadingBool={loading}
        width={width}
        height={height}
      />
    </>
  );
};

export default ImageLoader;
