import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import BaseModal from 'components/atoms/BaseModal';
import { AlertList } from 'components/molecules/AlertList';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { ARGENT, TEXT } from 'config/appColors';
import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { AlertsState, LoaderStatusEnum, Nullable } from 'types';

interface DeleteFileModalProps {
  open: boolean;
  fileName: string;
  fileUUID: string;
  fileType?: string;
  fileDeleteStatus: LoaderStatusEnum;
  fileDeleteAlertsState: Nullable<AlertsState>;
  handleClose: () => void;
  onDelete: (fileUUID: string) => Promise<void>;
}

const StyledBaseModal = styled(BaseModal)`
  .MuiDialog-paper {
    width: 450px;
  }

  .MuiDialogContent-root {
    min-height: 130px;
    padding: 0;
    border-bottom: 1px solid ${ARGENT};
    margin-bottom: 16px;
  }
`;

const StyledContainer = styled('div')`
  width: 100%;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  color: ${TEXT.PRIMARY};
  margin: 13px 48px;
  overflow-wrap: break-word;
  overflow-y: auto;

  p {
    margin: 0 0 10px;
  }

  span {
    font-size: 1.4rem;
  }
`;

const StyledDescription = styled('div')`
  margin: 20px 0;
`;

export const DeleteFileModal: FC<DeleteFileModalProps> = (props) => {
  const {
    open,
    fileName,
    fileUUID,
    fileType,
    fileDeleteStatus,
    fileDeleteAlertsState: alertsState,
    handleClose,
    onDelete,
  } = props;
  const [t] = useTranslation('components');
  const notificationMessage = t('File deleted successfully.');
  const { openSnackbar } = useSnackbarNotification();
  const showAlerts = alertsState && alertsState.alerts.length > 0;

  const getTitle = () =>
    t('Delete') + (fileType ? `: ${fileType}` : ` ${t('file')}`);

  const nextAction = () => {
    onDelete(fileUUID).then(() => {
      handleClose();
      openSnackbar(notificationMessage, 'success');
    });
  };

  return (
    <StyledBaseModal open={open}>
      <TemplateAuditModal
        title={getTitle()}
        contentComponent={
          <StyledContainer>
            {showAlerts && (
              <AlertList
                variant={alertsState?.variant}
                alerts={alertsState.alerts}
              />
            )}
            <StyledDescription>
              <p>{t('Do you really want to delete the document?')}</p>
              <span>{fileName}</span>
            </StyledDescription>
          </StyledContainer>
        }
        closeAction={handleClose}
        noPrevArrow
        noNextArrow
        prevLabel={t('Close')}
        nextLabel={t('Delete')}
        prevAction={handleClose}
        nextAction={nextAction}
        prevColor="modalPrevButton"
        nextColor="error"
        hidePrevButton={fileDeleteStatus === LoaderStatusEnum.LOADING}
        nextButtonLoading={fileDeleteStatus === LoaderStatusEnum.LOADING}
      />
    </StyledBaseModal>
  );
};
