import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import { SelectChangeEvent, styled } from '@mui/material';
import React, { FC, ChangeEvent as ReactChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/atoms/Button';
import { DatePicker } from 'components/atoms/DatePicker';
import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { Select } from 'components/atoms/Select';
import { StyledFiltersContainer } from 'components/atoms/StyledFiltersContainer';
import { TEXT, WHITE } from 'config/appColors';
import { useDiaryResetSearchPhrase } from 'domains/auditAppointment/hooks/useDiaryResetSearchPhrase';
import { useMemoizedDebounce } from 'hooks/useMemoizedDebounce';
import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { LoaderStatusEnum, Nullable, ProcessStatus } from 'types';
import { formatDate } from 'utils/dates';
import { getTranslatedStatuses } from 'utils/getTranslatedStatuses';

import { DIARY_MIN_SEARCH_CHARS } from '../../../config/constants';
import { TEMPORARY_MODULES } from '../../../config/temporalConstants';
import {
  exportDiaryAuditAppointmentList,
  resetFilterAndSortState,
  resetSearchPhrase,
  selectDiaryAuditsAppointmentExportLoading,
  selectDiaryAuditsAppointmentFilter,
  selectSearchMinCharError,
  setFilterState,
} from '../../../state/diaryAuditAppointments/slice';
import { FilterSearchInput } from '../../atoms/FilterSearchInput';

interface StyledSelectProps {
  $minWidth?: string;
  $maxWidth?: string;
  $width?: string;
}

const StyledLabel = styled('label')`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 10px;
`;

const StyledLabelText = styled('span')`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.015rem;
`;

const StyledSelect = styled(Select)<StyledSelectProps>`
  height: 40px;
  background: ${WHITE};
  width: ${({ $width }) => $width ?? 'auto'};
  min-width: ${({ $minWidth }) => $minWidth ?? 'none'};
  max-width: ${({ $maxWidth }) => $maxWidth ?? 'none'};
`;

const StyledCommonButton = styled(Button)`
  height: 30px;
  font-size: 1.3rem;
  line-height: 2.2rem;
  letter-spacing: 0.046rem;
  font-weight: 500;
`;

const StyledResetButton = styled(StyledCommonButton)`
  margin: 20px 10px 10px 0;
  width: fit-content;
`;

const StyledExportButton = styled(StyledCommonButton)`
  margin-top: 20px;
  margin-left: auto;
  width: fit-content;
  margin-bottom: 10px;
`;

const StyledLoader = styled(LoaderIcon)`
  width: 20px;
  height: 20px;

  svg {
    color: ${TEXT.SECONDARY_LIGHT};
  }
`;

export const DiaryAuditAppointmentsFilters: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const notificationMessage = t('Excel could not be generated');
  const { openSnackbar } = useSnackbarNotification();
  const filter = useSelector(selectDiaryAuditsAppointmentFilter);
  const loading = useSelector(selectDiaryAuditsAppointmentExportLoading);
  const searchMinCharError = useSelector(selectSearchMinCharError);
  const searchTermInputRef = useRef<Nullable<HTMLInputElement>>(null);

  useDiaryResetSearchPhrase(
    searchMinCharError,
    resetSearchPhrase,
    searchTermInputRef
  );

  const handleDateChange = (key: 'startDate' | 'endDate') => (date: Date) => {
    dispatch(setFilterState({ key, value: formatDate(date, 'yyyy-MM-dd') }));
  };

  const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(
      setFilterState({
        key: 'status',
        value: event.target.value as ProcessStatus,
      })
    );
  };

  const handleModuleChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(
      setFilterState({ key: 'moduleId', value: event.target.value as string })
    );
  };

  const handleSearchChange = (
    event: ReactChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
    dispatch(
      setFilterState({
        key: 'supplierNameCoidSearchString',
        value: event.target.value.trim(),
      })
    );

  const debouncedHandleChange = useMemoizedDebounce(handleSearchChange);

  const handleResetClick = () => {
    dispatch(resetFilterAndSortState());
    if (searchTermInputRef && searchTermInputRef.current) {
      searchTermInputRef.current.value = '';
    }
  };

  const onExportErrorCallback = () =>
    openSnackbar(notificationMessage, 'error');
  const handleExportClick = () => {
    dispatch(exportDiaryAuditAppointmentList({ onExportErrorCallback }));
  };

  return (
    <StyledFiltersContainer>
      <StyledLabel>
        <StyledLabelText>{t('Date')}</StyledLabelText>
        <div className="date-pickers">
          <DatePicker
            value={filter.startDate}
            inputWidth="125px"
            datePickerOffset="95px"
            onChange={handleDateChange('startDate')}
          />
          <DatePicker
            value={filter.endDate}
            inputWidth="125px"
            datePickerOffset="95px"
            onChange={handleDateChange('endDate')}
          />
        </div>
      </StyledLabel>
      <StyledLabel>
        <StyledLabelText>{t('Status')}</StyledLabelText>
        <StyledSelect
          data-testid="status"
          icon={ArrowDropDownIcon}
          value={filter.status[0]}
          options={getTranslatedStatuses()}
          onChange={handleStatusChange}
          $width="150px"
        />
      </StyledLabel>
      <StyledLabel>
        <StyledLabelText>
          {t('Standard/program', { ns: 'components' })}
        </StyledLabelText>
        <StyledSelect
          data-testid="module"
          icon={ArrowDropDownIcon}
          value={filter.moduleId}
          placeholder="---"
          options={TEMPORARY_MODULES}
          onChange={handleModuleChange}
          $minWidth="150px"
          $maxWidth="300px"
        />
      </StyledLabel>
      <StyledLabel>
        <StyledLabelText>{t('Search Name/COID')}</StyledLabelText>
        <FilterSearchInput
          data-testid="search"
          inputRef={searchTermInputRef}
          placeholder={t('search')}
          defaultValue={filter.supplierNameCoidSearchString}
          onChange={debouncedHandleChange}
          helperText={
            searchMinCharError
              ? t('Please enter at least {{number}} characters', {
                  number: DIARY_MIN_SEARCH_CHARS,
                })
              : ''
          }
          error={searchMinCharError}
          minWidth="200px"
          maxWidth="400px"
        />
      </StyledLabel>
      <StyledResetButton
        variant="contained"
        color="info"
        onClick={handleResetClick}
      >
        {t('Reset')}
      </StyledResetButton>
      <StyledExportButton
        variant="contained"
        color="info"
        endIcon={
          loading === LoaderStatusEnum.LOADING ? (
            <StyledLoader size="small" />
          ) : (
            <DownloadIcon />
          )
        }
        onClick={handleExportClick}
        disabled={loading === LoaderStatusEnum.LOADING}
      >
        {t('export', { ns: 'components' })}
      </StyledExportButton>
    </StyledFiltersContainer>
  );
};
