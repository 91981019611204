import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { SetCertificateDraftUpdateReasonParams } from './types';
import { endpoints } from '../../config/endpoints';

const setUpdateReasonApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    setCertificateDraftUpdateReason: build.mutation<
      void,
      SetCertificateDraftUpdateReasonParams
    >({
      query: ({ certificateDraftId, reason }) => ({
        url: endpoints.SET_REASON(certificateDraftId),
        data: reason,
        method: 'PUT',
      }),
    }),
  }),
});

export const useCertificateDraftSetUpdateReasonMutation = () => {
  const [setCertificateDraftUpdateReason, mutation] =
    setUpdateReasonApi.useSetCertificateDraftUpdateReasonMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: SetCertificateDraftUpdateReasonParams) =>
    setCertificateDraftUpdateReason(params).unwrap();

  return {
    ...mutation,
    setCertificateDraftUpdateReason: requestMethod,
    status,
    error,
  };
};
