import {
  FieldIdentifier,
  GeneralDetails,
  Nullable,
  ProcessStatus,
  StartEndDates,
  Supplier,
  UniqueIdentifier,
} from 'types';

export enum AuditAppointmentTypeEnum {
  INITIAL = 'initial-audit',
  RECERTIFICATION = 'recertification-audit',
  EXTENSION = 'extension-audit',
  FOLLOW_UP = 'follow-up-audit',
}

export enum AuditAppointmentKindEnum {
  ANNOUNCED = 'announced',
  UNANNOUNCED = 'unannounced',
}

export enum AuditAppointmentExecutionModeEnum {
  ON_SITE = 'on-site',
  REMOTE = 'remote',
  SPLIT = 'split',
}

export interface BlackoutTime extends StartEndDates {
  uuid: string;
}

export interface ModuleIdentifier extends Omit<FieldIdentifier, 'enabled'> {
  plannerFormId?: string;
}

export type TypeIdentifier = FieldIdentifier<AuditAppointmentTypeEnum>;

export type KindIdentifier = FieldIdentifier<AuditAppointmentKindEnum>;

export type ExecutionModeIdentifier =
  FieldIdentifier<AuditAppointmentExecutionModeEnum>;

export interface AuditAppointment {
  uuid: string;
  certificationBody: UniqueIdentifier;
  supplier: Supplier;
  status: GeneralDetails<ProcessStatus>;
  module: ModuleIdentifier;
  type: AuditAppointmentTypeEnum;
  kind: AuditAppointmentKindEnum;
  executionMode: AuditAppointmentExecutionModeEnum;
  dueDate: Nullable<StartEndDates>;
  unannouncedPeriod: Nullable<StartEndDates>;
  description: string;
}

export interface TimeRange {
  earliest: string; // "2002-11-01"
  latest: string; // "2002-11-01"
  hints: string[];
}

export type DiaryAuditAppointment = Omit<AuditAppointment, 'blackoutTimes'>;
