import i18n from 'i18next';
import { forEach } from 'lodash';

import { STATUSES } from 'domains/auditAppointment/config/constants';
import { Option } from 'types';

export const getTranslatedStatuses = (): Option[] => {
  const statuses: Option[] = [];
  forEach(STATUSES, (o) => {
    statuses.push({ ...o, label: i18n.t(o.label, { ns: 'components' }) });
  });
  return statuses;
};
