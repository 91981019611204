import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ERROR } from 'config/appColors';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { getCertificateDraftCreatePathname as YII_getCertificateDraftCreatePathname } from 'domains/widgets/utils/getCertificateDraftCreatePathname';
import { ActionDropdownOption } from 'types';
import { getCertificateDraftCreatePathname } from 'utils/getCertificateDraftCreatePathname';
import isIframe from 'utils/isIframe';

import { useCertificateInfoBoxData } from './useCertificateInfoBoxData';
import { useGetCertificateDetailsQuery } from '../state/certificateDetails/api';
import { useGetCertificateDetailsActions } from '../state/certificateDetailsActions/api';
import { openCertificateModal, setUpdateUuid } from '../state/modal/slice';
import { CertificateDetailsModalEnum } from '../state/modal/types';
import { CertificateActionsEnum } from '../types';

const dropdownItemStyles = {
  dropdownDeleteItemStyle: {
    color: `${ERROR.LIGHT}`,
  },
};

interface UseCertificateDetailsDropdownType {
  showDropdown: boolean;
  options: ActionDropdownOption[];
}

export const useCertificateDetailsDropdown = (
  certificateUuid: string,
  supplierId: string
): UseCertificateDetailsDropdownType => {
  const [t] = useTranslation('certificateDetails');
  const { certificateDetailsActions } =
    useGetCertificateDetailsActions(certificateUuid);
  const { certificateDetails } = useGetCertificateDetailsQuery({
    certificateUuid,
  });
  const { activeLock, exceptionalCircumstance } =
    useCertificateInfoBoxData(certificateDetails);
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const isAdmin = useSelector(selectUserIsAdmin);

  const openCertificateDraftUpdatePage = () => {
    // TODO: once we get rid of iframe things should be removed
    const getPathname = isIframe
      ? YII_getCertificateDraftCreatePathname
      : getCertificateDraftCreatePathname;

    push(
      getPathname({
        supplierId,
        certificateId: certificateUuid,
      }),
      location.state
    );
  };

  const options: ActionDropdownOption[] = [
    {
      label: t('Reinstate certificate/letter of confirmation'),
      onClickAction: () => {
        dispatch(setUpdateUuid(activeLock?.uuid));
        dispatch(
          openCertificateModal({
            name: CertificateDetailsModalEnum.UNLOCK_CERTIFICATE,
          })
        );
      },
      permission: CertificateActionsEnum.CERTIFICATE_REINSTATE_ADD,
    },
    {
      label: t('Add exceptional circumstances'),
      onClickAction: () => {
        dispatch(
          openCertificateModal({
            name: CertificateDetailsModalEnum.ADD_EXCEPTIONAL_CIRCUMSTANCE,
          })
        );
      },
      permission:
        CertificateActionsEnum.CERTIFICATE_EXCEPTIONAL_CIRCUMSTANCE_ADD,
    },
    ...(exceptionalCircumstance?.uuid
      ? [
          {
            label: t('Update Exceptional Circumstance'),
            onClickAction: () => {
              const { description, type } = exceptionalCircumstance;

              dispatch(setUpdateUuid(exceptionalCircumstance.uuid));
              dispatch(
                openCertificateModal({
                  name: CertificateDetailsModalEnum.UPDATE_EXCEPTIONAL_CIRCUMSTANCE,
                  values: {
                    circumstanceDescription: description,
                    circumstanceType: type.id,
                  },
                })
              );
            },
            permission:
              CertificateActionsEnum.CERTIFICATE_EXCEPTIONAL_CIRCUMSTANCE_UPDATE,
          },
          {
            label: t('Remove Exceptional Circumstance'),
            onClickAction: () => {
              dispatch(setUpdateUuid(exceptionalCircumstance.uuid));
              dispatch(
                openCertificateModal({
                  name: CertificateDetailsModalEnum.CLOSE_EXCEPTIONAL_CIRCUMSTANCE,
                })
              );
            },
            permission:
              CertificateActionsEnum.CERTIFICATE_EXCEPTIONAL_CIRCUMSTANCE_CLOSE,
          },
        ]
      : []),
    {
      label: t('Add Supplier'),
      onClickAction: () => {
        dispatch(
          openCertificateModal({
            name: CertificateDetailsModalEnum.ADD_SUPPLIER,
          })
        );
      },
      permission: CertificateActionsEnum.CERTIFICATE_SUPPLIER_ADD,
    },
    {
      label: t('Add additional Audit'),
      onClickAction: () => {
        dispatch(
          openCertificateModal({
            name: CertificateDetailsModalEnum.ADD_AUDIT,
          })
        );
      },
      permission: CertificateActionsEnum.CERTIFICATE_AUDIT_ADD,
    },
    {
      label: t('Lock certificate/letter of confirmation'),
      onClickAction: () => {
        dispatch(
          openCertificateModal({
            name: CertificateDetailsModalEnum.LOCK_CERTIFICATE,
          })
        );
      },
      permission: CertificateActionsEnum.CERTIFICATE_LOCK,
    },
    {
      label: t('Update Certificate'),
      onClickAction: openCertificateDraftUpdatePage,
      permission: CertificateActionsEnum.CERTIFICATE_UPDATE,
    },
    {
      label: t('Switch CB'),
      onClickAction: () => {
        dispatch(
          openCertificateModal({
            name: CertificateDetailsModalEnum.SWITCH_CB_MODAL,
          })
        );
      },
      permission: CertificateActionsEnum.CERTIFICATE_CB_SWITCH,
    },
    ...(isAdmin
      ? [
          {
            label: t('Delete Group Certificate'),
            sx: dropdownItemStyles.dropdownDeleteItemStyle,
            onClickAction: () => {
              dispatch(
                openCertificateModal({
                  name: CertificateDetailsModalEnum.REMOVE_GROUP_CERTIFICATE,
                })
              );
            },
            permission:
              CertificateActionsEnum.CERTIFICATE_GROUP_CERTIFICATE_REMOVE,
          },
          {
            label: t('Delete Certificate'),
            sx: dropdownItemStyles.dropdownDeleteItemStyle,
            onClickAction: () => {
              dispatch(
                openCertificateModal({
                  name: CertificateDetailsModalEnum.REMOVE_CERTIFICATE,
                })
              );
            },
            permission: CertificateActionsEnum.CERTIFICATE_REMOVE,
          },
        ]
      : []),
  ].filter(({ permission }) => certificateDetailsActions.includes(permission));

  const showDropdown = options.length > 0;

  return {
    options,
    showDropdown,
  };
};
