import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button';
import DropdownMenu from 'components/molecules/DropdownMenu';

import { ActionsDropdownProps } from './types';

export const ActionsDropdown: FC<ActionsDropdownProps> = (props) => {
  const { className, options, showDropdown } = props;
  const [t] = useTranslation('components');

  return showDropdown ? (
    <DropdownMenu
      className={className}
      options={options}
      renderOpenButton={({ onClick }) => (
        <Button
          variant="contained"
          color="info"
          onClick={onClick}
          size="small"
          endIcon={<KeyboardArrowDownIcon />}
        >
          {t('Actions')}
        </Button>
      )}
    />
  ) : null;
};
