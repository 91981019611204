import { ActionReasonOption, SelectOption } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

interface UseActionReasonOptions {
  options: SelectOption[];
  optionsWithRequiredDescription: string[];
}

export const useActionReasonOptions = (
  rawOptions: ActionReasonOption[]
): UseActionReasonOptions => {
  const optionsWithRequiredDescription: string[] = [];
  const preparedActionReasonOptions = rawOptions.map(
    ({ id: value, text, requiredDescription }) => {
      if (requiredDescription) {
        optionsWithRequiredDescription.push(value);
      }

      return {
        label: getTranslatedValue(text),
        value,
      };
    }
  );

  return {
    options: preparedActionReasonOptions,
    optionsWithRequiredDescription,
  };
};
