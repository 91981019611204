import { Card, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ModalFooterLoader } from 'components/atoms/ModalFooterLoader';
import { ModalTitleWithDetails } from 'components/atoms/ModalTitleWithDetails';
import { TemplateAuditModal } from 'components/templates/TemplateAuditModal';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { AUDITS_ASSESSMENTS_TAB_SLUG } from 'config/constants';
import { FileUploadAbortModal } from 'domains/auditFileUpload/components/organisms/FileUploadAbortModal';
import {
  useCreateAuditDraftViaAXPXFileMutation,
  useCreateAuditDraftViaFileMutation,
} from 'domains/auditFileUpload/state/regularUpload/api';
import {
  getSupplierDetails,
  selectSupplierDetails,
} from 'domains/supplier/state/details/slice';
import { useAbortModal } from 'hooks/useAbortModal';
import { AuditDetailsURLParams } from 'types';
import { getAuditDetailsPathname } from 'utils/getAuditDetailsPathname';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';
import { isAnyLoading } from 'utils/isAnyLoading';

import { AuditAmendmentFileUploadForm } from '../AuditAmendmentFileUploadForm';

const PageWrapper = styled('div')`
  padding: 40px;
`;
const StyledCard = styled(Card)`
  max-width: 980px;
  margin: 0 auto;

  .MuiDialogActions-root {
    padding-top: 16px;
  }
`;

export const WidgetAuditFileUploadAmendmentUpload: FC = () => {
  const [t] = useTranslation('auditFileUpload');
  const { supplierId, auditId } = useParams<AuditDetailsURLParams>();
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const selectedSupplier = useSelector(selectSupplierDetails);
  const titleDetails = getSupplierDetailsTitle(selectedSupplier);
  const title = (
    <ModalTitleWithDetails
      title={t('Audit/assessment update')}
      details={titleDetails}
    />
  );
  const abortModalTitle = (
    <ModalTitleWithDetails
      title={t('Audit/assessment update')}
      details={titleDetails}
    />
  );
  const { status: createAuditDraftViaFileStatus } =
    useCreateAuditDraftViaFileMutation(true);
  const { status: createAuditDraftViaAXPXFileStatus } =
    useCreateAuditDraftViaAXPXFileMutation(true);
  const isLoading = isAnyLoading(
    createAuditDraftViaFileStatus,
    createAuditDraftViaAXPXFileStatus
  );
  const buttonLoader = isLoading ? <ModalFooterLoader /> : null;
  const { isAbortModalOpen, openAbortModal, closeAbortModal } = useAbortModal();
  const handleAbortAction = () =>
    push(
      getAuditDetailsPathname(supplierId, auditId, AUDITS_ASSESSMENTS_TAB_SLUG),
      location.state
    );

  useEffect(() => {
    if (!selectedSupplier) {
      dispatch(getSupplierDetails({ supplierId }));
    }
  }, [selectedSupplier, supplierId, dispatch]);

  return (
    <TemplatePageDefault hidePageHeader hideAside hideHeader hideSupport>
      <FileUploadAbortModal
        open={isAbortModalOpen}
        title={abortModalTitle}
        continueAction={closeAbortModal}
        abortAction={handleAbortAction}
      />
      <PageWrapper>
        <StyledCard>
          <TemplateAuditModal
            title={title}
            contentComponent={
              <AuditAmendmentFileUploadForm
                supplierId={supplierId}
                auditId={auditId}
              />
            }
            closeAction={openAbortModal}
            showCloseButton={!isLoading}
            nextButtonComponent={buttonLoader}
          />
        </StyledCard>
      </PageWrapper>
    </TemplatePageDefault>
  );
};
