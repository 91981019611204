import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { forceMockHeader } from 'mock/constants';
import { apiSlice } from 'store/apiSlice';

import endpoints from '../../config/endpoints';

const auditDetailsIssueGroupCertificateApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    issueGroupCertificate: build.mutation<void, string>({
      query: (auditUuid) => ({
        url: endpoints.ISSUE_GROUP_CERTIFICATE(auditUuid),
        method: 'PUT',
        headers: forceMockHeader,
      }),
      invalidatesTags: [
        'AUDIT_DETAILS',
        'AUDIT_DETAILS_TEMPLATE',
        'AUDIT_DETAILS_ACTIONS',
      ],
    }),
  }),
});

export const useIssueGroupCertificateMutation = () => {
  const [issueGroupCertificate, mutation] =
    auditDetailsIssueGroupCertificateApi.useIssueGroupCertificateMutation();
  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (auditUuid: string) =>
    issueGroupCertificate(auditUuid).unwrap();

  return {
    ...mutation,
    issueGroupCertificate: requestMethod,
    status,
    error,
  };
};
