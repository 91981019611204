import { styled } from '@mui/material';
import React, { FC } from 'react';

import { DateRangePickerWithCalendar } from 'components/atoms/DateRangePicker';
import { TEXT, WHITE } from 'config/appColors';
import { Nullable } from 'types';
import { formatDate } from 'utils/dates';

import { DateRangeFilter, UseFiltersAndSorting } from '../../../types';
import { commonInputStyles } from '../../atoms/StyledTextField';

const StyledContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledDateRangePickerWithCalendar = styled(DateRangePickerWithCalendar)`
  ${commonInputStyles};

  input {
    padding-right: 0;
  }

  .MuiInputBase-root {
    background: ${WHITE};
  }

  .MuiInputBase-root {
    padding-right: 0;
  }

  svg {
    color: ${TEXT.SECONDARY_LIGHT};
  }
`;

type DateRangeFilterCellProps = DateRangeFilter & UseFiltersAndSorting;

export const DateRangeFilterCell: FC<DateRangeFilterCellProps> = (props) => {
  const { startFilterKey, endFilterKey, filters, setFilterValue } = props;

  // TODO: once we introduce TS 5.5.X (PL4FE-449) we can change this code to
  // const getDateValue = (key:string)=> typeof filters[key] === 'string' ? filters[key] : ''
  const getDateValue = (key: string) => {
    const val = filters[key];
    return typeof val === 'string' ? val : '';
  };

  const startDate = getDateValue(startFilterKey);
  const endDate = getDateValue(endFilterKey);

  const handleDateChange = (filterKey: string) => (date: Nullable<Date>) => {
    const value = formatDate(date);
    setFilterValue({ filterKey, value });
  };

  return (
    <StyledContainer>
      <StyledDateRangePickerWithCalendar
        startDate={startDate}
        onStartDateChange={handleDateChange(startFilterKey)}
        endDate={endDate}
        onEndDateChange={handleDateChange(endFilterKey)}
      />
    </StyledContainer>
  );
};
