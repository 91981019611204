import routes from 'config/routes';

import { UUID_V4 } from '../regex/uuid';

interface GetCertificateDraftFormPathnameParams {
  supplierId: string;
  certificateDraftId: string;
}

export const getCertificateDraftFormPathname = ({
  supplierId,
  certificateDraftId,
}: GetCertificateDraftFormPathnameParams): string =>
  routes.CERTIFICATE_DRAFT_FORM.replace(
    `:supplierId(${UUID_V4})`,
    supplierId
  ).replace(`:certificateDraftId(${UUID_V4})`, certificateDraftId);
