import React, { FC } from 'react';

import { AuditAppointmentKindEnum } from 'domains/auditAppointment/types';
import { Nullable, StartEndDates } from 'types';
import { renderDateRange } from 'utils/dates';

interface AppointmentsDateRangeCellProps {
  kind: AuditAppointmentKindEnum;
  dueDate: Nullable<StartEndDates>;
  unannouncedPeriod: Nullable<StartEndDates>;
}

export const AppointmentsDateRangeCell: FC<AppointmentsDateRangeCellProps> = ({
  kind,
  dueDate,
  unannouncedPeriod,
}) => {
  const finalDate =
    kind === AuditAppointmentKindEnum.ANNOUNCED ? dueDate : unannouncedPeriod;
  const dateRangeToRender = finalDate
    ? renderDateRange(finalDate.start, finalDate.end)
    : '';

  return <>{dateRangeToRender}</>;
};
