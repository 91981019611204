import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { CertificateDetailsTemplateResponse } from './types';
import endpoints from '../../config/endpoints';

const certificateDetailsTemplateApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateDetailsTemplate: build.query<
      CertificateDetailsTemplateResponse,
      string
    >({
      query: (certificateUuid: string) => ({
        url: endpoints.TEMPLATE(certificateUuid),
      }),
      providesTags: ['CERTIFICATE_DETAILS_TEMPLATE'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetCertificateDetailsTemplateQuery = (
  certificateUuid: string,
  skip = false
) => {
  const query =
    certificateDetailsTemplateApi.useGetCertificateDetailsTemplateQuery(
      certificateUuid,
      { skip }
    );
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    certificateDetailsTemplate: query.data,
    error,
    status,
  };
};
