import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useActionInformationData } from 'components/organisms/ActionInformationSection';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import {
  deleteBlackoutTime,
  selectDeleteBlackoutTimeLoading,
} from 'domains/auditAppointment/state/deleteBlackoutTime/slice';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { LoaderStatusEnum, ModalProps } from 'types';

import { DeleteBlackoutTimeParams } from '../../../state/deleteBlackoutTime/types';
import { BlackoutTime } from '../../../types';
import { AuditAppointmentBaseModal } from '../AuditAppointmentBaseModal';
import { DeleteBlackoutTimeModalContent } from '../DeleteBlackoutTimeModalContent';

interface DeleteBlackoutTimeModalProps extends ModalProps {
  appointmentId: string;
  blackoutTime?: BlackoutTime;
}

export const DeleteBlackoutTimeModal: FC<DeleteBlackoutTimeModalProps> = (
  props
) => {
  const { open, handleClose, appointmentId, blackoutTime } = props;
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const loadingStatus = useSelector(selectDeleteBlackoutTimeLoading);
  const isAdmin = useSelector(selectUserIsAdmin);

  const {
    isAdminInfoValid,
    setIsAdminInfoValid,
    adminReason,
    adminComment,
    handleAdminReasonChange,
    handleAdminCommentChange,
  } = useActionInformationData();

  const nextAction = () => {
    if (appointmentId && blackoutTime) {
      const actionComment = { reason: adminReason, comment: adminComment };
      const data: DeleteBlackoutTimeParams = {
        appointmentId,
        blackoutTimeId: blackoutTime.uuid,
        actionComment: isAdminInfoValid ? actionComment : undefined,
      };
      dispatch(deleteBlackoutTime(data));
    }
  };

  return (
    <AuditAppointmentBaseModal open={open}>
      <TemplateAuditModal
        title={t('Delete blackout period')}
        contentComponent={
          <DeleteBlackoutTimeModalContent
            blackoutTime={blackoutTime}
            isAdmin={isAdmin}
            setIsAdminInfoValid={setIsAdminInfoValid}
            adminReason={adminReason}
            adminComment={adminComment}
            adminReasonChangeHandler={handleAdminReasonChange}
            adminCommentChangeHandler={handleAdminCommentChange}
          />
        }
        closeAction={handleClose}
        prevAction={handleClose}
        nextAction={nextAction}
        noPrevArrow
        noNextArrow
        prevLabel={t('Cancel')}
        nextLabel={t('Delete', { ns: 'components' })}
        prevColor="modalPrevButton"
        nextColor="error"
        disableNext={isAdmin && !isAdminInfoValid}
        nextButtonLoading={loadingStatus === LoaderStatusEnum.LOADING}
        hidePrevButton={loadingStatus === LoaderStatusEnum.LOADING}
      />
    </AuditAppointmentBaseModal>
  );
};

export default DeleteBlackoutTimeModal;
