import { LANG_PARAM } from 'config/languages';

export const endpoints = {
  APPOINTMENTS: (auditDraftId: string): string =>
    `/${LANG_PARAM}/audit-drafts/${auditDraftId}/appointments`,
  CONFIRMATIONS: (auditDraftId: string): string =>
    `/${LANG_PARAM}/audit-drafts/${auditDraftId}/confirmations`,
  CREATE_AUDIT_DRAFT_VIA_EXISTING_AUDIT: `/${LANG_PARAM}/audit-drafts/create-by-existing-audit`,
  DATA: {
    ADD: (auditDraftId: string): string =>
      `/${LANG_PARAM}/audit-drafts/${auditDraftId}/data`,
  },
  DETAILS: (auditDraftId: string): string =>
    `/${LANG_PARAM}/audit-drafts/${auditDraftId}`,
  DOCUMENT_DEFINITIONS: (auditDraftId: string): string =>
    `/${LANG_PARAM}/audit-drafts/${auditDraftId}/document-definitions`,
  DOCUMENT_MANAGE: {
    ADD_DOCUMENT: (auditDraftId: string): string =>
      `/${LANG_PARAM}/audit-drafts/${auditDraftId}/documents`,
    DELETE_DOCUMENT: (auditDraftId: string, documentId: string): string =>
      `/${LANG_PARAM}/audit-drafts/${auditDraftId}/documents/${documentId}`,
  },
  SET_ACTION_COMMENT: (auditDraftId: string): string =>
    `/${LANG_PARAM}/audit-drafts/${auditDraftId}/action-comments`,
  SET_COMMENT: (auditDraftId: string): string =>
    `/${LANG_PARAM}/audit-drafts/${auditDraftId}/comments`,
  SUBMIT: (auditDraftId: string): string =>
    `/${LANG_PARAM}/audit-drafts/${auditDraftId}/submit`,
  TEMPLATE: (auditDraftId: string): string =>
    `/${LANG_PARAM}/audit-drafts/${auditDraftId}/templates`,
  SET_REASON: (auditDraftId: string): string =>
    `/${LANG_PARAM}/audit-drafts/${auditDraftId}/update-reasons`,
};
