import { alpha, styled } from '@mui/material';
import React, { FC, ReactElement } from 'react';

import { ErrorBoundary } from 'components/molecules/ErrorBoundary';
import { PageHeader } from 'components/molecules/PageHeader';
import { SupportLink } from 'components/molecules/SupportLink';
import { AppTopbar } from 'components/organisms/AppTopbar';
import { SidebarMenu } from 'components/organisms/SidebarMenu';
import { BLACK, MOSTLY_WHITE_GRAY } from 'config/appColors';


import {
  StyledAsideWrapperProps,
  StyledContentProps,
  StyledPageHeaderWrapperProps,
  TemplatePageDefaultProps,
} from './types';

export const ASIDE_WIDTH = 250;
export const HEADER_HEIGHT = 55;
const SUPPORT_HEIGHT = 160;

const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
`;

const StyledContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledHeaderWrapper = styled('div')`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  & > * {
    width: 100%;
    height: 100%;
  }
`;

const StyledAsideWrapper = styled('aside')<StyledAsideWrapperProps>`
  display: flex;
  position: fixed;
  top: ${({ $hideHeader }) => ($hideHeader ? 0 : `${HEADER_HEIGHT}px`)};
  left: 0;
  bottom: ${({ $hideSupport }) => ($hideSupport ? 0 : `${SUPPORT_HEIGHT}px`)};
  width: ${ASIDE_WIDTH}px;
  overflow: auto;
  margin-left: 0;
  box-shadow: inset -1px 0 0 0 ${alpha(BLACK, 0.05)};

  & > div {
    width: 100%;
  }
`;

const StyledSupport = styled('div')`
  position: fixed;
  left: 0;
  bottom: 0;
  width: ${ASIDE_WIDTH}px;
  height: ${SUPPORT_HEIGHT}px;
  margin-left: 0;
  box-shadow: inset -1px 1px 0 0 ${alpha(BLACK, 0.05)};

  & > * {
    width: 100%;
    height: 100%;
  }
`;

const StyledPageHeaderWrapper = styled('div')<StyledPageHeaderWrapperProps>`
  width: ${({ $hideAside }) =>
    $hideAside ? '100%' : `calc(100% - ${ASIDE_WIDTH}px)`};
  margin-left: ${({ $hideAside }) => ($hideAside ? 0 : `${ASIDE_WIDTH}px`)};
  margin-top: ${({ $hideHeader }) => ($hideHeader ? 0 : `${HEADER_HEIGHT}px`)};
  min-height: 0;
  background: ${MOSTLY_WHITE_GRAY};
`;

const StyledContent = styled('div')<StyledContentProps>`
  width: ${({ $hideAside }) =>
    $hideAside ? '100%' : `calc(100% - ${ASIDE_WIDTH}px)`};
  margin-top: ${({ $hideHeader, $hidePageHeader }) =>
    $hideHeader || !$hidePageHeader ? 0 : `${HEADER_HEIGHT}px`};
  margin-left: ${({ $hideAside }) => ($hideAside ? 0 : `${ASIDE_WIDTH}px`)};
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  background: ${MOSTLY_WHITE_GRAY};
  position: relative;

  & > * {
    width: 100%;
  }
`;

export const TemplatePageDefault: FC<TemplatePageDefaultProps> = (
  props
): ReactElement => {
  const {
    children,
    header,
    aside,
    support,
    titleComponent,
    pageTitle,
    hideAside,
    hideHeader,
    hideSupport,
    hidePageHeader,
    hideBreadcrumbs,
  } = props;

  return (
    <StyledWrapper>
      {!hideHeader && (
        <StyledHeaderWrapper>{header || <AppTopbar />}</StyledHeaderWrapper>
      )}
      <StyledContentWrapper>
        {!hideAside && (
          <StyledAsideWrapper
            $hideSupport={hideSupport}
            $hideHeader={hideHeader}
          >
            {aside || <SidebarMenu />}
          </StyledAsideWrapper>
        )}
        {!hidePageHeader && (
          <StyledPageHeaderWrapper
            $hideHeader={hideHeader}
            $hideAside={hideAside}
          >
            <PageHeader
              title={pageTitle}
              titleComponent={titleComponent}
              hideBreadcrumbs={hideBreadcrumbs}
            />
          </StyledPageHeaderWrapper>
        )}
        <StyledContent
          $hideHeader={hideHeader}
          $hideAside={hideAside}
          $hidePageHeader={hidePageHeader}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </StyledContent>
        {!hideSupport && (
          <StyledSupport>{support || <SupportLink />}</StyledSupport>
        )}
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

export default TemplatePageDefault;
