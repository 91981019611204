import iframeRoutes from 'domains/widgets/config/routes';
import { UUID_V4 } from 'utils/regex/uuid';

export const getAuditDraftInitUpdateDocsPathname = (
  supplierId: string,
  auditId: string
): string =>
  iframeRoutes.AUDIT_DRAFT_UPDATE_DOCUMENTS_INIT.replace(
    `:supplierId(${UUID_V4})`,
    supplierId
  ).replace(`:auditId(${UUID_V4})`, auditId);
