import get from 'lodash/get';
import React, { FC } from 'react';

import { CertificationBody } from 'types';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

const isCertificationBody = (cb: unknown): cb is CertificationBody =>
  !!cb &&
  'uuid' in (cb as CertificationBody) &&
  'name' in (cb as CertificationBody);

interface AttributeRowCertificationBodyProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
  //   children: null; // TBD it might be used in the future
}

export const AttributeRowCertificationBody: FC<AttributeRowCertificationBodyProps> =
  (props) => {
    const { data, dataRef, labelText } = props;
    const value = get(data, dataRef);
    const showAttributeRow = isCertificationBody(value);

    if (!showAttributeRow) {
      return (
        <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong CertificationBody data" />
      );
    }

    const valueToDisplay = `${value.name} ${value.legalForm || ''}`;

    return <AttributeRowBase label={labelText} value={valueToDisplay} />;
  };
