import { styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'components/atoms/Checkbox';
import { RadioGroup } from 'components/molecules/RadioGroup';
import { FieldIdentifier } from 'types';

import {
  ContentCheckboxValues,
  ContentOption,
} from '../../../hooks/useReleaseCommonFormFields';

interface ReleaseCommonFormFieldProps {
  contentOptions: ContentOption[];
  handleContentChange: (value: boolean, id: string) => void;
  contentCheckboxValues: ContentCheckboxValues;
  timeFrameOptions: FieldIdentifier[];
  timeFrameId: string;
  setTimeFrameId: Dispatch<SetStateAction<string>>;
}

const StyledRadioGroup = styled(RadioGroup)`
  margin-left: 11px;
`;

export const ReleaseCommonFormFields: FC<ReleaseCommonFormFieldProps> = (
  props
) => {
  const {
    contentOptions,
    handleContentChange,
    contentCheckboxValues,
    timeFrameId,
    timeFrameOptions,
    setTimeFrameId,
  } = props;
  const [t] = useTranslation('auditDetails');

  return (
    <>
      <p>{t('Audit content release')}:</p>
      {contentOptions.map(({ value, label, disabled }) => (
        <Checkbox
          key={value}
          onChange={(ev) => handleContentChange(ev.target.checked, value)}
          id={value}
          label={label}
          value={value}
          checked={!!contentCheckboxValues[value]} // don't simplify to avoid controlled/uncontrolled warning
          disabled={disabled}
        />
      ))}

      <p>{t('Release time frame')}:</p>
      <StyledRadioGroup
        value={timeFrameId}
        options={timeFrameOptions}
        onChange={setTimeFrameId}
      />
    </>
  );
};
