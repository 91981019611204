import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import MuiTableFooter from '@mui/material/TableFooter';
import MuiTableRow from '@mui/material/TableRow';
import React, { FC, MouseEvent as ReactMouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from './config';
import { ActionsContainer, StyledMuiTablePagination } from './Table.styled';
import { PaginationProps, TablePaginationActionsProps } from './types';

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const [t] = useTranslation('components');

  const handleClick =
    (newPage: number) => (event: ReactMouseEvent<HTMLButtonElement>) => {
      onPageChange(event, newPage);
    };

  const isPrevBtnDisabled = page === 0;
  const isNextBtnDisabled = page >= Math.ceil(count / rowsPerPage) - 1;

  return (
    <ActionsContainer>
      <IconButton
        onClick={handleClick(page - 1)}
        disabled={isPrevBtnDisabled}
        aria-label={t('previous page')}
        size="large"
      >
        <KeyboardArrowLeft fontSize="large" />
      </IconButton>
      <IconButton
        onClick={handleClick(page + 1)}
        disabled={isNextBtnDisabled}
        aria-label={t('next page')}
        size="large"
      >
        <KeyboardArrowRight fontSize="large" />
      </IconButton>
    </ActionsContainer>
  );
}

export const TablePagination: FC<PaginationProps> = (props) => {
  const {
    totalRowsAmount,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    outsideOfTable = false,
  } = props;

  const [t] = useTranslation('components');

  const pagination = (
    <StyledMuiTablePagination
      component={outsideOfTable ? 'div' : 'td'}
      rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
      colSpan={100}
      count={totalRowsAmount}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage={t('rows per page')}
      page={page}
      SelectProps={{
        inputProps: {
          'aria-label': t('rows per page'),
          'data-testid': 'rows-per-page',
        },
      }}
      onPageChange={(_, newPage) => {
        onPageChange(newPage);
      }}
      onRowsPerPageChange={(event) => {
        const newRowsPerPage = Number(event.target.value);
        onPageChange(0); // to avoid page that gives results out of range
        onRowsPerPageChange(newRowsPerPage);
      }}
      ActionsComponent={TablePaginationActions}
    />
  );

  if (outsideOfTable) {
    return pagination;
  }

  return (
    <MuiTableFooter>
      <MuiTableRow>{pagination}</MuiTableRow>
    </MuiTableFooter>
  );
};
