import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TableCellColorVariant,
  TableCellIconName,
  TableCellWithIcon,
} from 'components/atoms/TableCellWithIcon';
import { CertificateLockEnum, isCertificateLock } from 'types';
import { isReinstate } from 'utils/isReinstate';

import { CertificateDetails } from '../../../types';

interface TableCellWithLockIconProps {
  certificate?: Pick<CertificateDetails, 'activeLock' | 'latestLock' | 'ceid'>;
  customLabel?: string;
  useTrimmedText?: boolean;
}

export const TableCellWithLockIcon: FC<TableCellWithLockIconProps> = ({
  certificate,
  useTrimmedText = true,
  customLabel = '',
}) => {
  const [t] = useTranslation('supplier');
  const { activeLock, latestLock, ceid = '' } = certificate || {};
  const isLocked = isCertificateLock(activeLock);
  const lockType = isLocked ? activeLock.type.id : undefined;
  const isReinstated = isReinstate(latestLock);
  const showLockIcon = isLocked || isReinstated;
  const variant: TableCellColorVariant = isReinstated ? 'light' : 'error';
  const iconName: TableCellIconName = isReinstated
    ? 'lockOpenOutlined'
    : 'lock';

  let title = '';

  if (isReinstated) {
    title = t('Reinstated');
  } else if (isLocked && lockType === CertificateLockEnum.SUSPENSION) {
    title = t('Suspended');
  } else if (isLocked && lockType === CertificateLockEnum.WITHDRAWAL) {
    title = t('Withdrawn');
  }

  return (
    <div title={title}>
      <TableCellWithIcon
        showLockIcon={showLockIcon}
        label={customLabel || ceid}
        iconName={iconName}
        variant={variant}
        useTrimmedText={useTrimmedText}
      />
    </div>
  );
};
