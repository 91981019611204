import { alpha, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StepFormTitle } from 'components/atoms/StepFormTitle';
import Loader from 'components/molecules/Loader';
import { BLACK, ERROR } from 'config/appColors';
import { DocumentTable } from 'domains/auditDraft/components/organisms/DocumentTable';
import { UploadDocDefinitionButton } from 'domains/auditDraft/components/organisms/UploadDocDefinitionButton';
import { useCertificateDraftDetailsQuery } from 'domains/certificateDraft/state/certificateDraftDetails/api';
import { useGetCertificateDraftDocumentDefinitionsQuery } from 'domains/certificateDraft/state/documentDefinitions/api';
import {
  useAddDocumentsMutation,
  useDeleteDocumentMutation,
} from 'domains/certificateDraft/state/documentManage/api';
import { FormDocumentUploadStep } from 'domains/certificateDraft/types';
import { DocumentDefinition, LoaderStatusEnum, Nullable } from 'types';
import convertFileToBase64 from 'utils/convertFileToBase64';
import { isAnyError } from 'utils/isAnyError';

import { TemplateCertificateDraftStep } from '../../templates/TemplateCertificateDraftStep';

const StyledStepContent = styled('div')`
  margin: 0 10px;
`;

const StyledDocDefinitionWrapper = styled('div')`
  width: 100%;
`;
const StyledDocDefinitionItem = styled('div')`
  margin-bottom: 23px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
const StyledUploadHeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  border-bottom: 1px ${alpha(BLACK, 0.23)} solid;

  > div:last-of-type {
    margin-left: auto;
    align-self: flex-end;
  }
`;
const StyledMissingDocument = styled('span')`
  margin: 0 10px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.017rem;
  color: ${ERROR.LIGHT};
`;
const StyledUploadDocDefinitionButton = styled(UploadDocDefinitionButton)`
  align-self: flex-end;
`;

type FormDocumentUploadProps = FormDocumentUploadStep & {
  certificateDraftId: string;
};

export const FormDocumentUpload: FC<FormDocumentUploadProps> = ({
  props,
  certificateDraftId,
}) => {
  const { backAction, nextAction } = props;
  // TODO: review the translation when draft domains are migrating to a single one
  const [t] = useTranslation('auditDraft');

  const {
    certificateDraftDetails,
    error: draftDetailsError,
    status: draftDetailsStatus,
    isLoading: isCertificateDraftLoading,
    isFetching: isCertificateDraftFetching,
  } = useCertificateDraftDetailsQuery({ certificateDraftId });
  const {
    allRequiredDocumentsFulfilled,
    documentDefinitions,
    error: documentDefinitionsError,
    status: documentDefinitionsStatus,
    isLoading: isDocDefLoading,
    isFetching: isDocDefFetching,
  } = useGetCertificateDraftDocumentDefinitionsQuery({ certificateDraftId });
  const { addDocuments, error: addDocumentsError } = useAddDocumentsMutation();
  const {
    deleteDocument,
    error: documentDeleteError,
    status: deleteDocumentStatus,
    reset: resetDeleteDocumentMutation,
  } = useDeleteDocumentMutation();
  const [uploadingDocDefinitions, setUploadingDocDefinitions] = useState<
    string[]
  >([]);
  const [processingDocDefId, setProcessingDocDefId] =
    useState<Nullable<string>>(null);
  const disableNextBtn =
    !allRequiredDocumentsFulfilled ||
    documentDefinitionsStatus !== LoaderStatusEnum.SUCCESS;

  const errorAlerts =
    draftDetailsError || documentDefinitionsError || addDocumentsError;
  const isCriticalError = isAnyError(
    draftDetailsStatus,
    documentDefinitionsStatus
  );

  const prepareFiles = async (files: File[], displayName?: string) =>
    Promise.all(
      files.map(async (file) => ({
        file: await convertFileToBase64(file),
        fileName: file.name,
        displayName, // currently isn't prepared for multiple naming
      }))
    );

  const onFilesChange = async (
    rawFiles: File[],
    docDefinition: DocumentDefinition,
    language: Nullable<string>,
    displayName?: string
  ) => {
    if (rawFiles.length > 0) {
      const docDefinitionId = docDefinition.id;
      setUploadingDocDefinitions((prev) => [...prev, docDefinitionId]);
      const files = await prepareFiles(rawFiles, displayName);

      return addDocuments({
        certificateDraftId,
        docDefinitionId,
        language,
        files,
      }).then(() => {
        setUploadingDocDefinitions((prev) =>
          prev.filter((uploadingDocId) => uploadingDocId !== docDefinitionId)
        );
        setProcessingDocDefId(docDefinitionId);
      });
    }

    return Promise.resolve();
  };

  const onFileDelete = (docDefId: string) => (documentId: string) =>
    deleteDocument({ certificateDraftId, documentId }).then(() => {
      setProcessingDocDefId(docDefId);
    });
  const getDocumentsGroupedByDocDef = (id: string) =>
    (certificateDraftDetails?.documents || []).filter(
      ({ docDefinitionId }) => docDefinitionId === id
    );

  const mainLoaderStatus =
    isCertificateDraftLoading || isDocDefLoading
      ? LoaderStatusEnum.LOADING
      : LoaderStatusEnum.SUCCESS;

  useEffect(() => {
    if (
      !isCertificateDraftFetching &&
      !isDocDefFetching &&
      processingDocDefId
    ) {
      setProcessingDocDefId(null);
    }
  }, [isCertificateDraftFetching, isDocDefFetching, processingDocDefId]);

  return (
    <TemplateCertificateDraftStep
      backAction={backAction}
      nextAction={nextAction}
      error={errorAlerts}
      disableNext={disableNextBtn}
    >
      <StyledStepContent>
        {!isCriticalError && (
          <>
            <Loader status={mainLoaderStatus} minHeight="150px">
              <StyledDocDefinitionWrapper>
                {documentDefinitions.map((docDef: DocumentDefinition) => {
                  const { id, type, isRequired, isUploadable } = docDef;
                  const isUploading = uploadingDocDefinitions.includes(id);
                  const documents = getDocumentsGroupedByDocDef(id);

                  if (!isUploadable && !documents.length && !isDocDefFetching) {
                    // hide non-uploadable & empty docs
                    return null;
                  }

                  return (
                    <StyledDocDefinitionItem key={id}>
                      <StyledUploadHeaderContainer>
                        <StepFormTitle content={type.name} variant="h4" />
                        {isRequired && (
                          <StyledMissingDocument>
                            {t('Document missing')}
                          </StyledMissingDocument>
                        )}
                        <StyledUploadDocDefinitionButton
                          docDefinition={docDef}
                          onFilesChange={onFilesChange}
                          isUploading={isUploading}
                          isRefetchingDocs={id === processingDocDefId}
                          disabled={!isUploadable}
                        />
                      </StyledUploadHeaderContainer>
                      <DocumentTable
                        documents={documents}
                        documentsLoadingStatus={LoaderStatusEnum.SUCCESS}
                        fileDeleteStatus={deleteDocumentStatus}
                        fileDeleteAlertsState={documentDeleteError}
                        onFileDelete={onFileDelete(id)}
                        resetDeleteDocumentMutation={
                          resetDeleteDocumentMutation
                        }
                      />
                    </StyledDocDefinitionItem>
                  );
                })}
              </StyledDocDefinitionWrapper>
            </Loader>
          </>
        )}
      </StyledStepContent>
    </TemplateCertificateDraftStep>
  );
};
