import routes from 'config/routes';
import { TARGET_AUDIT_SEARCH } from 'utils/getAuditDraftFormPathname';

import { UUID_V4 } from '../regex/uuid';

export const getAuditDraftUpdateDocsPathname = (
  supplierId: string,
  auditDraftId: string,
  targetAuditId: string
): string => {
  const pathname = routes.AUDIT_DRAFT_UPDATE_DOCUMENTS.replace(
    `:supplierId(${UUID_V4})`,
    supplierId
  ).replace(`:auditDraftId(${UUID_V4})`, auditDraftId);

  return `${pathname}?${TARGET_AUDIT_SEARCH}=${targetAuditId}`;
};
