import { isAfter, isBefore } from 'date-fns';
import React, { FC } from 'react';

import { PLAIN_DATE_REGEX_WITH_DOTS } from 'utils/regex/date';

import { getDate } from './utils';
import { DateMaskedInput } from '../DateMaskedInput';

interface DateRangePickerProps {
  startDate: string;
  endDate: string;
  onClick?: () => void;
  onStartChange: (date: string) => void;
  onEndChange: (date: string) => void;
}

export const DateRangePicker: FC<DateRangePickerProps> = (props) => {
  const { endDate, startDate, onClick, onEndChange, onStartChange } = props;

  const handleDateChange =
    (
      dateValidator: typeof isBefore,
      dateToCompare: string,
      onChange: (date: string) => void
    ) =>
    (date: string) => {
      if (date !== '' && !PLAIN_DATE_REGEX_WITH_DOTS.test(date)) return;

      const skipValidation = dateToCompare === '' || date === '';
      const isNewDateValid =
        skipValidation || dateValidator(getDate(date), getDate(dateToCompare));
      const value = isNewDateValid ? date : '';

      onChange(value);
    };

  const handleStartDateChange = handleDateChange(
    isBefore,
    endDate,
    onStartChange
  );

  const handleEndDateChange = handleDateChange(isAfter, startDate, onEndChange);

  return (
    <>
      <DateMaskedInput
        value={startDate}
        onChange={handleStartDateChange}
        onClick={onClick}
        showCalendarIcon
      />
      -
      <DateMaskedInput
        value={endDate}
        onChange={handleEndDateChange}
        onClick={onClick}
        showCalendarIcon
      />
    </>
  );
};
