import { styled } from '@mui/material';
import React, { FC } from 'react';

import { TemplateBasicPageContent } from 'domains/supplier/components/templates/TemplateBasicPageContent';
import { LoaderStatusEnum } from 'types';

const StyledTemplateBasicPageContent = styled(TemplateBasicPageContent)`
  margin-top: -40px;
`;

export const GeneralAuditsTab: FC = () => (
  <StyledTemplateBasicPageContent
    customTitleComponent="audits"
    loaderStatus={LoaderStatusEnum.SUCCESS}
    minHeight="230px"
  >
    <div style={{ minHeight: '500px', padding: '20px' }}>
      general audits page
    </div>
  </StyledTemplateBasicPageContent>
);
