import React, { FC } from 'react';

import { CheckboxFilterCell } from './CheckboxFilterCell';
import { DateFilterCell } from './DateFilterCell';
import { DateRangeFilterCell } from './DateRangeFilterCell';
import { MultiSelectFilterCell } from './MultiSelectFilterCell';
import { TextFieldFilterCell } from './TextFieldFilterCell';
import {
  FilterTypeEnum,
  TableFilter,
  UseFiltersAndSorting,
} from '../../../types';

type FilterCellProps = TableFilter & UseFiltersAndSorting;

export const FilterCell: FC<FilterCellProps> = (props) => {
  const { type } = props;

  switch (type) {
    case FilterTypeEnum.CHECKBOX: {
      return <CheckboxFilterCell {...props} />;
    }
    case FilterTypeEnum.TEXT: {
      return <TextFieldFilterCell {...props} />;
    }
    case FilterTypeEnum.DATE: {
      return <DateFilterCell {...props} />;
    }
    case FilterTypeEnum.DATE_RANGE: {
      return <DateRangeFilterCell {...props} />;
    }
    case FilterTypeEnum.MULTISELECT: {
      return <MultiSelectFilterCell {...props} />;
    }
    default: {
      return null;
    }
  }
};
