import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuditAppointmentStepEnum } from 'domains/auditAppointment/types';
import type { RootState } from 'store';
import { LoaderStatusEnum } from 'types';

import { AuditAppointmentStepperModalState } from './types';
import { parentReducerName } from '../../config/constants';

const initialState: AuditAppointmentStepperModalState = {
  currentStep: AuditAppointmentStepEnum.MODULE,
  isModalOpen: false,
  isNextButtonDisabled: true,
  isPreviousButtonDisabled: false,
};

const reducerName = `${parentReducerName}/stepperModal`;

// slice
export const stepperModalSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setCurrentStep: (
      state,
      action: PayloadAction<AuditAppointmentStepEnum>
    ) => {
      state.currentStep = action.payload;
    },
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    setIsNextButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.isNextButtonDisabled = action.payload;
    },
    setIsPreviousButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.isNextButtonDisabled = action.payload;
    },
    resetModalState: () => initialState,
  },
});

// actions
export const {
  setCurrentStep,
  setIsModalOpen,
  resetModalState,
  setIsNextButtonDisabled,
  setIsPreviousButtonDisabled,
} = stepperModalSlice.actions;

// selectors
export const selectCurrentStep = (state: RootState): AuditAppointmentStepEnum =>
  state[parentReducerName].stepperModal.currentStep;
export const selectIsModalOpen = (state: RootState): boolean =>
  state[parentReducerName].stepperModal.isModalOpen;
export const selectIsNextButtonDisabled = (state: RootState): boolean =>
  state[parentReducerName].stepperModal.isNextButtonDisabled ||
  state.auditAppointment.formOptions.loading !== LoaderStatusEnum.SUCCESS;
export const selectIsPrevButtonDisabled = (state: RootState): boolean => {
  const {
    loading,
    times: { start, end },
  } = state[parentReducerName].formOptions;
  const loadingStatus = LoaderStatusEnum.LOADING;
  return (
    loading === loadingStatus ||
    start.loading === loadingStatus ||
    end.loading === loadingStatus ||
    state[parentReducerName].create.loading === loadingStatus
  );
};
export const selectIsPreviousButtonDisabled = (state: RootState): boolean =>
  state[parentReducerName].stepperModal.isPreviousButtonDisabled;

// reducer
export default stepperModalSlice.reducer;
