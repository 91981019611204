import { useTranslation } from 'react-i18next';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryReset } from 'hooks/useRTKQueryReset';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { apiSlice, invalidateTags } from 'store/apiSlice';
import { createErrorAlertState } from 'utils/createAlertsState';
import { formatToAlerts } from 'utils/formatToAlerts';
import { getBaseQueryError } from 'utils/getBaseQueryError';
import { invalidateRTKTags } from 'utils/invalidateRTKTags';

import { AddMultipleDocumentsParams, DeleteDocumentParams } from './types';
import { endpoints } from '../../config/endpoints';

const documentManageApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    addCertificateDraftDocuments: build.mutation<
      unknown,
      AddMultipleDocumentsParams
    >({
      async queryFn(
        { docDefinitionId, files, language = null, certificateDraftId },
        { dispatch },
        extraOptions,
        baseQuery
      ) {
        const errorMessages = [];
        let someError = false;
        let someSucceed = false;

        for (let i = 0; i < files.length; i += 1) {
          const { fileName, displayName, file } = files[i];

          // intentionally send requests in sequential order
          // eslint-disable-next-line no-await-in-loop
          const res = await baseQuery({
            url: endpoints.DOCUMENT_MANAGE.ADD_DOCUMENT(certificateDraftId),
            method: 'PUT',
            data: {
              docDefinitionId,
              language,
              file,
              fileName,
              ...(displayName && { displayName }),
            },
          });

          if (res.error) {
            someError = true;
            errorMessages.push(...formatToAlerts(res.error));
          } else {
            someSucceed = true;
          }
        }

        if (someSucceed) {
          dispatch(
            invalidateTags([
              'CERTIFICATE_DRAFT_DETAILS',
              'CERTIFICATE_DRAFT_DOCUMENT_DEFINITIONS',
            ])
          );
        }

        if (someError) {
          return getBaseQueryError(errorMessages);
        }

        return { data: null };
      },
    }),
    deleteCertificateDraftDocument: build.mutation<
      unknown,
      DeleteDocumentParams
    >({
      query({ certificateDraftId, documentId }) {
        return {
          url: endpoints.DOCUMENT_MANAGE.DELETE_DOCUMENT(
            certificateDraftId,
            documentId
          ),
          method: 'DELETE',
        };
      },
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_DRAFT_DETAILS',
        'CERTIFICATE_DRAFT_DOCUMENT_DEFINITIONS',
      ]),
    }),
  }),
});

export const useAddDocumentsMutation = () => {
  const [addDocuments, mutation] =
    documentManageApi.useAddCertificateDraftDocumentsMutation({
      fixedCacheKey: 'add-certificate-draft-document',
    });
  const error = useFormattedError(mutation, 'formatToAlerts');
  const [t] = useTranslation('auditDraft'); // TODO: review the translation when draft domains are migrating to a single one
  const { openSnackbar } = useSnackbarNotification();
  useRTKQueryReset(mutation, { onUnmount: true }); // to avoid notification twice

  const requestMethod = async (params: AddMultipleDocumentsParams) => {
    const fileAmount = params.files.length;
    const successMessage = t(
      fileAmount > 1 ? 'Files are uploaded' : 'File is uploaded'
    );
    const errorMessage = t(
      fileAmount > 1 ? 'Files are not uploaded' : 'File is not uploaded'
    );

    return addDocuments(params)
      .unwrap()
      .then(() => openSnackbar(successMessage, 'success'))
      .catch(() => openSnackbar(errorMessage, 'error'));
  };

  return {
    ...mutation,
    addDocuments: requestMethod,
    error,
  };
};

export const useDeleteDocumentMutation = () => {
  const [deleteDocument, mutation] =
    documentManageApi.useDeleteCertificateDraftDocumentMutation({
      fixedCacheKey: 'delete-certificate-draft-document',
    });
  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: DeleteDocumentParams) =>
    deleteDocument(params).unwrap();

  return {
    ...mutation,
    deleteDocument: requestMethod,
    status,
    error: error ? createErrorAlertState(error) : null,
    reset: mutation.reset,
  };
};
