import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { ActionComment, AlertsState, LoaderStatusEnum, Nullable } from 'types';
import apiClient from 'utils/apiClient';
import { createErrorAlertState } from 'utils/createAlertsState';
import { formatToAlerts } from 'utils/formatToAlerts';

import { UpdateDescriptionParams, UpdateDescriptionState } from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';

const initialState: UpdateDescriptionState = {
  description: '',
  actionComment: null,
  isModalOpen: false,
  loading: LoaderStatusEnum.IDLE,
  alertsState: null,
};

const reducerName = `${parentReducerName}/updateDescription`;

// thunks
export const updateDescription = createAsyncThunk(
  reducerName,
  async (params: UpdateDescriptionParams, { rejectWithValue }) => {
    try {
      const { appointmentId, actionComment, description } = params;
      const data = { actionComment, description };
      const response = await apiClient.put(
        endpoints.UPDATE_DESCRIPTION(appointmentId),
        data
      );
      return response.data;
    } catch (err) {
      const errors = formatToAlerts(err);
      return rejectWithValue(errors);
    }
  }
);

// slice
export const updateDescriptionSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetUpdateDescriptionState: () => initialState,
    setUpdateDescriptionDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setUpdateDescriptionActionComment: (
      state,
      action: PayloadAction<ActionComment>
    ) => {
      state.actionComment = action.payload;
    },
    setUpdateDescriptionIsModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateDescription.pending, (state) => {
      state.loading = LoaderStatusEnum.LOADING;
      state.alertsState = null;
    });
    builder.addCase(updateDescription.fulfilled, (state) => {
      state.loading = LoaderStatusEnum.SUCCESS;
      state.alertsState = null;
    });
    builder.addCase(updateDescription.rejected, (state, action) => {
      state.loading = LoaderStatusEnum.ERROR;
      state.alertsState = createErrorAlertState(action.payload);
    });
  },
});

// actions
export const {
  resetUpdateDescriptionState,
  setUpdateDescriptionDescription,
  setUpdateDescriptionActionComment,
  setUpdateDescriptionIsModalOpen,
} = updateDescriptionSlice.actions;

// selectors
export const selectUpdateDescriptionIsModalOpen = (state: RootState): boolean =>
  state[parentReducerName].updateDescription.isModalOpen;
export const selectUpdateDescriptionLoading = (
  state: RootState
): LoaderStatusEnum => state[parentReducerName].updateDescription.loading;
export const selectUpdateDescriptionAlertsState = (
  state: RootState
): Nullable<AlertsState> =>
  state[parentReducerName].updateDescription.alertsState;
export const selectUpdateDescriptionDescription = (state: RootState): string =>
  state[parentReducerName].updateDescription.description;
export const selectUpdateDescriptionActionComment = (
  state: RootState
): Nullable<ActionComment> =>
  state[parentReducerName].updateDescription.actionComment;

// reducer
export default updateDescriptionSlice.reducer;
