import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useCertificationBodyModal } from 'domains/certificationBody/hooks/useCertificationBodyModal';
import { UseDraftTrigger } from 'domains/supplier/types';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { CertificationBody } from 'types';

import { getCertificateDraftCreatePathname } from '../utils/getCertificateDraftCreatePathname';

export const useCertificateDraftTrigger = (): UseDraftTrigger => {
  const { push } = useHistory();
  const { supplierId } = useParams<{ supplierId: string }>();
  const isAdmin = useSelector(selectUserIsAdmin);

  const openRequestCertificateDraftPage = () =>
    push(
      getCertificateDraftCreatePathname({
        supplierId,
      })
    );
  const { isCbModalOpen, openCbModal, closeCbModal, handleCbModalNextAction } =
    useCertificationBodyModal(openRequestCertificateDraftPage);

  const handleCbNextAction = (cb: CertificationBody) => {
    handleCbModalNextAction(cb, openRequestCertificateDraftPage);
  };

  return {
    isCbModalOpen,
    showButton: isAdmin,
    handleUploadProcess: openCbModal,
    closeCbModal,
    handleCbNextAction,
  };
};
