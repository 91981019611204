import { useEffect } from 'react';

export interface RTKFunctionPartialResult {
  reset: () => void;
}

export function useRTKQueryReset(
  mutationOrQuery: RTKFunctionPartialResult,
  whenReset: {
    onMount?: boolean;
    onUnmount?: boolean;
    onDependencyChange?: boolean;
  },
  deps: unknown[] = []
): void {
  const { reset } = mutationOrQuery;
  const {
    onMount = false,
    onUnmount = false,
    onDependencyChange = false,
  } = whenReset;

  useEffect(() => {
    if (onMount && !onDependencyChange) {
      reset();
    }
    return () => {
      if (onUnmount) {
        reset();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onDependencyChange) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);
}
