import get from 'lodash/get';
import React, { FC } from 'react';

import { StartEndDates } from 'types';
import { renderDateRange } from 'utils/dates';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

type TimeFrame = StartEndDates;

const isTimeFrame = (timeFrame: unknown): timeFrame is TimeFrame =>
  !!timeFrame &&
  'start' in (timeFrame as TimeFrame) &&
  'end' in (timeFrame as TimeFrame);

interface AttributeRowTimeFrameProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
}

export const AttributeRowTimeFrame: FC<AttributeRowTimeFrameProps> = (
  props
) => {
  const { data, dataRef, labelText } = props;
  const value = get(data, dataRef);
  const showAttributeRow =
    isTimeFrame(value) && !!value.start.trim() && !!value.end.trim();

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong TimeFrame data" />
    );
  }

  return (
    <AttributeRowBase
      label={labelText}
      value={renderDateRange(value.start, value.end)}
    />
  );
};
