import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { FC, ReactNode } from 'react';

import { TEXT } from 'config/appColors';

const StyledTypography = styled(Typography)`
  font-weight: 500;
  font-size: 2rem;
  color: ${TEXT.PRIMARY};
  line-height: 3.2rem;
`;
interface SectionTitleProps {
  className?: string;
  children?: ReactNode;
}

export const SectionTitle: FC<SectionTitleProps> = ({
  className,
  children,
}) => (
  <StyledTypography className={className} variant="h4">
    {children}
  </StyledTypography>
);
