import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { StepperStepType } from 'components/atoms/Step';
import { useAbortModal } from 'hooks/useAbortModal';
import { renderDateRange } from 'utils/dates';

import {
  createAuditAppointment,
  resetFormValuesToCurrentStep,
  selectAuditAppointmentFormValues,
} from '../state/create/slice';
import { resetFormOptionsStateToCurrentStep } from '../state/formOptions/slice';
import {
  selectCurrentStep,
  selectIsModalOpen,
  selectIsNextButtonDisabled,
  selectIsPrevButtonDisabled,
  setCurrentStep,
  setIsNextButtonDisabled,
} from '../state/stepperModal/slice';
import {
  AuditAppointmentStepEnum,
  AuditPlanStepperFormHook,
  ButtonsConfig,
} from '../types';

const STEPS_WITHOUT_STEPPER: AuditAppointmentStepEnum[] = [];

export const useAuditPlanStepperForm = (): AuditPlanStepperFormHook => {
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const { module, type, kind, time, executionMode } = useSelector(
    selectAuditAppointmentFormValues
  );
  const currentStep = useSelector(selectCurrentStep);
  const { isAbortModalOpen, openAbortModal, closeAbortModal } = useAbortModal();
  const isModalOpen = useSelector(selectIsModalOpen);
  const isNextButtonDisabled = useSelector(selectIsNextButtonDisabled);
  const isPrevButtonDisabled = useSelector(selectIsPrevButtonDisabled);

  const showStepper = !STEPS_WITHOUT_STEPPER.includes(currentStep);

  const steps = [
    {
      id: AuditAppointmentStepEnum.MODULE,
      label: t('Standard/program', { ns: 'components' }),
      value: module?.name,
    },
    {
      id: AuditAppointmentStepEnum.TYPE,
      label: t('Type'),
      value: type?.name,
    },
    {
      id: AuditAppointmentStepEnum.KIND,
      label: t('Kind'),
      value: kind?.name,
    },
    {
      id: AuditAppointmentStepEnum.TIME,
      label: t('Time'),
      value: time?.end ? renderDateRange(time.start, time?.end) : '',
    },
    {
      id: AuditAppointmentStepEnum.EXECUTION_MODE,
      label: t('Execution Mode'),
      value: executionMode?.name,
    },
    {
      id: AuditAppointmentStepEnum.DESCRIPTION,
      label: t('Comment'),
      value: currentStep === AuditAppointmentStepEnum.SUMMARY ? ' ' : undefined,
    },
    {
      id: AuditAppointmentStepEnum.SUMMARY,
      label: t('Summary'),
      value: '',
    },
  ];

  const updateStepper = (step: AuditAppointmentStepEnum) => {
    dispatch(resetFormValuesToCurrentStep(step));
    dispatch(resetFormOptionsStateToCurrentStep(step));
  };

  const updateCurrentStep = (newStep: AuditAppointmentStepEnum) => {
    dispatch(setCurrentStep(newStep));
  };

  const getCurrentStepIndex = () =>
    steps.findIndex((step) => step.id === currentStep);

  const prevAction = () => {
    if (currentStep === AuditAppointmentStepEnum.MODULE) {
      openAbortModal();
    } else {
      const currentStepIndex = getCurrentStepIndex();
      const prevStepIndex = currentStepIndex - 1;

      if (prevStepIndex >= 0) {
        const prevStep = steps[prevStepIndex];

        updateCurrentStep(prevStep.id);
        updateStepper(prevStep.id);
        dispatch(setIsNextButtonDisabled(false));
      }
    }
  };

  const nextAction = () => {
    const currentStepIndex = getCurrentStepIndex();
    const nextStepIndex = currentStepIndex + 1;

    if (nextStepIndex <= steps.length - 1) {
      const nextStep = steps[nextStepIndex];

      updateCurrentStep(nextStep.id);
      dispatch(setIsNextButtonDisabled(true));
    }

    if (currentStepIndex === steps.length - 1) {
      dispatch(createAuditAppointment());
    }
  };

  const buttonsConfig: ButtonsConfig = {
    prevLabel:
      currentStep === AuditAppointmentStepEnum.MODULE
        ? t('Cancel')
        : t('Back', { ns: 'components' }),
    nextLabel:
      currentStep === AuditAppointmentStepEnum.SUMMARY
        ? t('Create')
        : t('Continue', { ns: 'components' }),
    prevColor: 'modalPrevButton',
    nextColor:
      currentStep === AuditAppointmentStepEnum.SUMMARY ? 'primary' : 'info',
    disableNext: isNextButtonDisabled,
    disablePrev: isPrevButtonDisabled,
  };

  const handleStepClick = (step: StepperStepType) => {
    updateCurrentStep(step.id as AuditAppointmentStepEnum);
    updateStepper(step.id as AuditAppointmentStepEnum);
    dispatch(setIsNextButtonDisabled(false));
  };

  return {
    showStepper,
    currentStep,
    auditPlanSteps: steps,
    buttonsConfig,
    isAbortModalOpen,
    openAbortModal,
    closeAbortModal,
    isModalOpen,
    nextAction,
    prevAction,
    handleStepClick,
  };
};
