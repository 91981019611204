import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { LoaderStatusEnum } from 'types';

import { ConditionalModalProps } from './types';
import { getAuditAppointment } from '../../../state/details/slice';
import {
  resetUpdateDescriptionState,
  selectUpdateDescriptionIsModalOpen,
  selectUpdateDescriptionLoading,
  setUpdateDescriptionIsModalOpen,
} from '../../../state/updateDescription/slice';
import { UpdateDescriptionModal } from '../UpdateDescriptionModal';

export const ConditionalUpdateDescriptionModal: FC<ConditionalModalProps> = ({
  appointmentId,
}) => {
  const [t] = useTranslation('auditAppointment');
  const notificationMessage = t('Comment successfully updated');
  const { openSnackbar } = useSnackbarNotification();
  const dispatch = useDispatch();
  const loading = useSelector(selectUpdateDescriptionLoading);
  const open = useSelector(selectUpdateDescriptionIsModalOpen);

  const closeModal = () => {
    dispatch(setUpdateDescriptionIsModalOpen(false));
    dispatch(resetUpdateDescriptionState());
  };

  useEffect(() => {
    if (loading === LoaderStatusEnum.SUCCESS) {
      dispatch(resetUpdateDescriptionState()); // reset state to IDLE
      dispatch(getAuditAppointment(appointmentId)); // refetch audit appointment details if success
      openSnackbar(notificationMessage, 'success');
    }
  }, [loading, appointmentId, dispatch, openSnackbar, notificationMessage]);

  if (!open) return null;

  return (
    <UpdateDescriptionModal
      open={open}
      appointmentId={appointmentId}
      handleClose={closeModal}
    />
  );
};
