import { styled } from '@mui/material';
import get from 'lodash/get';
import React, { FC } from 'react';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

const StyledParagraph = styled('p')`
  margin: 0 0 5px 0;
`;

const isText = (text: unknown): text is string => typeof text === 'string';

interface AttributeRowTextProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
}

export const AttributeRowText: FC<AttributeRowTextProps> = (props) => {
  const { data, dataRef, labelText } = props;

  const dataRefList = isText(dataRef) ? [dataRef] : dataRef;
  const values = dataRefList
    .map((dataRefListItem) => get(data, dataRefListItem))
    .filter((value) => isText(value) && value.trim().length > 0) as string[];
  const showAttributeRow = values.length > 0;

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Text data or no data" />
    );
  }

  const valuesToDisplay = values.map((value) => (
    <StyledParagraph key={value}>{value}</StyledParagraph>
  ));

  return <AttributeRowBase label={labelText} value={valuesToDisplay} />;
};
