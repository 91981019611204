import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertList } from 'components/molecules/AlertList';
import { FileUpload } from 'components/molecules/FileUpload';
import { TEXT } from 'config/appColors';
import { AlertVariantEnum, WithUndefined } from 'types';

const AuditFileUploadFormContainer = styled('div')`
  width: 100%;
  padding: 50px 106px;
  font-weight: 400;
  letter-spacing: 0.015rem;
  color: ${TEXT.PRIMARY};
  align-self: flex-start;
`;
const StyledAlertList = styled(AlertList)`
  > div:first-child {
    margin-top: 0;
  }
`;

interface TemplateFileUploadFormProps {
  alerts: WithUndefined<string[]>;
  isErrorAlert: boolean;
  onFileChange: (files: File[]) => void;
  hideRemoveButton?: boolean;
  children?: ReactNode;
}

export const TemplateFileUploadForm: FC<TemplateFileUploadFormProps> = (
  props
) => {
  const {
    alerts = [],
    children,
    isErrorAlert,
    onFileChange,
    hideRemoveButton = false,
  } = props;
  const [t] = useTranslation('auditFileUpload');

  return (
    <AuditFileUploadFormContainer data-testid="audit-file-upload-container">
      {alerts.length > 0 && (
        <StyledAlertList
          alerts={alerts}
          variant={
            isErrorAlert ? AlertVariantEnum.ERROR : AlertVariantEnum.WARNING
          }
        />
      )}
      <p>{t('Please upload the auditXpress file.')}</p>
      <FileUpload
        onChange={onFileChange}
        uploadButtonText={t('Select file')}
        fileTypes={['.ifs', '.axpx']}
        maxFileSize={1048576 * 4} // 4MB
        hideRemoveButtons={hideRemoveButton}
      />
      {children}
    </AuditFileUploadFormContainer>
  );
};
