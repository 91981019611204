import { styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertList } from 'components/molecules/AlertList';
import { SearchField } from 'components/molecules/SearchField';
import { useMemoizedDebounce } from 'hooks/useMemoizedDebounce';
import { AlertVariantEnum, CertificationBody, Nullable, Option } from 'types';

import { useLazyGetCertificationBodyListQuery } from '../../../state/search/api';

interface SelectCertificationBodyModalContentProps {
  setCertificationBody: Dispatch<SetStateAction<Nullable<CertificationBody>>>;
}

const StyledWrapper = styled('div')`
  min-height: 140px;
`;

export const SelectCertificationBodyModalContent: FC<SelectCertificationBodyModalContentProps> =
  (props) => {
    const { setCertificationBody } = props;
    const [t] = useTranslation('certificationBody');

    const {
      certificationBodyList,
      searchCertificationBodyList,
      error,
      isLoading,
    } = useLazyGetCertificationBodyListQuery();

    const fetchCbList = (searchByName: string) =>
      searchCertificationBodyList({ filter: { searchByName } });
    const fetchCbListDebounced = useMemoizedDebounce(fetchCbList);

    const handleValueSelect = (option: Nullable<Option>) => {
      if (option) {
        setCertificationBody({
          uuid: option.value,
          name: option.label,
        });
      } else setCertificationBody(null);
    };

    const options = certificationBodyList.map(
      ({ name, uuid, cbid, legalForm }) => ({
        label:
          name +
          (legalForm ? ` ${legalForm}` : '') +
          (cbid ? ` (CBID: ${cbid})` : ''),
        value: uuid,
      })
    );

    return (
      <StyledWrapper>
        {error.length > 0 && (
          <AlertList variant={AlertVariantEnum.ERROR} alerts={error} />
        )}
        <SearchField
          dataTestId="certification-body-search"
          options={options}
          loading={isLoading}
          onValueSelect={handleValueSelect}
          onSearchTermChange={fetchCbListDebounced}
          description={t('Please select the Certification Body')}
          placeholder={t('Search Certification Body')}
          infoMessage
          noOptionsMessage={t('No Certification Body found.')}
        />
      </StyledWrapper>
    );
  };
