import {
  ActionReasonOption,
  BackAction,
  DraftStepCmp,
  NextAction,
  TranslatedText,
} from 'types';

export enum CertificateDraftStepEnum {
  BASE_DATA = 'baseDataStep',
  PRODUCT_SCOPES = 'productScopesStep',
  TECH_SCOPES = 'techScopesStep',
  SCOPE_DATA = 'scopeDataStep',
  PROCESSING_SERVICES = 'processingServicesStep',
  ADDITIONAL_DATA = 'additionalDataStep',
  DOCUMENT_UPLOAD = 'documentUploadStep',
  COMMENT = 'commentStep',
  REASON = 'reasonStep',
  ADMIN_COMMENT = 'adminCommentStep',
  CONFIRMATION_SUBMIT = 'confirmationSubmitStep',
}

interface StepProps<Id extends CertificateDraftStepEnum> {
  id: Id;
  title: TranslatedText;
  instructionText: TranslatedText;
  backAction: BackAction;
  nextAction: NextAction;
}

export interface FormBaseDataStep {
  type: 'step';
  props: StepProps<CertificateDraftStepEnum.BASE_DATA> & {
    enabledWarningIds: string[];
  };
  children: DraftStepCmp[];
}

export const isFormBaseDataStep = (step: unknown): step is FormBaseDataStep =>
  !!step &&
  (step as FormBaseDataStep).props.id === CertificateDraftStepEnum.BASE_DATA;

export interface FormProductScopesStep {
  type: 'step';
  props: StepProps<CertificateDraftStepEnum.PRODUCT_SCOPES> & {
    noDataText: TranslatedText;
    productScopeDefinition: unknown; // TBD - for now in template empty {}
  };
  children: DraftStepCmp[];
}

export const isFormProductScopesStep = (
  step: unknown
): step is FormProductScopesStep =>
  !!step &&
  (step as FormProductScopesStep).props.id ===
    CertificateDraftStepEnum.PRODUCT_SCOPES;

export interface FormTechScopesStep {
  type: 'step';
  props: StepProps<CertificateDraftStepEnum.TECH_SCOPES> & {
    noDataText: TranslatedText;
    techScopeDefinition: unknown; // TBD - for now in template empty {}
  };
  children: DraftStepCmp[];
}

export const isFormTechScopesStep = (
  step: unknown
): step is FormTechScopesStep =>
  !!step &&
  (step as FormTechScopesStep).props.id ===
    CertificateDraftStepEnum.TECH_SCOPES;

export interface FormScopeDataStep {
  type: 'step';
  props: StepProps<CertificateDraftStepEnum.SCOPE_DATA> & {
    enabledWarningIds: string[];
  };
  children: DraftStepCmp[];
}

export const isFormScopeDataStep = (step: unknown): step is FormScopeDataStep =>
  !!step &&
  (step as FormScopeDataStep).props.id === CertificateDraftStepEnum.SCOPE_DATA;

export interface FormAdditionalDataStep {
  type: 'step';
  props: StepProps<CertificateDraftStepEnum.ADDITIONAL_DATA> & {
    enabledWarningIds: string[];
  };
  children: DraftStepCmp[];
}

export const isFormAdditionalDataStep = (
  step: unknown
): step is FormAdditionalDataStep =>
  !!step &&
  (step as FormAdditionalDataStep).props.id ===
    CertificateDraftStepEnum.ADDITIONAL_DATA;

export interface FormDocumentUploadStep {
  type: 'step';
  props: StepProps<CertificateDraftStepEnum.DOCUMENT_UPLOAD>;
  children: null;
}

export const isFormDocumentUploadStep = (
  step: unknown
): step is FormDocumentUploadStep =>
  !!step &&
  (step as FormDocumentUploadStep).props.id ===
    CertificateDraftStepEnum.DOCUMENT_UPLOAD;

export interface FormCommentStep {
  type: 'step';
  props: StepProps<CertificateDraftStepEnum.COMMENT> & {
    actionReasonOptions: ActionReasonOption[];
  };
  children: null;
}

export const isFormCommentStep = (step: unknown): step is FormCommentStep =>
  !!step &&
  (step as FormCommentStep).props.id === CertificateDraftStepEnum.COMMENT;

export interface FormReasonStep {
  type: 'step';
  props: Omit<StepProps<CertificateDraftStepEnum.REASON>, 'instructionText'> & {
    actionReasonOptions: ActionReasonOption[];
    updateOptions: ActionReasonOption[];
    labelUpdateReasonSelect: TranslatedText;
    labelUpdateReasonDescription: TranslatedText;
    hintUpdateReasonDescription: TranslatedText;
  };
  children: null;
}

export const isFormReasonStep = (step: unknown): step is FormReasonStep =>
  !!step &&
  (step as FormReasonStep).props.id === CertificateDraftStepEnum.REASON;

export interface FormAdminCommentStep {
  type: 'step';
  props: StepProps<CertificateDraftStepEnum.ADMIN_COMMENT> & {
    actionReasonOptions: ActionReasonOption[];
  };
  children: null;
}

export const isFormAdminCommentStep = (
  step: unknown
): step is FormAdminCommentStep =>
  !!step &&
  (step as FormAdminCommentStep).props.id ===
    CertificateDraftStepEnum.ADMIN_COMMENT;

export interface FormConfirmationSubmitStep {
  type: 'step';
  props: StepProps<CertificateDraftStepEnum.CONFIRMATION_SUBMIT> & {
    successMessage: TranslatedText;
    closeAction: NextAction;
  };
  children: DraftStepCmp[];
}

export const isFormConfirmationSubmitStep = (
  step: unknown
): step is FormConfirmationSubmitStep =>
  !!step &&
  (step as FormConfirmationSubmitStep).props.id ===
    CertificateDraftStepEnum.CONFIRMATION_SUBMIT;

export interface FormProcessingServicesStep {
  type: 'step';
  props: StepProps<CertificateDraftStepEnum.PROCESSING_SERVICES>;
  children: DraftStepCmp[];
}

export const isFormProcessingServicesStep = (
  step: unknown
): step is FormProcessingServicesStep =>
  !!step &&
  (step as FormProcessingServicesStep).props.id ===
    CertificateDraftStepEnum.PROCESSING_SERVICES;

export type CertificateDraftStep =
  | FormBaseDataStep
  | FormProductScopesStep
  | FormTechScopesStep
  | FormScopeDataStep
  | FormDocumentUploadStep
  | FormCommentStep
  | FormReasonStep
  | FormAdminCommentStep
  | FormConfirmationSubmitStep
  | FormProcessingServicesStep;
