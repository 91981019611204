import { ButtonProps, styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import BaseModal from 'components/atoms/BaseModal';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { ARGENT } from 'config/appColors';
import {
  OpenSnackbar,
  useSnackbarNotification,
} from 'hooks/useSnackbarNotification';
import { ButtonColorType } from 'types';

const StyledBaseModal = styled(BaseModal)`
  .MuiDialog-container {
    height: auto;
    min-height: 100%;
  }
  .MuiDialog-paper {
    width: 600px;
    max-width: 600px;
    min-height: 303px;
  }
  .MuiDialogContent-root {
    padding: 0;
    border-bottom: 1px solid ${ARGENT};
    margin-bottom: 16px;
  }
`;

export type NextAction = (
  openSnackbar: OpenSnackbar,
  closeModal: () => void
) => void;

export interface TemplateModalWithSnackbarProps {
  nextAction: NextAction;
  isLoading: boolean;
  title: string;
  closeMethod: () => void;
  prevLabel?: string;
  nextLabel?: string;
  disableNext?: boolean;
  prevColor?: ButtonColorType;
  nextColor?: ButtonColorType;
  className?: string;
  children?: ReactNode;
  hidePrevButton?: boolean;
  prevVariant?: ButtonProps['variant'];
  nextVariant?: ButtonProps['variant'];
}

export const TemplateModalWithSnackbar: FC<TemplateModalWithSnackbarProps> = (
  props
) => {
  const [t] = useTranslation('components');
  const {
    children,
    isLoading,
    nextAction,
    title,
    closeMethod,
    disableNext = false,
    prevLabel = t('Close'),
    nextLabel = t('Submit'),
    prevColor = 'modalPrevButton',
    nextColor = 'info',
    className,
    hidePrevButton,
    nextVariant,
    prevVariant,
  } = props;

  const { openSnackbar } = useSnackbarNotification();

  const handleNextAction = () => {
    nextAction(openSnackbar, closeMethod);
  };

  return (
    <StyledBaseModal className={className} open>
      <TemplateAuditModal
        title={title}
        contentComponent={children}
        closeAction={closeMethod}
        prevAction={closeMethod}
        nextAction={handleNextAction}
        noPrevArrow
        noNextArrow
        prevLabel={prevLabel}
        nextLabel={nextLabel}
        prevColor={prevColor}
        nextColor={nextColor}
        disableNext={disableNext}
        nextButtonLoading={isLoading}
        hidePrevButton={hidePrevButton || isLoading}
        nextVariant={nextVariant}
        prevVariant={prevVariant}
      />
    </StyledBaseModal>
  );
};
