import { styled } from '@mui/material';
import React, { FC } from 'react';

import { LoaderIcon } from 'components/atoms/LoaderIcon';

const StyledLoader = styled(LoaderIcon)`
  margin: 3px;
`;

export const ModalFooterLoader: FC = () => (
  <StyledLoader size={30} color="primary" />
);
