import { styled } from '@mui/material';
import { round } from 'lodash';
import React from 'react';

import { BLACK } from 'config/appColors';
import { renderDateRange } from 'utils/dates';

import { AdminTableCell, SupportedMessageEnum } from '../../../types';
import { DateWarningTooltip } from '../../atoms/DateWarningTooltip';

const StyledAuditTime = styled('div')`
  margin-top: 8px;
  color: ${BLACK};

  span {
    font-weight: 600;
  }
`;

export const AuditTimesCell: AdminTableCell = (props) => {
  const { auditTimeframe, t } = props;
  const { startDate, endDate, auditTimeInMinutes = 0 } = auditTimeframe;
  const date = renderDateRange(startDate, endDate);
  const { AUDIT_TIME_EXCEEDED, AUDIT_TIME_NOT_REACHED } = SupportedMessageEnum;
  const auditTimeInHours = round(auditTimeInMinutes / 60, 2);

  return (
    <>
      <DateWarningTooltip
        date={date}
        auditProps={props}
        supportedMessages={[AUDIT_TIME_EXCEEDED, AUDIT_TIME_NOT_REACHED]}
      />
      {!!auditTimeInHours && (
        <StyledAuditTime>
          <span>{t('Audit time')}</span>: {auditTimeInHours} {t('hours')}
        </StyledAuditTime>
      )}
    </>
  );
};
