import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { invalidateRTKTags } from 'utils/invalidateRTKTags';

import { DeleteAuditParams } from './types';
import endpoints from '../../config/endpoints';

const deleteAuditApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    deleteAudit: build.mutation<void, DeleteAuditParams>({
      query: ({ auditUuid, actionComment }: DeleteAuditParams) => ({
        url: endpoints.DELETE_AUDIT(auditUuid),
        data: {
          actionComment,
        },
        method: 'DELETE',
      }),
      invalidatesTags: invalidateRTKTags([
        'AUDIT_LIST',
        'AUDIT_DETAILS',
        'AUDIT_DETAILS_TEMPLATE',
        'AUDIT_DETAILS_ACTIONS',
      ]),
    }),
  }),
});

export const useDeleteAuditMutation = () => {
  const [deleteAudit, mutation] = deleteAuditApi.useDeleteAuditMutation();
  const status = useRTKQueryStatus(mutation);
  const error = useFormattedError(mutation, 'formatToAlerts');

  const requestMethod = async (params: DeleteAuditParams) =>
    deleteAudit(params).unwrap();

  return {
    ...mutation,
    deleteAudit: requestMethod,
    error,
    status,
  };
};
