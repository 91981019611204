import { css, styled } from '@mui/material';

import { TextField } from 'components/atoms/TextField';
import { WHITE } from 'config/appColors';

export const commonInputStyles = css`
  .MuiInputBase-root {
    height: 30px;
  }

  input {
    border-radius: 4px;
    height: 30px;
    background-color: ${WHITE};
    padding: 0 5px;
    overflow: hidden;
  }
`;

const StyledTextField = styled(TextField)`
  ${commonInputStyles}
`;

StyledTextField.displayName = 'StyledTextField';

export { StyledTextField };
