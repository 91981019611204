import React, { FC, ReactNode } from 'react';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  selectedIndex: number;
  id: string;
  navId: string;
}

export const TabPanel: FC<TabPanelProps> = ({
  children,
  selectedIndex,
  index,
  id,
  navId,
  ...restProps
}) => (
  <div
    role="tabpanel"
    hidden={selectedIndex !== index}
    id={id}
    aria-labelledby={navId}
    {...restProps}
  >
    {selectedIndex === index && children}
  </div>
);

export default TabPanel;
