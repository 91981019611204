import { styled } from '@mui/material';

import { Table } from 'components/organisms/Table';

export const StyledTable = styled(Table)`
  table,
  .skeleton-container {
    min-width: 910px;
  }
` as typeof Table;
