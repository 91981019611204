import { TEMPORARY_MODULES } from 'domains/auditAppointment/config/temporalConstants';
import { TranslatedText } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { auditOptions } from './auditOptions';
import { auditTags } from './auditTags';
import { auditTypes } from './auditTypes';
import { countries } from './countries';
import {
  AdditionalInformationCell,
  AuditIdCell,
  AuditOptionCell,
  AuditorsCell,
  AuditPlannedCell,
  AuditTagsCell,
  AuditTimesCell,
  AuditTypeCell,
  CertificateCell,
  CertificateLocksCell,
  CertificateRecertificationCell,
  CertificationBodyCell,
  DocumentsCell,
  ResultCell,
  ScopesCell,
  ScopesDescriptionCell,
  StandardCell,
  SupplierCell,
  SupplierCityCell,
  SupplierCountryCell,
  SupplierHeadquarterCell,
  UploadedCell,
} from '../components/molecules/DataCells';
import { ExceptionalCircumstancesCell } from '../components/molecules/DataCells/ExceptionalCircumstancesCell';
import { AdminColumn, FilterTypeEnum } from '../types';

interface RawOption {
  id: string;
  text: TranslatedText;
}

const getOptions = (rawOptions: RawOption[]) =>
  rawOptions.map(({ id, text }) => ({
    label: getTranslatedValue(text) || id,
    value: id,
  }));

export const columns: AdminColumn[] = [
  {
    cellComponent: AuditIdCell,
    filtersConfig: [
      {
        filterKey: 'auid',
        type: FilterTypeEnum.TEXT,
        minLength: 1,
      },
      {
        filterKey: 'includeDeletedAudits',
        type: FilterTypeEnum.CHECKBOX,
        label: 'include deleted',
        defaultValue: null,
      },
    ],
    header: 'Audit ID (AUID)',
    width: '150px',
  },
  {
    cellComponent: SupplierCell,
    filtersConfig: [
      {
        filterKey: 'supplierSearchString',
        type: FilterTypeEnum.TEXT,
      },
    ],
    header: 'Supplier',
    width: '225px',
    sortKey: 'supplier',
    hidable: true,
  },
  {
    cellComponent: SupplierCityCell,
    filtersConfig: [
      {
        filterKey: 'supplierCitySearchString',
        type: FilterTypeEnum.TEXT,
      },
    ],
    header: 'Supplier city',
    width: '200px',
    sortKey: 'supplierCity',
    hidable: true,
  },
  {
    cellComponent: SupplierCountryCell,
    filtersConfig: [
      {
        filterKey: 'supplierCountries',
        type: FilterTypeEnum.MULTISELECT,
        options: getOptions(countries),
      },
    ],
    header: 'Supplier country',
    width: '200px',
    sortKey: 'supplierCountry',
    hidable: true,
  },
  {
    cellComponent: SupplierHeadquarterCell,
    filtersConfig: [
      {
        filterKey: 'supplierHeadquarterSearchString',
        type: FilterTypeEnum.TEXT,
      },
      {
        filterKey: 'supplierHasHeadquarter',
        type: FilterTypeEnum.CHECKBOX,
        label: 'has headquarter',
        defaultValue: null,
      },
    ],
    header: 'Supplier headquarter',
    width: '225px',
    sortKey: 'supplierHeadquarter',
    hidable: true,
  },
  {
    cellComponent: StandardCell,
    filtersConfig: [
      {
        filterKey: 'auditModules',
        type: FilterTypeEnum.MULTISELECT,
        options: TEMPORARY_MODULES,
      },
    ],
    header: 'Standards/Programs',
    width: '200px',
    sortKey: 'module',
    hidable: true,
  },
  {
    cellComponent: AuditTypeCell,
    filtersConfig: [
      {
        filterKey: 'auditTypes',
        type: FilterTypeEnum.MULTISELECT,
        options: getOptions(auditTypes),
      },
    ],
    header: 'Audit type',
    width: '245px',
    sortKey: 'type',
    hidable: true,
  },
  {
    cellComponent: AuditOptionCell,
    filtersConfig: [
      {
        filterKey: 'auditKinds',
        type: FilterTypeEnum.MULTISELECT,
        options: getOptions(auditOptions),
      },
    ],
    header: 'Audit option',
    width: '150px',
    sortKey: 'kind',
    hidable: true,
  },
  {
    cellComponent: AuditPlannedCell,
    filtersConfig: [
      {
        filterKey: 'auditHasAppointment',
        type: FilterTypeEnum.CHECKBOX,
        label: 'only not planned audits',
        defaultValue: null,
      },
    ],
    header: 'Audit planned',
    width: '150px',
    hidable: true,
  },
  {
    cellComponent: AuditTimesCell,
    filtersConfig: [
      {
        type: FilterTypeEnum.DATE_RANGE,
        startFilterKey: 'auditTimeStart',
        endFilterKey: 'auditTimeEnd',
      },
      // TODO: once filters are ready on BE uncomment the following code
      // {
      //   filterKey: 'auditHasTimeViolation',
      //   type: FilterTypeEnum.CHECKBOX,
      //   label: 'only not respected duration',
      //   defaultValue: null
      // },
    ],
    header: 'Audit time frame',
    width: '270px',
    sortKey: 'auditTimeframe.startDate',
    hidable: true,
  },
  {
    cellComponent: UploadedCell,
    filtersConfig: [
      {
        filterKey: 'auditUploadedAt',
        type: FilterTypeEnum.DATE,
      },
      // TODO: once filters are ready on BE uncomment the following code
      // {
      //   filterKey: 'auditHasUploadViolation',
      //   type: FilterTypeEnum.CHECKBOX,
      //   label: 'only late uploads',
      //   defaultValue: null
      // },
    ],
    header: 'Uploaded at',
    width: '150px',
    sortKey: 'uploadedAt',
    hidable: true,
  },
  {
    cellComponent: ResultCell,
    header: 'Result',
    width: '250px',
    hidable: true,
  },
  {
    cellComponent: ScopesCell,
    header: 'Scopes',
    width: '200px',
    hidable: true,
  },
  {
    cellComponent: ScopesDescriptionCell,
    header: 'Scopes description',
    width: '200px',
  },
  {
    cellComponent: AuditTagsCell,
    filtersConfig: [
      {
        filterKey: 'auditTags',
        type: FilterTypeEnum.MULTISELECT,
        options: getOptions(auditTags),
      },
    ],
    header: 'Tags',
    width: '200px',
  },
  {
    cellComponent: DocumentsCell,
    header: 'Documents',
    width: '200px',
  },
  {
    cellComponent: CertificationBodyCell,
    filtersConfig: [
      {
        filterKey: 'certificationBodySearchString',
        type: FilterTypeEnum.TEXT,
      },
    ],
    header: 'Certification Body',
    width: '200px',
    sortKey: 'certificationBody',
    hidable: true,
  },
  {
    cellComponent: AuditorsCell,
    // TODO: once filters are ready on BE uncomment the following code
    // filtersConfig: [
    //   {
    //     filterKey: 'auditHasAuditorScopeViolation',
    //     type: FilterTypeEnum.CHECKBOX,
    //     label: 'has scope mismatch',
    //     defaultValue: null
    //   },
    // ],
    header: 'Auditors',
    width: '250px',
    hidable: true,
  },
  {
    cellComponent: CertificateCell,
    filtersConfig: [
      {
        filterKey: 'auditHasCertificate',
        type: FilterTypeEnum.CHECKBOX,
        label: 'has Certificate',
        defaultValue: null,
      },
    ],
    header: 'Certificate',
    width: '225px',
    hidable: true,
  },
  {
    cellComponent: CertificateRecertificationCell,
    header: 'Recertification',
    width: '200px',
    hidable: true,
  },
  {
    cellComponent: CertificateLocksCell,
    filtersConfig: [
      {
        filterKey: 'certificateIsLocked',
        type: FilterTypeEnum.CHECKBOX,
        label: 'is locked',
        defaultValue: null,
      },
      {
        filterKey: 'certificateIsReinstated',
        type: FilterTypeEnum.CHECKBOX,
        label: 'is reinstated',
        defaultValue: null,
      },
    ],
    header: 'Locks',
    width: '200px',
    hidable: true,
  },
  {
    cellComponent: ExceptionalCircumstancesCell,
    filtersConfig: [
      {
        filterKey: 'certificateHasExceptionalCircumstances',
        type: FilterTypeEnum.CHECKBOX,
        label: 'has exceptional circumstance',
        defaultValue: null,
      },
    ],
    header: 'Exceptional Circumstances',
    width: '250px',
    hidable: true,
  },
  {
    cellComponent: AdditionalInformationCell,
    header: 'Additional Information',
    width: '250px',
  },
];
