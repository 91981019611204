import { BaseQueryError } from 'store/apiSlice';

export const getBaseQueryError = (
  data?: string | string[]
): { error: BaseQueryError } => ({
  error: {
    response: {
      data,
    },
  },
});
