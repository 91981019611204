import qs from 'query-string';

import { Nullable } from 'types';

export class QueryParams {
  static getAllParams(): qs.ParsedQuery<string> {
    return qs.parse(window.location.search, { sort: false });
  }

  static getParam(name: string): Nullable<string> {
    const params = QueryParams.getAllParams();
    return params[name] as Nullable<string>; // the value of param is expected to be string or null
  }

  static updateQueryParams(
    object: Record<string, unknown>,
    mergeParams = true
  ): string {
    return qs.stringify(
      { ...(mergeParams && { ...QueryParams.getAllParams() }), ...object },
      { sort: false, skipNull: true, arrayFormat: 'bracket-separator' }
    );
  }

  static stringifyParams(object: Record<string, unknown>): string {
    return qs.stringify(object, {
      sort: false,
      arrayFormat: 'bracket-separator',
    });
  }

  static parseParams(parsedValue: string): unknown {
    return qs.parse(parsedValue, {
      sort: false,
      arrayFormat: 'bracket-separator',
      parseBooleans: true,
    });
  }

  static removeParams(url: string, keys: string[]): string {
    return qs.exclude(url, keys);
  }

  static isQueryParamValid = (param: Nullable<string>): boolean =>
    param !== undefined && param !== null && !Number.isNaN(param);
}
