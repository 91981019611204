import { styled } from '@mui/material';
import React, { FC } from 'react';

import { ActionsDropdown } from 'components/molecules/ActionDropdown';

import { useAuditAppointmentDetailsDropdown } from '../../../hooks/useAuditAppointmentDetailsDropdown';

const StyledDropdownMenu = styled(ActionsDropdown)`
  margin-left: auto;
`;

export const AppointmentDetailsActionsDropdown: FC = () => {
  const dropdownConfig = useAuditAppointmentDetailsDropdown();

  return <StyledDropdownMenu {...dropdownConfig} />;
};
