import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { AlertsState, LoaderStatusEnum, Nullable } from 'types';
import apiClient from 'utils/apiClient';
import { createErrorAlertState } from 'utils/createAlertsState';
import { formatToAlerts } from 'utils/formatToAlerts';

import { DeleteBlackoutTimeParams, DeleteBlackoutTimeState } from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';

const initialState: DeleteBlackoutTimeState = {
  loading: LoaderStatusEnum.IDLE,
  alertsState: null,
};

const reducerName = `${parentReducerName}/deleteBlackoutTime`;

// thunks
export const deleteBlackoutTime = createAsyncThunk(
  reducerName,
  async (params: DeleteBlackoutTimeParams, { rejectWithValue }) => {
    try {
      const { appointmentId, blackoutTimeId, actionComment } = params;
      const data = { actionComment };
      await apiClient.delete(
        endpoints.BLACKOUT_TIME.DELETE(appointmentId, blackoutTimeId),
        {
          data,
        }
      );
    } catch (err) {
      const errors = formatToAlerts(err);
      return rejectWithValue(errors);
    }
    return true;
  }
);

// slice
export const deleteBlackoutTimeSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetDeleteBlackoutTimeState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteBlackoutTime.pending, (state) => {
      state.loading = LoaderStatusEnum.LOADING;
      state.alertsState = null;
    });
    builder.addCase(deleteBlackoutTime.fulfilled, (state) => {
      state.loading = LoaderStatusEnum.SUCCESS;
      state.alertsState = null;
    });
    builder.addCase(deleteBlackoutTime.rejected, (state, action) => {
      state.loading = LoaderStatusEnum.ERROR;
      state.alertsState = createErrorAlertState(action.payload);
    });
  },
});

// actions
export const { resetDeleteBlackoutTimeState } = deleteBlackoutTimeSlice.actions;

// selectors
export const selectDeleteBlackoutTimeLoading = (
  state: RootState
): LoaderStatusEnum => state[parentReducerName].deleteBlackoutTime.loading;
export const selectDeleteBlackoutTimeAlertsState = (
  state: RootState
): Nullable<AlertsState> =>
  state[parentReducerName].deleteBlackoutTime.alertsState;

// reducer
export default deleteBlackoutTimeSlice.reducer;
