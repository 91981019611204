import { Grid, styled, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/atoms/Button';
import { SPANISH_GRAY } from 'config/appColors';
import { SelectCertificationBodyModal } from 'domains/certificationBody';

import { useAuditDraftTrigger } from '../../../hooks/useAuditDraftTrigger';
import { useCertificateDraftTrigger } from '../../../hooks/useCertificateDraftTrigger';

const StyledHeading = styled(Typography)`
  font-size: 2.4rem;
  line-height: 2rem;
  letter-spacing: 0.018rem;
  font-weight: 400;
  color: ${SPANISH_GRAY};
`;

const StyledButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

type ProcessType = 'auditDraft' | 'certificateDraft';

const texts = {
  auditDraft: {
    heading: 'Audit/assessment overview',
    button: 'Upload Audit/Assessment',
  },
  certificateDraft: {
    heading: 'Certificates/Letters of confirmation',
    button: 'Create certificate',
  },
};

const hooks = {
  auditDraft: useAuditDraftTrigger,
  certificateDraft: useCertificateDraftTrigger,
};

interface CustomTitleProps {
  variant: ProcessType;
}

export const CustomTitle: FC<CustomTitleProps> = ({ variant }) => {
  const [t] = useTranslation('supplier');
  const { location } = useHistory();
  const { button, heading } = texts[variant];
  const useDraftTrigger = hooks[variant];
  const {
    closeCbModal,
    handleUploadProcess,
    isCbModalOpen,
    showButton,
    handleCbNextAction,
  } = useDraftTrigger();

  useEffect(() => {
    // when user comes directly from URL
    if (location.state === 'CB_REQUIRED') handleUploadProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <StyledHeading variant="h3">{t(heading)}</StyledHeading>
        </Grid>
        {showButton && (
          <Grid item>
            <StyledButtonContainer>
              <Button
                variant="contained"
                color="info"
                onClick={handleUploadProcess}
              >
                {t(button)}
              </Button>
            </StyledButtonContainer>
          </Grid>
        )}
      </Grid>
      <SelectCertificationBodyModal
        open={isCbModalOpen}
        handleClose={closeCbModal}
        nextAction={handleCbNextAction}
      />
    </>
  );
};
