import * as yup from 'yup';

import { generalDetailsSchema, relatedSupplierSchema } from 'schemas';

import { Release } from '../../types';

export const releaseSchema: yup.ObjectSchema<Release> = yup.object({
  uuid: yup.string().required(),
  type: yup.string().required(),
  supplier: relatedSupplierSchema.required(),
  releasedForCompany: relatedSupplierSchema.required(),
  timeFrame: generalDetailsSchema.required(),
  referencedAudit: yup.object({
    uuid: yup.string().required(),
    auid: yup.string().required(),
  }),
  moduleFamily: generalDetailsSchema.required(),
  content: yup.array().of(yup.string().required()).required(),
});
