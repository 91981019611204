import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { removeSpacesFromString } from 'utils/removeSpacesFromString';
import { isActionCommentFieldValid } from 'utils/validation/isActionCommentFieldValid';

import {
  COMMON_CIRCUMSTANCE_TYPES,
  MODAL_FORM_FIELD_MIN_CHARS,
} from '../config/constants';
import { AddUpdateCircumstanceRequestBody } from '../state/exceptionalCircumstance/types';
import { selectCertificateDetailsModalFormValues } from '../state/modal/slice';

interface UseModalCircumstanceRequiredFields {
  fieldValues: AddUpdateCircumstanceRequestBody;
  isFormValid: boolean;
}

export const useModalCircumstanceRequiredFields =
  (): UseModalCircumstanceRequiredFields => {
    const commonCircumstancecTypesValues = COMMON_CIRCUMSTANCE_TYPES.map(
      ({ value }) => value
    );
    const {
      circumstanceDescription = '',
      circumstanceType = '',
      actionComment,
    } = useSelector(selectCertificateDetailsModalFormValues);
    const isAdmin = useSelector(selectUserIsAdmin);
    const isActionCommentValid = isAdmin
      ? isActionCommentFieldValid(
          actionComment.reason,
          actionComment.comment || ''
        )
      : true;

    const isFormValid = !!(
      circumstanceType &&
      commonCircumstancecTypesValues.includes(circumstanceType) &&
      removeSpacesFromString(circumstanceDescription).length >=
        MODAL_FORM_FIELD_MIN_CHARS &&
      isActionCommentValid
    );

    return {
      fieldValues: {
        type: circumstanceType,
        description: circumstanceDescription,
        ...(isAdmin ? { actionComment } : {}),
      },
      isFormValid,
    };
  };
