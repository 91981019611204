import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { LoaderStatusEnum, ModalProps } from 'types';

import {
  selectAdminActionComment,
  selectDueDateLoading,
  selectDueDateSelected,
  setDueDate,
} from '../../../state/dueDate/slice';
import { SetDueDateParams } from '../../../state/dueDate/types';
import { AuditAppointmentBaseModal } from '../AuditAppointmentBaseModal';
import { SetDueDateModalContent } from '../SetDueDateModalContent';

interface SetDueDateModalProps extends ModalProps {
  appointmentId?: string;
}

export const SetDueDateModal: FC<SetDueDateModalProps> = (props) => {
  const { open, handleClose, appointmentId } = props;
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const loadingStatus = useSelector(selectDueDateLoading);
  const selectedDueDate = useSelector(selectDueDateSelected);
  const selectedAdminActionComment = useSelector(selectAdminActionComment);
  const [isAdminInfoValid, setIsAdminInfoValid] = useState(false);
  const isAdmin = useSelector(selectUserIsAdmin);

  const handleSave = () => {
    if (selectedDueDate.start && selectedDueDate.end && appointmentId) {
      const data: SetDueDateParams = {
        appointmentId,
        startDate: selectedDueDate.start,
        endDate: selectedDueDate.end,
        actionComment:
          isAdminInfoValid && selectedAdminActionComment
            ? selectedAdminActionComment
            : undefined,
      };
      dispatch(setDueDate(data));
    }
  };

  return (
    <AuditAppointmentBaseModal open={open}>
      <TemplateAuditModal
        title={t('Set Due Date')}
        contentComponent={
          <SetDueDateModalContent
            setIsAdminInfoValid={setIsAdminInfoValid}
            isAdmin={isAdmin}
          />
        }
        closeAction={handleClose}
        prevAction={handleClose}
        nextAction={handleSave}
        noPrevArrow
        noNextArrow
        prevLabel={t('Cancel')}
        nextLabel={t('Save', { ns: 'components' })}
        prevColor="inherit"
        nextColor="primary"
        disableNext={!selectedDueDate.end || (isAdmin && !isAdminInfoValid)}
        nextButtonLoading={loadingStatus === LoaderStatusEnum.LOADING}
        hidePrevButton={loadingStatus === LoaderStatusEnum.LOADING}
      />
    </AuditAppointmentBaseModal>
  );
};
