import { styled } from '@mui/material';
import { get } from 'lodash';
import React, { ChangeEvent, FC } from 'react';

import { DynamicFormItemLabel } from 'components/atoms/DynamicFormItemLabel';
import { WithUndefined } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { StyledSubItemsContainer } from './StyledElements';
import {
  DynamicFormCommonProps,
  DynamicFormItemCommonProps,
  RadioType,
} from './types';
import { Radio as RadioButton } from '../../atoms/Radio';

const StyledContainer = styled('div')`
  padding-left: 10px;
`;

type RadioProps = RadioType &
  DynamicFormCommonProps &
  DynamicFormItemCommonProps;

export const Radio: FC<RadioProps> = ({
  props,
  values,
  validationState,
  setValue,
  validateFormItem,
  subItems,
}) => {
  const { label, dataRef, options } = props;
  const value = get(values, dataRef) || '';
  const error = get(validationState, dataRef) as WithUndefined<string>;

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(dataRef, target.value);
    validateFormItem(target.value, dataRef);
  };

  return (
    <div>
      <DynamicFormItemLabel text={label} error={error} />
      <StyledContainer>
        {options.map(({ text, value: optionValue }) => (
          <RadioButton
            key={optionValue}
            label={getTranslatedValue(text)}
            value={optionValue}
            checked={optionValue === value}
            onChange={handleChange}
          />
        ))}
        {!!subItems && (
          <StyledSubItemsContainer>{subItems}</StyledSubItemsContainer>
        )}{' '}
      </StyledContainer>
    </div>
  );
};
