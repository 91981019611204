import routes from 'config/routes';

import { UUID_V4 } from '../regex/uuid';

export const getSupplierPathname = (
  supplierId: string,
  tabName?: string
): string => {
  let pathname = routes.SUPPLIER_DETAILS.replace(
    `:supplierId(${UUID_V4})`,
    supplierId
  );

  if (tabName) {
    pathname = `${pathname}?tab=${tabName}`;
  }

  return pathname;
};
