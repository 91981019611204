import React, { FC } from 'react';

import { ChangeCertificationBodyModal } from 'domains/certificationBody/components/organisms/ChangeCertificationBodyModal';

import { closeAndResetCertificateModal } from '../../../state/modal/slice';
import { CertificateModalCommonProps } from '../../../types';

type CertificateCBChangeModalProps = CertificateModalCommonProps;

export const CertificateCBChangeModal: FC<CertificateCBChangeModalProps> = ({
  certificateUuid,
}) => (
  <ChangeCertificationBodyModal
    collection="certificates"
    relatedEntityUuid={certificateUuid}
    closeAction={closeAndResetCertificateModal}
  />
);
