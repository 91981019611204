import ArrowLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms/Button';
import { TemplateResponseStatus } from 'components/templates/TemplateResponseStatus';
import { LoaderStatusEnum } from 'types';

export interface LoginResponseStatusProps {
  status: LoaderStatusEnum;
  closeAction: () => void;
  title: string;
  responseMessage?: string;
}

const StyledResponseWrapper = styled('div')`
  p + button {
    margin-top: 30px;
  }
`;

export const LoginResponseStatus: FC<LoginResponseStatusProps> = ({
  status,
  responseMessage,
  closeAction,
  title,
}) => {
  const [t] = useTranslation('components');

  return (
    <StyledResponseWrapper>
      <TemplateResponseStatus
        type={status}
        title={t(title)}
        description={responseMessage}
        closeAction={closeAction}
        renderCustomClose={(close) => (
          <>
            {status !== LoaderStatusEnum.LOADING && (
              <Button type="button" variant="outlined" onClick={close}>
                <ArrowLeftIcon fontSize="large" /> {t('Back')}
              </Button>
            )}
          </>
        )}
      />
    </StyledResponseWrapper>
  );
};

export default LoginResponseStatus;
