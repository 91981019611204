import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms/Button';

import { useDownloadOldLogsMutation } from '../../../state/logsTab/api';

interface DownloadOldLogsButtonProps {
  auditUuid: string;
}

export const DownloadOldLogsButton: FC<DownloadOldLogsButtonProps> = ({
  auditUuid,
}) => {
  const [t] = useTranslation('auditDetails');
  const { downloadOldLogsFile, isLoading } = useDownloadOldLogsMutation();
  const disabled = !auditUuid || isLoading;

  return (
    <Button
      variant="contained"
      color="info"
      size="small"
      disabled={disabled}
      onClick={() => downloadOldLogsFile({ auditUuid })}
    >
      {t('Load old history')}
    </Button>
  );
};
