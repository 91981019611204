export const parentReducerName = 'user';
export const MOCKED_PASSWORD = 'ifs';
export const MOCKED_USER_CREDENTIALS = {
  username: 'user',
  password: MOCKED_PASSWORD,
};
export const MOCKED_ADMIN_CREDENTIALS = {
  username: 'admin',
  password: MOCKED_PASSWORD,
};
export const availableLogins = [
  MOCKED_ADMIN_CREDENTIALS.username,
  MOCKED_USER_CREDENTIALS.username,
];
export const REFRESH_TOKEN_COOKIE_NAME = 'mocked_refresh_token';
