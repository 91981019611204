import LockIcon from '@mui/icons-material/Lock';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { alpha, styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { BLACK, ERROR } from 'config/appColors';

const StyledCell = styled('div')`
  display: flex;
  align-items: center;
`;

const StyledTrimmedText = styled('span')`
  max-width: 14ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface StyledIconProps {
  $color: string;
}

const StyledIcon = styled('div')<StyledIconProps>`
  margin-left: auto;
  margin-right: 12px;
  font-size: 2.1rem;
  color: ${({ $color }) => $color};
`;

const ICONS = {
  lock: LockIcon,
  lockOpenOutlined: LockOpenOutlinedIcon,
};

export type TableCellIconName = keyof typeof ICONS;

const VARIANTS = {
  error: ERROR.LIGHT,
  light: alpha(BLACK, 0.3),
};

export type TableCellColorVariant = keyof typeof VARIANTS;

interface TableCellWithIconProps {
  label?: ReactNode;
  showLockIcon?: boolean;
  className?: string;
  useTrimmedText?: boolean;
  variant?: TableCellColorVariant;
  iconName: TableCellIconName;
}

export const TableCellWithIcon: FC<TableCellWithIconProps> = ({
  label = '-',
  showLockIcon = false,
  className,
  useTrimmedText = false,
  variant = 'error',
  iconName,
}) => {
  const finalLabel = useTrimmedText ? (
    <StyledTrimmedText>{label}</StyledTrimmedText>
  ) : (
    label
  );

  const color = VARIANTS[variant];
  const icon = ICONS[iconName];

  return (
    <StyledCell className={className}>
      {finalLabel}
      {showLockIcon && <StyledIcon as={icon} $color={color} />}
    </StyledCell>
  );
};
