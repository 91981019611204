import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LogsTab } from 'domains/auditDetails/components/organisms/LogsTab';

import { AdminAudit } from '../../../types';
import { TemplateModal } from '../../templates/TemplateModal';

export const AuditLogsModal: FC<AdminAudit> = ({ uuid }) => {
  const [t] = useTranslation('adminReports');

  return (
    <TemplateModal title={t('Audit Logs')}>
      <LogsTab
        auditUuid={uuid}
        headerText={t('Details', { ns: 'auditAppointment' })}
      />
    </TemplateModal>
  );
};
