import { styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SearchField } from 'components/molecules/SearchField';
import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import {
  NextAction,
  TemplateModalWithSnackbar,
} from 'components/templates/TemplateModalWithSnackbar';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';
import { useMemoizedDebounce } from 'hooks/useMemoizedDebounce';
import { Nullable, Option } from 'types';
import { emitTabClick } from 'utils/emitTabClick';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { AUDIT_RELEASE_TYPE } from '../../../config/constants';
import { useReleaseCommonFormFields } from '../../../hooks/useReleaseCommonFormFields';
import { useGetAuditDetailsQuery } from '../../../state/auditDetails/api';
import { useGetAuditDetailsTemplateQuery } from '../../../state/auditDetailsTemplate/api';
import { closeAndResetAuditModal } from '../../../state/modal/slice';
import { AuditModalCommonProps } from '../../../state/modal/types';
import {
  useCreateAuditReleaseMutation,
  useLazyGetCompanyListQuery,
} from '../../../state/releaseManage/api';
import { isReleasesTab } from '../../../types';
import { ReleaseCommonFormFields } from '../ReleaseCommonFormFields';

type CreateReleaseModalProps = AuditModalCommonProps;

const StyledSearchField = styled(SearchField)`
  .SearchField-description {
    margin-top: 0;
  }

  .MuiFormControl-root {
    width: 100%;
  }
`;

export const CreateReleaseModal: FC<CreateReleaseModalProps> = (props) => {
  const { auditUuid, supplierId } = props;
  const { auditDetails: { module } = {} } = useGetAuditDetailsQuery({
    auditUuid,
  });
  const {
    contentOptions,
    contentCheckboxValues,
    content,
    handleContentChange,
    timeFrameOptions,
    timeFrameId,
    setTimeFrameId,
  } = useReleaseCommonFormFields(auditUuid);
  const [t] = useTranslation('auditDetails');
  const dispatch = useDispatch();
  const [companyId, setCompanyId] = useState('');

  const { auditDetailsTemplate } = useGetAuditDetailsTemplateQuery(auditUuid);
  const releaseTab =
    auditDetailsTemplate?.template?.children.find(isReleasesTab);
  const releaseTabName = releaseTab?.props?.name;

  const {
    createRelease,
    isLoading,
    error = [],
  } = useCreateAuditReleaseMutation();
  const closeMethod = () => {
    dispatch(closeAndResetAuditModal());
  };
  const nextAction: NextAction = (openSnackbar, closeModal) => {
    createRelease({
      type: AUDIT_RELEASE_TYPE,
      supplierId,
      releasedForCompanyId: companyId,
      timeFrameId,
      referencedAuditId: auditUuid,
      content,
    }).then(() => {
      openSnackbar(t('Audit release saved.'), 'success');
      closeModal();
      if (releaseTabName) emitTabClick(getTranslatedValue(releaseTabName));
    });
  };

  const alertsState = useMemoizedAlerts(error);
  const moduleFamily = module?.name || ''; // @ToDo - change to 'family' when API will be ready
  const modalTitle = t('Add audit/assessment release: {{moduleFamily}}', {
    moduleFamily,
  });
  const disableNextButton = !companyId || !timeFrameId || !content.length;

  const {
    searchCompany,
    companies,
    isLoading: isCompanySearchLoading,
  } = useLazyGetCompanyListQuery();
  const fetchCompanyList = (searchTerm: string) => searchCompany(searchTerm);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCompanyListDebounced = useMemoizedDebounce(fetchCompanyList);
  const handleCompanySelect = (option: Nullable<Option>) => {
    setCompanyId(option ? option.value : '');
  };
  const companyOptions = companies.map(({ name, uuid, coid }) => ({
    value: uuid,
    label: `${name} (COID: ${coid})`,
  }));

  return (
    <TemplateModalWithSnackbar
      title={modalTitle}
      nextAction={nextAction}
      nextLabel={t('Save', { ns: 'components' })}
      disableNext={disableNextButton}
      isLoading={isLoading}
      nextColor="primary"
      closeMethod={closeMethod}
    >
      <StyledBaseModalContent alertsState={alertsState}>
        <StyledSearchField
          options={companyOptions}
          loading={isCompanySearchLoading}
          onValueSelect={handleCompanySelect}
          onSearchTermChange={fetchCompanyListDebounced}
          description={t('Company/COID')}
          placeholder={t('search by name or COID')}
          infoMessage
          noOptionsMessage={t('No Company found.')}
          dataTestId="company-coid-search"
        />
        <ReleaseCommonFormFields
          contentOptions={contentOptions}
          handleContentChange={handleContentChange}
          contentCheckboxValues={contentCheckboxValues}
          timeFrameOptions={timeFrameOptions}
          timeFrameId={timeFrameId}
          setTimeFrameId={setTimeFrameId}
        />
      </StyledBaseModalContent>
    </TemplateModalWithSnackbar>
  );
};
