import { Params } from '../../types';

interface FormatQueryParams {
  filter: {
    status: string[];
  };
  sort?: string;
  limit: number;
  offset: number;
}

export const formatQueryParams = (
  params: Omit<Params<string>, 'supplierId'>
): FormatQueryParams => {
  const { limit, offset, sort, status } = params;
  return {
    limit,
    offset,
    ...(sort && { sort }),
    filter: {
      status,
    },
  };
};
