import React, { FC } from 'react';

import { Logger } from 'components/atoms/Logger';
import { DynamicForm, useDynamicForm } from 'components/organisms/DynamicForm';
import { FormConfig, TemplateCmpTypeEnum, WithUndefined } from 'types';

import { useCertificateDraftAddDataMutation } from '../../../state/certificateDraftData/api';
import { useCertificateDraftDetailsQuery } from '../../../state/certificateDraftDetails/api';
import { FormScopeDataStep } from '../../../types';
import { TemplateCertificateDraftStep } from '../../templates/TemplateCertificateDraftStep';

type FormScopeDataProps = FormScopeDataStep & {
  certificateDraftId: string;
};

export const FormScopeData: FC<FormScopeDataProps> = ({
  props,
  certificateDraftId,
  children,
}) => {
  const { backAction, nextAction } = props;

  const {
    isSuccess: isDraftSuccess,
    certificateDraftDetails,
    status: draftDetailsStatus,
  } = useCertificateDraftDetailsQuery({ certificateDraftId });

  const {
    addData,
    error,
    status: addDataStatus,
  } = useCertificateDraftAddDataMutation();

  const template = children.find(
    (child) => child.type === TemplateCmpTypeEnum.FORM
  ) as WithUndefined<FormConfig>;

  const dynamicFormProps = useDynamicForm({
    draft: certificateDraftDetails,
    template,
    isDraftSuccess,
    isTemplateSuccess: true,
  });

  const { values, isFormValid } = dynamicFormProps;

  const handleNextClick = (goNextStep: () => void) => {
    if (values) {
      addData({ certificateDraftId, data: values }).then(goNextStep);
    }
  };

  return (
    <TemplateCertificateDraftStep
      contentStatus={draftDetailsStatus}
      nextButtonStatus={addDataStatus}
      backAction={backAction}
      nextAction={nextAction}
      onNextClick={handleNextClick}
      disableNext={!isFormValid}
      error={error}
    >
      {children?.map((child) => {
        if (child.type === TemplateCmpTypeEnum.FORM) {
          return <DynamicForm key={child.type} {...dynamicFormProps} />;
        }

        return (
          <Logger
            key={child.type}
            message={`System encountered unsupported type of child: ${child.type}`}
          />
        );
      })}
    </TemplateCertificateDraftStep>
  );
};
