import { alpha, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StatusIndicator } from 'components/atoms/StatusIndicator';
import { StyledResult } from 'components/atoms/StyledResult';
import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';
import { SortStateType } from 'components/organisms/Table/types';
import { BLACK, ERROR, WARNING } from 'config/appColors';
import { TableCellWithLockIcon } from 'domains/supplier/components/atoms/TableCellWithLockIcon';
import {
  activeCertificateStatuses,
  inactiveCertificateStatuses,
} from 'domains/supplier/config/constants';
import { useGetCertificateListQuery } from 'domains/supplier/state/certificateList/api';
import { selectSupplierDetails } from 'domains/supplier/state/details/slice';
import {
  CertificateDetails,
  CertificateStatusEnum,
} from 'domains/supplier/types';
import { useCertificateDetailsLink } from 'domains/widgets/hooks/useCertificateDetailsLink';
import { Nullable } from 'types';
import { formatDate } from 'utils/dates';
import { generateId } from 'utils/generateId';

const StyledTable = styled(Table)`
  table,
  .skeleton-container {
    min-width: 1060px;
  }

  .skeleton-container {
    padding: 0 10px;
  }
` as typeof Table;

const StyledStatusIndicator = styled(StatusIndicator)`
  margin-right: 2px;
  margin-top: 2px;
`;

interface CertificateListTableProps {
  showInactiveCertificates?: boolean;
}

const CERTIFICATE_STATUS_COLORS: Record<string, string> = {
  [CertificateStatusEnum.WITHDRAWN]: WARNING.LIGHT,
  [CertificateStatusEnum.SUSPENDED]: ERROR.LIGHT,
  [CertificateStatusEnum.EXPIRED]: alpha(BLACK, 0.26),
  [CertificateStatusEnum.DEPRECATED]: alpha(BLACK, 0.26),
};

export const CertificateListTable: FC<CertificateListTableProps> = (props) => {
  const { showInactiveCertificates } = props;
  const [t] = useTranslation('supplier');

  const supplierId = useSelector(selectSupplierDetails)?.uuid || '';
  const { pagination, limit, offset, setTotalRowsAmount } = usePagination();
  const [sortState, setSortState] = useState<Nullable<SortStateType>>(null);
  const handleRowClick = useCertificateDetailsLink();
  const onSortClick = (newSortState: Nullable<SortStateType>) => {
    setSortState(newSortState);
  };

  const currentStatus = showInactiveCertificates
    ? inactiveCertificateStatuses
    : activeCertificateStatuses;

  const {
    certificateList,
    status: requestStatus,
    error,
    total,
  } = useGetCertificateListQuery({
    limit,
    offset,
    status: currentStatus,
    supplierId,
    ...(sortState && { sort: `${sortState.key}:${sortState.direction}` }),
  });

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const { getColumnConfig, getColumnConfigWithEllipsis } =
    useColumnConfig<CertificateDetails>('10%');

  const inactiveCertificateColumns: Column<CertificateDetails>[] = [
    {
      // TODO: PL4FE-205 remove single StyledStatusIndicator
      customCell: ({ status }) =>
        Array.isArray(status) ? (
          status.map(({ id, name }, index) => (
            <StyledStatusIndicator
              key={generateId(id, index)}
              label={name}
              bgColor={CERTIFICATE_STATUS_COLORS[id]}
            />
          ))
        ) : (
          <StyledStatusIndicator
            label={status?.name}
            bgColor={CERTIFICATE_STATUS_COLORS[status?.id]}
          />
        ),
      headerName: t('Status'),
      sortKey: 'status',
      onSortClick,
      ...getColumnConfig('10%'),
    },
  ];

  const columns: Column<CertificateDetails>[] = [
    {
      customCell: (certificate) => (
        <TableCellWithLockIcon certificate={certificate} />
      ),
      headerName: t('Certificate/letter of confirmation ID (CEID)'),
      ...getColumnConfig('10%'),
    },
    {
      customCell: ({ module }) => module?.name,
      headerName: t('Standards/Programs'),
      sortKey: 'module',
      onSortClick,
      ...getColumnConfigWithEllipsis('17%'),
    },
    ...(showInactiveCertificates ? inactiveCertificateColumns : []),
    {
      customCell: ({ certificationBody }) => certificationBody?.name,
      headerName: t('Certification body/assessment service provider'),
      sortKey: 'certificationBody',
      onSortClick,
      ...getColumnConfigWithEllipsis('17%'),
    },
    {
      customCell: ({ validity }) => formatDate(validity?.end),
      headerName: t('Valid until', { ns: 'components' }),
      sortKey: 'validity.end',
      onSortClick,
      ...getColumnConfig('10%'),
    },
    {
      customCell: ({ auditResult }) =>
        auditResult && (
          <StyledResult $color={auditResult?.overall?.color}>
            {auditResult?.overall?.text}
          </StyledResult>
        ),
      headerName: t('Result'),
      sortKey: 'result',
      onSortClick,
      ...getColumnConfigWithEllipsis('17%'),
    },
  ];

  return (
    <StyledTable
      onRowClick={handleRowClick}
      rows={certificateList}
      columns={columns}
      status={requestStatus}
      error={error}
      pagination={pagination}
      sortState={sortState}
      dense
    />
  );
};
