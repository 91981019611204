import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { LoaderStatusEnum } from 'types';
import { PLAIN_DATE_REGEX_WITH_DOTS } from 'utils/regex/date';

import { AdminAuditListParams, AdminAuditListResponse } from './types';
import { endpoints } from '../../config/endpoints';
import {
  ExtendedLoaderStatusEnum,
  LoaderStatusWithEmptyEnum,
} from '../../types';

const initialState: AdminAuditListResponse = {
  audits: [],
  total: 0,
};

const convertGetAdminAuditListParams = ({
  filter = {},
  ...rest
}: AdminAuditListParams) => {
  const adjustedFilter: Record<string, unknown> = {};
  Object.entries(filter).forEach(([key, value]) => {
    if (typeof value === 'string' && PLAIN_DATE_REGEX_WITH_DOTS.test(value)) {
      const [day, month, year] = value.split('.');
      adjustedFilter[key] = `${year}-${month}-${day}`;
    }
    /**
     * Other params transformations go here
     */
  });

  return { filter: { ...filter, ...adjustedFilter }, ...rest };
};

const allAuditsListApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAdminAuditList: build.query<
      AdminAuditListResponse,
      AdminAuditListParams
    >({
      query: (params) => ({
        url: endpoints.ALL_AUDITS,
        params: convertGetAdminAuditListParams(params),
      }),
      providesTags: ['ADMIN_AUDIT_LIST'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetAdminAuditListQuery = () => {
  const [getAllAudits, query] =
    allAuditsListApi.useLazyGetAdminAuditListQuery();
  let status: ExtendedLoaderStatusEnum = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  if (status === LoaderStatusEnum.SUCCESS && query.data?.audits.length === 0) {
    status = LoaderStatusWithEmptyEnum.EMPTY;
  }

  return {
    ...query,
    auditList: query.data?.audits || initialState.audits,
    total: query.data?.total || initialState.total,
    error,
    status,
    getAllAudits,
  };
};
