import { styled } from '@mui/material';
import React, { FC } from 'react';

interface LogInfoCellProps {
  info: string[];
  cellId: string;
}

const StyledInfoList = styled('ul')`
  margin: 0;
  padding-left: 25px;
`;

export const LogInfoCell: FC<LogInfoCellProps> = ({ info, cellId }) => {
  if (!info.length) return <>-</>;
  if (info.length === 1) return <>{info[0]}</>;

  return (
    <StyledInfoList>
      {info.map((item, index) => {
        const key = `info-${cellId}_${index + 1}`;
        return <li key={key}>{item}</li>;
      })}
    </StyledInfoList>
  );
};
