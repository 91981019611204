import { styled } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Textarea } from 'components/atoms/Textarea';
import { SearchField } from 'components/molecules/SearchField';
import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import {
  NextAction,
  TemplateModalWithSnackbar,
} from 'components/templates/TemplateModalWithSnackbar';
import { useMemoizedDebounce } from 'hooks/useMemoizedDebounce';
import { AlertVariantEnum, Nullable, Option } from 'types';

import { useChangeCertificationBodyMutation } from '../../../state/changeCertificationBody/api';
import { Collection } from '../../../state/changeCertificationBody/types';
import { useLazyGetCertificationBodyListQuery } from '../../../state/search/api';

const StyledSearchField = styled(SearchField)`
  margin-bottom: 30px;

  .MuiFormControl-root {
    width: 100%;
  }
`;

const COMMENT_MIN_LENGTH = 3;

interface Content {
  title: string;
}

const content: Record<Collection, Content> = {
  certificates: {
    title: 'Change Certification Body for Certificate',
  },
  audits: {
    title: 'Change Certification Body for Audit',
  },
};

interface ChangeCertificationBodyModalProps {
  relatedEntityUuid: string;
  collection: Collection;
  closeAction: () => void;
}

export const ChangeCertificationBodyModal: FC<ChangeCertificationBodyModalProps> =
  ({ closeAction, relatedEntityUuid, collection }) => {
    const dispatch = useDispatch();
    const closeMethod = () => {
      dispatch(closeAction());
    };

    const { title } = content[collection];

    const {
      certificationBodyList,
      searchCertificationBodyList,
      error: certificationBodyListError,
      isLoading: isGetCertificationBodyListLoading,
    } = useLazyGetCertificationBodyListQuery();

    const certificationBodyOptions = certificationBodyList.map(
      ({ name, uuid, cbid }) => ({
        value: uuid,
        label: `${name} ${cbid ? `(${cbid})` : ''}`,
      })
    );

    const {
      changeCertificationBody,
      error: changeCertificationBodyError,
      isLoading: isChangeCertificationBodyLoading,
    } = useChangeCertificationBodyMutation();

    const [t] = useTranslation('certificationBody');
    const [certificationBodyId, setCertificationBodyId] = useState('');
    const [comment, setComment] = useState('');

    const searchCertificationBodies = (searchByName: string) =>
      searchCertificationBodyList({ filter: { searchByName } });

    const fetchCertificationBodies = useMemoizedDebounce(
      searchCertificationBodies
    );

    const handleCertificationBodyChange = (option: Nullable<Option>) =>
      option && setCertificationBodyId(option.value);

    const handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
      setComment(event.target.value);

    const handleNextClick: NextAction = (openSnackbar, closeModal) => {
      changeCertificationBody({
        collection,
        relatedEntityUuid,
        certificationBodyId,
        comment,
      }).then(() => {
        openSnackbar(t('CB Switch requested.'), 'success');
        closeModal();
      });
    };

    const disableNext =
      comment.replaceAll(' ', '').length < COMMENT_MIN_LENGTH ||
      !certificationBodyId;

    return (
      <TemplateModalWithSnackbar
        title={t(title)}
        nextAction={handleNextClick}
        nextLabel={t('Submit', { ns: 'components' })}
        disableNext={disableNext}
        isLoading={isChangeCertificationBodyLoading}
        nextColor="primary"
        closeMethod={closeMethod}
      >
        <StyledBaseModalContent
          alertsState={{
            alerts: [
              ...certificationBodyListError,
              ...changeCertificationBodyError,
            ],
            variant: AlertVariantEnum.ERROR,
          }}
        >
          <StyledSearchField
            options={certificationBodyOptions}
            loading={isGetCertificationBodyListLoading}
            onValueSelect={handleCertificationBodyChange}
            onSearchTermChange={fetchCertificationBodies}
            description={t(
              'Please select the Certification Body/assessment provider'
            )}
            placeholder={t('Search Certification Body', {
              ns: 'certificationBody',
            })}
            noOptionsMessage={t('No Certification Body found.', {
              ns: 'certificationBody',
            })}
            dataTestId="certification-body-search"
          />
          <Textarea
            label={t('Comment', { ns: 'components' })}
            value={comment}
            onChange={handleCommentChange}
            rows={7}
            multiline
            fullWidth
          />
        </StyledBaseModalContent>
      </TemplateModalWithSnackbar>
    );
  };
