import { SelectChangeEvent, styled, Typography } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseModalContent } from 'components/organisms/BaseModalContent';
import { TEXT } from 'config/appColors';

import { actionReasons } from '../../../config/constants';
import { useReactivateAuditAppointmentMutation } from '../../../state/reactivate/api';

interface ReactivateAuditAppointmentModalContentProps {
  setIsAdminInfoValid: Dispatch<SetStateAction<boolean>>;
  adminReason: string;
  adminComment: string;
  adminReasonChangeHandler: (event: SelectChangeEvent<unknown>) => void;
  adminCommentChangeHandler: (comment: string) => void;
}

const StyledHeading = styled(Typography)`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${TEXT.PRIMARY};
  margin: 3px 0 35px;
`;

export const ReactivateAuditAppointmentModalContent: FC<ReactivateAuditAppointmentModalContentProps> =
  (props) => {
    const {
      setIsAdminInfoValid,
      adminReason,
      adminComment,
      adminReasonChangeHandler,
      adminCommentChangeHandler,
    } = props;
    const [t] = useTranslation('auditAppointment');
    const { error: alertsState } = useReactivateAuditAppointmentMutation();

    return (
      <BaseModalContent
        isAdmin // for reactivate audit appointment, action information section is mandatory for all users
        adminReasonOptions={actionReasons.REACTIVATE_AUDIT_APPOINTMENT.ADMIN}
        setIsAdminInfoValid={setIsAdminInfoValid}
        alertsState={alertsState}
        adminReason={adminReason}
        adminComment={adminComment}
        adminReasonChangeHandler={adminReasonChangeHandler}
        adminCommentChangeHandler={adminCommentChangeHandler}
      >
        <StyledHeading>
          {t('Do you really want to reactivate the audit appointment?')}
          <br />
          {t(
            'The audit appointment is deactivated again by an overnight job if no audit is uploaded.'
          )}
        </StyledHeading>
      </BaseModalContent>
    );
  };
