import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { LogsParams, LogsResponse } from 'types';

import endpoints from '../../config/endpoints';

const certificateLogsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateLogs: build.query<LogsResponse, LogsParams>({
      query: ({ uuid, params }) => ({
        url: endpoints.LOGS_TAB.LIST(uuid),
        params,
      }),
      providesTags: ['CERTIFICATE_DETAILS_LOGS'],
    }),
  }),
});

export const useGetCertificateLogsQuery = (params: LogsParams) => {
  const query = certificateLogsApi.useGetCertificateLogsQuery(params);
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  return {
    ...query,
    logs: query.data?.logs || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};
