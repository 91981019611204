import { useDispatch, useSelector } from 'react-redux';

import { setCertificationBodyValue } from 'domains/certificationBody/state/search/slice';
import { CertificationBody } from 'types';

import {
  closeModal,
  openModal,
  selectCertificationModalIsOpen,
} from '../state/modal/slice';

interface UseCertificationBodyModal {
  isCbModalOpen: boolean;
  openCbModal: () => void;
  closeCbModal: () => void;
  handleCbModalNextAction: (
    cb: CertificationBody,
    customCallbackAfterValueSet?: () => void
  ) => void;
}

export const useCertificationBodyModal = (
  defaultCallbackAfterValueSet?: () => void
): UseCertificationBodyModal => {
  const dispatch = useDispatch();
  const isCbModalOpen = useSelector(selectCertificationModalIsOpen);
  const openCbModal = () => {
    dispatch(openModal());
  };
  const closeCbModal = () => {
    dispatch(closeModal());
  };
  const handleCbModalNextAction = (
    certificationBody: CertificationBody,
    customCallbackAfterValueSet?: () => void // for multiple hook usage on same page
  ) => {
    dispatch(setCertificationBodyValue(certificationBody));

    if (defaultCallbackAfterValueSet && !customCallbackAfterValueSet) {
      defaultCallbackAfterValueSet();
    }
    customCallbackAfterValueSet?.();
  };

  return {
    isCbModalOpen,
    openCbModal,
    closeCbModal,
    handleCbModalNextAction,
  };
};
