export const auditOptions = [
  {
    id: 'announced',
    text: {
      en: 'Announced',
      de: 'Angekündigt',
      fr: 'Annoncé',
      es: 'Anunciada',
      it: 'Annunciato',
    },
  },
  {
    id: 'unannounced',
    text: {
      en: 'Unannounced',
      de: 'Unangekündigt',
      fr: 'Non annoncé',
      es: 'No anunciada',
      it: 'Non annunciato',
    },
  },
];
