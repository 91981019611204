import { TableHead as MuiTableHead, TableCell, TableRow } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getNewDirection } from 'components/organisms/Table';
import { StyledArrowUpwardIcon } from 'components/organisms/Table/Table.styled';
import { generateId } from 'utils/generateId';

import { columns } from '../../../config/adminTableColumns';
import { useFiltersAndSorting } from '../../../hooks/useFiltersAndSorting';
import { FilterTypeEnum, TableFilter } from '../../../types';
import { FilterCell } from '../../molecules/FilterCells';

export const AllAuditsTableHead: FC = () => {
  const [t] = useTranslation('adminReports');
  const filtersAndSorting = useFiltersAndSorting();
  const { sortState, setSortState } = filtersAndSorting;

  const handleSortClick = (key: string) => {
    setSortState({ direction: getNewDirection(key, sortState), key });
  };

  const getKey = (filter: TableFilter) => {
    if (filter.type === FilterTypeEnum.DATE_RANGE) {
      return `${filter.startFilterKey}${filter.endFilterKey}`;
    }
    return filter.filterKey;
  };

  return (
    <MuiTableHead className="table-head">
      <TableRow className="table-head__first-row">
        {columns.map(({ header, width, sortKey }) => (
          <TableCell
            key={header}
            style={{ width }}
            onClick={() => sortKey && handleSortClick(sortKey)}
            className={sortKey ? 'sortable' : ''}
          >
            {t(header)}
            {sortKey && sortState?.key === sortKey && (
              <StyledArrowUpwardIcon $direction={sortState?.direction} />
            )}
          </TableCell>
        ))}
      </TableRow>
      <TableRow className="table-head__second-row bg-gray">
        {columns.map((column, index) => {
          const { header, width } = column;
          return (
            <TableCell key={generateId(header, index)} style={{ width }}>
              {'filterComponent' in column &&
                column.filterComponent?.(filtersAndSorting)}
              {'filtersConfig' in column &&
                column.filtersConfig?.map((config) => (
                  <FilterCell
                    {...config}
                    {...filtersAndSorting}
                    key={getKey(config)}
                  />
                ))}
            </TableCell>
          );
        })}
      </TableRow>
    </MuiTableHead>
  );
};
