import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { LoaderStatusEnum } from 'types';
import { invalidateRTKTags } from 'utils/invalidateRTKTags';

import {
  CompanyListParams,
  CreateReleaseParams,
  UpdateReleaseParams,
} from './types';
import endpoints from '../../config/endpoints';
import { Company } from '../../types';

const releaseManageApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createAuditRelease: build.mutation<void, CreateReleaseParams>({
      query: (data) => ({
        url: endpoints.RELEASES.CREATE,
        data,
        method: 'POST',
      }),
      invalidatesTags: invalidateRTKTags(['AUDIT_RELEASES']),
    }),
    getCompanyList: build.query<Company[], CompanyListParams>({
      query: (params) => ({
        url: endpoints.RELEASES.COMPANY_LIST,
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    updateAuditRelease: build.mutation<void, UpdateReleaseParams>({
      query: ({ releaseUuid, ...data }) => ({
        url: endpoints.RELEASES.UPDATE(releaseUuid),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateRTKTags(['AUDIT_RELEASES']),
    }),
    deleteRelease: build.mutation<void, string>({
      query: (releaseUuid: string) => ({
        url: endpoints.RELEASES.DELETE(releaseUuid),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateRTKTags(['AUDIT_RELEASES']),
    }),
  }),
});

export const useCreateAuditReleaseMutation = () => {
  const [createRelease, mutation] =
    releaseManageApi.useCreateAuditReleaseMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: CreateReleaseParams) =>
    createRelease(params).unwrap();

  return {
    ...mutation,
    createRelease: requestMethod,
    status,
    error,
  };
};

export const useLazyGetCompanyListQuery = () => {
  const [trigger, query] = releaseManageApi.useLazyGetCompanyListQuery();
  const status = useRTKQueryStatus(query);
  const isLoading = status === LoaderStatusEnum.LOADING;

  const requestMethod = async (companySearchTerm: string) =>
    trigger({ companySearchTerm });

  return {
    searchCompany: requestMethod,
    companies: query.data || [],
    isLoading,
  };
};

export const useUpdateAuditReleaseMutation = () => {
  const [updateRelease, mutation] =
    releaseManageApi.useUpdateAuditReleaseMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: UpdateReleaseParams) =>
    updateRelease(params).unwrap();

  return {
    ...mutation,
    updateRelease: requestMethod,
    status,
    error,
  };
};

export const useDeleteReleaseMutation = () => {
  const [deleteRelease, mutation] = releaseManageApi.useDeleteReleaseMutation();
  const status = useRTKQueryStatus(mutation);
  const error = useFormattedError(mutation, 'formatToAlerts');

  const requestMethod = async (releaseUuid: string) =>
    deleteRelease(releaseUuid).unwrap();

  return {
    ...mutation,
    deleteRelease: requestMethod,
    error,
    status,
  };
};
