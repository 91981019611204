import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import { CertificationBody, Nullable } from 'types';

import { UserCertificationBodyState } from './types';
import { parentReducerName } from '../../config/constants';

const initialState: UserCertificationBodyState = {
  value: null,
};

const reducerName = `${parentReducerName}/search`;

export const certificationBodySearchSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetCertificationBodyValue: () => initialState,
    setCertificationBodyValue: (
      state,
      action: PayloadAction<CertificationBody>
    ) => {
      state.value = action.payload;
    },
  },
});

// actions
export const { setCertificationBodyValue, resetCertificationBodyValue } =
  certificationBodySearchSlice.actions;

// selectors
export const selectCertificationBodyValue = (
  state: RootState
): Nullable<CertificationBody> => state[parentReducerName].search.value;

// reducer
export default certificationBodySearchSlice.reducer;
