import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, Table, useColumnConfig } from 'components/organisms/Table';
import { useSnackbarNotification } from 'hooks/useSnackbarNotification';
import { Document } from 'types';
import { formatDate } from 'utils/dates';

import {
  useDownloadAuditDetailsDocumentMutation,
  useGetAuditDetailsDocumentsQuery,
} from '../../../state/documentsTab/api';
import { AuditDocumentsTab } from '../../../types';
import { TabSectionHeading } from '../../atoms/TabSectionHeading';
import { DownloadFileButton } from '../../molecules/DownloadFileButton';

type DocumentsTabProps = AuditDocumentsTab['props'] & {
  auditUuid: string;
};

export const DocumentsTab: FC<DocumentsTabProps> = (props) => {
  const { headerText, auditUuid } = props;
  const [t] = useTranslation('auditDetails');
  const { documents, error, status } = useGetAuditDetailsDocumentsQuery({
    auditUuid,
  });
  const { downloadFile } = useDownloadAuditDetailsDocumentMutation();
  const { openSnackbar } = useSnackbarNotification();
  const handleFileDownload = (documentUuid: string, fileName: string) => {
    downloadFile({
      auditUuid,
      documentUuid,
      fileName,
    }).catch(() =>
      openSnackbar(t('Cannot download a file.', { ns: 'components' }), 'error')
    );
  };

  const { getColumnConfig, getColumnConfigWithEllipsis } =
    useColumnConfig<Document>('25%');
  const columns: Column<Document>[] = [
    {
      customCell: ({ type }) => type?.name ?? '-',
      headerName: t('Document type', { ns: 'components' }),
      ...getColumnConfig(),
    },
    {
      field: 'displayName',
      headerName: t('Filename', { ns: 'components' }),
      customCell: (document) => (
        <DownloadFileButton onClick={handleFileDownload} document={document} />
      ),
      ...getColumnConfigWithEllipsis('50%'),
    },
    {
      field: 'uploadedAt',
      headerName: t('Uploaded at', { ns: 'components' }),
      customCell: ({ uploadedAt }) =>
        uploadedAt
          ? formatDate(new Date(uploadedAt), 'MMM d, yyyy, h:mm:ss aa')
          : '-',
      ...getColumnConfig(),
    },
  ];

  return (
    <>
      <TabSectionHeading text={headerText} />
      <Table
        rows={documents}
        columns={columns}
        status={status}
        error={error}
        dense
      />
    </>
  );
};
