import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { removeSpacesFromString } from 'utils/removeSpacesFromString';
import { isActionCommentFieldValid } from 'utils/validation/isActionCommentFieldValid';

import { MODAL_FORM_FIELD_MIN_CHARS } from '../../../config/constants';
import { useUnlockCertificateMutation } from '../../../state/certificateUnlock/api';
import { selectCertificateDetailsModalFormValues } from '../../../state/modal/slice';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { CertificateModalCommonProps } from '../../../types';
import {
  CertificateDetailsModalBase,
  NextAction,
} from '../../templates/CertificateDetailsModalBase';
import { UnlockCertificateForm } from '../UnlockCertificateForm';

export const UnlockModal: FC<CertificateModalCommonProps> = ({
  certificateUuid,
}) => {
  const [t] = useTranslation('certificateDetails');

  const { unlockCertificate, isLoading, error } =
    useUnlockCertificateMutation();
  const { actionComment, reasonDescription } = useSelector(
    selectCertificateDetailsModalFormValues
  );
  const isAdmin = useSelector(selectUserIsAdmin);
  const isActionCommentValid = isAdmin
    ? isActionCommentFieldValid(
        actionComment.reason,
        actionComment.comment || ''
      )
    : true;
  const isReasonDescriptionValid =
    removeSpacesFromString(reasonDescription).length >=
    MODAL_FORM_FIELD_MIN_CHARS;
  const isFormValid = isActionCommentValid && isReasonDescriptionValid;

  const handleReinstateClick: NextAction = (openSnackbar, closeModal) => {
    unlockCertificate({
      certificateUuid,
      data: { reasonDescription, ...(isAdmin ? { actionComment } : {}) },
    }).then(() => {
      openSnackbar(t('The Certificate is reinstated.'), 'success');
      closeModal();
    });
  };

  return (
    <CertificateDetailsModalBase
      title={t('Reinstate certificate/letter of confirmation')}
      nextColor="primary"
      disableNext={!isFormValid}
      nextLabel={t('Reinstate')}
      nextAction={handleReinstateClick}
      isLoading={isLoading}
    >
      <UnlockCertificateForm
        modalType={CertificateDetailsModalEnum.UNLOCK_CERTIFICATE}
        errors={error}
        certificateUuid={certificateUuid}
      />
    </CertificateDetailsModalBase>
  );
};
