import {
  OptionsObject,
  SnackbarKey,
  useSnackbar,
  VariantType,
} from 'notistack';
import React from 'react';

import { SnackbarCloseButton } from 'components/atoms/SnackbarCloseButton';

export type OpenSnackbar = (
  message: string,
  variant: VariantType,
  customOptions?: OptionsObject
) => SnackbarKey;

interface UseSnackbarNotification {
  openSnackbar: OpenSnackbar;
  closeSnackbar: (key: SnackbarKey) => void;
}

export const useSnackbarNotification = (): UseSnackbarNotification => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const openSnackbar = (
    message: string,
    variant: VariantType,
    customOptions?: OptionsObject
  ) =>
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      action: (key) => (
        <SnackbarCloseButton onClick={() => closeSnackbar(key)} />
      ),
      ...customOptions,
    });

  return {
    openSnackbar,
    closeSnackbar: (key: SnackbarKey) => closeSnackbar(key),
  };
};
