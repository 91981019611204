import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AttributeRowBase } from 'components/atoms/AttributeRow/AttributeRowBase';
import { SectionTitle } from 'components/atoms/SectionTitle';
import { ErrorState } from 'components/molecules/ErrorState';
import Loader from 'components/molecules/Loader';
import { Column, Table, useColumnConfig } from 'components/organisms/Table';
import { NEVADA } from 'config/appColors';
import {
  getSupplierAddresses,
  selectSupplierAddresses,
  selectSupplierAddressesError,
  selectSupplierAddressesLoading,
} from 'domains/supplier/state/detailsAddresses/slice';

import {
  selectSupplierDetails,
  selectSupplierDetailsError,
  selectSupplierDetailsLoading,
} from '../../../state/details/slice';
import { Address } from '../../../types';

const StyledContainer = styled('div')`
  padding: 25px;
`;

const StyledAttributeRowsContainer = styled('div')`
  margin-left: 10px;
`;

const StyledDotsIcon = styled(MoreVertIcon)`
  color: ${NEVADA};
`;

const StyledTable = styled(Table)`
  table {
    min-width: 1000px;
  }
` as typeof Table;

interface SectionTitleProps {
  $marginTop?: number;
}

const StyledSectionTitle = styled(SectionTitle)<SectionTitleProps>`
  margin-top: ${(props) => `${props.$marginTop || '0'}px`};
  margin-bottom: 15px;
`;

const GeneralSupplierDetails: FC = () => {
  const [t] = useTranslation('supplier');

  const { coid, name, GLN } = useSelector(selectSupplierDetails) || {};
  const loadingState = useSelector(selectSupplierDetailsLoading);
  const errorState = useSelector(selectSupplierDetailsError);

  if (errorState)
    return (
      <ErrorState
        heading={t('Failed to load supplier details')}
        description={errorState.message}
        errorCode={errorState.details}
      />
    );

  return (
    <>
      <StyledSectionTitle>{t('General')}</StyledSectionTitle>
      <Loader status={loadingState}>
        <StyledAttributeRowsContainer>
          <AttributeRowBase label={t('COID')} value={coid} />
          <AttributeRowBase label={t('Company')} value={name} />
          <AttributeRowBase label={t('GLN')} value={GLN?.join(', ')} />
        </StyledAttributeRowsContainer>
      </Loader>
    </>
  );
};

const SupplierAddresses: FC = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [t] = useTranslation('supplier');

  const dispatch = useDispatch();

  const addresses = useSelector(selectSupplierAddresses);
  const loadingState = useSelector(selectSupplierAddressesLoading);
  const errorState = useSelector(selectSupplierAddressesError);

  const { getColumnConfig } = useColumnConfig<Address>('18%');

  useEffect(() => {
    dispatch(getSupplierAddresses({ supplierId }));
  }, [dispatch, supplierId]);

  const columns: Column<Address>[] = [
    {
      field: 'type',
      headerName: t('Type'),
      ...getColumnConfig(),
    },
    {
      field: 'name',
      headerName: t('Legal Name'),
      ...getColumnConfig(),
    },
    {
      field: 'street',
      headerName: t('Street, No.'),
      ...getColumnConfig(),
    },
    {
      field: 'zipCode',
      headerName: t('Zip-Code'),
      ...getColumnConfig(),
    },
    {
      field: 'country',
      headerName: t('Country'),
      ...getColumnConfig(),
    },
    {
      headerName: '',
      ...getColumnConfig('10%', { otherCellProps: { align: 'right' } }),
      customCell: () => (
        <Button>
          <StyledDotsIcon fontSize="large" />
        </Button>
      ),
    },
  ];

  return (
    <>
      <StyledSectionTitle $marginTop={50}>{t('Addresses')}</StyledSectionTitle>
      <StyledTable
        rows={addresses}
        columns={columns}
        status={loadingState}
        error={errorState}
        dense
      />
    </>
  );
};

export const CompanyDataTabGeneral: FC = () => (
  <StyledContainer>
    <GeneralSupplierDetails />
    <SupplierAddresses />
  </StyledContainer>
);
