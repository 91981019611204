import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { StyledBaseModalContent } from 'components/organisms/StyledBaseModalContent';
import {
  NextAction,
  TemplateModalWithSnackbar,
} from 'components/templates/TemplateModalWithSnackbar';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';

import { useIssueGroupCertificateMutation } from '../../../state/issueGroupCertificate/api';
import { closeAndResetAuditModal } from '../../../state/modal/slice';
import { AuditModalCommonProps } from '../../../state/modal/types';

const StyledTemplateModalWithSnackbar = styled(TemplateModalWithSnackbar)`
  .MuiDialog-paper {
    min-height: 229px;
  }
  .MuiDialogContent-root {
    min-height: 0;

    > div {
      padding: 30px 140px;
    }
  }
`;
const StyledDescription = styled('p')`
  font-weight: 400;
`;

type IssueGroupCertificateProps = AuditModalCommonProps;

export const IssueGroupCertificateModal: FC<IssueGroupCertificateProps> = (
  props
) => {
  const { auditUuid } = props;
  const [t] = useTranslation('auditDetails');
  const dispatch = useDispatch();

  const {
    issueGroupCertificate,
    isLoading,
    error = [],
  } = useIssueGroupCertificateMutation();
  const closeMethod = () => {
    dispatch(closeAndResetAuditModal());
  };
  const nextAction: NextAction = (openSnackbar, closeModal) => {
    issueGroupCertificate(auditUuid).then(() => {
      openSnackbar(t('Group Certificate is issued.'), 'success');
      closeModal();
    });
  };

  const alertsState = useMemoizedAlerts(error);

  return (
    <StyledTemplateModalWithSnackbar
      title={t('Issue Group Certificate')}
      nextAction={nextAction}
      nextLabel={t('Issue Certificate')}
      prevLabel={t('Cancel', { ns: 'components' })}
      isLoading={isLoading}
      nextColor="primary"
      closeMethod={closeMethod}
    >
      <StyledBaseModalContent alertsState={alertsState}>
        <StyledDescription>
          {t('Do you want to issue the group certificate?')}
        </StyledDescription>
      </StyledBaseModalContent>
    </StyledTemplateModalWithSnackbar>
  );
};
