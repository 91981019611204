import { styled } from '@mui/material';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import React, { FC, ReactElement } from 'react';

const StyledDialogActions = styled(DialogActions)`
  padding: ${(props) => props.theme.spacing(0, 2, 2)};
  justify-content: space-between;
`;

export const ModalActions: FC<DialogActionsProps> = (props): ReactElement => (
  <StyledDialogActions {...props} />
);

export default ModalActions;
