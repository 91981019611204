import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { invalidateTags } from 'store/apiSlice';

export const useInvalidateTagsOnTabClick = (): void => {
  const dispatch = useDispatch();

  const receiveMessage = (event: MessageEvent) => {
    switch (event.data) {
      case 'certificates':
        dispatch(
          invalidateTags([
            'CERTIFICATE_DETAILS',
            'CERTIFICATE_DETAILS_ACTIONS',
            'CERTIFICATE_DETAILS_ADDITIONAL_AUDIT_LIST',
            'CERTIFICATE_DETAILS_ADDITIONAL_SUPPLIER_LIST',
            'CERTIFICATE_DETAILS_DOCUMENTS',
            'CERTIFICATE_DETAILS_LOCK_HISTORY',
            'CERTIFICATE_DETAILS_TEMPLATE',
            'CERTIFICATE_LIST',
          ])
        );
        break;
      case 'audits':
        dispatch(
          invalidateTags([
            'AUDIT_CERTIFICATE_DETAILS',
            'AUDIT_CERTIFICATE_DOCUMENTS',
            'AUDIT_DETAILS',
            'AUDIT_DETAILS_ACTIONS',
            'AUDIT_DETAILS_DOCUMENTS',
            'AUDIT_DETAILS_TEMPLATE',
            'AUDIT_LIST',
            'AUDIT_APPOINTMENT_DETAILS_LOGS',
            'AUDIT_RELEASES',
            'AUDITOR_PARTICIPANTS',
          ])
        );
        break;
      case 'diary':
        dispatch(
          invalidateTags(['APPOINTMENTS', 'AUDIT_APPOINTMENT_DETAILS_LOGS'])
        );
        break;
      default:
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
