import { MutableRefObject, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Nullable } from 'types';

export const useDiaryResetSearchPhrase = (
  searchMinCharError: boolean,
  resetMethod: () => void,
  inputRef: MutableRefObject<Nullable<HTMLInputElement>>
): void => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      // reset search param when user changes tab
      if (searchMinCharError) {
        dispatch(resetMethod());
        if (inputRef && inputRef.current) {
          inputRef.current.value = '';
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, resetMethod]
  );
};
