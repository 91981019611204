import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms/Button';
import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { ERROR, TEXT } from 'config/appColors';

const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 28px 70px;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 5.3rem;
  margin: 10px 0 10px;
  color: ${ERROR.LIGHT};
`;

const StyledText = styled('p')`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.025rem;
  text-align: center;
  width: 70%;
`;

const StyledButton = styled(Button)`
  letter-spacing: 0.125rem;
  line-height: 1.6rem;
  color: ${TEXT.PRIMARY};
`;

const StyledCloseButton = styled(StyledButton)`
  color: ${ERROR.LIGHT};
`;

interface AbortModalProps {
  title: ReactNode;
  message: string;
  closeButtonText?: string;
  continueButtonText?: string;
  showCloseButton?: boolean;
  closeAction?: () => void;
  abortAction: () => void;
  continueAction: () => void;
}

export const AbortModal: FC<AbortModalProps> = (props) => {
  const [t] = useTranslation('components');

  const {
    title,
    message,
    closeButtonText = t('Close anyway'),
    continueButtonText = t('Continue'),
    showCloseButton = false,
    closeAction,
    abortAction,
    continueAction,
  } = props;

  return (
    <TemplateAuditModal
      title={title}
      closeAction={() => closeAction?.()}
      showCloseButton={showCloseButton}
      contentComponent={
        <StyledContent>
          <StyledErrorIcon />
          <StyledText>{message}</StyledText>
        </StyledContent>
      }
      prevButtonComponent={
        <StyledCloseButton variant="text" onClick={abortAction}>
          {closeButtonText}
        </StyledCloseButton>
      }
      nextButtonComponent={
        <StyledButton variant="text" color="secondary" onClick={continueAction}>
          {continueButtonText}
        </StyledButton>
      }
    />
  );
};

export default AbortModal;
