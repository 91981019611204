import { createTheme } from '@mui/material';
import type { ThemeOptions } from '@mui/material';

import {
  ALTO,
  ERROR,
  LIGHT_GRAY,
  NEVADA,
  PRIMARY,
  SECONDARY,
  TEXT,
  WHITE,
} from 'config/appColors';

import './fonts.css';

export const mainThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: PRIMARY.MAIN,
    },
    secondary: {
      main: SECONDARY[200],
    },
    error: {
      main: ERROR.LIGHT,
    },
    info: {
      main: ALTO,
      dark: LIGHT_GRAY,
      contrastText: TEXT.PRIMARY,
    },
    background: {
      default: WHITE,
    },
    text: {
      primary: NEVADA,
    },
    modalPrevButton: {
      main: TEXT.PRIMARY,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeightRegular: 300,
    allVariants: {
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      color: NEVADA,
    },
    body1: {
      fontWeight: 300,
      fontSize: '1.4rem',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.8rem',
    },
  },
};

const theme: ThemeOptions = createTheme(mainThemeOptions);

export default theme;
