import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';

import { MISSING_PARAMS_ERROR, UNEXPECTED_ERROR } from 'config/constants';
import { RootState } from 'store';
import {
  ActionComment,
  AlertsState,
  AlertVariantEnum,
  AsyncThunkOptions,
  LoaderStatusEnum,
  Nullable,
} from 'types';
import apiClient from 'utils/apiClient';
import { createErrorAlertState } from 'utils/createAlertsState';
import { formatToAlerts } from 'utils/formatToAlerts';

import { DueDateState, ResetDueDateParams, SetDueDateParams } from './types';
import { parentReducerName } from '../../config/constants';
import endpoints from '../../config/endpoints';
import { TimeRange, TimeSelectPlaceholderEnum } from '../../types';
import { AuditAppointmentTime } from '../create/types';
import { TimeFormOptions } from '../formOptions/types';

const initialTimeState = {
  loading: LoaderStatusEnum.IDLE,
  placeholder: TimeSelectPlaceholderEnum.SELECT,
  range: null,
};

const initialTimes = {
  start: initialTimeState,
  end: initialTimeState,
};

const initialState: DueDateState = {
  setDueDateModalOpen: false,
  resetDueDateModalOpen: false,
  dateSelected: {
    start: null,
    end: null,
  },
  loading: LoaderStatusEnum.IDLE,
  alertsState: null,
  actionComment: null,
  times: initialTimes,
};

const reducerName = `${parentReducerName}/dueDate`;

const getHintsAlertsState = (hints?: string[]) => {
  if (!hints || hints.length === 0) return null;
  return {
    alerts: hints,
    variant: AlertVariantEnum.WARNING,
  };
};

// thunks
export const setDueDate = createAsyncThunk(
  `${reducerName}/setDueDate`,
  async (params: SetDueDateParams, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { appointmentId, startDate, endDate, actionComment } = params;
      const data = { startDate, endDate, actionComment };
      const response = await apiClient.post(
        endpoints.DUE_DATE.SET_AND_RESET(appointmentId),
        data
      );
      return fulfillWithValue(response.data);
    } catch (err) {
      const errors = formatToAlerts(err);
      return rejectWithValue(errors);
    }
  }
);

export const resetDueDate = createAsyncThunk(
  `${reducerName}/resetDueDate`,
  async (params: ResetDueDateParams, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { appointmentId, actionComment } = params;
      const response = await apiClient.delete(
        endpoints.DUE_DATE.SET_AND_RESET(appointmentId),
        {
          data: { actionComment },
        }
      );
      return fulfillWithValue(response.data);
    } catch (err) {
      const errors = formatToAlerts(err);
      return rejectWithValue(errors);
    }
  }
);

export const fetchStartRange = createAsyncThunk<
  TimeRange,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchStartRange`, async (_, thunkApi) => {
  try {
    const appointmentId =
      thunkApi.getState().auditAppointment.details?.data?.uuid;
    if (!appointmentId) return thunkApi.rejectWithValue([UNEXPECTED_ERROR]);

    const { data } = await apiClient.get(
      endpoints.DUE_DATE.START_RANGE(appointmentId)
    );

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

export const fetchEndRange = createAsyncThunk<
  TimeRange,
  undefined,
  AsyncThunkOptions
>(`${reducerName}/fetchEndRange`, async (_, thunkApi) => {
  try {
    const appointmentId =
      thunkApi.getState().auditAppointment.details?.data?.uuid;
    if (!appointmentId) return thunkApi.rejectWithValue([UNEXPECTED_ERROR]);

    const { start } = thunkApi.getState().auditAppointment.dueDate.dateSelected;
    if (!start) return thunkApi.rejectWithValue([MISSING_PARAMS_ERROR]);

    const { data } = await apiClient.get(
      endpoints.DUE_DATE.END_RANGE(appointmentId),
      {
        params: {
          start,
        },
      }
    );

    return thunkApi.fulfillWithValue(data, null);
  } catch (err) {
    const errors = formatToAlerts(err);
    return thunkApi.rejectWithValue(errors);
  }
});

// slice
export const auditAppointmentDueDateSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetDueDateState: () => initialState,
    setDueDateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.setDueDateModalOpen = action.payload;
    },
    setResetDueDateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.resetDueDateModalOpen = action.payload;
    },
    setDueDateStart: (state, action: PayloadAction<Nullable<string>>) => {
      state.dateSelected.start = action.payload;
    },
    setDueDateEnd: (state, action: PayloadAction<Nullable<string>>) => {
      state.dateSelected.end = action.payload;
    },
    resetDueDateSelected: (state) => {
      state.dateSelected = {
        start: null,
        end: null,
      };
    },
    resetDueDateAlertsState: (state) => {
      state.alertsState = null;
    },
    setAdminActionComment: (state, action: PayloadAction<ActionComment>) => {
      state.actionComment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStartRange.pending, (state) => {
      state.alertsState = null;
      state.times.start.loading = LoaderStatusEnum.LOADING;
      state.times.start.placeholder = TimeSelectPlaceholderEnum.CALCULATING;
    });
    builder.addCase(fetchStartRange.rejected, (state, action) => {
      state.alertsState = createErrorAlertState(action.payload);
      state.times.start.loading = LoaderStatusEnum.ERROR;
      state.times.start.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder.addCase(fetchStartRange.fulfilled, (state, action) => {
      state.times.start.loading = LoaderStatusEnum.SUCCESS;
      state.alertsState = getHintsAlertsState(action.payload.hints);
      state.times.start.range = action.payload;
      state.times.start.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder.addCase(fetchEndRange.pending, (state) => {
      state.dateSelected.end = null;
      state.alertsState = null;
      state.times.end.loading = LoaderStatusEnum.LOADING;
      state.times.end.placeholder = TimeSelectPlaceholderEnum.CALCULATING;
    });
    builder.addCase(fetchEndRange.rejected, (state, action) => {
      state.alertsState = createErrorAlertState(action.payload);
      state.times.end.loading = LoaderStatusEnum.ERROR;
      state.times.end.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder.addCase(fetchEndRange.fulfilled, (state, action) => {
      state.times.end.loading = LoaderStatusEnum.SUCCESS;
      state.alertsState = getHintsAlertsState(action.payload.hints);
      state.times.end.range = action.payload;
      state.times.end.placeholder = TimeSelectPlaceholderEnum.SELECT;
    });
    builder.addMatcher(
      isAnyOf(setDueDate.pending, resetDueDate.pending),
      (state) => {
        state.loading = LoaderStatusEnum.LOADING;
        state.alertsState = null;
      }
    );
    builder.addMatcher(
      isAnyOf(setDueDate.fulfilled, resetDueDate.fulfilled),
      (state) => {
        state.loading = LoaderStatusEnum.SUCCESS;
        state.alertsState = null;
      }
    );
    builder.addMatcher(
      isAnyOf(setDueDate.rejected, resetDueDate.rejected),
      (state, action) => {
        state.loading = LoaderStatusEnum.ERROR;
        state.alertsState = createErrorAlertState(action.payload);
      }
    );
  },
});

// actions
export const {
  setDueDateModalOpen,
  setDueDateStart,
  setDueDateEnd,
  resetDueDateSelected,
  resetDueDateState,
  resetDueDateAlertsState,
  setResetDueDateModalOpen,
  setAdminActionComment,
} = auditAppointmentDueDateSlice.actions;

// selectors
export const selectDueDateModalOpen = (state: RootState): boolean =>
  state[parentReducerName].dueDate.setDueDateModalOpen;

export const selectDueDateSelected = (state: RootState): AuditAppointmentTime =>
  state[parentReducerName].dueDate.dateSelected;

export const selectDueDateAlertsState = (
  state: RootState
): Nullable<AlertsState> => state[parentReducerName].dueDate.alertsState;

export const selectDueDateLoading = (state: RootState): LoaderStatusEnum =>
  state[parentReducerName].dueDate.loading;

export const selectResetDueDateModalOpen = (state: RootState): boolean =>
  state[parentReducerName].dueDate.resetDueDateModalOpen;

export const selectAdminActionComment = (
  state: RootState
): Nullable<ActionComment> => state[parentReducerName].dueDate.actionComment;

export const selectDueDateRange = (state: RootState): TimeFormOptions =>
  state[parentReducerName].dueDate.times;

export default auditAppointmentDueDateSlice.reducer;
