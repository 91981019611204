import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import {
  AddExceptionalCircumstanceParams,
  CloseExceptionalCircumstanceParams,
  UpdateExceptionalCircumstanceParams,
} from './types';
import endpoints from '../../config/endpoints';

const exceptionalCircumstanceApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    addCertificateExceptionalCircumstance: build.mutation<
      void,
      AddExceptionalCircumstanceParams
    >({
      query: ({ certificateUuid, ...data }) => ({
        url: endpoints.EXCEPTIONAL_CIRCUMSTANCE.ADD(certificateUuid),
        data,
        method: 'POST',
      }),
      invalidatesTags: ['CERTIFICATE_DETAILS', 'CERTIFICATE_DETAILS_ACTIONS'],
    }),
    updateCertificateExceptionalCircumstance: build.mutation<
      void,
      UpdateExceptionalCircumstanceParams
    >({
      query: ({ certificateUuid, caseUuid, ...data }) => ({
        url: endpoints.EXCEPTIONAL_CIRCUMSTANCE.UPDATE(
          certificateUuid,
          caseUuid
        ),
        data,
        method: 'PUT',
      }),
      invalidatesTags: ['CERTIFICATE_DETAILS', 'CERTIFICATE_DETAILS_ACTIONS'],
    }),
    closeExceptionalCircumstance: build.mutation<
      unknown,
      CloseExceptionalCircumstanceParams
    >({
      query({ certificateUuid, caseUuid, data }) {
        return {
          url: endpoints.EXCEPTIONAL_CIRCUMSTANCE.CLOSE(
            certificateUuid,
            caseUuid
          ),
          data,
          method: 'PUT',
        };
      },
      invalidatesTags: ['CERTIFICATE_DETAILS', 'CERTIFICATE_DETAILS_ACTIONS'],
    }),
  }),
});

export const useAddExceptionalCircumstanceMutation = () => {
  const [addExceptionalCircumstance, mutation] =
    exceptionalCircumstanceApi.useAddCertificateExceptionalCircumstanceMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: AddExceptionalCircumstanceParams) =>
    addExceptionalCircumstance(params).unwrap();

  return {
    ...mutation,
    addExceptionalCircumstance: requestMethod,
    status,
    error,
  };
};

export const useUpdateExceptionalCircumstanceMutation = () => {
  const [updateExceptionalCircumstance, mutation] =
    exceptionalCircumstanceApi.useUpdateCertificateExceptionalCircumstanceMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: UpdateExceptionalCircumstanceParams) =>
    updateExceptionalCircumstance(params).unwrap();

  return {
    ...mutation,
    updateExceptionalCircumstance: requestMethod,
    status,
    error,
  };
};

export const useCloseExceptionalCircumstanceMutation = () => {
  const [closeExceptionalCircumstance, mutation] =
    exceptionalCircumstanceApi.useCloseExceptionalCircumstanceMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: CloseExceptionalCircumstanceParams) =>
    closeExceptionalCircumstance(params).unwrap();

  return {
    ...mutation,
    closeExceptionalCircumstance: requestMethod,
    status,
    error,
  };
};
