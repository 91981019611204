import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { TypedStartListening } from '@reduxjs/toolkit';

import type { RootState } from 'store';

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;
