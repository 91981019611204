import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SortStateType } from 'components/organisms/Table/types';
import { RootState } from 'store';
import { Nullable } from 'types';

import { AllAuditsFiltersAndSortState } from './types';
import { parentReducerName } from '../../config/constants';
import { FilterType, Payload } from '../../types';
import { getInitialFilters } from '../../utils/getInitialFilters';

const initialState: AllAuditsFiltersAndSortState = {
  filtersReadyToFetch: false,
  filters: getInitialFilters(),
  sortState: null,
};

const allAuditsListSlice = createSlice({
  initialState,
  name: 'allAuditsList',
  reducers: {
    setFiltersReadyToFetch: (state) => {
      state.filtersReadyToFetch = true;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilterValue: (
      state,
      action: PayloadAction<Payload | Payload<string[]>>
    ) => {
      state.filters[action.payload.filterKey] = action.payload.value;
    },
    removeValueFromFilterList: (
      state,
      action: PayloadAction<Payload<string>>
    ) => {
      const filterList = state.filters[action.payload.filterKey];

      if (Array.isArray(filterList)) {
        state.filters[action.payload.filterKey] = filterList.filter(
          (filter) => filter !== action.payload.value
        );
      } else if (action.payload.filterKey in state) {
        // console.warn only for development purposes, we can get rid of it once table is finished
        console.warn(
          "You're trying to remove element of filter list by passing wrong key"
        );
      }
    },
    resetSortState: (state) => {
      state.sortState = null;
    },
    setSortState: (state, action: PayloadAction<SortStateType>) => {
      state.sortState = action.payload;
    },
    resetAllAuditsListState: () => initialState,
    updateFiltersAndSortState: (
      state,
      action: PayloadAction<
        Omit<AllAuditsFiltersAndSortState, 'filtersReadyToFetch'>
      >
    ) => {
      state.filters = action.payload.filters;
      state.sortState = action.payload.sortState;
    },
  },
});

export const {
  setFiltersReadyToFetch,
  removeValueFromFilterList,
  resetFilters,
  setFilterValue,
  resetAllAuditsListState,
  resetSortState,
  setSortState,
  updateFiltersAndSortState,
} = allAuditsListSlice.actions;

export const selectFilters = (state: RootState): Record<string, FilterType> =>
  state[parentReducerName].allAuditsList.filters;

export const selectSortState = (state: RootState): Nullable<SortStateType> =>
  state[parentReducerName].allAuditsList.sortState;

export const selectAreFiltersReadyToFetch = (state: RootState): boolean =>
  state[parentReducerName].allAuditsList.filtersReadyToFetch;

export default allAuditsListSlice.reducer;
