import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import endpoints from '../../config/endpoints';
import { SupplierListResponse } from '../../types';

const suppliersApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSuppliers: build.query<SupplierListResponse, void>({
      query: () => ({
        url: endpoints.SUPPLIERS.LIST,
      }),
      providesTags: ['SUPPLIERS'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetSuppliersQuery = () => {
  const query = suppliersApi.useGetSuppliersQuery();
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    suppliersList: query.data?.suppliers || [],
    error,
    status,
  };
};
