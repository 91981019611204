import get from 'lodash/get';
import React, { FC } from 'react';

import { Supplier } from 'types';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

const isSupplier = (supplier: unknown): supplier is Supplier =>
  !!supplier &&
  'uuid' in (supplier as Supplier) &&
  'coid' in (supplier as Supplier) &&
  'name' in (supplier as Supplier);

interface AttributeRowSupplierProps extends AttributeRowCommonProps {
  data: Record<string, unknown>;
  //   children: null; // TBD it might be used in the future
}

export const AttributeRowSupplier: FC<AttributeRowSupplierProps> = (props) => {
  const { data, dataRef, labelText } = props;
  const value = get(data, dataRef);
  const showAttributeRow = isSupplier(value);

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Supplier data" />
    );
  }

  const valueToDisplay = `${value.name} (${value.coid})`;

  return <AttributeRowBase label={labelText} value={valueToDisplay} />;
};
