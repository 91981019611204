import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect, VFC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from 'components/atoms/Button';
import { TextField } from 'components/atoms/TextField';
import globalRoutes from 'config/routes';

import { FormValues, LocationState } from './types';
import { login, selectIsLogged } from '../../../state/auth/slice';

const validationSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

const StyledGrid = styled(Grid)`
  margin-bottom: 15px;
`;
const StyledLabel = styled('label')`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.015rem;
  line-height: 2.4;
`;
const StyledTextField = styled(TextField)`
  input {
    padding: 16.5px 14px;
  }
`;
const StyledBtnContainer = styled('div')`
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

export const FormLogin: VFC = () => {
  const [t] = useTranslation('user');
  const { push } = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const isLogged = useSelector(selectIsLogged);
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const redirectLink = (state as LocationState)?.from ?? globalRoutes.HOME;
  const onSubmit = (data: FormValues) => dispatch(login({ data }));

  useEffect(() => {
    if (isLogged) {
      push(redirectLink);
    }
  }, [isLogged, redirectLink, push]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <StyledGrid container>
        <Grid item xs={5}>
          <StyledLabel htmlFor="username">{t('Username')}:</StyledLabel>
        </Grid>
        <Grid item xs={7}>
          <Controller
            name="username"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <StyledTextField
                value={value}
                onChange={onChange}
                fullWidth
                id="username"
                name="username"
                autoComplete="username"
                autoFocus
                placeholder={t('enter your username')}
              />
            )}
          />
        </Grid>
      </StyledGrid>
      <StyledGrid container>
        <Grid item xs={5}>
          <StyledLabel htmlFor="password">{t('Password')}:</StyledLabel>
        </Grid>
        <Grid item xs={7}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <StyledTextField
                value={value}
                onChange={onChange}
                fullWidth
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder={t('enter your password')}
              />
            )}
          />
        </Grid>
      </StyledGrid>
      <StyledBtnContainer>
        <Button
          type="submit"
          variant="outlined"
          color="secondary"
          disabled={!isDirty || !isValid}
        >
          {t('Submit', { ns: 'components' })}
        </Button>
      </StyledBtnContainer>
    </form>
  );
};

export default FormLogin;
