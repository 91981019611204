import { styled } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import React, { FC, ReactNode } from 'react';

import { StepperStepType } from 'components/atoms/Step';
import ModalHeader from 'components/molecules/ModalHeader';
import { Stepper } from 'components/molecules/Stepper';
import { ARGENT, BLACK } from 'config/appColors';
import { ButtonColorType } from 'types';

import ActionButtons from '../../molecules/AuditModalActionButtons';

export const STEPPER_MODAL_WIDTH = 800;
const CONTENT_SECTION_WIDTH = 550;

const StyledModalContent = styled(DialogContent)`
  width: ${CONTENT_SECTION_WIDTH}px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${BLACK};

  && .MuiDialogContent-root {
    padding: 0;
  }
`;

const StyledStepperContainer = styled('div')`
  width: ${STEPPER_MODAL_WIDTH - CONTENT_SECTION_WIDTH}px;
  padding-left: 16px;
  padding-bottom: 25px;
  font-size: 1.6rem;
  border-right: 1px solid ${ARGENT};
`;

const StyledContainer = styled('div')<{ $maxHeight: number }>`
  max-height: ${({ $maxHeight }) => `${$maxHeight}px`};
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid ${ARGENT};
`;

interface TemplateAuditModalProps {
  title: ReactNode;
  contentComponent: ReactNode;
  prevButtonComponent?: ReactNode;
  nextButtonComponent?: ReactNode;
  disableNext?: boolean;
  disablePrev?: boolean;
  closeAction: () => void;
  prevAction?: () => void;
  nextAction?: () => void;
  prevLabel?: ReactNode | string;
  nextLabel?: ReactNode | string;
  noPrevArrow?: boolean;
  noNextArrow?: boolean;
  prevColor?: ButtonColorType;
  nextColor?: ButtonColorType;
  showCloseButton?: boolean;
  hidePrevButton?: boolean;
  hideNextButton?: boolean;
  steps?: StepperStepType[];
  activeStep: string;
  onStepClick?: (step: StepperStepType) => void;
  className?: string;
}

export const TemplateStepperModal: FC<TemplateAuditModalProps> = (props) => {
  const {
    title,
    contentComponent,
    disableNext,
    disablePrev,
    closeAction,
    prevAction,
    nextAction,
    prevLabel,
    nextLabel,
    prevButtonComponent,
    nextButtonComponent,
    noNextArrow,
    noPrevArrow,
    prevColor,
    nextColor,
    showCloseButton = true,
    hidePrevButton,
    hideNextButton,
    steps,
    activeStep,
    onStepClick,
    className,
  } = props;

  /**
   *
   *  stepper example:
   *
   *  o  Documents
   *  |
   *  o  Comment
   *  |
   *  o  Summary
   *
   * 3 circles: 24px * 3
   * 2 lines: 34px * 2
   */
  const stepsAmount = steps?.length || 0;
  const lineHeight = 26;
  const circleHeight = 40;
  const defaultMaxHeight = 470;
  const stepsBasedHeight =
    stepsAmount * circleHeight + (stepsAmount - 1) * lineHeight;
  const finalMaxHeight =
    stepsBasedHeight > defaultMaxHeight ? stepsBasedHeight : defaultMaxHeight;

  return (
    <div className={className}>
      <ModalHeader
        title={title}
        closeAction={closeAction}
        showCloseButton={showCloseButton}
      />
      <StyledContainer $maxHeight={finalMaxHeight}>
        {steps && (
          <StyledStepperContainer>
            <Stepper
              activeStep={activeStep}
              steps={steps}
              onStepClick={onStepClick}
            />
          </StyledStepperContainer>
        )}
        <StyledModalContent>{contentComponent}</StyledModalContent>
      </StyledContainer>
      <ActionButtons
        prevAction={prevAction}
        nextAction={nextAction}
        disabledNext={disableNext}
        disabledPrev={disablePrev}
        prevLabel={prevLabel}
        nextLabel={nextLabel}
        prevButtonComponent={prevButtonComponent}
        nextButtonComponent={nextButtonComponent}
        noNextArrow={noNextArrow}
        noPrevArrow={noPrevArrow}
        prevColor={prevColor}
        nextColor={nextColor}
        hidePrevButton={hidePrevButton}
        hideNextButton={hideNextButton}
      />
    </div>
  );
};
