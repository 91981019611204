import { t as rawT } from 'i18n';
import {
  AlertVariantEnum,
  DraftStatus,
  DraftStatusContent,
  DraftStatusEnum,
  Nullable,
} from 'types';

export const getUseAuditDraftDocumentUpdateStatusContent =
  (alerts?: string[] | string) =>
  (draftStatus?: DraftStatus): Nullable<DraftStatusContent> => {
    const t = (key: string) => rawT(key, 'auditDraft');
    const headerTitlePrefix = t('Update Documents');

    switch (draftStatus?.id) {
      case DraftStatusEnum.CANCELLED: {
        return {
          headerTitlePrefix,
          title: draftStatus.name,
          status: AlertVariantEnum.WARNING,
          label: t('The Process was cancelled'),
        };
      }
      case DraftStatusEnum.SUBMITTED: {
        return {
          headerTitlePrefix,
          title: draftStatus.name,
          status: AlertVariantEnum.SUCCESS,
          label: t(
            'The data was successfully submitted. The system will analyse the data and create the related entries. This may take a few minutes. You can close the process now.'
          ),
        };
      }
      case DraftStatusEnum.UNSUBMITTED: {
        return null;
      }
      default: {
        return {
          headerTitlePrefix,
          title: '',
          status: AlertVariantEnum.ERROR,
          label: alerts || t('Unsupported status value'),
        };
      }
    }
  };
