import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useCertificationBodyModal } from 'domains/certificationBody/hooks/useCertificationBodyModal';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { useUserPermission } from 'hooks/useUserPermission';
import { CertificationBody, UserPermissionEnum } from 'types';
import { getAuditFileUploadPathname } from 'utils/getAuditFileUploadPathname';

import { UseDraftTrigger } from '../types';

export const useAuditDraftTrigger = (): UseDraftTrigger => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const { push } = useHistory();
  const isAdmin = useSelector(selectUserIsAdmin);
  const isPermittedToCreate = useUserPermission(
    UserPermissionEnum.AUDIT_DRAFT_CREATE
  );

  const openAuditFileUploadPage = () =>
    push(
      getAuditFileUploadPathname({
        supplierId,
      })
    );
  const { isCbModalOpen, openCbModal, closeCbModal, handleCbModalNextAction } =
    useCertificationBodyModal(openAuditFileUploadPage);
  const handleUploadProcess = isAdmin ? openCbModal : openAuditFileUploadPage;

  const handleCbNextAction = (cb: CertificationBody) => {
    handleCbModalNextAction(cb, openAuditFileUploadPage);
  };

  return {
    isCbModalOpen,
    showButton: isPermittedToCreate,
    handleUploadProcess,
    closeCbModal,
    handleCbNextAction,
  };
};
