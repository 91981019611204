import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { StepperStepType } from 'components/atoms/Step';
import { WithUndefined } from 'types';
import { checkIfEnumValueExists } from 'utils/checkIfEnumValueExists';

import { useAuditDraftTemplateQuery } from '../state/auditDraftTemplate/api';
import {
  selectAuditDraftCurrentStep,
  setCurrentStep,
} from '../state/auditDraftTemplate/slice';
import { AuditDraftTemplateResponse } from '../state/auditDraftTemplate/types';
import {
  AuditDraftStep,
  AuditDraftStepEnum,
  AuditDraftURLParams,
} from '../types';

const getIndexOfStep = (
  step: WithUndefined<AuditDraftStepEnum>,
  steps: AuditDraftStep[]
) => steps.findIndex((s) => s.props.id === step);

interface UseAuditDraftStepper {
  auditDraftTemplate: WithUndefined<AuditDraftTemplateResponse>;
  steps: StepperStepType[];
  currentStep: WithUndefined<AuditDraftStepEnum>;
  currentStepConfig: WithUndefined<AuditDraftStep>;
  onStepClick: (step: StepperStepType) => void;
  setNextStep: () => void;
  setPrevStep: () => void;
}

export const useAuditDraftStepper = (): UseAuditDraftStepper => {
  const dispatch = useDispatch();
  const { auditDraftId } = useParams<AuditDraftURLParams>();
  const currentStep = useSelector(selectAuditDraftCurrentStep);
  const { auditDraftTemplate } = useAuditDraftTemplateQuery(auditDraftId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const auditDraftSteps = (auditDraftTemplate?.template.children || []).filter(
    (step) => {
      const stepId = step?.props?.id;
      if (checkIfEnumValueExists(AuditDraftStepEnum, stepId)) {
        return true;
      }

      console.warn(
        `${stepId} was removed from stepper (unsupported step id), please check spelling`
      );

      return false;
    }
  );
  const indexOfCurrentStep = getIndexOfStep(currentStep, auditDraftSteps);
  const currentStepConfig = auditDraftSteps.find(
    ({ props }) => props.id === currentStep
  );

  useEffect(() => {
    if (!currentStep && auditDraftSteps && auditDraftSteps[0]) {
      // setting first step as an initial step in the process
      dispatch(setCurrentStep(auditDraftSteps[0].props.id));
    }
  }, [auditDraftSteps, currentStep, dispatch]);

  const steps: StepperStepType[] = auditDraftSteps.map(
    ({ props: { id, title } }, index, stepsArr) => ({
      id,
      label: title.en,
      // when value is white space string stepper displays circle with background
      // without any text according to design otherwise it displays inactive circle
      value: index < getIndexOfStep(currentStep, stepsArr) ? ' ' : undefined,
    })
  );

  const onStepClick = (step: StepperStepType) => {
    dispatch(setCurrentStep(step.id));
  };

  const setNextStep = () => {
    if (auditDraftSteps.length - 1 !== indexOfCurrentStep) {
      const nextStep = auditDraftSteps[indexOfCurrentStep + 1];
      dispatch(setCurrentStep(nextStep.props.id));
    }
  };

  const setPrevStep = () => {
    if (indexOfCurrentStep !== 0) {
      const prevStep = auditDraftSteps[indexOfCurrentStep - 1];
      dispatch(setCurrentStep(prevStep.props.id));
    }
  };

  return {
    auditDraftTemplate,
    steps,
    currentStep,
    currentStepConfig,
    onStepClick,
    setNextStep,
    setPrevStep,
  };
};
