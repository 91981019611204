import { TranslatedText } from 'types';

export enum AttributeRowTypeEnum {
  TEXT = 'attribute-row-text',
  COMMENT = 'attribute-row-comment',
  SUPPLIER = 'attribute-row-supplier',
  SUPPLIER_LINK = 'attribute-row-supplier-link',
  CERTIFICATION_BODY = 'attribute-row-certification-body',
  CERTIFICATION_BODY_LINK = 'attribute-row-certification-body-link',
  CERTIFICATE_ISSUED_AT = 'attribute-row-certificate-issued-at',
  CERTIFICATE_VALID_UNTIL = 'attribute-row-certificate-valid-until',
  KIND = 'attribute-row-kind',
  TYPE = 'attribute-row-type',
  MODULE = 'attribute-row-module',
  SCOPE = 'attribute-row-scope',
  STATUS_BADGE = 'attribute-row-status-badge',
  RAW_RESULT = 'attribute-row-raw-result',
  RESULT = 'attribute-row-result',
  TIME_FRAME = 'attribute-row-time-frame',
  DATE = 'attribute-row-date',
  AUDIT_TIME_FRAME = 'attribute-row-audit-time-frame',
  BADGES = 'attribute-row-badges',
  MAPPED_TEXT = 'attribute-row-mapped-text',
}

export interface AttributeRowCommonProps extends Record<string, unknown> {
  labelWidth?: number;
  labelText: TranslatedText;
  dataRef: string | string[]; // it might be dot notation e.g. 'obj.field1.field2.field3'
}

export interface AttributeRow {
  type: AttributeRowTypeEnum;
  props: AttributeRowCommonProps;
  children?: unknown;
}
