import { styled } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';

import { TranslatedText } from 'types';
import { generateId } from 'utils/generateId';
import { isArrayOfString } from 'utils/isArrayOfString';

import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';
import { StatusIndicatorWithIcon } from '../StatusIndicator';

const isAttributeRowBadgesChild = (
  value: unknown
): value is AttributeRowBadgesChild =>
  'id' in (value as AttributeRowBadgesChild) &&
  'text' in (value as AttributeRowBadgesChild) &&
  !!(value as AttributeRowBadgesChild).text.en;

interface AttributeRowBadgesChild {
  id: string;
  text: TranslatedText;
}

const StyledContainer = styled('div')`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;

  svg {
    transform: scale(0.85);
  }
`;

export interface AttributeRowBadgesProps extends AttributeRowCommonProps {
  children: unknown;
  data: Record<string, unknown>;
}

export const AttributeRowBadges: FC<AttributeRowBadgesProps> = (props) => {
  const { children, data, dataRef } = props;

  const value = get(data, dataRef);
  const valuesAreValid = isArrayOfString(value);
  const childrenAreValid =
    Array.isArray(children) &&
    children.every((badgeItem) => isAttributeRowBadgesChild(badgeItem?.props));
  const showAttributeRow = valuesAreValid && childrenAreValid;

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Badges data" />
    );
  }

  const finalChildren = children
    .map((child) => child.props)
    .filter((child) => value.includes(child.id));

  if (finalChildren.length === 0) {
    return null;
  }

  return (
    <StyledContainer>
      {finalChildren.map((child, index) => (
        <StatusIndicatorWithIcon
          iconData={child}
          key={generateId(child.id, index)}
        />
      ))}
    </StyledContainer>
  );
};
