import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { CreateAuditDraftViaExistingAuditParams } from './types';
import { endpoints } from '../../config/endpoints';

const auditDraftViaExistingAuditApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createAuditDraftViaExistingAudit: build.mutation<
      string,
      CreateAuditDraftViaExistingAuditParams
    >({
      query: (data) => ({
        url: endpoints.CREATE_AUDIT_DRAFT_VIA_EXISTING_AUDIT,
        data,
        method: 'POST',
        delayTime: 2000,
      }),
    }),
  }),
});

export const useCreateAuditDraftViaExistingAuditMutation = () => {
  const [createAuditDraft, mutation] =
    auditDraftViaExistingAuditApi.useCreateAuditDraftViaExistingAuditMutation();

  const error = useFormattedError(mutation, 'formatToAlerts');
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (
    params: CreateAuditDraftViaExistingAuditParams
  ) => createAuditDraft(params).unwrap();

  return {
    ...mutation,
    createAuditDraft: requestMethod,
    status,
    error,
  };
};
