import { ActionComment, Nullable } from 'types';

import { AdditionalAudit } from '../additionalAuditManage/types';
import { AdditionalSupplier } from '../certificateGroupCertificateTab/types';

export enum CertificateDetailsModalEnum {
  UPDATE_LOCK_REASON = 'update-lock-reason',
  UNLOCK_CERTIFICATE = 'unlock-certificate',
  UPDATE_UNLOCK_REASON = 'update-unlock-reason',
  ADD_EXCEPTIONAL_CIRCUMSTANCE = 'add-exceptional-circumstance',
  UPDATE_EXCEPTIONAL_CIRCUMSTANCE = 'update-exceptional-circumstance',
  CLOSE_EXCEPTIONAL_CIRCUMSTANCE = 'close-exceptional-circumstance',
  ADD_AUDIT = 'add-audit',
  REMOVE_AUDIT = 'remove-audit',
  ADD_SUPPLIER = 'add-supplier',
  REMOVE_SUPPLIER = 'remove-supplier',
  LOCK_CERTIFICATE = 'lock-certificate',
  REMOVE_GROUP_CERTIFICATE = 'remove-group-certificate',
  REQUEST_AUDIT_ACCESS = 'request-audit-access',
  REMOVE_CERTIFICATE = 'remove-certificate',
  SWITCH_CB_MODAL = 'switch-cb-modal',
}

// lock certificate / update lock reason
export interface CommonFormValues {
  category: string;
  categoryOthersDescription?: string;
  descriptionEnglish: string;
  descriptionOtherLanguage?: string;
  actionComment?: ActionComment;
  type: string;
}

export interface FormValuesState
  extends CommonFormValues,
    ExceptionalCircumstanceState {
  reasonDescription: string;
  actionComment: ActionComment;
}

export interface ExceptionalCircumstanceState {
  circumstanceType?: string;
  circumstanceDescription?: string;
}

export interface CertificateDetailsModalState {
  openModalName: Nullable<CertificateDetailsModalEnum>;
  commonFormValues: FormValuesState;
  updateUuid: Nullable<string>;
  supplierToRemove: Nullable<AdditionalSupplier>;
  auditToRemove: Nullable<AdditionalAudit>;
}

export interface StringFormValuePayload {
  name: keyof Omit<FormValuesState, 'actionComment'>;
  value: string;
}

export interface ActionCommentPayload {
  name: keyof FormValuesState['actionComment'];
  value: string | boolean;
}

export interface OpenCertificateModalPayload {
  name: CertificateDetailsModalState['openModalName'];
  values?: Partial<CertificateDetailsModalState['commonFormValues']>;
}
