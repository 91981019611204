import { AxiosError } from 'axios';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import yiiApiClientInstance from 'utils/apiClient/yii';
import { getBaseQueryError } from 'utils/getBaseQueryError';

import { RequestAuditAccessParams } from './types';
import endpoints from '../../config/endpoints';

const auditAccessApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    requestAuditAccess: build.mutation<unknown, RequestAuditAccessParams>({
      queryFn: async (payload) => {
        try {
          const { data } =
            await yiiApiClientInstance.post<RequestAuditAccessParams>(
              endpoints.ACTIONS.REQUEST_AUDIT_ACCESS,
              payload
            );
          return { data };
        } catch (axiosError) {
          const err = axiosError as AxiosError<string | string[]>;

          return getBaseQueryError(err?.response?.data);
        }
      },
    }),
  }),
});

export const useRequestAuditAccessMutation = () => {
  const [requestAuditAccess, mutation] =
    auditAccessApi.useRequestAuditAccessMutation();

  const alerts = useFormattedError(mutation, 'formatToAlerts') || [];
  const status = useRTKQueryStatus(mutation);

  const requestMethod = async (params: RequestAuditAccessParams) =>
    requestAuditAccess(params).unwrap();

  return {
    ...mutation,
    requestAuditAccess: requestMethod,
    status,
    alerts,
  };
};
