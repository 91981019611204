import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import { CertificateDetailsActionsResponse } from './types';
import endpoints from '../../config/endpoints';

const certificateDetailsActionsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateDetailsActions: build.query<
      CertificateDetailsActionsResponse,
      string
    >({
      query: (certificateUuid: string) => ({
        url: endpoints.ACTIONS.DROPDOWN_OPTIONS(certificateUuid),
      }),
      providesTags: ['CERTIFICATE_DETAILS_ACTIONS'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetCertificateDetailsActions = (certificateUuid: string) => {
  const query =
    certificateDetailsActionsApi.useGetCertificateDetailsActionsQuery(
      certificateUuid
    );
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    certificateDetailsActions: query.data?.actions || [],
    error,
    status,
  };
};
