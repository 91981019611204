import { styled } from '@mui/material';
import React, { FC } from 'react';

interface ModalTitleWithDetailsProps {
  title: string;
  details: string;
}

const StyledModalTitleDetails = styled('span')`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  margin: 0;
`;

export const ModalTitleWithDetails: FC<ModalTitleWithDetailsProps> = ({
  title,
  details,
}) => (
  <>
    {title}
    <StyledModalTitleDetails>{details}</StyledModalTitleDetails>
  </>
);
