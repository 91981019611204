import { LANG_PARAM } from 'config/languages';

const endpoints = {
  CREATE_IFS: `/${LANG_PARAM}/audit-drafts/create-by-audit-file`,
  CREATE_AXPX: `/${LANG_PARAM}/audit-drafts/create-by-axpx-audit-file`,
  REGULAR_UPLOAD: {
    MODULES_LIST: `/${LANG_PARAM}/audit-drafts/preflights/audit-file-modules`,
  },
};

export default endpoints;
