import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';

import {
  CertificateDraftTemplateResponse,
  GetCertificateDraftTemplateParams,
} from './types';
import { endpoints } from '../../config/endpoints';

const certificateDraftTemplateApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCertificateDraftTemplate: build.query<
      CertificateDraftTemplateResponse,
      string
    >({
      query: (certificateDraftId) => ({
        url: endpoints.TEMPLATE(certificateDraftId),
      }),
      providesTags: ['CERTIFICATE_DRAFT_TEMPLATE'],
      keepUnusedDataFor: 60,
    }),
  }),
});

export const useCertificateDraftTemplateQuery = (
  params: GetCertificateDraftTemplateParams,
  skip = false
) => {
  const query = certificateDraftTemplateApi.useGetCertificateDraftTemplateQuery(
    params.certificateDraftId,
    {
      skip,
    }
  );

  const error = useFormattedError(query, 'formatToAlerts');
  const status = useRTKQueryStatus(query);

  return {
    ...query,
    certificateDraftTemplate: query.data,
    status,
    error,
  };
};
