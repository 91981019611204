import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { CSSSize, LoaderStatusEnum, Size } from 'types';

interface StyledLoaderProps {
  $minWidth?: CSSSize;
  $minHeight?: CSSSize;
}

interface StyledLoaderIndicatorProps {
  size?: Size;
}

export interface LoaderProps extends StyledLoaderIndicatorProps {
  minWidth?: CSSSize;
  minHeight?: CSSSize;
  children: ReactNode;
  status: LoaderStatusEnum;
  error?: ReactNode;
  className?: string;
}

const StyledLoaderWrapper = styled('div')<StyledLoaderProps>`
  display: flex;
  justify-content: stretch;
  min-width: ${({ $minWidth }) => $minWidth || '100%'};
  min-height: ${({ $minHeight }) => $minHeight || '100%'};
`;

const StyledIndicatorWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Loader: FC<LoaderProps> = (props) => {
  const { status, children, minWidth, minHeight, size, error, className } =
    props;

  const showSpecifiedError = status === LoaderStatusEnum.ERROR && !!error;
  const showChildren =
    status === LoaderStatusEnum.SUCCESS ||
    status === LoaderStatusEnum.IDLE ||
    (status === LoaderStatusEnum.ERROR && !showSpecifiedError);

  return (
    <StyledLoaderWrapper
      $minWidth={minWidth}
      $minHeight={minHeight}
      className={className}
    >
      {status === LoaderStatusEnum.LOADING && (
        <StyledIndicatorWrapper className="loader">
          <LoaderIcon size={size} />
        </StyledIndicatorWrapper>
      )}
      {showChildren && children}
      {showSpecifiedError && error}
    </StyledLoaderWrapper>
  );
};

export default Loader;
