import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useActionInformationData } from 'components/organisms/ActionInformationSection';
import { DeleteModalForm } from 'components/organisms/DeleteModalForm';
import {
  NextAction,
  TemplateModalWithSnackbar,
} from 'components/templates/TemplateModalWithSnackbar';
import { AUDITS_ASSESSMENTS_TAB_SLUG } from 'config/constants';
import { getSupplierPathname as YII_getSupplierPathname } from 'domains/widgets/utils/getSupplierPathname';
import { useMemoizedAlerts } from 'hooks/useMemoizedAlerts';
import { getSupplierPathname } from 'utils/getSupplierPathname';
import isIframe from 'utils/isIframe';

import { actionReasons } from '../../../config/constants';
import { useDeleteAuditMutation } from '../../../state/deleteAudit/api';
import { closeAndResetAuditModal } from '../../../state/modal/slice';
import {
  AuditDetailsModalEnum,
  AuditModalCommonProps,
} from '../../../state/modal/types';

type DeleteAuditModalProps = AuditModalCommonProps;

export const DeleteAuditModal: FC<DeleteAuditModalProps> = (props) => {
  const { auditUuid, supplierId } = props;
  const [t] = useTranslation('auditDetails');
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isAdminInfoValid,
    setIsAdminInfoValid,
    adminReason,
    adminComment,
    handleAdminReasonChange,
    handleAdminCommentChange,
  } = useActionInformationData();

  const { deleteAudit, isLoading, error = [] } = useDeleteAuditMutation();
  const closeMethod = () => {
    dispatch(closeAndResetAuditModal());
  };
  const nextAction: NextAction = (openSnackbar, closeModal) => {
    deleteAudit({
      auditUuid,
      actionComment: {
        reason: adminReason,
        ...(adminComment && {
          comment: adminComment,
        }),
      },
    }).then(() => {
      openSnackbar(t('The Audit is deleted.'), 'success');
      closeModal();
      // TODO: once we get rid of iframe things ternary&YII_getSupplierPathname should be removed
      const supplierPathname = isIframe
        ? YII_getSupplierPathname(supplierId, 'AUDITS')
        : getSupplierPathname(supplierId, AUDITS_ASSESSMENTS_TAB_SLUG);

      history.push(supplierPathname);
    });
  };

  const alertsState = useMemoizedAlerts(error);

  return (
    <TemplateModalWithSnackbar
      title={t('Delete audit/assessment')}
      nextAction={nextAction}
      nextLabel={t('Delete', { ns: 'components' })}
      disableNext={!isAdminInfoValid}
      isLoading={isLoading}
      nextColor="error"
      closeMethod={closeMethod}
    >
      <DeleteModalForm
        setIsAdminInfoValid={setIsAdminInfoValid}
        adminReason={adminReason}
        adminComment={adminComment}
        adminReasonChangeHandler={handleAdminReasonChange}
        adminCommentChangeHandler={handleAdminCommentChange}
        adminReasonOptions={actionReasons[AuditDetailsModalEnum.DELETE_AUDIT]}
        alertsState={alertsState}
        description={t('Do you really want to delete the audit/assessment?')}
      />
    </TemplateModalWithSnackbar>
  );
};
