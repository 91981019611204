import React, { FC, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from 'components/atoms/DatePicker';
import { LoaderStatusEnum } from 'types';
import { formatDate } from 'utils/dates';

import { useDatePickerPropsUtils } from '../../../hooks/useDatePickerPropsUtils';
import {
  resetTimes,
  selectAuditPlanTime,
  setEndTime,
  setStartTime,
} from '../../../state/create/slice';
import {
  fetchUnannouncedEndDate,
  fetchUnannouncedStartRange,
  resetRanges,
  selectTimeFormOptions,
} from '../../../state/formOptions/slice';
import { setIsNextButtonDisabled } from '../../../state/stepperModal/slice';
import { DatePickerContainer } from '../../atoms/DatePickerContainer';

export const SetUnannouncedTimeInitial: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const selectedTime = useSelector(selectAuditPlanTime);
  const { start, end } = useSelector(selectTimeFormOptions);
  const { getValue, getPlaceholder, getDateForRange, getDefaultCalendarMonth } =
    useDatePickerPropsUtils();

  useLayoutEffect(() => {
    if (selectedTime?.start && selectedTime?.end) return;

    dispatch(setIsNextButtonDisabled(true));
    dispatch(resetTimes());
    dispatch(resetRanges());
    dispatch(fetchUnannouncedStartRange());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartDateChange = (startDate: Date) => {
    const formattedStartDate = formatDate(startDate, 'yyyy-MM-dd');
    dispatch(setIsNextButtonDisabled(true));
    dispatch(setStartTime(formattedStartDate));
    dispatch(setEndTime(null));
    dispatch(fetchUnannouncedEndDate());
  };

  return (
    <>
      <DatePickerContainer label={t('Start Date')}>
        <DatePicker
          onChange={handleStartDateChange}
          value={getValue(start.loading, selectedTime?.start)}
          inputPlaceholder={getPlaceholder(
            start.placeholder,
            selectedTime?.start
          )}
          loading={start.loading === LoaderStatusEnum.LOADING}
          disabled={!start.range || start.loading === LoaderStatusEnum.LOADING}
          defaultCalendarMonth={getDefaultCalendarMonth(start)}
          minDate={getDateForRange(start, 'earliest')}
          maxDate={getDateForRange(start, 'latest')}
        />
      </DatePickerContainer>
      <DatePickerContainer label={t('End Date')}>
        <DatePicker
          value={selectedTime?.end || null}
          inputPlaceholder={getPlaceholder(end.placeholder, selectedTime?.end)}
          loading={end.loading === LoaderStatusEnum.LOADING}
          disabled
        />
      </DatePickerContainer>
    </>
  );
};
