import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { AuditorParticipant } from 'types';
import { validateAndLogErrors } from 'utils/schemaValidator';

import { AuditorParticipantsParams } from './types';
import endpoints from '../../config/endpoints';
import { auditorParticipantSchema } from '../../schemas/AuditorParticipant';

const auditorParticipantsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditorParticipants: build.query<
      AuditorParticipant[],
      AuditorParticipantsParams
    >({
      query: ({ auditUuid }) => ({
        url: endpoints.AUDITOR_PARTICIPANTS_TAB.LIST(auditUuid),
      }),
      transformResponse: validateAndLogErrors(auditorParticipantSchema),
      providesTags: ['AUDITOR_PARTICIPANTS'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetAuditorParticipantsQuery = (
  params: AuditorParticipantsParams
) => {
  const query = auditorParticipantsApi.useGetAuditorParticipantsQuery(params);
  const status = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  return {
    ...query,
    auditorParticipants: query.data || [],
    error,
    status,
  };
};
