import { styled, Typography } from '@mui/material';

import { TEXT } from 'config/appColors';

const StyledHeading = styled(Typography)`
  font-size: 2rem;
  font-weight: 500;
  line-height: 3.2rem;
  letter-spacing: 0.015rem;
  color: ${TEXT.PRIMARY};
  margin-bottom: 10px;
`;

StyledHeading.displayName = 'StyledHeading';

export { StyledHeading };
