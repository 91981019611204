import { styled } from '@mui/material';
import React, { FC } from 'react';

import { AlertList, AlertListProps } from 'components/molecules/AlertList';

const StyledAlertList = styled(AlertList)`
  margin-left: 10px;
`;

export const StepAlertList: FC<AlertListProps> = (props) => (
  <StyledAlertList {...props} />
);
