import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
} from 'components/organisms/Table';

import { useReleasesTemplate } from '../../../hooks/useReleasesTemplate';
import { useGetReleasesQuery } from '../../../state/releasesTab/api';
import { AuditReleasesTab, Release } from '../../../types';
import { getReleaseContentToDisplay } from '../../../utils/getReleaseContentToDisplay';
import { TabSectionHeading } from '../../atoms/TabSectionHeading';
import { ReleaseActionsDropdown } from '../../molecules/ReleaseActionsDropdown';

type ReleasesTabProps = AuditReleasesTab['props'] & {
  auditUuid: string;
};

export const ReleasesTab: FC<ReleasesTabProps> = (props) => {
  const { auditUuid, headerText } = props;
  const [t] = useTranslation('auditDetails');
  const { translationsMap } = useReleasesTemplate(auditUuid);

  const { pagination, limit, offset, setTotalRowsAmount } = usePagination();

  const { status, error, releases, total } = useGetReleasesQuery({
    auditUuid,
    limit,
    offset,
  });

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const { getColumnConfig, getColumnConfigWithEllipsis } =
    useColumnConfig('24%');

  const columns: Column<Release>[] = [
    {
      customCell: ({ releasedForCompany: { name, coid } }) =>
        `${name} (${coid})`,
      headerName: t('Company (COID)'),
      ...getColumnConfig(),
    },
    {
      customCell: ({ moduleFamily: { name } }) => name,
      headerName: t('Standard/program', { ns: 'components' }),
      ...getColumnConfig(),
    },
    {
      customCell: getReleaseContentToDisplay(translationsMap),
      title: getReleaseContentToDisplay(translationsMap),
      headerName: t('Content'),
      ...getColumnConfigWithEllipsis('23%'),
    },
    {
      customCell: ({ timeFrame }) => timeFrame.name,
      title: ({ timeFrame }) => timeFrame.name,
      headerName: t('Timeframe'),
      ...getColumnConfigWithEllipsis('23%'),
    },
    {
      customCell: (release) => (
        <ReleaseActionsDropdown auditUuid={auditUuid} release={release} />
      ),
      headerName: t('Actions', { ns: 'components' }),
      ...getColumnConfig('2%'),
    },
  ];

  return (
    <>
      <TabSectionHeading text={headerText} />
      <Table
        rows={releases}
        columns={columns}
        status={status}
        error={error}
        pagination={pagination}
        dense
      />
    </>
  );
};
