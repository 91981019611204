import { styled } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React, { FC, PropsWithChildren } from 'react';

import { ERROR } from 'config/appColors';

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantSuccess,
  &.SnackbarItem-variantError,
  &.SnackbarItem-variantWarning,
  &.SnackbarItem-variantInfo {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
  }

  &.SnackbarItem-variantError {
    background: ${ERROR.LIGHT};
  }
`;

const Snackbars: FC<PropsWithChildren> = ({ children }) => (
  <StyledSnackbarProvider>{children}</StyledSnackbarProvider>
);

export default Snackbars;
