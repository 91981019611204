import { Card, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ModalFooterLoader } from 'components/atoms/ModalFooterLoader';
import { ModalTitleWithDetails } from 'components/atoms/ModalTitleWithDetails';
import { TemplateAuditModal } from 'components/templates/TemplateAuditModal';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { ARGENT } from 'config/appColors';
import { AuditFileUploadForm } from 'domains/auditFileUpload/components/organisms/AuditFileUploadForm';
import { FileUploadAbortModal } from 'domains/auditFileUpload/components/organisms/FileUploadAbortModal';
import {
  useCreateAuditDraftViaAXPXFileMutation,
  useCreateAuditDraftViaFileMutation,
} from 'domains/auditFileUpload/state/regularUpload/api';
import { selectCertificationBodyValue } from 'domains/certificationBody/state/search/slice';
import {
  getSupplierDetails,
  selectSupplierDetails,
} from 'domains/supplier/state/details/slice';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { getAuditDraftFormPathname } from 'domains/widgets/utils/getAuditDraftFormPathname';
import { getSupplierPathname } from 'domains/widgets/utils/getSupplierPathname';
import { useAbortModal } from 'hooks/useAbortModal';
import { FieldIdentifier, Nullable } from 'types';
import convertFileToBase64 from 'utils/convertFileToBase64';
import { FileTypeValidator } from 'utils/fileTypeValidator';
import { getSupplierDetailsTitle } from 'utils/getSupplierDetailsTitle';
import { isAnyLoading } from 'utils/isAnyLoading';

const PageWrapper = styled('div')`
  padding: 40px;
`;
const StyledCard = styled(Card)`
  max-width: 980px;
  margin: 0 auto;

  .MuiDialogActions-root {
    border-top: 1px ${ARGENT} solid;
    padding-top: 16px;
  }
`;

export const WidgetAuditFileUploadRegularUpload: FC = () => {
  const [t] = useTranslation('auditFileUpload');
  const { supplierId } = useParams<{ supplierId: string }>();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const selectedSupplier = useSelector(selectSupplierDetails);
  const title = (
    <ModalTitleWithDetails
      title={t('Upload Audit/Assessment')}
      details={getSupplierDetailsTitle(selectedSupplier)}
    />
  );
  const [auditFile, setAuditFile] = useState<Nullable<File>>(null);
  const [selectedModule, setSelectedModule] =
    useState<Nullable<FieldIdentifier['id']>>(null);
  const [isGroupCertificationAudit, setIsGroupCertificationAudit] =
    useState(false);
  const { createAuditDraftViaFile, status: createViaFileStatus } =
    useCreateAuditDraftViaFileMutation();
  const { createAuditDraftViaAxpxFile, status: createViaAxpxStatus } =
    useCreateAuditDraftViaAXPXFileMutation();
  const isLoading = isAnyLoading(createViaFileStatus, createViaAxpxStatus);
  const buttonLoader = isLoading ? <ModalFooterLoader /> : null;
  const nextAction = async () => {
    const isAxpxFile = FileTypeValidator.isAxpx(auditFile);
    if (auditFile && (selectedModule || isAxpxFile)) {
      const convertedFile = await convertFileToBase64(auditFile);
      const auditDraftId = isAxpxFile
        ? await createAuditDraftViaAxpxFile({
            auditFile: convertedFile,
            supplierId,
            isGroupCertificationAudit,
          })
        : await createAuditDraftViaFile({
            auditFile: convertedFile,
            supplierId,
            moduleId: selectedModule as string,
            isGroupCertificationAudit,
          });

      push(
        getAuditDraftFormPathname({
          supplierId,
          auditDraftId,
        })
      );
    }
  };
  const { isAbortModalOpen, openAbortModal, closeAbortModal } = useAbortModal();
  const handleAbortAction = () =>
    push(getSupplierPathname(supplierId, 'AUDITS'));
  const customCertificationBody = useSelector(selectCertificationBodyValue);
  const isAdmin = useSelector(selectUserIsAdmin);
  const disableNext =
    !auditFile || (FileTypeValidator.isIfs(auditFile) && !selectedModule);

  useEffect(() => {
    if (isAdmin && !customCertificationBody) {
      push(getSupplierPathname(supplierId, 'AUDITS'), 'CB_REQUIRED');
    }
  }, [isAdmin, customCertificationBody, push, supplierId]);

  useEffect(() => {
    if (!selectedSupplier) {
      dispatch(getSupplierDetails({ supplierId }));
    }
  }, [selectedSupplier, supplierId, dispatch]);

  return (
    <TemplatePageDefault hidePageHeader hideAside hideHeader hideSupport>
      <FileUploadAbortModal
        open={isAbortModalOpen}
        title={title}
        continueAction={closeAbortModal}
        abortAction={handleAbortAction}
      />
      <PageWrapper>
        <StyledCard>
          <TemplateAuditModal
            title={title}
            contentComponent={
              <AuditFileUploadForm
                supplierId={supplierId}
                selectedModule={selectedModule || ''}
                setSelectedModule={setSelectedModule}
                setAuditFile={setAuditFile}
                isGroupCertificationAudit={isGroupCertificationAudit}
                setIsGroupCertificationAudit={setIsGroupCertificationAudit}
              />
            }
            closeAction={openAbortModal}
            showCloseButton={!isLoading}
            noNextArrow
            nextColor="info"
            nextLabel={t('Next', { ns: 'components' })}
            nextAction={nextAction}
            disableNext={disableNext}
            nextButtonComponent={buttonLoader}
          />
        </StyledCard>
      </PageWrapper>
    </TemplatePageDefault>
  );
};
