import CloseIcon from '@mui/icons-material/Close';
import { alpha, styled } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import React, { FC, ReactElement } from 'react';

import { BLACK } from 'config/appColors';

export interface CloseButtonProps extends IconButtonProps {
  'aria-label'?: string;
}

const defaultProps: CloseButtonProps = {
  'aria-label': 'close',
};

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: ${(props) => props.theme.spacing(1)};
  top: ${(props) => props.theme.spacing(1)};
  color: ${alpha(BLACK, 0.6)};
`;

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 2.2rem;
`;

export const CloseButton: FC<CloseButtonProps> = (
  props: CloseButtonProps
): ReactElement => (
  <StyledIconButton {...props} size="large">
    <StyledCloseIcon />
  </StyledIconButton>
);

CloseButton.defaultProps = defaultProps;

export default CloseButton;
