import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import { invalidateRTKTags } from 'utils/invalidateRTKTags';

import { DeleteCertificateParams } from './types';
import endpoints from '../../config/endpoints';

const deleteCertificateApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    deleteCertificate: build.mutation<void, DeleteCertificateParams>({
      query: ({ certificateUuid, actionComment }: DeleteCertificateParams) => ({
        url: endpoints.DELETE_CERTIFICATE(certificateUuid),
        data: {
          actionComment,
        },
        method: 'DELETE',
      }),
      invalidatesTags: invalidateRTKTags([
        'CERTIFICATE_LIST',
        'CERTIFICATE_DETAILS',
        'CERTIFICATE_DETAILS_TEMPLATE',
        'CERTIFICATE_DETAILS_ACTIONS',
      ]),
    }),
  }),
});

export const useDeleteCertificateMutation = () => {
  const [deleteCertificate, mutation] =
    deleteCertificateApi.useDeleteCertificateMutation();
  const status = useRTKQueryStatus(mutation);
  const error = useFormattedError(mutation, 'formatToAlerts');

  const requestMethod = async (params: DeleteCertificateParams) =>
    deleteCertificate(params).unwrap();

  return {
    ...mutation,
    deleteCertificate: requestMethod,
    error,
    status,
  };
};
