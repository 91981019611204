import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { alpha, styled } from '@mui/material';
import MuiGrow from '@mui/material/Grow';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiMenuList from '@mui/material/MenuList';
import MuiPaper from '@mui/material/Paper';
import MuiPopper from '@mui/material/Popper';
import MuiTextField from '@mui/material/TextField';
import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { LoaderIcon } from 'components/atoms/LoaderIcon';
import { BLACK, ERROR, TEXT } from 'config/appColors';
import { Nullable, Option } from 'types';

interface SearchFieldProps {
  options: Option[];
  dataTestId?: string;
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  description?: string;
  noOptionsMessage?: string;
  infoMessage?: string | true;
  searchTriggerLength?: number;
  onValueSelect?: (option: Nullable<Option>) => void;
  onSearchTermChange?: (newSearchTerm: string) => void;
  onTextFieldValueChange?: (newValue: string) => void;
  className?: string;
}

const StyledContainer = styled('div')`
  position: relative;
`;

const StyledMuiTextField = styled(MuiTextField)`
  width: 350px;
  border-radius: 4px;

  > div.MuiInputBase-root {
    padding-right: 0;

    input.MuiInputBase-input {
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.015rem;
      font-weight: 400;
      color: ${TEXT.PRIMARY};
      padding: 16px 12px;

      &::placeholder {
        color: ${TEXT.SECONDARY_LIGHT};
        opacity: 1;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${alpha(BLACK, 0.23)};
      border-width: 1px;
    }

    &.Mui-disabled {
      input.MuiInputBase-input {
        &::placeholder {
          -webkit-text-fill-color: ${TEXT.DISABLED}; // necessary because this has precedence over color and MUI uses this
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-style: dotted;
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${TEXT.PRIMARY};
      }
    }
  }
`;

const StyledMuiPopper = styled(MuiPopper)`
  width: 100%;
  border-radius: 4px;
  z-index: 2;

  > div.MuiPaper-root {
    box-shadow: 0 5px 5px -3px ${alpha(BLACK, 0.2)},
      0 8px 10px 1px ${alpha(BLACK, 0.14)}, 0 3px 14px 2px ${alpha(BLACK, 0.12)};
  }
`;

const StyledMuiPaper = styled(MuiPaper)`
  max-height: 310px;
  width: 100%;
  overflow-y: auto;
`;

const StyledMuiMenuList = styled(MuiMenuList)`
  width: 100%;
  max-height: 220px;
`;

const StyledMuiMenuItem = styled(MuiMenuItem)`
  && {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: 0.015rem;
    color: ${TEXT.PRIMARY};
    white-space: normal;
  }
`;

const StyledLoader = styled(LoaderIcon)<{ $open?: boolean }>`
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  position: absolute;
  right: 10px;
`;

const StyledDescription = styled('p')`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.015rem;
  color: ${TEXT.PRIMARY};
  margin-bottom: 5px;
`;

const StyledError = styled('p')`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.04rem;
  color: ${ERROR.LIGHT};
  padding-left: 14px;
  margin-top: 5px;
`;

const StyledInfo = styled(StyledError)`
  color: ${TEXT.SECONDARY_LIGHT};
`;

// used to add a top margin of 5px to popper element since overriding margin for popper using styled components gives a warning.
const popperOptions = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    },
  ],
};

export const DEFAULT_SEARCH_TRIGGER_LENGTH = 3;

export const SearchField: FC<SearchFieldProps> = (props) => {
  const {
    options,
    dataTestId,
    loading,
    disabled,
    placeholder,
    description,
    infoMessage,
    noOptionsMessage,
    searchTriggerLength = DEFAULT_SEARCH_TRIGGER_LENGTH,
    onValueSelect,
    onSearchTermChange,
    onTextFieldValueChange,
    className,
  } = props;

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showInfo, setShowInfo] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Nullable<Option>>(null);

  const menuListRef = useRef<HTMLLIElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  const [t] = useTranslation('common');
  const defaultInfoMessage = t(
    'Please enter minimum {{value}} letters to start searching.',
    { value: searchTriggerLength }
  );
  const finalInfoMessage =
    typeof infoMessage === 'boolean' ? defaultInfoMessage : infoMessage;

  const openPopper = () => {
    setOpen(true);
  };

  const closePopper = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    if (!selectedOption) setOpen((prevOpenVal) => !prevOpenVal);
  };

  const handleClose = (
    event: MouseEvent | TouchEvent | ReactMouseEvent<EventTarget>
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    closePopper();
  };

  const handleTextFieldValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchString = event.target.value;
    const trimmedOldSearchString = searchTerm.trim();
    const trimmedSearchString = newSearchString.trim();

    setSearchTerm(newSearchString);
    onTextFieldValueChange?.(newSearchString);

    if (trimmedSearchString === trimmedOldSearchString) return;

    setSelectedOption(null);
    setShowError(false);
    closePopper();

    onValueSelect?.(null);

    if (trimmedSearchString.length >= searchTriggerLength) {
      onSearchTermChange?.(trimmedSearchString); // invoke search term change callback only if search string's length is more than searchTriggerLength
      setShowInfo(false);
    } else {
      setShowInfo(true);
    }
  };

  const handleTextFieldKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      menuListRef.current?.focus();
    }
  };

  const handleMenuListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      closePopper();
    }
  };

  const handleMenuItemClick = (
    event: ReactMouseEvent<HTMLLIElement, MouseEvent>,
    option: Option
  ) => {
    onValueSelect?.(option);
    setSelectedOption(option);
    setSearchTerm(option.label);
    handleClose(event);
  };

  useEffect(() => {
    setShowError(!!noOptionsMessage && options.length === 0 && !loading);
  }, [noOptionsMessage, options, loading]);

  useEffect(() => {
    if (options.length && !selectedOption) openPopper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const finalOpen =
    open &&
    !loading &&
    options.length > 0 &&
    searchTerm.length >= searchTriggerLength;

  const finalLoading = loading && searchTerm.length >= searchTriggerLength;

  return (
    <StyledContainer className={className}>
      {description && (
        <StyledDescription className="SearchField-description">
          {description}
        </StyledDescription>
      )}
      <StyledMuiTextField
        autoComplete="off"
        data-testid={dataTestId}
        placeholder={placeholder}
        value={searchTerm}
        disabled={disabled}
        onKeyDown={handleTextFieldKeyDown}
        ref={anchorRef}
        onClick={handleToggle}
        onChange={handleTextFieldValueChange}
        aria-controls={finalOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        InputProps={{
          endAdornment: <StyledLoader size={25} $open={finalLoading} />,
        }}
      />
      <StyledMuiPopper
        transition
        disablePortal
        anchorEl={anchorRef.current}
        open={finalOpen}
        role={undefined}
        popperOptions={popperOptions}
      >
        {({ TransitionProps }) => (
          <MuiGrow {...TransitionProps}>
            <StyledMuiPaper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMuiMenuList onKeyDown={handleMenuListKeyDown}>
                  {options.map((option, index) => (
                    <StyledMuiMenuItem
                      ref={index === 0 ? menuListRef : null}
                      data-value={option.value}
                      role="option"
                      key={option.label}
                      onClick={(e) => handleMenuItemClick(e, option)}
                    >
                      {option.label}
                    </StyledMuiMenuItem>
                  ))}
                </StyledMuiMenuList>
              </ClickAwayListener>
            </StyledMuiPaper>
          </MuiGrow>
        )}
      </StyledMuiPopper>
      {finalInfoMessage && showInfo && (
        <StyledInfo>{finalInfoMessage}</StyledInfo>
      )}
      {showError && !showInfo && <StyledError>{noOptionsMessage}</StyledError>}
    </StyledContainer>
  );
};
