import { styled } from '@mui/material';
import React, { FC } from 'react';

import { TextField, TextFieldProps } from 'components/atoms/TextField';
import { WHITE } from 'config/appColors';

interface FilterSearchInputProps extends TextFieldProps {
  minWidth?: string;
  maxWidth?: string;
}

interface StyledSearchInputProps {
  $minWidth?: string;
  $maxWidth?: string;
}

const StyledSearchInput = styled(TextField)<StyledSearchInputProps>`
  input {
    padding: 9px 12px;
    background: ${WHITE};
    min-width: ${({ $minWidth }) => $minWidth ?? 'none'};
    max-width: ${({ $maxWidth }) => $maxWidth ?? 'none'};
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -2.5rem;
    left: 0;
  }
`;

export const FilterSearchInput: FC<FilterSearchInputProps> = (props) => {
  const { minWidth, maxWidth, ...restProps } = props;

  return (
    <StyledSearchInput
      {...restProps}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
    />
  );
};
