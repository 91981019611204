import { alpha, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StepDescription } from 'components/atoms/StepDescription';
import { StepFormTitle } from 'components/atoms/StepFormTitle';
import { AlertList } from 'components/molecules/AlertList';
import Loader from 'components/molecules/Loader';
import { BLACK, ERROR } from 'config/appColors';
import {
  DocumentDefinition,
  LoaderStatusEnum,
  Nullable,
  TranslatedText,
} from 'types';
import convertFileToBase64 from 'utils/convertFileToBase64';
import { getTranslatedValue } from 'utils/getTranslatedValue';
import { isAnyError } from 'utils/isAnyError';

import { useAuditDraftDetailsQuery } from '../../../state/auditDraftDetails/api';
import { useGetAuditDraftDocumentDefinitionsQuery } from '../../../state/documentDefinitions/api';
import {
  useAddDocumentsMutation,
  useDeleteDocumentMutation,
} from '../../../state/documentManage/api';
import { DocumentTable } from '../DocumentTable';
import { UploadDocDefinitionButton } from '../UploadDocDefinitionButton';

const StyledStepContent = styled('div')`
  margin: 0 10px;
`;
const StyledStepDescription = styled(StepDescription)`
  margin-bottom: 20px;
`;
const StyledDocDefinitionWrapper = styled('div')`
  width: 100%;
`;
const StyledDocDefinitionItem = styled('div')`
  margin-bottom: 23px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
const StyledUploadHeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  border-bottom: 1px ${alpha(BLACK, 0.23)} solid;

  > div:last-of-type {
    margin-left: auto;
    align-self: flex-end;
  }
`;
const StyledMissingDocument = styled('span')`
  margin: 0 10px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.017rem;
  color: ${ERROR.LIGHT};
`;
const StyledUploadDocDefinitionButton = styled(UploadDocDefinitionButton)`
  align-self: flex-end;
`;

interface DocumentUploadProps {
  auditDraftId: string;
  instructionText?: TranslatedText;
  className?: string;
}

export const DocumentUpload: FC<DocumentUploadProps> = (props) => {
  const { auditDraftId, instructionText, className } = props;
  const [t] = useTranslation('auditDraft');
  const {
    auditDraftDetails,
    error: draftDetailsError,
    status: draftDetailsStatus,
    isLoading: isAuditDraftLoading,
    isFetching: isAuditDraftFetching,
  } = useAuditDraftDetailsQuery(auditDraftId);
  const {
    documentDefinitions,
    error: documentDefinitionsError,
    status: documentDefinitionsStatus,
    isLoading: isDocDefLoading,
    isFetching: isDocDefFetching,
  } = useGetAuditDraftDocumentDefinitionsQuery({ auditDraftId });
  const { addDocuments, error: addDocumentsError } = useAddDocumentsMutation();
  const {
    deleteDocument,
    error: documentDeleteError,
    status: deleteDocumentStatus,
    reset: resetDeleteDocumentMutation,
  } = useDeleteDocumentMutation();
  const [uploadingDocDefinitions, setUploadingDocDefinitions] = useState<
    string[]
  >([]);
  const [processingDocDefId, setProcessingDocDefId] =
    useState<Nullable<string>>(null);
  const errorAlerts =
    draftDetailsError || documentDefinitionsError || addDocumentsError;
  const isCriticalError = isAnyError(
    draftDetailsStatus,
    documentDefinitionsStatus
  );

  const prepareFiles = async (files: File[], displayName?: string) =>
    Promise.all(
      files.map(async (file) => ({
        file: await convertFileToBase64(file),
        fileName: file.name,
        displayName, // currently isn't prepared for multiple naming
      }))
    );

  const onFilesChange = async (
    rawFiles: File[],
    docDefinition: DocumentDefinition,
    language: Nullable<string>,
    displayName?: string
  ) => {
    if (rawFiles.length > 0) {
      const docDefinitionId = docDefinition.id;
      setUploadingDocDefinitions((prev) => [...prev, docDefinitionId]);
      const files = await prepareFiles(rawFiles, displayName);

      return addDocuments({
        auditDraftId,
        docDefinitionId,
        language,
        files,
      }).then(() => {
        setUploadingDocDefinitions((prev) =>
          prev.filter((uploadingDocId) => uploadingDocId !== docDefinitionId)
        );
        setProcessingDocDefId(docDefinitionId);
      });
    }

    return Promise.resolve();
  };

  const onFileDelete = (docDefId: string) => (documentId: string) =>
    deleteDocument({ auditDraftId, documentId }).then(() => {
      setProcessingDocDefId(docDefId);
    });
  const getDocumentsGroupedByDocDef = (id: string) =>
    (auditDraftDetails?.documents || []).filter(
      ({ docDefinitionId }) => docDefinitionId === id
    );

  const mainLoaderStatus =
    isAuditDraftLoading || isDocDefLoading
      ? LoaderStatusEnum.LOADING
      : LoaderStatusEnum.SUCCESS;

  useEffect(() => {
    if (!isAuditDraftFetching && !isDocDefFetching && processingDocDefId) {
      setProcessingDocDefId(null);
    }
  }, [isAuditDraftFetching, isDocDefFetching, processingDocDefId]);

  return (
    <StyledStepContent className={className}>
      {errorAlerts && <AlertList alerts={errorAlerts} />}
      {!isCriticalError && (
        <>
          <StyledStepDescription
            content={getTranslatedValue(instructionText)}
          />
          <Loader status={mainLoaderStatus} minHeight="150px">
            <StyledDocDefinitionWrapper>
              {documentDefinitions.map((docDef) => {
                const { id, type, isRequired, isUploadable } = docDef;
                const isUploading = uploadingDocDefinitions.includes(id);
                const documents = getDocumentsGroupedByDocDef(id);

                if (!isUploadable && !documents.length && !isDocDefFetching) {
                  // hide non-uploadable & empty docs
                  return null;
                }

                return (
                  <StyledDocDefinitionItem key={id}>
                    <StyledUploadHeaderContainer>
                      <StepFormTitle content={type.name} variant="h4" />
                      {isRequired && (
                        <StyledMissingDocument>
                          {t('Document missing')}
                        </StyledMissingDocument>
                      )}
                      <StyledUploadDocDefinitionButton
                        docDefinition={docDef}
                        onFilesChange={onFilesChange}
                        isUploading={isUploading}
                        isRefetchingDocs={id === processingDocDefId}
                        disabled={!isUploadable}
                      />
                    </StyledUploadHeaderContainer>
                    <DocumentTable
                      documents={documents}
                      documentsLoadingStatus={LoaderStatusEnum.SUCCESS}
                      fileDeleteStatus={deleteDocumentStatus}
                      fileDeleteAlertsState={documentDeleteError}
                      onFileDelete={onFileDelete(id)}
                      resetDeleteDocumentMutation={resetDeleteDocumentMutation}
                    />
                  </StyledDocDefinitionItem>
                );
              })}
            </StyledDocDefinitionWrapper>
          </Loader>
        </>
      )}
    </StyledStepContent>
  );
};
