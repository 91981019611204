import React, { FC } from 'react';

import { AccessList } from 'domains/accessManagement/components/organisms/AccessList';

import { AuditAccessesTab } from '../../../types';
import { TabSectionHeading } from '../../atoms/TabSectionHeading';

type AuditAccessesTabProps = AuditAccessesTab['props'] & {
  auditUuid: string;
};

export const AccessesTab: FC<AuditAccessesTabProps> = ({
  auditUuid,
  headerText,
}) => (
  <>
    <TabSectionHeading text={headerText} />
    <AccessList resourceName="audits" entityUuid={auditUuid} />
  </>
);
